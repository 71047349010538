
<div class="popup-header w-100 d-flex">
  <p class="w-100">
    Timelines(Side By Side)
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<mat-dialog-content style="overflow: auto;">
  <button *ngIf="(timelines$ | async).length" 
      mat-raised-button
      color="primary"
      class="mb-3 mr-2" 
      type="button"
      [disabled]="!filterCheckedArray((timelines$ | async)).length"
      (click)="addTimelinesToReport()"
      >
      <span>Add</span>
    </button>

    <div infiniteScroll
       [infiniteScrollDistance]="4"
       [infiniteScrollThrottle]="10"
       [scrollWindow]="false"
       (scrolled)="onScroll()"
       style="height: calc(100vh - 150px); overflow-y: auto;"
  >
    <div class="mat-card m-1 mt-3  position-relative" *ngFor="let relation of (timelines$ | async); let idx = index;">
      <p class="title"  >{{relation.title }}</p>
      <div class="d-flex align-items-center scroller">
        <mat-checkbox *ngIf="data.type === 'report'" (change)="relation.isChecked = $event.checked" class="mr-2"></mat-checkbox>
        <div class="image-wrapper">
          <div
            class="img-placeholder"
          >
          <p class="inspection-date"> {{relation.keyProjectInspectionDate?.toDate() | date }} </p>
            <img *ngIf="imagesThumbs[relation.keyImageId]"
            [src]="imagesThumbs[relation.keyImageId]" alt="Image" class="img-placeholder"
              style="width: 100%; height: 100%; object-fit: contain" />
          </div>
        </div>

        <div class="image-wrapper align-self-end ml-2">
          <div class="img-placeholder">
          <p class="inspection-date"> {{relation.valueProjectInspectionDate?.toDate() | date }} </p>
       <img *ngIf="imagesThumbs[relation.valueImageId]" 
              [src]="imagesThumbs[relation.valueImageId]" alt="Image" class="img-placeholder"
              style="width: 100%; height: 100%; object-fit: contain" />
          </div>
        </div>

      </div>
    </div>
  </div>

</mat-dialog-content>
