import Konva from 'konva';
import {DD} from 'konva/lib/DragAndDrop'

/**
 * 
 */
export function dragInit(stage: Konva.Stage) {
    // removing left mouse dependency
    window.removeEventListener('mouseup', DD._endDragBefore, true);
    window.removeEventListener('mousemove', DD._drag);
    window.removeEventListener('mouseup', DD._endDragAfter, false);

    let dragging = false, dragStartPosition = null;

    stage.on('mousedown touchstart', e => {
        if (!stage.draggable()) return;

        dragging = true;
        dragStartPosition = stage.getPointerPosition();
    });
    stage.on('mousemove touchmove', e => {
        if (!e['evt'].shiftKey) return;

        if (dragging) {
            const pointer = stage.getPointerPosition();
            stage.move({
                x: pointer.x - dragStartPosition.x,
                y: pointer.y - dragStartPosition.y
            });
            dragStartPosition = pointer;

            stage.fire('dragmove', e);
            stage.batchDraw();
        }
    });
    stage.on('mouseup touchend', e => {
        dragging = false;
        dragStartPosition = null;
    });
}

/**
 * 
 * @param stage 
 */
export function registerDragBounds(stage: Konva.Stage) {
    stage.on('dragmove', e => {
        stage.find('Image').each(img => {
            const inverse = stage.scaleX() <= 1;
            const   leftEdge = 0,
                    topEdge = 0,
                    rightEdge = (stage.width() * stage.scaleX()) - img.width(),
                    bottomEdge = (stage.height() * stage.scaleY()) - img.height();

            if(inverse ? stage.x() < leftEdge : stage.x() > leftEdge) {
                stage.setAttrs({ x: leftEdge });
            }

            if(inverse ? stage.y() < topEdge : stage.y() > topEdge) {
                stage.setAttrs({ y: topEdge });
            }

            if(inverse ? -stage.x() < rightEdge : -stage.x() > rightEdge) {
                stage.setAttrs({ x: -rightEdge});
            }

            if(inverse ? -stage.y() < bottomEdge : -stage.y() > bottomEdge) {
                stage.setAttrs({ y: -bottomEdge });
            }
        });
    });
}