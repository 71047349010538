import { Component, Input, ViewChild, Inject, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from '../services/ui.service';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';
import { GeneralService } from '../services/general.service';
import { Alignment } from '../Three/alignment';
import * as _ from 'lodash';
import * as THREE from 'three';
import { v4 as uuidv4 } from 'uuid';
import {
  MathUtils
} from 'three';
import { take } from 'rxjs/operators';
declare var window: any;

@Component({
  selector: 'app-align-models',
  templateUrl: './align-models.component.html',
  styleUrls: ['./align-models.component.scss']
})
export class AlignModelsComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  inspectionModel;
  controlView: string = "orbit";
  alignmentType: string = 'manual';
  private projectValueChangeSubscription = new Subscription();
  leftLabelSubscription = new Subscription();
  rightLabelSubscription = new Subscription();
  projectModelScene: any;
  baselineModelScene: any;
  leftClick;
  rightClick;
  projectModelLoading: boolean = false;
  projectViewer;
  leftViewer;
  rightViewer;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uiService: UiService,
    public backendService: BackendService,
    public dialogRef: MatDialogRef<AlignModelsComponent>,
    private generalService: GeneralService,
    private toastr: ToastrService,
    private storage: AngularFireStorage) {
  }

  ngOnInit(): void {
    this.projectValueChangeSubscription = this.uiService.projectFieldsChangeEvent$.subscribe(() => {
      if (this.isLoading && this.uiService.project.alignStatus === 'success') {
        // if (!this.arraysOfObjectsAreEqual(this.data.project.gltfUrl, this.uiService.project.gltfUrl)) {
        //  this.updateNewModel();
        this.toastr.success('Model aligned successfully. you can re-open again to see the model');
        this.dialogRef.close();

        // }
      }
      if (this.isLoading && this.uiService.project.alignStatus === 'failed') {
        this.isLoading = false
        this.toastr.error("Auto model manual aligned is failed, please use manual model for alignment")
      }
    })
    this.getBaselineModels();
  }


  arraysOfObjectsAreEqual(arr1, arr2) {
    // Check if both arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Custom comparison function for objects
    function isObjectEqual(obj1, obj2) {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      return keys1.every((key) => obj1[key] === obj2[key]);
    }

    // Check if every object in arr1 is also in arr2
    return arr1.every((obj1) => arr2.some((obj2) => isObjectEqual(obj1, obj2)));
  }


  updateNewModel() {
    this.isLoading = false;
    const cleanup = () => {
      this.projectModelLoading = false;
    };

    const mesh = this.rightViewer.pivot?.children.filter(o => o.type === 'Group');
    if (mesh.length) {
      this.rightViewer.pivot.remove(mesh[0]);
      this.rightViewer.scene.remove(mesh[0])
    }
    this.resetPoints();
    this.projectModelLoading = true;
    const loader = this.rightViewer.loadGLTF(this.uiService.project, false);
    loader.catch((e) => {
      cleanup();
      this.toastr.error('3D file failed to load, please check your internet connection or refresh the browser again')
    })
    loader.then((scene) => {
      cleanup();
      this.projectModelScene = scene;
      this.rightViewer.alignedModel(this.projectModelScene, false, this.uiService.project.modelOptions,false);
    });
  }


  projectLoad() {
    const _this = this;
    const project = _.cloneDeep(this.data.project);
    const viewer = this.createProjectModelViewer(project);
    const cleanup = () => {
      this.projectModelLoading = false;
    };

    if (project.gltfUrl && project.gltfUrl.length) {
      const loader = viewer.loadGLTF(project, false);
      loader.catch((e) => {
        cleanup();
        this.toastr.error('3D file failed to load, please check your internet connection or refresh the browser again')
      })
      loader.then((scene) => {
        this.projectViewer.setContent(scene, false, project, this.baselineModel)
        _this.projectModelScene = scene;
        _this.initRightModel();
        cleanup();
      })
    }

    if (this.baselineModel.gltfUrl && this.baselineModel.gltfUrl.length) {
      const loader = viewer.loadGLTF(this.baselineModel, true);
      loader.catch((e) => {
        cleanup();
        this.toastr.error('3D file failed to load, please check your internet connection or refresh the browser again')
      })
      loader.then((scene) => {
        this.projectViewer.setBaselineContent(scene, false)
        _this.baselineModelScene = scene;
        _this.initLeftModel();
        cleanup();
      })
    }
  }

  initLeftModel() {
    const _this = this;
    if (!this.leftViewer) {
      this.leftViewer = this.createLeftModelViewer();
      this.getLeftModelLabels();
      this.leftViewer.renderer.domElement.addEventListener('pointerup', onPointerUp)
      function onPointerUp(event) {
        const time = new Date().getTime();
        const currentTime = _this.leftClick;
        _this.leftClick = time;
        if (time - currentTime < 300 && event.which === 1) {
          _this.leftViewer.raycaster.setFromCamera(
            {
              x: ((event.offsetX) / _this.leftViewer.renderer.domElement.clientWidth) * 2 - 1,
              y: -((event.offsetY) / _this.leftViewer.renderer.domElement.clientHeight) * 2 + 1,

            },
            _this.leftViewer.camera
          )

          const meshes = _this.leftViewer.pivot.children.filter(o => o.type == 'Group' && o.children.length);
          if (!meshes || !meshes[0]) { return };
          const intersects = _this.leftViewer.raycaster.intersectObjects(meshes[0].children, false);
          const id = uuidv4();
          if (intersects.length) {
            //remove points
            const labelIntersects = _this.leftViewer.raycaster.intersectObjects(_this.leftPoints, true);
            if (labelIntersects[0] && labelIntersects[0].object.userData) {
              const removePoints = _this.leftViewer.scene.children.filter(o => o.userData?.id === labelIntersects[0].object.userData.id);
              removePoints.forEach(points => {
                _this.leftViewer.scene.remove(points);
              });
              _this.leftAnnotations = _this.leftAnnotations.reduce((p, c) => (c.id !== labelIntersects[0].object.userData.id && p.push(c), p), []);
            } else {
              _this.leftAnnotations.push({
                id: id,
                point: intersects[0].point
              })
            }
            _this.leftlabels();
          }
        }
      }
    }

  }

  initRightModel() {
    const _this = this;
    if (!this.rightViewer) {
      this.rightViewer = this.createRightModelViewer();
      this.getRightModelLabels();
      this.rightViewer.renderer.domElement.addEventListener('pointerup', onPointerUp)
      function onPointerUp(event) {
        const time = new Date().getTime();
        const currentTime = _this.rightClick;
        _this.rightClick = time;
        if (time - currentTime < 300 && event.which === 1) {
          _this.rightViewer.raycaster.setFromCamera(
            {
              x: ((event.offsetX) / _this.rightViewer.renderer.domElement.clientWidth) * 2 - 1,
              y: -((event.offsetY) / _this.rightViewer.renderer.domElement.clientHeight) * 2 + 1,

            },
            _this.rightViewer.camera
          )

          const meshes = _this.rightViewer.pivot.children.filter(o => o.type == 'Group' && o.children.length);
          if (!meshes || !meshes[0]) { return };
          const intersects = _this.rightViewer.raycaster.intersectObjects(meshes[0].children, false);
          const id = uuidv4();
          if (intersects.length) {
            //remove points
            const labelIntersects = _this.rightViewer.raycaster.intersectObjects(_this.rightPoints, true);
            if (labelIntersects[0] && labelIntersects[0].object.userData) {
              const removePoints = _this.rightViewer.scene.children.filter(o => o.userData?.id === labelIntersects[0].object.userData.id);
              removePoints.forEach(points => {
                _this.rightViewer.scene.remove(points);
              });
              _this.rightAnnotations = _this.rightAnnotations.reduce((p, c) => (c.id !== labelIntersects[0].object.userData.id && p.push(c), p), []);
            } else {
              _this.rightAnnotations.push({
                id: id,
                point: intersects[0].point
              })
            }
            _this.rightlabels();
          }
        }
      }
    }
  }

  onAlignmentValChange(value) {
    if (value === 'auto') {
      if (!this.rightViewer.content) {
        this.rightViewer.alignedModel(this.projectModelScene, false, this.data.project.modelOptions,false);
      } else {
        this.rightViewer.addScene(this.projectModelScene)
      }
      if (!this.leftViewer.content) {
        this.leftViewer.alignedModel(this.baselineModelScene, false, this.baselineModel.modelOptions,true);
      } else {
        this.leftViewer.addScene(this.baselineModelScene)
      }

    } else {
      this.projectViewer.combineScene(this.baselineModelScene, this.projectModelScene);
      //  this.projectViewer.addProjectScene(this.projectModelScene)
    }

  }


  orientation(axis) {
    this.projectViewer.orientation[axis] = this.projectViewer.orientation[axis] + 90;
    const rad = MathUtils.degToRad(90);
     this.projectViewer.pivot.rotation[axis.toLowerCase()] = this.projectViewer.pivot.rotation [axis.toLowerCase()] + rad;
  }

  createProjectModelViewer(model) {
    let options = {};
    if (!model.modelOptions) {
      options = {
        grid: true,
        label: 0.05,
        bgColor: '#e1e1e1'
      }
    }
    if (!model.modelOptions || !model.modelOptions.scale) {
      //default scales
      options = {
        ...{
          scale: {
            x: 1,
            y: 1,
            z: 1
          },
          translate: {
            x: 0,
            y: 0,
            z: 0
          }, rotation: {
            x: 0,
            y: 0,
            z: 0
          },
          ...options
        }
      }
    }
    const viewerEl: any = document.getElementById('projectModelPanel');
    viewerEl.width = (window.innerWidth) - 10;
    viewerEl.height = window.innerHeight - 100;
    const params = Object.assign({}, {
      modelOptions: {
        ...model.modelOptions,
        ...options
      },
      isAlignment: true,
      disableMovement: true,
      grid: true,

    })

    this.projectViewer = new Alignment(viewerEl, params);
    return this.projectViewer;
  }

  createLeftModelViewer() {
    const viewerEl: any = document.getElementById('autoBaseModelPanel');
    viewerEl.width = (window.innerWidth / 2) - 10;
    viewerEl.height = window.innerHeight - 100;

    const params = Object.assign({}, {
      modelOptions: {
        isAlignment: true,
        disableMovement: true,
        grid: true,
        label: 0.05,
        bgColor: '#e1e1e1',
        ...this.baselineModel.modelOptions
      }
    })
    this.leftViewer = new Alignment(viewerEl, params);
    return this.leftViewer;
  }

  createRightModelViewer() {
    const viewerEl: any = document.getElementById('autoProjectPanel');
    viewerEl.width = (window.innerWidth / 2) - 10;
    viewerEl.height = window.innerHeight - 100;
    const params = Object.assign({}, {
      modelOptions: {
        isAlignment: true,
        disableMovement: true,
        grid: true,
        label: 0.05,
        bgColor: '#e1e1e1',
        ...this.data.project.modelOptions
      }
    })
    this.rightViewer = new Alignment(viewerEl, params);
    return this.rightViewer;
  }

  handleResize = () => {
    let modelWidth = (window.innerWidth) - 10;
    let modelHeight = window.innerHeight - 100;
    /*   if (this.baselineViewer.scene.children) {
         this.baselineViewer.resize(modelHeight, modelWidth)
       }
     */
    if (this.projectViewer.scene.children) {
      this.projectViewer.resize(modelHeight, modelWidth)
    }
  }

  baselineModelSubscription: any = new Subject();
  isLoad: boolean = false;
  baselineModel: any;
  getBaselineModels() {
    this.baselineModelSubscription = this.backendService.get3DModels$(this.data.assetId).subscribe((result: any) => {
      if (result) {
        this.baselineModel = result;
        if (result.tileStatus === 'success' && !this.isLoad) {
          this.isLoad = true;
          this.projectLoad();
        }
      }
    });
  }

  leftlabelShow: boolean = false;
  getLeftModelLabels() {
    this.leftLabelSubscription = this.backendService.get3DModelLabels$(this.baselineModel?.id).subscribe((result: any) => {
      const labels = result.label || [];
      if (this.leftViewer.scene) {
        const annotations = this.leftViewer.scene.children.filter(o => o.name == 'annotations');
        if (annotations) {
          for (var i = 0; i < annotations.length; i++) {
            this.leftViewer.scene.remove(annotations[i])
          }
        }
      }
      labels.forEach(async (el, index) => {
        const canvas = await this.generalService.getCanvasLabel(index)
        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        const annotationSpriteMaterial = new THREE.SpriteMaterial({
          map: texture,
          depthTest: false,
          depthWrite: false,
          sizeAttenuation: false,
        })
        const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
        annotationSprite.scale.set(this.leftViewer.state.label, this.leftViewer.state.label, this.leftViewer.state.label)
        annotationSprite.position.copy(el.lookAt)
        annotationSprite.userData = { number: index, ...el };
        annotationSprite.name = "annotations";
        annotationSprite.visible = this.leftlabelShow;
        this.leftViewer.scene.add(annotationSprite);
      });

    });
  }

  rightlabelShow: boolean = false;
  getRightModelLabels() {
    this.rightLabelSubscription = this.backendService.get3DModelLabels$(this.baselineModel?.id).subscribe((result: any) => {
      const labels = result.label || [];
      if (this.rightViewer.scene) {
        const annotations = this.rightViewer.scene.children.filter(o => o.name == 'annotations');
        if (annotations) {
          for (var i = 0; i < annotations.length; i++) {
            this.rightViewer.scene.remove(annotations[i])
          }
        }
      }
      labels.forEach(async (el, index) => {
        const canvas = await this.generalService.getCanvasLabel(index)
        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        const annotationSpriteMaterial = new THREE.SpriteMaterial({
          map: texture,
          depthTest: false,
          depthWrite: false,
          sizeAttenuation: false,
        })
        const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
        annotationSprite.scale.set(this.rightViewer.state.label, this.rightViewer.state.label, this.rightViewer.state.label)
        annotationSprite.position.copy(el.lookAt)
        annotationSprite.userData = { number: index, ...el };
        annotationSprite.name = "annotations";
        annotationSprite.visible = this.rightlabelShow;
        this.rightViewer.scene.add(annotationSprite);
      });

    });
  }

  showRightLabels(value) {
    this.rightlabelShow = value;
    this.rightViewer.scene?.children.filter(o => o.name == "annotations")
      .map((item) => {
        return item.visible = value;
      })
  }

  showLeftLabels(value) {
    this.leftlabelShow = value;
    this.leftViewer.scene?.children.filter(o => o.name == "annotations")
      .map((item) => {
        return item.visible = value;
      })
  }

  ngOnDestroy(): void {
    this.baselineModelSubscription.unsubscribe();
    this.projectValueChangeSubscription.unsubscribe();
    this.leftLabelSubscription.unsubscribe();
    this.rightLabelSubscription.unsubscribe();

    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (this.projectViewer) {
      this.projectViewer.remove()
      document.getElementById('projectModelPanel').innerHTML = "";
    }

    if (this.leftViewer) {
      this.leftViewer.remove()
      document.getElementById('autoBaseModelPanel').innerHTML = "";
    }

    if (this.rightViewer) {
      this.rightViewer.remove()
      document.getElementById('autoProjectPanel').innerHTML = "";
    }

    window.removeEventListener('resize', this.handleResize, false)

  }

  isLoading = false;
  alignModel() {
    if (this.alignmentType === 'manual') {
      const params = {
        rotation: {
          x: this.projectViewer.options.modelOptions.rotation.x,
          y: this.projectViewer.options.modelOptions.rotation.y,
          z: this.projectViewer.options.modelOptions.rotation.z,
        },
        scale: {
          x: this.projectViewer.content.scale.x,
          y: this.projectViewer.content.scale.x,
          z: this.projectViewer.content.scale.x
        },
        translate: {
          x: this.projectViewer.content.position.x,
          y: this.projectViewer.content.position.y,
          z: this.projectViewer.content.position.z
        },
        orientation: {
          X: (THREE.MathUtils.radToDeg(this.projectViewer.pivot.rotation.x) - (this.baselineModel.modelOptions?.orientation?.X || 0))%360,
          Y: (THREE.MathUtils.radToDeg(this.projectViewer.pivot.rotation.y) - (this.baselineModel.modelOptions?.orientation?.Y || 0))%360,
          Z: (THREE.MathUtils.radToDeg(this.projectViewer.pivot.rotation.z) - (this.baselineModel.modelOptions?.orientation?.Z || 0))%360,

          
        },
       

        orientationLocked: true

      }

     
      this.backendService.updateProjectAlignment(this.uiService.selectedProjectId,
        Object.assign({}, this.projectViewer.state, params)
      ).then(() => {
        this.dialogRef.close();
        this.toastr.success("Model alignment successfully")
      })
    } else {

      let baselinePoints = [];
      let projectPoints = [];

      this.leftAnnotations.forEach(points => {
        baselinePoints.push({
          "id": uuidv4(),
          "points": {
            x: points.point.x - this.leftViewer.content.position.x,
            y: points.point.y - this.leftViewer.content.position.y,
            z: points.point.z - this.leftViewer.content.position.z
          }
        })
      });

      this.rightAnnotations.forEach((points, i) => {
        projectPoints.push({
          "id": baselinePoints[i].id,
          "points": {
            x: points.point.x - this.rightViewer.content.position.x,
            y: points.point.y - this.rightViewer.content.position.y,
            z: points.point.z - this.rightViewer.content.position.z
          }
        })
      });
      const params =
      {
        "modelId": this.baselineModel.id,
        "projectId": this.data.project.id,
        "baselinePoints": baselinePoints,
        "projectPoints": projectPoints

      }

      console.log(params);

      this.isLoading = true;
      this.backendService.alignedModel(params).pipe(take(1)).subscribe(() => { }, error => {
        this.toastr.error('Something went wrong please try again later.')
        this.isLoading = false;

      })

    }

  }

  controls: boolean = false
  toggleControls() {
    this.controls = !this.controls;
  }

  leftControls: boolean = false
  toggleLeftControls() {
    this.leftControls = !this.leftControls;

  }
  rightControls: boolean = false
  toggleRightControls() {
    this.rightControls = !this.rightControls;
  }

  lockControl() {
    if (this.projectViewer?.controls.enabled) {
      this.projectViewer.controls.enabled = false;
    } else {
      this.projectViewer.controls.enabled = true;
    }
  }

  rotationTransform(value) {
    if (value) {
      this.projectViewer.controls.enabled = false;
      this.projectViewer.transformControls.attach(this.projectViewer.content)
      this.projectViewer.transformControls.setMode('rotate')

    } else {
      this.projectViewer.transformControls.detach(this.projectViewer.content)
      this.projectViewer.controls.enabled = true;
    }
  }

  addRotation(axis) {
    this.projectViewer.options.modelOptions.rotation[axis] = this.projectViewer.options.modelOptions.rotation[axis] + .2;
    const rad = MathUtils.degToRad(this.projectViewer.options.modelOptions.rotation[axis]);
    this.projectViewer.content.rotation[axis] = rad;
    console.log(this.uiService.project.modelOptions.rotation);
  }

  substractRotation(axis) {
    this.projectViewer.options.modelOptions.rotation[axis] = this.projectViewer.options.modelOptions.rotation[axis] - .2;
    const rad = MathUtils.degToRad(this.projectViewer.options.modelOptions.rotation[axis]);
    this.projectViewer.content.rotation[axis] = rad;
  }

  translateTransform(value) {
    if (value) {
      this.projectViewer.controls.enabled = false;
      this.projectViewer.transformControls.attach(this.projectViewer.content)
      this.projectViewer.transformControls.setMode('translate')

    } else {
      this.projectViewer.transformControls.detach(this.projectViewer.content)
      this.projectViewer.controls.enabled = true;

    }
  }

  addTranslate(axis) {
    this.projectViewer.content.position[axis] = this.projectViewer.content.position[axis] + .2;
  }

  substractTranslate(axis) {
    this.projectViewer.content.position[axis] = this.projectViewer.content.position[axis] - .2;
  }


  addScale() {
    this.projectViewer.content.scale.x = this.projectViewer.content.scale.x + 0.01;
    this.projectViewer.content.scale.y = this.projectViewer.content.scale.y + 0.01;
    this.projectViewer.content.scale.z = this.projectViewer.content.scale.z + 0.01;
  }

  substractScale() {
    this.projectViewer.content.scale.x = this.projectViewer.content.scale.x - 0.01;
    this.projectViewer.content.scale.y = this.projectViewer.content.scale.y - 0.01;
    this.projectViewer.content.scale.z = this.projectViewer.content.scale.z - 0.01;
  }

  scaleRange(value) {
    const range = parseFloat(value);
    this.projectViewer.content.scale.x = range;
    this.projectViewer.content.scale.y = range;
    this.projectViewer.content.scale.z = range;
  }

  rotatioRange(axis, value) {
    const rad = MathUtils.degToRad(parseFloat(value));
    this.projectViewer.content.rotation[axis] = rad;
  }
  translateRange(axis, value) {
    this.projectViewer.content.position[axis] = parseFloat(value);
  }


  leftAnnotations = [];
  leftPoints = [];
  async leftlabels() {
    if (this.leftViewer.scene) {
      const leftPoints = this.leftViewer?.scene.children.filter(o => o.name == 'annotations_circle' || o.name === 'annotations_label');
      if (leftPoints) {
        for (var i = 0; i < leftPoints.length; i++) {
          this.leftViewer.scene.remove(leftPoints[i])
        }
      }
      this.leftAnnotations.forEach(async (label, i) => {
        const canvas = await this.generalService.getBackground("#ff0000")
        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        const annotationSpriteMaterial = new THREE.SpriteMaterial({
          map: texture,
          depthTest: true,
          side: THREE.DoubleSide,
          depthWrite: true,
          sizeAttenuation: false
        })
        const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
        annotationSprite.scale.set(this.leftViewer.state.label / 2, this.leftViewer.state.label / 2, this.leftViewer.state.label / 2)
        const id = uuidv4();
        annotationSprite.position.copy(label.point);
        annotationSprite.name = "annotations_circle";
        annotationSprite.userData = { id: label.id, type: 'circle' };
        this.leftPoints.push(annotationSprite);
        this.leftViewer.scene.add(annotationSprite)

        const canvasLabel = await this.generalService.getCanvasLabel(i, 'transparent')
        var lblTexture = new THREE.Texture(canvasLabel);
        lblTexture.needsUpdate = true;
        var annotationSpriteLabel = new THREE.SpriteMaterial({
          map: lblTexture,
          depthTest: false,
          side: THREE.FrontSide,
          depthWrite: false,
          sizeAttenuation: false
        })
        const annotationlabelSprite = new THREE.Sprite(annotationSpriteLabel)
        annotationlabelSprite.userData = { id: label.id, type: 'label' };
        annotationlabelSprite.scale.set(this.leftViewer.state.label, this.leftViewer.state.label, this.leftViewer.state.label)
        annotationlabelSprite.position.copy(label.point);
        annotationlabelSprite.name = "annotations_label";
        this.leftPoints.push(annotationSprite);
        this.leftViewer.scene.add(annotationlabelSprite)

      });
    }
  }

  rightAnnotations = [];
  rightPoints = [];
  async rightlabels() {
    if (this.rightViewer.scene) {
      const rightPoints = this.rightViewer?.scene.children.filter(o => o.name == 'annotations_circle' || o.name === 'annotations_label');
      if (rightPoints) {
        for (var i = 0; i < rightPoints.length; i++) {
          this.rightViewer.scene.remove(rightPoints[i])
        }
      }
      this.rightAnnotations.forEach(async (label, i) => {
        const canvas = await this.generalService.getBackground("#ff0000")
        var texture = new THREE.Texture(canvas);
        texture.needsUpdate = true;
        const annotationSpriteMaterial = new THREE.SpriteMaterial({
          map: texture,
          depthTest: true,
          side: THREE.DoubleSide,
          depthWrite: true,
          sizeAttenuation: false
        })
        const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
        annotationSprite.scale.set(this.rightViewer.state.label / 2, this.rightViewer.state.label / 2, this.rightViewer.state.label / 2)
        const id = uuidv4();
        annotationSprite.position.copy(label.point);
        annotationSprite.name = "annotations_circle";
        annotationSprite.userData = { id: label.id, type: 'circle' };
        this.rightPoints.push(annotationSprite);
        this.rightViewer.scene.add(annotationSprite)

        const canvasLabel = await this.generalService.getCanvasLabel(i, 'transparent')
        var lblTexture = new THREE.Texture(canvasLabel);
        lblTexture.needsUpdate = true;
        var annotationSpriteLabel = new THREE.SpriteMaterial({
          map: lblTexture,
          depthTest: false,
          side: THREE.FrontSide,
          depthWrite: false,
          sizeAttenuation: false
        })
        const annotationlabelSprite = new THREE.Sprite(annotationSpriteLabel)
        annotationlabelSprite.userData = { id: label.id, type: 'label' };
        annotationlabelSprite.scale.set(this.rightViewer.state.label, this.rightViewer.state.label, this.rightViewer.state.label)
        annotationlabelSprite.position.copy(label.point);
        annotationlabelSprite.name = "annotations_label";
        this.rightPoints.push(annotationSprite);
        this.rightViewer.scene.add(annotationlabelSprite)

      });
    }
  }

  resetPoints() {
    const rightPoints = this.rightViewer?.scene.children.filter(o => o.name == 'annotations_circle' || o.name === 'annotations_label');
    if (rightPoints) {
      for (var i = 0; i < rightPoints.length; i++) {
        this.rightViewer.scene.remove(rightPoints[i])
      }
    }
    const leftPoints = this.leftViewer?.scene.children.filter(o => o.name == 'annotations_circle' || o.name === 'annotations_label');
    if (leftPoints) {
      for (var i = 0; i < leftPoints.length; i++) {
        this.leftViewer.scene.remove(leftPoints[i])
      }
    }

    this.leftAnnotations = [];
    this.rightAnnotations = [];
  }


  onControlsValChange(event) {
    this.leftViewer?.changeControl(event);
    this.rightViewer?.changeControl(event);
  }

}