import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { BackendService } from '../services/backend.service';
import * as moment from 'moment';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-history-bottom-sheet',
  templateUrl: './history-bottom-sheet.component.html',
  styleUrls: ['./history-bottom-sheet.component.scss']
})
export class HistoryBottomSheetComponent {
  imageId: string;
  projectId: string;
  imageHistory = [];
  private onDestroy$ = new Subject();
  public memberSubscription = new Subscription();
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  constructor(public backendService: BackendService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private _bottomSheetRef: MatBottomSheetRef<HistoryBottomSheetComponent>
  ) {
    if (data) {
      this.imageId = data.imageId;
      this.projectId = data.projectId;
      this.getMembers();
    }
  }

  ngOnInit(): void {
  }

  getMembers(): any {
    this.memberSubscription.unsubscribe();
    this.loading$.next(true);
    this.memberSubscription = this.backendService.getProject(this.projectId).subscribe((membersDoc) => {
      if (!membersDoc) return of();
      return combineLatest(
        this.backendService.getUsers(Object.keys(membersDoc.people || {})),
        this.backendService.getProspective(this.projectId)
      ).pipe(takeUntil(this.onDestroy$)).subscribe(([members, prospectiveMembers]) => {
        this.backendService.getHistory(this.imageId).subscribe(data => {
          const sortArray= data.docs.sort(function(a,b){
            return b.data().date.toDate()- a.data().date.toDate()
          })
          sortArray.forEach(doc => {
            const imageData =doc.data();
            this.imageHistory.push({
              date:moment(imageData.date.toDate()).format('DD-MM-YYYY HH:mm A'),
              actionBy: this.getUserName(imageData.userId,members,prospectiveMembers),
              action: imageData.action
            })
          });
          this.loading$.next(false);
        });
      });
    });
  }

  getUserName(id,members,prospectiveMembers){
   let user= members.find(o=>o.uid==id);
   let prospectiveUser= members.find(o=>o._id==id);
   if(user){
    return (user.firstName && user.lastName) ? user.firstName + " "+ user.lastName: user.displayName;
   }else if(prospectiveUser){
    return prospectiveUser.email;
   }else{
    return "";
   }
  }

  ngOnDestroy(){
    this.memberSubscription.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
