<div class="popup-header w-100 d-flex">
  <p class="w-100">
    IMAGE CONTEXTS
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<mat-dialog-content class="bg" style="overflow: auto;">
  <div 
    *ngIf="data.type !== 'report'" 
    class="text-right mb-3 text-danger" 
    (click)="deleteAllRelations()" 
    style="cursor: pointer;"
    [class.disabled]="getReadOnlyForCurrentUser()">
    Delete all
  </div>
  <button *ngIf="data.type === 'report' && (relations$ | async).length" 
      style="margin-right: 20px"
      mat-raised-button
      color="primary"
      class="mb-3" 
      type="button"
      [disabled]="!filterCheckedArray((relations$ | async)).length"
      (click)="addRelationsToReport()"
      >
      <span>Add</span>
    </button>

    <div infiniteScroll
       [infiniteScrollDistance]="4"
       [infiniteScrollThrottle]="10"
       [scrollWindow]="false"
       (scrolled)="onScroll()"
       style="height: calc(100vh - 150px); overflow-y: auto;"
  >
    <div class="image-relation position-relative" *ngFor="let relation of (relations$ | async); let idx = index;">
      <p>Relation name</p>
      <div class="d-flex align-items-center scroller bg p-1">
        <mat-checkbox *ngIf="data.type === 'report'" (change)="relation.isChecked = $event.checked" class="mr-2"></mat-checkbox>
        <div class="image-wrapper">
          <div
            class="img-placeholder"
            style="width: 192px; height: 115px; object-fit: cover"
          >
            <img *ngIf="imagesThumbs[relation.parentImagePath]"
            [src]="imagesThumbs[relation.parentImagePath]" alt="Image" class="img-placeholder"
              style="width: 100%; height: 100%; object-fit: contain" />
          </div>
        </div>

        <div class="image-wrapper align-self-end ml-2">
          <div class="img-placeholder">
            <img *ngIf="imagesThumbs[relation.childImagePath]" 
              [src]="imagesThumbs[relation.childImagePath]" alt="Image" class="img-placeholder"
              style="width: 100%; height: 100%; object-fit: contain" />
          </div>
          <!-- <button mat-icon-button class="edit-button">
            <mat-icon svgIcon="pencil_outline"></mat-icon>
          </button> -->
        </div>

        <mat-icon *ngIf="data.type !== 'report'" (click)="deleteRelation(relation)"
          style="
            cursor: pointer;
            position: absolute;
            bottom: 10px;
            right: 10px;
            color: #5f6769;
          "
          svgIcon="trash"
          [class.disabled]="getReadOnlyForCurrentUser()"
        ></mat-icon>
      </div>
    </div>
  </div>

</mat-dialog-content>
