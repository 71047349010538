<router-outlet></router-outlet>
 <!-- uppy global div  -->
 <div class="DashboardContainer"></div>
 
 <div *ngIf="noInternetConnection" class="overlay">
    <div class="overlay-text">
        <mat-icon>network_check
        </mat-icon>
       <p>
        Please Check Your Internet Connection
    </p>
  
    </div>
  </div>