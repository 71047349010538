import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BackendService } from 'src/app/services/backend.service';
import { UiService } from 'src/app/services/ui.service';
import { GeneralService } from 'src/app/services/general.service';
import { IGroup } from '../../models/group.interface';
import { takeUntil } from 'rxjs/operators';
import { ReportGroupComponent } from '../report-group/report-group.component';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
  onDestroy$ = new Subject();
  tags = this.data.tags || [];
  addGroup: boolean = false;
  groupName: string;
  isLoading: boolean = false;
  groups: IGroup[];
  constructor(
    public dialogRef: MatDialogRef<FilterComponent>,
    public dialog: MatDialog,
    public uiService: UiService,
    private generalService: GeneralService,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  public get selectedTag() {
    return this.uiService.selectedTag;
  }

  public set selectedTag(tag) {
    this.uiService.selectedTag = tag;
  }

  public get sensitiveTag() {
    return this.uiService.sensitiveTag;
  }

  public set sensitiveTag(tag) {
    this.uiService.sensitiveTag = tag;
  }
  public get groups$() {
    return this.uiService.groups$;
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0' });
    this.getGroups();
  }

  getGroups() {
    this.uiService.groups$.pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      this.groups = groups;
    })
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


  tagClick(tag, event) {
    if (event.target.className.indexOf('sensitivity') != -1) {
      this.selectedTag = tag.tag;
      this.sensitiveTag = null;
      this.tags.forEach((e) => {
        if (e.tag != this.selectedTag) {
          e.isOpen = false;
        }
      });
      this.uiService.tagSelectedEvent$.next();
      tag.isOpen = !tag.isOpen;
    }
    else if (event.target.className.indexOf('levels') != -1) {
      this.severityTags(parseInt(event.target.id));
    }
    else {
      this.selectTag(tag.tag)
    }
  }

  close() {
    this.tags.forEach((tag) => {
      tag.isOpen = false;
    });
    this.sensitiveTag = null;
  }

  severityTags(type: number) {
    this.sensitiveTag = type;
    this.uiService.tagSelectedEvent$.next();
  }

  selectTag(tag): void {
    this.selectedTag = tag;
    const containerEl = document.querySelector('.infinite-scroll-container');
    if (containerEl) {
      containerEl.scrollTop = 0;
    }
    this.close();
    this.uiService.tagSelectedEvent$.next();
  }

  selectTab(event): void {
    const tab: 'features' | 'groups' = event.tab.textLabel.toLowerCase();
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  toggleGroup() {
    this.addGroup = !this.addGroup;
    this.groupName = "";
  }

  isNameValid(name): boolean {
    return this.groups.map(o => o.groupName).indexOf(name) === -1;
  }

  isEditNameValid(group, name): boolean {
    return this.groups.filter(o => o.id != group.id).map(o => o.groupName).indexOf(name) === -1;
  }

  handleAddGroup() {
    this.isLoading = true;
    if (this.groupName && this.isNameValid(this.groupName)) {
      this.backendService.
        addImageGroup(this.groupName, this.data.projectId)
        .subscribe((result) => {
          this.isLoading = false;
          this.getGroups();
          this.toggleGroup();
        }, error => {
          this.isLoading = false;
        });
    }

  }

  handleEditGroup(group) {
    this.isLoading = true;
    this.backendService.
      updateImageGroup(group.id, this.groupName, this.data.projectId)
      .subscribe((result) => {
        this.isLoading = false;
        this.getGroups();
      }, error => {
        this.isLoading = false;
      });
  }

  private deleteGroup(group: any): void {
    this.backendService.removeImageGroup(group.id, this.data.projectId).subscribe();
    this.groups = this.groups.filter(item => group.id !== item.id);
  }

  selectGroup(group, event) {
    if (event.target.id === 'delete') {
      this.deleteGroup(group);
    }
    else if (event.target.id === 'edit') {
      this.addGroup = false;
      group.isEdit = true;
      this.groupName = group.groupName;
    }
    else if (event.target.id === 'add') {
      const dialogRef = this.dialog.open(ReportGroupComponent, {
        width: '80vw',
        height: '100vh',
        data: {
          projectId: this.data.projectId,
          group: group
        }
      });
      dialogRef.beforeClosed().pipe(takeUntil(this.onDestroy$)).pipe().
        subscribe(data => {
          this.getGroups();
        })
    }
    else {
      // Selected Group
      this.selectedTag ='Group';
      this.uiService.selectedGroupImages = group.images || [];
      this.uiService.groupSelectedEvent$.next();
    }

  }

  getFilterTagCount(tag) {
    switch (tag) {
      case 'Tagged':
        return `(${this.uiService.images.filter((image) => image.tags && image.tags.length).length})`;
      case 'Hotspots':
        return `(${this.uiService.images.filter((image) => (image.hotspots && image.hotspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === true).length > 0)

        ).length})`;
      case 'Coldspots':
        return `(${this.uiService.images.filter((image) => (image.coldspots && image.coldspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === false).length > 0)
        ).length})`;
    }
  }
}
