import { ProjectDetailComponent } from './project-detail/project-detail.component';
import { TaggingComponent } from './tagging/tagging.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedOutGuard } from './logged-out.guard';

import { LandingComponent } from './landing/landing.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectsComponent } from './projects/projects.component';
import { ProjectComponent } from './project/project.component';
import { MembersComponent } from './members/members.component';
import { TagsComponent } from './tags/tags.component';
import { LoggedInGuard } from './logged-in.guard';
import { ReportsComponent } from './reports/reports.component';
import { ActionHandlerComponent } from './action-handler/action-handler.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { CompareImagesComponent } from './compare-images/compare-images.component';
import { BaseModelComponent } from './base-model/base-model.component';
import { TermsComponent } from './terms/terms.component';
import { AssetFormComponent } from './assets/asset-form/asset-form.component';
//import { AssetSummaryComponent } from './asset-summary/asset-summary.component';
const routes: Routes = [
  {
    path: '',
    component: SigninComponent,
    canActivate: [LoggedOutGuard]
  },
  {
    path: 'signin',
    component: SigninComponent,
    canActivate: [LoggedOutGuard],
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [LoggedOutGuard],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [LoggedOutGuard],
  },
  {
    path: 'action',
    component: ActionHandlerComponent
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: '',
        redirectTo: 'projects',
        pathMatch: 'full',
      },
      {
        path: 'projects',
        component: ProjectsComponent,
        pathMatch: 'full',
      },
      // {
      //   path: 'summary',
      //   component: AssetSummaryComponent,
      //   pathMatch: 'full',
      // },

      {
        path: 'projects/:projectId',
        component: ProjectComponent,
        children: [
          { path: '', pathMatch: 'full', component: ProjectDetailComponent },
          {
            path: 'images/:imageId',
            component: TaggingComponent,
          },
        ],
      },
      {
        path: 'members',
        component: MembersComponent,
      },
      {
        path: 'tags',
        component: TagsComponent,
      },
      {
        path: 'reports',
        component: ReportsComponent,
      },
      {
        path: 'user-profile',
        component: UserProfileComponent,
      },
      {
        path: 'asset/:assetId/project/:projectId',
        component: CompareImagesComponent,
        pathMatch: 'full',
      },
      {
        path: 'asset/:assetId',
        component: CompareImagesComponent,
        pathMatch: 'full',
      },
      {
        path:'assets',
        component:AssetFormComponent
      },
      {
        path: 'view/:assetId',
        component: BaseModelComponent,
        pathMatch: 'full',
      },

    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
