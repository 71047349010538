import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-color-plate',
  templateUrl: './color-plate.component.html',
  styleUrls: ['./color-plate.component.scss']
})
export class ColorPlateComponent implements OnInit {

  formatLabel(value: number) {
    return value + '°';
  }
  
  constructor(
    public uiService:UiService, public dialogRef: MatDialogRef<ColorPlateComponent>) { }

  ngOnInit(): void {
  }
  
  palette(colorPalette) {
    this.dialogRef.close({
      colorPalette: colorPalette
    })
  }
}
