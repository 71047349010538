<div class="flex flex-wrap justify-content-center" [style.flexGrow]="!(loading$ | async) ? '1' : '0'">
    <ng-container *ngIf="(!(loading$ | async)); else loading">
        <ng-container *ngIf="imageHistory.length">
            <div style="width: 800px;height:500px">

                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" class="text-uppercase">Date</th>
                            <th scope="col" class="text-uppercase">Action By</th>
                            <th scope="col" class="text-uppercase">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let history of imageHistory">
                            <td>{{ history.date }}</td>
                            <td>
                                {{ history.actionBy }}
                            </td>
                            <td>
                                {{ history.action }}
                            </td>

                        </tr>
                    </tbody>
                </table>
               
            </div>
        </ng-container>
        <ng-container *ngIf="imageHistory.length == 0">
            <div class="d-flex justify-content-center align-items-center flex-column h-100">
                No History
            </div>
        </ng-container>

    </ng-container>
    <ng-template #loading>
        <div class="h-100 w-100 flex align-center align-middle">
            <mat-spinner [diameter]="50"></mat-spinner>
        </div>
    </ng-template>
</div>