import { Component, ViewChild, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { BackendService } from '../services/backend.service';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { UiService } from '../services/ui.service';
import { IImageGroupDialogData, ImageGroupDialogComponent } from '../image-group-dialog/image-group-dialog.component';
import { ITemplate } from '../models/template.interface';
import { ImageTitlesDialogComponent } from '../image-titles-dialog/image-titles-dialog.component';
import { IGroup } from '../models/group.interface';
import { ITreeNode, TreeNode, TreeNodeType } from '../models/tree-node.interface';
import { IImage } from '../models/image.interface';
import { ImageTitleImageData, ImageTitleImageDialogComponent } from '../image-title-image-dialog/image-title-image-dialog.component';
import { IInitialsData, InitialsDataModel } from '../models/initials-data.interface';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { GeneralService } from '../services/general.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ImageTimelineDialogComponent } from '../image-timeline-dialog/image-timeline-dialog.component';
import { AssetType } from '../models/app.enum';
AssetType
export interface DropInfo {
  targetId: string;
  action?: DropInfoAction;
}
export enum DropInfoAction {
  Before,
  After,
  Inside,
}

export interface IReportEditorData {
  name: string;
  nodes: ITreeNode[];
  id?: string;
  projectId?: string;
  templateId?: string;
  selectedTabIdx: number;
  initialsData?: IInitialsData;
  userInitials?: IInitialsData[];
  freeze: boolean;
  userUid: string;
  projects?: any[];
  isProjectScope?: boolean;
  isFeatures?: boolean;
  isCertificate?: boolean;
  isDocuments?: boolean;
  isMapScreen?: boolean;
  projectUsers?: any[];
  imageBorder?: boolean;
  columnLayout?: string;
}

@Component({
  selector: 'app-report-editor-dialog',
  templateUrl: './report-editor-dialog.component.html',
  styleUrls: ['./report-editor-dialog.component.scss'],
})
export class ReportEditorDialogComponent implements OnInit, OnDestroy {
  @ViewChild('baseModel') baseModelDialog: any;
  nodes: ITreeNode[] = [];
  public currentHeading = 'Add heading here';
  public name = '';
  public editMode = false;
  public spinner = false;
  public isLoading = false;
  // ids for connected drop lists
  public dropTargetIds = [];
  private nodeLookup = {};
  private dropActionTodo: DropInfo = null;

  //public templates$: Observable<ITemplate[]> = this.backendService.getTemplates();

  public isUpdateInitialsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  projectInitialSubscribe;
  public projectInitials: IInitialsData[] = [];
  isInitialsListView = true;
  projectImageGroupSub;
  projects = [];
  project;
  public asset;
  public initialsForm = new FormGroup({
    projectName: new FormControl(''),
    contractNumber: new FormControl(''),
    projectNumber: new FormControl(''),
    documentNumber: new FormControl(''),
    author: new FormControl(''),
    date: new FormControl(''),
    revision: new FormControl(''),
    approver: new FormControl(''),
    checker: new FormControl(''),
    clientLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    yourLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    id: new FormControl('')
  });
  public summary;
  public tags;
  certificates = [];
  documents = [];
  get clientLogo(): AbstractControl { return this.initialsForm.controls.clientLogo; }
  get yourLogo(): AbstractControl { return this.initialsForm.controls.yourLogo; }
  get projectName(): AbstractControl { return this.initialsForm.controls.projectName; }
  get contractNumber(): AbstractControl { return this.initialsForm.controls.contractNumber; }
  get projectNumber(): AbstractControl { return this.initialsForm.controls.projectNumber; }
  get documentNumber(): AbstractControl { return this.initialsForm.controls.documentNumber; }
  get author(): AbstractControl { return this.initialsForm.controls.author; }
  get date(): AbstractControl { return this.initialsForm.controls.date; }
  get revision(): AbstractControl { return this.initialsForm.controls.revision; }
  get approver(): AbstractControl { return this.initialsForm.controls.approver; }
  get checker(): AbstractControl { return this.initialsForm.controls.checker; }

  private onDestroy$ = new Subject();
  public gridColumn: string = "two";
  public imageBorder: boolean = false;
  public isProjectScope: boolean = false;
  public isFeatures: boolean = false;
  public projectScope: string = '';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IReportEditorData,
    public dialog: MatDialog,
    public backendService: BackendService,
    public dialogRef: MatDialogRef<ReportEditorDialogComponent>,
    private uiService: UiService,
    @Inject(DOCUMENT) private document: Document,
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    console.log({ tab: this.data.selectedTabIdx })
    this.dialogRef.updatePosition({ top: '0', right: '0' });
    this.isLoading = true;
    if (this.data.id !== null && this.data.id !== undefined) {
      this.editMode = true;
    }

    const project = this.data.projects.find(o => o.id == this.data.projectId);
    if (project) {
      this.project = project;
      this.getTags(project);
      this.projectScope = this.data.projects.find(o => o.id === this.data.projectId).projectScope || '';

    }

  }

  getTags(project): any {
    return combineLatest(
      this.backendService.getSummary(this.data.projectId),
      this.backendService.getTags(this.data.projectId),
      this.backendService.getProjectDocuments(this.data.projectId)
    ).pipe(takeUntil(this.onDestroy$)).subscribe(([summary, { tags }, documents]) => {
      this.certificates = documents.filter(o => o.isCertificate)
      this.documents = documents.filter(o => !o.isCertificate);
      this.summary = summary;
      this.tags = tags.sort((tag1, tag2) => tag1.tag.localeCompare(tag2.tag))
        .map(tag => {
          return {
            ...tag
          };
        });
      if (project.assetId) {
        this.asset = this.uiService.allAssets.find(o => o.id == project.assetId);
        this.getImageLabels(this.asset);
        if (!this.asset.baseModel) {
          this.restoreData();
        }
      } else {
        this.restoreData();
      }
    });

  }

  restoreData() {
    // Restore all the nodes from latest DB Entries
    if (this.data.nodes) {
      this.nodes = this.data.nodes;
      this.data.nodes.forEach((node, index) => {
        if (node.type == TreeNodeType.Features) {
          this.isFeatures = true;
        }
        this.data.isCertificate = node.isCertificates ? true : this.data.isCertificate;
        this.data.isDocuments = node.isDocuments ? true : this.data.isDocuments;
        this.data.isMapScreen = node.isMap ? true : this.data.isMapScreen;
        if (!node.isModelType) {
          node.children.forEach((child, childIndex) => {
            if (child.type == 'image') {
              const subs = this.backendService.getImageAnnotations(child.data.imageId).pipe(takeUntil(this.onDestroy$)).subscribe((response: any) => {
                subs.unsubscribe();
                const polygons = response && response.polygons ? response.polygons : [];
                child.polygons = polygons;
                child.data.narration = this.getNarration(node.groupId, response)
                child.data.labels = this.onlyUniqueLabels(this.linkLabelImages).filter(o => o.images.includes(child.data.imageId)).map(o => o.label?.title).join()
                if (response && response.projectId) {
                  this.backendService.getImageGroup(response.projectId, child.data.imageId).subscribe((resp: any) => {
                    this.nodeLookup[child.id] = Object.assign({}, this.nodeLookup[child.id], { groupImages: resp.docs.length });
                  })
                }

              })
            }
            if (child.type == 'relation') {
              const contextId = child.data.childImagePath.split('images/')[1];
              const subs = this.backendService.getImageAnnotations(contextId).pipe(takeUntil(this.onDestroy$)).subscribe((response: any) => {
                subs.unsubscribe();
                child.data.narration = this.getNarration(node.groupId, response)
                child.data.labels = this.onlyUniqueLabels(this.linkLabelImages).filter(o => o.images.includes(contextId)).map(o => o.label?.title).join()

              })
            }
            if (child.type == 'timeline') {
              const subs = combineLatest(
                this.backendService.getImageAnnotations(child.keyImageId),
                this.backendService.getImageAnnotations(child.valueImageId)
              ).pipe(takeUntil(this.onDestroy$)).subscribe(([keyImage, valueImage]: any) => {
                subs.unsubscribe();
                const keyImageAnnotations = keyImage ? keyImage.polygons : [];
                const valueImageAnnotations = valueImage ? valueImage.polygons : [];
                this.nodeLookup[child.id] = Object.assign(this.nodeLookup[child.id], { keyImageAnnotations: keyImageAnnotations, valueImageAnnotations: valueImageAnnotations });
              })
            }
            if (child.type == TreeNodeType.FINDINGS_TBL) {
              child.data.tags = this.tags?.map(function (item) {
                return {
                  tag: item["tag"],
                  description: item['description'] || ''
                }
              });
              child.data.summary = this.summary['features']
            }
          })
        }
      });
    }

    if (this.data.isProjectScope) {
      this.isProjectScope = true;
    }

    if (this.data.columnLayout) {
      this.gridColumn = this.data.columnLayout;
    }
    if (this.data.imageBorder) {
      this.imageBorder = this.data.imageBorder;
    }

    this.getProjectInitials(this.data.projectId)
    this.name = this.data.name;
    this.prepareDragDrop(this.nodes);
    this.isLoading = false;

  }

  gridView() {
    if (this.gridColumn == 'two') {
      this.gridColumn = 'one';
    } else {
      this.gridColumn = 'two';

    }
  }
  toggleBorder() {
    this.imageBorder = !this.imageBorder;
  }
  getGridLi() {
    if (this.gridColumn != 'two') {
      return {
        display: 'flex'
      }
    }
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.projectInitialSubscribe.unsubscribe();

  }

  imagesPreview(event, form: AbstractControl): void {
    if (event.target.files && event.target.files[0]) {
      let imageFile;
      const reader = new FileReader();

      reader.onload = (_event: any) => {
        imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
        form.setValue(imageFile);

      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  findAndRemoveNode(id: string, list: ITreeNode[]): void {
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < list.length; i++) {
      if (list[i].id === id) {
        list = list.splice(i, 1);
      } else {
        this.findAndRemoveNode(id, list[i].children);
      }
    }
  }

  prepareDragDrop(nodes: ITreeNode[]): void {
    nodes.forEach((node) => {
      this.dropTargetIds.push(node.id);
      this.nodeLookup[node.id] = node;
      this.prepareDragDrop(node.children);
    });
  }

  addText(id: string): void {
    const node = Object.assign({}, new TreeNode(TreeNodeType.Text, '', null, null, false));
    this.dropTargetIds.push(node.id);
    // this.findNode(id, this.nodes).children.push(node);
    this.nodeLookup[id].children.push(node);
    this.nodeLookup[node.id] = node;
    this.nodeLookup[id].isExpanded = true;
  }

  addImages(id: string): void {
    const nodeImagesIds = this.nodeLookup[id].children.filter(item => item.type === 'image').map(item => item.data.imageId)
    const data: ImageTitleImageData = {
      projectId: this.data.projectId,
      nodeImagesIds
    };
    const dialogRef = this.dialog.open(ImageTitleImageDialogComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r && !r.selectedImages) {
        return;
      }

      if (r) {
        r.selectedImages.filter(this.generalService.onlyUnique).forEach(imageId => {
          this.backendService.getImage$<any>(imageId).subscribe((image: IImage) => {
            if (image.deleted != true) {
              if (!this.nodeLookup[id].children.map(o => o.data).find(o => o.imageId == imageId)) {
                this.addImageToNode(id, image, imageId, "");
              }
            }

          });


        });
      }
    });
  }

  getNarration(groupId, annotations) {
    if (annotations) {
      if (!groupId) {
        return annotations.narration || "";
      } else {
        return annotations.groupNarration?.find(group => group.groupId == groupId)?.narration || "";
      }
    }
    return "";
  }

  private addImageToNode(nodeId: string, image: IImage, imageId: string, groupId: string): void {
    this.backendService.getImageAnnotations(imageId).subscribe((response: any) => {
      const polygons = response && response.polygons ? response.polygons : [];
      const node = Object.assign({}, new TreeNode(TreeNodeType.Image, {
        fileUrl: image.mediumFileUrl, fileName: image.fileName, imageId, narration: this.getNarration(groupId, response)
        , labels: this.onlyUniqueLabels(this.linkLabelImages).filter(o => o.images.includes(imageId)).map(o => o.label?.title).join()
      }, 0, polygons));
      this.dropTargetIds.push(node.id);
      this.nodeLookup[nodeId].children.push(node);
      this.nodeLookup[node.id] = node;
      this.nodeLookup[nodeId].isExpanded = true;
      this.backendService.getImageGroup(image.projectId, imageId).subscribe((resp: any) => {
        this.nodeLookup[node.id] = Object.assign({}, this.nodeLookup[node.id], { groupImages: resp.docs.length });
      })
    })
  }

  /*@debounce(50)
  dragMoved(event, isContents: boolean = false): void {
    const e = this.document.elementFromPoint(
      event.pointerPosition.x,
      event.pointerPosition.y
    );

    if (!e) {
      this.clearDragInfo();
      return;
    }
    const container: Element = e.classList.contains('node-item') ? e : e.closest('.node-item');

    if (!container) {
      this.clearDragInfo();
      return;
    }
    this.dropActionTodo = {
      targetId: container.getAttribute('data-id'),
    };

    const targetRect = container.getBoundingClientRect();
    const oneThird = targetRect.height / 3;

    if (event.pointerPosition.y - targetRect.top < oneThird) {
      // before
      this.dropActionTodo.action = DropInfoAction.Before;
    } else if (event.pointerPosition.y - targetRect.top > 2 * oneThird) {
      // after
      this.dropActionTodo.action = DropInfoAction.After;
    } else {
      // inside
      this.dropActionTodo.action = DropInfoAction.Inside;
    }
    this.showDragInfo(isContents);
  }

  drop(event, isContents: boolean = false): void {
    if (!this.dropActionTodo) {
      return;
    }
    const parentId = isContents ? 'main-contents' : 'main';

    const draggedItemId = event.item.data;
    const parentItemId = event.previousContainer.id;
    const targetListId = this.getParentNodeId(
      this.dropActionTodo.targetId,
      this.nodes,
      parentId
    );


    console.log(
      '\nmoving\n[' + draggedItemId + '] from list [' + parentItemId + ']',
      '\n[' +
      this.dropActionTodo.action +
      ']\n[' +
      this.dropActionTodo.targetId +
      '] from list [' +
      targetListId +
      ']'
    );

    if ((targetListId === 'main' && this.nodes.length === 1)
      ||
      (parentItemId === 'main' && targetListId != 'main')
      ||
      (parentItemId != targetListId
        && parentItemId != 'main')
    ) {
      return;
    }


    // if (parentItemId != targetListId){return;}

    if (
      this.dropActionTodo.action === DropInfoAction.Inside &&
      (this.nodeLookup[this.dropActionTodo.targetId].type !== TreeNodeType.Heading ||
        this.nodeLookup[draggedItemId].type === TreeNodeType.Heading)
    ) {
      return;
    }
    const draggedItem = this.nodeLookup[draggedItemId];
    const oldItemContainer =
      parentItemId !== parentId && this.nodeLookup[parentItemId]
        ? this.nodeLookup[parentItemId].children
        : this.nodes;
    const newContainer =
      targetListId !== parentId && this.nodeLookup[targetListId]
        ? this.nodeLookup[targetListId].children
        : this.nodes;

    const i = oldItemContainer.findIndex((c) => c.id === draggedItemId);
    oldItemContainer.splice(i, 1);

    switch (this.dropActionTodo.action) {
      case DropInfoAction.Before:
      case DropInfoAction.After:
        const targetIndex = newContainer.findIndex(
          (c) => c.id === this.dropActionTodo.targetId
        );
        if (this.dropActionTodo.action === DropInfoAction.Before) {
          newContainer.splice(targetIndex, 0, draggedItem);
        } else {
          newContainer.splice(targetIndex + 1, 0, draggedItem);
        }
        break;

      case DropInfoAction.Inside:
        this.nodeLookup[this.dropActionTodo.targetId].children.push(
          draggedItem
        );
        this.nodeLookup[this.dropActionTodo.targetId].isExpanded = true;
        break;
    }
    this.clearDragInfo(true);
  }

  getParentNodeId(
    id: string,
    nodesToSearch: ITreeNode[],
    parentId: string
  ): string {
    for (const node of nodesToSearch) {
      if (node.id === id) {
        return parentId;
      }
      const ret = this.getParentNodeId(id, node.children, node.id);
      if (ret) {
        return ret;
      }
    }
    return null;
  }

  showDragInfo(isContent: boolean): void {
    this.clearDragInfo();
    const elementId = `node-${isContent ? 'contents-' : ''}${this.dropActionTodo.targetId}`;
    if (this.dropActionTodo) {
      this.document
        .getElementById(elementId)
        .classList.add('drop-' + this.dropActionTodo.action);
    }
  }

  clearDragInfo(dropped = false): void {
    if (dropped) {
      this.dropActionTodo = null;
    }
    this.document
      .querySelectorAll('.drop-before')
      .forEach((element) => element.classList.remove('drop-before'));
    this.document
      .querySelectorAll('.drop-after')
      .forEach((element) => element.classList.remove('drop-after'));
    this.document
      .querySelectorAll('.drop-inside')
      .forEach((element) => element.classList.remove('drop-inside'));
  }
*/

  textBorder(isBorder, id) {
    this.nodeLookup[id].textBorder = isBorder;
  }

  addHeading(group?): string {
    const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, group?.groupName || this.currentHeading, 1), { groupId: group?.id || "" });
    this.dropTargetIds = [node.id, ...this.dropTargetIds];
    this.nodes.push(node);
    this.nodeLookup[node.id] = node;
    return node.id;
  }

  makeReport(isUpdateInitials: boolean = false): void {
    this.spinner = true;
    let initialsData: IInitialsData = this.initialsForm.value;
    if (initialsData && initialsData.date) {
      initialsData.date = new Date(initialsData.date).toISOString();
    }
    combineLatest([
      this.backendService.saveLogos(initialsData.clientLogo),
      this.backendService.saveLogos(initialsData.yourLogo)
    ]).pipe(switchMap(([clientLogo, yourLogo]) => {
      if (clientLogo) {
        initialsData.clientLogo = clientLogo;
      }
      if (yourLogo) {
        initialsData.yourLogo = yourLogo;
      }
      if (this.data.initialsData) {
        initialsData = this.data.initialsData;
      }
      const reportData = {
        name: this.name,
        nodes: this.nodes,
        projectId: this.data.projectId,
        isProjectScope: this.isProjectScope,
        isFeatures: this.isFeatures,
        projectScope: this.projectScope,
        reportInitiated: new Date().toISOString(),
        templateId: this.data.templateId,
        columnLayout: this.gridColumn,
        imageBorder: this.imageBorder,
        initialsData: {}
      };
      if (this.editMode) {
        this.uiService.updateReportEvent$.next(this.data.id);
        if (this.data.freeze || !this.isOwner(this.data.userUid)) {
          return of(true);
        }
        return this.backendService.editReport(
          this.data.id, reportData, initialsData, 'in-progress')
      } else {
        return this.backendService.createReport(
          reportData, initialsData, 'in-progress'
        );
      }
    }),
      // tap((result) => {
      //   // if (isUpdateInitials) {
      //     const id = this.editMode ? this.data.id : result.id;
      //     this.saveInitialsToUser$(initialsData, id).subscribe();
      //   // }
      // }),
      takeUntil(this.onDestroy$)
    ).subscribe((result) => {
      const id = this.editMode ? this.data.id : result.id;
      this.dialogRef.close();
      this.spinner = false;
      this.uiService.generateReportEvent$.next(id);
    });
  }


  saveReport(isUpdateInitials: boolean = false): void {
    this.uiService.updateReportEvent$.next(this.data.id);
    this.spinner = true;
    let initialsData: IInitialsData = this.initialsForm.value;
    if (initialsData && initialsData.date) {
      initialsData.date = new Date(initialsData.date).toISOString();
    }
    combineLatest([
      this.backendService.saveLogos(initialsData.clientLogo),
      this.backendService.saveLogos(initialsData.yourLogo)
    ]).pipe(switchMap(([clientLogo, yourLogo]) => {
      if (clientLogo) {
        initialsData.clientLogo = clientLogo;
      }
      if (yourLogo) {
        initialsData.yourLogo = yourLogo;
      }
      if (this.data.initialsData) {
        initialsData = this.data.initialsData;
      }
      const reportData = {
        name: this.name,
        nodes: this.nodes,
        projectId: this.data.projectId,
        isProjectScope: this.isProjectScope,
        isFeatures: this.isFeatures,
        projectScope: this.projectScope,
        reportInitiated: new Date().toISOString(),
        templateId: this.data.templateId,
        columnLayout: this.gridColumn,
        imageBorder: this.imageBorder,
        freeze: false,
        initialsData: {}
      };
      if (this.editMode) {
        return this.backendService.editReport(this.data.id, reportData, initialsData, 'saved')
      } else {
        return this.backendService.createReport(reportData, initialsData, 'saved');
      }
    }),
      takeUntil(this.onDestroy$)
    ).subscribe((result) => {
      this.spinner = false;
      this.dialogRef.close();
    });
  }

  checkIncreaseLevelArrow(currentNodeId: string, previousNodeId: string): boolean {
    return previousNodeId &&
      this.nodeLookup[currentNodeId].level < 5 &&
      this.nodeLookup[currentNodeId].level < this.nodeLookup[previousNodeId].level + 1;
  }

  increaseLevel(currentNodeId: string, previousNodeId: string): void {
    console.log(currentNodeId, previousNodeId, this.nodeLookup);
    if (this.checkIncreaseLevelArrow(currentNodeId, previousNodeId)) {
      this.nodeLookup[currentNodeId].level++;
    }
  }

  decreaseLevel(currentNodeId: string, previousNodeId: string): void {
    console.log(currentNodeId, previousNodeId, this.nodeLookup);
    if (this.nodeLookup[currentNodeId].level > 1) {
      this.nodeLookup[currentNodeId].level--;
    }
  }

  removeNode(id: string): void {
    if (this.nodeLookup[id].isMap) {
      this.data.isMapScreen = false;
    }
    if (this.nodeLookup[id].isProjectScope) {
      this.data.isProjectScope = false;
    }
    if (this.nodeLookup[id].isDocuments) {
      this.data.isDocuments = false;
    }
    if (this.nodeLookup[id].isCertificates) {
      this.data.isCertificate = false;
    }

    delete this.nodeLookup[id];
    this.findAndRemoveNode(id, this.nodes);
    this.dropTargetIds.splice(this.dropTargetIds.indexOf(id), 1);
    // this.nodeLookup.splice(index, 1);
  }

  manageGroupReport(): void {
    this.projectImageGroupSub = this.backendService.getProjectImageGroups(this.data.projectId).pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      const data: IImageGroupDialogData = {
        groups$: of(groups),
        projectId: this.data.projectId,
        showAddBtn: true
      };
      const dialogRef = this.dialog.open(ImageGroupDialogComponent, {
        width: '50vw',
        height: '100vh',
        panelClass: 'bg-dialog',
        data,
      });

      dialogRef.afterClosed().subscribe((selected) => {
        if (selected) {
          const subs = this.backendService.getProjectImages$(data.projectId).subscribe(images => {
            subs.unsubscribe();
            selected.forEach((group: IGroup) => {
              const newNodeId = this.addHeading(group);
              if (group.images) {
                images.filter(image => group.images.includes(image.id)).sort((a, b) => group.images.indexOf(a.id) - group.images.indexOf(b.id)).forEach(image => {
                  this.addImageToNode(newNodeId, image, image.id, group.id);
                });
              }
            });
          });


        }
        this.projectImageGroupSub.unsubscribe();

      });
    });
  }

  public onSuccessAddTemplate(): void { }


  // ** collapse and toggle nodes **
  isCollapse = false;

  collapseAllNodes(): void {
    if (!this.isCollapse) {
      this.nodes.forEach(node => {
        node.isExpanded = false;
      });
      this.isCollapse = true;
    } else {
      this.nodes.forEach(node => {
        node.isExpanded = true;
      });
      this.isCollapse = false;
    }
  }

  toggleNode(node): void {
    node.isExpanded = !node.isExpanded;
  }

  clearHeading(): void {
    if (this.currentHeading === 'Add heading here') {
      this.currentHeading = '';
    }
  }

  addDefaultHeading(): void {
    if (this.currentHeading === '') {
      this.currentHeading = 'Add heading here';
    }
  }

  manageTitles(id): void {
    const dialogRef = this.dialog.open(ImageTitlesDialogComponent, {
      width: '50vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data: {
        type: 'report',
        projectId: this.data.projectId,
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(r => {
      if (r && r.relations) {
        const relations: any[] = r.relations;
        relations.forEach(relation => {
          const contextId = relation.childImagePath.split('images/')[1];
          this.backendService.getImageAnnotations(contextId).subscribe((response: any) => {
            const node = Object.assign({}, new TreeNode(TreeNodeType.Relation, {
              ...relation,
              narration: this.getNarration(this.nodes.find(o => o.id == id)?.groupId, response),
              labels: this.onlyUniqueLabels(this.linkLabelImages).filter(o => o.images.includes(contextId)).map(o => o.label?.title).join(),
              imagesThumbs: r.imagesThumbs,
            }, 1));
            this.nodeLookup[id].children.push(node);
            this.nodeLookup[node.id] = node;
            this.nodeLookup[id].isExpanded = true;
          });
        });
      }
    });
  }

  public addProjectInitials(): void {
    const initialsData = new InitialsDataModel();
    this.backendService.addProjectInitials$(initialsData, this.data.projectId)
  }

  public getProjectInitials(projectId: string): void {
    this.projectInitialSubscribe = this.backendService.valueChangesProjectInitials(projectId).subscribe(response => {
      this.projectInitials = response;
    });
  }

  public updateProjectInitials(): void {
    let initialsData: IInitialsData = this.initialsForm.value;
    this.isUpdateInitialsLoading$.next(true)
    combineLatest([
      this.backendService.saveLogos(initialsData.clientLogo),
      this.backendService.saveLogos(initialsData.yourLogo)
    ]).pipe(
      switchMap(([clientLogo, yourLogo]) => {
        if (clientLogo) {
          initialsData.clientLogo = clientLogo;
        }
        if (yourLogo) {
          initialsData.yourLogo = yourLogo;
        }
        const projectId = this.data.projectId;
        return this.backendService.updateProjectInitials$(this.initialsForm.value, projectId)
      })
    ).subscribe(response => {
      this.isUpdateInitialsLoading$.next(false)
      this.isInitialsListView = true;
      this.initialsForm.reset();
    }, error => {
      this.isUpdateInitialsLoading$.next(false)
    });

  }


  removeProjectInitials(id): void {
    const projectId = this.data.projectId;
    this.backendService.removeProjectInitials$(projectId, id);
  }

  openForm(idx): void {
    this.isInitialsListView = false;
    this.initialsForm.patchValue(this.projectInitials[idx]);
  }
  isOwner(userId: string): boolean {
    return this.backendService.getCurrentUser().uid === userId;
  }

  getReadOnlyForCurrentUser(project): boolean {
    const projectId = this.data.projectId;
    return this.generalService.getReadOnlyForCurrentUser(this.data.projects.find(o => o.id == projectId));
  }
  getNotes(polygons) {
    if (polygons && polygons.length > 0) {
      return polygons.filter(o => o.note)?.length;
    } else {
      return 0;
    }

  }
  getContext(polygons) {
    if (polygons && polygons.length > 0) {
      return polygons.filter(o => o.tag === 'context')?.length;
    } else {
      return 0;
    }
  }
  getImageGroup(node) {
    if (node) {
      return this.nodeLookup[node.id]?.groupImages || 0;
    }
  }

  manageSideBySide(id): void {
    const dialogRef = this.dialog.open(ImageTimelineDialogComponent, {
      width: '50vw',
      height: '100vh',
      panelClass: 'bg-dialog',
      data: {
        type: 'report',
        projectId: this.data.projectId,
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(r => {
      if (r && r.timelines) {
        const timelines: any[] = r.timelines.filter(this.generalService.onlyUnique);
        timelines.forEach(timeline => {
          const subs = combineLatest(
            this.backendService.getImageAnnotations(timeline.keyImageId),
            this.backendService.getImageAnnotations(timeline.valueImageId)
          ).pipe(takeUntil(this.onDestroy$)).subscribe(([keyImage, valueImage]: any) => {
            subs.unsubscribe();
            timeline["keyImageAnnotations"] = keyImage && keyImage.polygons ? keyImage.polygons : [];
            timeline["valueImageAnnotations"] = valueImage && valueImage.polygons ? valueImage.polygons : [];
            const node = Object.assign({ ...timeline, imagesThumbs: r.imagesThumbs }, new TreeNode(TreeNodeType.Timeline, { createdBy: timeline.createdBy }, 0));
            delete node.createdBy;
            this.nodeLookup[id].children.push(node);
            this.nodeLookup[node.id] = node;
            this.nodeLookup[id].isExpanded = true;
          })

        });
      }
    });
  }

  addModelHeader(heading: string, modelId: string, modelType: string, annotations): string {
    if (modelType === '3d') {
      const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, heading, 1), { isModelType: true, modelType: modelType, modelId: modelId, annotations: annotations });
      this.dropTargetIds = [node.id, ...this.dropTargetIds];
      this.nodes.push(node);
      this.nodeLookup[node.id] = node;
      return node.id;
    } else {
      const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, heading, 1), { isModelType: true, modelType: modelType });
      this.dropTargetIds = [node.id, ...this.dropTargetIds];
      this.nodes.push(node);
      this.nodeLookup[node.id] = node;
      return node.id;
    }

  }

  modelDialogRef;
  capture3DScreens() {
    let isAssetOwner = false
    const owners = this.projects.filter(project => project.people[this.backendService.currentUser.uid].role == "owner");
    if (owners.length > 0 || this.asset.createdBy == this.backendService.currentUser.uid) {
      isAssetOwner = true;
    }
    const data = {
      assetId: this.asset.id,
      projects: this.data.projects,
      isAssetOwner: isAssetOwner,
      asset: this.asset,
      selectedProjectId: this.data.projectId
    };
    this.modelDialogRef = this.dialog.open(this.baseModelDialog, {
      panelClass: 'report-panel',
      data,
      width: '100%',
      height: '95vh'
    });
    this.modelDialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).pipe().
      subscribe(data => {
        if (data) {
          const modelNodes = this.nodes.find(o => o.isModelType && o.modelType === '3d');
          let newNodeId;
          if (modelNodes) {
            newNodeId = modelNodes.id;
          } else {
            newNodeId = this.addModelHeader("3D Model images", data.modelId, '3d', data.annotations?.map(o => o.title));
          }
          data.images.forEach(image => {
            const node = Object.assign({ isModelImages: true }, new TreeNode(TreeNodeType.Image, { fileUrl: image.fileUrl, title: image.title }));
            this.dropTargetIds.push(node.id);
            this.nodeLookup[newNodeId].children.push(node);
            this.nodeLookup[node.id] = node;
          });
          this.nodeLookup[newNodeId].isExpanded = true;
        }
      })
  }

  getTechnicalDrawings() {
    let isAssetOwner = false
    const owners = this.projects.filter(project => project.people[this.backendService.currentUser.uid].role == "owner");
    if (owners.length > 0 || this.asset.createdBy == this.backendService.currentUser.uid) {
      isAssetOwner = true;
    }
    const data = {
      assetId: this.asset.id,
      projects: this.data.projects,
      isAssetOwner: isAssetOwner,
      asset: this.asset
    };
    this.modelDialogRef = this.dialog.open(this.baseModelDialog, {
      panelClass: 'report-panel',
      data,
      width: '100%',
      height: '95vh'


    });
    this.modelDialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).pipe().
      subscribe(data => {
        if (data) {
          const modelNodes = this.nodes.find(o => o.isModelType && o.modelType === '2d');
          let newNodeId;
          if (modelNodes) {
            newNodeId = modelNodes.id;
          } else {
            newNodeId = this.addModelHeader("2D plane images", null, '2d', data.annotations?.map(o => o.title));
          }
          data.images.forEach(image => {
            const node = Object.assign({ isModelImages: true, techDrawingId: image.id }, new TreeNode(TreeNodeType.Image, { fileUrl: image.fileUrl, fileName: image.fileName, title: image.fileName }));
            this.dropTargetIds.push(node.id);
            this.nodeLookup[newNodeId].children.push(node);
            this.nodeLookup[node.id] = node;
          });
          this.nodeLookup[newNodeId].isExpanded = true;
        }
      })

  }

  getLabels(labels) {
    var result = labels.map(function (value, index) {
      return (index + 1) + '.' + value;
    }).join(', ');
    return result;
  }

  drop1(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.nodes, event.previousIndex, event.currentIndex);
  }

  dropChildren(event: CdkDragDrop<string[]>, node) {
    const index = this.nodes.findIndex(o => o.id == node.id);
    moveItemInArray(this.nodes[index].children, event.previousIndex, event.currentIndex);
  }

  linkLabelImages = [];
  getImageLabels(asset) {
    if (asset.baseModel == "3d") {
      const modelSubscription = this.backendService.get3DModels$(asset.id).subscribe((result: any) => {
        modelSubscription.unsubscribe();
        if (result) {
          const labelSubscription = this.backendService.get3DModelLabels$(result.id).subscribe((labels: any) => {
            labelSubscription.unsubscribe();
            const allLabels = labels.label || [];
            this.backendService.getLinkedImages(asset.id, result.id, this.data.projectId).subscribe(labelImages => {
              if (labelImages) {
                let keys = Object.keys(labelImages);
                keys.forEach(element => {
                  const index = allLabels.findIndex(o => o.id == element);
                  if (index != -1) {
                    if (labelImages[element]?.['images'] && !this.linkLabelImages.map(o=>o.label.id).includes(allLabels[index].id)) {
                      this.linkLabelImages.push({
                        label: allLabels[index],
                        images: labelImages[element]?.['images']
                      })
                    }
                  }
                });
                this.restoreData();
              } else {
                this.restoreData();
              }
            })
          });
        } else {
          this.restoreData();
        }
      })

    }
    else if (asset.baseModel == "2d") {
      if (asset.assetType != AssetType.SOLAR) {
        const modelSubscription = this.backendService
          .get2DModels$(asset.id).pipe(
            takeUntil(this.onDestroy$)
          ).subscribe((techDrawings: any) => {
            const result = [];
            techDrawings = techDrawings?.filter(o => !o.isDXFFile)
            modelSubscription.unsubscribe();
            techDrawings.forEach(drawing => {
              const node = {
                id: drawing.id,
                title: drawing.fileName,
                children: drawing.labels || [],
              };
              if (node.children.length) {
                result.push(node)
              }
            });
            let count = 0;
            result.forEach(drawing => {
              this.backendService.getLinkedImages(asset.id, drawing.id, this.data.projectId).subscribe(labelImages => {
                count++;
                if (labelImages) {
                  let keys = Object.keys(labelImages);
                  keys.forEach(element => {
                    const index = drawing.children.findIndex(o => o.id == element);
                    if (index != -1) {
                      if (labelImages[element]?.['images'] &&
                      !this.linkLabelImages.map(o=>o.label.id).includes( drawing.children[index])) {
                        this.linkLabelImages.push({
                          label: drawing.children[index],
                          images: labelImages[element]?.['images']
                        })
                      }
                    }
                  });
                }
                if (count == result.length) {
                  this.restoreData();
                }


              })
            });
            if (result.length == 0) {
              this.restoreData();
            }
          }, (error) => {
            throw error;
          });
      }
      else {
        const modelSubscription = this.backendService
          .get2DDXFModels$(asset.id).pipe(
            takeUntil(this.onDestroy$)
          ).subscribe((techDrawings: any) => {
            const result = [];
            modelSubscription.unsubscribe();
            this.restoreData();
          });
      }
    }
  }

  addFeatureHeader(heading: string): string {
    const node = Object.assign({}, new TreeNode(TreeNodeType.Features, heading, 1), { isFeatures: true });
    this.dropTargetIds = [node.id, ...this.dropTargetIds];
    this.nodes.push(node);
    this.nodeLookup[node.id] = node;
    return node.id;
  }

  getFindingCount(tag) {
    if (this.summary && this.summary.features[tag.tag]) {
      return this.summary.features[tag.tag];
    } else {
      return 0;
    }
  }

  private addFeatureTableToNode(nodeId: string): void {
    const node = Object.assign({}, new TreeNode(TreeNodeType.FINDINGS_TBL, {
      tags: this.tags.map(function (item) { return { tag: item["tag"], description: item['description'] || '' } }),
      summary: this.summary['features']
    })
    );
    this.dropTargetIds.push(node.id);
    this.nodeLookup[nodeId].children.push(node);
    this.nodeLookup[nodeId].isExpanded = true;

    const severityTags = this.tags.filter(o => o.sensitivity);
    const severity = [];
    const propertySequence = ["level", "color", "title", "action"];
    severityTags.forEach(tag => {
      severity.push({
        tag: tag.tag,
        levels: tag.levels.map(obj => {
          const newObj = {};
          propertySequence.forEach(property => {
            newObj[property] = obj[property];
          });
          return newObj;
        })
      })
    });
    const mergedArray = this.mergeTags(severity);
    mergedArray.forEach(tag => {
      const nodeSeverity = Object.assign({}, new TreeNode(TreeNodeType.SEVERITY_TBL, tag)
      );
      this.dropTargetIds.push(nodeSeverity.id);
      this.nodeLookup[nodeId].children.push(nodeSeverity);
      this.nodeLookup[nodeId].isExpanded = true;
    });
  }

  mergeTags(array) {
    const mergedArray = [];
    // Create a map to store merged elements based on levels properties
    const mergedMap = new Map();

    // Iterate over the array and merge elements
    array.forEach(element => {
      const levelsKey = JSON.stringify(element.levels);
      if (mergedMap.has(levelsKey)) {
        // Merge the "tag" property if all levels properties are the same
        const mergedElement = mergedMap.get(levelsKey);
        mergedElement.tag += ", " + element.tag;
      } else {
        // Add element to the map if it doesn't exist
        mergedMap.set(levelsKey, { tag: element.tag, levels: element.levels });
      }
    });

    // Push merged elements into the merged array
    mergedMap.forEach(element => {
      mergedArray.push(element);
    });

    return mergedArray;
  }

  featureChange(evt) {
    if (evt) {
      const featureChapter = this.nodes.find(o => o.isFeatures);
      let newNodeId;
      if (featureChapter) {
        newNodeId = featureChapter.id;
      } else {
        newNodeId = this.addFeatureHeader("Features");
      }
      this.nodeLookup[newNodeId].isExpanded = true;
      this.addFeatureTableToNode(newNodeId);
    } else {
      const featureChapter = this.nodes.find(o => o.isFeatures);
      this.removeNode(featureChapter.id)
    }
  }

  includeCertificate(evt) {
    const chapter = this.nodes.find(o => o.isCertificates);
    if (evt) {
      let newNodeId;
      if (chapter) {
        newNodeId = chapter.id;
      } else {
        const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, "Certificates", 1), { isModelType: true, isCertificates: true, urls: this.certificates });
        this.nodes.push(node);
        this.nodeLookup[node.id] = node;
        this.nodeLookup[node.id].isExpanded = true;
      }
    } else {
      this.removeNode(chapter.id)
    }
  }

  includeDocuments(evt) {
    const chapter = this.nodes.find(o => o.isDocuments);
    if (evt) {
      let newNodeId;
      if (chapter) {
        newNodeId = chapter.id;
      } else {
        const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, "Documents", 1), { isModelType: true, isDocuments: true, urls: this.documents });
        this.nodes.push(node);
        this.nodeLookup[node.id] = node;
        this.nodeLookup[node.id].isExpanded = true;
      }
    } else {
      this.removeNode(chapter.id)
    }
  }

  includeMapScreenShots(evt) {
    const chapter = this.nodes.find(o => o.isMap);
    if (evt) {
      let newNodeId;
      if (chapter) {
        newNodeId = chapter.id;
      } else {
        const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, "Maps"), { isModelType: true, isMap: true, fileUrl: this.asset.mapImage.link });
        this.nodes.push(node);
        this.nodeLookup[node.id] = node;
        this.nodeLookup[node.id].isExpanded = true;
      }
    } else {
      this.removeNode(chapter.id)
    }
  }

  includeProjectScope(evt) {
    const chapter = this.nodes.find(o => o.isProjectScope);
    if (evt) {
      let newNodeId;
      if (chapter) {
        newNodeId = chapter.id;
      } else {
        const node = Object.assign({}, new TreeNode(TreeNodeType.Heading, "Scope"), { isModelType: true, scope: this.projectScope, isProjectScope: true });
        this.nodes.push(node);
        this.nodeLookup[node.id] = node;
        this.nodeLookup[node.id].isExpanded = true;
      }
    } else {
      this.removeNode(chapter.id)
    }
  }

  onlyUniqueLabels(data){
    const uniqueData = new Map();
    data.forEach(item => {
      uniqueData.set(item.label.id, item);
    });
    // Convert the Map back to an array
    return Array.from(uniqueData.values());
  }
}



