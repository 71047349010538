<mat-card class="notification m-1" [ngClass]="notification.messageType ?  notification.messageType : '#e3e3e3'"
    *ngFor="let notification of notifications;let i= index">
    <p>
        <mat-spinner-button class="delete-icon float-right" matTooltip="delete" [options]="deleteBtnOptions"
            (btnClick)="delete(notification.id,i)">
        </mat-spinner-button>
        {{notification.message}}
      
    </p>
    <span>
        {{getTime(notification.createdTime)}}
      </span>
</mat-card>