import { Inject, Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AuthProcessService,
  NgxAuthFirebaseUIConfig,
  NgxAuthFirebaseUIConfigToken
} from 'ngx-auth-firebaseui';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard implements CanActivate {
  constructor(
    @Inject(NgxAuthFirebaseUIConfigToken)
    private config: NgxAuthFirebaseUIConfig,
    private router: Router,
    private authProcess: AuthProcessService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
   return this.authProcess.afa.user.pipe(
      map(user => {
        if (!user) {
          if (next.queryParams.addToProject) {
            this.router.navigate([this.config.authGuardFallbackURL], {
              queryParams: {
                action: 'addToProject',
                project: next.queryParams.addToProject,
                role: next.queryParams.role,
                readonly:next.queryParams.readonly,
                id:next.queryParams.id
              }
            });
          } else {
            this.router.navigate([this.config.authGuardFallbackURL]);
          }
        }
        return !!user;
      })
    );
  }
}
