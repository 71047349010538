import { BackendService } from '../services/backend.service';
import { CombineQueriesService } from '../services/combine-queries.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { UiService } from '../services/ui.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../projects/item/project.type';
import { debounceTime, distinctUntilChanged, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IFolder } from '../models/folder.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IGroup } from '../models/group.interface';
import { GeneralService } from '../services/general.service';
import { TaggedArea } from '../models/tagging.interface';
import { IImage } from '../models/image.interface';

export interface ImageTitleImageData {
  project;
  nodeImagesIds?: string[];
  label: string;
  assetId;
}

@Component({
  selector: 'app-link-images',
  templateUrl: './link-images.component.html',
  styleUrls: ['./link-images.component.scss']
})
export class LinkImagesComponent implements OnInit, OnDestroy {
  public label;
  public folders: IFolder[] = [];
  public showedFolders$ = new BehaviorSubject<any[]>([]);
  public folderImages$ = new BehaviorSubject<any[]>([]);
  public filteredFolderImages$ = new BehaviorSubject<any[]>([]);
  public selectedImagesIds$ = new BehaviorSubject<string[]>([]);
  public loading$ = new BehaviorSubject<boolean>(true);
  public isFolderContentLoading$ = new BehaviorSubject<boolean>(false);
  public videos = [];
  public filteredFolderVideos$ = new BehaviorSubject<any[]>([]);
  public blockedImagesIds: string[] = [];

  public selectedProject$ = new BehaviorSubject(this.data.project);
  public tags: any[];
  private selectedTag: string = 'All';

  public set movedImages(images) {
    this.uiService.movedImages = images;
  }

  public get movedImages(): IImage[] {
    return this.uiService.movedImages;
  }
  public get selectedProject() {
    return this.data.project.id;
  }

  public selectedFolder$ = new BehaviorSubject<IFolder>(null);
  public foldersBreadcrumb: IFolder[] = [];
  public tab = 1;
  private onDestroy$ = new Subject<void>();
  private startIndex = 0;
  private endIndex = 32;
  public selectedFolderId;
  public images = [];
  selectedImages: IImage[];
  public contextMenuPosition = { x: '0px', y: '0px' };
  constructor(
    public dialogRef: MatDialogRef<LinkImagesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageTitleImageData,
    public backendService: BackendService,
    public uiService: UiService,
    private combineQueriesService: CombineQueriesService,
    private generalService: GeneralService
  ) {
    if (data && data.nodeImagesIds) {
      this.blockedImagesIds = data.nodeImagesIds;
    }
    this.label = data.label;
    this.selectedProject$.pipe(
      tap(() => this.selectedImagesIds$.next([])),
      switchMap(projectId => this.combineQueriesService.getFolders$(this.data.project.id)),
      tap((folders) => {
        this.folders = folders;
        this.showedFolders$.next(folders);
        const selectedFolder = folders && folders.length > 0 ? { ...folders[0] } : null;
        this.selectedFolder$.next(selectedFolder);
        this.foldersBreadcrumb = [];
        this.setFolderBreadcrumb(selectedFolder);
        if (selectedFolder) {
          if (selectedFolder.children.filter(o => !o.doc.deletedDate).length)
            this.showedFolders$.next(selectedFolder.children.filter(o => !o.doc.deletedDate));
        } else {
          this.showedFolders$.next([]);
        }
      }),
      takeUntil(this.onDestroy$),
    ).subscribe();
    this.selectedFolder$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(folder => {
        if (folder === null) {
          return of([]);
        }
        return this.combineQueriesService.getFoldersImages$(folder.path, this.selectedProject);
      }),
      tap((images) => this.folderImages$.next(images)),
      tap((images) => this.filteredFolderImages$.next(images)),
      tap(() => this.loading$.next(false)),
      takeUntil(this.onDestroy$)
    ).subscribe((images) => {
      if (images && this.selectedFolder$.value) {
        this.backendService.getVideoContentsAll$(
          this.selectedFolder$.value.path, this.selectedProject
        ).pipe(
          takeUntil(this.onDestroy$)
        ).subscribe((videos) => {
          videos = videos.map(obj => ({ ...obj, type: 'videos' }))
          this.videos = videos;
          this.startIndex = 0;
          this.endIndex = 32;
          this.selectTag(this.selectedTag);
        }, error => {
          console.error(error)
        });
      }
    })

  }

  public selectedProjectName$ = this.selectedProject$.pipe(
    map(() => {
      const selectedProject = this.uiService.allProjects.find(o=>o.id == this.data.project.id);
      return selectedProject ? selectedProject.name : null;
    })
  );

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
    this.getTags();
  }

  getNextImagesSubscription: Subscription[] = []

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.getNextImagesSubscription.map((subscr: Subscription) => subscr.unsubscribe());
  }

  onScroll() {
    if (this.isFolderContentLoading$.value) {
      return
    }
    this.isFolderContentLoading$.next(true);
    setTimeout(() => {
      this.startIndex = this.endIndex;
      this.endIndex = this.startIndex + 32;
      if (this.selectedTag === 'All') {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.slice(this.startIndex, this.endIndex));
      } else if (this.selectedTag === 'Untagged') {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.filter((image) => !image.tags || !image.tags.length).slice(this.startIndex, this.endIndex));

      } else if (this.selectedTag === 'Tagged') {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.filter((image) => image.tags && image.tags.length && image.tags.map(o => o.tag) != 'context').slice(this.startIndex, this.endIndex));

      }
      else if (this.selectedTag === 'Videos') {
        this.images = this.filteredFolderImages$.value.concat(this.videos.slice(this.startIndex, this.endIndex))
      } else {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.filter((image) =>
          image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
        ).slice(this.startIndex, this.endIndex));
      }
      this.filteredFolderImages$.next(this.images)
      this.isFolderContentLoading$.next(false);
    }, 300)
  }

  toggleFolder(folder: IFolder): void {
    if (this.folders.filter(o => o.docId == folder.docId).length) {
      this.selectedFolderId = folder.docId
    }
    this.selectedFolder$.next(folder);
    this.setFolderBreadcrumb(folder);
    this.scrollToTop();
    if (folder.children.filter(o => !o.doc.deletedDate).length > 0) {
      this.showedFolders$.next(folder.children.filter(o => !o.doc.deletedDate));
    }
  }

  selectFolder(folder: IFolder): void {
    this.selectedFolder$.next(folder);
    this.foldersBreadcrumb = [];
    this.selectedFolder$.next(folder);
    this.setFolderBreadcrumb(folder);
    this.scrollToTop();
    if (folder) {
      this.showedFolders$.next(folder.children.filter(o => !o.doc.deletedDate));
    } else {
      this.showedFolders$.next([]);
    }

  }

  goToRoot(): void {
    if (this.foldersBreadcrumb.length > 0) {
      this.selectedFolder$.next(this.foldersBreadcrumb[0]);
      this.showedFolders$.next(this.folders);
      this.foldersBreadcrumb = [];
    }
  }

  onClickImage(image: any, { checked }: MatCheckboxChange): void {
    let newVal: any[];
    if (checked && !this.selectedImagesIds$.value.includes(image.id)) {
      newVal = [...this.selectedImagesIds$.value, image.id];
    } else if (!checked && this.selectedImagesIds$.value.includes(image.id)) {
      newVal = this.selectedImagesIds$.value.filter(imageId => imageId !== image.id);
    }
    this.selectedImagesIds$.next(newVal);
  }

  addImagesToTitle(): void {
    this.dialogRef.close({
      videos:this.videos.map(o=>o.id).filter(element =>  this.selectedImagesIds$.value.includes(element)),
      images:this.folderImages$.value.map(o=>o.id).filter(element =>  this.selectedImagesIds$.value.includes(element)),
      projectId: this.selectedProject
     });
  }

  private setFolderBreadcrumb(folder: IFolder): void {
    if (folder === null) {
      return;
    }

    if (!folder.children || !folder.children.length) return;

    if (this.foldersBreadcrumb.length === 0) {
      this.foldersBreadcrumb.push(folder);
      return;
    }

    const idx = this.foldersBreadcrumb.findIndex(breadcrumFolder => breadcrumFolder.path === folder.path);
    console.log({ breadcrumb: this.foldersBreadcrumb, folder, idx });
    if (idx === -1) {
      this.foldersBreadcrumb.push(folder);
    } else {
      this.foldersBreadcrumb = this.foldersBreadcrumb.slice(0, idx + 1);
    }
  }

  getTags(projectId: string = this.data.project.id) {
    this.backendService.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      this.tags = [
        { tag: 'Tagged' },
        ...tags,
        { tag: 'Untagged' },
        { tag: 'Videos' },
        { tag: 'All' }

      ];
    });
  }

  selectTagEl(tag): void {
    this.startIndex = 0;
    this.endIndex = 32;
    this.scrollToTop();
    this.selectTag(tag)
  }

  scrollToTop() {
    const containerEl = document.querySelector('.modal-two-side-layout-images');
    containerEl.scrollTop = 0;
  }

  selectTag(tag): void {
    this.selectedTag = tag;
    if (this.selectedTag === 'All') {
      this.images = this.videos.concat(this.folderImages$.value.slice(this.startIndex, this.endIndex));
    } else if (this.selectedTag === 'Untagged') {
      this.images =
        this.folderImages$.value.filter((image) => !image.tags || !image.tags.length).slice(this.startIndex, this.endIndex)
        ;
    }
    else if (this.selectedTag === 'Tagged') {
      this.images =
        this.folderImages$.value.filter((image) => image.tags && image.tags.length).slice(this.startIndex, this.endIndex
        );
    }
    else if (this.selectedTag === 'Videos') {
      this.images =
        this.videos.slice(this.startIndex, this.endIndex
        );
    }
    else {
      this.images =
        this.folderImages$.value.filter(
          (image) => image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
        ).slice(this.startIndex, this.endIndex
        );
    }
    this.filteredFolderImages$.next(this.images)
  }



  getAnnotations(images) {
    combineLatest(
      images.map((image) =>
        this.backendService.getImageAnnotations$<{
          polygons: Record<string, TaggedArea>;
        }>(image)
      )
    ).pipe(takeUntil(this.onDestroy$)).subscribe((annotations) => {
      annotations.forEach((annotation, i) => {
        let x = this.folderImages$.value.filter(o => o.id == images[i])[0];
        if (x) {
          x.tags = annotation ? (annotation as any).polygons : [];
        }

      });
      this.startIndex = 0;
      this.endIndex = 32;
      this.selectTag(this.selectedTag);
    });
  }

  someMethod(event) {
    this.selectedImagesIds$.next(this.selectedImages.map(o => o.id));
  }

  isChecked(imageId: string): boolean {
    return (this.selectedImages && this.selectedImages.map(selectedImage => selectedImage.id).includes(imageId) || this.blockedImagesIds.includes(imageId));
  }

  isMovedImage(image: IImage): boolean {
    return (this.movedImages.length && this.movedImages.map(image => image.id).includes(image.id));
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  itemDeselected() {
    if (!this.movedImages.length) {
      this.cancelMoving();
    }
  }

  cancelMoving() {
    const selected = this.selectedImages;
    this.movedImages = this.selectedImages = [];
    let newVal: any[];
    selected.forEach(image => {
      if (this.selectedImagesIds$.value.includes(image.id)) {
        newVal = this.selectedImagesIds$.value.filter(imageId => imageId !== image.id);
      }
    });
    this.selectedImagesIds$.next(newVal);
  }

  activeTab(tab) {
    this.tab = tab;
  }

}
