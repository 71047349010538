<div class="w-100 h-100 bg">
    <headings class="headings py-2">
        <span class="mr-3 title">Profile</span>
        <div class="d-flex side-right ml-auto">
            <button mat-button (click)="close()">Cancel</button>
            <mat-spinner-button *ngIf="tab != 4 && tab != 5" class="mr-2 ml-1 header-spinner-button btn-save"
                [options]="createBtnOptions" (btnClick)="updateUserProfile()">
            </mat-spinner-button>

        </div>
    </headings>
    <div class="row card-scroll ml-2 mr-2 mt-2">
        <div class="col-12">
            <!-- <mat-tab-group dynamicHeight>
                <mat-tab label="Profile">
                    <ng-container [ngTemplateOutlet]="profile"></ng-container>
                </mat-tab>
                <mat-tab disabled label="Plans">
                    <ng-container [ngTemplateOutlet]="plans"></ng-container>
                </mat-tab>
            </mat-tab-group> -->
            <mat-card class="my-4 mat-card-profile">
                <div class="form-container px-30">
                    <div class="row pb-30">
                        <div class="col-2 p-0">
                            <input type="file" (change)="fileChoosen($event)" accept="image/*" hidden id="fileInput">
                            <div class="profile" (click)="updateProfilePic()">
                                <img class="preview-image" *ngIf="profileForm.controls['photoURL'].value"
                                    [src]="profileForm.controls['photoURL'].value" class="profile" />
                                <mat-icon *ngIf="profileForm.controls['photoURL'].value" class="camera">photo_camera
                                </mat-icon>
                                <div *ngIf="!profileForm.controls['photoURL'].value">
                                    <div class="logo-input__icon">
                                        <svg style="margin-left: 40%;margin-top: 35%;" width="30" height="30"
                                            viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0)">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M26.2537 3.75H3.75366C3.25638 3.75 2.77947 3.94754 2.42784 4.29917C2.07621 4.65081 1.87866 5.12772 1.87866 5.625V24.375C1.87866 24.8723 2.07621 25.3492 2.42784 25.7008C2.77947 26.0525 3.25638 26.25 3.75366 26.25H26.2537C26.7509 26.25 27.2279 26.0525 27.5795 25.7008C27.9311 25.3492 28.1287 24.8723 28.1287 24.375V5.625C28.1287 5.12772 27.9311 4.65081 27.5795 4.29917C27.2279 3.94754 26.7509 3.75 26.2537 3.75ZM3.75366 1.875C2.7591 1.875 1.80527 2.27009 1.10201 2.97335C0.39875 3.67661 0.00366211 4.63044 0.00366211 5.625V24.375C0.00366211 25.3696 0.39875 26.3234 1.10201 27.0266C1.80527 27.7299 2.7591 28.125 3.75366 28.125H26.2537C26.7461 28.125 27.2338 28.028 27.6887 27.8395C28.1437 27.6511 28.5571 27.3749 28.9053 27.0266C29.2535 26.6784 29.5298 26.265 29.7182 25.8101C29.9067 25.3551 30.0037 24.8675 30.0037 24.375V5.625C30.0037 4.63044 29.6086 3.67661 28.9053 2.97335C28.2021 2.27009 27.2482 1.875 26.2537 1.875H3.75366Z"
                                                    fill="#CAD2D5" />
                                                <path
                                                    d="M19.9649 14.3362C20.1042 14.1973 20.2836 14.1057 20.4777 14.0744C20.6719 14.0432 20.871 14.0737 21.0468 14.1618L28.1287 17.8124V26.2499H1.87866V22.4999L6.83991 18.0862C6.99316 17.9335 7.1945 17.8385 7.4098 17.8173C7.62509 17.7961 7.84109 17.85 8.02116 17.9699L13.0087 21.2943L19.9649 14.338V14.3362Z"
                                                    fill="#CAD2D5" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.44116 13.125C8.8105 13.125 9.17623 13.0523 9.51746 12.9109C9.85869 12.7696 10.1687 12.5624 10.4299 12.3012C10.6911 12.0401 10.8982 11.73 11.0396 11.3888C11.1809 11.0476 11.2537 10.6818 11.2537 10.3125C11.2537 9.94316 11.1809 9.57743 11.0396 9.2362C10.8982 8.89497 10.6911 8.58493 10.4299 8.32376C10.1687 8.0626 9.85869 7.85543 9.51746 7.71409C9.17623 7.57275 8.8105 7.5 8.44116 7.5C7.69524 7.5 6.97987 7.79632 6.45242 8.32376C5.92498 8.85121 5.62866 9.56658 5.62866 10.3125C5.62866 11.0584 5.92498 11.7738 6.45242 12.3012C6.97987 12.8287 7.69524 13.125 8.44116 13.125Z"
                                                    fill="#CAD2D5" />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0">
                                                    <rect width="30" height="30" fill="white" />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <span class="logo-input__text"> Upload an image </span>
                                </div>


                            </div>
                        </div>
                        <div class="col-10 mt-2 p-0">
                            <h1>
                                <b>
                                    {{profileForm.controls['firstName'].value}}
                                    {{profileForm.controls['lastName'].value}}
                                </b>
                            </h1>
                            <h3>
                                Company: {{companyForm.controls['companyName'].value || '-'}}
                            </h3>
                            <div class="d-flex align-items-center">
                                <mat-icon class="mr-2">email</mat-icon>
                                <span>
                                    {{profileForm.controls['email'].value}}
                                </span>

                                <div class="d-flex align-items-center ml-3"
                                    *ngIf="profileForm.controls['contact'].value">
                                    <mat-icon class="mr-2">phone</mat-icon>
                                    <span>
                                        {{profileForm.controls['contact'].value}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex w-100 border-top pt-3">
                        <button (click)="activeTab(1)" [class.personal-tab_active]="tab === 1"
                            class="text-uppercase personal-tab mr-1">
                            Personal Info
                        </button>
                        <button (click)="activeTab(2)" [class.personal-tab_active]="tab === 2"
                            class="text-uppercase personal-tab  mr-1">

                            Address
                        </button>
                        <button (click)="activeTab(3)" [class.personal-tab_active]="tab === 3"
                            class="text-uppercase personal-tab  mr-1">
                            Change Password
                        </button>
                        <button (click)="activeTab(4)" [class.personal-tab_active]="tab === 4"
                            class="text-uppercase personal-tab  mr-1">
                            Industry
                        </button>
                        <button (click)="activeTab(5)" [class.personal-tab_active]="tab === 5"
                        class="text-uppercase personal-tab  mr-1">
                        Settings
                    </button>
                    </div>

                </div>
            </mat-card>

            <!-- ------------Profile------------- -->
            <mat-card class="p-4" *ngIf="tab ==1">
                <div class="row">
                    <div class="col-6 mb-3">
                        <mat-label class="w-100">First Name</mat-label>
                        <mat-form-field appearance="fill" class="">

                            <input type="text" matInput [formControl]="profileForm.controls['firstName']">
                        </mat-form-field>

                    </div>
                    <div class="col-6 mb-3">
                        <mat-label class="w-100">Last Name</mat-label>
                        <mat-form-field appearance="fill" class="">

                            <input type="text" matInput [formControl]="profileForm.controls['lastName']">
                        </mat-form-field>

                    </div>

                </div>

                <div class="row">
                    <div class="col-6 mb-0">
                        <mat-label>Contact</mat-label>
                        <mat-form-field appearance="fill">

                            <input type="text" matInput [formControl]="profileForm.controls['contact']">
                        </mat-form-field>

                    </div>
                    <div class="col-6 mb-0">
                        <mat-label>Company</mat-label>
                        <mat-form-field appearance="fill">

                            <input type="input" matInput [formControl]="companyForm.controls['companyName']">
                        </mat-form-field>

                    </div>

                </div>


            </mat-card>

            <!-- ------------Address------------- -->
            <mat-card class="mb-2 shadow-none border-0 p-0" *ngIf="tab ==2" style="background-color: transparent;">
                <div class="row">
                    <div class="col-6 p-2">

                        <div class="contact-box ml-8">
                            <div class="header-form">
                                Contact Address
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group mb-2">
                                        <mat-label>Address (Area and street)</mat-label>
                                        <mat-form-field appearance="fill">

                                            <textarea type="text" matInput
                                                [formControl]="contactForm.controls['address']"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <mat-label>City / District / Town</mat-label>
                                    <mat-form-field appearance="fill" class="form-group mb-2">

                                        <input type="text" matInput [formControl]="contactForm.controls['city']">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-label>State</mat-label>
                                    <mat-form-field appearance="fill" class="form-group mb-2">

                                        <input type="text" matInput [formControl]="contactForm.controls['state']">
                                    </mat-form-field>
                                </div>
                                <div class="col-4">
                                    <mat-label>Pincode</mat-label>
                                    <mat-form-field appearance="fill" class="form-group mb-2">

                                        <input type="text" matInput [formControl]="contactForm.controls['pincode']">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <mat-label>Country</mat-label>
                                    <mat-form-field appearance="fill" class="form-group mb-2">

                                        <input type="text" matInput [formControl]="contactForm.controls['country']">
                                    </mat-form-field>
                                </div>
                                <div class="col-6">
                                    <mat-label>Alternative phone</mat-label>
                                    <mat-form-field appearance="fill" class="form-group mb-2">

                                        <input type="text" matInput [formControl]="contactForm.controls['phone']">
                                    </mat-form-field>
                                </div>
                            </div>



                        </div>
                    </div>
                    <div class="col-6 p-2">
                        <div class="contact-box ml-8">
                            <div class="header-form">
                                Billing Address

                                <mat-checkbox class="mb-0" [(ngModel)]="isSameAsContact" [color]="'primary'"
                                    (change)="sameAsContact($event)">
                                    Same as Contact Address
                                </mat-checkbox>
                            </div>
                            <div class="form-group mb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-label>Address(Area and street)</mat-label>
                                        <mat-form-field appearance="fill">

                                            <textarea type="text" matInput
                                                [formControl]="billingForm.controls['address']"></textarea>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-4">
                                        <mat-label>City / District / Town</mat-label>
                                        <mat-form-field appearance="fill" class="form-group mb-2">

                                            <input type="text" matInput [formControl]="billingForm.controls['city']">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-label>State</mat-label>
                                        <mat-form-field appearance="fill" class="form-group mb-2">

                                            <input type="text" matInput [formControl]="billingForm.controls['state']">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-label>Pincode</mat-label>
                                        <mat-form-field appearance="fill" class="form-group mb-2">

                                            <input type="text" matInput [formControl]="billingForm.controls['pincode']">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-6">
                                        <mat-label>Country</mat-label>
                                        <mat-form-field appearance="fill" class="form-group">

                                            <input type="text" matInput [formControl]="billingForm.controls['country']">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-6">
                                        <mat-label>Alternative phone</mat-label>
                                        <mat-form-field appearance="fill" class="form-group">

                                            <input type="text" matInput [formControl]="billingForm.controls['phone']">
                                        </mat-form-field>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>


            </mat-card>

            <!-- ------------Change Password------------- -->
            <mat-card class="mb-2" *ngIf="tab ==3">
                <div class="row">
                    <div class="col-12 col-md-6 offset-md-3 mb-2">
                        <mat-label>Old Password</mat-label>
                        <mat-form-field appearance="fill">

                            <input type="password" matInput [formControl]="passwordForm.controls['password']">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 offset-md-3 mb-2">
                        <mat-label>New Password</mat-label>
                        <mat-form-field appearance="fill">

                            <input type="password" matInput [formControl]="passwordForm.controls['newPassword']">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 offset-md-3">
                        <mat-label>Confirm Password</mat-label>
                        <mat-form-field appearance="fill">

                            <input type="password" matInput [formControl]="passwordForm.controls['confirm']">
                        </mat-form-field>
                    </div>

                </div>
            </mat-card>

            <!-- ------------Industry------------- -->
            <mat-card class="mb-2" *ngIf="tab ==4">
                <div class="row">
                    <span *ngFor="let industry of userIndustries" class="tag label label-info industry-field">
                        <span> {{industry}}</span>
                        <mat-icon (click)="openDeleteIndustryDialog(industry)">close</mat-icon>
                    </span>
                    <mat-icon (click)="openIndustryDialog()" style="margin:10px;cursor: pointer;">add</mat-icon>
                </div>
                <div *ngIf="userIndustries.length ==0" class="small">
                    Please add an industry to your profile before creating any projects.
                </div>
            </mat-card>

            <!-- ------------Settings------------- -->
            <mat-card class="mb-2" *ngIf="tab ==5">
                <div class="row">
                    <div class="col-12  offset-md-3 mb-2">
                        <mat-label>Default Inspection Dashboard view</mat-label>
                        <div>
                            <mat-button-toggle-group class="mat-group" vertical="false" [(ngModel)]="dashPanel"
                            class="mt-2">
                            <mat-button-toggle (change)="onValChange($event.value)" [class.activeToggle]="dashPanel ==='map'"
                              value="map">
                              Map View
                            </mat-button-toggle>
                            <mat-button-toggle [class.activeToggle]="dashPanel ==='panel'" (change)="onValChange($event.value)"
                              value="panel">
                              Panel View
                            </mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>
                      
                    </div>

                    <div class="col-12  offset-md-3 mb-2">
                        <mat-label>Side Panel View</mat-label>
                        <div>
                            <mat-button-toggle-group class="mat-group" vertical="false" [(ngModel)]="defaultView"
                            class="mt-2">
                            <mat-button-toggle (change)="onValChange($event.value)" [class.activeToggle]="defaultView ==='assets'"
                              value="assets">
                              Asset View
                            </mat-button-toggle>
                            <mat-button-toggle [class.activeToggle]="defaultView ==='campaigns'" (change)="onValChange($event.value)"
                              value="campaigns">
                              Campaign View
                            </mat-button-toggle>
                          </mat-button-toggle-group>
                        </div>
                      
                    </div>


                </div>
            </mat-card>


        </div>
    </div>
</div>

<ng-template #industryDialog let-data>
    <div class="industryDialog">
        <div class="industryDialog-header">
            <span>Select Industry</span>
            <button mat-icon-button (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div class="industryDialog-body">
            <p (click)="chooseIndustry(industry)" *ngFor="let industry of data.industries">
                {{industry}}
            </p>
        </div>
    </div>
</ng-template>

<ng-template #deleteIndustryDialog let-data>
    <div class="deleteIndustryDialog">
        <div class="delete-industryDialog-body">
            <p *ngIf="data.industries.length ==0">
                Are you sure you want to delete this industry?
            </p>
            <p *ngIf="data.industries.length != 0">
                There is <b>{{data.industries.length}} project</b> associated with this industry.
                <br />
                Please, delete this project before deleting this industry?
            </p>
            <p>

            </p>
        </div>
        <div class="deleteIndustryDialog-footer">
            <div class="d-flex align-items-center justify-content-end mt-3">
                <button mat-button mat-stroked-button class="cancel-button" (click)="closeDeleteDialog()">
                    Cancel
                </button>
                <button [disabled]="data.industries.length > 0" color="warn" mat-button mat-stroked-button
                    class="delete-button" (click)="deleteIndustry(data.industry)">
                    Delete
                </button>
            </div>
        </div>
    </div>
</ng-template>