import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BackendService} from '../services/backend.service';

@Component({
  selector: 'app-tag-note-add-dialog',
  templateUrl: './tag-note-add-dialog.component.html',
  styleUrls: ['./tag-note-add-dialog.component.scss']
})
export class TagNoteAddDialogComponent implements OnInit {
  public note = '';
  public lastEditedUser: {
    displayName: string,
    email: string
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<TagNoteAddDialogComponent>,
              private backendService: BackendService) {
    this.note = this.data.note;
    this.lastEditedUser = this.data.lastEditedUser;
    this.dialogRef.afterClosed().subscribe(() => {
      if (this.data.imageId && this.data.id && this.note !== undefined && this.data.polygon) {
        if(this.data.note != this.note){
         this.backendService.addHistory([this.data.imageId], this.data.projectId, !this.data.note? "notes added":"notes edited", "notes", {note:this.note || '', tag: this.data.tag || ''})
        }
       this.backendService.addAnnotationNote(this.data.imageId, this.data.id, this.note, this.data.polygon, this.data.tag,this.data.type).subscribe(() => {});
      }
    });
  }

  ngOnInit(): void {}

  deleteAnnotation(){
    this.backendService
    .removeThermalAnnotation(
      this.data.id,
      this.data.imageId,
      this.data.type
    ).pipe().subscribe(() => {
    this.dialogRef.close();
    })
  }
}
