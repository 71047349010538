import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Inject,
} from '@angular/core';
import {
  of,
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest,
  Subject,
  from,
} from 'rxjs';
import { first, mergeMap, map, startWith, catchError, tap, switchMap, takeUntil, take, concatMap, debounceTime, distinctUntilChanged, last, debounce, takeLast, skip } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { KonvaComponent } from '../konva/konva.module';
import { KonvaConfig, TaggedArea } from '../models/tagging.interface';
const colorPalette = {
  COLORMAP_JET: '#FFF',
  COLORMAP_RAINBOW: '#FFF',
  COLORMAP_HSV: '#FFF',
  COLORMAP_HOT: '#FFF',
  COLORMAP_INFERNO: '#FFF',

};
import {
  EMPTY_SELECTION,
  DrawingMode,
  getPolygonRect,
  getScaledPolygon,
  inverseScaleXY,
  scaledLabelOffset
} from '../tagging/tagging.helpers';
import { Project } from '../projects/item/project.type';
import { MatMenuTrigger } from '@angular/material/menu';
import { IImage } from '../models/image.interface';
import { UiService } from '../services/ui.service';
const fontSize = 16;
const fontColor = 'white';
const color = '#EB5757';
@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss']
})
export class ImageModalComponent implements OnInit {
  image;
  @ViewChild('stage') stage: KonvaComponent;
  @ViewChild('container') container: ElementRef;
  taggingModes;
  get taggingMode(): DrawingMode {
    return this.drawingMode;
  }
  set taggingMode(value: DrawingMode) {
    this.drawingMode = value;
    this.resetAreaSelection();
  }
  public tags: string[] = [];
  public sensitiveTags: any[] = [];
  public tagsSliderOptions: any[] = [];
  public tagsTextConfigs: any[] = [];
  public colors = {};
  public containerSize: any;
  public imageAnnotationsSub: Subscription;
  public configStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 750,
    height: 450,
  });

  public configImg$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    width: 0,
    height: 0,
    image: null,
  });

  public selectionConfig$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    ...EMPTY_SELECTION,
  });
  public selectionShadowConfig$: BehaviorSubject<
    KonvaConfig
  > = new BehaviorSubject({ ...EMPTY_SELECTION });
  public selectionLineConfig$: BehaviorSubject<
    KonvaConfig
  > = new BehaviorSubject({ ...EMPTY_SELECTION });

  public taggedAreas: Map<string, TaggedArea[]> = new Map();
  public isDrawing = false;
  public tagsListHeight = 0;
  public tagsListWidth = 0;

  public get activeImg(): { doc: any; image?: HTMLImageElement } {
    return this.image
  }

  public activeImgAreaIdx: number;

  public get activeImgAreas(): TaggedArea[] {
    return this.taggedAreas.get(this.imageId) || [];
  }

  public get activeImageArea(): TaggedArea {
    return this.activeImgAreas[this.activeImgAreaIdx];
  }



  private drawingMode: DrawingMode;
  public folders = [];
  public project: Project;
  public groups$: Observable<any[]>;
  public folderContents$: Observable<any[]>;
  public contextMenuPosition = { x: '0px', y: '0px' };
  public calcValue = `calc(${50}% + ${10}px)`;
  // for childContextImages
  relations = [];
  relationsWithBaseImagesCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  private isSetChildImages = true;
  childContextImages = new Map<string, IImage>();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  imageId: any;
  projectId: string;
  public imageAnnotations = [];
  public isSetContainerSize: boolean = true;
  private onDestroy$ = new Subject();
  constructor(
    public backend: BackendService,
    private readonly cdr: ChangeDetectorRef,
    private router: Router,
    public uiService: UiService,
    public dialogRef: MatDialogRef<ImageModalComponent>,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.image = data;
    this.projectId = data.projectId;
    this.imageId = data.id;
    this.getTags(data.projectId);
    this.project = this.uiService.allProjects.find(o=>o.id === data.projectId);
    this.activate(data)
    this.getRelations();

  }
  ngOnInit(): void {
    if (this.data.parentEl == 'left') {
      this.dialogRef.updatePosition({ top: '110px', 'left': '5px' })
    }

    if (this.data.parentEl == 'right') {
      this.dialogRef.updatePosition({ top: '110px', 'right': '10px' })
    }
  }

  preview(image) {
    this.dialogRef.close();
    this.router.navigateByUrl(`/dashboard/projects/${image.projectId}/images/${image.id}`);

  }

  isSetConfigStage = true;
  isFirstScroll = true;

  ngAfterViewInit() {
    if (window) {
      this.renderer.listen(window, 'resize', () => {
        this.isSetConfigStage = true;
      });
    }

  }


  ngAfterViewChecked(): void {
    if (this.isSetConfigStage && this.container) {
      this.isSetConfigStage = false;

      this.setContainerSize();
      if (this.image)
        this.activate(this.image);
    }

    if (this.container && this.isSetContainerSize) {
      this.isSetContainerSize = false;
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };
    }
  }

  ngOnDestroy(): void {
    if (this.imageAnnotationsSub)
      this.imageAnnotationsSub.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setContainerSize(): void {
    if (this.container) {
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };
      this.configStage$.next(this.containerSize);
      this.configImg$.next(this.containerSize);
    }
  }

  getTagSensitive(area) {
    return this.sensitiveTags.map(tag => tag.tag).includes(area.tag);
  }

  activeSensitiveTags(){
    return  this.activeImgAreas.filter(area=>area.annotation  &&  this.getTagSensitive(area))
  }

  getTags(projectId: string): void {
    this.backend.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      const activeTags = tags.filter(
        (x) => x.status !== 'de-active'
      );

      activeTags.forEach((activeTag, i) => {
        if (!this.colors[activeTag.tag]) {
          this.colors[activeTag.tag] = activeTag.color || this.backend.CONSTANTS.tags_colors[
            i % this.backend.CONSTANTS.tags_colors.length
          ];
        }
      });

      const height = 20;
      const padding = 3;

      this.tags = activeTags.map((tag) => tag.tag);
      this.sensitiveTags = activeTags.reduce((acc, tag) => tag.sensitivity ? [...acc, tag] : acc, []);
      this.tagsSliderOptions = [];
      this.tagsListHeight = this.tags.length * (height + padding + 3);
      this.tagsListWidth = Math.max(...this.tags.map(tag => tag.length)) * ((fontSize - 2) / 2) + padding;

      this.tagsTextConfigs = activeTags.map((tag, idx) => {
        const space = 3;

        return {
          label$: new BehaviorSubject({
            y: idx * (height + space),
            ...inverseScaleXY(this.stage ? this.stage.getScale() : 1)
          }),
          tag$: new BehaviorSubject({
            fill: '#222831',
            ...inverseScaleXY(this.stage ? this.stage.getScale() : 1)
          }),
        };
      });
    });
  }

  getImageScale(image): { widthScale: number; heightScale: number } {
    const { width, height } = image['image'];
    const { width: stageWidth, height: stageHeight } = this.configStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;

    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }

  activate(img): void {
    this.setContainerSize();
    const image = new Image();
    image.onload = () => {
      this.image['image'] = image;
      const { height, width } = image;
      const { widthScale, heightScale } = this.getImageScale(this.image);
      const newWidth = width * widthScale;
      const newHeight = height * heightScale;
      this.getImageAnnotations(img.id);
      const newSize = {
        width: newWidth,
        height: newHeight,
      };
      this.configStage$.next(newSize);
      this.configImg$.next({
        ...newSize,
        image: image
      });
      this.cdr.detectChanges();
    };
    image.src = img.colorMediumFileUrl || img.mediumFileUrl;
  }

  getPalette() {
    return colorPalette[
      this.project?.colorPalette || '335AFF']
  }


  getImageAnnotations(imageId) {
    if (this.imageAnnotationsSub) {
      this.imageAnnotationsSub.unsubscribe();
    }
    this.imageAnnotationsSub = this.backend
      .getImageAnnotations$<{
        polygons: {
          id: string,
          polygon: KonvaConfig;
          tag: string,
          hasContext: boolean,
          note?: string,
        }[];
        thermalPolygons: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        hotspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        coldspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        sensitive: number;
      }>(imageId)
      .subscribe((annotations) => {
        if (this.getImageScale(this.image)) {
          const annotationData =
            annotations && annotations.polygons
              ? annotations.polygons.map((currentArea) => {
                const { tag, hasContext } = currentArea;
                let taggingItem = {
                  ...this.updateTaggingItemConfig(currentArea.polygon, tag, currentArea.id, hasContext, currentArea['sensitive'], currentArea['levels']),
                };
                taggingItem = {
                  ...taggingItem,
                  annotation: {
                    ...taggingItem.annotation,
                    tag: tag,
                    polygon: {
                      ...currentArea.polygon,
                      sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
                    },
                    levels: this.sensitiveTags.find(o => o.tag === tag)?.levels || [],
                    hasContext: currentArea.hasContext,
                  }
                };

                return taggingItem;
              })
              : [];

          let data = [];
          if (annotations && annotations.thermalPolygons) {
            data = annotations.thermalPolygons.map(obj => ({ ...obj, type: 'thermalPolygons' }))
          }
          if (annotations && annotations.coldspots) {
            data = data.concat(annotations.coldspots.map(obj => ({ ...obj, type: 'coldspots' })))
          }
          if (annotations && annotations.hotspots) {
            data = data.concat(annotations.hotspots.map(obj => ({ ...obj, type: 'hotspots' })))
          }
          const thermalData = data.map((currentArea: any) => {
            let temperatureItem = {
              maxTemperature: currentArea.maxTemperature,
              minTemperature: currentArea.minTemperature,
              temperature: currentArea.temperature
            }

            let taggingItem = {
              ...this.updateTaggingItemConfig(
                 currentArea.polygon, "thermalPolygon", currentArea.id, false, currentArea.sensitive, currentArea.note
              ),
            };
            taggingItem = {
              ...taggingItem,
              annotation: {
                ...taggingItem.annotation,
                ...temperatureItem,
                tag: "",
                type: currentArea.type,
                polygon: {
                  ...currentArea.polygon,
                  sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
                },
                note: currentArea.note,
                noteCreatedBy: currentArea.noteCreatedBy,
                lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
                levels: []
              }
            };

            return taggingItem;
          })

          this.imageAnnotations = annotationData.concat(thermalData);;
          this.taggedAreas.set(imageId, annotationData.concat(thermalData));
          if (this.isSetChildImages && this.image) {
            this.isSetChildImages = false;
            this.activeImgAreas.filter(area => area.annotation && area.annotation.hasContext).forEach(area => {
              return this.getChildImageForContext(area);
            })
          }
          this.cdr.detectChanges();
        }
      });
  }

  getMidpointOfPolygon(polygonPoints: number[]) {
    const xValues = polygonPoints.filter((_, i) => i % 2 === 0);
    const yValues = polygonPoints.filter((_, i) => i % 2 === 1);

    // Calculate the average midpoint
    const xSum = xValues.reduce((sum, point) => sum + point, 0);
    const ySum = yValues.reduce((sum, point) => sum + point, 0);

    return {
      x: xSum / xValues.length,
      y: ySum / yValues.length,
    };

  }

  getAreaTempratureLabelStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }

    const points = area.config$.getValue().points;
    const { x, y, height, width } = getPolygonRect(points);
    const positionOffset = this.stage.getStage().position()
    const scale = this.stage.getScale()

    if (area.annotation && area.annotation.closedType) {
      if (area.annotation.closedType != "rectangle" &&
        area.annotation.closedType != "point") {
        return {
          top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
          left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
        };
      } else {

        if (area.annotation.closedType === 'point') {
          return {
            top: `${positionOffset.y - 15 + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
          };
        } else {
          return {
            top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (x + 5 * (1 / scale)) * scale}px`,
          };
        }
      }
    }

  }


  updateTaggingItemConfig(area: KonvaConfig, tag: string, id?: string, hasContext?: boolean, sensitive?, note?: string, levels?,
    minTemperature?: number,
    maxTemperature?: number, temperature?: number): TaggedArea {
    const { widthScale, heightScale } =
      this.getImageScale(this.image);
    if (!widthScale && !heightScale) {
      return;
    }
    const polygon = {
      points: id ? getScaledPolygon(area.points, {
        x: widthScale,
        y: heightScale,
      }) : area.points,
    };
    const { originX, originY, x, y, width, height } = getPolygonRect(polygon.points);
    const { tagsGroupX, tagsGroupY, tagTextY } = this.getTagsGroupXY(polygon);

    const annotations = id ? { tag, annotation: { id, sensitive, levels, ...area, minTemperature, maxTemperature, temperature } } : {};
    return {
      ...annotations,
      config$: area['closedType'] == "point" ?
        new BehaviorSubject({
          ...polygon,
          radius: 3,
          x: polygon.points[0],
          y: polygon.points[1],
          stroke: !note ? '#FFF' : '#4456F9',
          fill: this.getPalette(),
          strokeWidth: 2,
        })
        : new BehaviorSubject({
          ...polygon,
          stroke:
            tag == 'thermalPolygon' ?
              this.getPalette()
              : tag && tag !== 'context' ? this.colors[tag] || this.colors[tag.toLowerCase()] : (tag === 'context' ? '#335AFF' : color)
          ,
          fill: this.uiService.isMask ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)',
          strokWidth: 3,
          strokeScaleEnabled: false,
          closed: area['closedType'] == "line" ? false : true,
          dash: tag === 'context' ? [2, 2] : null
        }),
      textConfig$: new BehaviorSubject({
        fontSize: fontSize - 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        x: originX,
        y: tagTextY,
        fill: '#fff',
        text: tag,
        padding: 5,
        wrap: 'none',
        lineHeight: 1,
        ellipsis: true,
        ...inverseScaleXY(this.stage.getScale() ),
        listening: false,
        visible: (tag !== 'thermalPolygon')
      }),
      tag$: new BehaviorSubject({
        fill: '#222831',
        ...inverseScaleXY(this.stage.getScale()),
        listening: false,
        visible: (tag !== 'context' && tag !== 'thermalPolygon')
      }),
      label$: new BehaviorSubject({
        y: originY < tagTextY ? 5 : tagTextY,
        x,
        listening: false,
        visible: (tag !== 'context')
      }),
      tagsGroupConfig$: new BehaviorSubject({
        x: tagsGroupX,
        y: tagsGroupY,
        ...inverseScaleXY(this.stage.getScale()),
        visible: !!!id
      }),
      closedType: area['closedType']

    };

  }




  handleZoom(event: WheelEvent): void {
    if (!this.configImg$.getValue().image) {
      return;
    }
    this.cdr.detectChanges();
    this.taggedAreas.set(this.imageId, this.imageAnnotations)
    if (this.taggedAreas.get(this.imageId)) {
      this.updateAllAreaTags();
    }

  }

  getTagsGroupXY(
    area: KonvaConfig
  ): { tagsGroupX: number; tagsGroupY: number, tagTextY: number } {
    const {
      width: stageWidth,
      height: stageHeight,
    } = this.configStage$.getValue();
    const { x, y, width, height, originY } = getPolygonRect(area.points);

    const transform = this.stage.getStage().getTransform().copy();
    const { x: x1, y: y1 } = transform.point({ x, y }),
      { x: x2, y: y2 } = transform.point({ x: x + width, y: y + height });

    const tagsGroupRight = x + width + 1.5 / this.stage.getScale();
    const tagsGroupLeft = x - (this.tagsListWidth + 1.5) / this.stage.getScale();
    const tagsGroupTop = y;
    const tagsGroupBottom = y + height - this.tagsListHeight / this.stage.getScale();

    const tagTextTop = y - scaledLabelOffset(this.stage.getScale());
    const tagTextBottom = y + height + 1.5 / this.stage.getScale();

    const tagsGroupX =
      stageWidth < x2 + this.tagsListWidth
        ? tagsGroupLeft
        : tagsGroupRight;

    const tagsGroupY =
      stageHeight < y1 + this.tagsListHeight
        ? tagsGroupBottom
        : tagsGroupTop;

    const tagTextY =
      y1 - scaledLabelOffset(1) > 0
        ? tagTextTop
        : tagTextBottom

    return {
      tagsGroupX: tagsGroupX < 0 ? 5 : tagsGroupX,
      tagsGroupY,
      tagTextY
    };
  }

  initTaggingItemConfig(area: KonvaConfig): TaggedArea {
    const { tagsGroupX, tagsGroupY, tagTextY } = this.getTagsGroupXY(area);

    const { originX, originY, x, y } = getPolygonRect(area.points);

    return {
      config$: new BehaviorSubject({
        ...area,
        stroke: color,
        closed: true,
        listening: false,
      }),
      textConfig$: new BehaviorSubject({
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        fontSize,
        x,
        y: tagTextY,
        fill: fontColor,
        text: '',
        listening: false,
        ...inverseScaleXY(this.stage.getScale())
      }),
      tag$: new BehaviorSubject({
        fill: '#222831',
        listening: false,
        ...inverseScaleXY(this.stage.getScale())
      }),
      label$: new BehaviorSubject({
        y: tagTextY,
        x,
        listening: false
      }),
      tagsGroupConfig$: new BehaviorSubject({
        x: tagsGroupX,
        y: tagsGroupY,
        ...inverseScaleXY(this.stage.getScale())
      }),
      tag: '',
    }
  }

  updateAllAreaTags(): void {
    this.taggedAreas.set(
      this.imageId,
      this.taggedAreas.get(this.imageId)
        .map(area => {

          if (area.annotation) {
            const polygon = area.annotation.polygon ? area.annotation.polygon : { points: area.annotation.points };
            return this.updateTaggingItemConfig(
              polygon,
              area.tag,
              area.annotation.id,
              area.annotation.hasContext,
              area.annotation.sensitive,
              area.annotation.levels
            );
          } else {
            return this.updateTaggingItemConfig(area.config$.getValue(), '');
          }
        })
    );

    this.cdr.detectChanges();
  }


  getSliderClass(area) {
    if (area?.annotation?.sensitive === '2') {
      return 'high';
    } else if (area?.annotation?.sensitive === '1') {
      return 'medium';
    }
    else {
      return 'low';
    }
  }

  getSliderStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }

    const { height } = getPolygonRect(area.config$.getValue().points);
    const scale = this.stage.getScale();
    return {
      minHeight: `${Math.max((height) * scale) + (scale * 3)}px`,
    };
  }

  getAreaTagPanelStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }

    const { tagsGroupX, tagsGroupY } = this.getTagsGroupXY(area.config$.getValue());
    const { x: left, y: top, height } = getPolygonRect(area.config$.getValue().points);
    const scale = this.stage.getScale();

    const transform = this.stage.getStage().getTransform().copy();
    let { x, y } = transform.point({ x: tagsGroupX, y: tagsGroupY });

    if (tagsGroupX < left) x += this.tagsListWidth - 20;

    if (tagsGroupY < top) y += this.tagsListHeight - Math.max((height - 5) * scale, 50);

    return {
      top: `${y}px`,
      left: `${x}px`,
    };
  }

  getChildImageStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!area) { return }
    if(!area.config$) { return }
    const points = getPolygonRect(area.config$.getValue().points);
    return {
      top: `${points.y}px`,
      left: `${points.x - 75}px`,
    };
  }

  private resetAreaSelection(): void {
    this.isDrawing = false;
    this.selectionConfig$.next(EMPTY_SELECTION);
    this.selectionLineConfig$.next(EMPTY_SELECTION);
    this.selectionShadowConfig$.next(EMPTY_SELECTION);
  }

  onContextMenu(event: MouseEvent, item: any): void {
    event.preventDefault();
    console.log('onContextMenu', event);
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  showChildContextImages(area: TaggedArea, enabled: boolean) {
    if (area.tag === 'context') {
      this.childContextImages.get(area.annotation.id).isShowed = enabled;
      this.childContextImages.get(area.annotation.id).isClicked = enabled;
    }
  }

  area;
  hoverChildContextImages(area: TaggedArea, enabled: boolean) {
    if (!area.annotation) { return }
    this.area=area;
    if (area.tag === 'context' && this.childContextImages.get(area.annotation.id)) {
      if (!this.childContextImages.get(area.annotation.id).isClicked) {
        this.childContextImages.get(area.annotation.id).isShowed = enabled;
      }
    }

    if (enabled) {
      area.annotation.isHover = true;


    } else {
      area.annotation.isHover = false;
    }
  }

  getRelations(): void {
    this.backend.getImageRelation$(this.projectId, `images/${this.imageId}`).pipe(
      take(1),
      tap((relations: any[]) => {
        this.relations = relations;
        this.isSetChildImages = true
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  getChildImageForContext(area: TaggedArea): void {
    const relation = this.relations.filter(relation => relation.annotationId === area.annotation.id)[0];
    if (relation) {
      const childImageId = relation.childImagePath.replace('images/', '');
      this.backend.getImage$(childImageId).pipe(first()).subscribe((image: IImage) => {
        this.childContextImages.set(area.annotation.id, { ...image, id: childImageId, isShowed: false, isClicked: false });
      });
    }
  }

  getAreaSeverityIconStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }
    const { x, y, height, width } = getPolygonRect(area.config$.getValue().points);
    const positionOffset = this.stage.getStage().position();
    const scale = this.stage.getScale();

    if (area.annotation && area.annotation.closedType && area.annotation.closedType != "rectangle") {
      return {
        top: `${positionOffset.y - 10 + (area.config$.getValue().points[area.config$.getValue().points.length - 1] * scale)}px`,
        left: `${positionOffset.x + (10) + (area.config$.getValue().points[area.config$.getValue().points.length - 2] * scale)}px`,
      };

    }
    else {
      return {
        top: `${positionOffset.y + (y + height - 15 * (1 / scale)) * scale}px`,
        left: `${positionOffset.x + (x + width - 15 * (1 / scale)) * scale}px`,
      };
    }
  }

  getBGColor(area) {
    if (area.annotation.sensitive == 0) {
      return '#d4d4d4';
    } else {
      return area.annotation.levels?.find(o => o.level == area.annotation.sensitive)?.color;
    }
  }
  getTitle(area) {
    if (!area.annotation.sensitive) {
      return 'not marked';
    } else {
      const level = area.annotation.levels?.find(o => o.level == area.annotation.sensitive);
      if (level) {
        return `level ${level.level},   ${level?.title}`;
      }
    }
  }

  calculateLeftPosition() {
    if (this.data.parentEl == 'left') {
      return 10;
    } else if (this.data.parentEl == 'right') {
      return window.innerWidth / 2 + 10;
    }
  }
  calculateRightPosition() {
    if (this.data.parentEl == 'right') {
      return 10;
    } else if (this.data.parentEl == 'left') {
      return window.innerWidth / 2 + 10;
    }
  }

  getDetectionColor(annotation, isBackground,project) {
    const minTemp = annotation.minTemperature;
    const maxTemp = annotation.maxTemperature;
    const projectMinTemp = project.minTemperature;
    const projectMaxTemp = project.maxTemperature;
    const stdDev = project.std_dev;
  
    if (projectMinTemp && minTemp && maxTemp &&
      projectMaxTemp && stdDev) {
      if (maxTemp >= (projectMaxTemp + stdDev)) {
        return isBackground ? '#FFF' : 'red'; //hotspots
      }
      if (minTemp <= (projectMinTemp - stdDev)) {
        return isBackground ? '#FFF' : 'blue';  //coldspots
      }
    } else {
        if (annotation.temperature && projectMinTemp &&
          projectMaxTemp && stdDev) {
          if (annotation.temperature >= (projectMaxTemp + stdDev)) {
            return isBackground ? '#FFF' : 'red'; //hotspots
          }
          if (annotation.temperature <= (projectMinTemp - stdDev)) {
            return isBackground ? '#FFF' : 'blue';  //coldspots
          }
        }
  
      
    }
    return isBackground ? '#2f1818' : 'fff';  //normal
  }
  
}
