<ion-row class="project-groups">
<div class="row">
    <div class="col-md-6 pr-0"><h3>{{data.keyProject.name}}</h3></div>
    <div class="col-md-6 pl-0"><h3>{{data.valueProject.name}}</h3>
        <button (click)="dialogRef.close()" class="close">&times;</button></div>
</div>
    <div class="row group-list">
        <div class="col-md-6 pr-0">            
            <div *ngFor="let group of keyProjectGroups">
                <p class="d-flex align-items-center py-2 px-3 m-0  border-bottom">
                    <input type="checkbox" class="checkbox mr-2" [(ngModel)]="group.checked" />
                    <span>
                        {{group.groupName}}
                    </span>

                </p>

            </div>
        </div>
        <div class="col-md-6 pl-0">
            
            <div *ngFor="let group of valueProjectGroups">
                <p class="d-flex align-items-center py-2 px-3 m-0  border-bottom">
                    <input type="checkbox" class="checkbox mr-2" [(ngModel)]="group.checked" />
                    <span>
                        {{group.groupName}}
                    </span>
                </p>

            </div>
        </div>

       
    </div>
    <div class="row"> <div class="col-md-12 text-center border-top">
        <button (click)="filter();" [disabled]="!selectedGroups()" mat-button
            class="btn btn-primary btn-apply mt-3">
            Apply
        </button>
    </div></div>
</ion-row>