import { Input, Component, OnInit, ViewChild, OnDestroy, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { combineLatest, Subscription } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { BackendService } from '../../services/backend.service';
import { Project } from './project.type';
import { Overlay } from '@angular/cdk/overlay';
import { AssignComponent } from 'src/app/assets/assign/assign.component';
import { Router } from '@angular/router';
@Component({
  selector: 'projects-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ProjectsItemComponent implements OnInit, OnDestroy {
  @ViewChild('infoDialog') infoDialog: any;
  public deleting = false;
  public deleteBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: '',
    spinnerSize: 24,
    //buttonColor: 'warn',
    // spinnerColor: 'primary',
    // mode: 'indeterminate',
    customClass: 'mat-icon-button',
    buttonIcon: {
      fontIcon: 'delete_outline'
    }
  };
  @Input() project: Project;
  @Input() selectedTab: string;
  @Input() readonly: boolean;
  @Input() assets;
  @Input() companyAssets;

  @Output() renameEvent = new EventEmitter();

  @ViewChild('deleteDialog') deleteDialog: any;
  public projectNameInput: string;
  deleteDialogRef;

  deleteDialogSubcription = new Subscription();
  public annotationSubscription: Subscription = new Subscription();
  public imageAnnotationsSub: Subscription;
  constructor(
    private confirmDialog: WaMatConfirmDialog,
    public backend: BackendService,
    public dialog: MatDialog,
    private uiService: UiService,
    private overlay: Overlay,
    private router: Router,
  ) { }
  ngOnInit(): void {
    if (!this.uiService.summary.find(o => o.id == this.project.id)) {
        this.backend.getProjectSummary(this.project.id).subscribe((result) => {
        this.project.projectSummary = result;
        const index = this.uiService.summary.findIndex(o => o.id == this.project.id);
        if (index === -1) {
          this.uiService.summary.push(result);
        } else {
          this.uiService.summary[index] = result;
        }
      })
    } else {
      this.project.projectSummary = this.uiService.summary.find(o => o.id == this.project.id);
    }
   
  }

  getFeatures(feature) {
    const features = []
    if (feature) {
      const obj = Object.keys(feature);
      obj.forEach(key => {
        features.push(`${key} [${feature[key]}]`)
      });
      return features.join(", ")
    }
  }
  isOwnerOfProject() {
    return this.project.people[this.backend.getCurrentUser().uid] &&
      this.project.people[this.backend.getCurrentUser().uid].role === 'owner';
  }

  getRoleOfProject(): string {
    const currentUser = this.project.people[this.backend.getCurrentUser().uid];
    return currentUser ? currentUser.role : null;
  }

  ngOnDestroy(): void {
    this.deleteDialogSubcription.unsubscribe();
    if (this.annotationSubscription) {
      this.annotationSubscription.unsubscribe();
    }
  }

  handleDelete(project: Project) {
    this.confirmDialog
      .open(
        `Are you sure you want to delete project "${project.name}"?`,
        {
          trueButtonTitle: 'Delete',
          falseButtonTitle: 'Cancel'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.deleteBtnOptions.active = true;
          // this.backend.userProjects.doc(project.id).delete();
          this.backend.markProjectDeleted(project.id);
        }
      });
  }

  // functions for delete modal:
  openDeleteDialog(project): void {
    this.deleteDialogRef = this.dialog.open(this.deleteDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: { project: project }
    });

    this.deleteDialogSubcription = this.deleteDialogRef.afterClosed().subscribe(result => {
      this.projectNameInput = '';
    });
  }

  closeDeleteDialog(): void {
    this.projectNameInput = '';
    this.deleteDialogRef.close();
  }

  async deleteProject(project) {
    try {
      this.deleteBtnOptions.active = true;
      this.closeDeleteDialog();
      if (this.uiService.selectedProjectId === project.id) {
        this.uiService.selectedProjectId = undefined;
      }
      // await this.backend.userProjects.doc(project.id).delete();
      await this.backend.markProjectDeleted(project.id);
    } catch (error) {
      this.deleteBtnOptions.active = false;
      throw error;
    }
  }

  sendRenameEvent(project): void {
    this.renameEvent.emit(project);
  }


  assetView(project) {
    if (project.assetId) {

      const data = JSON.stringify({
        'assets': this.assets,
        'companyAssets': this.companyAssets,
        'id': project.assetId,
        'projects': this.uiService.allProjects
      })
      this.router.navigate(["dashboard/assets"], {
        state: {
          data: data
        }
      })
    } else {
      this.dialog.open(AssignComponent, {
        width: '510px',
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: {
          projectId: project.id
        }
      })

    }
  }

  checkTopSeverity(severities) {
    if (severities) {
      const levels = [];
      severities.forEach(severity => {
        const key: any = Object.keys(severity);
        levels.push({
          action: severity[key]?.action,
          title: severity[key]?.title,
          color: severity[key]?.color,
          highest_level: severity[key]?.highest_level || 0,
          tag: key,
          total: severity[key]?.total
        })
      });
      const max = Math.max(...levels.map(o => o.highest_level))
      if (max) {
        return levels?.find(o => o.highest_level == max)?.color;
      }
    }
  }
}
