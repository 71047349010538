import { BackendService } from '../services/backend.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UiService } from '../services/ui.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../projects/item/project.type';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GeneralService } from '../services/general.service';

@Component({
  selector: 'app-image-titles-dialog',
  templateUrl: './image-titles-dialog.component.html',
  styleUrls: ['./image-titles-dialog.component.scss'],
})
export class ImageTitlesDialogComponent implements OnInit, OnDestroy {
  public projects$: Observable<Project[]>;
  public folders$: Observable<any[]>;
  public folderImages$: Observable<any[]>;

  public relations$ = new BehaviorSubject<any>([]);

  private imageIds: string[] = [];
  private imagesThumbs = {};
  private imageId: string;
  private projectId: string;

  private onDestroy$ = new Subject();

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  constructor(
    public dialogRef: MatDialogRef<ImageTitlesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backendService: BackendService,
    public uiService: UiService,
    public router: Router,
    private generalService: GeneralService
  ) {
    this.projects$ = this.uiService.projects$;
    this.folders$ = this.uiService.folders$;
    this.imageId = data.imageId;
    this.projectId = data.projectId;

    this.getRelations();
  }

  getRelations() {
    if (this.data.type === 'report') {
      this.getAllRelations();
    } else if (this.data.type === 'image') {
      this.getRelationsForParent();
    } else if (this.data.type === 'child-image') {
      this.getRelationsForChild();
    }
  }

  onScroll() {
    if (this.data.type === 'report') {
      this.onScrollForAllRelations();
    } else if (this.data.type === 'image') {
      this.onScrollForParent();
    } else if (this.data.type === 'child-image') {
      this.onScrollForChild();
    }
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
  }

  // get Relations for parentImagePath
  // ================
  getRelationsForParent(): void {
    this.backendService.getImageRelationFirst$(this.projectId, 'parentImagePath', `images/${this.imageId}`).pipe(
      take(1),
      tap((relations: any[]) => {
        this.tapForParent(relations);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  onScrollForParent() {
    if (this.backendService.latestRelationEntry) {
      this.backendService.getImageRelationNext$(this.projectId, 'parentImagePath', `images/${this.imageId}`).pipe(
        tap((relations: any[]) => {
          this.tapForParent(relations);
        }),
        takeUntil(this.onDestroy$)
      ).subscribe();
    }
  }
  
  tapForParent(relations: any[]) {
    this.relations$.next(
      this.generalService.makeImageArrayUnique(this.relations$.value.concat(relations))
    );
    this.imageIds.push(`images/${this.imageId}`);
    relations.forEach(rel => {
      this.imageIds.push(rel.childImagePath);
    });
    this.loadImages();
  }
  // ================

  // get Relations for childImagePath 
  // ================
  getRelationsForChild(): void {
    this.backendService.getImageRelationFirst$(this.projectId, 'childImagePath', `images/${this.imageId}`).pipe(
      take(1),
      tap((relations: any[]) => {
        this.tapForChild(relations);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  onScrollForChild() {
    if (this.backendService.latestRelationEntry) {
      this.backendService.getImageRelationNext$(this.projectId, 'childImagePath', `images/${this.imageId}`).pipe(
        tap((relations: any[]) => {
          this.tapForChild(relations);
        }),
        takeUntil(this.onDestroy$)
      ).subscribe();
    }
  }
  
  tapForChild(relations: any[]) {
    this.relations$.next(
      this.generalService.makeImageArrayUnique(this.relations$.value.concat(relations))
    );
    this.imageIds.push(`images/${this.imageId}`);
    relations.forEach(rel => {
      this.imageIds.push(rel.parentImagePath);
    });
    this.loadImages();
  }
  // =============================

  // get Relations for reports
  // ================
  getAllRelations(): void {
    this.backendService.getAllImagesRelationsFirst$(this.projectId).pipe(
      tap((relations: any[]) => {
        this.tapForAllRelations(relations);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  onScrollForAllRelations(): void {
    this.backendService.getAllImagesRelationsNext$(this.projectId).pipe(
      tap((relations: any[]) => {
        this.tapForAllRelations(relations);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  tapForAllRelations(relations: any[]) {
    this.relations$.next(
      this.generalService.makeImageArrayUnique(this.relations$.value.concat(relations))
    );
    relations.forEach(rel => {
      rel.isChecked = false;
      this.imageIds.push(rel.parentImagePath);
      this.imageIds.push(rel.childImagePath);
    });
    this.loadImages();
  }
  // ================

  loadImages(): void {
    this.imagesThumbs = {};
    this.imageIds.forEach(img => {
      const id = img.replace('images/', '');
      this.backendService.getImage$<any>(id).pipe(take(1)).subscribe((image) => {
        if (!image) {
          console.log('[image is absent] =>', id)
        }
        this.imagesThumbs[img] = image.thumbFileUrl;
      });
    });
  }

  deleteRelation(relation: any, update: boolean = true): void {
    this.backendService.removeImageRelation(this.projectId, relation.parentImagePath, relation.childImagePath).pipe(
      switchMap(() => {
        this.backendService.addHistory([this.data.imageId],this.data.projectId, "context removed", "context",relation.imageId)
        return this.backendService.removeAnnotation(relation.annotationId, relation.imageId);
   
      }),
      take(1)
    ).subscribe(() => {
      if (update) {
        this.router.navigate['../' + this.imageId];
        this.getRelations();
      }
    });
  }

  deleteAllRelations(): void {
    this.relations$.value.forEach((relation, idx) => {
      this.deleteRelation(relation, false);
    });
    this.relations$.next([]);
  }

  handleProjectChange(): void {}

  filterCheckedArray(array): any[] {
    return array.filter(item => item.isChecked);
  }

  addRelationsToReport(): void {
    this.dialogRef.close({
      relations: this.filterCheckedArray(this.relations$.value),
      imagesThumbs: this.imagesThumbs
    });
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }
}
