import redraw from './redraw';

export default function updateProps(
  component,
  props = {},
  oldProps = {}
): void {
  if (props.hasOwnProperty('id')) {
    console.warn(`
      ng2-konva: You are using "id" attribute for Konva node.
      In some very rare cases it may produce bugs.
      Currently we recommend not to use it and use "name" attribute instead.\
    `);
  }

  const instance = component._stage;

  Object.keys({ ...oldProps, ...props }).forEach((prop) => {
    const propIsEvent = prop.slice(0, 2) === 'on';
    const oldValue = oldProps[prop];
    const newValue = props[prop];
    const propChanged = oldValue !== newValue;

    if (!propChanged) {
      return;
    }

    if (propIsEvent) {
      let eventName = prop.substr(2).toLowerCase();

      if (eventName.substr(0, 7) === 'content') {
        eventName =
          'content' +
          eventName.substr(7, 1).toUpperCase() +
          eventName.substr(8);
      }

      if (oldValue) {
        instance.off(eventName, oldValue);
      }
      if (newValue) {
        instance.on(eventName, newValue);
      }
    } else {
      instance.setAttr(prop, props[prop]);
    }
  });

  redraw(instance);
}
