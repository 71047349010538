import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BackendService } from '../../services/backend.service';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
@Component({
    selector: 'narration-bottom-sheet',
    templateUrl: 'narration-bottom-sheet.component.html',
})
export class NarrationBottomSheetComponent {
    narration: string;
    imageGroups = [];
    isChecked: boolean = false;
    public createBtnOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Save',
        raised: true,
        spinnerSize: 24,
        buttonColor: 'primary',
        spinnerColor: 'primary',
        mode: 'indeterminate',
        customClass: 'text-uppercase'
    };

    constructor(
        @Inject(MAT_DIALOG_DATA) public data,
        public dialogRef: MatDialogRef<NarrationBottomSheetComponent>,
        public dialog: MatDialog,
        private backendService: BackendService,
    ) {
        if (data && data.imageAnnotations) {
            if (data.imageAnnotations.isGroupNarration) {
                this.isChecked = true;
            }
            this.narration = data.imageAnnotations.narration;
        }

        const imageGroupSubs = this.backendService.getImageGroup(data.projectId, data.imageId).subscribe((resp: any) => {
            this.imageGroups = resp.docs.length ? resp.docs.map(doc => doc.data()) : [];
            imageGroupSubs.unsubscribe();
            if (data.imageAnnotations.isGroupNarration) {
                data.imageAnnotations.groupNarration.forEach(group => {
                    const index = this.imageGroups.findIndex(o => o.id == group.groupId);
                    if (index != -1) {
                        this.imageGroups[index].narration = group.narration;
                    }

                });
            }
        })
    }



    ngOnDestroy() {

    }

    saveNarration() {
        const groupNarration: any = [];
        if (this.isChecked) {
            this.imageGroups.forEach(group => {
                groupNarration.push({
                    groupId: group.id,
                    narration: group.narration || ''
                })
            });
        }
        this.createBtnOptions.active = true;
        this.backendService.updateAnnotationNarration(this.data.imageId, this.data.projectId, this.narration, this.isChecked, groupNarration)
            .subscribe(() => {
                this.createBtnOptions.active = false;
                this.dialogRef.close();
            })
    }
}