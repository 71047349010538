<div class="popup-header w-100 d-flex">
  <p class="w-100">
    Findings
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="cell">
  <div class="d-flex p-1">
    <div class="ml-5 w-100 d-flex" *ngIf="data.temperature ">
      <span class="material-icons">device_thermostat</span>
      {{data.temperature}}°C
    </div>
    <div class="ml-5 w-100  d-flex" *ngIf="data.minTemperature && data.maxTemperature">
      <span class="material-icons">device_thermostat</span>
      {{data.minTemperature}}°C - {{data.maxTemperature}}°C
    </div>
    <mat-icon class="delete-label" *ngIf="data.closedType ==='point'" (click)="deleteAnnotation()" matTooltip="delete anotation">delete_outline</mat-icon>
  </div>
  


  <div mat-dialog-content lcass="p-10">
    <form class="d-flex flex-column justify-content-between">
      <div class="form-field4">
        <textarea id="note" type="text" class="form-control input-field" placeholder="Add finding here..." maxlength="200"
          name="note" [(ngModel)]="note">
        </textarea>
      </div>
      <p *ngIf="lastEditedUser" class="mb-0 mt-2" style="font-size: 14px;"><b>Finding last edited by {{
          lastEditedUser?.displayName || lastEditedUser?.email || 'Unknown' }}</b></p>
    </form>
  </div>
</div>