import { AngularFireAuth } from '@angular/fire/auth';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
export const handleFirebaseError = (auth: AngularFireAuth,funcName:string) => {
  return catchError((e) => {
    console.log(funcName)
    // If any firebase error occured, moved to dashboard location
    console.error(e);
    if(auth.currentUser){
      document.location.href= document.location.origin;
    }
    /* auth.signOut()
      .then(() => {
         if (document) {
           if(sessionStorage.getItem("inactiveUser")){
             sessionStorage.removeItem("inactiveUser")
           }else{
             document.location.reload();
 
           }
         }
     });
     */
    return of(e);
  });
}