import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import * as moment from 'moment';

import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BackendService } from '../services/backend.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  public notifications = [];
  public deleteBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: '',
    spinnerSize: 24,
    customClass: 'mat-icon-button',
    buttonIcon: {
      fontIcon: 'delete_outline'
    }
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private backendService: BackendService,
    public dialogRef: MatDialogRef<NotificationComponent>
  ) {
    this.notifications = data;
    this.notifications.filter(o=>!o.isRead).forEach(alert => {
      this.backendService.readNotification(alert.id).then(() => { });
      
    });
  }


  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0' });
  }

  delete(id, index) {
    this.backendService.deleteNotification(id).then(() => { });
    this.notifications.splice(index, 1);
  }
  ngOnDestroy(): void { }

  getTime(date) {
    return date ? moment(date.toDate()).format('DD-MMM-YYYY hh:mm A') : "-"
  }
}