import { BackendService } from '../services/backend.service';
import { CombineQueriesService } from '../services/combine-queries.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { UiService } from '../services/ui.service';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../projects/item/project.type';
import { debounceTime, distinctUntilChanged, first, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IFolder } from '../models/folder.interface';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IGroup } from '../models/group.interface';
import { GeneralService } from '../services/general.service';
import { TaggedArea } from '../models/tagging.interface';
import { IImage } from '../models/image.interface';
import { SelectContainerComponent } from 'ngx-drag-to-select';
export interface ImageTitleImageData {
  projectId: string;
  groupId?: string;
  nodeImagesIds?: string[];
}
@Component({
  selector: 'app-image-title-image-dialog',
  templateUrl: './image-title-image-dialog.component.html',
  styleUrls: ['./image-title-image-dialog.component.scss'],
})
export class ImageTitleImageDialogComponent implements OnInit, OnDestroy {
  @ViewChild('selectContainerImageTitle') selectContainer: SelectContainerComponent;
  //public projects$: Observable<Project[]> = this.backendService.getProjects();

  private projectGroups: IGroup[];
  tab = 1;
  public folders: IFolder[] = [];
  public showedFolders$ = new BehaviorSubject<any[]>([]);
  public folderImages$ = new BehaviorSubject<any[]>([]);
  public filteredFolderImages$ = new BehaviorSubject<any[]>([]);
  public selectedImagesIds$ = new BehaviorSubject<string[]>([]);
  public loading$ = new BehaviorSubject<boolean>(true);
  public isFolderContentLoading$ = new BehaviorSubject<boolean>(false);

  public blockedImagesIds: string[] = [];

  public selectedProject$ = new BehaviorSubject(this.data.projectId);
  IImage
  public tags: any[];
  private selectedTag: string = 'Tagged';

  public set movedImages(images) {
    this.uiService.movedImages = images;
  }

  public get movedImages(): IImage[] {
    return this.uiService.movedImages;
  }

  get selectedProject(): string {
    return this.selectedProject$.value;
  }
  set selectedProject(val: string) {
    if (val !== this.selectedProject$.value) {
      this.selectedProject$.next(val);
    }
  }

  public selectedProjectName$ = this.selectedProject$.pipe(
    map((projects) => {
      const selectedProject = this.uiService.allProjects.find(o=>o.id == this.data.projectId);
      return selectedProject ? selectedProject.name : null;
    })
  );

  public selectedFolder$ = new BehaviorSubject<IFolder>(null);
  public foldersBreadcrumb: IFolder[] = [];

  private onDestroy$ = new Subject<void>();
  private startIndex = 0;
  private endIndex = 32;
  public selectedFolderId;
  public images = [];
  selectedImages: IImage[];
  public contextMenuPosition = { x: '0px', y: '0px' };
  constructor(
    public dialogRef: MatDialogRef<ImageTitleImageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageTitleImageData,
    public backendService: BackendService,
    public uiService: UiService,
    private combineQueriesService: CombineQueriesService,
    private generalService: GeneralService
  ) {
    if (data && data.nodeImagesIds) {
      this.blockedImagesIds = data.nodeImagesIds;
    }
    this.selectedProject$.pipe(
      tap(() => this.selectedImagesIds$.next([])),
      switchMap(projectId => this.combineQueriesService.getFolders$(projectId)),
      tap((folders) => {
        this.folders = folders;
        this.showedFolders$.next(folders);
        const selectedFolder = folders && folders.length > 0 ? { ...folders[0] } : null;
        this.selectedFolder$.next(selectedFolder);
        this.foldersBreadcrumb = [];
        this.setFolderBreadcrumb(selectedFolder);
        if (selectedFolder) {
          if (selectedFolder.children.filter(o => !o.doc.deletedDate).length)
            this.showedFolders$.next(selectedFolder.children.filter(o => !o.doc.deletedDate));
        } else {
          this.showedFolders$.next([]);
        }
      }),
      takeUntil(this.onDestroy$),
    ).subscribe();

    this.selectedFolder$.pipe(
      tap(() => this.loading$.next(true)),
      switchMap(folder => {
        if (folder === null) {
          return of([]);
        }
        // return this.uiService.images
        // return this.backendService.getFolderContentsFirst$(folder.path, this.selectedProject);
        return this.combineQueriesService.getFoldersImages$(folder.path, this.selectedProject);
      }),
      map(images =>
        images.filter(image => {
          const projectGroupsImages = []
          this.projectGroups.map(group => group.images).map(imageIdsArray => {
            if (imageIdsArray) {
              imageIdsArray.map(imageId => {
                if (!projectGroupsImages.includes(imageId)) projectGroupsImages.push(imageId);
              })
            }
          })
          if (!data.nodeImagesIds) {
            if (this.projectGroups.length) {
              if (this.projectGroups.find(o => o.id == this.data.groupId) &&
                this.projectGroups.find(o => o.id == this.data.groupId).images) {
                this.blockedImagesIds = this.projectGroups.find(o => o.id == this.data.groupId).images
              }
            }
          }
          return projectGroupsImages


        })),
      tap((images) => this.folderImages$.next(images)),
      tap((images) => this.filteredFolderImages$.next(images)),
      tap(() => this.loading$.next(false)),
      takeUntil(this.onDestroy$)
    ).subscribe((images) => {
      if (images.length) {
        this.startIndex = 0;
        this.endIndex = 32;
        this.selectTag(this.selectedTag);
      }
    })

  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });

    this.getProjectGroups();
    this.getTags();
  }

  getNextImagesSubscription: Subscription[] = []

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.getNextImagesSubscription.map((subscr: Subscription) => subscr.unsubscribe());
  }

  onScroll() {
    if (this.isFolderContentLoading$.value) {
      return
    }
    this.isFolderContentLoading$.next(true);
    setTimeout(() => {
      this.startIndex = this.endIndex;
      this.endIndex = this.startIndex + 32;
      if (this.selectedTag === 'All') {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.slice(this.startIndex, this.endIndex));
      } else if (this.selectedTag === 'Untagged') {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.filter((image) => !image.tags || !image.tags.length).slice(this.startIndex, this.endIndex));

      } else if (this.selectedTag === 'Tagged') {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.filter((image) => image.tags && image.tags.length && image.tags.map(o => o.tag) != 'context').slice(this.startIndex, this.endIndex));

      } else {
        this.images = this.filteredFolderImages$.value.concat(this.folderImages$.value.filter((image) =>
          image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
        ).slice(this.startIndex, this.endIndex));
      }

      this.filteredFolderImages$.next(this.images)
      this.selectContainer.update();
      this.isFolderContentLoading$.next(false);
    }, 200)
  }

  toggleFolder(folder: IFolder): void {
    if (this.folders.filter(o => o.docId == folder.docId).length) {
      this.selectedFolderId = folder.docId
    }
    this.selectedFolder$.next(folder);
    this.setFolderBreadcrumb(folder);
    this.scrollToTop();
    if (folder.children.filter(o => !o.doc.deletedDate).length > 0) {
      this.showedFolders$.next(folder.children.filter(o => !o.doc.deletedDate));
    }
  }

  selectFolder(folder: IFolder): void {
    this.selectedFolder$.next(folder);
    this.foldersBreadcrumb = [];
    this.selectedFolder$.next(folder);
    this.setFolderBreadcrumb(folder);
    this.scrollToTop();
    if (folder) {
      this.showedFolders$.next(folder.children.filter(o => !o.doc.deletedDate));
    } else {
      this.showedFolders$.next([]);
    }

  }

  goToRoot(): void {
    if (this.foldersBreadcrumb.length > 0) {
      this.selectedFolder$.next(this.foldersBreadcrumb[0]);
      this.showedFolders$.next(this.folders);
      this.foldersBreadcrumb = [];
    }
  }

  onClickImage(image: any, { checked }: MatCheckboxChange): void {
    let newVal: any[];
    if (checked && !this.selectedImagesIds$.value.includes(image.id)) {
      newVal = [...this.selectedImagesIds$.value, image.id];
    } else if (!checked && this.selectedImagesIds$.value.includes(image.id)) {
      newVal = this.selectedImagesIds$.value.filter(imageId => imageId !== image.id);
    }
    this.selectedImagesIds$.next(newVal);
  }

  addImagesToTitle(): void {
    const selectedImagesArr = this.folderImages$.value.filter(img => this.selectedImagesIds$.value.includes(img.id))
      .map(img => img.id);
    if (this.data.groupId) {
      this.uiService.groups$.subscribe(groups => {
        const selectedGroup = groups.find(group => group.id === this.data.groupId);
        this.backendService.addHistory(selectedImagesArr, this.data.projectId, `Image assigned to group  ${selectedGroup.groupName}`, "group", selectedGroup)
        this.backendService.updateImageGroup$(selectedGroup, selectedImagesArr, this.data.projectId)
          .pipe(takeUntil(this.onDestroy$)).subscribe();
      });
    }

    this.dialogRef.close({
      selectedImages: this.selectedImagesIds$.value,
    });
  }

  private setFolderBreadcrumb(folder: IFolder): void {
    if (folder === null) {
      return;
    }

    if (!folder.children || !folder.children.length) return;

    if (this.foldersBreadcrumb.length === 0) {
      this.foldersBreadcrumb.push(folder);
      return;
    }

    const idx = this.foldersBreadcrumb.findIndex(breadcrumFolder => breadcrumFolder.path === folder.path);
    console.log({ breadcrumb: this.foldersBreadcrumb, folder, idx });
    if (idx === -1) {
      this.foldersBreadcrumb.push(folder);
    } else {
      this.foldersBreadcrumb = this.foldersBreadcrumb.slice(0, idx + 1);
    }
  }

  getTags(projectId: string = this.data.projectId) {
    this.backendService.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      this.tags = [
        { tag: 'Tagged' },
        ...tags,
        { tag: 'Untagged' },
        { tag: 'All' }

      ];
      //     this.uiService.tags = this.tags;
    });
  }

  selectTagEl(tag): void {
    this.startIndex = 0;
    this.endIndex = 32;
    this.scrollToTop();
    this.selectTag(tag)
  }

  scrollToTop() {
    const containerEl = document.querySelector('.modal-two-side-layout-images');
    containerEl.scrollTop = 0;
  }

  selectTag(tag): void {
    this.selectedTag = tag;
    if (this.selectedTag === 'All') {
      this.images = this.folderImages$.value.slice(this.startIndex, this.endIndex);
    } else if (this.selectedTag === 'Untagged') {
      this.images =
        this.folderImages$.value.filter((image) => !image.tags || !image.tags.length).slice(this.startIndex, this.endIndex)
        ;
    }
    else if (this.selectedTag === 'Tagged') {
      this.images =
        this.folderImages$.value.filter((image) => image.tags && image.tags.length).slice(this.startIndex, this.endIndex
        );
    }
    else {
      this.images =
        this.folderImages$.value.filter(
          (image) => image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
        ).slice(this.startIndex, this.endIndex
        );
    }
    this.filteredFolderImages$.next(this.images)
  }



  getProjectGroups(projectId: string = this.data.projectId) {
    this.backendService.getProjectImageGroups(projectId).pipe(
      first(),
      takeUntil(this.onDestroy$),
    ).subscribe((groups) => {
      this.projectGroups = groups;
    })
  }

  someMethod(event) {
    this.selectedImagesIds$.next(this.selectedImages.map(o => o.id));
  }

  isChecked(imageId: string): boolean {
    return (this.selectedImages && this.selectedImages.map(selectedImage => selectedImage.id).includes(imageId) || this.blockedImagesIds.includes(imageId));
  }

  isMovedImage(image: IImage): boolean {
    return (this.movedImages.length && this.movedImages.map(image => image.id).includes(image.id));
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  itemDeselected() {
    if (!this.movedImages.length) {
      this.cancelMoving();
    }
  }

  cancelMoving() {
    const selected = this.selectedImages;
    this.movedImages = this.selectedImages = [];
    let newVal: any[];
    selected.forEach(image => {
      if (this.selectedImagesIds$.value.includes(image.id)) {
        newVal = this.selectedImagesIds$.value.filter(imageId => imageId !== image.id);
      }
    });
    this.selectedImagesIds$.next(newVal);
  }

  activeTab(tab) {
    this.tab = tab;
  }


}
