import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackendService } from '../services/backend.service';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-member-add-dialog',
  templateUrl: './member-add-dialog.component.html',
  styleUrls: ['./member-add-dialog.component.scss']
})
export class MemberAddDialogComponent implements OnInit {
  public selectedRole = 'editor';
  public email = '';
  public members = [];
  public roles = [];
  public currentUserId;
  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    readOnly: new FormControl(false)
  });
  get readOnly(): AbstractControl {
    return this.form.get('readOnly');
  }

  isEmailExist = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private toast: ToastrService,
    private backendService: BackendService,
    public dialogRef: MatDialogRef<MemberAddDialogComponent>,
    public uiService: UiService) {
    this.members = this.data.members;
    this.roles = this.data.roles;
    this.currentUserId = this.getCurrentUserId();
    this.form.controls['readOnly'].disable()
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0' });
  }

  getRoleForUser(user): any {
    if (user.status === 'onboarded') {
      return this.roles.find(role => role.id === user._id)?.role;
    } else if (user.role) {
      return user.role;
    }else{
      return this.roles.find(role => role.id === user._id)?.role;
    }
  }
  getCurrentUserId() {
    return this.backendService.getCurrentUser().uid;
  }

  submit(): void {
    this.email =this.email.toLowerCase().trim();
    if (this.email) {
      if (this.members.findIndex(member => member.email == this.email) !== -1) {
        this.isEmailExist = true
        return;
      }
      const isMemberValid = this.backendService.domainControlValid('invite');
      if (isMemberValid) {
        this.backendService.inviteUserToProject(
          this.data.projectId,
          this.email,
          this.selectedRole,
          this.readOnly.value,
          this.uiService.asset?.assetName
        ).subscribe((result) => {
          this.members = [
            {
              email: this.email,
              role: this.selectedRole
            },
            ...this.members
          ];
          // this.dialogRef.close();
        });
      } else {
        if (this.backendService.DOMAIN_CONTROL) {
          this.toast.error(`Invited users limit (${this.backendService.DOMAIN_CONTROL.invitedUsers}) reached. For more users please reach out to sales@oceansai.tech.`);
        } else {
          this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
        }
      }
    }
  }

  toggleReadOnly(event) {
    if (event.value == 'editor' || event.value == 'viewer') {
      this.form.controls['readOnly'].disable()
      if(event.value == 'editor')
      this.form.controls['readOnly'].setValue(false)
      else
      this.form.controls['readOnly'].setValue(true)

    } else {
      this.form.controls['readOnly'].enable()
    }
  }
}
