import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiService } from '../services/ui.service';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';
const timeFrameDifference = 0.95;  // In seconds
const logFrameDifference = 2; // In seconds
import moment from 'moment'
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';
import {
  of,
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest,
  Subject,
  from,
} from 'rxjs';

import { first, mergeMap, map, startWith, catchError, tap, switchMap, takeUntil, take, concatMap, debounceTime, distinctUntilChanged, last, debounce, takeLast, skip, find } from 'rxjs/operators';
import { BackendService } from '../services/backend.service';
import { KonvaComponent } from '../konva/konva.module';
import { Images, KonvaConfig, TaggedArea } from '../models/tagging.interface';
import { ActivatedRoute, Router } from '@angular/router';
import {
  getPolygonRect,
  getScaledPolygon,
  inverseScaleXY,
  scaledLabelOffset
} from './../tagging/tagging.helpers';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
//import { MatVideoComponent } from 'mat-video/lib/video.component';
import { ToastrService } from 'ngx-toastr';
import { CombineQueriesService } from '../services/combine-queries.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LogFileComponent } from '../log-file/log-file.component';
import { InspectionType } from '../models/app.enum';
const fontSize = 16;
const color = '#EB5757';
@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('video') matVideo: any;
  video: HTMLVideoElement;
  @ViewChild('videoStage') videoStage: KonvaComponent;
  @ViewChild('videoContainer') videoContainer: ElementRef;
  @ViewChild('bubble') bubbleContainer: ElementRef;
  @ViewChild('modal') videoModal: ElementRef;
  @ViewChild('addFrameDialog') addFrameDialog: any;
  addFrameDialogRef;
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Capture',
    raised: false,
    spinnerSize: 18,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    customClass: 'video-component'
  };

  public imageId;
  public projectTags: string[] = [];
  public sensitiveProjectTags: any = [];
  public tagsTextConfigs: any[] = [];
  public isDrawing = false;
  public colors = {};
  public videoContainerSize: any;
  public projectImages$: Observable<any>;
  public activeImgIdx: number;
  public isPlaying: boolean = false;
  private onDestroy$ = new Subject();
  public videoImages = [];
  private videoImageSubs: Subscription;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoModalComponent>,
    public uiService: UiService,
    private storage: AngularFireStorage,
    public backend: BackendService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private toast: ToastrService,
    private dialog: MatDialog,
    private router: Router,
    private combineService: CombineQueriesService,
    private sanitizer: DomSanitizer
  ) {
    this.getTags(data.projectId);
    this.getVideoImages(data);
  }
  public taggedAreas: Map<string, TaggedArea[]> = new Map();
  public get activeImgAreas(): TaggedArea[] {
    return this.taggedAreas.get(this.imageId) || [];
  }

  public videoConfigStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 600,
    height: 350
  });
  lastFrameTime = null;
  getTimeFrame() {
    // Display the current position of the video in a <p> element with id="demo"
    const time = Math.round(this.video.currentTime);
    if (this.lastFrameTime != time) {
      const idx = this.videoImages.findIndex(o => Math.round(o.frame) == time);
      if (idx != -1 && this.videoImages[idx].id != this.imageId) {
        this.activate(idx);
      } else {
        this.imageId = null;
      }
    }

    this.lastFrameTime = time;
  }

  ngOnInit(): void {

  }

  sliderChange($event) {
    this.pause();
    this.video.currentTime = $event.value;
  }

  isSetConfigStage = true;
  isLoadedVideo:boolean=false;
  ngAfterViewInit() {
    this.matVideo.nativeElement.crossOrigin = "Anonymous"
    this.video = this.matVideo.nativeElement;

    this.video.addEventListener('loadedmetadata', () => {
      console.log('Video loaded fully');
      this.isLoadedVideo=true;

    }
    );

    this.video.addEventListener('ended', () =>
      _this.isPlaying = false
    )

    this.video.addEventListener('timeupdate', () => {
      _this.getTimeFrame()
    }
    );
    this.video.addEventListener('loadstart', () => {
      _this.setContainerSize()
    }
    );
    if (window) {
      this.renderer.listen(window, 'resize', () => {
        this.isSetConfigStage = true;
        this.isSetConfigStage = true;
      });
    }
    const _this = this;


  }

  public isSetContainerSize: boolean = true;
  ngAfterViewChecked(): void {
    if (this.isSetConfigStage && this.videoContainer) {
      this.isSetConfigStage = false;
      // this.setContainerSize();
      if (this.activeImgIdx !== undefined) {
        this.activate(this.activeImgIdx);
      }
    }
    if (this.videoContainer && this.isSetContainerSize) {
      this.isSetContainerSize = false;
    }

  }

  setContainerSize(): void {
    if (this.videoContainer) {
      const { offsetWidth, offsetHeight } = this.videoContainer.nativeElement;
      this.videoContainerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };

      this.videoConfigStage$.next(this.videoContainerSize);
      this.cdr.detectChanges();
    }

  }

  getTagSensitive(area) {
    return this.sensitiveProjectTags.map(tag => tag.tag).includes(area.tag);
  }

  getAreaSeverityIconStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.videoStage) { return null; }
    const { x, y, height, width } = getPolygonRect(area.config$.getValue().points);
    const positionOffset = this.videoStage.getStage().position();
    const scale = this.videoStage.getScale();

    if (area.annotation && area.annotation.closedType && area.annotation.closedType != "rectangle") {
      return {
        top: `${positionOffset.y - 10 + (area.config$.getValue().points[area.config$.getValue().points.length - 1] * scale)}px`,
        left: `${positionOffset.x + (10) + (area.config$.getValue().points[area.config$.getValue().points.length - 2] * scale)}px`,
      };

    }
    else {
      return {
        top: `${positionOffset.y + (y + height - 15 * (1 / scale)) * scale}px`,
        left: `${positionOffset.x + (x + width - 15 * (1 / scale)) * scale}px`,
      };
    }
  }

  getBGColor(area) {
    if (area.annotation.sensitive == 0) {
      return '#d4d4d4';
    } else {
      return area.annotation.levels?.find(o => o.level == area.annotation.sensitive)?.color;
    }
  }
  getTitle(area) {
    if (!area.annotation.sensitive) {
      return 'not marked';
    } else {
      const level = area.annotation.levels?.find(o => o.level == area.annotation.sensitive);
      if (level) {
        return `level ${level.level},   ${level?.title}`;
      }
    }
  }

  public tagsListHeight = 0;
  public tagsListWidth = 0;
  getTags(projectId: string): void {
    this.backend.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      const activeTags = tags.filter(
        (x) => x.status !== 'de-active'
      );

      activeTags.forEach((activeTag, i) => {
        if (!this.colors[activeTag.tag]) {
          this.colors[activeTag.tag] = activeTag.color || this.backend.CONSTANTS.tags_colors[
            i % this.backend.CONSTANTS.tags_colors.length
          ];
        }
      });

      const height = 20;
      const padding = 3;

      this.projectTags = activeTags.map((tag) => tag.tag);
      this.sensitiveProjectTags = activeTags.reduce((acc, tag) => tag.sensitivity ? [...acc, tag] : acc, []);
      this.tagsListHeight = this.projectTags.length * (height + padding + 3);
      this.tagsListWidth = Math.max(...this.projectTags.map(tag => tag.length)) * ((fontSize - 2) / 2) + padding;

      this.tagsTextConfigs = activeTags.map((tag, idx) => {
        const space = 3;

        return {
          label$: new BehaviorSubject({
            y: idx * (height + space),
            ...inverseScaleXY(this.videoStage ? this.videoStage.getScale() : 1)
          }),

          text$: new BehaviorSubject({
            fontSize: fontSize - 2,
            fontFamily: 'Montserrat',
            fontStyle: 'bold',
            text: tag.tag,
            fill: 'white',
            padding,
            wrap: 'none',
            lineHeight: 1,
            ellipsis: true,
          }),

          tag$: new BehaviorSubject({
            fill: '#222831',
            ...inverseScaleXY(this.videoStage ? this.videoStage.getScale() : 1)
          }),
        };
      });

    });
  }

  activeImage(idxDelta: number, image): void {
    this.pause();
    this.video.currentTime = image.frame;
    // this.activate(idxDelta);
    if (this.uiService.project.inspectionType === InspectionType.FORSSEA) {
      const dialogRef = this.dialog.open(LogFileComponent, {
        width: '30vw',
        data: {
          logFrame: image.logFrame,
          fileName: image.fileName
        },
      });

    }


  }


  getImageScale(idx: number, imageEl?: any): { widthScale: number; heightScale: number } {
    if (!this.videoImages || !this.videoImages[idx]) return;
    const image = this.videoImages[idx].mediumFileSize;
    const { width, height } = image;
    const { width: stageWidth, height: stageHeight } = this.videoConfigStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;

    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }

  updateTaggingItemConfig(area: KonvaConfig, tag: string, id?: string, hasContext?: boolean, sensitive?, levels?): TaggedArea {
    const { widthScale, heightScale } = this.getImageScale(this.activeImgIdx)
    const polygon = {
      points: id ? getScaledPolygon(area.points, {
        x: widthScale,
        y: heightScale,
      }) : area.points,
    };
    const { originX, originY, x, y, width, height } = getPolygonRect(polygon.points);
    const { tagsGroupX, tagsGroupY, tagTextY } = this.getTagsGroupXY(polygon);

    const annotations = id ? { tag, annotation: { id, sensitive, levels, ...area } } : {};
    return {
      ...annotations,
      config$: new BehaviorSubject({
        ...polygon,
        stroke: tag && tag !== 'context' ? this.colors[tag] || this.colors[tag.toLowerCase()] : (tag === 'context' ? '#335AFF' : color),
        fill: 'rgba(255, 255, 255, 0.3)',
        strokeWidth: 3,
        strokeScaleEnabled: false,
        closed: true,
        dash: tag === 'context' ? [2, 2] : null
      }),
      textConfig$: new BehaviorSubject({
        fontSize: fontSize - 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        x: originX,
        y: tagTextY,
        fill: '#fff',
        text: tag,
        padding: 5,
        wrap: 'none',
        lineHeight: 1,
        ellipsis: true,
        ...inverseScaleXY(this.videoStage.getScale()),
        listening: false
      }),
      tag$: new BehaviorSubject({
        fill: '#222831',
        ...inverseScaleXY(this.videoStage.getScale()),
        listening: false,
        visible: (tag !== 'context')
      }),
      label$: new BehaviorSubject({
        y: originY < tagTextY ? 5 : tagTextY,
        x,
        listening: false,
        visible: (tag !== 'context')
      }),
      tagsGroupConfig$: new BehaviorSubject({
        x: tagsGroupX,
        y: tagsGroupY,
        ...inverseScaleXY(this.videoStage.getScale()),
        visible: !!!id
      })

    };

  }


  formatLabel(value: number): string {
    switch (value) {
      case 0:
        return 'Low';
      case 1:
        return 'Medium';
      case 2:
        return 'High';
    }
  }

  getSliderClass(area) {
    if (area?.annotation?.sensitive === '2') {
      return 'high';
    } else if (area?.annotation?.sensitive === '1') {
      return 'medium';
    }
    else {
      return 'low';
    }
  }

  getSliderStyle(area: TaggedArea, type: string): Partial<CSSStyleDeclaration> {
    if (!this.videoStage) { return null; }
    const { height } = getPolygonRect(area.config$.getValue().points);
    const scale = this.videoStage.getScale();
    return {
      minHeight: `${Math.max((height) * scale) + (scale * 3)}px`,
    };
  }

  getAreaTagPanelStyle(area: TaggedArea, type: string): Partial<CSSStyleDeclaration> {
    if (this.videoStage) { return null; }

    const { tagsGroupX, tagsGroupY } = this.getTagsGroupXY(area.config$.getValue());
    const { x: left, y: top, height } = getPolygonRect(area.config$.getValue().points);

    const scale = this.videoStage.getScale();
    const transform = this.videoStage.getStage().getTransform().copy();
    let { x, y } = transform.point({ x: tagsGroupX, y: tagsGroupY });
    if (tagsGroupX < left) x += this.tagsListWidth - 20;

    if (tagsGroupY < top) y += this.tagsListHeight - Math.max((height - 5) * scale, 50);
    return {
      top: `${y}px`,
      left: `${x}px`,
    };
  }


  getTagsGroupXY(
    area: KonvaConfig): { tagsGroupX: number; tagsGroupY: number, tagTextY: number } {
    const {
      width: stageWidth,
      height: stageHeight,
    } = this.videoConfigStage$.getValue();
    const { x, y, width, height, originY } = getPolygonRect(area.points);

    const transform = this.videoStage.getStage().getTransform().copy();
    const { x: x1, y: y1 } = transform.point({ x, y }),
      { x: x2, y: y2 } = transform.point({ x: x + width, y: y + height });

    const tagsGroupRight = x + width + 1.5 / this.videoStage.getScale();
    const tagsGroupLeft = x - (this.tagsListWidth + 1.5) / this.videoStage.getScale();
    const tagsGroupTop = y;
    const tagsGroupBottom = y + height - this.tagsListHeight / this.videoStage.getScale();

    const tagTextTop = y - scaledLabelOffset(this.videoStage.getScale());
    const tagTextBottom = y + height + 1.5 / this.videoStage.getScale();

    const tagsGroupX =
      stageWidth < x2 + this.tagsListWidth
        ? tagsGroupLeft
        : tagsGroupRight;

    const tagsGroupY =
      stageHeight < y1 + this.tagsListHeight
        ? tagsGroupBottom
        : tagsGroupTop;

    const tagTextY =
      y1 - scaledLabelOffset(1) > 0
        ? tagTextTop
        : tagTextBottom

    return {
      tagsGroupX: tagsGroupX < 0 ? 5 : tagsGroupX,
      tagsGroupY,
      tagTextY
    };
  }


  public imageAnnotations = [];
  getImageAnnotations(doc) {
    const annotationData =
      doc.tags ? doc.tags.filter(o => o.tag != 'context').map((currentArea) => {
        const { tag, hasContext } = currentArea;
        let taggingItem = {
          ...this.updateTaggingItemConfig(currentArea.polygon, tag, currentArea.id, hasContext, currentArea['sensitive'], currentArea['levels']),
        };

        taggingItem = {
          ...taggingItem,
          annotation: {
            ...taggingItem.annotation,
            tag: tag,
            polygon: {
              ...currentArea.polygon,
              sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null,
            },
            levels: this.sensitiveProjectTags.find(o => o.tag === tag)?.levels || [],
            hasContext: currentArea.hasContext
          }
        };


        return taggingItem;
      }) : [];
    this.imageAnnotations = annotationData;
    this.taggedAreas.set(doc.id, annotationData);
    this.cdr.detectChanges();

  }

  activate(idx: number): void {
    if (!this.videoImages[idx]) return;
    const doc = this.videoImages[idx];
    this.imageId = doc.id;
    this.setContainerSize();
    if (!doc.mediumFileSize) return;
    const { height, width } = doc.mediumFileSize;
    const { widthScale, heightScale } = this.getImageScale(idx);
    const newWidth = width * widthScale;
    const newHeight = height * heightScale;
    this.video.height = newHeight;
    this.video.width = newWidth;
    if (this.videoStage) {
      this.videoStage.reset();
    }
    this.activeImgIdx = idx;

    const newSize = {
      width: newWidth,
      height: newHeight,
    };
    this.videoConfigStage$.next(newSize);

    this.getImageAnnotations(doc);
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.videoImageSubs.unsubscribe();
  }

  getVideoImages(data) {
    this.videoImageSubs = this.combineService.getVideoImages(data.projectId, data.id)
      .pipe(
        tap(images => {
          const processsingVideo: any = this.videoImages.filter(o1 => !images.some(o2 => o1.frame == o2.frame));
          this.videoImages = processsingVideo.concat(images).sort((a, b) => a.frame - b.frame);
      
        }),
      ).subscribe();

  }

  getBubbleStyle(frame, i) {
    return {
      "margin-left": `${(frame / this.video.duration) * 100}%`,
      'width': '100%',
      'text-align': 'left',
      'position': 'relative',
      'height': 0,
      // 'bottom':i * 35+"px" 
      // "transform": `translateX(-${(100-((frame / this.video.duration) * 100))}%)`
    }
  }

  capture() {
    // this.video.currentTime = Math.round(this.video.currentTime);
    if (!this.video.paused) {
      this.video.pause();
      this.isPlaying = false;
    }

    const idx = this.videoImages.findIndex(o => Math.round(o.frame) == Math.round(this.video.currentTime));
    if (idx != -1) {
      this.toast.warning("Already have image for this frame")
      return;
    }
    const canvas = document.createElement('canvas');
    canvas.height = this.video.videoHeight;
    canvas.width = this.video.videoWidth;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(this.video, 0, 0, canvas.width, canvas.height);
    const _this = this;
    canvas.toBlob(function (blob) {
      const metaData = {
        contentType: "image/jpeg",
        customMetadata: {
          folderPath: _this.data.folderPath,
          projectId: _this.data.projectId,
          videoId: _this.data.id,
          frame: _this.video.currentTime,
          assetId:_this.uiService.project.assetId,
          logFrame: null
        }
      };
      _this.addFrameDialogRef = _this.dialog.open(_this.addFrameDialog, {
        data: {
          title: '',
          blob: blob,
          image: URL.createObjectURL(blob),
          metaData: metaData
        }
      });
    }, "image/jpeg");
  }

  async saveVideoFrame(data) {
    const _this = this;
    this.addFrameDialogRef.close()
    data.metaData.customMetadata['fileName'] = data.title;
    let matchedLogs;
    if (this.uiService.project.inspectionType === InspectionType.FORSSEA && this.uiService.project.inspectionFile) {
      if (!this.dataArray.length) {
        const res: ArrayBuffer = await this.backend.getExcelFile(this.uiService.project?.inspectionFile).toPromise();
        this.dataArray = await this.processExcelData(res);
      }
      const processRunTime = this.dataArray.filter(o => o.Time)[0]?.Time;
      const timeToAddInSeconds = data.metaData.customMetadata.frame;
      let extractedDate = new Date();
      if (processRunTime) {
        extractedDate = new Date(processRunTime);
        extractedDate.setSeconds(extractedDate.getSeconds() + timeToAddInSeconds);
        matchedLogs = this.uiService.logs.find(data => {
          // Calculate the time difference using Moment.js
          const diffSec = moment(extractedDate).diff(moment(data.LogTime), 'second');
          // Check if the time difference is within the specified range (2 hours)
          return (diffSec <= 7200 && diffSec >= (7200 - logFrameDifference));
        });
      }
    }
    if (matchedLogs) {
      data.metaData.customMetadata.logFrame = JSON.stringify(matchedLogs);
    }
    this.videoImages.push({
      deleted: false,
      fileName: data.title,
      thumbFileUrl: this.sanitizer.bypassSecurityTrustUrl(data.image),
      frame: data.metaData.customMetadata.frame,
      tags: [],
      processing: true,
    })
    this.videoImages.sort((a, b) => a.frame - b.frame);
    //  this.createBtnOptions.active = true;
    this.storage.ref("images/projects/").child(uuidv4()).put(data.blob, data.metaData).then(function (snapshot) {
      _this.backend.imageSimilarityTrain(_this.data.projectId).pipe(
        takeUntil(_this.onDestroy$)).subscribe();
    })

  }


  play() {
    if (this.video.paused) {
      this.video.play();
    }
    this.isPlaying = true;
  }

  pause() {
    this.video.pause();
    this.isPlaying = false;
  }

  getTime() {
    if (this.video == null || isNaN(this.video.duration)) {
      return `00:00:00`;
    }
    const secondNum = Math.round(this.video.currentTime);
    let hours: any = Math.floor(secondNum / 3600);
    let minutes: any = Math.floor((secondNum - (hours * 3600)) / 60);
    let seconds: any = secondNum - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  getDuration() {
    if (this.video == null || isNaN(this.video.duration)) {
      return `00:00:00`;

    }
    const secondNum = Math.round(this.video.duration); // don't forget the second param
    let hours: any = Math.floor(secondNum / 3600);
    let minutes: any = Math.floor((secondNum - (hours * 3600)) / 60);
    let seconds: any = secondNum - (hours * 3600) - (minutes * 60);
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  getMax() {
    if (this.video == null || isNaN(this.video.duration)) {
      return;
    }
    return Math.round(this.video.duration);
  }
  getSliderValue() {
    if (this.video == null || isNaN(this.video.duration)) {
      return;
    }
    return this.video.currentTime;
  }


  preview(image) {
    if (this.data.dialogRef) {
      this.data.dialogRef.close();
    }
    this.dialogRef.close();
    this.router.navigateByUrl(`/dashboard/projects/${this.data.projectId}/images/${image.id}`);
  }

  openFileChoose() {
    document.getElementById('inspection_file').click();
  }

  public isProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fileChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    this.isProcessing$.next(true);
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = reader.result;
      // const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
      // Specify the range of cells for headers (A10 to G10)
      const headerRange = XLSX.utils.decode_range('A10:G10');
      const headers = [];

      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellAddress = XLSX.utils.encode_cell({
          r: headerRange.s.r,
          c: col,
        });
        const header = workbook.Sheets[sheetName][cellAddress]
          ? workbook.Sheets[sheetName][cellAddress].v
          : undefined;
        headers.push(header);
      }

      // Specify the range of cells for data (starting from A11 to the last row)
      const dataRange = XLSX.utils.decode_range(
        'A11:' +
        XLSX.utils.encode_col(
          XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']).e.c
        ) +
        XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']).e.r
      );
      let dataArray = [];


      for (let row = dataRange.s.r; row <= dataRange.e.r; row++) {
        const rowData = {};

        for (let col = dataRange.s.c; col <= dataRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
          const cellValue = workbook.Sheets[sheetName][cellAddress]
            ? workbook.Sheets[sheetName][cellAddress].v
            : undefined;
          const header = headers[col - dataRange.s.c];
          rowData[header] = cellValue;
        }

        dataArray.push(rowData);

      }
      if (!headers.includes('Session') || !headers.includes('Video Time')) {
        this.isProcessing$.next(false);
        this.toast.warning("Sheet does not container Sessions and Video Time");
        return;
      }
      dataArray = this.filterRecordsBetweenOccurrences(dataArray, this.getChannelFileName(this.data.fileName));
      const filter = dataArray.filter(o => o.Session && (o.Session.includes('.png') || o.Session.includes('.jpg')))
      if (!filter.length) {
        this.isProcessing$.next(false);
        this.toast.warning("Sheet does not have any image data");
        return;
      }

      const images = this.uiService.projectImages.filter(image =>
        filter.some(data => this.getChannelFileName(image['fileName']) === data['Session'])
      );
      if (images.length === 0) {
        this.isProcessing$.next(false);
        this.toast.warning("No matching images found");
        return;
      }

      const params = [];
      images.forEach(image => {
        const findFrame = filter.find(o => o.Session === this.getChannelFileName(image['fileName']));
        const excelDate = new Date(findFrame.Time)
        const matchedLogs = this.uiService.logs.find(data => {
          // Calculate the time difference using Moment.js
          const diffSec = moment(excelDate).diff(moment(data.LogTime), 'second');
          // Check if the time difference is within the specified range (2 hours)
          return (diffSec <= 7200 && diffSec >= (7200 - logFrameDifference));
        });
        params.push({
          imageId: image.id,
          videoId: this.data.id,
          logFrame: matchedLogs ? matchedLogs : null,
          frame: this.timeStringToSeconds(findFrame['Video Time'])
        })
      });
      this.backend.addImagesToVideos(params).pipe().subscribe(response => {
        this.isProcessing$.next(false);
      }, error => {
        this.isProcessing$.next(false);
        throw (error);
      })
    };
    reader.readAsArrayBuffer(file);
  }

  dataArray = [];
  public async matchLogs() {
    this.isProcessing$.next(true);
    try {
      const res: ArrayBuffer = await this.backend.getExcelFile(this.uiService.project?.inspectionFile).toPromise();
      this.dataArray = await this.processExcelData(res);
      this.logsMapped(this.dataArray)
    } catch (error) {
      console.error('Error occurred while processing excel data:', error);
      throw error; // Rethrow the error to be caught by the caller
    } finally {
      this.isProcessing$.next(false);
    }
  }

  private async processExcelData(buffer: ArrayBuffer): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const workbook = XLSX.read(reader.result, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const headerRange = XLSX.utils.decode_range('A10:G10');
        const headers = [];
        for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
          const cellAddress = XLSX.utils.encode_cell({
            r: headerRange.s.r,
            c: col,
          });
          const header = workbook.Sheets[sheetName][cellAddress]
            ? workbook.Sheets[sheetName][cellAddress].v
            : undefined;
          headers.push(header);
        }
        const dataRange = XLSX.utils.decode_range(
          'A11:' +
          XLSX.utils.encode_col(
            XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']).e.c
          ) +
          XLSX.utils.decode_range(workbook.Sheets[sheetName]['!ref']).e.r
        );
        let dataArray = [];
        for (let row = dataRange.s.r; row <= dataRange.e.r; row++) {
          const rowData = {};
          for (let col = dataRange.s.c; col <= dataRange.e.c; col++) {
            const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
            const cellValue = workbook.Sheets[sheetName][cellAddress]
              ? workbook.Sheets[sheetName][cellAddress].v
              : undefined;
            const header = headers[col - dataRange.s.c];
            rowData[header] = cellValue;
          }
          dataArray.push(rowData);
        }
        if (!headers.includes('Session') || !headers.includes('Video Time')) {
          this.toast.warning("Sheet does not contain Sessions and Video Time");
          reject(new Error("Sheet does not contain Sessions and Video Time"));
        } else {
          dataArray = this.filterRecordsBetweenOccurrences(dataArray, this.getChannelFileName(this.data.fileName));
          resolve(dataArray);
        }
      };
      reader.readAsArrayBuffer(new Blob([buffer]));
    });
  }


  logsMapped(dataArray) {
    const filter = dataArray.filter(o => o.Session && (o.Session.includes('.png') || o.Session.includes('.jpg')))
    if (!filter.length) {
      this.isProcessing$.next(false);
      this.toast.warning("Sheet does not have any image data");
      return;
    }

    const images = this.uiService.projectImages.filter(image =>
      filter.some(data => this.getChannelFileName(image['fileName']) === data['Session'])
    );
    if (images.length === 0) {
      this.isProcessing$.next(false);
      this.toast.warning("No matching images found");
      return;
    }

    const params = [];
    images.forEach(image => {
      const findFrame = filter.find(o => o.Session === this.getChannelFileName(image['fileName']));
      const excelDate = new Date(findFrame.Time)
      const matchedLogs = this.uiService.logs.find(data => {
        // Calculate the time difference using Moment.js
        const diffSec = moment(excelDate).diff(moment(data.LogTime), 'second');
        // Check if the time difference is within the specified range (2 hours)
        return (diffSec <= 7200 && diffSec >= (7200 - logFrameDifference));
      });
      params.push({
        imageId: image.id,
        videoId: this.data.id,
        logFrame: matchedLogs ? matchedLogs : null,
        frame: this.timeStringToSeconds(findFrame['Video Time'])
      })
    });
    this.backend.addImagesToVideos(params).pipe().subscribe(response => {
      this.isProcessing$.next(false);
    }, error => {
      this.isProcessing$.next(false);
      throw (error);
    })
  }

  timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + (seconds + timeFrameDifference);
  }

  getChannelFileName(fileName) {
    return fileName.replace(/_Ch1[^.]*\./, '.').replace(/_Ch2[^.]*\./, '.').replace(/_Ch3[^.]*\./, '.');
  }

  filterRecordsBetweenOccurrences(records, targetName) {
    let startIndex = -1;
    let endIndex = -1;

    for (let i = 0; i < records.length; i++) {
      if (records[i]['Session'] === targetName) {
        if (startIndex === -1) {
          startIndex = i;
        }
        endIndex = i;
      }
    }

    if (startIndex !== -1 && endIndex !== -1) {
      return records.slice(startIndex, endIndex + 1);
    } else {
      return [];
    }
  }
}
