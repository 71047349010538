import redraw from './redraw';
import updateProps from './update-props';
import { zoomHandler, registerZoomOnWheel } from './zoom-handler';
import { dragInit, registerDragBounds } from './drag-handler'

function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter: string, index: number) =>
      index === 0 ? letter.toLowerCase() : letter.toUpperCase()
    )
    .replace(/\s+/g, '');
}

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getName(componentTag): string {
  return capitalizeFirstLetter(
    camelize(componentTag.substr(3).replace('-', ' '))
  );
}

export function createListener(instance: any): any {
  const output = {};
  [
    'onclick',
    'ondblclick',
    'onmouseover',
    'onmouseout',
    'onmousemove',
    'onmouseup',
    'onmousedown',
    'ontap',
    'ondbltap',
    'ontouchstart',
    'onscaleXChange',
    'onfillChange',
    'ondragstart',
    'ondragmove',
    'ondragend',
    'oncontextmenu',
    'touchmove',
  ].forEach((eventName) => {
    if (instance[eventName].observers.length) {
      output[eventName] = instance[eventName].emit.bind(instance[eventName]);
    }
  });

  return output;
}

export { redraw, updateProps, zoomHandler, dragInit, registerDragBounds, registerZoomOnWheel };
