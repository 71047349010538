import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { mergeMap,switchMap, take } from 'rxjs/operators';
import { AuthProcessService } from 'ngx-auth-firebaseui';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(public authProcess: AuthProcessService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request.url.startsWith(environment.baseAPIUrl);

    return this.authProcess.afa.idToken.pipe(
      take(1),  //-------------- Only emit the first value!
      switchMap((idToken: any) => {
        if (idToken) {
          if(!request.url.includes('https://storage.googleapis.com/' || 'assets.ion.cesium.com')){
            request = request.clone({
              setHeaders: { Authorization: `Bearer ${idToken}` }
            });
          }
        
        }
        return next.handle(request);
      })

    /*  mergeMap(idToken => {
          const authReq= request.clone({
            headers: request.headers.set('Authorization',"Bearer "+ idToken)
          });

          return next.handle(authReq);
      
      
      })*/
    );
  }
}
