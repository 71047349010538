<div style="height: 100vh" class="grid-x align-center align-middle">
  <div class="cell shrink">
    <mat-card class="mat-card-outer">
      <div *ngIf="email.valid && (isSuccess$ | async) === true " >

      </div>
      <form [formGroup]="form" (submit)="submit()" style="padding-left: 50px;padding-right: 50px;">
        <p class="mb-5 text-center" style="color: #5f6769; font-weight: normal; font-size: 18px;">Forgot password</p>

        <mat-form-field class="w-100 mb-0" appearance="fill">
          <mat-label>Email*</mat-label>
          <input (change)="isSuccess$.next(null)" formControlName="email" matInput cdkFocusInitial type="email"/>
        </mat-form-field>

        <mat-error class="mb-4 mt-2" style="min-height: 24px;">
          <!-- errors -->
          <ng-container *ngIf="email.invalid && email.touched && email.dirty">
            <ng-container *ngIf="email.hasError('required')">Email is required</ng-container>
            <ng-container *ngIf="email.hasError('pattern')">Email is incorrect</ng-container>
          </ng-container>
          <ng-container *ngIf="email.valid && (isSuccess$ | async) === false">Email not exist</ng-container>
          <!-- success -->
          <div *ngIf="email.valid && (isSuccess$ | async) === true" style="color: rgb(9, 158, 4);">
            The reset Link to your account was sent to the email ID. please chekc your email for the resent link.
             If you dont receive the email please contact info@oceansai.tech
        <p class="login-link" (click)='login()'>Return to login </p>
        
            </div>
        </mat-error>

        <div class="d-flex justify-content-center">
          <button [disabled]="form.invalid" mat-raised-button color="primary"
            class="submit-button" type="submit">Get New Password</button>
        </div>
      
      </form>
    </mat-card>
  </div>
</div>

<!-- <div style="height: 100vh" class="grid-x align-center align-middle">
  <div class="cell shrink">
    <mat-card class="mat-card-outer">
      <ngx-auth-firebaseui
        providers="[]"
        providersTheme="stroked"
        [guestEnabled]="false"
        titleText="Log in"
        loginButtonText="Log in to account"
        signInCardTitleText="Log in"
        signInTabText="Log in"
        registerTabText="Sign up"
        registerButtonText="Create your account"
        resetPasswordActionButtonText="Get New Password"
        resetPasswordTabText="Forgot Password"
        resetPasswordInputText="Email"
        [registrationEnabled]="false"
        logoUrl="../../assets/logo.png"
        (onSuccess)="handleSuccess()"
        (onCreateAccountRequested)="navigateToSignUp()"
        (onResetPasswordRequested)="onResetPasswordRequested()"
      ></ngx-auth-firebaseui>
    </mat-card>
  </div>
</div> -->
