<div class="popup-header w-100 d-flex">
  <p class="w-100 d-flex">
    <span>Select your panel layers</span>
  </p>
</div>
<mat-dialog-content>
  <div class="d-flex">
    <div class="col-6">
      <h3> <b>DXF Layers</b></h3>
    </div>
    <div class="col-6">
      <h3><b>Selected panel layers</b> </h3>
    </div>
  </div>
 
  <div class="d-flex">
    <div class="container-list" dragula="layers_list" [(dragulaModel)]="lists">
      <div matTooltip="{{list}}" class="list-item wrap-text" *ngFor="let list of lists">
        {{ list }}
      </div>
    </div>

    <div class="container-list" dragula="layers_list" [(dragulaModel)]="dropItems">
      <div matTooltip="{{item}}" class="list-item wrap-text" *ngFor="let item of dropItems">
        {{ item }}
      </div>
    </div>
  </div>
</mat-dialog-content>
<div mat-dialog-actions class="m-1">
  <button color="primary" (click)="saveLayers();" [disabled]="!dropItems.length" class="btn w-100" mat-raised-button>
    Save
  </button>

</div>