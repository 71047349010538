import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from '../../services/backend.service';
import { AssetFormComponent } from '../asset-form/asset-form.component';

@Component({
  selector: 'app-assign',
  templateUrl: './assign.component.html',
  styleUrls: ['./assign.component.scss']
})
export class AssignComponent implements OnInit {
  public assets = [];
  public filterAssets = [];
  public assetId: string;
  public companies = [];
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'ASSIGN',
    raised: true,
    spinnerSize: 24,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    customClass: 'text-uppercase'
  };

  constructor(
    private toast: ToastrService,
    private router: Router,
    public backend: BackendService, public dialog: MatDialog,
    public dialogRef: MatDialogRef<AssignComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    this.backend.getCompanyList().subscribe(companyDoc => {
      companyDoc.docs.forEach(company => {
        this.companies.push(Object.assign({}, company.data(), { companyId: company.id }))
      });
    })
    this.backend.getAssetList().subscribe(assetDoc => {
      assetDoc.docs.forEach(asset => {
        this.assets.push(Object.assign({}, asset.data(), { id: asset.id }))
      });
      this.filterAssets = this.assets;

    })
  }

  search(value: String) {
    if (value === '') {
      return this.filterAssets = this.assets;
    }
    this.filterAssets = this.assets.filter(function (asset) {
      return (asset.assetName.toLowerCase().indexOf(value.toLowerCase()) > -1)
    })
  }

  close(): void {
    this.dialogRef.close();
  }

  handleCreate(): void {
    this.createBtnOptions.active = true;
    this.backend.linkAssetToProject(this.data.projectId, this.assetId).subscribe(() => {
      this.createBtnOptions.active = false;
      this.close()
    })
  }

  handleAddAsset() {
    const isValid = this.backend.domainControlValid('assets');
    const data = JSON.stringify({
      'assets': this.assets,
      'companyAssets': this.companies,
      linkProjectId: this.data.projectId
    })
    if (isValid) {
      this.close();
      this.router.navigate(["dashboard/assets"], {
        state: {
          data: data
        }
      })
    } else {
      if (this.backend.DOMAIN_CONTROL) {
        this.toast.error(`Assets limit (${this.backend.DOMAIN_CONTROL.assets}) reached. For more assets please reach out to sales@oceansai.tech.`);
      } else {
        this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
      }
    }

    /*   this.dialog.open(AssetFormComponent, {
         width: '95%',
         height: '95%',
         data: {
           assets: this.assets,
           companyAssets: this.companies,
           linkProjectId:this.data.projectId
         }
       })
       */
  }

}
