<div class="popup-header w-100 d-flex">
  <p class="w-100">
    New project
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div>
  <div mat-dialog-content class="small  mb-5">
    <div class="form-field d-flex mb-1">
      <label class="w-50">Project Name(*)</label>
      <mat-form-field appearance="fill" class="w-100">
        <input matInput cdkFocusInitial [(ngModel)]="name" />
      </mat-form-field>
    </div>
    <div class="form-field d-flex  mb-1">
      <label class="w-50">Project Type(*)</label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select [(ngModel)]="projectType">
          <mat-option value="visual">Visual</mat-option>
          <mat-option value="thermal">Thermal</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="projectType ==='visual'" class="form-field d-flex  mb-1">
      <label class="w-50">Inspection Type(*)</label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select [(ngModel)]="inspectionType">
          <mat-option value="">General</mat-option>
          <mat-option value="forssea-robotics">Forssea Robotics</mat-option>
        </mat-select>
      </mat-form-field>
      <br>
    </div>
    <div *ngIf="projectType ==='thermal'" class="form-field d-flex  mb-1">
      <label class="w-50">Camera Type(*)</label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select [(ngModel)]="cameraType" (ngModelChange)="onCameraTypeChange()">
          <mat-option value="FLIR">FLIR</mat-option>
          <mat-option value="DJI">DJI</mat-option>
          <mat-option value="SenseFly">SenseFly </mat-option>

        </mat-select>
      </mat-form-field>
      <br>
    </div>

    <div *ngIf="projectType ==='thermal' && cameraType" class="form-field d-flex  mb-1">
      <label class="w-50">Camera Model(*)</label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select [(ngModel)]="cameraModel">
          <mat-option *ngFor="let model of modelsList" value="{{model.value}}">{{model.text}}</mat-option>
        </mat-select>
      </mat-form-field>
      <br>
    </div>

    <div class="form-field d-flex  mb-1">
      <label class="w-50">Date of inspection(*)</label>
      <mat-form-field appearance="fill" class="w-100">
        <input type="date" matInput  [(ngModel)]="inspectionDate" placeholder="Date of inspection" />
      </mat-form-field>
    </div>
    <div class="form-field d-flex  mb-1">
      <label class="w-50">Standard Classification</label>
      <mat-form-field appearance="fill" class="w-100">
        <mat-select [(ngModel)]="classDefinition">
          <mat-option  value ="">---Select---</mat-option>
          <mat-option *ngFor="let class of standardClasses" value ="{{class}}">{{ class | uppercase }} Class</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <br>
    <div *ngIf="assetProjects.length && !classDefinition" class="form-field d-flex">
      <mat-checkbox [(ngModel)]="isFeatures">
      </mat-checkbox>
      <label class="ml-2">Include features from last project</label>
    </div>

    <div *ngIf="assetProjects.length" class="form-field d-flex">
      <mat-checkbox [(ngModel)]="isFolders">
      </mat-checkbox>
      <label class="ml-2">Include folders/sub-fodlers from last project</label>
    </div>
    <div *ngIf="assetProjects.length" class="d-flex form-field">
      <mat-checkbox [(ngModel)]="isMembers" (change)="membersChoosen($event)">
      </mat-checkbox>
      <label class="ml-2">Add previous project members</label>
    </div>

    <div *ngIf="isMembers" class="form-field">
      <div *ngFor="let member of members;let i= index" class="row user-row">
        <div class="col-4 m-0 username">
          {{member.name}}
        </div>
        <div class="col-3 m-0">
          <mat-form-field style="width: 120px;" appearance="fill">
            <mat-select [(value)]="member.role">
              <mat-option [value]="'editor'">Editor</mat-option>
              <mat-option [value]="'reviewer'">Reviewer</mat-option>
              <mat-option [value]="'approver'">Approver</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-4 m-0">
          <mat-checkbox style="width: 120px;" [disabled]="member.role == 'editor'" [(ngModel)]="member.readonly"
            [checked]="member.readonly">Read only
          </mat-checkbox>
        </div>
        <div class="col-1  m-0">
          <mat-icon class="close-button" [ngStyle]="{'color':'#EB5757'}" (click)="remove(i)">close</mat-icon>
        </div>
      </div>
    </div>
    <div class="input-error" *ngIf="!isNameValid(name)">Project already exists</div>
  </div>

  <div class="m-1 message-warn" *ngIf="isFeatures || isFolders">
    Will take few seconds to process folders and features.
  </div>
  <div mat-dialog-actions>
    <mat-spinner-button [options]="createBtnOptions"
      (btnClick)="handleCreate(name,inspectionDate,projectType,cameraType)"
      [disabled]="!name || !inspectionDate  ||  !projectType || !isNameValid(name)  || (projectType =='thermal' && (!cameraType || !cameraModel))">
    </mat-spinner-button>
    <button class="ml-2" mat-button (click)="close()">Cancel</button>
  </div>

</div>