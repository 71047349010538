<div class="popup-header w-100 d-flex">
    <p class="w-100">
        {{report?.reportData?.name}}
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="grid-contaner full h-100 project">
    <div class="grid-x h-100">


        <div (click)="toggleLeftPanel()" [style.left]="isPanelShow ? '250px' : '0px'" class="collapse-menu">
            <mat-icon>{{isPanelShow ? 'arrow_left' : 'arrow_right'}}</mat-icon>
        </div>
        <div class="cell h-100 scroller" [ngStyle]="{ width: isPanelShow ?'250px' : '20px' }">

            <div class="h-100 project-sidebar scroller">
                <ng-container *ngIf="isPanelShow" [ngTemplateOutlet]="leftOuter"></ng-container>
                <ng-template #leftOuter>

                    <div *ngIf="editors.length">
                        <p class="role">
                            <span>Editors </span>
                        </p>
                        <div *ngFor="let user of editors;let i=index" class="user">
                            <p class='user-name'>
                                {{i+1}}. {{ getUserName(user) }}
                            </p>
                        </div>
                    </div>

                    <div *ngIf="reviewers.length">
                        <p class="role">
                            <span>Reviewers </span>
                        </p>
                        <div *ngFor="let user of reviewers;let i=index" class="user">
                            <p class='user-name'>
                                {{i+1}}. {{ getUserName(user) }}
                            </p>
                            <div style="display: inline-block;" *ngIf="currentUserId == user.id else members">
                                <button [disabled]='!report.freeze' (click)="marked(user.id,'reviewer')" class='mark'
                                    *ngIf="checkReviewerStatus(user.id) else reviewed" mat-raised-button
                                    color="primary">
                                    Reviewed
                                </button>
                                <ng-template #reviewed>
                                    <span class="marked">
                                        (Reviewed)
                                    </span>
                                </ng-template>
                            </div>
                            <ng-template #members>
                                <span class="marked" *ngIf="!checkReviewerStatus(user.id)">
                                    (Reviewed)
                                </span>
                                <span class="pending" *ngIf="checkReviewerStatus(user.id)">
                                    (Pending)
                                </span>
                            </ng-template>
                            <span>

                            </span>
                        </div>
                    </div>
                    <div *ngIf="approvers.length">
                        <p class="role">
                            <span>Approvers </span>
                        </p>
                        <div *ngFor="let user of approvers;let i=index" class="user">

                            <p class='user-name'>
                                {{i+1}}. {{ getUserName(user) }}
                            </p>

                            <div style="display: inline-block;" *ngIf="currentUserId == user.id else members">
                                <button [disabled]='!report.freeze || !allReviewedCompleted() '
                                    (click)="marked(user.id,'approver')" class='mark'
                                    *ngIf="checkApproverStatus(user.id) else approve" mat-raised-button color="primary">
                                    Approved
                                </button>
                                <ng-template #approve>
                                    <span class="marked">
                                        (Approved)
                                    </span>
                                </ng-template>
                            </div>
                            <ng-template #members>
                                <span class="marked" *ngIf="!checkApproverStatus(user.id)">
                                    (Approved)
                                </span>
                                <span class="pending" *ngIf="checkApproverStatus(user.id)">
                                    (Pending)
                                </span>
                            </ng-template>
                            <span>

                            </span>
                        </div>
                    </div>

                </ng-template>

            </div>
        </div>
        <div class="cell auto h-100 w-100" style="overflow: hidden;">
            <div class="pdf-container">
                <pdf-viewer [src]="src" [rotation]="0" style="height: 100vh" [original-size]="false" [show-all]="true"
                    [fit-to-page]="true" [zoom]="1" [zoom-scale]="'page-width'" [stick-to-page]="true"
                    [render-text]="true" [external-link-target]="'blank'" [autoresize]="true" [show-borders]="false">
                </pdf-viewer>
            </div>

        </div>
    </div>
</div>