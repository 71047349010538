import { ViewChild, Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { BackendService } from '../services/backend.service';
import { AuthProcessService } from 'ngx-auth-firebaseui';
import { ToastrService } from 'ngx-toastr';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { MatDialog } from '@angular/material/dialog';
import { Overlay } from '@angular/cdk/overlay';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @ViewChild('industryDialog') industryDialog: any;
  @ViewChild('deleteIndustryDialog') deleteIndustryDialog: any;
  industryDialogRef;
  deleteIndustryDialogRef;
  projects = [];
  projectIndustries = [];
  userData;
  tab: number = 1;
  dashPanel:string;
  defaultView:string;
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };
  profileForm: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    contact: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
    photoURL: new FormControl('')
  });
  contactForm: FormGroup = new FormGroup({
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    country: new FormControl('', [Validators.required])
  });
  billingForm: FormGroup = new FormGroup({
    address: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.required]),
    phone: new FormControl(''),
    country: new FormControl('', [Validators.required])
  });
  isSameAsContact: boolean = false;
  companyForm: FormGroup = new FormGroup({
    companyName: new FormControl('', [Validators.required])
  });

  passwordForm: FormGroup = new FormGroup({
    password: new FormControl('', [Validators.required]),
    confirm: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
  });

  providerId: string = '';
  userIndustries = [];
  constructor(
    public router: Router,
    public uiService:UiService,
    public overlay: Overlay, private dialog: MatDialog, private toastr: ToastrService, private backendService: BackendService, public readonly authProcess: AuthProcessService) {
    this.backendService.valueChangesIndustries().subscribe(data => {
      if (data.industries) {
        this.userIndustries = data.industries;
      }
    });
    this.getUser();
    this.getProjects();
  }

  getUser(): void {
    const userSubscription = this.backendService.getUser(this.backendService.getCurrentUser().uid).pipe(take(1)).subscribe(user => {
      const data = user.data();
      this.profileForm.controls['firstName'].setValue(data.firstName || data.displayName)
      this.profileForm.controls['lastName'].setValue(data.lastName || '')
      this.profileForm.controls['email'].setValue(data.email)
      this.profileForm.controls['contact'].setValue(data.contact || '')
      this.profileForm.controls['photoURL'].setValue(data.photoURL || '')
      if (data.contactAddress) {
        this.contactForm.controls['address'].setValue(data.contactAddress.address)
        this.contactForm.controls['city'].setValue(data.contactAddress.city)
        this.contactForm.controls['state'].setValue(data.contactAddress.state)
        this.contactForm.controls['pincode'].setValue(data.contactAddress.pincode)
        this.contactForm.controls['phone'].setValue(data.contactAddress.phone)
        this.contactForm.controls['country'].setValue(data.contactAddress.country)
      }
      if (data.billingAddress) {
        this.billingForm.controls['address'].setValue(data.billingAddress.address)
        this.billingForm.controls['city'].setValue(data.billingAddress.city)
        this.billingForm.controls['state'].setValue(data.billingAddress.state)
        this.billingForm.controls['pincode'].setValue(data.billingAddress.pincode)
        this.billingForm.controls['phone'].setValue(data.billingAddress.phone)
        this.billingForm.controls['country'].setValue(data.billingAddress.country)
        this.isSameAsContact = data.billingAddress.isSameAsContact;
      }
      if (data.companyName) {
        this.companyForm.controls['companyName'].setValue(data.companyName)
      }
      this.dashPanel = data.dashPanel || 'map';
      this.defaultView = data.defaultView || 'assets'
      userSubscription.unsubscribe();
      this.providerId = data.providerId;
    });
  }

  getProjects() {
    const projectSubscription = this.backendService.getProjects().subscribe(projects => {
      this.projectIndustries = projects.map(o => o.industry);
      projectSubscription.unsubscribe();
    }, error => {
      console.error(error);
    });
  }

  ngOnInit(): void { }



  uploadProfile(photoURL) {
    this.backendService.updateProfilePic(photoURL).pipe().subscribe(response => {
      this.backendService.updateCurrentUserProfile({
        'photoURL': response.link
      }).then(() => {
        this.profileForm.controls['photoURL'].setValue(response.link);
        this.backendService.updateUser({
          'photoURL': response.link
        }).then(() => { }).catch(err => {
          this.toastr.error('', err.message);
        });
      }).catch(err => {
        this.toastr.error('', err.message);
      });
    })
  }

  sameAsContact(event) {
    if (event.checked) {
      let contactForm = this.contactForm.value;
      this.billingForm.controls['address'].setValue(contactForm.address)
      this.billingForm.controls['city'].setValue(contactForm.city)
      this.billingForm.controls['state'].setValue(contactForm.state)
      this.billingForm.controls['pincode'].setValue(contactForm.pincode)
      this.billingForm.controls['phone'].setValue(contactForm.phone)
      this.billingForm.controls['country'].setValue(contactForm.country)
      this.billingForm.disable();

    } else {
      this.billingForm.enable();
    }
  }

  handleUpdatePassword(): void {
    this.dialog.open(UpdatePasswordComponent, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '400px'
    });
  }

  updateProfilePic() {
    document.getElementById('fileInput').click();
  }

  async fileChoosen(event) {
    if (event.target.files && event.target.files[0]) {
      let imageFile;
      const reader = new FileReader();
      reader.onload = (_event: any) => {
        imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
        this.profileForm.controls['photoURL'].setValue(imageFile.link);
        this.uploadProfile(imageFile);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  openIndustryDialog(): void {
    this.industryDialogRef = this.dialog.open(this.industryDialog, {
      width: '300px',
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      data: {
        industries: this.backendService.industries.filter(item => !this.userIndustries.filter(o => o).includes(item))
      }
    });
  }

  openDeleteIndustryDialog(industry): void {
    let industries = this.projectIndustries.filter(o => o == industry);
    this.deleteIndustryDialogRef = this.dialog.open(this.deleteIndustryDialog, {
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      width: '600px',
      data: {
        industries: industries,
        industry: industry
      }
    });
  }

  chooseIndustry(industry) {
    this.backendService.addIndustry(industry, this.userIndustries.length == 0 ? false : true).then(() => {
      this.industryDialogRef.close();
    }).catch(err => {
      this.toastr.error('', err.message);
    });
  }

  closeDialog() {
    this.industryDialogRef.close();
  }

  closeDeleteDialog() {
    this.deleteIndustryDialogRef.close();
  }

  deleteIndustry(industry) {
    this.backendService.deleteIndustry(industry).then(() => {
      this.deleteIndustryDialogRef.close();
    }).catch(err => {
      this.toastr.error('', err.message);
    });
  }

  isFormValid(formValue) {
    if (formValue.address && formValue.city && formValue.state && formValue.country && formValue.pincode) {
      return false;
    }
    return true;
  }

  activeTab(index) {
    this.tab = index;
  }

  updateUserProfile() {
    if (this.tab == 1) {
      this.createBtnOptions.active = true;
      this.backendService.updateCurrentUserProfile({
        'displayName': this.profileForm.value.firstName.trim() + " " + this.profileForm.value.lastName.trim()
      }).then(() => {
        this.backendService.updateUser({
          firstName: this.profileForm.value.firstName.trim(),
          lastName: this.profileForm.value.lastName.trim(),
          displayName: this.profileForm.value.firstName.trim() + " " + this.profileForm.value.lastName.trim(),
          contact: this.profileForm.value.contact,
          companyName: this.companyForm.value.companyName
        }).then(() => {
          this.createBtnOptions.active = false;
          this.toastr.success('', 'Profile updated!');
          this.tab = 0;
        }).catch(err => {
          this.createBtnOptions.active = false;
          this.toastr.error('', err.message);
        });
      }).catch(err => {
        this.createBtnOptions.active = false;
        this.toastr.error('', err.message);
      });

    }
    if (this.tab == 2) {
      this.createBtnOptions.active = true;
      this.backendService.updateUser({
        billingAddress: this.billingForm.value,
        contactAddress: this.contactForm.value,
      }).then(() => {
        this.createBtnOptions.active = false;
        this.toastr.success('', 'Address updated!');
        this.tab = 0;
      });
    }
    if (this.tab == 3) {
      const passwordForm = this.passwordForm.value;
      if (passwordForm.newPassword != passwordForm.confirm) {
        this.toastr.warning("Password and Confirm not matched.")
        return;
      }
      if (passwordForm.password  && passwordForm.newPassword) {
        this.createBtnOptions.active = true;
        this.backendService.getReauthenticateUser(passwordForm.password).then(() => {
          this.backendService.updateCurrentUserPassword(passwordForm.newPassword)
            .then(() => {
              this.createBtnOptions.active = false;
              this.toastr.success('', 'Password changed successfully!');
              this.tab = 0;
            }).catch(err => {
              this.createBtnOptions.active = false;
              this.toastr.error('', err.message);
            });
        }).catch(err => {
          this.createBtnOptions.active = false;
          this.toastr.error('', err.message);
        });
      }
    }
    if (this.tab == 5) {
      this.createBtnOptions.active = true;
      this.backendService.updateUser({
        'dashPanel': this.dashPanel,
        'defaultView': this.defaultView
      }).then(() => {
        this.createBtnOptions.active = false;
        this.uiService.defaultView=this.defaultView;
        if(this.uiService.selectedSideTab != 'projects'){
          this.uiService.selectedSideTab =this.defaultView;
        }
          this.toastr.success('', 'Setting updated!');
      }).catch(err => {
        this.createBtnOptions.active = false;
        this.toastr.error('', err.message);
      });
    }
  }
  close() {
    this.router.navigateByUrl('dashboard/projects')
  }

  onValChange(value) {
    this.dashPanel = value;
    this.updateUserProfile();
  }
}