import { Component, SimpleChanges, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild, NgZone, ChangeDetectorRef } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IImage } from 'src/app/models/image.interface';
import { GeneralService } from 'src/app/services/general.service';
import { SelectContainerComponent } from 'ngx-drag-to-select';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BackendService } from 'src/app/services/backend.service';
import { LinkImagesComponent } from 'src/app/link-images/link-images.component';
import { MatDialog } from '@angular/material/dialog';
import { VideoModalComponent } from 'src/app/video-modal/video-modal.component';
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { dragula, DragulaService } from 'ng2-dragula';
import { AssetType, Status } from 'src/app/models/app.enum';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-label-grid',
  templateUrl: './label-grid.component.html',
  styleUrls: ['./label-grid.component.scss']
})
export class LabelGridComponent implements OnChanges {
  @Output() mediaUpdates: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild('containerGrid') selectContainer: SelectContainerComponent;
  public selectedImagesIds$ = new BehaviorSubject<string[]>([]);
  @ViewChild('exifModel') exifModelDialog: any;
  isContentLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false)
  @Input() media;
  @Input() assetId: string;
  @Input() modelId: string;
  @Input() project;
  linkImages = [];
  startIndex = 0;
  endIndex = 32;
  @Input() labelId: string;
  @Input() linkMediaId: string;
  @Input() models: string;
  @Input() selectedLabelNode;
  @Input() dxfLinkRef;
  selectedImages: IImage[];
  option: string = "images";
  sortVideos = [];
  sortImages = [];
  imageLoading: boolean = false;
  loading: boolean = false;

  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };
  public set unlabelledImages(images) {
    this.uiService.unlabelledImages = images;
  }

  public get unlabelledImages(): IImage[] {
    return this.uiService.unlabelledImages;
  }

  public get queued() {
    return Status.QUEUED;
  }
  public get processing() {
    return Status.PROCESSING;
  }
  public get failed() {
    return Status.FAILED;
  }
  public get success() {
    return Status.SUCCESS;
  }

  public contextMenuPosition = { x: '0px', y: '0px' };
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() switchTab: EventEmitter<any> = new EventEmitter();
  constructor(
    private backendService: BackendService,
    private dialog: MatDialog,
    private router: Router,
    public uiService: UiService,
    public dragulaService: DragulaService,
    private snackBar: MatSnackBar,
    private toastr: ToastrService,
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    private generalService: GeneralService) {
    /* this.dragulaService.destroy('sort-label-linked')
     this.dragulaService.createGroup('sort-label-linked', {
       //   removeOnSpill: true
     });*/

  }
  ngOnChanges(changes: SimpleChanges) {
    this.loading = false;
    if (changes.labelId?.currentValue) {
      this.backendService.cancelFetchImage$.next();
      this.linkImages = [];
      this.startIndex = 0;
      this.endIndex = 32;
      const scroll: any = document.getElementsByClassName('label-scroll')
      if (scroll && scroll.length) {
        scroll[0].scrollTop = 0;
      }
      this.load();
    }
    else if (changes.media?.currentValue) {
      if (this.linkImages.length && changes.media.currentValue[this.labelId]) {
        this.sortImages = changes.media.currentValue[this.labelId]?.['images'] || [];
        this.sortVideos = changes.media.currentValue[this.labelId]?.['videos'] || [];
      }
      this.load();
    }

  }


  openContextMenu(event) {
    if ((this.labelId === 'unlabelled' && this.selectedImages && this.selectedImages.length) || this.unlabelledImages.length) {
      event.preventDefault();
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
      this.contextMenu.openMenu();
    }
  }

  selectMovedImages() {
    this.selectedImages.forEach(image => {
      this.unlabelledImages.push(image);
    })
    this.snackBar.open(`${this.unlabelledImages.length} Files Flagged for Move. Open target label and right click to complete the linked.`, 'Ok', {
      panelClass: 'snack-bar'
    });
  }

  cancelMoving() {
    this.unlabelledImages = this.selectedImages = [];
    this.snackBar.dismiss();
  }

  pasteImages() {
    const type = this.unlabelledImages[0].type;
    this.backendService.linkImages(this.assetId, this.modelId, this.labelId, this.unlabelledImages.map(o => o.id), type, this.project.id,
      this.uiService.selectedBaseModel).subscribe();
    this.snackBar.dismiss();
    this.unlabelledImages = []
  }


  unlabelledSubs: Subscription;
  load(): void {
    if (this.unlabelledSubs) {
      this.unlabelledSubs.unsubscribe();
    }
    if (this.labelId === 'unlabelled') {
      this.linkImages = [];
      this.loading = true;
      this.unlabelledSubs = this.backendService.getUnlabelledImages(this.assetId, this.uiService.selectedBaseModel, this.uiService.selectedProjectId).subscribe((result: any) => {
        this.loading = false;
        this.linkImages = result.unlinkedImages.concat(result.unlinkedVideos)
      }, error => {
        this.loading = false;
      });
    } else {
      if (this.media) {
        let keys = Object.keys(this.media);
        const _this = this;
        let imageIds: any = [];
        const element = keys.find(o => o === this.labelId);
        if (element) {
          //Video Content
          this.media[element]['videos']?.forEach(videoId => {
            if (videoId && !this.linkImages.find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  _this.linkImages.push({ ...video, type: 'videos' })
                }
              });
            }
          })
        }
        if (this.selectedLabelNode && this.selectedLabelNode.data?.layer) {
          let images = [];
          this.imageLoading = true;
          this.getNodeByLayer(this.selectedLabelNode.data).then((data) => {
            imageIds = imageIds.concat(data).concat(element);

            const set1 = new Set(keys);
            const set2 = new Set(imageIds);
            const findsIds = [...set1].filter(element => set2.has(element));
            findsIds.forEach(key => {
              if (key && this.media.hasOwnProperty(key) && this.media[key]['images']) {
                images.push(...this.media[key]['images']);
              }
            });

            images = Array.from(new Set(images));

            this.linkImages = this.uiService.projectImages.filter(item => images.includes(item.id)).map(image => ({
              ...image,
              type: 'images'
            }));

            this.imageLoading = false;
            //this.cd.detectChanges();
          })
        } else {
          if (element) {
            this.linkImages = this.uiService.projectImages.filter(item => this.media[element]['images']?.includes(item.id)).map(image => ({
              ...image,
              type: 'images'
            }));

          }
          /*  let req = 0;
           let resp = 0;
          this.media[element]['images']?.forEach((imageId, i) => {
             if (imageId && !this.linkImages.find(o => o.id == imageId)) {
               this.imageLoading = true;
               req++;
               this.backendService.getImage$<any>(imageId).subscribe((image: any) => {
                 resp++;
                 if (image.deleted != true) {
                   this.linkImages.push({ ...image, type: 'images' })
                 }
                 if (req === resp) {
                   this.imageLoading = false;
                 }
               });
             }
           })*/

        }
      }


    }
  }


  getMedia() {
    if (this.option == 'images') {
      return this.generalService.makeImageArrayUnique(this.linkImages.filter(o => o.type === this.option)).slice(this.startIndex, this.endIndex).sort((a, b) => this.sortImages.indexOf(a.id) - this.sortImages.indexOf(b.id));

    } else {
      return this.generalService.makeImageArrayUnique(this.linkImages.filter(o => o.type === this.option)).slice(this.startIndex, this.endIndex).sort((a, b) => this.sortVideos.indexOf(a.id) - this.sortVideos.indexOf(b.id))

    }
  }

  onClickImageCheckbox(event: MouseEvent, id: string): void {
    const currentVal = this.selectedImagesIds$.value;
    let newVal;
    if (currentVal.includes(id)) {
      newVal = currentVal.filter(val => val !== id);
    } else {
      newVal = [...currentVal, id];
    }
    this.selectedImagesIds$.next(newVal);
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  someMethod(event) {
    this.selectedImagesIds$.next(this.selectedImages.map(o => o.id));
  }

  isChecked(imageId: string): boolean {
    return this.selectedImages && this.selectedImages.map(selectedImage => selectedImage.id).includes(imageId)
  }

  changeOption(option) {
    this.option = option;
  }

  showImageDialog() {
    const data: any = {
      project: this.project,
      nodeImagesIds: this.linkImages.map(o => o.id),
      assetId: this.assetId,
      label: ''
    };
    const dialogRef = this.dialog.open(LinkImagesComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });
    dialogRef.afterClosed().subscribe(r => {
      if (!r) {
        return;
      }
      if (r.images.length) {
        if (this.uiService.asset.assetType != AssetType.SOLAR) {
          this.backendService.linkImages(this.assetId, this.modelId, this.labelId, r.images, "images", this.project.id,
            this.uiService.selectedBaseModel).subscribe();
        } else {
          this.isMediaLinking = true;
          let images = [];
          let media = {};
          if (this.media && this.media[this.labelId] && this.media[this.labelId]["images"]) {
            images = this.media[this.labelId]["images"].concat(r.images).filter(this.generalService.onlyUnique)
            this.media[this.labelId]["images"] = images;
            media = this.media;
          } else if (this.media && !this.media[this.labelId]) {
            const newSets = {
              [this.labelId]: {
                "images":
                  r.images

              }
            }
            media = { ...this.media, ...newSets }
          }
          if (!this.media) {
            media = {
              [this.labelId]: {
                "images": r.images
              }
            }
          }
          this.backendService.dXFLinkedImages(this.project.id, media, this.assetId, this.modelId, this.dxfLinkRef).subscribe((result: any) => {
            this.isMediaLinking = false;
            this.mediaUpdates.emit(this.modelId);
          }, error => {
            this.isMediaLinking = false;
            this.toastr.error("Something issue with your linking process")
          });

        }

      }
      if (r.videos.length) {
        if (this.uiService.asset.assetType != AssetType.SOLAR) {
          this.backendService.linkImages(this.assetId, this.modelId, this.labelId, r.videos, "videos", this.project.id,
            this.uiService.selectedBaseModel).subscribe();
        } else {
          this.isMediaLinking = true;
          let videos = [];
          let media = {};
          if (this.media && this.media[this.labelId] && this.media[this.labelId]["videos"]) {
            videos = this.media[this.labelId]["videos"].concat(r.videos).filter(this.generalService.onlyUnique)
            this.media[this.labelId]["videos"] = videos;
            media = this.media;
          } else if (this.media && !this.media[this.labelId]) {
            const newSets = {
              [this.labelId]: {
                "videos":
                  r.videos

              }
            }
            media = { ...this.media, ...newSets }
          }
          if (!this.media) {
            media = {
              [this.labelId]: {
                "videos": r.videos
              }
            }
          }
          this.backendService.dXFLinkedImages(this.project.id, media, this.assetId, this.modelId, this.dxfLinkRef).subscribe((result: any) => {
            this.isMediaLinking = false;
            this.mediaUpdates.emit(this.modelId);
          }, error => {
            this.isMediaLinking = false;
            this.toastr.error("Something issue with your linking process")
          });
        }
      }
    })
  }
  isMediaLinking = false;
  isLinking = false;
  delete() {
    const _this = this;
    if (_this.selectedImagesIds$.value.length) {
      if (this.uiService.asset.assetType != AssetType.SOLAR) {
        const images =
          this.linkImages.filter(o => o.type === this.option).map(o => o.id).filter(function (obj) { return _this.selectedImagesIds$.value.indexOf(obj) == -1; });
        this.backendService.removedLinkedImages(this.assetId, this.modelId, this.labelId, images, this.option, this.project.id).subscribe();
        this.linkImages = this.linkImages.filter(item2 => !_this.selectedImagesIds$.value.some(item => item === item2.id));
      } else {
        this.isLinking = true;
        this.linkImages.filter(o => o.type === this.option).map(o => o.id).filter(function (obj) { return _this.selectedImagesIds$.value.indexOf(obj) == -1; });
        this.linkImages = this.linkImages.filter(item2 => !_this.selectedImagesIds$.value.some(item => item === item2.id));
        const images = this.media[this.labelId][this.option].filter(item => !_this.selectedImagesIds$.value.includes(item));
        this.media[this.labelId][this.option] = images;
        this.backendService.dXFLinkedImages(this.project.id, this.media, this.assetId, this.modelId, this.dxfLinkRef).subscribe((result: any) => {
          this.isLinking = false;
          this.mediaUpdates.emit(this.modelId);
        }, error => {
          this.isLinking = false;
          this.toastr.error("Something issue with your linking process")
        });
      }
    }
  }

  goToCanvas(media) {
    if (media.type === "images") {
      this.router.navigateByUrl(`/dashboard/projects/${media.projectId}/images/${media.id}`);
    } else {
      this.dialog.open(VideoModalComponent, {
        data: media,
        width: '100%',
        height: '100%',
        panelClass: 'video-dialog'
      });

    }
  }

  sortList(event) {
    const lsits = event.map(o => o.id);
    this.backendService.reOrderedImages(this.assetId, this.modelId, this.labelId, lsits, this.option, this.project.id).subscribe(() => { })
  }


  checkTopSeverity(img) {
    const image = this.uiService.projectImageContexts.find(o => o.id == img.id);
    if (image) {
      const levels = image.tags?.filter(o => o.sensitive).map(o => o.sensitive);
      if (levels && levels.length) {
        const maxLevel = Math.max(...levels);
        const tag = image.tags.find(element => element.sensitive === maxLevel);
        const findTag = this.uiService.tags.find(x => x.tag == tag.tag && x.status == 'active')
        if (findTag)
          return findTag.levels?.find(o => o.level == maxLevel)?.color;
      }
    }
    return "";
  }

  
  getSeverityDetail(image) {
    return this.generalService.getSeverityDetail(image,this.uiService.tags);
  }

  
  isEmptyObject(obj) {
    if (typeof obj === 'object' && obj != null && Object.keys(obj).length !== 0) {
      return false;
    } else {
      return true;
    }
  }

  openExifInfo(metaData) {
    if (!this.isEmptyObject(metaData)) {
      this.dialog.open(this.exifModelDialog, {
        panelClass: 'report-panel',
        data: {
          data: metaData,
          isExapnd: false,
          more: Object.entries(metaData),
        },
      });
    }
  }

  openPrompt(data, isMore) {
    if (isMore) {
      data.isExapnd = true;
    } else {
      data.isExapnd = false;
    }
  }


  toggle3DIcon(image) {
    const exclude = this.uiService.excluded3DIamges.includes(image.id);
    this.backendService.exclude3DImage$(image.id, image.projectId, !exclude, this.uiService.excluded3DIamges.length).subscribe(() => { })

  }

  getExcudedImages(imageId) {
    return this.uiService.excluded3DIamges.includes(imageId)
  }

  async getNodeByLayer(data) {
    const ids = [];
    async function search(tree) {
      ids.push(tree.id);
      if (tree.children) {
        tree.children.forEach(child => search(child));
      }
    }
    data.children.forEach(item => search(item));
    return ids;
  }

  onScroll() {
    this.isContentLoading$.next(true);
    setTimeout(() => {
      this.endIndex = this.endIndex + 32;
      this.isContentLoading$.next(false);
    }, 200);

  }
  handleLabelSelectionChange() {
    this.selectionChange.emit();
  }
}