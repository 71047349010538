<div class="popup-header w-100 d-flex">
    <p class="w-100">
       {{ this.form.get('reportName').value || 'Reports'}} 
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>
        <div class="d-flex align-items-center">
            <mat-tab-group class="mat-small-tabs h-100 w-100" [selectedIndex]="selectedTabIdx"
                (selectedTabChange)="selectedTabChange($event)">
                <mat-tab label="Reports">
                    <ng-container [ngTemplateOutlet]="reportsContent"></ng-container>
                </mat-tab>
                <mat-tab label="Initials">
                    <ng-container [ngTemplateOutlet]="initialsContent"></ng-container>
                </mat-tab>
            </mat-tab-group>
        </div>

        <ng-template #reportsContent>
            <div class="form-container w-100 mt-2">
                <form class="d-flex flex-column w-100" [formGroup]="form">
                    <div class="form-field mb-3 d-flex align-items-center">
                        <mat-form-field class="mb-0 full-width" appearance="fill">
                            <mat-select formControlName="projectId" disabled placeholder="Select project">
                                <mat-option [value]="project.id" *ngFor="let project of projects">{{
                                    project.name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="form-field mb-3 d-flex align-items-center" style="background: #ffffff;">
                        <input id="report-name-input" type="test" maxLength="64" required formControlName="reportName"
                            [class.is-invalid]="
                                form.get('reportName').invalid &&
                                form.get('reportName').touched
                            " class="form-control input-field report-name-input" placeholder="Report Title"
                            name="reportName" />
                    </div>

                    <div class="form-field  mb-3 d-flex align-items-center">
                        <mat-form-field class="mb-0 full-width" appearance="fill">
                            <mat-select panelClass="myPanelClass" class="mySelectClass" disableOptionCentering
                                formControlName="templateId" placeholder="Select Template">
                                <mat-option (click)="addNewTemplate($event)" class="add-template-option">
                                    <span>Add new template</span>
                                </mat-option>
                                <mat-option [value]="template.id" *ngFor="let template of templates">{{
                                    template?.title }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <app-upload-template [templates]="templates$ | async" (successLoad)="onSuccessAddTemplate()">
                        <button #uploadTmplBtn class="UppyTemplateModalOpenerBtn" style="display: none" type="button">
                            <span class="text-uppercase">Upload template</span>
                        </button>
                    </app-upload-template>
                </form>

                <div class="form-field mb-4 d-flex align-items-center">
                    <mat-form-field class="mb-0 full-width" appearance="fill">
                        <mat-select panelClass="myPanelClass" class="mySelectClass" disableOptionCentering
                            *ngIf="projectInitials && projectInitials.length > 0" placeholder="Initials"
                            [(ngModel)]="selectedInitialsObjIdx">
                            <mat-option [value]="Initials.id"
                                *ngFor="let Initials of projectInitials; let idx = index;"> Initials
                                {{idx +1 }} {{getUserName(Initials) }}</mat-option>
                        </mat-select>

                        <mat-select (click)="addNewInitials()" *ngIf="!projectInitials || projectInitials.length == 0"
                            placeholder="Add new Initials">
                            <!-- <mat-option >
                                Add new initials</mat-option> -->
                        </mat-select>

                    </mat-form-field>

                </div>
                <div class="text-center">
                    <button mat-raised-button type="submit" color="primary" class="submit-button" (click)="openEditor()"
                        [disabled]="!form.valid || !selectedInitialsObjIdx">
                        <span class="text-uppercase">Next</span>
                    </button>
                </div>
            </div>
        </ng-template>

        <ng-template #initialsContent>
            <app-project-initials class="w-100" (removeInitials)="removeInitials($event)" *ngIf="form.get('projectId').value" [projects]="projects" [projectUsers]="projectUsers"
                [projectId]="form.get('projectId').value"></app-project-initials>
        </ng-template>
   