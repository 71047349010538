import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  ElementRef,
  ContentChildren,
  QueryList,
  OnInit,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import Konva from 'konva';
import { CoreShapeComponent as CoreShape } from './core-shape.component';
import { BehaviorSubject, Subscription } from 'rxjs';
import { KonvaComponent } from '../ko.interface';
import { redraw, createListener, updateProps, dragInit, registerDragBounds, registerZoomOnWheel } from '../utils';

@Component({
  selector: 'ko-stage',
  template: ` <ng-content></ng-content> `,
})
export class StageComponent
  implements KonvaComponent, AfterContentInit, OnInit, OnDestroy {
  @ContentChildren(CoreShape) shapes = new QueryList<CoreShape>();

  @Output() onclick: EventEmitter<any> = new EventEmitter();
  @Output() ondblclick: EventEmitter<any> = new EventEmitter();
  @Output() onmouseover: EventEmitter<any> = new EventEmitter();
  @Output() onmouseout: EventEmitter<any> = new EventEmitter();
  @Output() onmousemove: EventEmitter<any> = new EventEmitter();
  @Output() onmouseup: EventEmitter<any> = new EventEmitter();
  @Output() onmousedown: EventEmitter<any> = new EventEmitter();
  @Output() ontap: EventEmitter<any> = new EventEmitter();
  @Output() ondbltap: EventEmitter<any> = new EventEmitter();
  @Output() ontouchstart: EventEmitter<any> = new EventEmitter();
  @Output() onscaleXChange: EventEmitter<any> = new EventEmitter();
  @Output() onfillChange: EventEmitter<any> = new EventEmitter();
  @Output() ondragstart: EventEmitter<any> = new EventEmitter();
  @Output() ondragmove: EventEmitter<any> = new EventEmitter();
  @Output() ondragend: EventEmitter<any> = new EventEmitter();
  @Output() oncontextmenu: EventEmitter<any> = new EventEmitter();
  @Output() touchmove: EventEmitter<any> = new EventEmitter();

  @Input() config$: BehaviorSubject<any>;

  private _stage;
  private _config;
  private _cachedProps: any = {};
  private _subscription: Subscription;

  public getStage() {
    return this._stage;
  }

  public getConfig() {
    return this._config;
  }

  public reset() {
    this._stage.setAttrs({ x: 0, y: 0, scale: { x: 1, y: 1 }});
    this._stage.batchDraw();
  }

  public getScale() {
    return this._stage.scaleX();
  }

  constructor(private elementRef: ElementRef) {}

  private updateStage(config) {
    const props = {
      ...config,
      ...createListener(this),
    };

    updateProps(this, props, this._cachedProps);

    this._cachedProps = props;
  }

  ngOnInit() {    
    if (!this.config$ || !this.config$.subscribe) {
      throw new Error('You must provide config$ of type BehaviorSubject to the konva stage');
    }

    this.updateConfig(this.config$.getValue());
    this._subscription = this.config$.subscribe((c) => this.updateConfig(c));
  }

  private updateConfig(config) {
    this._config = config;

    if (!this._stage) {
      this._stage = new Konva.Stage({
        width: config.width,
        height: config.height,
        container: this.elementRef.nativeElement,
        draggable: true
      });

      dragInit(this._stage);

      registerZoomOnWheel(this._stage);
      registerDragBounds(this._stage);
    }

    this.updateStage(config);
  }

  ngAfterContentInit() {
    this.shapes.forEach((item: CoreShape) => {
      this._stage.add(item.getStage());
    });

    redraw(this._stage);
  }

  ngOnDestroy() {
    if (this._stage) { this._stage.destroy(); }
    if (this._subscription) { this._subscription.unsubscribe(); }
  }
}
