<section>
    <h3 class="main-title">AI Software as a Service (Saas) Terms of Use</h3>
    <ol>
         <li>
              <h4>Overview</h4>
              <ol>
                <li>This online enterprise inspection and reporting platform [<a target='_blank'
                    href="{{origin}}">{{origin}}</a>] (“<b>Platform</b>”)
                is
                maintained and owned by Oceans.ai Pte. Ltd. (“<b>Oceans.ai</b>”).</li>
                   <li>These Terms of Use form a legally binding agreement between you and Oceans.ai, and govern
                        your use of and access to the Platform and the Services (including DeepDIVE) (all defined below).
                        These Terms of Use highlight your respective legal rights and obligations when using and/or
                        accessing the Platform and/or Services. Please read the Terms of Use carefully (including the
                        <a target='_blank'
                             href="https://www.oceansai.tech/Privacy%20Notice.html"> Privacy
                             Policy </a> ) before using this Platform and/or Services. By using and accessing this
                        Platform and/or Services, you agree to be legally bound by these Terms of Use. Please do not
                        use or access this Platform and/or Services if you do not agree to any of these Terms of Use.
                   </li>
                   <li>Services provided via this Platform include, without limitation:
                        <ol>
                             <li>the use of Oceans.ai’s proprietary deep learning Digital Inspection Visual Engine, an
                                  enterprise inspection and reporting platform and AI data processing tool kit
                                  (“<b>DeepDIVE</b>”),provided as a Software as a Service (Saas) for the purposes of visual
                                  data processing services and visual inspection solutions, including inspecting,
                                  identifying, tagging, annotating, and reporting of visual defects; and exporting the
                                  data in AI-friendly formats for AI processing. </li>
                             <li>all other related services provided by Oceans.ai and its agents and/or
                                  employees,(collectively, the “<b>Services</b>”).</li>
                        </ol>
                   </li>
                   <li>The terms “<b>you</b>” and “<strong>your</strong>” in these Terms of Use shall mean the business entity on whose behalf you are using the Services and/or entering into this Agreement 
                        (“<strong>Business Entity</strong>”)”), unless the context does not permit. By using the Platform and/or the Services, you hereby represent and warrant that you are duly authorised to act on behalf of the Business Entity, and that these Terms of Use are binding upon the Business Entity.</li>
                   <li>These Terms of Use are not exclusive from any other agreement on this Platform. Additional terms, conditions and notices may also be posted in such other areas or sections of this Platform, and together with these Terms of Use, govern your access and usage of the Platform and Services provided. These Terms of Use, together with such applicable additional terms and conditions, are referred to as this“<strong>Agreement</strong>”.</li>
                   <li>These Terms of Use apply to all users of the Platform and Services. Any breach of these Terms of
                        Use by you may result in immediate termination or suspension of your account without prior
                        notification.</li>
              </ol>
         </li>
         <li>
              <h4>Licence</h4>
              <ol>
                   <li>Subject to your compliance with these Terms of Use, Oceans.ai hereby grants you a limited, revocable, non-exclusive, and non-transferable right to access the Platform via a designated web browser and use the Services including DeepDIVE through such access, solely for your internal business purposes.</li>
                   <li>In using the Platform and/or the Services, you agree:
                        <ol>
                             <li>	to comply with all notices, guidelines, rules and instructions pertaining to the use of the Platform and/or the Services as issued in writing by Oceans.ai from time to time, and any operating rules or policies that may be published from time to time by Oceans.ai.</li>
                             <li>to abide by all applicable rules, laws, regulations, or guidelines in connection with your access to or use of the Platform and/or Services.</li>
                             <li>not to permit or grant access to the Platform and/or Services to any third party,
                                  other than as expressly permitted under this Agreement.</li>
                             <li>not to, without the prior written approval of Oceans.ai, copy or reproduce Platform
                                  and/or Services by any means or in any form, or modify, reproduce, adapt, or publicly
                                  distribute any materials or content on this Platform.</li>
                             <li>not to reverse engineer, decompile, disassemble or otherwise attempt to discover the
                                  object code, source code or underlying ideas or algorithms of the Platform and/or
                                  Services.</li>
                             <li>not to modify, translate, or create derivative works based on any element of the
                                  Platform or Services.</li>
                             <li>not to rent, lease, distribute, sell, resell, assign, or otherwise transfer its rights
                                  to use the Platform and/or Services.</li>
                             <li>not to use the Platform for timesharing purposes or offer on a “pay-per-use” basis or
                                  otherwise for the benefit of any person or entity.</li>
                             <li>to comply with all applicable data protection and privacy laws, including the Personal
                                  Data Protection Act 2012 (“<strong>PDPA</strong>”).</li>
                             <li>that you have obtained all relevant and necessary consents before posting, providing,
                                  or submitting the personal data of third parties.</li>
                             <li>not to attempt to obtain unauthorised access to the Platform and/or the Services, or
                                  to hack, compromise or tamper with the security or security features of the Platform
                                  and/or Services, including:
                                  <ol>
                                       <li>not to deliberately interfere with or hamper the working and performance of
                                            the Platform and/or Services, or the access of any user, host, or network,
                                            including without limitation, hacking, transmitting any virus, overloading,
                                            flooding, spamming, denial-of-service attacks, or tampering with any aspect
                                            of the Platform and/or Services.</li>
                                       <li>not to use any cheats, exploits, hacks, bots, mods or third party software
                                            designed modify or interfere with the Platform and/or Services.</li>
                                       <li>not to avoid, bypass, remove, deactivate, impair, descramble or otherwise
                                            circumvent any technological measure implemented by Oceans.ai to protect
                                            the Platform, Services, the DeepDIVE, and/or intellectual property owned by
                                            Oceans.ai, or attempt to do any of the foregoing; and</li>
                                  </ol>
                             </li>
                             <li>not to use the Platform or Services for any purpose other than the purposes for which
                                  they are provided by Oceans.ai.</li>
                        </ol>
                   </li>
              </ol>
         </li>
         <li>
              <h4>User Account and Passwords</h4>
              <ol>
                   <li>Oceans.ai will issue to you one or more user logins and passwords for access and use of the
                        Platform and Services by users authorised by you (each an “<strong>Authorised User</strong>”).
                        You are responsible for maintaining the confidentiality of all user logins and passwords and
                        for ensuring that each user login and password is used only by the  Authorised User to which it
                        was issued. You shall restrict your Authorised User(s) from sharing passwords.</li>
                   <li>You agree to be solely responsible for all online activities and communications conducted
                        through your account and/or associated with the user logins and passwords of you and your
                        Authorised Users. You agree and acknowledge that Oceans.ai will deem all access and use of the
                        Services and all transactions entered into and activities conducted under the user logins of
                        you and your Authorised Users as transactions entered into and activities conducted by you, and
                        you agree to be liable for all such transactions and activities. You agree that Oceans.ai will
                        not be liable for any loss, damage, liability, claims, demands, fees, costs and expenses
                        (including legal and professional costs and expenses) (collectively “<strong>Loss</strong>”)
                        arising as a result of or in connection with the unlawful, fraudulent or otherwise wrongful or
                        unauthorised use of your account orany failure to comply with the terms set forth in this
                        <strong>Clause 3</strong>.
                   </li>
                   <li>You are solely responsible for, and bear the risk of, all activities carried out under or
                        pursuant to the user logins and passwords assigned to you and your Authorised Users, whether or
                        not such use is authorised by you. Oceans.ai shall not be responsible for controlling or
                        monitoring the use of user logins and passwords, or for any Loss that may be suffered by you or
                        any third party as a result of any unauthorised use of the user logins and passwords of you and
                        your Authorised Users. To prevent any unauthorised use, you agree to take such actions as are
                        necessary to maintain the confidentiality of the user logins and passwords of you and your
                        Authorised Users. You agree to immediately notify Oceans.ai of any unauthorised use of the
                        account and/or login and password of you and/or your Authorised Users, or any other breach of
                        security known to you.</li>
              </ol>
         </li>
         <li>
              <h4>Uploaded Data</h4>
              <ol>
                   <li>By making available any text, data, information, graphics, images, photos, and any other content
                        uploaded, transmitted, or submitted on or through the Platform (“<strong>Uploaded
                             Data</strong>”), you hereby grant to us, for the purpose of operating the Platform and
                        providing the Services, and any other services to be provided by Oceans.ai to you, a worldwide,
                        non-exclusive, sublicensable, and royalty-free licence to use, modify, reproduce, and/or
                        process any Uploaded Data in the Platform, whether now known or hereinafter developed, without
                        the requirement to make any payment to the you or to any third party, or the need to seek any
                        third party permission.</li>
                   <li>In addition, you agree that Oceans.ai shall have the right to use, modify, reproduce, and
                        process Uploaded Data(other than personally identifiable information) in individual or aggregate
                        form (a) to the extent and in the manner permitted by all applicable laws or regulations,
                        including the PDPA, and (b) with your prior consent,for purposes of deep machine learning and
                        improvement of artificial intelligence; data gathering, analysis; and service enhancement for
                        the benefit of Oceans.ai clients; provided that Oceans.ai shall not advertise, market or
                        otherwise disclose to any third parties your name or logo, without your prior written approval.
                   </li>
                   <li>You acknowledge and agree that you are solely responsible for all Uploaded Data that you
                        make available on or through the Platform and/or Services, including any Uploaded Data uploaded
                        to be processed by DeepDIVE. Accordingly, you agree, represent, and warrant that:
                        <ol>
                             <li>you are either the sole and exclusive owner of all Uploaded Data that you
                                  make available on or through the Platform and/or Services, or that you have all
                                  rights, licences, consents, and releases that are necessary to provide Oceans.ai with
                                  the Uploaded Data, including for use in performing the Services or any other services
                                  to be provided by Oceans.ai to you hereunder, including for the purposes set out in
                                  <strong>Clauses 4.1</strong> and  <strong>4.2.</strong>
                             </li>
                             <li>any information you post to or transmit through the Platform:
                                  <ol>
                                       <li>is truthful, accurate, valid, complete, up to date, not misleading, and
                                            offered in good faith, and that you have the right to post or transmit such
                                            information; and</li>
                                       <li>complies with all applicable laws, regulations, permits, and approvals.</li>
                                  </ol>
                             </li>
                             <li>the Uploaded Data, your posting, uploading, publication, submission, or transmission
                                  of the Uploaded Data (or any portion thereof) on, or via the Platform and/or Services
                                  do not contain any content, information, data and/or material that:
                                  <ol>
                                       <li>is unlawful, infringing, or false;</li>
                                       <li>is an infringement of any intellectual property or other rights of a third
                                            party, or would otherwise violate or encourage the violation of any law,
                                            intellectual property rights, or other rights of any third party;</li>
                                       <li>contains viruses, Trojan horses, worms, time bombs or any other programs
                                            designed to impair the operation and functionality of the Platform or any
                                            computer; and</li>
                                       <li>without the relevant third party owners’ permission, contains any links to
                                            third party websites or use of any third party trade marks.</li>
                                  </ol>
                             </li>
                             <li>You undertake that you shall maintain updated backups of all data (including Uploaded
                                  Data) which you upload to the Platform.</li>
                        </ol>
                   </li>
              </ol>
         </li>
         <li>
              <h4>Security</h4>
              <ol>
                   <li>Oceans.ai will deploy reasonable security precautions intended to protect against unauthorised
                        access to any of Your Data stored by Oceans.ai in connection with your operation of the
                        Platform. Oceans.ai will exercise reasonable efforts to deploy corrections within the Platform
                        for security breaches made known to Oceans.ai. You may not circumvent or otherwise interfere
                        with any user authentication or security of the Platform.</li>
                   <li>You acknowledge that, notwithstanding the security precautions deployed by Oceans.ai, the use
                        of, or connection to, the In ternet provides the opportunity for unauthorised third parties to
                        circumvent such precautions and illegally gain access to the Platform and Your Data. Oceans.ai
                        cannot and does not guarantee the privacy, security, integrity or authenticity of any
                        information transmitted over or stored in any system connected to or accessible via the
                        Internet or otherwise or that any such security precautions will be adequate or sufficient.
                   </li>
              </ol>
         </li>
         <li>
              <h4>Services and Exported Data</h4>
              <ol>
                   <li>As part of the Services offered by us, Uploaded Data submitted by you onto the Platform can be
                        processed using Ocean.ai’s proprietary DeepDIVE, which enables you to process data.</li>
                   <li>In order for DeepDIVE to process your Uploaded Data, you must provide a list of identifiers of
                        defects(“Identifiers”) to be submitted to the Platform. Depending on the Identifiers submitted
                        by you (including the type, quality, quantity, and specificity of Identifiers),You agree that the accuracy, quality and comprehensiveness of the Exported Data shall be dependent on the Identifiers submitted by you. Hence, Oceans.ai provides no representations or warranties in relation to, and shall not be liable for, any defects that were not identified in the Exported Data.
                    </li>
                   <li>
                    3.	Each account is issued with such amount of credits as may be specified by Oceans.ai based on the contract drawn up for the specific account. These credits are applied against the capacty utilization on the Platform by the specific account. The credits maybe based on specific payment frameworks setup for the account (for example number of asset, users and storage capacity). On expiry of the Credits it is the onus of the account holder to add more credits other their access to the platform will be limited or cut-off depending on the extent of credits overshot. 
            </li>
              </ol>
         </li>
         <li>
              <h4>Payment</h4>
              <ol>
                   <!-- <li>You may purchase a minimum of 1,000 credits at any time. For each purchase of 1,000 credits, you
                        will be charged the amount of USD 8.00.</li> -->
                   <li>Any fees, costs, and other amounts payable for the Platform and/or the Services under this Agreement as specified from Oceans.ai including the Credit Fees "(<strong>Fees</strong>"), are payable as set out in this <strong>Clause 7</strong> and such other terms as may be specified by Oceans.ai on the Platform from time to time, and may be revised or modified by Oceans.ai in its sole discretion from time to time. </li>
                 
                   <li>2.	All Fees payable under this Agreement by you to Oceans.ai shall be in United States Dollars and shall be paid by mode of payment as may be designated by Oceans.ai from time to time. All bank charges and taxes, if any, shall be borne by you. Any required currency conversion shall be made at the exchange rate prevailing at the time of accrual, as quoted by the bank, card company, or any other source as designated by Oceans.ai to you from time to time.</li>
                   <li>All taxes, including goods and services tax and value added tax, in respect of any amounts due
                        under this Agreement or any other payments which are to be made by you to Oceans.ai, shall be
                        borne by you.</li>
                   <li>Oceans.ai shall be entitled (but not obliged) to set-off and deduct any sums due from you to
                        Oceans.ai against any amounts due or to become due from Oceans.ai to you under this Agreement
                        or any other contract with Oceans.ai.</li>
                   <li>Oceans.ai reserves the right to cancel, terminate, or suspend your account and/or your access to
                        and use of the Services in its sole and absolute discretion, in the event of any failure by you
                        to make full payment of any sum(s) due from you to Oceans.ai by their respective due dates as
                        stated on Ocean.ai’s invoices.</li>
                        <li>
                          Please note that all Fees paid under this Agreement including Credit Fees shall not be refundable for any reason (including but not limited to the termination, deletion, or suspension of your account) and that any unused credits or Free Credits cannot be encashed or transferred to another account.
                        </li>
              </ol>
         </li>
         <li>
              <h4>Amendments and Modifications</h4>
              <ol>
                   <li>Oceans.ai has absolute and unfettered discretion from time to time, but shall not be obliged to:
                        <ol>
                             <li>make upgrades, changes, alterations, enhancements, and/or modifications to the
                                  Platform and/or Services, including the development of new features, designs, user
                                  interfaces, functions and capabilities, updates, patches, maintenance, upgrades
                                  and/or the procurement of new releases of any software; and</li>
                             <li>modify, change, update, or revise these Terms of Use, including any Fee structure and
                                  other features;</li>
                             <li>remove, discontinue, suspend, or terminate the whole or any portion of the Platform
                                  and/or Services;</li>
                             <li>impose restrictions on certain features or restrict your access to parts of the entire Platform or Services,</li>
                        </ol>
                   </li>
                   <li>Oceans.ai shall not be liable for any Loss arising as a result of or in connection with any loss
                        of or interruption in the access of the Platform and/or use of the Services due to any Changes.
                   </li>
                   <li>Any amendments to these Terms of Use will apply and take effect immediately at the time they are
                        published on this website. It is your responsibility to remain updated on any changes that may
                        be made to the relevant 
                        <a href="/legal">Terms of Use</a>, which shall be updated on the following website from time to time. You shall be deemed to have accepted and affirmed the amended or modified Terms of Use by your continued use of the Platform and Services following any change to the Terms of Use. Oceans.ai will not in any event be liable for any Loss resulting from any amendment(s) or modifications to these Terms of Use.</li>
                   <li>Oceans.ai shall be entitled to specify and update from time to time, the required configuration
                        and specifications for hardware and software as it deems necessary or appropriate for your
                        access and connectivity to the Platform or the use of the Services, and you agree to comply
                        with such initial and updated configurations and specifications at your own costs, failing
                        which you accept that you may not be able to obtain access and connectivity to the Platform
                        and/or the use of the Services.</li>
              </ol>
         </li>
         <li>
              <h4>Intellectual Property</h4>
              <ol>
                   <li>Any and all intellectual property rights embodied in or relating to or used in connection with
                        in the Platform, the Services, DeepDIVE, the Exported Data, and all materials and works comprised
                        in or relating thereto, with the exception of the Uploaded Data, including without limitation:
                        <ol>
                             <li>all trade marks, service marks, logos, trade names, information, text, data, images,
                                  graphics, photos, links, maps, materials, data, software, music, audio-visual
                                  content, and/or other materials, produced and/or procured by Oceans.ai, whether or
                                  not the aforesaid were incorporated into the Platform;</li>
                             <li>HTML, WML, Java, CGI scripts, javascript and/or all other forms of computer code
                                  employed in the design, creation and posting of the Platform on the Internet, and all
                                  updates and derivative works thereto;</li>
                             <li>all computer programs, modules, algorithms and/or tools relating to DeepDIVE (including
                                  the source codes and the object codes, and all documentation, concepts, database,
                                  dataand methodologies thereof);</li>
                             <li>the graphical user interfaces relating to the Platform;</li>
                             <li>the compilation, collection, arrangement and assembly of all data, information and
                                  content on the Platform and in the Exported Data; and</li>
                             <li>any other proprietary designations or materials used in association with the Platform
                                  and the Services,
                                  (collectively "<strong>OA Materials</strong>"), are the property of Oceans.ai, its
                                  licensors and/or its affiliates, (each an “<strong>Owner</strong>”) and are protected
                                  from unauthorised use, copying or dissemination by copyright law, trade mark law,
                                  designs law, patent law, international conventions, and other intellectual property
                                  laws. No part or whole of any OA Materials or the Platform may be copied, reproduced,
                                  published, modified, adapted, uploaded, posted, transmitted, distributed, or
                                  commercially exploited in any way without the prior written consent of the Owner.
                             </li>
                        </ol>
                   </li>
                   <li>Any and all intellectual property rights embodied in or relating to the Uploaded Data are the
                        property of you, your licensors and/or your affiliates and are protected from unauthorised use,
                        copying or dissemination by copyright law, trade mark law, designs law, patent law,
                        international conventions, and other intellectual property laws. No part or whole of any
                        Uploaded Data may be copied, reproduced, published, modified, adapted, uploaded, posted,
                        transmitted, distributed, or commercially exploited in any way without the prior written
                        consent by you.</li>
              </ol>
         </li>
         <li>
              <h4>Indemnity</h4>
              <ol>
                   <li>By using the Platform and/or Services, you agree to indemnify, hold harmless, and defend
                        Oceans.ai, its affiliates, officers, directors, employees and agents, from and against any and
                        all Loss incurred, arising out of or relating to:
                        <ol>
                             <li>your access to, reliance on, and/or use of the Platform, Services,Exported Data,and/or
                                  OA Materials;</li>
                             <li>any claims by any third parties, and any claim that your access to, reliance on,
                                  and/or use of the Platform, the Services, Exported Data, and/or OA Materials, caused
                                  Loss, damage, injury or death to a third party;</li>
                             <li>any violation of any third party right by you, including without limitation any
                                  intellectual property, or unauthorised disclosure of any proprietary information of
                                  Oceans.ai;</li>
                             <li>any other liabilities incurred through your access to, reliance on, and/or use of the
                                  Platform, Services, Exported Data, and/or OA Materials;and/or</li>
                             <li>any breach of this Agreement, including without limitation <strong>Clauses
                                       3,4,</strong> and <strong>12</strong>.</li>
                        </ol>
                   </li>
                   <li>The above indemnification obligation will survive the expiry or termination of this Agreement,
                        and your access to, reliance on, and/or use of the Platform, Services, Exported Data, and/or OA
                        Materials.</li>
              </ol>
         </li>
         <li>
              <h4>Warranties</h4>
              <ol>
                   <li>To the fullest extent permitted by applicable law, Oceans.ai, its affiliates, licensors, and
                        their respective directors, officers, agents, and employees (collectively, the "<strong>OA
                             Parties</strong>") expressly disclaim all warranties or undertakings of any kind, whether
                        express or implied, including, but not limited to:
                        <ol>
                             <li>any warranties of title, non-infringement of third party rights, merchantability,
                                  satisfactory quality, fitness for a particular purpose and freedom from computer
                                  virus or other malicious, destructive or corrupting code, agent, program or macros,
                                  is given in conjunction with the Services, Platform, the Exported Data, or any
                                  information and materials provided through the same.</li>
                             <li>any warranty, undertaking, or representation of any kind that the Platform, Services,
                                  and Exported Data will meet your requirements, expectations, or specifications,
                                  achieve any intended results, or be compatible or work with any other software,
                                  applications, systems or services. You acknowledge and agree that the Platform,
                                  Services and Exported Data are not intended to meet any of your specific
                                  requirements, and any such request for specific requirements shall be subject to
                                  separate terms to be agreed in writing with Oceans.ai.</li>
                        </ol>
                   </li>
                   <li>Oceans.ai will use reasonable efforts to keep the Platform and Services operational. However,
                        certain technical difficulties and/or maintenance may, from time to time, result in temporary
                        interruptions or downtime. Neither Oceans.ai nor any other OA Party warrants uninterrupted use,
                        operation, or availability of the Platform and/or Services, or that any submission or
                        transaction request you attempt using the Platform or Services will be successful, uncorrupted,
                        or completed within a reasonable amount of time or meet any performance or reliability
                        standards. You acknowledge and agree that the Services and the Platform may have errors and may
                        encounter unexpected technical problems from time-to-time, and that you may experience downtime
                        and errors in the operation, functionality or performance of the Platform and/or the Services.
                   </li>
                   <li>Oceans.aidoes not warrant, endorse, guarantee or assume responsibility for any third party
                        information, materials, content, or any other product or service produced or provided by a
                        third party. Consequently, Oceans.ai will not be responsible for any transaction between you and
                        third party providers of third party products, and/or services, or for any acts or omissions of
                        such third parties.</li>
                   <li>No advice or information, whether oral or written, obtained by you from Oceans.aior any other OA
                        Party through or from the Services, will create any warranty regarding the Services that is not
                        expressly stated in this Agreement.</li>
                   <li>You acknowledge and agree that:
                        <ol>
                             <li>your use of the Platform and/or the Services, including, without limitation, your use
                                  of any Exported Data or intellectual property owned by Oceans.ai, is at your sole
                                  risk;</li>
                             <li>the Platform, Services, all Exported Data and all OA Materials are provided on an "as
                                  is" and "as available" basis;</li>
                             <li>Oceans.ai endeavours to ensure, but does not guarantee that the content displayed on
                                  the Platform or in the Exported Data will be accurate or up to date. In particular,
                                  you acknowledge and agree that the Platform relies on artificial intelligence
                                  processing and may contain false positives or false negatives, depending on the
                                  quality and accuracy of the Uploaded Data and the Identifiers, and the duration and
                                  amount of deep learning acquired by the Platform at the relevant time.As such,
                                  Oceans.ai is not liable for any Loss suffered arising out of or due to your reliance on
                                  any results or information provided by the Platform, Services, or any Exported Data;
                             </li>
                             <li>Oceans.ai endeavours to ensure, but will not be able to ascertain the accuracy,
                                  quality, or reliability of any results, information,or materials posted on
                                  the Platform or in the Exported Data and will not be held liable for any Loss suffered
                                  by you arising out of or due to any errors or inaccuracies on the Platform or in the
                                  any Exported Data;</li>
                             <li>Oceans.ai is not liable for any viruses, spyware, defects, errors, malfunctions to
                                  computers that arises out of your usage of the Platform or the Exported Data;</li>
                             <li>Oceans.ai is not liable for any reliance on any information, opinions or views
                                  expressed by Oceans.ai on the Platform or the Exported Data;</li>
                             <li>the above disclaimers and limitation are required for Oceans.ai to provide you and
                                  other users with the full and complete Services requested and enable the Services to
                                  be provided to you.</li>
                        </ol>
                   </li>
              </ol>
         </li>
         <li>
              <h4>Confidentiality</h4>
              <ol>
                   <li>Each Party (“<strong>Recipient</strong>”) shall treat as confidential all data and information
                        relating to the business and other operations of the other Party (“<strong>Owner</strong>”) and
                        all other information whatsoever obtained by it pursuant to or by virtue of this Agreement in
                        any form or medium (including written, electronic or verbal), including but not limited to all
                        documents, materials, and information marked as "confidential" and whether or not such data
                        and/or information is contained or embodied in the Platform ("<strong>Confidential
                             Information</strong>"). For the avoidance of doubt, all data in and information relating
                        to the Platform, the Services, DeepDIVE, the OA Materials, and including the terms of this
                        Agreement, shall without any limitation, be included as the Confidential Information of
                        Oceans.ai, and you shall not use such Confidential  any purpose other than your
                        internal reference and internal business purposes.</li>
                   <li>The Recipient shall not divulge any Confidential Information to any person (except to its own
                        employees and then only to those employees who need to know the same) without the Owner's prior
                        written consent. Provided that this clause shall not extend to information which was rightfully
                        in the possession of the Recipient prior to the commencement of the negotiations leading to
                        this Agreement, or which is already public knowledge or becomes so at a future date (otherwise
                        than as a result of the Recipient's breach of this clause). The Recipient shall not use any
                        Confidential Information except for the purpose of performing any obligation or exercising any
                        right under this Agreement.</li>
                   <li>The Recipient will establish and maintain sufficient security measures and procedures to provide
                        for the safe custody of Confidential Information and to prevent unauthorised access thereto or
                        use thereof.</li>
                   <li>The Confidential Information is hereby acknowledged as confidential by Parties, and may be
                        disclosed by the Recipient if and only if:
                        <ol>
                             <li>required by law or order of a court of competent jurisdiction or government department
                                  or agency with valid authority; or</li>
                             <li>permitted under this Agreement, and to such extent only as is necessary for the
                                  purposes contemplated by this Agreement, or as is required by law, and subject in
                                  each case to the Recipient using its best endeavours to ensure that the person in
                                  question keeps the Confidential Information confidential and does not use it except
                                  for the purposes for which the disclosure is made.</li>
                        </ol>
                   </li>
              </ol>
         </li>
         <li>
              <h4>Termination</h4>
              <ol>
                   <li>Notwithstanding any other provision in this Agreement to the contrary, Oceans.ai reserves the
                        right, without notice or cause and in its sole discretion, to terminate this Agreement.</li>
                   <li>Oceans.ai reserves the right to cancel, terminate, and/or suspend your access to and use of the
                        Platform, Services, and/or your account at any time at its sole and absolute discretion without
                        notice or justification, and/or terminate this Agreement, including without limitation in the
                        event of unauthorised, or suspected unauthorised use of the Platform and/or the Services,
                        whether in contravention of this Agreement or otherwise. Oceans.ai reserves the right to
                        consequently reject or cancel any transactions or requests associated with you and your
                        account.</li>
                   <li>Any suspension, termination or other action by Oceans.ai set out in these Terms of Use will not
                        limit any other remedies Oceans.ai may have against you at law or equity.</li>
              </ol>
         </li>
         <li>
              <h4>Effect of Termination</h4>
              <ol>
                   <li>Upon termination of this Agreement, the Services, or your account, for any reason, you will lose
                        all access to your account, and to any content, materials or information relating to it.
                        Subject to the applicable laws, Oceans.ai will have no obligation to maintain any information
                        stored in its database related to your account, or to forward any information to you or any
                        third party.</li>
                   <li>Upon termination of this Agreement, the Services or your account, Oceans.ai will have no further
                        obligation to provide the Services, and all licences and other rights granted to you under
                        these Terms of Use will immediately cease. Oceans.ai will not be liable to you or to any third
                        party for such termination and cessation.</li>
                   <li>Any suspension, termination or cancellation of this Agreement, the Platform, Services, or your
                        account will not affect any of your obligations to Oceans.ai under these Terms of Use which by
                        their meaning and context are intended to survive such suspension, termination or cancellation.
                   </li>
              </ol>
         </li>
         <li>
              <h4>Limitation of Liability</h4>
              <ol>
                   <li>To the fullest extent permitted under applicable law, under no circumstances, including but not
                        limited to negligence, shall Oceans.ai be liable for any direct, indirect, incidental, special,
                        consequential, or exemplary damages, including, without limitation, damages for loss of
                        profits, goodwill, use, data, or other intangible losses (even if Oceans.ai has been advised of
                        the possibility of these damages), resulting from your access to or use of, or your inability
                        to access or use, the Platform, Services, any Exported Data, or any OA Materials, nor shall
                        Oceans.ai be responsible for any damages whatsoever that result from mistakes, omissions,
                        interruptions, deletion of files, errors, defects, delays in operation or transmission, or any
                        failure of performance whether or not caused by events beyond Oceans.ai’s reasonable control,
                        including but not limited to acts of God, communications failure, global positioning failure,
                        theft, destruction, or unauthorised access to Oceans.ai’s records, programs, or services.</li>
                   <li>In the event that Oceans.ai is liable for damages despite the foregoing provision, you agree that Oceans.ai’s aggregate liability to you for any and all causes of action in relation to the Platform, Services, Exported Data, or any OA Materials, and this Agreement, shall not exceed the total amount of fees and charges paid by you to Oceans.ai for the Services for the one (1) month] period immediately preceding the date on which such liability arose.</li>
              </ol>
         </li>
         <li>
              <h4>General</h4>
              <ol>
                   <li>
                        <h5>Notices</h5>
                        <ol>
                             <li>Any notice, demand or other communication sent by Oceans.ai shall be sent to the email
                                  address notified by you, and you shall be responsible for ensuring that the email
                                  address provided is correct and current.</li>
                             <li>Any notice, demand or other communication sent by Oceans.ai shall be deemed to have
                                  been received by you:
                                  <ol>
                                       <li>if personally delivered, at the time of delivery;</li>
                                       <li>if sent by post, five days after the date of posting;</li>
                                       <li>if by email, two days after the date and time of transmission directed to
                                            the email address notified by you, unless Oceans.ai receives a non-delivery
                                            or error message indicating that the email was not successfully sent to the
                                            recipient’s mailbox or mail server.</li>
                                  </ol>
                             </li>
                        </ol>
                   </li>
                   <li>
                        <h5>Entire Agreement</h5>
                        This Agreement constitute the entire agreement between you and Oceans.ai, and supersedes any
                        prior agreements, undertakings, representations, and warranties in relation to the Platform,
                        and/or the Services, whether written or oral. Any additional or different terms or conditions
                        in relation to the subject matter of this Agreement in any written or oral communication from
                        you to Oceans.ai are void unless agreed to in writing by Oceans.ai. You agree and accept that
                        you have not accepted this Agreement in reliance of or to any oral or written representations
                        made by Oceans.ai which are not contained in this Agreement.
                   </li>
                   <li>
                        <h5>Waiver &amp; Remedies</h5>
                        <ol>
                             <li>No failure or delay or partial exercise by any party in exercising that party's
                                  rights, power or remedies under this Agreement shall operate as a waiver of that or
                                  any other right, power or remedy of that party unless that party has waived its
                                  rights, powers or remedies in writing.</li>
                             <li>No waiver of any party’s rights, powers or remedies in respect of a breach of this
                                  Agreement by any other party shall be:
                                  <ol>
                                       <li>effective unless made in writing and signed by the party making the waiver;
                                       </li>
                                       <li>deemed to be a waiver of any subsequent breach of that or any other
                                            provision of this Agreement.</li>
                                  </ol>
                             </li>
                        </ol>
                   </li>
                   <li>
                        <h5>Force Majeure</h5>
                        <ol>
                             <li>Neither party shall be liable for non-performance, error, interruption or delay in the
                                  performance of its obligations, in the operation of or access to the Platform, in the
                                  provision of the Services (or any part thereof) if this is due, in whole or in part,
                                  directly or indirectly to an event or failure which is beyond its reasonable control
                                  which includes without limitation:
                                  <ol>
                                       <li>flood, lightning, acts of God, fire, earthquakes and other natural
                                            disasters;</li>
                                       <li>judgments, legislation, acts, orders, directives, policies, restrictions,
                                            regulations, bye-laws, prohibitions or measures of any kind on the part of
                                            any court, governmental, parliamentary and/or regulatory authority imposed
                                            or to be imposed after the fact;</li>
                                       <li>power failure, acts or defaults of any telecommunications network operator,
                                            circumstances where communications lines for any party’s computer system or
                                            data centre (whether in Singapore or elsewhere) cannot be used for reasons
                                            attributable to third party telecommunications carriers; and/or</li>
                                       <li>acts or omissions of any third party service provider.</li>
                                  </ol>
                             </li>
                        </ol>
                   </li>
                   <li>
                        <h5>Severability</h5>
                        If, at any time, any provision or any part of a provision of this Agreement becomes illegal,
                        invalid or unenforceable, the legality, validity and enforceability of the remaining provisions
                        and the remaining part of that provision of this Agreement shall not be affected.
                   </li>
                   <li>
                        <h5>Rights of Third Parties</h5>
                        A person or entity who is not a party to this Agreement shall have no right under the Contracts
                        (Rights of Third Parties) Act (Cap. 53B) to enforce any of these Terms of Use, regardless of
                        whether such person or entity has been identified by name, as a member of a class or as
                        answering a particular description.
                   </li>
                   <li>
                        <h5>No Assignment</h5>
                        The rights and duties created by this Agreement are personal to you. Accordingly, unless
                        otherwise permitted under this Agreement, this Agreement or any part or obligation or right
                        thereof shall not be sold, conveyed, transferred, assigned, sub-licensed, delegated,
                        sub-franchised or given as security without the prior written consent of Oceans.ai, which may
                        be withheld at its sole discretion, or granted subject to conditions. Any such attempt to sell,
                        convey, transfer, assign, sub-license, delegate, sub-franchise, or give as security shall be a
                        material breach of this Agreement by you which is incapable of remedy.
                   </li>
                   <li>
                        <h5>Governing Law &amp; Jurisdiction</h5>
                        <ol>
                             <li>This Agreement shall be governed by and construed in accordance with Singapore law.
                             </li>
                             <li>The parties hereby submit to the exclusive jurisdiction of the Singapore courts.</li>
                        </ol>
                   </li>
              </ol>
         </li>
    </ol>
</section>