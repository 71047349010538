import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend.service';
import { IGroup } from 'src/app/models/group.interface';
@Component({
  selector: 'app-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent implements OnInit {
  onDestroy$ = new Subject();
  keyProjectGroups: IGroup[];
  valueProjectGroups: IGroup[];

  selectedGroup;
  constructor(
    public dialogRef: MatDialogRef<GroupFilterComponent>,
    public dialog: MatDialog,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getKeyProjectGroups(data.keyProject.id)
    this.getValueProjectGroups(data.valueProject.id)
  }

  getKeyProjectGroups(projectId) {
    this.backendService.getProjectImageGroups(projectId)
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(groups => {
        this.keyProjectGroups = groups;
      });
  }

  getValueProjectGroups(projectId) {
    this.backendService.getProjectImageGroups(projectId)
      .pipe(
        takeUntil(this.onDestroy$)
      )
      .subscribe(groups => {
        this.valueProjectGroups = groups;
      });
  }


  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
  }


  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  selectedGroups() {
    if (this.keyProjectGroups?.filter(o => o?.checked).length > 0 &&
      this.valueProjectGroups?.filter(o => o?.checked).length > 0) {
      return true;
    }
    return false;
  }

  filter() {
    this.dialogRef.close({
      keyProjectGroups: this.keyProjectGroups.filter(o => o.checked),
      valueProjectGroups: this.valueProjectGroups.filter(o => o.checked)
    })
  }


}
