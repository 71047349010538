import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface IActionHandlerParams {
  mode: ActionHandlerMode;
  oobCode: string;
  continueUrl: string;
  lang: string;
}

enum ActionHandlerMode {
  resetPassword = 'resetPassword',
  recoverEmail = 'recoverEmail',
  verifyEmail = 'verifyEmail',
}

@Component({
  selector: 'app-action-handler',
  templateUrl: './action-handler.component.html',
  styleUrls: ['./action-handler.component.scss']
})
export class ActionHandlerComponent implements OnInit {

  public params: IActionHandlerParams;
  public modeTypes = ActionHandlerMode;

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.params = this.route.snapshot.queryParams as IActionHandlerParams;
  }

}
