import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'groupFilter' })
export class GroupFilterPipe implements PipeTransform {
  transform(allData: any[], filter: string) {
    if (allData === null) {
      return [];
    }
    return allData.filter(group => group && group.name && group.name.toLowerCase()?.indexOf(filter?.toLowerCase() || '') > -1);
  }
}