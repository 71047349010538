import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
} from '@angular/core';

import { MatBottomSheet } from '@angular/material/bottom-sheet';
import Konva from 'konva';
import { filter, prop } from 'ramda';
import {
  of,
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest,
  Subject,
  from,
} from 'rxjs';
import { first, mergeMap, map, startWith, catchError, tap, switchMap, takeUntil, take, concatMap, debounceTime, distinctUntilChanged, last, debounce, takeLast, skip, share, retry } from 'rxjs/operators';
import { UiService } from './../services/ui.service';
import { BackendService } from '../services/backend.service';
import { KonvaComponent } from '../konva/konva.module';
import { Images, KonvaConfig, TaggedArea } from '../models/tagging.interface';
import { TagSelectorComponent } from '../tag-selector/tag-selector.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TagNoteAddDialogComponent } from '../tag-note-add-dialog/tag-note-add-dialog.component';

import {
  DRAWING_STRATEGIES,
  EMPTY_SELECTION,
  MouseEventType,
  DrawingMode,
  getPolygonRect,
  getScaledPolygon,
  inverseScaleXY,
  scaledLabelOffset,
  createBoxOnXY,
  createRectBox
} from './tagging.helpers';
import { KonvaPointerEvent } from 'konva/types/PointerEvents';
import { Project } from '../projects/item/project.type';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProjectAddFolderComponent } from '../project/add-folder/add-folder.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IImage } from '../models/image.interface';
import { GeneralService } from '../services/general.service';
import { NarrationBottomSheetComponent } from './narration-bottom-sheet/narration-bottom-sheet.component';
import { HistoryBottomSheetComponent } from '../history-bottom-sheet/history-bottom-sheet.component';
import { ToastrService } from 'ngx-toastr';
import { SeverityLevelComponent } from './severity-level/severity-level.component';

function getRelativePointerPosition(node: Konva.Node): Konva.Vector2d {
  const transform = node.getAbsoluteTransform().copy();

  transform.invert();

  const pos = node.getStage().getPointerPosition();

  return transform.point(pos);
}

function resolvePointToFallInsideImage(point, stage: Konva.Stage) {
  stage.find('Image').each((img) => {
    point.x = Math.min(point.x, img.width());
    point.x = Math.max(point.x, img.x());

    point.y = Math.min(point.y, img.height());
    point.y = Math.max(point.y, img.y());
  });
}

const fontSize = 16;
const fontColor = 'white';
const color = '#EB5757';
const colorPalette = {
  COLORMAP_JET: '#FFF',
  COLORMAP_RAINBOW: '#FFF',
  COLORMAP_HSV: '#FFF',
  COLORMAP_HOT: '#FFF',
  COLORMAP_INFERNO: '#FFF',

};

@Component({
  selector: 'app-tagging',
  templateUrl: './tagging.component.html',
  styleUrls: ['./tagging.component.scss']
})
export class

  TaggingComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('stage') stage: KonvaComponent;
  @ViewChild('container') container: ElementRef;
  get taggingMode(): DrawingMode {
    this.drawingMode = this.uiService.taggingMode;
    return this.uiService.taggingMode;
  }
  set taggingMode(value: DrawingMode) {
    this.uiService.taggingMode = value;
    this.drawingMode = value;
    this.resetAreaSelection();
  }

  drawingChangeEventHandler() {
    this.resetAreaSelection();
    this.removeActiveAreaTag();
  }

  handleZoomIn(visibility) {
    this.resizeWindow(visibility);
    this.activate(this.activeImgIdx);
  }

  handleSliderChange(type) {
    this.isVerticalScroll = type;
    setTimeout(() => {
      this.activate(this.activeImgIdx);
      this.cdr.detectChanges();
    }, 50);
  }

  resizeWindow(visibility) {
    const containerEl: any = document.getElementsByClassName('zoom-item');
    const dashContainer: any = document.getElementsByClassName('dashboard-container');
    if (dashContainer) {
      dashContainer[0].style = visibility ? "padding-top:0px" : "padding-top:55px"
    }
    for (var i = 0; i < containerEl.length; i++) {
      containerEl[i].hidden = visibility;
    }
  }

  public tags: string[] = [];
  public sensitiveTags: any = [];
  public tagsTextConfigs: any[] = [];
  public thermalTemperatureTextConfigs: any[] = [];
  public colors = {};
  public containerSize: any;
  public sliderBlurTimeoutId: any;
  public images: Images;
  public images$: Observable<Images>;
  public collapsePanel$: Subscription;
  public imagesSubscription: Subscription;
  public imageAnnotationsSub: Subscription;
  public folderImagesSubscription$ = new Subject();
  public configStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 750,
    height: 450,
  });

  public configImg$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    width: 0,
    height: 0,
    image: null,
  });

  public selectionConfig$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    ...EMPTY_SELECTION,
  });
  public pointSelectionConfig$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    ...EMPTY_SELECTION,
  });
  public selectionShadowConfig$: BehaviorSubject<
    KonvaConfig
  > = new BehaviorSubject({ ...EMPTY_SELECTION });
  public selectionLineConfig$: BehaviorSubject<
    KonvaConfig
  > = new BehaviorSubject({ ...EMPTY_SELECTION });

  public taggedAreas: Map<string, TaggedArea[]> = new Map();
  public isDrawing = false;
  public narrationModifiedBy: string;
  public activeImgIdx: number;
  public tagsListHeight = 0;
  public tagsListWidth = 0;

  public get activeImg(): { doc: any; image?: HTMLImageElement } {
    return this.images.value[this.activeImgIdx];
  }

  public activeImgAreaIdx: number;

  public get activeImgAreas(): TaggedArea[] {
    return this.taggedAreas.get(this.imageId) || [];
  }

  public get activeImageArea(): TaggedArea {
    return this.activeImgAreas[this.activeImgAreaIdx];
  }

  get addContextEnabled$(): BehaviorSubject<boolean> {
    return this.uiService.addContextEnabled$;
  }

  get addThermalContextEnabled$(): BehaviorSubject<boolean> {
    return this.uiService.thermalEnabled$;
  }

  private drawingMode: DrawingMode;

  public memberSubscription = new Subscription();
  public folderSubscription = new Subscription();
  public groupsSubscription = new Subscription();
  //public getBaseImagesSubscription = new Subscription();
  public selectedTag = 'All';
  public folders = [];
  public groups = [];
  public members = [];
  public roles = [];
  public checkedImages = [];
  public filteredImages = [];
  public project: Project;
  public allNodesExpanded = false;
  public projects$: Observable<Project[]>;
  public groups$: Observable<any[]>;
  // public activeFolderPath: string;
  public timerId: any;
  public folderContents$: Observable<any[]>;
  public contextMenuPosition = { x: '0px', y: '0px' };

  // for childContextImages
  relations = [];
  relationsWithBaseImagesCount$: BehaviorSubject<number> = new BehaviorSubject(0);
  private isSetChildImages = true;
  childContextImages = new Map<string, IImage>();
  // showedChildContextImages = [];
  // baseImages: IImage[] = [];
  // isBaseImagesLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  @ViewChild('tree') tree: any;
  nodes = [];
  getLevel = prop('level');
  imageId: any;
  public imageAnnotationsTaggedItem = [];
  public imageAnnotations;
  public isSetContainerSize: boolean = true;
  public isVerticalScroll: boolean = false;
  public isSidePanelEnable: boolean = true;

  /*get activeFolder(): any {
    return this.uiService.activeFolder;
  }
  set activeFolder(value) {
    this.uiService.activeFolder = value;
    this.onChangeFolder$.next(value);
    this.uiService.activeFolderChange.next(value);
  }
*/

  get projectId(): any {
    return this.uiService.selectedProjectId;
  }

  set projectId(value) {
    this.uiService.selectedProjectId = value;
  }

  public isColorImage: boolean = false;

  private onDestroy$ = new Subject();
  public fileName = "";
  public isAnnotationVerified: boolean = false;
  public isAIDetection: boolean = false;
  public isAnnotationChanged = false;
  constructor(
    public backend: BackendService,
    public uiService: UiService,
    private readonly cdr: ChangeDetectorRef,
    private readonly tagSheet: MatBottomSheet,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private elRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private generalService: GeneralService,
    private renderer: Renderer2,
    private _bottomSheet: MatBottomSheet,
    private toast: ToastrService
  ) {
    this.activatedRoute.params.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      this.imageId = params.imageId;
      this.backend.getImageRelationForChild$(this.projectId, `images/${this.imageId}`).pipe(
        first(),
        takeUntil(this.onDestroy$)
      ).subscribe(relations => {
        this.relationsWithBaseImagesCount$.next(relations.length);
      });
      this.getRelations();

    });
    this.projects$ = this.uiService.projects$;
    this.uiService.addContextEnabled$.pipe(takeUntil(this.onDestroy$)).subscribe((enabled) => {
      if (!enabled) {
        this.resetAreaSelection();
      }
    });

    this.uiService.thermalEnabled$.pipe(takeUntil(this.onDestroy$)).subscribe((enabled) => {
      if (!enabled && this.uiService.project?.projectType === 'thermal') {
        this.resetAreaSelection();
      }
    });

    this.uiService.colorImageEnabled$.pipe(takeUntil(this.onDestroy$)).subscribe((isColorImage) => {
      if (this.uiService.project?.projectType === 'thermal') {
        this.isColorImage = isColorImage;
        if (this.activeImgIdx != null) {
          this.images.value[this.activeImgIdx].image = null;
          this.images.value[this.activeImgIdx].originalImage = null;
          this.activate(this.activeImgIdx)
        }
      }
    });

  }


  ngOnInit(): void {
    this.uiService.isAssetPanelExpand = false;
    this.collapsePanel$ = this.uiService.collapsePanel$.subscribe((isPanelShow: boolean) => {
      this.isSidePanelEnable = isPanelShow;
      if (this.activeImgIdx != -1) {
        setTimeout(() => {
          this.activate(this.activeImgIdx);
        }, 100);
      }
    })
    this.images$ = of({
      loading: true
    })

    this.images$ = this.uiService.activeFolderImages.pipe(
      takeUntil(this.onDestroy$),
      switchMap(() => {
        return this.backend.getImage$(this.uiService.folderParams?.imageId || this.imageId);
      }),
      switchMap((image: any) => {
        if (!this.uiService.allImagesSelected && (!this.uiService.activeFolder ||
          (!this.uiService.folderChangeWithOldImageRoute && image.folderPath != this.uiService.activeFolder.data?.path))) {
          this.uiService.folderChange.next({
            imageId: image.id,
            folderPath: image.folderPath
          });
          return of()
        }
        this.projectId = this.uiService.selectedProjectId || image.projectId;
        this.groups$ = this.backend.getProjectImageGroups(this.projectId);
        this.groupsSubscription = this.groups$.subscribe((groups) => {
          this.groups = groups;
        });
        if (!this.tags.length) {
          this.getTags(this.projectId);
        }
        this.images$ = of({
          loading: false
        })
        const imagesP = this.uiService.images.map((doc: any) => {
          return new Promise((resolve) => {

            resolve({ doc })
          });
        });
        return Promise.all(imagesP);
      }),
      map((value: any) => (
        {
          loading: value.type === 'start',
          value: value.type ? value.value : value,
        })),
      tap((images) => {
        this.images = images;
        this.cdr.detectChanges();
        this.images$ = of(images);
      }),
      startWith({ loading: true }),
      catchError((error) => of({ loading: false, error })
      )
    )

    this.cdr.detectChanges();
    // tslint:disable-next-line: deprecation
    if (this.imagesSubscription) {
      this.imagesSubscription.unsubscribe()
    }
    this.imagesSubscription = combineLatest(
      this.images$,
      this.route.params,
    ).pipe().subscribe(([images, params]) => {
     
      this.uiService.folderChangeWithOldImageRoute = false;
      this.images = images;
      this.cdr.detectChanges();
      if (images && images.value && images.value.length > 0 ) {
          this.filteredImages = this.images.value.map(o => o.doc);
          this.filterTag = 'All';
     
        const timeout = setTimeout(() => {
          const activeImageIdx = this.images.value.findIndex(image => image.doc.id === this.imageId)
          const containerEl = this.elRef.nativeElement.querySelector('.scroller');
          const imagesEl = this.elRef.nativeElement.querySelectorAll('.carousel__item');
          if (imagesEl[activeImageIdx]) {
            if (!this.isVerticalScroll) {
              containerEl.scrollTop = imagesEl[activeImageIdx].offsetTop - (74 * 3)
            } else {
              containerEl.scrollLeft = imagesEl[activeImageIdx].offsetLeft - 400;

            }
          }
          this.activeImgIdx = this.images.value.findIndex(
            (image) => image.doc.id === (this.uiService.folderParams?.imageId || params.imageId)
          );
          this.uiService.folderParams = null;
          if (this.activeImgIdx > -1) {
            this.activate(this.activeImgIdx);
          } else {
            this.activate(0);
          }

          clearTimeout(timeout);
        }, 50)
      }
     
    });

    this.uiService.updateContextsEvent$.pipe(takeUntil(this.onDestroy$)).subscribe((newRelation: any) => {
      this.relations.push(newRelation);
      const childImageId = newRelation.childImagePath.replace('images/', '');
      this.backend.getImage$(childImageId).pipe(first()).subscribe((image: IImage) => {
        this.childContextImages.set(newRelation.annotationId, { ...image, id: childImageId, isShowed: false, isClicked: false });
      });

    });
  }
  isSetConfigStage = true;
  isFirstScroll = true;

  ngAfterViewInit() {
    if (window) {
      this.renderer.listen(window, 'resize', () => {
        this.isSetConfigStage = true;
      });
    }

  }


  ngAfterViewChecked(): void {
    if (this.isSetConfigStage && this.container) {
      this.isSetConfigStage = false;

      this.setContainerSize();
      if (this.activeImgIdx !== undefined) {
        this.activate(this.activeImgIdx);
      }
    }

    if (this.container && this.isSetContainerSize) {
      this.isSetContainerSize = false;

      const { offsetWidth, offsetHeight } = this.container.nativeElement;

      this.containerSize = {
        width: offsetWidth - 294,
        height: offsetHeight - 50,
      };
    }

    if (this.isSetChildImages && this.images && this.images.value && this.activeImgAreas.length !== 0 && this.relations.length !== 0) {
      this.isSetChildImages = false;

      this.activeImgAreas.filter(area => area.annotation && area.annotation.hasContext).forEach(area => {
        return this.getChildImageForContext(area);
      })
    }
  }


  ngOnDestroy(): void {
    this.resizeWindow(false);
    this.groupsSubscription.unsubscribe();
    this.imagesSubscription.unsubscribe();
    this.folderSubscription.unsubscribe();
    // this.getBaseImagesSubscription.unsubscribe();
    this.collapsePanel$.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.folderImagesSubscription$.next();
    this.folderImagesSubscription$.complete();
    if (this.imageAnnotationsSub) {
      this.imageAnnotationsSub.unsubscribe();
    }
    if (this.isAnnotationChanged) {
      this.backend.updateSummary(this.projectId).subscribe();
    }
   
  }

  setContainerSize(): void {
    if (this.container) {
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth - 294,
        height: offsetHeight - (!this.isVerticalScroll ? 150 : 220),
      };
      this.configStage$.next(this.containerSize);
      this.configImg$.next(this.containerSize);
    }
  }

  getTagSensitive(area) {
    return this.sensitiveTags.map(tag => tag.tag).includes(area.tag);
  }

  activeSensitiveTags() {
    return this.activeImgAreas.filter(area => area.annotation && this.getTagSensitive(area))
  }

  getTags(projectId: string): void {
    this.backend.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      let activeTags = tags.filter(
        (x) => x.status !== 'de-active'
      );

      activeTags.forEach((activeTag, i) => {
        if (!this.colors[activeTag.tag]) {
          this.colors[activeTag.tag] = activeTag.color || this.backend.CONSTANTS.tags_colors[
            i % this.backend.CONSTANTS.tags_colors.length
          ];
        }
      });



      const height = 20;
      const padding = 3;

      this.tags = activeTags.map((tag) => tag.tag);
      this.sensitiveTags = activeTags.reduce((acc, tag) => tag.sensitivity ? [...acc, tag] : acc, []);

      this.tagsListHeight = this.tags.length * (height + padding + 3);
      this.tagsListWidth = Math.max(...this.tags.map(tag => tag.length)) * ((fontSize - 2) / 2) + padding;

      if (this.uiService.project.projectType === 'thermal') {

        this.thermalTemperatureTextConfigs = [{
          label$: new BehaviorSubject({
            y: height,
            ...inverseScaleXY(this.stage ? this.stage.getScale() : 1)
          }),

          text$: new BehaviorSubject({
            fontSize: fontSize - 2,
            fontFamily: 'Montserrat',
            text: "✓",
            fill: 'white',
            padding,
            wrap: 'none',
            lineHeight: 1,
            ellipsis: true,
          }),

          tag$: new BehaviorSubject({
            fill: '#222831',
            ...inverseScaleXY(this.stage ? this.stage.getScale() : 1)
          }),
        }]


      }
      this.tagsTextConfigs = activeTags.map((tag, idx) => {
        const space = 3;

        return {
          label$: new BehaviorSubject({
            y: idx * (height + space),
            ...inverseScaleXY(this.stage ? this.stage.getScale() : 1)
          }),

          text$: new BehaviorSubject({
            fontSize: fontSize - 2,
            fontFamily: 'Montserrat',
            fontStyle: 'bold',
            text: tag.tag,
            fill: 'white',
            padding,
            wrap: 'none',
            lineHeight: 1,
            ellipsis: true,
          }),

          tag$: new BehaviorSubject({
            fill: '#222831',
            ...inverseScaleXY(this.stage ? this.stage.getScale() : 1)
          }),
        };
      });
    });
  }

  getPalette() {
    return colorPalette[this.uiService.project.colorPalette] || '335AFF';
  }

  activateImage(idxDelta: number): void {
    const activeImageIdx = this.filteredImages.findIndex(image => image.id === this.imageId);
    const image = this.filteredImages[activeImageIdx + +idxDelta];
    if (image) {
      const idx = this.images.value.findIndex(o => o.doc.id == image.doc.id);
      this.activate(idx);
    }
  }

  carausalActivateImage(imageId): void {
    const index = this.images.value.findIndex(o => o.doc.id == imageId);
    this.activate(index);
  }

  getImageScale(idx: number, imageEl?: any): { widthScale: number; heightScale: number } {
    if (!this.images || !this.images.value || !this.images.value[idx] || !this.images.value[idx].image) return;
    const image = this.images.value[idx].image;
    const { width, height } = image;
    const { width: stageWidth, height: stageHeight } = this.configStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;

    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }

  tempImageId: string;
  isImageSessionLoading: boolean = true;
  activate(idx: number): void {
    if (idx < 0 || !this.images.value[idx]) return;

    const { image, originalImage, doc } = this.images.value[idx];
    this.imageId = doc.id;

    if (this.uiService.project?.projectType === 'thermal' && this.imageId != this.tempImageId) {
      this.isImageSessionLoading = false;
      this.backend.addImageToCache(this.imageId)
        .pipe(retry(2))
        .subscribe((response) => {
          this.isImageSessionLoading = true;
        }, error => {
          this.isImageSessionLoading = false;
        })
    }

    this.tempImageId = this.imageId;
    this.setContainerSize();
    if (image && originalImage) {
      const { height, width } = image;
      const { widthScale, heightScale } = this.getImageScale(idx);
      const newWidth = width * widthScale;
      const newHeight = height * heightScale;
      this.getImageAnnotations(this.images.value[idx]);
      this.stage.reset();
      this.resetAreaSelection();
      this.activeImgIdx = idx;

      const newSize = {
        width: newWidth,
        height: newHeight,
      };
      this.configImg$.next({
        ...newSize,
        image: image,
        mediumImage: image,
        originalImage: originalImage
      });
      this.configStage$.next(newSize);
    }
    /*else if (image && !originalImage) {
      let newSize = {};
        this.images.value[idx].image = image;
        const { height, width } = image;
        const { widthScale, heightScale } = this.getImageScale(idx);
        const newWidth = width * widthScale;
        const newHeight = height * heightScale;
        this.getImageAnnotations(doc);
        this.stage.reset();
        this.resetAreaSelection();
        this.activeImgIdx = idx;
        newSize = {
          width: newWidth,
          height: newHeight,
        };
        this.configImg$.next({
          ...newSize,
          image: image,
          mediumImage: image
        });
        this.configStage$.next(newSize);
      const imageP = new Image();
      imageP.onload = () => {
        this.images.value[idx].originalImage = imageP
        this.configImg$.next({
          originalImage: imageP
        });
      };
      imageP.src = doc.fileUrl;
    }*/
    else {
      const image = new Image();
      const imageP = new Image();
      let i = 0, newSize = {};
      image.onload = () => {
        this.images.value[idx].image = image;
        const { height, width } = image;
        const { widthScale, heightScale } = this.getImageScale(idx);
        const newWidth = width * widthScale;
        const newHeight = height * heightScale;
        this.getImageAnnotations(this.images.value[idx]);
        this.stage.reset();
        this.resetAreaSelection();
        this.activeImgIdx = idx;
        newSize = {
          width: newWidth,
          height: newHeight,
        };
        i++;
        this.configStage$.next(newSize);
        if (i == 2) {
          this.configImg$.next({
            ...newSize,
            image: image,
            mediumImage: image,
            originalImage: imageP
          });
        }
      };
      image.src = this.isColorImage ? doc.colorMediumFileUrl : doc.mediumFileUrl;

      imageP.onload = () => {
        i++;
        this.images.value[idx].originalImage = imageP
        if (i == 2) {
          this.configImg$.next({
            ...newSize,
            image: image,
            mediumImage: image,
            originalImage: imageP
          });
        }

      };
      imageP.src =
        this.isColorImage ? doc.colorFileUrl : doc.fileUrl;
    }

    this.fileName = doc.fileName;

    // this.scrollImage();
    const containerEl = this.elRef.nativeElement.querySelector('.scroller');
    const imagesEl = this.elRef.nativeElement.querySelectorAll('.carousel__item');
    if (imagesEl[idx]) {
      if (!this.isVerticalScroll) {
        containerEl.scrollTop = imagesEl[idx].offsetTop - (74 * 3)
      } else {
        containerEl.scrollLeft = imagesEl[idx].offsetLeft - 400;

      }
    }



    this.cdr.detectChanges();
  }

  ;
  getImageAnnotations(image) {
    const doc = image.doc;
    if (this.imageAnnotationsSub) {
      this.imageAnnotationsSub.unsubscribe();
    }
    this.imageAnnotationsSub = this.backend
      .getImageAnnotations$<{
        polygons: {
          id: string,
          polygon: KonvaConfig;
          tag: string,
          hasContext: boolean,
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          confidence?: number
        }[];
        thermalPolygons: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        hotspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        coldspots: {
          id: string,
          polygon: KonvaConfig;
          note?: string,
          noteCreatedBy: { displayName: string, email: string }
          lastModifiedBy?: { displayName: string, uid: string }
          minTemperature?: number,
          maxTemperature?: number,
          temperature?: number,
        }[];
        sensitive: number;
        narration: string;
        isGroupNarration?: [],
        groupNarration?: boolean,
        narrationModifiedBy: { displayName: string, uid: string }
        verifiedAnnotations?: boolean
        isAIDetection?: boolean,
        id: string,
        levels: [],

      }>(doc.id)
      .subscribe((annotations) => {
        this.imageAnnotations = annotations;
        const updateImageProperties = (image, filter) => {
          if (filter) {
            image.tags = filter.polygons || [];
            image.thermalPolygons = filter.thermalPolygons || [];
            image.coldspots = filter.coldspots || [];
            image.hotspots = filter.hotspots || [];
            image.verifiedAnnotations = filter.polygons ? filter.verifiedAnnotations : false;
            image.isAIDetection = filter.polygons ? filter.isAIDetection : false;
          }
        };
        const image = this.uiService.projectImageContexts.find(o => o.id === this.imageId);
        if (image) {
          updateImageProperties(image, annotations)
        }
        const projectImage = this.uiService.images.find(o => o.id === this.imageId);
        if (projectImage) {
          updateImageProperties(projectImage, annotations)
        }
        const allImages = this.uiService.projectImages.find(o => o.id === this.imageId);
        if (allImages) {
          updateImageProperties(allImages, annotations)
        }
        this.narrationModifiedBy = annotations && annotations.narrationModifiedBy ? annotations.narrationModifiedBy.displayName : '';
        this.isAnnotationVerified = annotations && annotations.verifiedAnnotations ? true : false;
        this.isAIDetection = annotations && annotations.isAIDetection ? true : false;
        const route: any = this.activatedRoute.params
        if (route._value.imageId != doc.id) {
          this.router.navigateByUrl(`/dashboard/projects/${this.projectId}/images/${doc.id}`);
        }

        const annotationData =
          annotations && annotations.polygons
            ? annotations.polygons.map((currentArea) => {
              const { tag, hasContext } = currentArea;
              let taggingItem = {
                ...this.updateTaggingItemConfig(currentArea.polygon, tag, currentArea.id, hasContext, currentArea['sensitive']),
              };

              taggingItem = {
                ...taggingItem,
                annotation: {
                  ...taggingItem.annotation,
                  tag: tag,
                  type: 'polygons',
                  polygon: {
                    ...currentArea.polygon,
                    sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
                  },
                  hasContext: currentArea.hasContext,
                  note: currentArea.note,
                  noteCreatedBy: currentArea.noteCreatedBy,
                  lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
                  confidence: currentArea.confidence,
                  levels: this.sensitiveTags.find(o => o.tag === tag)?.levels || []
                }
              };
              return taggingItem;
            })
            : [];

        let data = [];
        if (annotations && annotations.thermalPolygons) {
          data = annotations.thermalPolygons.map(obj => ({ ...obj, type: 'thermalPolygons' }))
        }
        if (annotations && annotations.coldspots) {
          data = data.concat(annotations.coldspots.map(obj => ({ ...obj, type: 'coldspots' })))
        }
        if (annotations && annotations.hotspots) {
          data = data.concat(annotations.hotspots.map(obj => ({ ...obj, type: 'hotspots' })))
        }

        const thermalData = data.map((currentArea: any) => {
          let temperatureItem = {
            maxTemperature: currentArea.maxTemperature,
            minTemperature: currentArea.minTemperature,
            temperature: currentArea.temperature
          }

          let taggingItem = {
            ...this.updateTaggingItemConfig(
              currentArea.polygon, "thermalPolygon", currentArea.id, false, currentArea.sensitive, currentArea.note
            ),
          };
          taggingItem = {
            ...taggingItem,
            annotation: {
              ...taggingItem.annotation,
              ...temperatureItem,
              tag: "",
              type: currentArea.type,
              polygon: {
                ...currentArea.polygon,
                sensitive: currentArea['sensitive'] ? currentArea['sensitive'].toString() : null
              },
              note: currentArea.note,
              noteCreatedBy: currentArea.noteCreatedBy,
              lastModifiedBy: currentArea.lastModifiedBy ? currentArea.lastModifiedBy : null,
              levels: []
            }
          };

          return taggingItem;
        })
        this.imageAnnotationsTaggedItem = annotationData.concat(thermalData);
        this.taggedAreas.set(doc.id, annotationData.concat(thermalData));
        this.cdr.detectChanges();

      });

  }

  handleMouseEvent(methodType: MouseEventType, event: MouseEvent): void {
    try {
      // avoid breaking when event is a WheelEvent
      if (event['evt'].shiftKey) return;
    } catch (error) {
      // do nothing
    }

    if (!this.drawingMode) return;
    const strategyHandler = DRAWING_STRATEGIES[this.drawingMode][methodType];

    if (!strategyHandler) {
      return;
    }
    if (!this.isImageSessionLoading) {
      return;
    }
    const point = getRelativePointerPosition(this.stage.getStage());
    resolvePointToFallInsideImage(point, this.stage.getStage());

    let config = { ...this.selectionConfig$.getValue() };
    const stateUpdate = strategyHandler(config, point, this.isDrawing, this.stage);

    if (!stateUpdate) {
      return;
    }

    const {
      isDrawing,
      selectionConfig,
      selectionLineConfig,
      selectionShadowConfig,
      tagArea,
      errorShow,
    } = stateUpdate;
    this.isDrawing = isDrawing;

    if (selectionConfig) {
      this.selectionConfig$.next(selectionConfig);
    }

    if (selectionLineConfig) {
      this.selectionLineConfig$.next(selectionLineConfig);
    }

    if (selectionShadowConfig) {
      this.selectionShadowConfig$.next(selectionShadowConfig);
    }

    if (tagArea) {
      this.addNewAreaToActiveImg(tagArea);
    }
    if (errorShow) {
      this.toast.error('Kindly draw a bigger box. The minimum box size should be 25px x 25px')
    }
  }

  handleZoom(event: WheelEvent): void {
    if (!this.configImg$.getValue().image) {
      return;
    }
    let image = this.images.value.find(o => o.doc.id == this.imageId).doc;
    if (this.stage.getStage().scale().x >= 2.5) {
      if (image.fileUrl != this.configImg$.getValue().image.src) {
        this.configImg$.next({
          ...this.configImg$.getValue(),
          image: this.configImg$.getValue().originalImage
        });
        this.taggedAreas.set(this.imageId, this.imageAnnotationsTaggedItem)
        this.cdr.detectChanges();
      }
    } else if (this.stage.getStage().scale().x < 2.5) {
      if (image.mediumFileUrl != this.configImg$.getValue().image.src) {

        this.configImg$.next({
          ...this.configImg$.getValue(),
          image: this.configImg$.getValue().mediumImage
        });
        this.cdr.detectChanges();
        this.taggedAreas.set(this.imageId, this.imageAnnotationsTaggedItem)
      }
    }
    if (this.taggedAreas.get(this.imageId)) {
      this.updateAllAreaTags();
    }

  }

  handleMouseDown(event: MouseEvent): void {
    if (!this.getReadOnlyForCurrentUser() && this.getImageScale(this.activeImgIdx)) {
      this.handleMouseEvent('mouseDown', event);
    }
  }

  handleMouseMove(event: MouseEvent): void {
    this.handleMouseEvent('mouseMove', event);
  }

  private snackBarRef;
  handleMouseUp(event: MouseEvent): void {
    if (!this.getReadOnlyForCurrentUser()) {

      this.handleMouseEvent('mouseUp', event);

      if (!this.addThermalContextEnabled$.value && this.tags.length === 0 && this.activeImgAreas.length !== 0 && !this.snackBarRef) {
        this.snackBarRef = this.snackBar.open('Add tag to project', 'Add tag', {
          panelClass: 'snack-bar'
        });

        this.snackBarRef.onAction().subscribe(() => {
          this.router.navigateByUrl('/dashboard/tags');
        });
      }
    }
  }

  handleClick(event: MouseEvent): void {
    if (!this.getReadOnlyForCurrentUser()) {
      if (event.target instanceof Konva.Image) {
        this.handleMouseEvent('click', event);
      }
    }
  }

  handleDoubleClick(event: MouseEvent): void {
    this.handleMouseEvent('doubleClick', event);
  }

  getTagsGroupXY(
    area: KonvaConfig
  ): { tagsGroupX: number; tagsGroupY: number, tagTextY: number } {
    const {
      width: stageWidth,
      height: stageHeight,
    } = this.configStage$.getValue();
    const { x, y, width, height, originY } = getPolygonRect(area.points);

    const transform = this.stage.getStage().getTransform().copy();
    const { x: x1, y: y1 } = transform.point({ x, y }),
      { x: x2, y: y2 } = transform.point({ x: x + width, y: y + height });

    const tagsGroupRight = x + width + 1.5 / this.stage.getScale();
    const tagsGroupLeft = x - (this.tagsListWidth + 1.5) / this.stage.getScale();
    const tagsGroupTop = y;
    const tagsGroupBottom = y + height - this.tagsListHeight / this.stage.getScale();

    const tagTextTop = y - scaledLabelOffset(this.stage.getScale());
    const tagTextBottom = y + height + 1.5 / this.stage.getScale();

    const tagsGroupX =
      stageWidth < x2 + this.tagsListWidth
        ? tagsGroupLeft
        : tagsGroupRight;

    const tagsGroupY =
      stageHeight < y1 + this.tagsListHeight
        ? tagsGroupBottom
        : tagsGroupTop;

    const tagTextY =
      y1 - scaledLabelOffset(1) > 0
        ? tagTextTop
        : tagTextBottom

    return {
      tagsGroupX: tagsGroupX < 0 ? 5 : tagsGroupX,
      tagsGroupY,
      tagTextY
    };
  }

  initTaggingItemConfig(area: KonvaConfig): TaggedArea {
    const { tagsGroupX, tagsGroupY, tagTextY } = this.getTagsGroupXY(area);
    const { originX, originY, x, y, width, height } = getPolygonRect(area.points);
    return {
      config$: new BehaviorSubject({
        ...area,
        stroke: color,
        closed: this.drawingMode == "line" || area.closed == false ? false : true,
        listening: false,
        fill: this.uiService.isMask ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)',
      }),

      textConfig$: new BehaviorSubject({
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        fontSize,
        x,
        y: tagTextY,
        fill: fontColor,
        text: '',
        listening: false,
        ...inverseScaleXY(this.stage.getScale())
      }),
      tag$: new BehaviorSubject({
        fill: '#222831',
        listening: false,
        ...inverseScaleXY(this.stage.getScale())
      }),
      label$: new BehaviorSubject({
        y: tagTextY,
        x,
        listening: false
      }),
      tagsGroupConfig$: new BehaviorSubject({
        x: tagsGroupX,
        y: tagsGroupY,
        ...inverseScaleXY(this.stage.getScale())
      }),
      removeConfig$: new BehaviorSubject({
        x: originX - 16 / this.stage.getScale(),
        y: originY - 8 / this.stage.getScale(),
        ...inverseScaleXY(this.stage.getScale()),
        opacity: 0.5
      }),
      removeConfigText$: new BehaviorSubject({
        text: 'x',
        fill: 'white',
        fontSize: 13,
        fontStyle: 'bold',
        width: 15,
        height: 15,
        lineHeight: 1.2,
        align: 'center',
      }),
      removeConfigRect$: new BehaviorSubject({
        fill: '#333',
        width: 15,
        height: 15,
        cornerRadius: 50,
      }),
      tag: '',
      temperatureGroup: {}
    }
  }

  updateTaggingItemConfig(area: KonvaConfig, tag: string, id?: string, hasContext?: boolean, sensitive?: string, note?: string, type?: string, lastModifiedBy?: string, confidence?: number, levels?: [], minTemperature?: number,
    maxTemperature?: number, temperature?: number): TaggedArea {
    if (!this.images || !this.images.value || !this.images.value[this.activeImgIdx] || !this.images.value[this.activeImgIdx].image) return;
    const { widthScale, heightScale } = this.getImageScale(this.activeImgIdx);
    const polygon = {
      points: id ? getScaledPolygon(area.points, {
        x: widthScale,
        y: heightScale,
      }) : area.points,
    };
    const { originX, originY, x, y, width, height } = getPolygonRect(polygon.points);
    const { tagsGroupX, tagsGroupY, tagTextY } = this.getTagsGroupXY(polygon);
    const annotations = id ? { tag, annotation: { id, ...area, sensitive, hasContext, note, type, lastModifiedBy, confidence, levels, minTemperature, maxTemperature, temperature } } : {};
    return {
      ...annotations,
      config$: area['closedType'] == "point" ?
        new BehaviorSubject({
          ...polygon,
          radius: 3,
          x: polygon.points[0],
          y: polygon.points[1],
          stroke: !note ? '#FFF' : '#4456F9',
          fill: this.getPalette(),
          strokeWidth: 2,

        })
        : new BehaviorSubject({
          ...polygon,
          stroke:
            tag == 'thermalPolygon' ?
              this.getPalette()
              : tag && tag !== 'context' ? this.colors[tag] || this.colors[tag.toLowerCase()] : (tag === 'context' ? '#335AFF' : color)
          ,
          fill: this.uiService.isMask ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)',
          strokWidth: 3,
          strokeScaleEnabled: false,
          closed: area['closedType'] == "line" ? false : true,
          dash: tag === 'context' ? [2, 2] : null
        }),
      textConfig$: new BehaviorSubject({
        fontSize: fontSize - 2,
        fontFamily: 'Montserrat',
        fontStyle: 'bold',
        x: originX,
        y: tagTextY,
        fill: '#fff',
        text: tag,
        padding: 5,
        wrap: 'none',
        lineHeight: 1,
        ellipsis: true,
        ...inverseScaleXY(this.stage.getScale()),
        listening: false,
        visible: (tag !== 'thermalPolygon')
      }),
      tag$: new BehaviorSubject({
        fill: '#222831',
        ...inverseScaleXY(this.stage.getScale()),
        listening: false,
        visible: (tag !== 'context' && tag !== 'thermalPolygon')
      }),
      label$: new BehaviorSubject({
        y: originY < tagTextY ? 5 : tagTextY,
        x: area['closedType'] == "rectangle" ? x : originX,
        listening: false,
        visible: (tag !== 'context')
      }),
      tagsGroupConfig$: new BehaviorSubject({
        x: tagsGroupX,
        y: tagsGroupY,
        ...inverseScaleXY(this.stage.getScale()),
        visible: !!!id
      }),
      removeConfig$: new BehaviorSubject({
        x: originX - 16 / this.stage.getScale(),
        y: originY - 8 / this.stage.getScale(),
        ...inverseScaleXY(this.stage.getScale()),
        opacity: 0.5
      }),
      removeConfigText$: new BehaviorSubject({
        text: 'x',
        fill: 'red',
        fontSize: 15,
        fontStyle: 'bold',
        width: 20,
        height: 20,
        lineHeight: 1.2,
        align: 'center',
      }),
      removeConfigRect$: new BehaviorSubject({
        width: 20,
        height: 20,
        cornerRadius: 50,
        fill: '#dcd5d5',
      }),
      closedType: area['closedType']
    };
  }

  maskChange() {
    this.activeImgAreas.forEach(area => {
      area.config$.getValue().fill =
        this.uiService.isMask ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)';
    })
    if (this.taggedAreas.get(this.imageId)) {
      this.taggedAreas.set(this.imageId, this.activeImgAreas);
      this.updateAllAreaTags();
      this.backend.updateProjectMasking(this.projectId, this.uiService.isMask).then(() => { });
    }
  }

  isLoading: boolean = false;
  addNewAreaToActiveImg(area: KonvaConfig): void {
    const { widthScale, heightScale } = this.getImageScale(this.activeImgIdx);

    const points = [area.points[0], area.points[1]];
    if (this.drawingMode === 'point') {
      area.points = createRectBox(area.points);
    }
    const taggingItem = this.initTaggingItemConfig(area);
    if (this.addThermalContextEnabled$.value) {
      // get temeprature
      if (this.drawingMode === 'point') {
        taggingItem['temperatureGroup']['x'] = points[0];
        taggingItem['temperatureGroup']['y'] = points[1];
        taggingItem['pointConfig$'] = new BehaviorSubject({
          radius: 2,
          x: points[0],
          y: points[1],
          stroke: '#FFF',
          closed: true,
          listening: false,
          fill: color,
          strokeWidth: 1,
        })
      }
      taggingItem.temperatureGroup['loading'] = true;
      this.isLoading = true;
      this.backend.getTemperature(this.imageId,
        this.drawingMode === 'point' ?
          (getScaledPolygon([points[0], points[1]], {
            x: 1 / widthScale,
            y: 1 / heightScale,
          }))
          :
          (getScaledPolygon(area.points, {
            x: 1 / widthScale,
            y: 1 / heightScale,
          }))
        , this.drawingMode).subscribe((response: any) => {
          this.isLoading = false;
          this.area = taggingItem;
          taggingItem.temperatureGroup = {
            ...taggingItem.temperatureGroup,
            loading: false,
            minTemperature: response.min_temperature,
            maxTemperature: response.max_temperature,
            temperature: response.temperature
          };
        }, error => {
          this.isLoading = true;
          taggingItem.temperatureGroup = {
            ...taggingItem.temperatureGroup,
            loading: false,
          }
          this.toast.error(error.message);
        })

    }


    const areasWithTagsOnly = filter(prop('tag'), this.activeImgAreas);

    this.taggedAreas.set(this.imageId, [
      ...areasWithTagsOnly,
      taggingItem,
    ]);

    this.activeImgAreaIdx = this.activeImgAreas.length;
    if (this.addContextEnabled$.value) {
      setTimeout(() => {
        this.uiService.addContextEvent$.next({
          ...area,
          points: getScaledPolygon(area.points, {
            x: 1 / widthScale,
            y: 1 / heightScale,
          }),
          closed: (this.drawingMode == "line" || area.closed == false) ? false : true,
        });
      }, 150);

      this.setAreaTag(this.activeImgAreaIdx, 'context');
    }
    //  this.updateAllAreaTags();
    this.cdr.detectChanges();

  }

  updateAllAreaTags(): void {
    this.taggedAreas.set(
      this.imageId,
      this.taggedAreas.get(this.imageId)
        .map(area => {
          if (this.addContextEnabled$.value && area.annotation?.tag === 'context') {
            this.uiService.addContextArea = area;
          }
          if (area.annotation) {
            const polygon = area.annotation.polygon ? area.annotation.polygon : { points: area.annotation.points, closedType: area.annotation.closedType };
            return this.updateTaggingItemConfig(
              polygon,
              area.tag,
              area.annotation.id,
              area.annotation.hasContext,
              area.annotation.sensitive,
              area.annotation.note,
              area.annotation.type,
              area.annotation?.lastModifiedBy,
              area.annotation?.confidence,
              area.annotation?.levels,
              area.annotation?.minTemperature,
              area.annotation?.maxTemperature,
              area.annotation?.temperature

            );
          } else {
            const taggingItem = this.updateTaggingItemConfig(area.config$.getValue(), '');
            return {
              ...taggingItem,
              temperatureGroup: area.temperatureGroup,
            }
          }
        })
    );

    this.cdr.detectChanges();
  }

  handleTagSelect($event: KonvaPointerEvent, idx: number, config, area): void {
    $event.cancelBubble = true;

    const { text } = config.text$.getValue();
    const sensitivity = this.getTagSensitive({ tag: text }) ? 0 : undefined;
    this.setAreaTag(idx, text, sensitivity);
  }

  handleRemoveArea($event: KonvaPointerEvent, idx: number): void {
    if (this.getReadOnlyForCurrentUser()) { return }
    $event.cancelBubble = true;
    if (!this.activeImgAreas[idx].annotation) {
      this.removeActiveAreaTag();
      return;
    }
    const annotationId = this.activeImgAreas[idx].annotation.id;
    if (this.activeImgAreas[idx].tag != "context") {
      this.backend.addHistory([this.imageId], this.projectId, `tag ${this.activeImgAreas[idx].annotation.tag} removed`, "tag", annotationId)
    } else {
      this.backend.addHistory([this.imageId], this.projectId, "context removed", "context", annotationId)
    }
    if (this.activeImgAreas[idx].annotation.type === 'polygons') {
      this.backend
        .removeAnnotation(
          annotationId,
          this.imageId,
        ).pipe(
          switchMap(() => {
            return this.backend.removeImageRelationByAnnotationId(
              this.projectId,
              annotationId
            );
          })
        )
        .subscribe();
      this.isAnnotationChanged = true;
    }
    else {
      this.backend
        .removeThermalAnnotation(
          annotationId,
          this.imageId,
          this.activeImgAreas[idx].annotation.type
        ).pipe().subscribe();
    }

    this.taggedAreas.set(
      this.imageId,
      this.activeImgAreas.filter((_, i) => i !== idx)
    );

    this.cdr.detectChanges();
  }

  handleRemoveButtonFocusIn($event: KonvaPointerEvent, idx: number, area): void {
    $event.currentTarget.opacity(1);
    if (area.annotation) {
      area.annotation.noteImg = 'assets/note-letter-green.png';
    }
    $event.currentTarget.getLayer().batchDraw();
  }

  handleRemoveButtonFocusOut($event: KonvaPointerEvent, idx: number, area): void {
    $event.currentTarget.opacity(0.5);
    if (area.annotation) {
      area.annotation.noteImg = null;
    }
    $event.currentTarget.getLayer().batchDraw();
  }

  setAreaTag(idx: number, tag: string, sensitive?: number, id?: string): void {
    let area = this.activeImgAreas[idx];

    if (!area) { area = this.activeImgAreas[idx - 1]; }

    let { points } = area.config$.getValue();
    const { widthScale, heightScale } = this.getImageScale(this.activeImgIdx);
    area.textConfig$.next({
      ...area.textConfig$.getValue(),
      text: tag,
    });
    this.backend.addHistory([this.imageId], this.projectId, `tag ${tag} added`, "tag", {
      points: getScaledPolygon(points, {
        x: 1 / widthScale,
        y: 1 / heightScale,
      }),
      closedType: this.drawingMode,
    })

    if (this.addThermalContextEnabled$.value) {
      if (this.drawingMode === 'point') {
        points = [area.temperatureGroup?.x, area.temperatureGroup?.y];
      }
      this.backend.createTemperatureAnnotation(this.imageId, this.projectId,
        {
          points: getScaledPolygon(points, {
            x: 1 / widthScale,
            y: 1 / heightScale,
          }),
          closedType: this.drawingMode,
        },
        id,
        area.temperatureGroup?.minTemperature || 0, area.temperatureGroup?.maxTemperature || 0,
        area.temperatureGroup?.temperature || 0
      ).subscribe(() => {
        this.area = null;
        this.isAnnotationChanged = true;
        this.updateAllAreaTags()
      });
    } else {
      this.backend.createAnnotation(this.imageId, this.projectId,
        {
          points: getScaledPolygon(points, {
            x: 1 / widthScale,
            y: 1 / heightScale,
          }),
          closedType: this.drawingMode,
        },
        tag,
        this.addContextEnabled$.value,
        sensitive,
        id,
      ).subscribe(() => {
        this.isAnnotationChanged = true;
        this.updateAllAreaTags()
      });
    }

  }

  removeActiveAreaTag(): void {
    this.resetAreaSelection();

    this.taggedAreas.set(
      this.imageId,
      this.activeImgAreas.filter(
        ({ textConfig$ }) => textConfig$.getValue().text !== ''
      )
    );
    this.area = null;
    this.cdr.detectChanges();
  }

  openBottomSheet(config): Promise<any> {
    const ref = this.tagSheet.open(TagSelectorComponent, config);
    const refClose$ = ref.afterDismissed();

    return refClose$.pipe(first()).toPromise();
  }

  filterTag: string = 'All';
  tagFilter(data) {
    if (data.type == 'features') {
      if (data.sensitiveTag) {
        this.filterTag = `Feature: ${data.selectedTag} (${this.formatLabel(data.selectedTag, parseInt(data.sensitiveTag))} Severity)`
      } else {
        this.filterTag = `Feature: ${data.selectedTag}`
      }
      const images = this.uiService.allImagesSelected ? this.uiService.projectImageContexts : this.uiService.images;
      this.selectedTag = data.selectedTag;
      if (this.selectedTag === 'All') {
        this.filteredImages = images;
      } else if (this.selectedTag === 'Tagged') {
        this.filteredImages = images.filter((image: any) => image.tags && image.tags.length)
      } else if (this.selectedTag === 'Untagged') {
        this.filteredImages = images.filter((image: any) => !image.tags || !image.tags.length)
      }
      else if (this.selectedTag === 'Hotspots') {
        this.filteredImages = images.filter((image) => (image.hotspots && image.hotspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === true).length > 0)

        )
      }
      else if (this.selectedTag === 'Coldspots') {
        this.filteredImages = images.filter((image) => (image.coldspots && image.coldspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === false).length > 0)
        )
      }
      else {
        if (data.sensitiveTag) {
          this.filteredImages = images.filter((image: any) =>
            image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === data.selectedTag.toLowerCase()
              && data.sensitiveTag === tempTag.sensitive)
          );
        } else {
          this.filteredImages = images.filter((image: any) =>
            image.tags && image.tags.find((tempTag) => tempTag.tag.toLowerCase() === this.selectedTag.toLowerCase())
          );

        }
      }

    } else {
      this.filterTag = `Group: ${data.selectedGroup.groupName}`
      const images = this.uiService.allImagesSelected ? this.uiService.projectImageContexts : this.uiService.images;
      this.filteredImages =
        images.filter(image => data.selectedGroup.images?.includes(image.id))
    }
    const index = this.filteredImages.findIndex(o => o.id == this.imageId);
    if (index != -1) {
      this.activeImgIdx = index;
    } else {
      if (this.filteredImages.length > 0) {
        const firstImageIndex = this.images.value.findIndex(o => o.doc.id == this.filteredImages[0].id);
        this.activate(firstImageIndex);
      } else {
        this.fileName = '';
        this.configImg$.next({
          image: null,
          mediumImage: null
        });
        this.imageAnnotationsTaggedItem = [];
        this.taggedAreas.set(this.imageId, []);
      }
    }

  }

  formatLabel(tag: string, level: number): string {
    const selectTag = this.sensitiveTags.find(o => o.tag === tag);
    if (selectTag) {
      return selectTag.levels.find(o => o.level == level).title;
    }
  }

  openSeverityLevels($event: MouseEvent, i: number): void {
    $event.stopPropagation();
    const tag = this.sensitiveTags.find(o=>o.tag === this.activeImgAreas[i].annotation.tag);
    const dialogRef = this.dialog.open(SeverityLevelComponent, {
      data: {
        imageId: this.imageId,
        id: this.activeImgAreas[i].annotation.id,
        tag: this.activeImgAreas[i].annotation.tag,
        description:tag?.description,
        chart:tag?.chart,
        levels: this.activeImgAreas[i].annotation.levels
      },
    });
    dialogRef.afterClosed().subscribe(r => {
      if (r && r.update) {
        this.isAnnotationChanged = true;
      }
    });
  }

  openNotes($event: MouseEvent, i: number): void {
    this.dialog.open(TagNoteAddDialogComponent, {
      data: {
        imageId: this.imageId,
        id: this.activeImgAreas[i].annotation.id,
        tag: this.activeImgAreas[i].annotation.tag,
        polygon: this.activeImgAreas[i].annotation.polygon || {
          points: this.activeImgAreas[i].annotation.points,
          sensitive: this.activeImgAreas[i].annotation.sensitive
        },
        note: this.activeImgAreas[i].annotation.note,
        lastEditedUser: this.activeImgAreas[i].annotation.noteCreatedBy,
        projectId: this.projectId,
        type: this.activeImgAreas[i].annotation.type,
        temperature: this.activeImgAreas[i].annotation.temperature,
        minTemperature: this.activeImgAreas[i].annotation.minTemperature,
        maxTemperature: this.activeImgAreas[i].annotation.maxTemperature,
        closedType: this.activeImgAreas[i].annotation.closedType
      },
    });
  }

  registerSliderChange($event, area, idx: number): void {
    const sensitive = $event.value;
    this.setAreaTag(idx, area.tag, sensitive, area.annotation.id);
    if (this.sliderBlurTimeoutId) {
      clearTimeout(this.sliderBlurTimeoutId);
    }
    this.sliderBlurTimeoutId = setTimeout(() => {
      Array.from(document.getElementsByClassName('mat-slider')).forEach(
        (slider: any) => {
          slider.blur();
        }
      );
    }, 1000);
  }

  getSliderClass(area) {
    if (area?.annotation?.sensitive === '2') {
      return 'high';
    } else if (area?.annotation?.sensitive === '1') {
      return 'medium';
    }
    else {
      return 'low';
    }
  }
  /*
    getSliderStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
      if (!this.stage) { return null; }
   
      const { height } = getPolygonRect(area.config$.getValue().points);
      const scale = this.stage.getScale();
      return {
        minHeight: `${Math.max((height) * scale) + (scale * 3)}px`,
      };
    }
  */
  getAreaTagPanelStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }

    const { tagsGroupX, tagsGroupY } = this.getTagsGroupXY(area.config$.getValue());
    const { x: left, y: top, height } = getPolygonRect(area.config$.getValue().points);
    const scale = this.stage.getScale();

    const transform = this.stage.getStage().getTransform().copy();
    let { x, y } = transform.point({ x: tagsGroupX, y: tagsGroupY });

    if (tagsGroupX < left) x += this.tagsListWidth - 20;

    if (tagsGroupY < top) y += this.tagsListHeight - Math.max((height - 5) * scale, 50);

    return {
      top: `${y}px`,
      left: `${x}px`,
    };
  }

  getChildImageStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!area && area.tag != 'context') { return }
    const points = getPolygonRect(area.config$.getValue().points);
    return {
      top: `${points.y}px`,
      left: `${points.x - 75}px`,
    };
  }

  getContext() {
    return this.activeImgAreas && this.activeImgAreas.filter(o => o.tag === 'context');
  }

  getAreaSeverityIconStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }
    const points = area.config$.getValue().points;
    const { x, y, height, width } = getPolygonRect(points);
    const positionOffset = this.stage.getStage().position();
    const scale = this.stage.getScale();

    if (area.annotation && area.annotation.closedType) {
      if (area.annotation.closedType != "rectangle") {
        return {
          top: `${positionOffset.y - 10 + (points[points.length - 1] * scale)}px`,
          left: `${positionOffset.x + (10) + (points[points.length - 2] * scale)}px`,
        };
      } else {
        return {
          top: `${positionOffset.y + (y + height - 15 * (1 / scale)) * scale}px`,
          left: `${positionOffset.x + (x + width - 15 * (1 / scale)) * scale}px`,
        };
      }
    }

  }

  getMidpointOfPolygon(polygonPoints: number[]) {
    const xValues = polygonPoints.filter((_, i) => i % 2 === 0);
    const yValues = polygonPoints.filter((_, i) => i % 2 === 1);

    // Calculate the average midpoint
    const xSum = xValues.reduce((sum, point) => sum + point, 0);
    const ySum = yValues.reduce((sum, point) => sum + point, 0);

    return {
      x: xSum / xValues.length,
      y: ySum / yValues.length,
    };

  }
  getAreaNoteButtonStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }
    const points = area.config$.getValue().points;
    const { x, y, height } = getPolygonRect(points);
    const positionOffset = this.stage.getStage().position();
    const scale = this.stage.getScale();
    if (area.annotation) {
      if (area.annotation.closedType && area.annotation.closedType != "rectangle") {
        return {
          top: `${positionOffset.y + (points[points.length - 1] * scale)}px`,
          left: `${positionOffset.x + (points[points.length - 2] * scale)}px`,
        };
      } else {
        return {
          top: `${positionOffset.y + (y + height - 15 * (1 / scale)) * scale}px`,
          left: `${positionOffset.x + (x + 5 * (1 / scale)) * scale}px`,
        };
      }

    }

  }

  getAreaTempratureLabelStyle(area: TaggedArea): Partial<CSSStyleDeclaration> {
    if (!this.stage) { return null; }
    const points = area.config$.getValue().points;
    const { x, y, height, width } = getPolygonRect(points);
    const positionOffset = this.stage.getStage().position();
    const scale = this.stage.getScale();

    if (area.annotation && area.annotation.closedType) {
      if (area.annotation.closedType != "rectangle" &&
        area.annotation.closedType != "point") {
        return {
          top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
          left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
        };
      } else {

        if (area.annotation.closedType === 'point') {
          return {
            top: `${positionOffset.y - 15 + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
          };
        } else {
          return {
            top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (x + 5 * (1 / scale)) * scale}px`,
          };
        }
      }
    } else {
      if (area.config$.getValue().closed) {
        if (this.drawingMode != "rectangle" &&
          this.drawingMode != "point") {
          return {
            top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (this.getMidpointOfPolygon(points).x * scale)}px`,
          };
        } else {
          return {
            top: `${positionOffset.y + (this.getMidpointOfPolygon(points).y * scale)}px`,
            left: `${positionOffset.x + (x + 5 * (1 / scale)) * scale}px`,
          };
        }
      }



    }

  }

  private resetAreaSelection(): void {
    this.isDrawing = false;
    this.area = null;
    this.selectionConfig$.next(EMPTY_SELECTION);
    this.selectionLineConfig$.next(EMPTY_SELECTION);
    this.selectionShadowConfig$.next(EMPTY_SELECTION);
  }


  handleProjectChange(e): void {
    this.router.navigate([`/dashboard/projects/${e.value}`]);
  }


  onContextMenu(event: MouseEvent, item: any): void {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { item };
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }



  getFolders(): void {
    if (this.nodes.length) {
      return;
    }

    if (this.timerId) {
      clearInterval(this.timerId);
    }

    this.folderSubscription = this.backend
      .getFolders$(this.projectId)
      .subscribe((folders) => {
        const result = [];
        folders.docs.forEach((doc, i) => {
          const node = {
            name: doc.data().name,
            doc: doc.data(),
            path: doc.ref.path,
            breadcrumb: {
              name: doc.data().name,
            },
            children: [],
          };
          result.push(node);
          this.backend.getFoldersOf(doc.ref, doc.ref.path, result[i]);
        });
        this.nodes = result.sort((a, b) => a.name.localeCompare(b.name));
        // this.timerId = setInterval(() => {
        //   console.log(this.nodes);
        //   this.nodes = [...this.nodes];
        // }, 2000);
      });
  }


  showChildContextImages(area: TaggedArea, enabled: boolean) {
    if (area.tag === 'context') {
      this.childContextImages.get(area.annotation.id).isShowed = enabled;
      this.childContextImages.get(area.annotation.id).isClicked = enabled;
    }
  }

  area;
  hoverChildContextImages(area: TaggedArea, enabled: boolean) {
    if (!area.annotation) { return }
    this.area = area;
    if (area.tag === 'context' && this.childContextImages.get(area.annotation.id)) {
      if (!this.childContextImages.get(area.annotation.id).isClicked) {
        this.childContextImages.get(area.annotation.id).isShowed = enabled;
      }
    }

    if (enabled) {
      area.annotation.isHover = true;
      if (area.tag != 'context' && area.closedType != 'point') {
        area.config$.getValue().fill = 'rgba(255, 255, 255, 0'
        area.config$.next(area.config$.getValue())
      }
      area.removeConfig$.next({
        ...area.removeConfig$.getValue(),
        opacity: 1,
        fill: 'rgb(255,255,255,0)'
      });
      area.annotation.noteImg = 'assets/note-letter-green.png';
    } else {
      area.annotation.isHover = false;
      if (area.tag != 'context' && area.closedType != 'point') {
        area.config$.getValue().fill =
          this.uiService.isMask ? 'rgba(255, 255, 255, 0.3)' : 'rgba(255, 255, 255, 0)'
        area.config$.next(area.config$.getValue())
      }
      area.annotation.noteImg = null;
      area.removeConfig$.next({
        ...area.removeConfig$.getValue(),
        opacity: 0.5,
        fill: 'rgba(255,255,255,0.3)'
      });
    }
  }



  getRelations(): void {
    this.backend.getImageRelation$(this.projectId, `images/${this.imageId}`).pipe(
      take(1),
      tap((relations: any[]) => {
        this.relations = relations;
        this.isSetChildImages = true
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }



  getChildImageForContext(area: TaggedArea): void {
    const relation = this.relations.filter(relation => relation.annotationId === area.annotation.id)[0];
    if (relation) {
      const childImageId = relation.childImagePath.replace('images/', '');
      this.backend.getImage$(childImageId).pipe(first()).subscribe((image: IImage) => {
        this.childContextImages.set(area.annotation.id, { ...image, id: childImageId, isShowed: false, isClicked: false });
      });
    }
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser() || this.generalService.AIOverrideProcess();
  }

  openNarrationBottomSheet() {
    if (!this.projectId) { return; }
    const bottomSheetRef = this.dialog.open(NarrationBottomSheetComponent, {
      data: {
        imageAnnotations: this.imageAnnotations,
        imageId: this.imageId,
        projectId: this.projectId,
        imageName: this.fileName,

      },
      width: '50%',
    });
  }

  openHistoryBottomSheet() {
    this._bottomSheet.open(HistoryBottomSheetComponent, {
      data: {
        imageId: this.imageId,
        projectId: this.projectId
      }
    });
  }

  getName() {
    if ((this.imageAnnotations && this.imageAnnotations.narration) || (this.imageAnnotations && this.imageAnnotations.isGroupNarration)) {
      return 'Edit narration'
    }
    return 'Add narration'
  }

  verifyAnnotation() {
    return this.backend.updateVerifiedImageAnnotation(this.imageId).subscribe(() => { });
  }


  getBGColor(area) {
    if (area.annotation.sensitive == 0) {
      return '#d4d4d4';
    } else {
      return area.annotation.levels.find(o => o.level == area.annotation.sensitive)?.color;
    }
  }
  getTitle(area) {
    if (!area.annotation.sensitive) {
      return 'not marked';
    } else {
      const level = area.annotation.levels.find(o => o.level == area.annotation.sensitive);
      if (level) {
        return `level ${level.level},   ${level?.title}`;
      }
    }
  }

  getSelectedImageIndex() {
    if (this.imageId) {
      return this.filteredImages.findIndex(o => o.id == this.imageId) + 1;
    }
    return 0;
  }

  contextImageClick(context) {
    if (context.folderPath != this.uiService.activeFolder?.data?.path) {
      this.uiService.folderChange.next({
        imageId: context.id,
        folderPath: context.folderPath
      });
    } else {
      this.router.navigateByUrl(`/dashboard/projects/${this.projectId}/images/${context.id}`);

    }
  }

  getBgColor(annotation, isBackground) {
    const minTemp = annotation.minTemperature;
    const maxTemp = annotation.maxTemperature;
    const projectMinTemp = this.uiService.project.minTemperature;
    const projectMaxTemp = this.uiService.project.maxTemperature;
    const stdDev = this.uiService.project.std_dev;

    if (projectMinTemp && minTemp && maxTemp &&
      projectMaxTemp && stdDev) {
      if (maxTemp >= (projectMaxTemp + stdDev)) {
        return isBackground ? '#FFF' : 'red'; //hotspots
      }
      if (minTemp <= (projectMinTemp - stdDev)) {
        return isBackground ? '#FFF' : 'blue';  //coldspots
      }
    } else {
      if (annotation.temperature && projectMinTemp &&
        projectMaxTemp && stdDev) {
        if (annotation.temperature >= (projectMaxTemp + stdDev)) {
          return isBackground ? '#FFF' : 'red'; //hotspots
        }
        if (annotation.temperature <= (projectMinTemp - stdDev)) {
          return isBackground ? '#FFF' : 'blue';  //coldspots
        }
      }


    }
    return isBackground ? '#2f1818' : 'fff';  //normal
  }

}