<dts-select-container class="project-items w-100 p-2" #containerGrid="dts-select-container"
  (select)="someMethod($event)"
   [(selectedItems)]="selectedImages"
 [disabled]="getReadOnlyForCurrentUser()" [style.minHeight]="'300px'">
  <div class="infinite-scroll-container w-100" (scroll)="containerGrid.update()" (scrolled)="containerGrid.update()"  infiniteScroll [infiniteScrollDistance]="4"
    [infiniteScrollThrottle]="10" [scrollWindow]="false" style="height: calc(100vh - 150px); overflow-y:scroll">
    <div class="w-100 p-2">
      <ng-container *ngFor="let image of images">
        <div class="project-item" [style.border]="isChecked(image.id) ? '2px solid #2196f3' : ''" [dtsSelectItem]="image">
          <div class="project project-item__contents" >
            <div  style="width: 100%; height: 100%;">
              <mat-checkbox [disabled]="true" [checked]="isChecked(image.id)"
              [color]="'primary'" style="position: absolute; right: 5px; top: 5px" 
              >
            </mat-checkbox>
              <img [src]="image.thumbFileUrl" alt="Image" class="img-placeholder"
                style="width: 100%; height: 100%; object-fit: cover"
                (click)="switchTab.emit()" />
            </div>
          </div>
            <div class="project-item__name">{{ image.fileName }}</div>
          </div>
      </ng-container>
    </div>
  </div>
</dts-select-container>
