
  <div class="popup-header w-100 d-flex">
    <p class="w-100">
     New Folder
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>

  <div mat-dialog-content class="pb-5">
    <mat-form-field class="w-100 mb-2" appearance="fill">
      <mat-label>Folder name</mat-label>
      <input matInput cdkFocusInitial [(ngModel)]="name" placeholder="Folder name"/>
    </mat-form-field>
    <div class="input-error" *ngIf="!isNameValid(name)">Folder already exists</div>
  </div>

  <div mat-dialog-actions>
    <mat-spinner-button
      [options]="createBtnOptions"
      (btnClick)="handleCreate(name)"
      [disabled]="!name || !isNameValid(name)"
    ></mat-spinner-button>
    <button mat-button (click)="close()">Cancel</button>

  </div>
