export enum Status {
  SUCCESS = 'success',
  QUEUED = 'queued',
  PROCESSING = 'processing',
  FAILED = 'failed',
  BASELINE = "baseline",
  CANCELLED = 'cancelled'
}
export enum TilesMessage {
  PROCESS = `The 3D model is being processed. You will recieve an email once the processing is completed.`,
  PROCESS_SYNC = `The 3D model for this project is being processed. You may sync them after it’s completed`,
  FAILED = `the 3D Model for this project failed to get generated/uploaded. Please contact info@oceansai.tech with the project name`,
  LOADING = `Your model is uploading. please wait and do not close this window.`,
  LARGE_FILE = `The size of model is larger. It will take few mins to load into viewer.`,
  GENERATE_MODEL_SYNC = `Please generate/upload the 3D model for this project before syncing.`,
  GENERATE_MODEL_VIEW = `Please generate/upload the 3D model for this project before viewing.`,
  GENERATE_BASE_MODEL = `There is no baseline model in Asset info for this project to sync with. Please upload/generate a 3D model and label it.`,
  BASE_MODEL_PROCESS = `The 3D model for this base line is being processed. You may sync them after it’s completed`,
  BASE_MODEL_PROCESS_VIEW = `The 3D model for this base line is being processed. You may view after it’s completed`,
  UPLOAD_FAILED = `The 3D model is failed to get processed for this project, please contact to info@oceansai.tech with your project details.`,
  NO_BASELINE_MODEL = 'The 3D model generated for this project is disabled. Please upload the baseLine from Asset Info tab.',
  MODEL_SYNC_2D = 'Model cannot be synced. The baseline model in Asset info for this asset is 2D',
  NO_REQUIRE_BASELINE_SYNC = 'This project data is linked to the baseline model. It need not be synced. The model can be viewed in the viewer',
  BASELINE_FAILED = 'The 3D Model for this project it the baseline failed to get generated/uploaded. Please contact info@oceansai.tech with the project name(s)',
  ALIGNMENT_PROCESS = 'Alignment is in progress.'
}

export enum ThermalMessage {
  PROCESS = `The color paletee conversion is in progress.`,
  FAILED = `The color paletee conversion is failed .`
  
}
export enum GENERAL {
  access = `You don't have editor access.`,
}


export enum DXF {
  No_PDT = `No panels found for this selected area`,
  PDT_WRONG_MARKED = `Selected area are not inside the PDT`,
  JB_WRONG_MARKED = `Selected area are not inside the JB`,
  TR_WRONG_MARKED = `Selected area are not inside the Trackers`,
  STR_WRONG_MARKED = `Selected area are not inside the String`,
  FAILED = `the 3D Model for this project failed to get generated/uploaded. Please contact info@oceansai.tech with the project name`,
  LOADING = `Your model is uploading. please wait and do not close this window.`,
  }

  export enum AssetType {
    SOLAR = 'solar',
    OTHER = 'other'
  }

  export enum InspectionType {
    FORSSEA = 'forssea-robotics',
    OTHER = 'other'
  }