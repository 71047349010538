<div [hidden]="!uiService.isAssetPanelExpand">
  <app-asset-sidebar *ngIf="backendService.currentUserData" [collapseMenu]="'hide'" [bgColor]="'#fafbfc'"
    class="side-menu zoom-item">
  </app-asset-sidebar>
</div>

<div class="grid-contaner full h-100 project">
  <div class="grid-x h-100">

    <div *ngIf="!uiService.isAssetPanelExpand" (click)="togglePanel()"
      [style.left]="isPanelShow || uiService.isAssetPanelExpand ? '250px' : '0px'" class="collapse-menu zoom-item">
      <mat-icon>{{isPanelShow ? 'arrow_left' : 'arrow_right'}}</mat-icon>
    </div>
    <div class="cell" style="height: calc(100vh - 60px);"
      [ngStyle]="{ width: isPanelShow || uiService.isAssetPanelExpand  ?'250px' : '0px' }">
      <div class="h-100 project-sidebar scroller" (click)="selectProjectRoot($event)">
        <!-- <mat-form-field
          *ngIf="projects$ | async as projects"
          appearance="outline"
          class="mb-35"
        >
         <mat-select
            [(value)]="projectId"
            (selectionChange)="handleProjectChange($event)"
            (click)="$event.stopImmediatePropagation()"
          >
            <mat-option *ngFor="let project of projects" [value]="project.id"
                        (click)="$event.stopImmediatePropagation()">
              {{ project.name }}
            </mat-option>
          </mat-select> 
        </mat-form-field> -->

        <mat-tab-group [hidden]="!isPanelShow" class="mat-two-tabs" [(selectedIndex)]="selectedIndex"
          (selectedTabChange)="selectTab($event)" (click)="cancelClickEvent($event);">
          <mat-tab label="Folders">
            <ng-container [ngTemplateOutlet]="foldersOuter"></ng-container>
          </mat-tab>
          <mat-tab label="Locations">
            <ng-container [ngTemplateOutlet]="labelsOuter"></ng-container>
          </mat-tab>
          <!-- <mat-tab label="Groups">
            <ng-container [ngTemplateOutlet]="groupsOuter"></ng-container>
          </mat-tab> -->

        </mat-tab-group>

        <ng-template #foldersOuter>
          <div class="d-flex justify-content-between">
            <button mat-button color="primary" name="button" style="padding: 0" class="btn-create-new"
              (click)="handleAddFolder(); $event.stopImmediatePropagation()" [disabled]="getReadOnlyForCurrentUser()">
              <mat-icon fontSet="material-icons-outlined" class="mr-2">
                create_new_folder
              </mat-icon>
              <span>New folder</span>
            </button>
            <!--  <button mat-icon-button color="primary">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>-->
          </div>
          <div [style.opacity]="uiService.projectInfo.loading? 0.5 : 1"
            [class.node-active]="uiService.allImagesSelected" *ngIf="nodes.length" (click)="showAllImages()"
            class="all-image-text node-content-wrapper w-100 wrap-text">
            All images
          </div>
          <div class="scroller zoom-item" style="height: calc(100vh - 175px);">
            <tree-root #treeComponent [treeAllowDrop]="true" [focused]="true" [nodes]="nodes" [options]="treeOptions"
              [state]="state">
              <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                <div class="node-wrapper" [style.padding-left]="node.getNodePadding()"
                  [class.node-active]="node.isActive" [style.background]="checkTopFolderSeverity(node)"
                  (click)="handleClick(node); $event.stopImmediatePropagation();">
                  <tree-node-expander [node]="node"></tree-node-expander>
                  <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
                    [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu"
                    *ngIf="!getReadOnlyForCurrentUser()">
                  </div>
                  <div matTooltip="{{node.data.name}}" class="node-content-wrapper w-100 wrap-text"
                    (click)="node.mouseAction('click', $event);"
                    (contextmenu)="onContextMenu($event, node, 'folder', nodes)" (treeDrop)="node.onDrop($event)"
                    [treeAllowDrop]="node.allowDrop" [treeDrag]="node" [treeDragEnabled]="node.allowDrag()">
                    <tree-node-content [node]="node" [index]="index">
                    </tree-node-content>
                  </div>
                </div>
              </ng-template>
            </tree-root>
          </div>
        </ng-template>



        <!-- Template for labels in left menu -->
        <ng-template #labelsOuter>
          <div class="scroller zoom-item" *ngIf="labels && labels.length" style="height: calc(100vh - 175px);">
            <tree-root [focused]="false" [options]="labelTreeOptions" [nodes]="labels">
              <ng-template #treeNodeWrapperTemplate let-node let-index="index">
                <div class="node-wrapper" (click)="handleLabelClick( $event);"
                  [style.padding-left]="node.getNodePadding()" [style.background]="node.data?.color"
                  [class.label-active]="node.isActive">
                  <tree-node-expander [node]="node"></tree-node-expander>
                  <div [matTooltip]="node.data.title  || node.data.name" class="node-content-wrapper w-100 wrap-text"
                    (click)="node.mouseAction('click', $event)">
                    {{node.data.title || node.data.name}}
                  </div>
                </div>

              </ng-template>
            </tree-root>
          </div>
        </ng-template>

        <!-- <div   (click)="$event.stopImmediatePropagation()" class="d-flex report-group-wrapper" *ngIf="isPanelShow &&  selectedTab === 'folders' && getCurrentUrl().includes('images')">
          <app-report-group  [groups]="groups"></app-report-group>
        </div> -->

      </div>
    </div>
    <mat-menu #contextMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        <button *ngIf="selectedTab === 'folders'" mat-menu-item (click)="handleRenameFolder(item)">Rename</button>
        <button mat-menu-item (click)="deleteItem(item)">Delete</button>
      </ng-template>
    </mat-menu>
    <div class="cell auto h-100">

      <div *ngIf="selectedTab ==='locations'">
        <div class="customTemplate">
          <app-label-grid [models]="labels" [dxfLinkRef]="dxfLinkRef" [selectedLabelNode]="selectedLabelNode"
            [modelId]="modelId" [assetId]="uiService.project.assetId" [project]="uiService.project"
            (selectionChange)="changeLabelSelection()" (mediaUpdates)="mediaUpdates($event)" [labelId]="labelId"
            [media]="linkedMedia" (switchTab)="selectFolderTab()">
          </app-label-grid>

        </div>

      </div>


      <div [hidden]="selectedTab !='folders'">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>