<mat-selection-list
  [multiple]="false"
  (selectionChange)="handleTagSelection($event)"
>
  <mat-list-option
    [selected]="data.selected === tag"
    *ngFor="let tag of data.tags"
    [value]="tag"
  >
    {{ tag }}
  </mat-list-option>
</mat-selection-list>
