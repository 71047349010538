import { Component, EventEmitter, OnDestroy, OnInit, Input, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BackendService } from '../../services/backend.service';
import { UiService } from '../../services/ui.service';
import * as moment from 'moment';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-asset-sidebar',
  templateUrl: './asset-sidebar.component.html',
  styleUrls: ['./asset-sidebar.component.scss']
})
export class AssetSidebarComponent implements OnInit, OnDestroy {
  public projects$: Observable<any[]>;
  public projects = [];
  public assets = [];
  public filteredProjects = [];
  public companyAssets = [];
  public projectSubscription = new Subscription();
  public assetsSubscription = new Subscription();
  public mapSubscription = new Subscription();

  private onDestroy$ = new Subject();
  public projectTitle: string;
  @Output() projectChange = new EventEmitter<any>();
  @Output() loadMap = new EventEmitter();
  @Output() assetsChange = new EventEmitter<any>();
  @Output() companyAssetChange = new EventEmitter<any>();
  @Output() allProjects = new EventEmitter<any>();
  @Output() title = new EventEmitter<string>();
  @Output() menuChange = new EventEmitter();
  @Input() bgColor;
  @Input() collapseMenu;
  @Input() projectPanelHide;
  isMenu: boolean = false;
  constructor(
    public backend: BackendService,
    public dialog: MatDialog,
    public uiService: UiService
  ) {
    this.mapSubscription = this.uiService.mapSelectedEvent$.subscribe((data: any) => {
      this.handleAssetsClick(data.parent, data.child)
    })
    // this.getFiltered()
  }

  ngOnInit(): void {
    this.uiService.defaultView = this.backend.currentUserData.defaultView;
    if (!this.uiService.defaultView) {
      this.uiService.defaultView = 'assets';
      this.uiService.selectedSideTab = this.uiService.selectedSideTab || 'assets';
      //this.uiService.assetSelectedTabIndex = 0;
    }
    if (!this.uiService.selectedSideTab) {
      if (this.uiService.defaultView == 'assets') {
        this.uiService.selectedSideTab = 'assets';
        this.uiService.assetSelectedTabIndex = 0;
      } else if (this.uiService.defaultView == 'campaigns') {
        this.uiService.selectedSideTab = 'campaigns';
        this.uiService.assetSelectedTabIndex = 0;
      } else {
        this.uiService.assetSelectedTabIndex = 1;
        this.uiService.selectedSideTab = 'projects';
      }
    }else{
      if( this.uiService.selectedSideTab === 'projects' && this.uiService.assetSelectedTabIndex == 0){
        this.uiService.assetSelectedTabIndex = 1;
      }
      if( this.uiService.selectedSideTab === 'assets' && this.uiService.assetSelectedTabIndex == 1){
        this.uiService.assetSelectedTabIndex = 0;
      }
    }

    this.refresh();
    this.uiService.changeAssetOrProjectData$.pipe(takeUntil(this.onDestroy$)).subscribe((panel: any) => {
      this.refresh();
    })
  }

  refresh() {
    this.projects = this.uiService.allProjects;
    this.allProjects.emit(this.projects);
    this.assets = this.uiService.allAssets;
    this.companyAssets = this.uiService.allCompanies;
    this.assetsChange.emit(this.assets);
    this.assetTree(this.uiService.allCompanies)
    this.projectTree();
    this.campaignTree();
    setTimeout(() => {
      this.getFiltered();
    }, 50);
  }


  projectTree() {
    //this.uiService.projectNodes = [];
    const assetAllLabelNode = this.uiService.projectNodes.find(o => o.id == 'All');
    if (!assetAllLabelNode) {
      this.uiService.projectNodes.push({
        name: 'All',
        id: 'All',
        displayName: 'All projects'
      })
    }

    this.projects.sort((a, b) => 0 - (new Date(a.createdDate?.toDate()) < new Date(b.createdDate?.toDate()) ? -1 : 1)).forEach((project, i) => {
      if (project.createdDate) {
        const projectDate = project.createdDate.toDate();
        const findYear = this.uiService.projectNodes.find(o => o.name === moment(projectDate).format('YYYY'));
        if (findYear) {
          if (!findYear.children.find(o => o.name === moment(projectDate).format('MMM'))) {
            findYear.children.push({
              date: projectDate,
              children: [],
              displayName: `${moment(projectDate).format('YYYY')}/${moment(projectDate).format('MMM')}`,
              name: moment(projectDate).format('MMM'),
              id: moment(projectDate).format('MMM')
            })
          }

        } else {
          const month = moment(projectDate).format('MMM');
          const projectNode = this.uiService.projectNodes.find(o => o.id === month);
          if (!projectNode) {
            this.uiService.projectNodes.push({
              children: [{
                displayName: `${moment(projectDate).format('YYYY')}/${moment(projectDate).format('MMM')}`,
                date: projectDate,
                name: month,
                id: month
              }],
              date: projectDate,
              displayName: moment(projectDate).format('YYYY'),
              name: moment(projectDate).format('YYYY'),
              id: moment(projectDate).format('YYYY')
            })
          }

        }
      }
      const ownerId = Object.keys(project.people).find(key => project.people[key].role === 'owner');
      if (ownerId) {
        this.backend.getUser(ownerId).pipe(take(1)).subscribe(userDoc => {
          if (userDoc.exists) {
            this.projects[i].owner = userDoc.data().email;
          }
        });
      }
      this.projects[i].asset = this.assets.find(o => o.id == this.projects[i].assetId);
    });
  }

  assetTree(companyAssets) {
    this.uiService.assetsNodes = this.uiService.assetsNodes.filter(node => this.uiService.allCompanies.map(o => o.id).includes(node.id))
    companyAssets.forEach((company) => {
      const children = [];
      let filterAsset = this.assets.filter(o => o.companyId == company.id);
      filterAsset.forEach(asset => {
        children.push({
          displayName: asset.location ? `${company.companyName}/${asset.assetName} (${asset.location})` : `${company.companyName}/${asset.assetName}`,
          name: asset.assetName,
          id: asset.id
        })
      });

      const assetNode = this.uiService.assetsNodes.find(o => o.id == company.id);

      if (!assetNode) {
        this.uiService.assetsNodes.push({
          name: company.companyName,
          id: company.id,
          children: children
        })
      } else {
        assetNode.name = company.companyName;
        assetNode.children = children;
      }

    });
    this.companyAssetChange.emit(this.companyAssets)

  }

  campaignTree() {
    this.uiService.campaigns.forEach((el, ind) => {
      el.assets.forEach((assetId, indx) => {
        let asset = this.uiService.allAssets.find(x => x.id == assetId);
        if (!asset) {
          el.assets.splice(indx, 1)
        }
      })
    })
    this.groupByCompanyView()

  }

  groupByCompanyView() {
    const groupedData = this.uiService.campaigns.reduce((result, obj) => {
      const { companyId, campaignName, assets, id } = obj;
      if (!result[companyId]) {
        result[companyId] = {
          companyId: companyId,
          campaigns: [],
          expand: false
        };
      }
      const companyObj = result[companyId];
      const campaignObj = companyObj.campaigns.find(
        (campaignObj) => campaignObj.campaignName === campaignName
      );

      if (!campaignObj) {
        companyObj.campaigns.push({
          campaignName: campaignName,
          campaignId: id,
          assets: [],
          expand: false
        });
      }
      const updatedCampaignObj = companyObj.campaigns.find(
        (campaignObj) => campaignObj.campaignName === campaignName
      );
      updatedCampaignObj.assets.push(...assets);
      return result;
    }, {});
    this.uiService.groupByCompanyView = groupedData;
  }

  toggleNode(node) {
    if (node.id == 'All') {
      this.handleProjectClick(node);
    } else {
      node.expand = !node.expand;
    }
  }

  toggleAssetNode(node) {
    node.expand = !node.expand;
  }

  handleProjectClick(node, child?) {
    node.expand = true;
    this.uiService.selectedProjectNode =
    {
      parent: node,
      child: child
    };
    if (node.id == 'All') {
      this.projectTitle = node.displayName;
      this.filteredProjects = this.projects;
    }
    else {
      const projectDate = child.date;
      child.isActive = true;
      this.projectTitle = child.displayName;
      this.filteredProjects = this.projects.filter(o => o.createdDate && moment(o.createdDate?.toDate()).format('YYYY')
        === moment(projectDate).format('YYYY') &&
        moment(o.createdDate?.toDate()).format('MM')
        === moment(projectDate).format('MM')
      )
    }
    this.projectChange.emit(this.filteredProjects);
    this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
    //  this.uiService.changeProjectEvent$.next();
    this.title.emit(this.projectTitle);
  }

  handleAssetsClick(node, child) {
    node.expand = true;
    this.uiService.selectedAssetNode =
    {
      parent: node,
      child: child
    };
    this.projectTitle = child.displayName;
    this.filteredProjects = this.projects.filter(o => o.assetId == child.id)
    this.projectChange.emit(this.filteredProjects);
    this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
    // this.uiService.changeProjectEvent$.next();
    this.loadMap.emit();
    this.title.emit(this.projectTitle);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.projectSubscription.unsubscribe();
    this.assetsSubscription.unsubscribe();
    this.mapSubscription.unsubscribe();
  }

  selectTab(event): void {
    const tab: 'assets' | 'projects' | 'campaigns' = event.tab.textLabel.toLowerCase();
    this.uiService.selectedSideTab = tab;
    this.getFiltered();
  }

  cancelClickEvent(event) {
    if (!event.target.className.includes('mat-tab-group') || this.uiService.selectedSideTab === 'groups') {
      event.stopPropagation();
    }
  }

  getFiltered() {
    if (this.uiService.selectedSideTab == 'projects') {
      if (this.uiService.projectNodes.length) {
        if (this.uiService.selectedProjectNode?.parent && this.uiService.selectedProjectNode?.parent.id != 'All') {
          let nodeIndex = this.uiService.projectNodes.findIndex(o => o.id == this.uiService.selectedProjectNode?.parent.id);
          let childIndex = this.uiService.projectNodes[nodeIndex].children.findIndex(o => o.id == this.uiService.selectedProjectNode?.child.id);
          this.handleProjectClick(this.uiService.projectNodes[nodeIndex], this.uiService.projectNodes[nodeIndex].children[childIndex]);
        } else {
          this.handleProjectClick(this.uiService.projectNodes[0]);
        }
      }
    } else if (this.uiService.selectedSideTab == 'campaigns') {
      // Client-Asset 
      if (this.uiService.campaignAssetView) {
        if (this.uiService.campaigns.length) {
          if (this.uiService.selectedCampaignNode?.company) {
            let nodeIndex = this.uiService.campaigns?.findIndex(o => o.id == this.uiService.selectedCampaignNode?.company.id);
            let childIndex;
            if (nodeIndex != -1) {
              childIndex = this.uiService.campaigns[nodeIndex].assets.findIndex(assetId => assetId == this.uiService.selectedCampaignNode?.asset);
            }
            if (nodeIndex != -1 && childIndex != -1)
              this.handleCampaignClick(this.uiService.campaigns[nodeIndex], this.uiService.campaigns[nodeIndex].assets[childIndex]);
            else
              this.handleCampaignClick(this.uiService.campaigns[0], this.uiService.campaigns[0].assets[0]);
          } else {
            if (!this.uiService.selectedProjectId) {
              this.handleCampaignClick(this.uiService.campaigns[0], this.uiService.campaigns[0].assets[0]);
            } else {
              if (this.uiService.project) {
                const selectedCampaign = this.uiService.campaigns.filter(item => {
                  return item.assets.includes(this.uiService.project?.assetId);
                })
                if (selectedCampaign.length) {
                  this.handleCampaignClick(selectedCampaign[0], this.uiService.project?.assetId);
                }
              }
            }
          }
        }
        else {
          this.loadNoProject();
        }
      }
      // Client-Campaign-Asset 
      if (!this.uiService.campaignAssetView) {
        if (this.uiService.campaigns.length) {
          if (this.uiService.selectedCampaignCompany.campaign && this.uiService.selectedCampaignCompany.asset) {
            let company = this.uiService.groupByCompanyView[this.uiService.selectedCampaignCompany.company.companyId]
            company.expand = true;
            let campaign = company.campaigns?.find(o => o.campaignId == this.uiService.selectedCampaignCompany.campaign.campaignId);
            if (campaign) {
              campaign.expand = true
              this.handleCompanyAssetClick(company, campaign, this.uiService.selectedCampaignCompany.asset)
            } else {
              this.loadTopCampaign();
            }
          } else {
            this.loadTopCampaign();
          }
        } else {
          this.loadNoProject();
        }
      }
    } else {
      if (this.uiService.assetsNodes.length) {
        const selectedAsset = this.uiService.assetsNodes.filter(o => o.children.length);
        if (this.uiService.selectedAssetNode?.parent) {
          let nodeIndex = this.uiService.assetsNodes.findIndex(o => o.id == this.uiService.selectedAssetNode.parent.id);
          let childIndex = this.uiService.assetsNodes[nodeIndex]?.children?.findIndex(o => o.id == this.uiService.selectedAssetNode.child.id);
          if (childIndex != -1) {
            this.handleAssetsClick(this.uiService.assetsNodes[nodeIndex], this.uiService.assetsNodes[nodeIndex].children[childIndex]);
          } else {
            if (selectedAsset.length) {
              this.handleAssetsClick(selectedAsset[0], selectedAsset[0].children[0]);
            }
          }
        } else {
          if (!this.uiService.selectedProjectId) {
            //If Project Route clicked set asset and projet node,
            if (selectedAsset.length) {
              this.handleAssetsClick(selectedAsset[0], selectedAsset[0].children[0]);
            }
          } else {
            if (this.uiService.project) {
              const nodeIndex = this.uiService.assetsNodes.findIndex(node => {
                return node.children.some(o => (o.id === this.uiService.project?.assetId))
              })
              if (nodeIndex != -1) {
                const childIndex = this.uiService.assetsNodes[nodeIndex].children.findIndex(o => o.id == this.uiService.project?.assetId);
                this.handleAssetsClick(this.uiService.assetsNodes[nodeIndex], this.uiService.assetsNodes[nodeIndex].children[childIndex]);
              }
            }
          }
        }
      } else {
        this.loadNoProject();
      }
    }
  }

  loadNoProject() {
    if(!this.uiService.selectedProjectId){
      this.filteredProjects = [];
      this.projectTitle = "";
      this.projectChange.emit(this.filteredProjects);
      this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
      this.title.emit(this.projectTitle);
    }
     
  }

  loadTopCampaign() {
    let key = Object.keys(this.uiService.groupByCompanyView)
    let company = this.uiService.groupByCompanyView[key[0]]
    company.expand = true;
    let campaign = this.uiService.groupByCompanyView[key[0]].campaigns[0]
    campaign.expand = true;
    let asset = campaign.assets[0]
    this.handleCompanyAssetClick(company, campaign, asset)
  }

  toggleMenu() {
    this.isMenu = !this.isMenu;
    this.menuChange.emit(!this.isMenu);
  }

  close() {
    this.uiService.isAssetPanelExpand = false;
  }

  getCompanyName(campaign) {
    if (typeof (campaign) == 'string') {
      return this.uiService.allCompanies.find(x => x.id == campaign).companyName
    } else {
      return this.uiService.allCompanies.find(x => x.id == campaign.companyId).companyName
    }
  }

  getAssetName(assetId) {
    return this.uiService.allAssets.find(x => x.id == assetId)?.assetName;
  }

  toggleCampaignNode(campaign) {
    campaign.expand = !campaign.expand
  }

  handleCampaignClick(node, assetId) {
    if (node && assetId) {
      node.expand = true;
      this.uiService.selectedCampaignNode =
      {
        company: node,
        asset: assetId
      };
      this.projectTitle = this.uiService.selectedCampaignNode.company.campaignName + ' / ' + this.getAssetName(assetId);
      this.filteredProjects = this.projects.filter(o => o.assetId == assetId)
      this.projectChange.emit(this.filteredProjects);
      this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
      this.loadMap.emit();
      this.title.emit(this.projectTitle);
    } else {
      this.uiService.selectedCampaignNode =
      {
        company: null,
        asset: null
      };
      this.projectTitle = "";
      this.filteredProjects = [];
      this.projectChange.emit(this.filteredProjects);
      this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
      this.loadMap.emit();
      this.title.emit(this.projectTitle);
    }

  }

  toggleCampaignView() {
    this.uiService.campaignAssetView = !this.uiService.campaignAssetView;
    this.getFiltered()
  }

  eventTarget(event, company, campaign, assetId) {
    this.toggleCampaignNode(campaign);
    //   if (event.target.id === 'icon') {
    //     this.toggleCampaignNode(campaign);
    //   }else{
    // this.handleCompanyAssetClick(company, campaign, assetId)    
    //   }
  }

  handleCompanyAssetClick(company, campaign, assetId?) {
    if (!assetId) {
      this.projectTitle = "";
      this.filteredProjects = [];
      this.projectChange.emit(this.filteredProjects);
      this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
      this.loadMap.emit();
      this.title.emit(this.projectTitle)
      this.uiService.selectedCampaignCompany =
      {
        company: null,
        campaign: null,
        asset: null
      };

      return;
    }
    this.uiService.selectedCampaignCompany =
    {
      company: company,
      campaign: campaign,
      asset: assetId
    };

    if (assetId) {
      this.projectTitle = campaign.campaignName + ' / ' + this.getAssetName(assetId);
      this.filteredProjects = this.projects.filter(o => o.assetId == assetId)
      this.projectChange.emit(this.filteredProjects);
      this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
      this.loadMap.emit();
      this.title.emit(this.projectTitle);
    } else {
      this.projectTitle = campaign.campaignName;
      this.filteredProjects = this.projects.filter(item => campaign.assets.includes(item.assetId));
      this.projectChange.emit(this.filteredProjects);
      this.uiService.updateAssetNodeEvent$.next(this.filteredProjects);
      this.loadMap.emit();
      this.title.emit(this.projectTitle);
    }
  }

}