<div *ngIf="isInitialsListView"
class="h-100 w-100 d-flex flex-column justify-content-around align-items-center">
<div class="add-initials d-flex justify-content-end mb-2 w-100">
    <button mat-raised-button type="submit" color="primary" class="submit-button mt-3 mr-2"
        (click)="addProjectInitials(this)"
        [disabled]="projectInitials && projectInitials.length > 4 || getReadOnlyForCurrentUser()"
        [ngClass]="{ 
            
        }">
        <span class="text-uppercase">Add Initials</span>
    </button>
    <mat-icon class="help mr-2"
        matTooltip="Each project can have up to a maximum of 5 initials across all users. Initials are shared across collaborating members">
        help</mat-icon>
</div>

<div *ngIf="projectInitials && projectInitials.length" class="initials-list w-100 h-100 mt-1">
        <mat-card [class.collection__item_active]="selectedInitialsObjIdx === projectInitials.id"
            *ngFor="let initials of projectInitials; let idx = index;"
            class="collection__item d-flex flex-column justify-content-between">
            <span class="collection__item-title">Initials {{idx + 1}} {{getUserName(initials) }}</span>
            <div class="d-flex  align-items-center">
                <span class="collection__item-text">
                    {{ initials.projectName }}
                    <ng-container
                        *ngIf="initials.projectName && initials.approver">,
                    </ng-container>
                    {{ initials.approver }}
                    <ng-container *ngIf="(initials.approver || initials.projectName) && initials.documentNumber">,</ng-container>
                    {{ initials.documentNumber }}
                </span>
                <div class="action-btn">
                <mat-icon [class.disabled]="getReadOnlyForCurrentUser()"
                    (click)="removeProjectInitials(initials.id)" class="mr-3 ml-auto mat-icon_size-18"
                    style="cursor: pointer" svgIcon="trash">
                </mat-icon>
                <div [class.disabled]="getReadOnlyForCurrentUser()" (click)="openForm(idx)" class="icon"
                    style="cursor: pointer;">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M17.2761 2.85156L15.3776 0.953125C14.4636 0.0390625 12.9518 0.0390625 12.0378 0.953125L0.858094 12.0625L0.0143443 17.3008C-0.0911244 17.8633 0.401063 18.3555 0.963563 18.25L6.20184 17.4062L17.3112 6.22656C18.2253 5.3125 18.2253 3.80078 17.2761 2.85156ZM4.23309 10.8672L10.2097 4.92578L13.3386 8.05469L7.39716 14.0312V12.5547H5.70966V10.8672H4.23309ZM2.89716 16.3867L1.87763 15.3672L2.33466 12.8008L2.96747 12.1328H4.44403V13.8203H6.13153V15.2969L5.46356 15.9297L2.89716 16.3867ZM16.2214 5.13672L14.4987 6.85938L11.405 3.76562L13.1276 2.04297C13.444 1.72656 13.9714 1.72656 14.2878 2.04297L16.1862 3.94141C16.5378 4.29297 16.5378 4.82031 16.2214 5.13672Z"
                            fill=" #219653" />
                    </svg>
                </div>
            </div></div>
        </mat-card>
</div>
</div>

<div *ngIf="!isInitialsListView" class="form-container w-100 mt-2">

<div class="d-flex align-items-center mb-2" style="height: 50px; cursor: pointer;">
    <div class="back-icon" (click)="isInitialsListView = true;" style="cursor: pointer;">
        <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.625 7.7152H4.3175L9.30875 2.10999C9.54214 1.84745 9.65443 1.50898 9.6209 1.16903C9.58738 0.829086 9.4108 0.515513 9.13 0.297298C8.8492 0.0790819 8.48719 -0.0259025 8.1236 0.00544001C7.76002 0.0367826 7.42464 0.201885 7.19125 0.464426L0.31625 8.17802C0.269996 8.23937 0.228634 8.30383 0.1925 8.37086C0.1925 8.43514 0.1925 8.47371 0.0962502 8.53799C0.0339263 8.68539 0.00129404 8.8423 0 9.0008C0.00129404 9.1593 0.0339263 9.31621 0.0962502 9.46362C0.0962502 9.5279 0.0962498 9.56647 0.1925 9.63075C0.228634 9.69778 0.269996 9.76223 0.31625 9.82359L7.19125 17.5372C7.32053 17.6823 7.48242 17.799 7.66541 17.879C7.8484 17.959 8.048 18.0003 8.25 18C8.57127 18.0006 8.88262 17.896 9.13 17.7043C9.26923 17.5964 9.38432 17.4638 9.46868 17.3143C9.55304 17.1647 9.60501 17.001 9.62161 16.8326C9.63822 16.6643 9.61913 16.4945 9.56544 16.333C9.51176 16.1715 9.42453 16.0215 9.30875 15.8916L4.3175 10.2864H20.625C20.9897 10.2864 21.3394 10.151 21.5973 9.90986C21.8551 9.66876 22 9.34177 22 9.0008C22 8.65984 21.8551 8.33284 21.5973 8.09175C21.3394 7.85065 20.9897 7.7152 20.625 7.7152Z"
                fill="#46B3E6" />
        </svg>
    </div>
</div>

<form class="d-flex flex-column  bg-white w-100 form-border" [formGroup]="initialsForm"
    style="position: relative;">
    <div style="position: absolute; right: 0; top: -60px;">
        <button mat-raised-button type="submit" color="primary"
            class="submit-button update-initials-button" [disabled]="isUpdateInitialsLoading$ | async"
            (click)="updateProjectInitials()">
            <!-- (click)="openEditor()" -->
            <span *ngIf="!(isUpdateInitialsLoading$ | async)" class="text-uppercase">Update</span>
            <span *ngIf="isUpdateInitialsLoading$ | async">
                <mat-spinner [diameter]="20"></mat-spinner>
            </span>
        </button>
    </div>
    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="projectName" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="projectName"
            id="projectName" />
        <label for="projectName" [class.form-field__label_full]="projectName.value"
            class="form-field__label">Project name</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="contractNumber" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="contractNumber"
            id="contractNumber" />
        <label for="contractNumber" [class.form-field__label_full]="contractNumber.value"
            class="form-field__label">Contract number</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="projectNumber" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="projectNumber"
            id="projectNumber" />
        <label for="projectNumber" [class.form-field__label_full]="projectNumber.value"
            class="form-field__label">Project number</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="documentNumber" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="documentNumber"
            id="documentNumber" />
        <label for="documentNumber" [class.form-field__label_full]="documentNumber.value"
            class="form-field__label">Document number</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="author" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="author"
            id="author" />
        <label for="author" [class.form-field__label_full]="author.value"
            class="form-field__label">Author</label>
    </div>

    <!-- <div class="mb-3 d-flex align-items-center"> -->
    <!-- <input
            formControlName="date"
            type="text"
            maxLength="64"
            required
            class="form-control input-field report-name-input"
            placeholder="Date"
            name="date"
        /> -->
    <!-- </div> -->
    <!-- <div class="form-field">
        <mat-form-field class="form-field data-picker form-field__input  mb-3 d-flex align-items-center"
            appearance="fill">
            <input id="timepicker" #datepicker matInput formControlName="date" [matDatepicker]="picker">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <label for="timepicker" [class.form-field__label_full]="date.value || datepicker.value"
            for="timepicker" class="form-field__label">Date</label>
    </div> -->

    <div class="form-field mb-3 d-flex align-items-center">
        <input  formControlName="date" type="date"
            class="form-control input-field report-name-input form-field__input" name="date"
            id="date" />
        <label for="date" [class.form-field__label_full]="date.value"
            class="form-field__label">Date</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="revision" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="revision"
            id="revision" />
        <label for="revision" [class.form-field__label_full]="revision.value"
            class="form-field__label">Revision</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="approver" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="approver"
            id="approver" />
        <label for="approver" [class.form-field__label_full]="approver.value"
            class="form-field__label">Approver</label>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">
        <input formControlName="checker" type="text" maxLength="64" required
            class="form-control input-field report-name-input form-field__input" name="checker"
            id="checker" />
        <label for="checker" [class.form-field__label_full]="checker.value"
            class="form-field__label">Checker</label>
    </div>

    <div class="wrap-logo-inputs ml-0">
        <div class="wrap-logo-inputs__item">
            <div class="wrap-logo-inputs__label">Logo [Client] </div>
            <label class="wrap-logo-inputs__input logo-input">
                <input (change)="imagesPreview($event, clientLogo)" type="file" name="clientLogo"
                    accept="image/*" #clientFileInput style="display: none;">
                <img class="preview-image" [src]="clientLogo.value.link"
                    style="object-fit: contain; max-width: 70%; max-height: 70%">

                <div *ngIf="!clientLogo.value.link" class="logo-input__icon">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M26.2537 3.75H3.75366C3.25638 3.75 2.77947 3.94754 2.42784 4.29917C2.07621 4.65081 1.87866 5.12772 1.87866 5.625V24.375C1.87866 24.8723 2.07621 25.3492 2.42784 25.7008C2.77947 26.0525 3.25638 26.25 3.75366 26.25H26.2537C26.7509 26.25 27.2279 26.0525 27.5795 25.7008C27.9311 25.3492 28.1287 24.8723 28.1287 24.375V5.625C28.1287 5.12772 27.9311 4.65081 27.5795 4.29917C27.2279 3.94754 26.7509 3.75 26.2537 3.75ZM3.75366 1.875C2.7591 1.875 1.80527 2.27009 1.10201 2.97335C0.39875 3.67661 0.00366211 4.63044 0.00366211 5.625V24.375C0.00366211 25.3696 0.39875 26.3234 1.10201 27.0266C1.80527 27.7299 2.7591 28.125 3.75366 28.125H26.2537C26.7461 28.125 27.2338 28.028 27.6887 27.8395C28.1437 27.6511 28.5571 27.3749 28.9053 27.0266C29.2535 26.6784 29.5298 26.265 29.7182 25.8101C29.9067 25.3551 30.0037 24.8675 30.0037 24.375V5.625C30.0037 4.63044 29.6086 3.67661 28.9053 2.97335C28.2021 2.27009 27.2482 1.875 26.2537 1.875H3.75366Z"
                                fill="#CAD2D5" />
                            <path
                                d="M19.9649 14.3362C20.1042 14.1973 20.2836 14.1057 20.4777 14.0744C20.6719 14.0432 20.871 14.0737 21.0468 14.1618L28.1287 17.8124V26.2499H1.87866V22.4999L6.83991 18.0862C6.99316 17.9335 7.1945 17.8385 7.4098 17.8173C7.62509 17.7961 7.84109 17.85 8.02116 17.9699L13.0087 21.2943L19.9649 14.338V14.3362Z"
                                fill="#CAD2D5" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.44116 13.125C8.8105 13.125 9.17623 13.0523 9.51746 12.9109C9.85869 12.7696 10.1687 12.5624 10.4299 12.3012C10.6911 12.0401 10.8982 11.73 11.0396 11.3888C11.1809 11.0476 11.2537 10.6818 11.2537 10.3125C11.2537 9.94316 11.1809 9.57743 11.0396 9.2362C10.8982 8.89497 10.6911 8.58493 10.4299 8.32376C10.1687 8.0626 9.85869 7.85543 9.51746 7.71409C9.17623 7.57275 8.8105 7.5 8.44116 7.5C7.69524 7.5 6.97987 7.79632 6.45242 8.32376C5.92498 8.85121 5.62866 9.56658 5.62866 10.3125C5.62866 11.0584 5.92498 11.7738 6.45242 12.3012C6.97987 12.8287 7.69524 13.125 8.44116 13.125Z"
                                fill="#CAD2D5" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <span class="logo-input__text">{{clientLogo.value.name || 'Upload an image'}} </span>

            </label>
        </div>

        <div class="wrap-logo-inputs__item">
            <div class="wrap-logo-inputs__label">Logo [Your's] </div>
            <label class="wrap-logo-inputs__input logo-input">
                <input type="file" (change)="imagesPreview($event, yourLogo)" name="yourLogo"
                    #yourFileInput style="display: none;">
                <img class="preview-image" [src]="yourLogo.value.link"
                    style="object-fit: contain; max-width: 70%; max-height: 70%">

                <div *ngIf="!yourLogo.value.link" class="logo-input__icon">
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M26.2537 3.75H3.75366C3.25638 3.75 2.77947 3.94754 2.42784 4.29917C2.07621 4.65081 1.87866 5.12772 1.87866 5.625V24.375C1.87866 24.8723 2.07621 25.3492 2.42784 25.7008C2.77947 26.0525 3.25638 26.25 3.75366 26.25H26.2537C26.7509 26.25 27.2279 26.0525 27.5795 25.7008C27.9311 25.3492 28.1287 24.8723 28.1287 24.375V5.625C28.1287 5.12772 27.9311 4.65081 27.5795 4.29917C27.2279 3.94754 26.7509 3.75 26.2537 3.75ZM3.75366 1.875C2.7591 1.875 1.80527 2.27009 1.10201 2.97335C0.39875 3.67661 0.00366211 4.63044 0.00366211 5.625V24.375C0.00366211 25.3696 0.39875 26.3234 1.10201 27.0266C1.80527 27.7299 2.7591 28.125 3.75366 28.125H26.2537C26.7461 28.125 27.2338 28.028 27.6887 27.8395C28.1437 27.6511 28.5571 27.3749 28.9053 27.0266C29.2535 26.6784 29.5298 26.265 29.7182 25.8101C29.9067 25.3551 30.0037 24.8675 30.0037 24.375V5.625C30.0037 4.63044 29.6086 3.67661 28.9053 2.97335C28.2021 2.27009 27.2482 1.875 26.2537 1.875H3.75366Z"
                                fill="#CAD2D5" />
                            <path
                                d="M19.9649 14.3362C20.1042 14.1973 20.2836 14.1057 20.4777 14.0744C20.6719 14.0432 20.871 14.0737 21.0468 14.1618L28.1287 17.8124V26.2499H1.87866V22.4999L6.83991 18.0862C6.99316 17.9335 7.1945 17.8385 7.4098 17.8173C7.62509 17.7961 7.84109 17.85 8.02116 17.9699L13.0087 21.2943L19.9649 14.338V14.3362Z"
                                fill="#CAD2D5" />
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M8.44116 13.125C8.8105 13.125 9.17623 13.0523 9.51746 12.9109C9.85869 12.7696 10.1687 12.5624 10.4299 12.3012C10.6911 12.0401 10.8982 11.73 11.0396 11.3888C11.1809 11.0476 11.2537 10.6818 11.2537 10.3125C11.2537 9.94316 11.1809 9.57743 11.0396 9.2362C10.8982 8.89497 10.6911 8.58493 10.4299 8.32376C10.1687 8.0626 9.85869 7.85543 9.51746 7.71409C9.17623 7.57275 8.8105 7.5 8.44116 7.5C7.69524 7.5 6.97987 7.79632 6.45242 8.32376C5.92498 8.85121 5.62866 9.56658 5.62866 10.3125C5.62866 11.0584 5.92498 11.7738 6.45242 12.3012C6.97987 12.8287 7.69524 13.125 8.44116 13.125Z"
                                fill="#CAD2D5" />
                        </g>
                        <defs>
                            <clipPath id="clip0">
                                <rect width="30" height="30" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <span class="logo-input__text">{{yourLogo.value.name || 'Upload an image'}} </span>
            </label>
        </div>
    </div>

    <div class="form-field mb-3 d-flex align-items-center">

        <mat-checkbox formControlName="isAssetInfo" [color]="primary">
            <mat-label>Asset information </mat-label>
        </mat-checkbox>
    </div>

    

    <div *ngIf="isAssetInfo.value">
        <form [formGroup]="assetForm">
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="assetName" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="assetName" />
                <label for="assetName" [class.form-field__label_full]="assetName.value" class="form-field__label">Asset Name</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="assetId" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="assetId" />
                <label for="assetId" [class.form-field__label_full]="assetId.value"  class="form-field__label">Asset Id</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="assetNumber" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="assetNumber" />
                <label for="assetNumber" [class.form-field__label_full]="assetNumber.value"  class="form-field__label">Asset Sr Number</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="industry" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="industry" />
                <label [class.form-field__label_full]="industry.value"  for="industry" class="form-field__label">Industry</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="eqClass" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="eqClass" />
                <label [class.form-field__label_full]="eqClass.value"  for="eqClass" class="form-field__label">Eq Class</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="eqSubClass" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="eqSubClass" />
                <label [class.form-field__label_full]="eqSubClass.value" for="eqSubClass" class="form-field__label">Eq Sub Class</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="location" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="location" />
                <label for="location" [class.form-field__label_full]="location.value" class="form-field__label">Location</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="country" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="country" />
                <label for="country" class="form-field__label" [class.form-field__label_full]="country.value">Country</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="dateOfComm" type="date"
                    class="form-control input-field report-name-input form-field__input"
                    name="dateOfComm" />
                <label for="dateOfComm" class="form-field__label" [class.form-field__label_full]="dateOfComm.value">dateOfComm</label>
            </div>

            <div  class="form-field mb-3 d-flex align-items-center">
                <input formControlName="latitude" type="number" [min]="-180" [max]="180"
                    class="form-control input-field report-name-input form-field__input spinner-hide"
                    name="latitude" />
                <label for="latitude" class="form-field__label" [class.form-field__label_full]="latitude.value">Latitude</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="longitude" type="number" [min]="-180" [max]="180"
                    class="form-control input-field report-name-input form-field__input spinner-hide"
                    name="longitude" />
                <label for="longitude" class="form-field__label" [class.form-field__label_full]="longitude.value">Longitude</label>
            </div>
        </form>

    </div>



    <div class="form-field mb-3 d-flex align-items-center">

        <mat-checkbox formControlName="isCompanyInfo" [color]="primary">
            <mat-label>Asset company info </mat-label>
        </mat-checkbox>
    </div>
    <div *ngIf="isCompanyInfo.value">
        <form [formGroup]="companyForm">
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="companyName" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="companyName" />
                <label for="companyName" class="form-field__label" [class.form-field__label_full]="companyName.value">Company Name</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="address" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="address" />
                <label for="address" class="form-field__label" [class.form-field__label_full]="address.value">Address</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="city" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="city" />
                <label for="city" class="form-field__label" [class.form-field__label_full]="city.value">City</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="state" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="state" />
                <label for="state" class="form-field__label" [class.form-field__label_full]="state.value">State</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="country" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="companyCountry" />
                <label for="companyCountry" class="form-field__label" [class.form-field__label_full]="companyCountry.value">Country</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="zip" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="zip" />
                <label for="zip" class="form-field__label" [class.form-field__label_full]="zip.value">Zip</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="phone" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="phone" />
                <label for="phone" class="form-field__label" [class.form-field__label_full]="phone.value">Phone</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="fax" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="fax" />
                <label for="fax" class="form-field__label" [class.form-field__label_full]="fax.value">Fax</label>
            </div>
          
        </form>

    </div>


    <div class="form-field mb-3 d-flex align-items-center">

        <mat-checkbox formControlName="isContactInfo" [color]="primary">
            <mat-label>Asset contact info </mat-label>
        </mat-checkbox>
    </div>
    <div *ngIf="isContactInfo.value">
        <form [formGroup]="contactForm">
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="contactName" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="contactName" />
                <label for="contactName" class="form-field__label" [class.form-field__label_full]="contactName.value">Contact Name</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="contactPersonLocation" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="contactPersonLocation" />
                <label for="contactPersonLocation" class="form-field__label" [class.form-field__label_full]="contactPersonLocation.value">Location</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="department" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="department" />
                <label for="department" class="form-field__label" [class.form-field__label_full]="department.value">Department</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="email" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="email" />
                <label for="email" class="form-field__label" [class.form-field__label_full]="email.value">Email</label>
            </div>

            <div class="form-field mb-3 d-flex align-items-center">
                <input formControlName="workContact" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="workContact" />
                <label for="workContact" class="form-field__label" [class.form-field__label_full]="workContact.value">Work #</label>
            </div>
            <div class="form-field mb-3 d-flex align-items-center">  
                <input formControlName="mobileContact" type="text"
                    class="form-control input-field report-name-input form-field__input"
                    name="mobileContact" />
                <label for="mobileContact" class="form-field__label" [class.form-field__label_full]="mobileContact.value">Mobile #</label>
            </div>
            
        </form>

    </div>
   
    <app-upload-template [templates]="templates$ | async" (successLoad)="onSuccessAddTemplate()">
        <button #uploadTmplBtn class="UppyTemplateModalOpenerBtn" style="display: none" type="button">
            <span class="text-uppercase">Upload template</span>
        </button>
    </app-upload-template>



</form>

</div>