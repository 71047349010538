import {Component, OnInit} from '@angular/core';
import {BackendService} from '../services/backend.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-company-add-dialog',
  templateUrl: './company-add-dialog.component.html',
  styleUrls: ['./company-add-dialog.component.scss']
})
export class CompanyAddDialogComponent implements OnInit {
  public industry = '';
  public companyName = '';
  form = new FormGroup({
    companyName: new FormControl('', [Validators.required]),
    industry: new FormControl('', [Validators.required]),
  });

  constructor(public backendService: BackendService, public dialogRef: MatDialogRef<CompanyAddDialogComponent>) {
  }

  ngOnInit(): void {
  }

  submit(): void {
    if (this.industry && this.companyName) {
      this.backendService.setUserCompany(this.companyName, this.industry).subscribe(result => {
        this.dialogRef.close();
      });
    }
  }

}
