import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend.service';
import { UiService } from 'src/app/services/ui.service';
import { IGroup } from 'src/app/models/group.interface';
import { GeneralService } from 'src/app/services/general.service';
@Component({
  selector: 'app-tag-filter',
  templateUrl: './tag-filter.component.html',
  styleUrls: ['./tag-filter.component.scss']
})
export class TagFilterComponent implements OnInit {
  onDestroy$ = new Subject();
  tags = [];
  groups:IGroup[];
  tabIndex=0;
  public sensitiveTag;
  public selectedTag;
  selectedGroup;
  constructor(
    public uiService:UiService,
    public dialogRef: MatDialogRef<TagFilterComponent>,
    public dialog: MatDialog,
    private generalService:GeneralService,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getTags(data.projectId)
  }

  public get groups$() {
    return this.uiService.groups$;
  }

  getGroups() {
    this.uiService.groups$.pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      this.groups = groups;
    })
  }


  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
    this.getGroups();
  }
  

  getTags(projectId) {
    this.backendService.getTags(projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      this.tags = [
        { tag: 'All' },
        { tag: "Tagged" },
        { tag: 'Untagged' },
        ...tags,
      ];
      this.tags=this.tags.concat(this.uiService.project?.projectType === 'thermal' ?
      [
        {
          tag: 'Hotspots', 'isThermal': true,
        }, {
          tag: 'Coldspots', 'isThermal': true,
        }
      ]
      : [])
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  groupClick(group){
    this.selectedGroup= group;
  }
  tagClick(tag, event) {
    if (event.target.className.indexOf('sensitivity') != -1) {
      this.selectedTag = tag.tag;
      this.sensitiveTag = null;
      this.tags.forEach((e) => {
        if(e.tag != this.selectedTag){
          e.isOpen = false;
        }
      });
      tag.isOpen = !tag.isOpen;
    }
    else if (event.target.className.indexOf('levels') != -1) {
      this.severityTags(parseInt(event.target.id));
    }
    else {
      this.sensitiveTag = null;
      this.selectTag(tag.tag)
    }
  }

  severityTags(type:number) {
    this.sensitiveTag = type;
  }

  selectTag(tag): void {
    this.selectedTag = tag;
  }

  filterImages() {
    if(this.tabIndex == 0){
      this.dialogRef.close({
        selectedTag: this.selectedTag,
        type:'features',
        sensitiveTag: this.sensitiveTag
      })
    }else{
      this.dialogRef.close({
        type:'groups',
        selectedGroup: this.selectedGroup
      })
    }
   
  }

  tabsChange(event){
   this.tabIndex=event.index;
  }

  getFilterTagCount(tag) {
    switch (tag) {
      case 'Tagged':
        return `(${this.uiService.images.filter((image) => image.tags && image.tags.length).length})`;
      case 'Hotspots':
        return `(${this.uiService.images.filter((image) => (image.hotspots && image.hotspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === true).length > 0)

        ).length})`;
      case 'Coldspots':
        return `(${this.uiService.images.filter((image) => (image.coldspots && image.coldspots.length)
          ||
          (image.thermalPolygons && image.thermalPolygons.filter((polygon) => this.generalService.isHotspots(polygon) === false).length > 0)
        ).length})`;
    }
  }
}
