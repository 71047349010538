import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { BackendService } from '../../services/backend.service';
@Component({
  selector: 'app-severity-level',
  templateUrl: './severity-level.component.html',
  styleUrls: ['./severity-level.component.scss']
})
export class SeverityLevelComponent implements OnInit {
  @ViewChild('imageModel') imageModelDialog: any;
  imageModelDialogRef;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private dialog:MatDialog,
    public dialogRef: MatDialogRef<SeverityLevelComponent>,
    private backendService: BackendService) {
  }

  ngOnInit(): void { }

  addLevel(level:number) {
    this.backendService.addSeverityLevel(this.data.id, this.data.imageId, level).subscribe(() => {
      this.dialogRef.close({'update':true});
    });
  }

  openDetails(data){
    this.imageModelDialogRef = this.dialog.open(this.imageModelDialog, {
      width:'70vh',
      data: {
      title:data.title,
      description:this.data.description,
      imageUrl:data.imageUrl,
      action: data.action
      }
    })
  }

  additionalInfo(){
    this.imageModelDialogRef = this.dialog.open(this.imageModelDialog, {
      width:'70vh',
      data: {
      title:this.data.tag,
      description:this.data.description,
      imageUrl:this.data.chart
      }
    })
  }
}
