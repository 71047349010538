<div class="grid-y h-100">
  <headings class="headings py-2">

    <div class="d-flex ml-3">
      <div (click)="changeOption('images')" [class.active]="option =='images'" class="option-type">
        <mat-icon> image</mat-icon>
        <span>
          Pictures
        </span>
      </div>
      <div (click)="changeOption('videos')" [class.active]="option =='videos'" class="option-type">
        <mat-icon> videocam</mat-icon>
        <span>
          Videos
        </span>
      </div>

      <label class="ml-2 mr-2">Locations</label>
      <mat-form-field appearance="fill">
        <mat-select style="width:150px" [(value)]="uiService.selectedBaseModel"
          (selectionChange)="handleLabelSelectionChange()" (click)="$event.stopImmediatePropagation()">
          <mat-option value="3d">
            3D locations
          </mat-option>
          <mat-option value="2d">
            2D locations
          </mat-option>
        </mat-select>
      </mat-form-field>

    </div>

    <div class="d-flex side-right ml-auto">

      <!-- <mat-spinner-button  [hidden]="!isChanges()" [disabled]="getReadOnlyForCurrentUser() || labelId == 'unlabelled'" class="mr-1 ml-1" [options]="createBtnOptions"
    (btnClick)="saveMedias()"></mat-spinner-button> -->

      <button *ngIf="labelId"
        [disabled]="isMediaLinking || getReadOnlyForCurrentUser() || labelId == 'unlabelled' ||  (selectedLabelNode?.data?.layer && selectedLabelNode?.data?.layer != 'L5')"
        mat-button class="btn folder-button mr-2 ml-2" (click)="showImageDialog()" class="header-flat-button mr-2"
        type="button">
        <mat-icon class="mr-1">add_circle_outline</mat-icon>
        <mat-spinner *ngIf="isMediaLinking" [diameter]="20"></mat-spinner>
        <span>Link media</span>
      </button>
      <button *ngIf="labelId" mat-button class="header-flat-button mr-2" type="button" (click)="delete()"
        [disabled]="isLinking || getReadOnlyForCurrentUser() || labelId == 'unlabelled' || (selectedLabelNode?.data?.layer && selectedLabelNode?.data?.layer != 'L5')">
        <mat-icon class="mr-2" svgIcon="trash"></mat-icon>
        <mat-spinner *ngIf="isLinking" [diameter]="20"></mat-spinner>
        <span>Delete</span>
      </button>


    </div>
  </headings>

  <div class="customTemplate-body">
    <div *ngIf="uiService.project?.linkImage_status === queued || uiService.project?.linkImage_status === processing"
      class="mt-3 w-100 flex align-center align-middle">
      <mat-spinner [diameter]="20"></mat-spinner>
      The data uploaded is being linked to respective labels in the background.
    </div>

    <div class="p-1" style="color:#e72222" *ngIf="uiService.project?.linkImage_status === failed"
      class="mt-3 w-100 flex align-center align-middle">
      The data uploaded is being linked to respective labels is failed,
      Please contact info@oceansai.tech with the asset name:< {{uiService.asset.assetName}}> and project:
        <{{this.uiService.project.name}}> for assistance
    </div>

    <div style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
      [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="contextMenu" *ngIf="!getReadOnlyForCurrentUser()">
    </div>
    <div *ngIf="loading" class="mt-5 h-100 w-100 flex align-center align-middle">
      <mat-spinner [diameter]="50"></mat-spinner>
      fetching unlabelled images and videos
    </div>

    <div *ngIf="imageLoading" class="mt-1 w-100 flex align-center align-middle">
      <mat-spinner [diameter]="20"></mat-spinner>
      loading images
    </div>
    <ng-container *ngIf="labelId && models && models.length; then hasLabel; else emptyLabel;"></ng-container>
    <ng-template #hasLabel>
      <dts-select-container (contextmenu)="openContextMenu($event)" class="project-items w-100 p-2"
        #containerLabelGrid="dts-select-container" (select)="someMethod($event)" [(selectedItems)]="selectedImages"
        [disabled]="getReadOnlyForCurrentUser()" [style.minHeight]="'300px'">
        <!-- [dragula]='"sort-label-linked"' (dragulaModelChange)="sortList($event)" [dragulaModel]='getMedia()'  -->
        <div class="infinite-scroll-container label-scroll w-100" (scroll)="containerLabelGrid.update()"
          (scrolled)="containerLabelGrid.update()" infiniteScroll [infiniteScrollDistance]="4" [scrollWindow]="false"
          [scrollWindow]="false" (scrolled)="onScroll()"
          style="height: calc(100vh - 170px); overflow-y: auto;overflow-x:hidden;">
          <div *ngIf="linkImages.length" class="w-100   p-2">
            <ng-container *ngFor="let image of getMedia()">
              <div class="project-item" [style.border]="isChecked(image.id) ? '2px solid #2196f3' : 'none'"
                [dtsSelectItem]="image">
                <div class="project-item__contents">
                  <div style="width: 100%; height: 100%;">
                    <mat-checkbox [disabled]="true" [checked]="isChecked(image.id)" [color]="'primary'"
                      style="position: absolute; right: 5px; top: 5px">
                    </mat-checkbox>
                    <img [src]="option==='images'?
                    uiService.isColorImage && image.colorThumbFileUrl ? 
                    image.colorThumbFileUrl:
                    image.thumbFileUrl:image.posterImage" alt="Image" class="img-placeholder"
                      style="width: 100%; height: 100%; object-fit: cover"
                      (dblclick)="goToCanvas(image);switchTab.emit()" />
                  </div>
                </div>

                <span *ngIf="option === 'images'" class="icon-3d" matTooltip="Include/Exclude for 3d model (ALT+click)"
                  (click)="toggle3DIcon(image);$event.stopPropagation();">
                  <img [src]="getExcudedImages(image.id) ? 'assets/3d-black.png' : 'assets/3d.png'" />
                </span>

                <span *ngIf="option ==='images'" [matTooltip]="getSeverityDetail(image)" [style.background]="checkTopSeverity(image)"
                  class="circle icon-label-severity"></span>

                <span *ngIf="option ==='images'" (click)="openExifInfo(image.metaData)" class="icon-info"
                  matTooltip="EXIF Info (ALT+click)">
                  <mat-icon [style.color]="!isEmptyObject(image.metaData) ? '#007bff' : 'darkgray'">info</mat-icon>
                </span>
                <div *ngIf="option ==='images'" [style.paddingLeft.px]="checkTopSeverity(image) ? 50 : 35"
                  style="padding-right: 20px" class="project-item__name">{{ image.fileName }}</div>
                <div *ngIf="option !='images'" class="project-item__name">{{ image.fileName }}</div>


              </div>
            </ng-container>
          </div>
          <div class="w-100 d-flex justify-content-center" *ngIf="isContentLoading$ | async">
            <!-- Loading... -->
            <span>
              <mat-spinner [diameter]="20"></mat-spinner>
            </span>
          </div>
        </div>
      </dts-select-container>

    </ng-template>

    <ng-template #emptyLabel>
      <div *ngIf="!modelId" class="text-center mt-5 w-100">
        No locations added yet, Go to Asset panel and add your locations
      </div>
    </ng-template>
  </div>

  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-item="item">
      <button *ngIf="selectedImages.length && labelId === 'unlabelled'" (click)="selectMovedImages()"
        mat-menu-item>Move</button>
      <button *ngIf="unlabelledImages.length" (click)="cancelMoving()" mat-menu-item>Cancel moving</button>
      <button *ngIf="unlabelledImages.length" (click)="pasteImages()" mat-menu-item>Paste</button>
    </ng-template>
  </mat-menu>
</div>

<ng-template let-data #exifModel>
  <div class="small-info" *ngIf="!data.isExapnd">
    <p><strong>Make: {{data.data?.Make}}</strong></p>
    <strong>EXIF Info-</strong>
    <ul style="padding-left: 20px;">
      <li>Exif Version: {{data.data?.ExifToolVersion || 'N/A' }}</li>
      <li>Model: {{data.data?.Model || 'N/A' }}</li>
      <li>Lens Model: {{data.data?.LensModel || 'N/A' }}</li>
      <li>Serial Number: {{data.data?.SerialNumber || 'N/A' }}</li>
    </ul>
    <strong>GPS Info-</strong>
    <ul style="padding-left: 20px;">
      <li>Altitude: {{data.data?.GPSAltitudeRef || 'N/A' }}</li>
      <li>LatitudeRef: {{data.data?.GPSLatitudeRef || 'N/A' }}</li>
      <li>LongitudeRef: {{data.data?.GPSLongitudeRef || 'N/A' }}</li>
      <li>Latitude: {{data.data?.GpsLatitude || 'N/A' }}</li>
      <li>Longitud: {{data.data?.GpsLongitude || 'N/A' }}</li>

    </ul>
  </div>
  <div class="more-info" *ngIf="data.isExapnd">
    <div *ngFor="let info of data.more">
      <b>{{info[0]}}</b>: {{info[1]}}
    </div>
  </div>
  <span *ngIf="!data.isExapnd" class="show-more" (click)="openPrompt(data,true)">Show More</span>
  <span *ngIf="data.isExapnd" class="show-more" (click)="openPrompt(data,false)">Show Less</span>

</ng-template>