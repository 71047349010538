import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { MemberAddDialogComponent } from '../member-add-dialog/member-add-dialog.component';
import { UiService } from '../services/ui.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit, OnDestroy {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  public projects$: Observable<Project[]>;
  public projectSubscription: Subscription = new Subscription();
  public page = 1;
  public pageSize = 10;
  public members = [];
  public slicedMembers = [];
  public roles = [];
  public currentUserId: string;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public selectedMember = null;
  private readonly inviteDaysLimit: number = 3;
  private onDestroy$ = new Subject();
  @ViewChild('changeRoleDialog') changeRoleDialog: any;
  changeRoleDialogRef;
  public selectedRole = 'editor';
  public readOnly: boolean = false;

  get projectId(): string {
    return this.uiService.selectedProjectId;
  }

  set projectId(value: string) {
    this.uiService.selectedProjectId = value;
  }

  constructor(public toast: ToastrService, public backendService: BackendService, public dialog: MatDialog, public uiService: UiService) {
    this.currentUserId = this.backendService.getCurrentUser().uid;
    if (!this.projectId) {
      this.loading$.next(false);
    }
    this.projectSubscription = this.uiService.getProjectsEvent$.pipe(
      tap((projects: []) => {
        this.projects$ = of(projects);
        if (projects && projects.length === 0) {
          this.loading$.next(false);
        }
      }),
    ).subscribe(() => {
      if (this.projectId) {
        this.getMembers();
      }
    });
  }

  getSearch(event) {
    const search = event.target.value;
    this.refreshMembers(search);
  }

  getMembers(): any {
    this.loading$.next(true);
    this.projects$.subscribe(projects => {
      const membersDoc = projects.find(project => project.id === this.projectId);
      if (!membersDoc) return of();
      return combineLatest(
        this.backendService.getUsers(Object.keys(membersDoc.people || {})),
        this.backendService.getProspective(this.projectId)
      ).pipe(takeUntil(this.onDestroy$)).subscribe(([members, prospectiveMembers]) => {
        this.loading$.next(false);
        this.roles = Object.keys(membersDoc.people || {}).map(person => {
          return {
            id: person,
            role: membersDoc.people[person].role,
          };
        });

        const newMembers = [
          ...members.map(member => ({ ...member, status: 'onboarded', readonly: membersDoc.people[member._id].readonly })),
          ...prospectiveMembers
            .filter(prospectiveMember => !members.find(member => member.email === prospectiveMember.email))
            .map(prospectiveMember => ({
              ...prospectiveMember,
              status: this.getDaysFromDate(prospectiveMember.timestamp.seconds) < this.inviteDaysLimit ? 'invited' : 'resend',
            }))
        ];
        this.members = newMembers;
        this.refreshMembers();
      }, error => {
        this.loading$.next(false);
      });
    });

  }

  getRoleForUser(user): any {
    if (user.status === 'onboarded') {
      return this.roles.find(role => role.id === user._id)?.role;
    } else {
      return user.role;
    }
  }

  ngOnInit(): void {

  }

  ngAfterViewChecked() { }

  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  refreshMembers(search?:string): void {
    let members= this.members || [];
    if(search){
      members=this.members.filter(member => {
        return member.email?.toLocaleLowerCase()?.includes(search);
  
      });
    }
    this.slicedMembers = members
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }

  changeProject(id): void {
    this.projectId = id;
    this.slicedMembers = [];
    this.members = [];
    this.roles = [];
    this.uiService.changeProjectEvent$.next();
    if (this.projectId) {
      this.getMembers();
    }
  }

  openDialog(): void {
    const isValid = this.backendService.domainControlValid('invite');
    if (isValid) {
      this.dialog.open(MemberAddDialogComponent, {
        width: '40vw',
        height: '100vh',
        panelClass: 'top-mr-dialog',
        data: {
          members: this.members,
          roles: this.roles,
          projectId: this.projectId,
        }
      });
    } else {
      if (this.backendService.DOMAIN_CONTROL) {
        this.toast.error(`Invited users limit (${this.backendService.DOMAIN_CONTROL.invitedUsers}) reached. For more users please reach out to sales@oceansai.tech.`);
      } else {
        this.toast.error(`please reach out to sales@oceansai.tech to update your account limit`);
      }
    }
  }

  deleteMember(member): void {
    if (member.status === 'onboarded') {
      this.backendService.removeUserFromProject(this.projectId, member._id).pipe(
        switchMap(() => this.backendService.removeProspectiveMember(member._id))
      ).subscribe(result => {
        this.refreshMembers();
      });
    } else {
      this.backendService.removeProspectiveMember(member._id).subscribe(result => {
        this.refreshMembers();
      });
    }
  }

  getDaysFromDate(seconds: number) {
    const currentDate: any = new Date();
    const preventDate: any = new Date(seconds * 1000);
    const result = (currentDate - preventDate) / (1000 * 60 * 60 * 24);
    return result;
  }

  reinvite(member) {
    member.status = 'Re-Sending';
    const role = this.getRoleForUser(member);
    this.backendService.inviteUserToProject(this.projectId, member.email, role, member.readonly, this.uiService.asset?.assetName).pipe(
      switchMap(() => this.backendService.updateProspectiveMember(member._id, {
        timestamp: new Date()
      }))
    ).subscribe();
  }

  isCurrentUser(userId: string): boolean {
    return this.backendService.getCurrentUser().uid === userId;
  }

  isCurrentUserOwner() {
    const currentUserId = this.backendService.getCurrentUser().uid;
    return this.getRoleForUserById(currentUserId) === 'owner';
  }

  isCurrentUserEditor() {
    const currentUserId = this.backendService.getCurrentUser().uid;
    return this.getRoleForUserById(currentUserId) === 'editor';
  }

  isCurrentUserReviewer() {
    const currentUserId = this.backendService.getCurrentUser().uid;
    return this.getRoleForUserById(currentUserId) === 'reviewer';
  }

  isCurrentUserApprover() {
    const currentUserId = this.backendService.getCurrentUser().uid;
    return this.getRoleForUserById(currentUserId) === 'approver';
  }
  isCurrentUserViewer() {
    const currentUserId = this.backendService.getCurrentUser().uid;
    return this.getRoleForUserById(currentUserId) === 'viewer';
  }

  getRoleForUserById(id: string): string {
    const roleObj = this.roles.find(role => role.id === id);
    if (roleObj) {
      return roleObj.role;
    }
  }

  toggleReadOnly(member) {
    const role = this.getRoleForUser(member);
    if (member.status === 'onboarded') {
      this.backendService.changeReadOnlyForProjectMember$(
        this.projectId,
        role,
        !member.readonly,
        member._id
      ).subscribe();
    } else {
      this.backendService.changeReadOnlyForProspectiveMember$(
        member._id,
        !member.readonly
      ).subscribe();
    }
  }

  changeOwner(member) {
    this.projects$.subscribe(projects => {
      const currentProject = projects.find(p => p.id === this.projectId);
      console.log(currentProject.name);
    })
    if (member.status === 'invited') {
      console.error('This member isn\'t accepted invitation');
      return false;
    }

    if (!this.isCurrentUserOwner()) {
      console.error('You aren\'t owner');
      return false;
    }

    if (this.getRoleForUser(member) === 'owner') {
      console.error('This member is already owner');
      return false;
    }

    this.backendService.updateProjectOwner(this.projectId, member._id).pipe(
      switchMap(() => this.projects$),
      switchMap(projects => {
        const currentProject = projects.find(p => p.id === this.projectId);
        return this.backendService.sendEmailWithNotification(member.email, currentProject.name, this.uiService.asset?.assetName)
      })
    ).subscribe(() => this.closeMakeOwnerMenu);
  }

  closeMakeOwnerMenu() {
    this.menuTrigger.closeMenu();
    this.selectedMember = null
  }

  toggleControl(event) {
    if (event.value == 'editor') {
      this.readOnly = false;
    }
    if (event.value == 'viewer') {
      this.readOnly = true;
    }
  }

  // functions for change role modal:
  openChangeRoleDialog(member): void {
    this.changeRoleDialogRef = this.dialog.open(this.changeRoleDialog, {
      data: { member: member }
    });
    this.selectedRole = this.getRoleForUser(member);
    this.readOnly = member.readonly;
  }

  changeRole(member) {
    if (member.status === 'onboarded') {
      this.backendService.changeRole(this.projectId, member._id, this.selectedRole, this.readOnly).pipe()
        .subscribe(() => {
          this.changeRoleDialogRef.close()
        });
    } else {
      this.backendService.changeRoleOnlyForProspectiveMember$(
        member._id,
        this.selectedRole
      ).subscribe(() => {
        this.changeRoleDialogRef.close()
      });
    }

  }

  isSearch: boolean = false;
  searchToggle() {
    this.isSearch = !this.isSearch;
  }

}

