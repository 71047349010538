import { Plugin, UppyFile } from '@uppy/core';
import { assocPath } from 'ramda';
import { from } from 'rxjs';
import { concatMap, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import QrcodeDecoder from 'qrcode-decoder';
export default class FirebaseCloudStorage extends Plugin {
  public type = 'uploader';
  public id = 'FirebaseCloudStorage';
  public title = 'Firebase Cloud Storage';
  public refId;
  public storageRef;
  public videoStorageRef;
  public storageCustomMetaData;
  constructor(uppy, opts) {
    super(uppy, opts);

    if (!opts.storageRef) {
      throw Error(
        'Please provide the root storageRef to be used as option `storageRef`. See https://firebase.google.com/docs/storage/web/upload-files'
      );
    }

    this.storageRef = opts.storageRef;
    this.videoStorageRef = opts.videoStorageRef;

    this.storageCustomMetaData = opts.storageCustomMetaData;
    this.uploadFiles = this.uploadFiles.bind(this);
    this.uploadFileById = this.uploadFileById.bind(this);
  }

  labelName: string = "";
  async uploadFileById(id): Promise<any> {
    const refId = uuidv4();
    this.refId = refId;
    const file = this.uppy.getFile(id);
    const fileRef = file.type.indexOf('video') != -1 ?
      this.videoStorageRef.child(refId + "." + file.extension)
      : this.storageRef.child(refId);
    const metaData = {
      contentType: file.type,
      customMetadata: {
        ...this.storageCustomMetaData,
        fileName: file.name
      }
    };

    const QRfile = file.name.toLowerCase().indexOf('qrcode') != -1;
    if (QRfile) {
      const readFile = (iFIle) => new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(iFIle.data);
      });
      const image = await readFile(file);
      const qrResult = await new QrcodeDecoder().decodeFromImage(image);
      file.meta['isQRImage'] = true;
      this.labelName = qrResult?.data;
    } else {
      if (this.labelName) {
        file.meta['label'] = this.labelName;
      }
    }

    //file.meta['fileName'] = file.name.split('.').slice(0, -1).join('.');;
    if (file.meta['isQRImage']) {
      metaData.customMetadata = {
        ...metaData.customMetadata,
        ...{
          'isQRImage': true,
        }
      }
    } else {
      if (file.meta['label'] && metaData.customMetadata.imageLinking) {
        metaData.customMetadata = {
          ...metaData.customMetadata,
          ...{
            'labelValue': file.meta['label']
          }
        }
      }
    }
    const uploadTask = fileRef.put(file.data, metaData);
    const uploadPromise = new Promise((resolve, reject) => {
      this.uppy.emit('upload-started', file);

      uploadTask.task.on('state_changed', async (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        await this.uppy.emit('upload-progress', file, {
          uploader: this,
          bytesUploaded: snapshot.bytesTransferred,
          bytesTotal: snapshot.totalBytes
        });
      }, async (error) => {
        // A full list of error codes is available at
        await this.uppy.emit('upload-error', file, error);
        reject(error);
      }, async () => {
        const file = this.uppy.getFile(id);
        await this.uppy.emit('upload-success', file, {
          status: 200,
          refId: this.refId
        });
        resolve(file);
      });
    });

    const handleCancel = () => {
      uploadTask.cancel();
    };

    const handlePause = (targetFileID, shouldPause) => {
      if (targetFileID === id) {
        if (shouldPause) { uploadTask.pause(); }
        else { uploadTask.resume(); }
      }
    };

    const handlePauseAll = () => {
      uploadTask.pause();
    };

    const handleResumeAll = () => {
      uploadTask.resume();
    };

    this.uppy.on('cancel-all', handleCancel);
    this.uppy.on('upload-pause', handlePause);
    this.uppy.on('pause-all', handlePauseAll);
    this.uppy.on('resume-all', handleResumeAll);

    return uploadPromise.finally(() => {
      this.uppy.off('cancel-all', handleCancel);
      this.uppy.off('upload-pause', handlePause);
      this.uppy.off('pause-all', handlePauseAll);
      this.uppy.off('resume-all', handleResumeAll);
    });
  }

  async uploadFiles(fileIds): Promise<void> {
    if (fileIds.length === 0) {
      this.uppy.log('[FirebaseCloudStorage] No files to upload!');
      return;
    }

    this.uppy.log('[FirebaseCloudStorage] Uploading...');
    await from(this.uppy.getFiles().filter(o => !o.progress.uploadComplete).sort((a, b) => a.data['lastModified'] - b.data['lastModified']).map(o => o.id)).pipe(
      concatMap((id) => this.uploadFileById(id))
    ).toPromise().then(() => {
      setTimeout(() => {
        this.uppy.emit('upload-finished', {
          status: 200
        });
      }, 2000);
    });
  }

  changeCapability(name, value) {
    const { capabilities } = this.uppy.getState();

    this.uppy.setState(assocPath(['capabilities', name], value, capabilities));
  }

  install() {
    this.changeCapability('resumableUploads', true);
    this.uppy.addUploader(this.uploadFiles);
  }

  uninstall() {
    this.changeCapability('resumableUploads', false);
    this.uppy.removeUploader(this.uploadFiles);
  }
}
