<ng-container [ngSwitch]="params?.mode">

  <ng-container *ngSwitchCase="modeTypes.resetPassword">
    <app-reset-password></app-reset-password>
  </ng-container>

  <ng-container *ngSwitchCase="modeTypes.verifyEmail">
    <app-verify-email></app-verify-email>
  </ng-container>

  <!-- <ng-container *ngSwitchCase="modeTypes.recoverEmail">
  </ng-container> -->

</ng-container>

