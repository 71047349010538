import { dragula, DragulaService } from 'ng2-dragula';
import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-dxf-layers',
  templateUrl: './dxf-layers.component.html',
  styleUrls: ['./dxf-layers.component.scss']
})
export class DxfLayersComponent implements OnInit {
 lists:any= [];
 subs = new Subscription();
 dropItems:any=[];
  constructor(
     @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DxfLayersComponent>,
    private dragulaService:DragulaService ) { 
     this.lists = data.lists;
      this.dragulaService.destroy('layers_list')
     this.dragulaService.createGroup('layers_list', {
      revertOnSpill: true
     });
  }

  ngOnInit(): void {

  }


  saveLayers(){
    this.dialogRef.close(this.dropItems);
  }

}
