<div>
    <h1 mat-dialog-title>
      <span>Update Password</span>
    </h1>
    <div mat-dialog-content class="mb-5">
    
      <mat-form-field class="w-100 mb-2" appearance="fill">
        <mat-label>Current password</mat-label>
        <input
          matInput
          cdkFocusInitial
          type="password"
          placeholder="Enter your current password"
          [(ngModel)]="currentPassword"

        />
      </mat-form-field>

      <mat-form-field class="w-100 mb-2  mr-40" appearance="fill">
        <mat-label>New password</mat-label>
        <input
          matInput
          cdkFocusInitial
          type="password"
          placeholder="Enter your new password"
          [(ngModel)]="password"

        />
      </mat-form-field>
     </div>
  
    <div mat-dialog-actions>
      <button mat-button (click)="close()">Cancel</button>
      <mat-spinner-button
        [options]="createBtnOptions"
        (btnClick)="handleUpdate(currentPassword,password)"
        [disabled]="!password || !currentPassword"
      ></mat-spinner-button>
    </div>
  
  </div>
  