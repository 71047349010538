import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BackendService } from '../services/backend.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';
import { AngularFireStorage } from '@angular/fire/storage';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-tag-add-dialog',
  templateUrl: './tag-add-dialog.component.html',
  styleUrls: ['./tag-add-dialog.component.scss']
})
export class TagAddDialogComponent implements OnInit, OnDestroy {
  public tag = '';
  public description = '';
  public sensitivity = false;
  public levels: any = environment.severityLevels;
  public tags = [];
  public aiTags = [];
  public filteredTags = [];
  public chart = '';
  onDestroy$ = new Subject();
  form = new FormGroup({
    tag: new FormControl('', [Validators.required]),
    sensitivity: new FormControl(false, []),
  });
  appInferenceEngineMode;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public backendService: BackendService,
    public toastr: ToastrService,
    private storage: AngularFireStorage,
    public dialogRef: MatDialogRef<TagAddDialogComponent>,
    private clipboard: Clipboard) {
    this.appInferenceEngineMode = environment.engineMode;
  }

  tagsOverflow(): boolean {
    return this.tags.length === this.backendService.CONSTANTS.TAGS_MAX_COUNT;
  }

  isNameValid(): boolean {
    if (this.tag && !this.aiTags.find(tag => tag.toLowerCase() == this.tag.toLowerCase())) {
      return !this.tags.find(tag => tag.tag.toLowerCase() === this.tag.toLowerCase());
    }
    return true;
  }

  sensitiveLevel = 3;
  sensitivityChange(checked: boolean) {
    if (checked) {
      this.addTable(this.sensitiveLevel);
    } else {
      this.selectedLevels = [];
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

  }
  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0' });
    this.tags = this.data.tags;
    if (this.data.edit) {
      this.sensitivity = this.data.tag.sensitivity;
      this.selectedLevels = this.data.tag.levels;
      this.sensitiveLevel = this.data.tag.levels.length;
      this.tag = this.data.tag.tag;
      this.description = this.data.tag.description || '';
      this.levels.forEach((level, i) => {
        if (i < this.data.tag.levels.length) {
          level.checked = true;
        } else {
          level.checked = false;
        }
      });
    }
    this.backendService.getVersion().pipe(takeUntil(this.onDestroy$)).subscribe(versions => {
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      this.filteredTags = this.aiTags = versions.map(o => o.modalType).filter(onlyUnique)
        ;
    });
  }

  filterAiTags(value: string) {
    const filterValue = value.toLowerCase();
    this.filteredTags = this.aiTags.filter(tag => tag.toLowerCase().includes(filterValue));
  }
  selectTag(tag) {
    this.tag = tag;
  }

  editTag() {
    const tag = this.data.tag;
    this.backendService.editTag(tag.id, this.data.projectId, this.selectedLevels, this.data.tags).subscribe(result => {
      this.toastr.success("Details updated successfully")
      this.dialogRef.close();
    });
  }

  submit(): void {
    if (!this.tagsOverflow() && this.tag) {
      let aiTag = this.aiTags.find(tag => tag.toLowerCase() == this.tag.toLowerCase());
      const tag = this.tags.find(tag => tag.tag.toLowerCase() == this.tag.toLowerCase());
      if (this.appInferenceEngineMode == 2 || !this.backendService.DOMAIN_CONTROL?.features?.aiEngine) {
        aiTag = false;
      }
      if (tag && aiTag) {
        //update existing tag to AI tag
        tag.sensitivity = this.sensitivity;
        tag.aiTag = true;
        this.backendService.updateTagStatus(tag.id, this.data.projectId, tag).subscribe(() => {
          this.tag = '';
          this.description = '';
          this.sensitivity = false;
          this.selectedLevels = [];
          this.sensitivity = false;
          this.toastr.success("Features added successfully");
        });
      } else {
        this.backendService.addTag(this.tag, this.sensitivity, this.data.projectId, aiTag ? true : false,
          this.description, this.selectedLevels, this.chart,
        ).subscribe(() => {
          this.tags = [
            { tag: this.tag, status: 'active', aiTag: aiTag, sensitivity: this.sensitivity, levels: this.selectedLevels },
            ...this.tags
          ];
          this.tag = '';
          this.description = '';
          this.chart = '',
            this.selectedLevels = [];
          this.sensitivity = false;
          this.toastr.success("Features added successfully.");

        });
      }

    }
  }

  chunkify(a, n, balanced) {
    if (n < 2)
      return [a];
    var len = a.length,
      out = [],
      i = 0,
      size;
    if (len % n === 0) {
      size = Math.floor(len / n);
      while (i < len) {
        out.push(a.slice(i, i += size));
      }
    }

    else if (balanced) {
      while (i < len) {
        size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, i += size));
      }
    }
    else {
      n--;
      size = Math.floor(len / n);
      if (len % size === 0)
        size--;
      while (i < size * n) {
        out.push(a.slice(i, i += size));
      }
      out.push(a.slice(size * n));
    }
    return out;
  }
  getColor(index, total) {
    const chunks = this.chunkify(this.levels, total, true);
    const arr = chunks[index];
    return arr[Math.round((arr.length - 1) / 2)].color;

  }
  getTitle(index) {
    switch (index) {
      case 1:
        return this.selectedLevels[index - 1]?.title || 'low'
      case 2:
        return this.selectedLevels[index - 1]?.title || 'medium'
      case 3:
        return this.selectedLevels[index - 1]?.title || 'high'
      default:
        return this.selectedLevels[index - 1]?.title || "";
    }
  }

  selectedLevels = [];
  addTable(index) {
    if (this.data.edit) {
      return;
    }
    this.sensitiveLevel = index;
    const levels = [];
    this.levels.forEach((level, i) => {
      if (i < index) {
        level.checked = true;
      } else {
        level.checked = false;
      }
    });
    for (var i = 1; i <= index; i++) {
      levels.push({
        color: this.getColor(i - 1, index),
        title: this.getTitle(i),
        action: this.selectedLevels[i - 1]?.action || '',
        level: i,
        imageUrl: this.selectedLevels[i - 1]?.imageUrl || '',
      })
    }
    this.selectedLevels = levels;

  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }

  isSeverityLevelsValid() {
    if (this.sensitivity && this.selectedLevels.length && this.selectedLevels.filter(o => !o.title).length) {
      return false;
    }
    return true;
  }

  copyFeature(tag) {
    this.sensitivity = tag.sensitivity;
    this.selectedLevels = tag.levels;
    this.sensitiveLevel = tag.levels.length;
    this.levels.forEach((level, i) => {
      if (i < tag.levels.length) {
        level.checked = true;
      } else {
        level.checked = false;
      }
    });
  }

  index: number;
  changeImage(index) {
    this.isAdditional = false;
    this.index = index;
    document.getElementById('standard_image').click();
  }

  isAdditional: boolean = false;;
  additionalDocument() {
    this.isAdditional = true;
    document.getElementById('standard_image').click();
  }

  public isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  async fileChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    this.isImageUploading$.next(true);
    const ref = this.storage.ref('standardImages').child(uuidv4());
    if (file) {
      await ref.put(file);
      ref.getDownloadURL()
        .subscribe((url) => {
          this.isImageUploading$.next(false);
          if (this.isAdditional) {
            if (this.data.edit) {
              this.data.tag.chart = url;
            }
            this.chart = url;
          } else {
            this.selectedLevels[this.index]['imageUrl'] = url;
          }
        })
    }
  }
}
