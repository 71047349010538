

import { dragula, DragulaService } from 'ng2-dragula';
import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subscription } from 'rxjs';
import { BackendService } from '../services/backend.service';
import { v4 as uuidv4 } from 'uuid';
@Component({
  selector: 'app-solar-csv-columns',
  templateUrl: './solar-csv-columns.component.html',
  styleUrls: ['./solar-csv-columns.component.scss']
})
export class SolarCsvColumnsComponent implements OnInit {
  list1: any = [];
  subs = new Subscription();
  list2: any = [];

  constructor(
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<SolarCsvColumnsComponent>,
    private dragulaService: DragulaService) {
    let lists = [];

    const levels = [];
    levels.push({
      key: 'L1',
      value: data.levels.L1,
      children: [],
      id: uuidv4()
    })

    levels.push({
      key: 'L2',
      value: data.levels.L2,
      children: [],
      id: uuidv4()
    })
    levels.push({
      key: 'L3',
      value: data.levels.L3,
      children: [],
      id: uuidv4()
    })
    levels.push({
      key: 'L4',
      value: data.levels.L4,
      children: [],
      id: uuidv4()
    })
    levels.push({
      key: 'L5',
      value: data.levels.L5,
      children: [],
      id: uuidv4()
    })

    lists.push(
      {
        value: 'Labels',
        key: 'all',
        id: uuidv4(),
        children: levels
      },
      {
        key: 'image_name',
        value: 'Image Name',
        id: uuidv4(),
        children: []
      }, {
      key: 'feature_name',
      value: 'Feature  Name',
      id: uuidv4(),
      children: []
    }, {
      key: 'severity_level',
      value: 'Severity Level',
      id: uuidv4(),
      children: []
    }, {
      key: 'severity_title',
      value: 'Severity Title',
      id: uuidv4(),
      children: []

    }, {
      key: 'severity_notes',
      value: 'Annotations Notes',
      children: []

    }, {
      key: 'annotations_marked',
      value: 'Annotations Marked',
      id: uuidv4(),
      children: []

    },
      {
        key: 'narration',
        value: 'Annotations Narration',
        id: uuidv4(),
        children: []

      }, {
      key: 'link',
      value: 'Link',
      id: uuidv4(),
      children: []
    })

    if (data.projectType === 'thermal') {
      lists.push({
        value: 'All Temperature',
        key: 'all_temperature',
        id: uuidv4(),
        children: [{
          value: 'Hotspots',
          id: uuidv4(),
          key: 'hot_temperature'
        },
        {
          value: 'Coldspots',
          id: uuidv4(),
          key: 'cold_temperature'
        }, {
          value: 'Normal',
          id: uuidv4(),
          key: 'normal_temperature'
        }]
      })
    }

    this.backendService.getProjectFirstImage(data.projectId).subscribe((image: any) => {
      if (image && image.metaData) {
        const metaData = Object.entries(image.metaData);
        const exif = [];
        metaData.forEach(info => {
          exif.push({
            key: info[0],
            id: uuidv4(),
            value: info[0]
          })
        });
        lists.push({
          key: 'metadata',
          id: uuidv4(),
          value: 'Image Exif Info',
          children: exif
        })
      }
      this.list1 = lists;
    });




    //this.lists = data.lists;
    // this.dragulaService.createGroup('groups', {
    //   revertOnSpill: true,
    //   copy: (el, source) => source.id === 'dropItems',
    //   accepts: (el, target, source, sibling) => {
    //     // Ensure that parent items can only be dropped on the parent list
    //     return target.id === 'nestedLists' || target.id === 'dropItems';
    //   },
    //   invalid: function (el, handle) {
    //     return el.tagName === 'UL';
    //   }
    // })
    this.dragulaService.destroy('ul-group');
    this.dragulaService.destroy('child-group');
   
    dragulaService.createGroup('ul-group', {
      moves: (el, container, handle) => {
        // Allow dragging top-level items (ul-group)
        return container.classList.contains('ul-group');
      },
      accepts: (el, target, source, sibling) => {
        // Allow dropping into UL groups only if target is not a child container
        return !target.classList.contains('child-group');
      },
    });

    dragulaService.createGroup('child-group', {
      moves: (el, container, handle) => {
        // Allow dragging child items (child-group)
        return container.classList.contains('child-group');
      },
      accepts: (el, target, source, sibling) => {
        // Allow dropping into child-group only if target is a child container
        return target.classList.contains('child-group');
      },
    });
  



    //  this.dragulaService.destroy('csv_list')
    //  this.dragulaService.createGroup('csv_list', {
    //   revertOnSpill: true
    //  });
  }

  private handleDrop(el: any, target: any, source: any, sibling: any) {
    const parentId = target.id;
    const childId = el.id;

    // Find the parent and child in the source list
    const parentItem = this.findItemById(source.id === 'list1' ? this.list1 : this.list2, parentId);
    const childItem = this.findItemById(parentItem.children, childId);

    // Check if the drop would cause a circular structure
    if (!this.isDescendant(parentItem, childItem) && parentItem.id !== childItem.id) {
      // Remove the child from the source list
      parentItem.children = parentItem.children.filter((child) => child.id !== childId);

      // Add the copied child to the target list
      const targetList = parentId === 'list1' ? this.list1 : this.list2;
      const targetParentItem = this.findItemById(targetList, parentId);
      targetParentItem.children.push({ ...childItem });
    }
  }

  private isDescendant(parent: any, child: any): boolean {
    if (!child) {
      return false;
    }

    if (parent.id === child.id) {
      return true;
    }

    for (const subChild of child.children) {
      if (this.isDescendant(parent, subChild)) {
        return true;
      }
    }

    return false;
  }

  private findItemById(list: any[], id: number | string): any {
    for (const item of list) {
      if (item.id === id) {
        return item;
      }
      if (item.children && item.children.length > 0) {
        const foundChild = this.findItemById(item.children, id);
        if (foundChild) {
          return foundChild;
        }
      }
    }
    return null;
  }
  ngOnInit(): void {

  }


  saveColumns() {
    this.dialogRef.close(this.list2);
  }

  allSelected() {
    //  this.dropItems = this.data.lists;
    //  this.lists = [];
  }

}
