<div class="d-flex h-100 w-100">
  <!-- --------------left menu-------- -->
  <app-asset-sidebar  *ngIf="backendService.currentUserData">
  </app-asset-sidebar>

  <!-- --------------right menu-------- -->
  <div class="w-100 bg">
    <headings class="headings py-2">
      <span class="mr-3 title">Features</span>
      <mat-form-field class="header-project-select" appearance="fill">
        <mat-select [(value)]="selected" (selectionChange)="changeProject($event.value)">
          <mat-option [value]="project.id" *ngFor="let project of projects$ | async">{{ project.name }}</mat-option>
        </mat-select>
      </mat-form-field>
      <!-- <div class="d-flex summary mx-auto">
        <span>
          <mat-icon class="loader" *ngIf="uiService.projectInfo.loading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </mat-icon>
          <button type="button" matTooltip="Feature count"><img src="assets/icons/annotations.svg" class="mr-1">
            <b>{{uiService.projectInfo.annotations}}</b></button>

          <button type="button" matTooltip="Images"><img src="assets/icons/images.svg" class="mr-1">
            <b>{{uiService.projectInfo.images}}</b></button>

          <button type="button" matTooltip="Videos"><img src="assets/icons/video.svg" class="mr-1">
            <b>{{uiService.projectInfo.videos}}</b></button>

          <button type="button" matTooltip="Images with Features"><img src="assets/icons/annotated-images.svg"
              class="mr-1"> <b>{{uiService.projectInfo.annotatedImages}}</b></button>

          <button type="button" matTooltip="Images with no Features"><img src="assets/icons/unannotated-images.svg"
              class="mr-1"><b>{{uiService.projectInfo.unAnnotatedImages}}</b></button>

        </span>

      </div> -->

      <div class="d-flex side-right ml-auto">
        <button (click)="searchToggle()" [class.isActive]="isSearch" mat-raised-button class="mr-2 search">
          <mat-icon>search</mat-icon>
        </button>
        <button mat-raised-button class="header-button mr-2" (click)="openDialog()" [disabled]="!selected || !canEdit">
          <mat-icon class="mr-2" svgIcon="tags"></mat-icon>
          <span class="text-uppercase">Add Features</span>
        </button>
      </div>
    </headings>

    <div class="flex flex-wrap justify-content-center" [style.flexGrow]="!(loading$ | async) ? '1' : '0'">
      <ng-container *ngIf="tags && (projects$ | async)?.length">
        <div class="w-100 tag-scroll mt-3 ml-5 mr-5">
          <input *ngIf="isSearch" (keyup)="getSearch($event)" type="text" class="form-control table-form-control"
            placeholder="Filter by tag name prefix">
          <table class="table">
            <thead>
              <tr>
                <th scope="col" class="text-uppercase">Feature Name</th>
                <th scope="col" class="text-uppercase">Annotations Count</th>
                <th scope="col" class="text-uppercase text-center">Severity</th>
                <th scope="col" class="text-uppercase text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tag of filterTags">
                <td [matTooltip]="tag.description">{{ tag.tag }}
                  <span style="color:#46b3e6;font-weight: normal;" *ngIf="tag.aiTag"> (AI)</span>
                </td>
                <td class="text-capitalize">
                  <!-- <mat-form-field appearance="fill" class="mb-0 w-100 panel-small">
                    <mat-select [value]="tag.status" [disabled]="!canEdit"
                      (selectionChange)="updateTagStatus(tag, $event)">
                      <mat-option *ngFor="let status of statuses" [value]="status">
                        {{status | uppercase}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field> -->
                  {{getFindingCount(tag)}}
                </td>
                <td class="text-center">
                  <div *ngIf="!tag.sensitivity">
                    N/A
                  </div>
                  <div class="d-flex levels" *ngIf="tag.sensitivity">
                    <span [matTooltip]="getToolTip(level)" class="sensitivity-labels" [style.background]="level.color" *ngFor="let level of tag.levels">
                    </span>
                    <button mat-button [disabled]="!canEdit" (click)="editTag(tag)">
                    <mat-icon matTooltip="Edit actions">edit
                    </mat-icon>
                  </button>
                  </div>
                  
                </td>
                <td class="text-center">
                  <button mat-button [disabled]="!canEdit" (click)="removeTag(tag)"
                    style="line-height: 1!important;">
                    <mat-icon style="cursor: pointer" svgIcon="trash" class="icon-display">
                    </mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>

    <ng-template #loading>
      <div class="mt-5 h-100 w-100 flex align-center align-middle">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
    </ng-template>
  </div>
</div>