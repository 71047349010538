<div [class]="data.parentEl=='left' || data.parentEl=='right' ? 'image-modal-popup' : 'image-modal'" #modal>
  <!-- <mat-icon [style.left.px]="
  calculateLeftPosition()" class="edit" (click)="preview(image)">edit
  </mat-icon> -->

  <mat-icon [style.right.px]="
  calculateRightPosition()"
    class="close-button" [ngStyle]="{'color':'#EB5757'}" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="grid-contaner full h-100 w-100">
  <div class="grid-y w-100 h-100 container-box" #container>
    <div class="cell shrink w-100 h-100">
      <div class="cell shrink justify-content-center w-100 h-100">
        <div *ngIf="image?.image" class="justify-content-center carousel-wrap h-100">
          <div class="carousel mt-0 mb-0">
            <div class="carousel__main-container" style="position: relative;overflow: hidden;">
              <!-- context child images -->
              <div class="child-image-wrapper" *ngFor="let area of activeImgAreas; let i = index"
                [style]="getChildImageStyle(area)">
                <ng-container
                  *ngIf="area && area.annotation && area.tag === 'context' && childContextImages.get(area.annotation?.id)?.isShowed">
                  <div class="image-wrapper">
                    <img [src]="childContextImages.get(area.annotation.id)?.thumbFileUrl"
                      [routerLink]="['../', childContextImages.get(area.annotation.id)?.id]">
                    <div class="close" *ngIf="childContextImages.get(area.annotation.id)?.isClicked"
                      (click)="showChildContextImages(area, false)">x</div>
                  </div>
                </ng-container>
              </div>
              <!-- ===== -->
              <!-- <div class="tag-panel" *ngFor="let area of activeImgAreas; let i = index"
                [style]="getAreaTagPanelStyle(area)">
                <div *ngIf="area?.textConfig$.getValue().text !== ''" class="tag-panel-inner">
                  <mat-slider [disabled]="true" *ngIf="getTagSensitive(area)" [style]="getSliderStyle(area)" [max]="2"
                    [min]="0" [step]="1" [thumbLabel]="true" [tickInterval]="1" [value]="+area?.annotation?.sensitive"
                    [ngClass]="getSliderClass(area)" [displayWith]="formatLabel" [vertical]="true">
                  </mat-slider>
                </div>
              </div> -->

              <div class="slide-button-container" *ngFor="let area of activeSensitiveTags(); let i = index"
                [style]="getAreaSeverityIconStyle(area)">
                <p [matTooltip]="getTitle(area)" [style.background]="getBGColor(area)"
                  class="bubble-marker"></p>
              </div>

              <div *ngIf="project?.projectType === 'thermal' && area">
                <div class="label-button-container" 
                  [style]="getAreaTempratureLabelStyle(area)">
                 
                  <div
                  [style.background]="area.annotation.type =='hotspots' ? '#FFF': area.annotation.type =='coldspots' ? '#FFF' : getDetectionColor(area.annotation,true,project)"
                  [style.color]="area.annotation.type =='hotspots' ? 'red': area.annotation.type =='coldspots' ? 'blue' :  getDetectionColor(area.annotation,false,project)"
                  *ngIf="area.annotation?.isHover && area.annotation?.minTemperature && area.annotation?.maxTemperature"
                  class="temp_label">
                  {{area.annotation.minTemperature}} °C - {{area.annotation.maxTemperature}} °C</div>

                <div
                  [style.background]="area.annotation.type =='hotspots' ? '#FFF': area.annotation.type =='coldspots' ? '#FFF' : getDetectionColor(area.annotation,true,project)"
                  [style.color]="area.annotation.type =='hotspots' ? 'red': area.annotation.type =='coldspots' ? 'blue' : getDetectionColor(area.annotation,false,project)"
                  *ngIf="area.annotation?.isHover && area.annotation?.temperature != null" class="temp_label">
                  {{area.annotation.temperature}} °C</div>
                </div>
              </div>
              <ko-stage #stage [config$]="configStage$">
                <ko-layer>
                  <ko-image [config$]="configImg$"></ko-image>
                </ko-layer>
                <ko-layer>
                  <ng-container *ngFor="let area of activeImgAreas; let i = index">
                    <ko-line *ngIf="area.closedType != 'point'"
                    (onmouseover)="hoverChildContextImages(area, true);"
                    (onmouseout)="hoverChildContextImages(area, false);" [config$]="area.config$">
                  </ko-line>
                  <ko-circle *ngIf="area.closedType === 'point'"
                    (onmouseover)="hoverChildContextImages(area, true);"
                    (onmouseout)="hoverChildContextImages(area, false);" [config$]="area.config$">
                  </ko-circle>

                  <ko-group *ngIf="area.tag && area.closedType !== 'point'">
                    <ko-label [config$]="area.label$">
                      <ko-tag [config$]="area.tag$"></ko-tag>
                      <ko-text [config$]="area.textConfig$"></ko-text>
                    </ko-label>
                  </ko-group>

                  </ng-container>
                </ko-layer>
              </ko-stage>

            </div>


          </div>

        </div>
      </div>
    </div>
  </div>