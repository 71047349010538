<div class="popup-header w-100 d-flex p-0">
  <mat-button-toggle-group [(ngModel)]="tabView" class="w-100">
    <mat-button-toggle class="px-4 py-2" [class.btn_active]="tabView === 'scope'" value="scope">
      Project Scope
    </mat-button-toggle>
    <mat-button-toggle class="px-4 py-2" [class.btn_active]="tabView === 'certificate'" value="certificate">
      Certificate
    </mat-button-toggle>
    <mat-button-toggle class="px-4 py-2" [class.btn_active]="tabView === 'documents'" value="documents">
      Other Documents
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<!-- 
'Underline', 'NumberedList','BulletedList','BlockQuote','Undo','Redo' -->
<div [hidden]="tabView != 'scope'">
  <app-ckeditor
  [config]="{ toolbar: ['bold', 'italic','Underline','NumberedList','BulletedList', 'BlockQuote','Undo','Redo'] }"
    placeholder="scope" [(ngModel)]="scope"></app-ckeditor>
  <div mat-dialog-actions>
    <mat-spinner-button [options]="createBtnOptions" (btnClick)="scopeUpdate(scope)" [disabled]="!scope">
    </mat-spinner-button>
    <button mat-button class="ml-2" (click)="close()">Cancel</button>
  </div>
</div>
<div class="model" *ngIf="tabView === 'certificate'">
  <mat-progress-bar *ngIf="progressBar" class="mt-2 mb-1 ml-1 mr-1 progressBar" #uploadProgressBar mode="determinate"
    [value]="progressBar">
  </mat-progress-bar>
  <div class="d-flex">
    <button mat-button class="d-flex mb-2 mt-2 upload-btn" [disabled]="isImageUploading$.value" (click)="handleAddCertificates()">
      <span *ngIf="isImageUploading$ | async">
        <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
      </span>
      <span class="material-icons">file_upload</span>
      Upload Certificate (Only Images)
    </button>

    <div *ngIf="certificates.length" style="color:#222 !important"
      class="d-flex side-right d-flex side-right mt-2 m-1 ml-auto">
      <span class="mr-1">Include in report</span>
      <input (change)="includeToReport($event.target.checked,'certificates')" [(ngModel)]="data.project.certificates" type="checkbox">
    </div>

  </div>

  <table class="table-doc table shadow-none table-striped">
    <thead>
      <tr>
        <th width="40%" style="width: 40%;">
          File name
        </th>
        <th width="40%" style="width: 40%;">
          Document name
        </th>
        <th width="20%" style="width: 20%;">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of  certificates">
        <td class="link_name" style="width: 40%;">
          <!-- <a *ngIf="document.docxURl" matTooltip="Open Preview"
            [href]="('https://docs.google.com/viewer?url='+ encode(document?.url) +'&embedded=true') | safeUrl"
            target="_blank">
            {{document.fileName}}
          </a> -->
          <a matTooltip="Open Preview" href="{{document?.url}}" target="_blank">
            {{document.fileName}}
          </a>
        </td>
        <td style="width: 40%;">
          {{document.documentName}}
        </td>
        <td style="width: 20%;">
          <mat-icon style="cursor:pointer" (click)="delete(document.id)">delete_outline</mat-icon>
        </td>
      </tr>
    </tbody>


  </table>
</div>

<div class="model" *ngIf="tabView === 'documents'">
  <mat-progress-bar *ngIf="progressBar" class="mt-2 mb-1 ml-1 mr-1 progressBar" #uploadProgressBar mode="determinate"
    [value]="progressBar">
  </mat-progress-bar>
  <div class="d-flex">
    <button mat-button class="d-flex mb-2 mt-2 upload-btn" [disabled]="isImageUploading$.value" (click)="handleAdd()">
      <span class="material-icons">file_upload</span>
      <span *ngIf="isImageUploading$ | async">
        <mat-spinner class="spinner" [diameter]="20"></mat-spinner>
      </span>
      Upload Documents

    </button>
    <div *ngIf="documents.length" style="color:#222 !important"
      class="d-flex side-right d-flex side-right mt-2 m-1 ml-auto">
      <span class="mr-1">Include in report</span>
      <input (change)="includeToReport($event.target.checked,'documents')" [(ngModel)]="data.project.documents" type="checkbox">
    </div>

  </div>

  <table class="table-doc table shadow-none table-striped">
    <thead>
      <tr>
        <th width="40%" style="width: 40%;">
          File name
        </th>
        <th width="40%" style="width: 40%;">
          Document name
        </th>
        <th width="20%" style="width: 20%;">&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let document of  documents">
        <td class="link_name" style="width: 40%;">
          <!-- <a *ngIf="document.docxURl" matTooltip="Open Preview"
            [href]="('https://docs.google.com/viewer?url='+ encode(document?.url) +'&embedded=true') | safeUrl"
            target="_blank">
            {{document.fileName}}
          </a> -->
          <a matTooltip="Open Preview" href="{{document?.url}}" target="_blank">
            {{document.fileName}}
          </a>
        </td>
        <td style="width: 40%;">
          {{document.documentName}}
        </td>
        <td style="width: 20%;">
          <mat-icon style="cursor:pointer" (click)="delete(document.id)">delete_outline</mat-icon>
        </td>
      </tr>

    </tbody>

  </table>
</div>

<input type="file" accept="application/pdf, image/*" (click)="$event.target.value = null" (change)="fileChoose($event)"
  hidden id="documents" />

  <input type="file" accept="image/*" (click)="$event.target.value = null" (change)="fileChoose($event)"
  hidden id="certificates" />

<ng-template #documentDialog let-data>
  <div class="popup-header w-100 d-flex">
    <p class="w-100 d-flex">
      <span>Document Name for <b>{{data.fileName}}</b></span>
    </p>
  </div>
  <mat-dialog-content>
    <div>
      <mat-form-field class="w-100 mb-2" appearance="fill">
        <input matInput cdkFocusInitial [(ngModel)]="data.documentName" placeholder="Document Name"
          autocomplete="false" />
      </mat-form-field>

    </div>
  </mat-dialog-content>
  <div mat-dialog-actions>


    <div class="w-100  align-items-center mt-3 mr-2">
      <button color="primary" [disabled]="!data.documentName" mat-raised-button (click)="saveDocument(data)">
        Save
      </button>
    </div>
  </div>
</ng-template>