<div class="popup-header d-flex">
  <p class="w-100">
    Daily Reports
  </p>
  <!-- <button matTooltip="Create" (click)="newReport()" mat-raised-button  color="primary" class="dark">
        New Report
    </button> -->
  <button [disabled]= "dailyReportAccess()" matTooltip="Create" class="mr-2 header-button" mat-raised-button (click)="newReport()">
    <mat-icon class="mr-1">add_circle_outline</mat-icon>
    <span class="text-uppercase">New Report</span>
  </button>

  <mat-icon class="close-btn copy" (click)="dialogRef.close()">close</mat-icon>
</div>

<div mat-dialog-content>
  <table matSort class="table w-100">
    <thead>
      <tr>
        <th scope="col" class="text-uppercase table-header">Report #</th>
        <th scope="col" class="text-uppercase table-header">Report Date</th>
        <th class="text-center">Status</th>
        <th scope="col" class="text-uppercase table-header">Action</th>
      <th class="text-right"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of dailyReports">
        <td>
          {{report.reportNumber}}
        </td>
        <td>
          {{report.reportDate}}
        </td>
      
        <td class="text-center">
          <ng-container *ngIf="report?.reportLocation?.status">
             {{report?.reportLocation?.status}}
            </ng-container>
            <ng-container *ngIf="!report?.reportLocation?.status">
              &#8211;
            </ng-container>
        </td>
     
        <td>
  
        <div *ngIf="report?.reportLocation?.pdf" matTooltip="Download PDF" matTooltipClass="reports-tooltip"
          class="doc-icon icon-tr" 
          (click)="downloadPdf(report?.reportLocation?.pdf,report)">
          <svg width="18" height="23" viewBox="41 0 1 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M49.1266 4.96094L45.5602 1.35156C45.1734 0.964844 44.6148 0.75 44.0992 0.75H35.3336C34.1734 0.75 33.2711 1.69531 33.2711 2.8125V20.6875C33.2711 21.8477 34.1734 22.75 35.3336 22.75H47.7086C48.8258 22.75 49.7711 21.8477 49.7711 20.6875V6.42188C49.7711 5.90625 49.5133 5.34766 49.1266 4.96094ZM47.5367 6.25H44.2711V2.98438L47.5367 6.25ZM35.3336 20.6875V2.8125H42.2086V7.28125C42.2086 7.88281 42.6383 8.3125 43.2398 8.3125H47.7086V20.6875H35.3336ZM46.0758 14.543C45.5602 14.0273 44.0563 14.1562 43.2828 14.2422C42.5523 13.8125 42.0797 13.168 41.7359 12.2656C41.8648 11.5781 42.1656 10.5039 41.9508 9.85938C41.7789 8.74219 40.318 8.82812 40.1031 9.60156C39.9313 10.2891 40.1031 11.2773 40.4039 12.4805C39.9742 13.5117 39.3297 14.8867 38.9 15.7031C38.0406 16.1328 36.8805 16.8203 36.7086 17.6797C36.5797 18.3672 37.8258 20.043 39.9742 16.3477C40.9195 16.0039 41.9938 15.6172 42.8961 15.4453C43.7125 15.918 44.6578 16.1758 45.3023 16.1758C46.4195 16.1758 46.5055 14.9727 46.0758 14.543ZM37.568 17.8945C37.7828 17.293 38.5992 16.6055 38.857 16.3906C38.0406 17.6797 37.568 17.8945 37.568 17.8945ZM41.0484 9.6875C41.3922 9.6875 41.3492 11.0625 41.1344 11.4492C40.9625 10.8477 40.9625 9.6875 41.0484 9.6875ZM40.0172 15.5742C40.4469 14.8438 40.7906 13.9844 41.0914 13.2109C41.4352 13.8555 41.8648 14.3711 42.3805 14.7148C41.4781 14.9297 40.7047 15.2734 40.0172 15.5742ZM45.6461 15.3594C45.6461 15.3594 45.4313 15.6172 44.0563 15.0156C45.5602 14.8867 45.818 15.2305 45.6461 15.3594Z"
              fill="#F82015" />
          </svg>

        </div>
      
<!-- 
        [style.color]="report.reportLocation?.pdf?'#2f752f':'orange'" -->
        <div *ngIf="report?.reportLocation?.pdf" class="report-email-icon icon-tr">
          <mat-icon matTooltip="Mail"
            matTooltipClass="reports-tooltip" (click)="emailReport(report)">email</mat-icon>
        </div>
          <div *ngIf="showReportIcon(report)" (click)="editReport(report)" matTooltip="Edit"
            matTooltipClass="reports-tooltip" class="icon-edit  icon-tr">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M17.2761 2.85156L15.3776 0.953125C14.4636 0.0390625 12.9518 0.0390625 12.0378 0.953125L0.858094 12.0625L0.0143443 17.3008C-0.0911244 17.8633 0.401063 18.3555 0.963563 18.25L6.20184 17.4062L17.3112 6.22656C18.2253 5.3125 18.2253 3.80078 17.2761 2.85156ZM4.23309 10.8672L10.2097 4.92578L13.3386 8.05469L7.39716 14.0312V12.5547H5.70966V10.8672H4.23309ZM2.89716 16.3867L1.87763 15.3672L2.33466 12.8008L2.96747 12.1328H4.44403V13.8203H6.13153V15.2969L5.46356 15.9297L2.89716 16.3867ZM16.2214 5.13672L14.4987 6.85938L11.405 3.76562L13.1276 2.04297C13.444 1.72656 13.9714 1.72656 14.2878 2.04297L16.1862 3.94141C16.5378 4.29297 16.5378 4.82031 16.2214 5.13672Z"
                fill="#219653" />
            </svg>
          </div> 
          <mat-icon matTooltip="View" class="mat-icon_size-18 icon-tr" (click)="reportView(report)">remove_red_eye
          </mat-icon>
          <img *ngIf="!report?.sendForReview" matTooltip="Send for review" class="ml-2 mb-2" (click)="sendForReview(report)" src="assets/daily-report-blue.png" style="height: 20px;">
         
        </td>
        <td>
          <mat-icon *ngIf="showReportIcon(report)" (click)="deleteReport(report)" matTooltip="Delete"
          matTooltipClass="reports-tooltip" class="mat-icon_size-18 icon-tr delete-right" svgIcon="trash"></mat-icon>
        </td>
      </tr>
     

    </tbody>
  </table>
</div>