<div class="popup-header d-flex">
  <p class="w-100">
    Add members
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div>
  <div mat-dialog-content>
    <form class="d-flex flex-column justify-content-between" [formGroup]="form" (submit)="submit()">
      <div class="form-field w-100 mb-3">
        <label for="input-email">Invite</label>
        <input id="input-email" type="email" maxLength="64" required formControlName="email"
          (input)="this.isEmailExist = false"
          [class.is-invalid]="form.get('email').invalid && form.get('email').touched" class="form-control input-field"
          placeholder="Email" name="email" [(ngModel)]="email" />
      </div>
      <div class="d-flex align-items-center">
        <div class="form-field mr-4">
          <label for="input-role">Role</label>
          <br>
          <mat-form-field  appearance="fill" id="input-role" style="padding: 0; margin-bottom: 16px;width:180px">
            <mat-select (selectionChange)="toggleReadOnly($event)" [(value)]="selectedRole">
              <mat-option [value]="'editor'">Editor</mat-option>
              <mat-option [value]="'reviewer'">Reviewer</mat-option>
              <mat-option [value]="'approver'">Approver</mat-option>
              <mat-option [value]="'viewer'">Viewer</mat-option>
              <!-- <mat-option *ngIf="
                getRoleForUser(currentUserId) === 'owner' ||
                getRoleForUser(currentUserId) === 'project manager'
              " [value]="'project manager'">Project manager</mat-option>
              <mat-option *ngIf=" 
                getRoleForUser(currentUserId) === 'owner' ||
                getRoleForUser(currentUserId) === 'project manager'
                " [value]="'project member'">Project member</mat-option>
              <mat-option *ngIf="
                getRoleForUser(currentUserId) !== 'customer manager' &&
                getRoleForUser(currentUserId) !== 'customer manager'
              " [value]="'customer manager'">Customer manager</mat-option>
              <mat-option [value]="'customer member'">Customer member</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
        <mat-checkbox formControlName="readOnly" class="mr-4 mt-4">Read only</mat-checkbox>

        <button mat-raised-button type="submit" color="primary" class="submit-button mt-3"
          [disabled]="form.get('email').invalid && form.get('email').touched">
          <span class="text-uppercase">Invite</span>
        </button>
      </div>
    </form>
    <span class="ml-2" *ngIf="isEmailExist" style="color: darkred">This email has already been invited.</span>
   
  </div>

  <div style="max-height: 300px" class="cell auto scroller">
    <table class="table table-curved shadow-none">
      <thead >
        <tr>
          <th scope="col" class="text-uppercase">Invited members</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let member of members">
          <td>{{ member.email }}</td>
          <td class="text-capitalize">
            {{getRoleForUser(member)}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>