import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { BackendService } from 'src/app/services/backend.service';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
@Component({
  selector: 'app-report-document-dialog',
  templateUrl: './report-document-dialog.component.html',
  styleUrls: ['./report-document-dialog.component.scss'],
})
export class ReportDocumentDialogComponent implements OnInit, OnDestroy {
  src: string;
  isPanelShow: boolean = true;
  public roles = [];
  public members = [];
  public editors = [];
  public reviewers = [];
  public approvers = [];
  report: any
  currentUserId = this.backendService.getCurrentUser().uid
  public memberSubscription = new Subscription();
  public reportSubscription = new Subscription();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ReportDocumentDialogComponent>,
    public backendService: BackendService
  ) {

  }

  ngOnInit() {
    if (this.data.report) {
      this.src = this.data.report.reportLocation?.pdf;
      this.getMembers();
      this.reportSubscription = this.backendService.getReportById(this.data.report._id).subscribe(report => {
        this.report = report;
      }, error => {
        console.error(error)
      });

    }
  }

  getMembers(): any {
    this.memberSubscription.unsubscribe();
    this.memberSubscription = this.backendService.getProject(this.data.projectId).subscribe((membersDoc) => {
      if (!membersDoc) return of();
      return combineLatest(
        this.backendService.getUsers(Object.keys(membersDoc.people || {})),
        // this.backendService.getProspective(this.data.projectId)
      ).subscribe(([members]) => {
        this.members = members;
        this.roles = Object.keys(membersDoc.people || {}).map(person => {
          return {
            id: person,
            role: membersDoc.people[person].role,
          };
        });
        this.editors = this.roles.filter(o => o.role === 'editor');
        this.reviewers = this.roles.filter(o => o.role === 'reviewer');
        this.approvers = this.roles.filter(o => o.role === 'approver');

      }, error => {
      });
    });
  }

  getUserName(user): any {
    const member = this.members.find(o => o._id == user.id);
    return member ? member.displayName ? `${member.displayName}` : `${member.email}`:''
  }

  ngOnDestroy() {
    if (this.memberSubscription) {
      this.memberSubscription.unsubscribe();
    }
    if (this.reportSubscription) {
      this.reportSubscription.unsubscribe();
    }
  }

  toggleLeftPanel() {
    this.isPanelShow = !this.isPanelShow;
  }

  checkReviewerStatus(userId) {
    if (this.report && this.report.reviewer) {
      if (this.report.reviewer.find(o => o.id == userId)) {
        return false;
      }
    }
    return true;
  }

  checkApproverStatus(userId) {
    if (this.report && this.report.approver) {
      if (this.report.approver.find(o => o.id == userId)) {
        return false;
      }
    }
    return true;
  }

  getReviewedStatus(userId) {
    if (this.currentUserId == userId && !this.checkReviewerStatus(userId)) {
      return 'Reviewed';
    }
  }

  allReviewedCompleted() {
    let reviewCompleted: boolean = true;
    if (this.report && this.reviewers.length) {
      if (!this.report.reviewer) {
      return false;
      }
      this.reviewers.forEach(reviewer => {
        if (!this.report.reviewer.find(o => o.id == reviewer.id)) {
          reviewCompleted = false;
        }
      });
    }
    return reviewCompleted;
  }

  marked(userId, type) {
    this.backendService.reportReviewed(this.report._id, type, userId).subscribe();
  }

}
