import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterContentInit,
  ContentChildren,
  QueryList,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import Konva from 'konva';
import { getName, createListener, updateProps, redraw } from '../utils/index';
import { KonvaComponent } from '../ko.interface';

@Component({
  // tslint:disable-next-line: component-selector
  selector: `ko-shape, ko-layer, ko-circle, ko-fastlayer, ko-group, ko-label, ko-rect, ko-ellipse, ko-wedge, ko-line, ko-sprite, ko-image, ko-text, ko-text-path, ko-star, ko-ring, ko-arc, ko-tag, ko-path, ko-regular-polygon, ko-arrow`,
  template: ` <ng-content></ng-content> `,
})
export class CoreShapeComponent
  implements KonvaComponent, AfterContentInit, OnDestroy, OnInit {
  @ContentChildren(CoreShapeComponent)
  shapes = new QueryList<CoreShapeComponent>();

  @Input() config$: BehaviorSubject<any>;
  @Output() onclick: EventEmitter<any> = new EventEmitter();
  @Output() ondblclick: EventEmitter<any> = new EventEmitter();
  @Output() onmouseover: EventEmitter<any> = new EventEmitter();
  @Output() onmouseout: EventEmitter<any> = new EventEmitter();
  @Output() onmousemove: EventEmitter<any> = new EventEmitter();
  @Output() onmouseup: EventEmitter<any> = new EventEmitter();
  @Output() onmousedown: EventEmitter<any> = new EventEmitter();
  @Output() ontap: EventEmitter<any> = new EventEmitter();
  @Output() ondbltap: EventEmitter<any> = new EventEmitter();
  @Output() ontouchstart: EventEmitter<any> = new EventEmitter();
  @Output() onscaleXChange: EventEmitter<any> = new EventEmitter();
  @Output() onfillChange: EventEmitter<any> = new EventEmitter();
  @Output() ondragstart: EventEmitter<any> = new EventEmitter();
  @Output() ondragmove: EventEmitter<any> = new EventEmitter();
  @Output() ondragend: EventEmitter<any> = new EventEmitter();
  @Output() oncontextmenu: EventEmitter<any> = new EventEmitter();
  @Output() touchmove: EventEmitter<any> = new EventEmitter();

  public nameNode: string;
  public added = false;

  private cacheProps: any = {};
  private _stage: any = {};
  private _config;
  private _subscription: Subscription;

  public getStage() {
    return this._stage;
  }

  public getConfig() {
    return this._config || {};
  }

  public reset() {
    // do nothing
  }

  public getScale() {
    return this._stage.scaleX();
  }

  constructor(private elementRef: ElementRef) {
    this.nameNode = getName(elementRef.nativeElement.localName);
  }

  ngOnInit() {
    this.initKonva();
  }

  private initKonva() {
    const nodesWithNoConfig = ['Layer', 'FastLayer', 'Group'];
    const nodeHasConfigObservable = this.config$ && this.config$.subscribe;
    const nodeRequiresConfig = !nodesWithNoConfig.includes(this.nameNode);

    this._stage = new Konva[this.nameNode]();

    if (nodeRequiresConfig && !nodeHasConfigObservable) {
      console.warn(
        `You must provide config$ of type BehaviorSubject to the ${this.nameNode}`
      );
    }

    if (nodeHasConfigObservable) {
      this.updateConfig(this.config$.getValue());
      this._subscription = this.config$.subscribe((c) => this.updateConfig(c));
    }
  }

  private updateConfig(config) {
    this._config = config;
    this.updateShape(config);
  }

  private updateShape(config) {
    const props = {
      ...config,
      ...createListener(this),
    };

    updateProps(this, props, this.cacheProps);

    this.cacheProps = props;
  }

  private renderChildren() {
    if (!this._stage) { return; }

    this.shapes.forEach((item: CoreShapeComponent) => {
      if (item !== this && item.getStage && !item.added) {
        item.added = true;
        this._stage.add(item.getStage());
      }
    });

    redraw(this._stage);
  }

  ngAfterContentInit() {
    this.renderChildren();
    this.shapes.changes.subscribe(this.renderChildren.bind(this));
  }

  ngOnDestroy() {
    if (this._stage) { this._stage.destroy(); }
    if (this._subscription) { this._subscription.unsubscribe(); }
  }
}
