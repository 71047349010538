import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'app-project-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class ProjectAddGroupComponent implements OnInit {
  public name: string;
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Create',
    raised: true,
    spinnerSize: 24,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    customClass: 'text-uppercase'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backend: BackendService,
    public dialogRef: MatDialogRef<ProjectAddGroupComponent>
  ) {}

  ngOnInit(): void {
  }

  isNameValid(name): boolean {
    return this.data.groups.indexOf(name) === -1;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  handleCreate(name): void {
    if (name && this.isNameValid(name)) {
      this.createBtnOptions.active = true;
      // this.backend.createFolder(this.data.doc.path, name).then((docRef) => {
      //   console.log('Document written with ID: ', docRef.path);
      //   this.createBtnOptions.active = false;
      //   this.dialogRef.close({success: true, path: docRef.path, name});
      // });
      this.backend
        .addImageGroup(name, this.data.projectId)
        .subscribe((result) => this.dialogRef.close());
    }
  }
}
