<div class="popup-header w-100 d-flex">
    <p class="w-100">
        Select color palette
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<span class="m-1" style="font-size: 12px;">
    Choose color palette to convert your gray scale images to color
</span>
<br>
<span class="ml-2" *ngIf='uiService.project.colorPalette'>
    Current Palette: {{uiService.project.colorPalette}}
</span>
<div class="table-scroll">
    <table class="table m-3">
        <tbody>
            <!-- <tr (click)="palette('COLORMAP_AUTUMN')">
                <td>AUTUMN</td>
                <td><img src="assets/colors/colorscale_autumn.jpg"></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_BONE')">
                <td>BONE</td>
                <td><img src="assets/colors/colorscale_bone.jpg"></td>
            </tr> -->
            <tr [class.activate]="uiService.project?.colorPalette === 'COLORMAP_JET'" (click)="palette('COLORMAP_JET')">
                <td>JET</td>
                <td><img src="assets/colors/colorscale_jet.jpg"></td>
            </tr>
            <!-- <tr (click)="palette('COLORMAP_WINTER')">
                <td>WINTER</td>
                <td><img src="assets/colors/colorscale_winter.jpg" ></td>
            </tr> -->
            <tr [class.activate]="uiService.project?.colorPalette === 'COLORMAP_RAINBOW'" (click)="palette('COLORMAP_RAINBOW')">
                <td>RAINBOW</td>
                <td><img src="assets/colors/colorscale_rainbow.jpg"></td>
            </tr>
            <!-- <tr  (click)="palette('COLORMAP_OCEAN')">
                <td>OCEAN</td>
                <td><img src="assets/colors/colorscale_ocean.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_SUMMER')">
                <td>SUMMER</td>
                <td><img src="assets/colors/colorscale_summer.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_SPRING')">
                <td>SPRING</td>
                <td><img src="assets/colors/colorscale_spring.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_COOL')">
                <td>COOL</td>
                <td><img src="assets/colors/colorscale_cool.jpg" ></td>
            </tr> -->
            <tr [class.activate]="uiService.project?.colorPalette === 'COLORMAP_HSV'" (click)="palette('COLORMAP_HSV')">
                <td>HSV</td>
                <td><img src="assets/colors/colorscale_hsv.jpg"></td>
            </tr>
            <!-- <tr (click)="palette('COLORMAP_PINK')">
                <td>PINK</td>
                <td><img src="assets/colors/colorscale_pink.jpg" ></td>
            </tr> -->
            <tr  [class.activate]="uiService.project?.colorPalette === 'COLORMAP_HOT'"(click)="palette('COLORMAP_HOT')">
                <td>HOT</td>
                <td><img src="assets/colors/colorscale_hot.jpg"></td>
            </tr>
            <!-- <tr (click)="palette('COLORMAP_PARULA')">
                <td>PARULA </td>
                <td><img src="assets/colors/colorscale_parula.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_MAGMA')">
                <td>MAGMA  </td>
                <td><img src="assets/colors/colorscale_magma.jpg" ></td>
            </tr> -->
            <tr [class.activate]="uiService.project?.colorPalette === 'COLORMAP_INFERNO'" (click)="palette('COLORMAP_INFERNO')">
                <td>INFERNO </td>
                <td><img src="assets/colors/colorscale_inferno.jpg"></td>
            </tr>
            <!-- <tr (click)="palette('COLORMAP_PLASMA')">
                <td>PLASMA </td>
                <td><img src="assets/colors/colorscale_plasma.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_VIRIDIS')">
                <td>VIRIDIS </td>
                <td><img src="assets/colors/colorscale_viridis.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_CIVIDIS')">
                <td>CIVIDIS</td>
                <td><img src="assets/colors/colorscale_cividis.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_TWILIGHT')">
                <td>TWILIGHT </td>
                <td><img src="assets/colors/colorscale_twilight.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_TWILIGHT_SHIFTED')">
                <td>TWILIGHT SHIFTED </td>
                <td><img src="assets/colors/colorscale_twilight_shifted.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_TURBO')">
                <td> TURBO </td>
                <td><img src="assets/colors/colorscale_turbo.jpg" ></td>
            </tr> -->
            <!-- <tr (click)="palette('COLORMAP_DEEPGREEN')">
                <td>DEEPGREEN  </td>
                <td><img src="assets/colors/colorscale_deepgreen.jpg" ></td>
            </tr> -->
        </tbody>
    </table>
    <span class="p-2 note">
        This process will take 20 mins - 30 mins to generate the color palette images
    </span>
</div>