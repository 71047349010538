export interface IFile {
    name: string;
    link: string;
    file: File;
}

export interface IInitialsData {
    id?: string;
    projectName?: string;
    contractNumber: string;
    projectNumber: string;
    documentNumber: string;
    author: string;
    date: string;
    revision: string;
    approver: string;
    checker: string;
    clientLogo: IFile;
    yourLogo: IFile;
    isUpdate?: boolean;

}

export class InitialsDataModel implements IInitialsData {
    projectName?: string;
    contractNumber: string;
    projectNumber: string;
    documentNumber: string;
    author: string;
    date: string;
    revision: string;
    approver: string;
    checker: string;
    clientLogo: IFile;
    yourLogo: IFile;
    isAssetInfo: boolean;
    isContactInfo: boolean;
    isCompanyInfo: boolean;
    constructor(
        projectName?: string,
        contractNumber?: string,
        projectNumber?: string,
        documentNumber?: string,
        author?: string,
        date?: string,
        revision?: string,
        approver?: string,
        checker?: string,
        clientLogo?: IFile,
        yourLogo?: IFile,
        isAssetInfo?: boolean,
        isContactInfo?: boolean,
        isCompanyInfo?: boolean,
    ) {
        this.projectName = projectName || '';
        this.contractNumber = contractNumber || '';
        this.projectNumber = projectNumber || '';
        this.documentNumber = documentNumber || '';
        this.author = author || '';
        this.date = date || '';
        this.revision = revision || '';
        this.approver = approver || '';
        this.checker = checker || '';
        this.clientLogo = clientLogo || { name: '', link: '', file: null };
        this.yourLogo = yourLogo || { name: '', link: '', file: null };
        this.isAssetInfo = isAssetInfo || false;
        this.isContactInfo = isContactInfo ||  false;
        this.isCompanyInfo = isCompanyInfo || false;

    }

    get(): IInitialsData {
        return {
            projectName: this.projectName,
            contractNumber: this.contractNumber,
            projectNumber: this.projectNumber,
            documentNumber: this.documentNumber,
            author: this.author,
            date: this.date,
            revision: this.revision,
            approver: this.approver,
            checker: this.checker,
            clientLogo: this.clientLogo,
            yourLogo: this.yourLogo,
        };
    }
}