import { Component, Input, ViewChild, Inject, OnDestroy, OnInit, Output, EventEmitter, ElementRef } from '@angular/core';
import * as THREE from 'three'
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkImagesComponent } from '../link-images/link-images.component';
import { UiService } from '../services/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { ToastrService } from 'ngx-toastr';
import { AngularFireStorage } from '@angular/fire/storage';
import { GeneralService } from '../services/general.service';
import jsZip from 'jszip';
import { Viewer } from '../Three/viewer';
import { environment } from 'src/environments/environment';
import { GENERAL, InspectionType, Status, TilesMessage } from '../models/app.enum';
import * as moment from 'moment';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { share, take, takeUntil } from 'rxjs/operators';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NgZone } from '@angular/core';
import { LogFileComponent } from '../log-file/log-file.component';
import { AlignModelsComponent } from '../align-models/align-models.component';
import  * as TWEEN from '@tweenjs/tween.js'
import { CesiumViewer } from 'src/app/Cesium/viewer';
declare var window: any;
declare var Cesium: any


@Component({
  selector: 'app-base-model',
  templateUrl: './base-model.component.html',
  styleUrls: ['./base-model.component.scss']
})
export class BaseModelComponent implements OnInit, OnDestroy {
  @Input() public assetId;
  @Input() public asset;
  @Input() public dialogRef;
  @Input() public isAssetOwner;
  @Input() public projects;
  @Input() public type = 'asset';
  @Input() public selectedProjectId;
  @Input() public baselineModel = false;
  is3DPanelExpand: boolean = false;


  @ViewChild('deleteLabelDialog') deleteLabelDialog: any;
  deleteLabelDialogRef;
  @ViewChild('createLabelDialog') createLabelDialog: any;
  createLabelDialogRef;
  @ViewChild('progressBar') progressBar: any;
  @ViewChild('uploadProgressBar') uploadProgressBar: any;
  @ViewChild('modelPanel') baseModelPanel: ElementRef;
  public isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  labelsShow: boolean = true;
  //toolBox: string = "";
  controlView: string = "orbit";
  cropControl: string = "";
  baseFile: boolean = false;
  isLoading: boolean = false;
  isProcessing: boolean = false;
  annotations = [];
  linkImages = [];
  progress: number = 0;
  centerPosition = {
    x: 0, y: 0, z: 0
  };
  isCesiumViewer: boolean = false;
  private onDestroy$ = new Subject();
  linkImageSubscription: any = new Subject();

  public isLeftMenuCollpase: boolean = false;
  annotationMarkers = [];
  projectId;
  measurements;
  projectImages = [];
  isHint: boolean = true;
  modelSize = environment.modelSize;
  public get tilesSuccess() {
    return Status.SUCCESS;
  }
  public get tilesFailed() {
    return Status.FAILED;
  }
  public get tilesProcess() {
    return Status.PROCESSING;
  }
  public get tilesQueue() {
    return Status.QUEUED;
  }

  public get processMessage() {
    return TilesMessage.PROCESS;
  }
  public get failedMessage() {
    return TilesMessage.FAILED;
  }

  public get project() {
    return this.uiService.project;
  }

  public get uploadMessage() {
    return TilesMessage.LOADING;
  }
  public get largeFile() {
    return TilesMessage.LARGE_FILE;
  }

  public get toolBox() {
    return this.viewer?.toolBox;
  }

  public set toolBox(value) {
    this.viewer.toolBox = value;
  }
  public get FORSSEA() {
    return InspectionType.FORSSEA;
  }
  public tags = [];

  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };
  sourceType:string = "3D_MODEL";
  sources =[{
      id:"3D_CAPTURE",
      label:"3D Capture/Photogrammetry/Reality Model"
    },
    {
      id:"3D_MODEL",
      label:"3D model"
    }
  ]
  constructor(
    private dialog: MatDialog,
    public uiService: UiService,
    public backendService: BackendService,
    private activatedRoute: ActivatedRoute,
    private toaster: ToastrService,
    private router: Router,
    private generalService: GeneralService,
    private storage: AngularFireStorage,
    private confirmDialog: WaMatConfirmDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private zone: NgZone

  ) {
    this.matIconRegistry.addSvgIcon(
      `polygon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/polygon.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      `cube`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/cube.svg'
      )
    );
  }

  ngOnInit(): void {
    window.addEventListener('contextmenu', function (e) {
      e.preventDefault(); // Prevent the default context menu
    });
    if (this.selectedProjectId) {
      this.projectId = this.selectedProjectId;
      if (this.type === "project") {
        this.getProjectImages(this.projectId);
      }
    }
    if (!this.is3DPanelExpand && this.type === 'asset') {
      this.toggle3DPanelView();
    }
    this.getModels();
    this.getTags();
  }

  getProjectImages(id) {
    if (!id) { return }
    const baseLineProject = this.projects.find(o => o.id == this.projectId);

    if (!baseLineProject) {
      console.info("Dont have project access")
      return
    }
    this.backendService.getProjectImages(id).subscribe((images: any) => {
      images.docs.forEach(image => {
        this.projectImages.push({
          id: image.id,
          ...image.data()
        })
      });
    });
  }
  getTags() {
    this.backendService.getTags(this.projectId).pipe(takeUntil(this.onDestroy$)).subscribe(({ tags }) => {
      this.tags = [
        { tag: 'All' },
        { tag: 'Untagged' },
        { tag: 'Unprocessed' },
        ...tags,
      ];
    });
  }

  filterImages() {
    const labels = this.annotations.filter(o => o.checked);
    this.dialogRef.close({
      modelId: this.model.id,
      labels: labels
    })
  }
  getSelectedLabels() {
    if (this.annotations && this.annotations.filter(o => o.checked).length > 0) {
      return true;
    }
    return false;
  }

  /**
  * Sets up the view manager.
  * @return {Viewer}
  */
  viewer;
  createViewer(model) {
    const viewerEl: any = this.baseModelPanel.nativeElement;
    viewerEl.width = (window.innerWidth / (!this.is3DPanelExpand ? 2 : 1));
    viewerEl.height = window.innerHeight - this.reduceContentHeight();
    this.viewer = new Viewer(viewerEl, { modelOptions: model.modelOptions || null });
    return this.viewer;
  }

  createCesiumViewer(model) {
    const viewerEl: any = this.baseModelPanel.nativeElement;
    viewerEl.width = viewerEl.style.width = (window.innerWidth / (!this.is3DPanelExpand ? 2 : 1)) - 10;
    viewerEl.height = viewerEl.style.height = window.innerHeight - this.reduceContentHeight();
    this.viewer = new CesiumViewer(viewerEl, { modelOptions: model.modelOptions || null, id: this.baseModelPanel.nativeElement }, this.zone);
    return this.viewer;
  }

  rcData = []
  csvFileToJSON(url) {
    try {
      this.backendService.getFile(url).subscribe((result: any) => {
        var jsonData = [];
        var headers = [];
        var rows = result.split("\r\n");
        for (var i = 0; i < rows.length; i++) {
          var cells = rows[i].split(",");
          var rowData = {};
          for (var j = 0; j < cells.length; j++) {
            if (i == 0) {
              var headerName = cells[j].trim();
              headers.push(headerName);
            } else {
              var key = headers[j];
              if (key) {
                rowData[key] = cells[j].trim();
              }
            }
          }
          //skip the first row (header) data
          if (i != 0) {
            jsonData.push(rowData);
          }
        }
        this.viewer.rcData = this.rcData = jsonData;


      })
    } catch (e) {
      console.error(e);
    }
  }



  errorMessage: string = "";
  clickedPoints = [];
  rcImages = [];
  activeShapePoints = [];
  activeShape;
  floatingPoint;
  load(model) {
    this.isLoading = true;
    if (model.rcFile) {
      this.csvFileToJSON(model.rcFile);
    }
    if (model.tilesUrl) {
      this.isCesiumViewer = true;
      const viewer = this.createCesiumViewer(model);
      this.isLoading = false;
      viewer.loadTiles(model);
      this.getLabels(this.model?.id);
      this.changeLabelView(this.labelsShow);

      const handler = this.viewer.viewer.screenSpaceEventHandler;

      // Handle mouse double click events
      const _this = this;
      handler.setInputAction(async (movement) => {
        if (_this.toolBox || _this.type === 'report' || _this.type === 'project' || _this.type === 'timeline') {
          return;
        }
        if (!_this.isAssetOwner) {
          _this.toaster.warning("Only Owners can create the labels")
          return;
        }
        if (!_this.model?.id) {
          return;
        }
        var pickedObject = this.viewer.viewer.scene.pick(movement.position);
        var pickedPosition = viewer.viewer.scene.pickPosition(movement.position)
        if (pickedPosition && Cesium.defined(pickedObject)) {

          _this.createLabelDialogRef = _this.dialog.open(_this.createLabelDialog, {
            width: '400px',
            data: {
              orientationLocked: _this.model.orientationLocked,
              label: '',
              type: 'tiles',
              intersects: pickedPosition
            }
          });

        }
      }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);


      // Handle mouse right click events
      handler.setInputAction(function (event) {
        if (_this.toolBox === 'rcTool') {
          terminateShape();
        }
      }, Cesium.ScreenSpaceEventType.RIGHT_CLICK);


      // Handle mouse move events
      handler.setInputAction(function (event) {
        if (_this.toolBox === 'rcTool' && Cesium.defined(_this.floatingPoint)) {
          const newPosition = viewer.viewer.scene.pickPosition(event.endPosition)
          if (Cesium.defined(newPosition)) {
            _this.floatingPoint.position.setValue(newPosition);
            _this.activeShapePoints.pop();
            _this.activeShapePoints.push(newPosition);
          }
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

      // single  click
      handler.setInputAction(async (movement) => {
        var pickedPosition = viewer.viewer.scene.pickPosition(movement.position)
        if (_this.type === 'timeline') {
          return;
        }
        if (_this.toolBox === 'distance') {
          this.viewer.getDistance(movement);
          return;
        }
        if (_this.toolBox === 'angle') {
          this.viewer.getAngles(movement);
          return;
        }
        if (_this.toolBox === 'rcTool') {
          if (this.activeShapePoints.length === 0) {
            this.floatingPoint = createPoint(pickedPosition);
            this.activeShapePoints.push(pickedPosition);
            const dynamicPositions = new Cesium.CallbackProperty(function () {
              if (drawingMode === "polygon") {
                return new Cesium.PolygonHierarchy(_this.activeShapePoints);
              }
              return _this.activeShapePoints;
            }, false);
            this.activeShape = drawShape(dynamicPositions);
          }
          this.activeShapePoints.push(pickedPosition);
          createPoint(pickedPosition);
          return;
        }
        if (!_this.model?.id) {
          return;
        }
        var pickedObject = this.viewer.viewer.scene.pick(movement.position);
        if (pickedObject && pickedObject.id) {
          // Clicking on annotations numbers
          const customData = pickedObject.id.customData;
          if (customData) {
            _this.gotoAnnotation(customData)
            //   _this.addImages(customData, customData.modelId);

          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      function createPoint(worldPosition) {
        const point = viewer.viewer.entities.add({
          position: worldPosition,
          point: {
            color: Cesium.Color.WHITE,
            pixelSize: 5,
            heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          },
        });
        return point;
      }

      let drawingMode = "polygon";
      function drawShape(positionData) {
        let shape;
        if (drawingMode === "line") {
          shape = viewer.viewer.entities.add({
            polyline: {
              positions: positionData,
              clampToGround: true,
              width: 3,
            },
          });
        } else if (drawingMode === "polygon") {
          shape = viewer.viewer.entities.add({
            polygon: {
              hierarchy: positionData,
              material: new Cesium.ColorMaterialProperty(
                Cesium.Color.WHITE.withAlpha(0.7)
              ),
            },
          });
        }
        return shape;
      }

      function terminateShape() {
        _this.activeShapePoints.pop();
        drawShape(_this.activeShapePoints);
        viewer.viewer.entities.remove(_this.floatingPoint);
        viewer.viewer.entities.remove(_this.activeShape);
        _this.floatingPoint = undefined;
        _this.activeShape = undefined;
        _this.activeShapePoints = [];
      }

    }

    else {
      const viewer = this.viewer || this.createViewer(model);
      const cleanup = () => {
        this.isLoading = false;
      };

      if (model.gltfUrl && model.gltfUrl.length) {
        const loader = viewer.loadGLTF(model);
        if (this.model?.id) {
          this.getLabels(this.model.id);
        }
        loader.catch((e) => {
          cleanup();
          if (this.type === 'project')
            this.errorMessage = `Oops! Sorry There was a problem loading the file into the browser. Maybe because of the size of the file or a corrupted file, Please contact info@oceansai.tech with the project name:<${this.uiService.project.name}> and project ID: <${this.uiService.project.id}> for assistance`
          else
            this.errorMessage = `Oops! Sorry There was a problem loading the file into the browser. Maybe because of the size of the file or a corrupted file, Please contact info@oceansai.tech with the asset name:<${this.asset.assetName}> and model ID: <${this.model.id}> for assistance`
          this.cleanModel();
        })
        loader.then((obj) => {
          cleanup();
          this.changeLabelView(this.labelsShow);

          if (this.assetId === 'Vr6ZtymwxyrCctWp2Z7P') {

            const E01 = {
              latitude: 47.19241240198025,
              longitude: -2.6876490053146833,
              depth: -14.76
            }
            const position = {
              x: 6.833629918035172,
              y: -2000,
              z: 10
            }
            // this.viewer.latLongToVector3(E01.latitude, E01.longitude, E01.depth)


            this.loadCablePoints(position, 'E01');
            const F01 = {
              latitude: 47.201907,
              longitude: -2.690502897703576,
              depth: -15.59
            }
            //const position1 = this.viewer.latLongToVector3(F01.latitude, F01.longitude, F01.depth)
            const position1 = {
              x: 2000,
              y: -2000,
              z: 0
            }
            this.loadCablePoints(position1, 'F01');
            this.viewer.createLinePoints(this.uiService.images, position, position1)

          }
        });
        this.initListeners();
      } else {
        this.isLoading = false;
      }
    }


    //gui events
    this.viewer.settingsChanges.subscribe((type) => {
      if (type == 'orientation') {
        this.saveOrientation();
      }
      else if (type === 'rc_points') {
        this.rcImages = [];

        if (this.rcData.length && this.toolBox === 'rcTool') {
          this.is3DPanelExpand = false;
          setTimeout(() => {
            this.handleResize();
          }, 200);

          this.rcData.forEach(element => {
            const isImage = this.viewer.isPointInGeometry(parseFloat(element.x), parseFloat(element.
              y), parseFloat(element.alt), parseFloat(element.heading), parseFloat(element.x),
              parseFloat(element.pitch), parseFloat(element.roll))
            if (isImage.length) {
              const image =
                this.type === 'project' ?
                  this.uiService.images.find(o => o.fileName === element['#name']) : this.projectImages.find(o => o.fileName === element['#name'])
              if (image) {
                this.rcImages.push(image)
              }
            }

          });

        }

      } else {
        this.saveSettings(type);

      }
    });

  }

  initListeners() {
    window.addEventListener('resize', this.handleResize, false)
    const _this = this;
    let mousedownTime;
    this.viewer.renderer.domElement.addEventListener('pointerdown', onDownClick, true)
    function onDownClick(event) {
      mousedownTime = new Date().getTime();
      if (_this.toolBox === 'rcTool') {
        _this.viewer.onMeasurementRectangle(event, 'click');
      }
      /* if (_this.toolBox === 'polygon') {
         _this.viewer.onMeasurementPolygon(event, 'click');
       }*/
      if (_this.toolBox === 'crop') {
        _this.viewer.croppintTool(event, 'pointerdown');
      }

    };

    this.viewer.renderer.domElement.addEventListener('pointermove', onMouseMove, false);

    function onMouseMove(event) {
      if (_this.toolBox === 'rcTool') {
        _this.viewer.onMeasurementRectangle(event, 'move');
      }
      if (_this.toolBox === 'crop') {
        _this.viewer.croppintTool(event, 'pointermove');
      }

    }

    this.viewer.renderer.domElement.addEventListener('pointerup', onPointerUp)
    function onPointerUp(event) {
      if (_this.type === 'report') {
        return;
      }

      if (_this.toolBox === 'rcTool') {
        _this.viewer.onMeasurementRectangle(event, 'release');
        return;
      }

      if (_this.toolBox === 'crop') {
        _this.viewer.croppintTool(event, 'pointerup');
        return;
      }
      const time = new Date().getTime();
      const currentTime = this.lastClickTime;
      this.lastClickTime = time;
      const mouseupTime = new Date().getTime(),
        timeDifference = mouseupTime - mousedownTime;
      if (timeDifference > 1000) {
        //  deep press model event fired
        return;
      }
      _this.viewer.raycaster.setFromCamera(
        {
          x: ((event.offsetX) / _this.viewer.renderer.domElement.clientWidth) * 2 - 1,
          y: -((event.offsetY) / _this.viewer.renderer.domElement.clientHeight) * 2 + 1,

        },
        _this.viewer.camera
      )
      if (time - currentTime < 300) {
        //console.log('double click')
      }
      if (event.which == 1) {
        if (_this.toolBox === 'distance') {
          _this.viewer.onMeasurementDistance(event);
        }
        else if (_this.toolBox === 'angle') {
          _this.viewer.onMeasurementAngle(event);
        }
        else if (_this.toolBox === 'area') {
          _this.viewer.onMeasurementArea(event);
        }
        else if (!_this.toolBox) {
          const intersects = _this.viewer.raycaster.intersectObjects(_this.annotationMarkers, true)
          if (intersects[0] && intersects[0].object.userData && _this.labelsShow) {
            _this.gotoAnnotation(intersects[0].object.userData)
            //   _this.addImages(intersects[0].object.userData, _this.model.id);
          }

        }

      } else {

        const meshes = _this.viewer.pivot.children.filter(o => o.type == 'Group' && o.children.length);
        if (!meshes || !meshes[0]) { return }

        const intersects = _this.viewer.raycaster.intersectObjects(meshes[0]?.children, true)

        if (!_this.model?.id) {
          return;
        }
        if (!_this.isAssetOwner) {
          _this.toaster.warning("Only Owners can create the labels")
          return;
        }
        if (intersects.length) {
          // Calculate the inverse rotation matrix of the model
          const inverseRotationMatrix = new THREE.Matrix4();
          inverseRotationMatrix.copy(_this.viewer.pivot.matrixWorld).invert(); // Inverse rotation matrix
          // Apply the inverse rotation to the intersection point to get it in local coordinates
          const intersectionPointLocal = intersects[0].point.clone().applyMatrix4(inverseRotationMatrix);

          _this.createLabelDialogRef = _this.dialog.open(_this.createLabelDialog, {
            width: '400px',
            data: {
              orientationLocked: _this.model.orientationLocked,
              label: '',
              type: 'model',
              intersects: intersects[0].point
            }
          });
        } else {
          // Calculate the inverse rotation matrix of the model
          const meshes = _this.viewer.scene.children.filter(o => o.name === 'connected_line')
          if (!meshes || !meshes[0]) { return }
          const intersects = _this.viewer.raycaster.intersectObjects(meshes, true)
          if (intersects.length) {
            _this.createLabelDialogRef = _this.dialog.open(_this.createLabelDialog, {
              width: '400px',
              data: {
                orientationLocked: _this.model.orientationLocked,
                label: '',
                type: 'model',
                intersects: intersects[0].point
              }
            });
          }

        }

      }
    }
  }

  saveLabels(data) {
    if (this.annotations.find(o => o.title == data.label)) {
      this.toaster.warning("Label name should be unique. You can not create the duplicate label");
      return;
    }
    const label =
      data.type === 'model' ?
        [{
          "title": data.label || "",
          "id": uuidv4(),
          "camPos": {
            "x": this.viewer.camera.position.x,
            "y": this.viewer.camera.position.y,
            "z": this.viewer.camera.position.z,
          },
          "lookAt": {
            "x": data.intersects.x,
            "y": data.intersects.y,
            "z": data.intersects.z
          }
        }] :
        [{
          "title": data.label || "",
          "id": uuidv4(),
          "position": {
            "x": data.intersects.x,
            "y": data.intersects.y,
            "z": data.intersects.z
          },
          "camPos": {
            x: this.viewer.viewer.camera.position.x,
            y: this.viewer.viewer.camera.position.y,
            z: this.viewer.viewer.camera.position.z,
          },
          "heading": this.viewer.viewer.camera.heading,
          "pitch": this.viewer.viewer.camera.pitch,
          "roll": this.viewer.viewer.camera.roll,
        }]

    this.backendService.create3DModelLabels(this.model.id, label).subscribe(() => {
      this.createLabelDialogRef.close();
    })
    if (this.model && !this.model.orientationLocked) {
      this.backendService.lockOrientation(this.model.id).subscribe(() => {
        document.getElementById('orientation').hidden = true;
      });
    }


  }
  isNameValid(name): boolean {
    return this.annotations.map(o => o.title).indexOf(name) === -1;
  }
  cleanModel() {
    if (this.viewer) {
      this.viewer.remove()
    }
    if (document.getElementById('modelPanel')) {
      document.getElementById('modelPanel').innerHTML = '';
    }
    const el: HTMLElement = document.querySelector('.gui-wrap');
    if (el) {
      el.hidden = true;
    }
  }

  handleResize = () => {
    if (this.viewer?.scene?.children) {
      let modelWidth = (window.innerWidth / (!this.is3DPanelExpand ? 2 : 1));
      let modelHeight = window.innerHeight - this.reduceContentHeight();
      this.viewer.resize(modelHeight, modelWidth)
    }
  }

  handleTilesResize = () => {
    let modelWidth = (window.innerWidth / (!this.is3DPanelExpand ? 2 : 1)) - 10;
    let modelHeight = window.innerHeight - this.reduceContentHeight();
    this.viewer.resize(modelHeight, modelWidth)
  }

  reduceContentHeight() {
    if (this.type === 'project') {
      return 90
    }
    if (this.type == 'timeline' || this.type == 'report') {
      return 120
    }
    return 200;
  }

  onDocumentMouseMove = (event) => {
    if (this.toolBox === 'distance') {
      this.viewer.onDocumentMouseMove(event);
    }
  }


  gotoAnnotation(a) {
    if (this.type === 'asset') {
      this.setCamPositions(a);
      return;
    }
    if (this.is3DPanelExpand && this.type != 'timeline') {
      this.toggle3DPanelView();
    }
    const _this = this;
    _this.annotations.forEach(node => {
      if (node.id === a.id) {
        node.show = true;
      } else {
        node.show = false;
      }
    });
    this.setCamPositions(a);

  }

  setCamPositions(a) {
    if (a.camPos) {
      if (this.model.tilesUrl) {
        this.viewer.viewer.camera.flyTo({
          orientation: {
            heading: a.heading, // Keep the current heading
            pitch: a.pitch, // Keep the current pitch
            roll: a.roll // Keep the current roll
          },
          destination: a.camPos,
          duration: 0.8, // Set the duration to .8 second for a fast flyTo
        })
      }
      else {
        new TWEEN.Tween(this.viewer.camera.position)
        .to({
          x: a.camPos.x,
          y: a.camPos.y,
          z: a.camPos.z,
        }, 800) // Duration 800ms
        .easing(TWEEN.Easing.Quadratic.InOut)
        .start();
      }


    }

  }

  addImages(el, modelId): void {
    if (!this.isAssetOwner) {
      this.toaster.warning("Only Owners can links the medias")
      return;
    }
    const index = this.annotations.findIndex(o => o.id == el.id);
    if (!this.linkImages[el.id]) {
      this.linkImages[el.id] = [];
    }
    const nodeImagesIds = this.linkImages[el.id].map(item => item.id) || [];
    const data: any = {
      project: this.uiService?.project,
      nodeImagesIds,
      assetId: this.assetId,
      label: this.annotations[index].title
    };
    const dialogRef = this.dialog.open(LinkImagesComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });
    dialogRef.afterClosed().subscribe(r => {
      if (!r) {
        return;
      }
      if (r.images.length) {
        this.backendService.linkImages(this.assetId, modelId, this.annotations[index].id, r.images, 'images', this.projectId, "3d").subscribe((result) => {
          r.images.forEach(imageId => {
            if (imageId && !this.linkImages[el.id].find(o => o.id == imageId)) {
              this.backendService.getImageWithAnnotations(imageId).pipe().toPromise().then((image: any) => {
                if (image.deleted != true) {
                  this.linkImages[el.id].push(image)
                  this.updateModelColors(el.id)
                }
              });
            }
          });
        });
      }
      if (r.videos.length) {
        this.backendService.linkImages(this.assetId, modelId, this.annotations[index].id, r.videos, 'videos', this.projectId, "3d").subscribe((result) => {
          r.videos.forEach(videoId => {
            if (videoId && !this.linkImages[el.id].find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  this.linkImages[el.id].push(video)
                }
              });
            }
          });
        });
      }

    });
  }

  imageProcess(src) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => resolve(img)
      img.onerror = reject
      img.src = src
    })
  }


  modelSubscription: any = new Subject();
  isLoad: boolean = false;
  model: any;
  getModels() {
    this.isProcessing = true;
    this.modelSubscription = this.backendService.get3DModels$(this.assetId).subscribe((result: any) => {
      this.isProcessing = false;
      this.model = result;
      if (this.type === 'report') {
        // Capture screenshot of reports
        const project = this.projects.find(o => o.id == this.projectId);
        if (project.isAligned && project.tileStatus === this.tilesSuccess && !this.isLoad) {
          this.baseFile = true;
          this.isLoad = true;
          this.load(project);

        } else {
          if (result && result.tileStatus && !this.isLoad) {
            if (result.tileStatus === Status.SUCCESS) {
              this.baseFile = true;
              this.isLoad = true;
              this.load(result);
            }
          }
        }
      }
      else {
        if (this.baselineModel) {
          if (result && result.tileStatus && !this.isLoad) {
            if (result.tileStatus === Status.SUCCESS) {
              this.baseFile = true;
              this.isLoad = true;
              this.load(result);
            }
          }
          if (result && !result.tileStatus) {
            result['tileStatus'] = 'requestProcessing';
          }
        }
        else {
          if (this.uiService.project.tileStatus === this.tilesSuccess && this.selectedProjectId && !this.isLoad) {
            this.baseFile = true;
            this.isLoad = true;
            this.load(this.uiService.project);
          }
        }
      }

    });
  }

  getExecutionTime() {
    if (this.model.executionTime &&
      this.model.approxModelExecutionTime) {
      const executionDate = moment(this.model.executionTime.toDate()).add(this.model.approxModelExecutionTime, 'hours');
      const serverTime = moment(this.backendService.getServerTime());
      var duration = moment.duration(executionDate.diff(serverTime));
      if (duration.asHours() >= 0)
        return `The process with take approximately <b> ${duration.asHours().toFixed(2)} hour</b>.`
      else
        return `The process is taking more than approx estimate time <b>${this.model.approxModelExecutionTime} hr.</b>. We will inform you once it's finished`
    }
  }


  labelSubscription: any = new Subject();
  isLabels: boolean = false;
  getLabels(id) {
    if (!id) {
      this.isLabels = true;
      return;
    }
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.labelSubscription = this.backendService.get3DModelLabels$(id).subscribe(async (result: any) => {
      this.isLabels = true;

      const selectedlabel = this.annotations.find(o => o.show)
      this.annotations = result.label || [];
      if (!this.baselineModel && this.type == 'project' && !this.uiService.project.isAligned) {
        this.isLabels = true;
        return;
      }


      if (!this.isCesiumViewer) {
        if (this.viewer.scene) {
          const annotations = this.viewer.scene.children.filter(o => o.name == 'annotations');
          if (annotations) {
            for (var i = 0; i < annotations.length; i++) {
              this.viewer.scene.remove(annotations[i])
            }
          }

        }
        this.annotationMarkers = [];
        this.annotations.forEach(async (el, index) => {
          if (el.id == selectedlabel?.id) {
            el.show = true;
          }
          el.modelId = id;
          const canvas = await this.generalService.getCanvasLabel(index)
          var texture = new THREE.Texture(canvas);
          texture.needsUpdate = true;
          const annotationSpriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            // useScreenCoordinates: false,
            // transparent: true,
            depthTest: false,
            depthWrite: false,
            sizeAttenuation: false,
          })
          const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
          annotationSprite.scale.set(this.viewer.state.label, this.viewer.state.label, this.viewer.state.label)
          annotationSprite.position.copy(el.lookAt)
          annotationSprite.userData = { number: index, ...el };
          annotationSprite.name = "annotations";
          annotationSprite.visible = !this.isLoading && this.labelsShow;
          this.viewer.scene.add(annotationSprite)
          this.annotationMarkers.push(annotationSprite)
          this.updateModelColors(el.id);
        });

      } else {
        this.viewer.viewer.entities.removeAll();
        this.annotations.forEach(async (el, index) => {
          el.modelId = id;
          const canvas = await this.generalService.getCanvasLabel(index);
          // The mouse click hit an object

          const position = Cesium.Cartesian3.fromElements(
            el.position.x,
            el.position.y,
            el.position.z
          );

          this.viewer.viewer.entities.add({
            position: position,
            billboard: {
              image: canvas,
              scale: this.viewer.state.label,
              verticalOrigin: Cesium.VerticalOrigin.CENTER,
              clampToGround: true,
              //  scaleByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
              translucencyByDistance: new Cesium.NearFarScalar(1.5e2, 1.0, 1.5e6, 0.0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY
            },
            customData: { number: index, ...el },
            show: !this.isLoading && this.labelsShow
          });
          this.updateModelColors(el.id);
        })
      }



      if (this.type != 'timeline') {
        this.getLinkedImages(id)
      }
    });
  }

  deletePrompt() {
    if (this.annotations.length) {
      this.toaster.warning('Delete all labels before deleting the model')
      return;
    }
    this.confirmDialog
      .open(
        `Are you sure you want to delete the model?`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.ngOnDestroy();
          this.backendService.deleteModel(this.model.id).then((result: any) => {
            this.model = null;
            this.ngOnInit();
          });
        }
      });
  }


  getLinkedImages(id) {
    this.linkImageSubscription = this.backendService.getLinkedImages(this.assetId, id, this.projectId).subscribe((result) => {
      if (!result) { return }
      let keys = Object.keys(result);
      const _this = this;
      keys.forEach(element => {
        const index = this.annotations.findIndex(o => o.id == element);
        if (index != -1) {
          if (!_this.linkImages[element]) {
            _this.linkImages[element] = [];
          }
          result[element]['images']?.forEach(imageId => {
            if (imageId && !this.linkImages[element].find(o => o.id == imageId)) {
              this.backendService.getImageWithAnnotations(imageId).pipe().toPromise().then((image: any) => {
                if (image.deleted != true) {
                  _this.linkImages[element].push(image);
                  _this.updateModelColors(element);
                }
              });
            }
          })
          result[element]['videos']?.forEach(videoId => {
            if (videoId && !this.linkImages[element].find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  _this.linkImages[element].push(video)
                }
              });
            }
          })
        }
      });
    });
  }

  openModal(data, node?) {
    if (data.type != "videos") {
      this.dialog.open(ImageModalComponent, {
        //  panelClass: 'no-border-radius-dialog',
        height: '97%',
        width: '97%',
        data: {
          ...data,
          dialogRef: this.dialogRef
        },
      });

    } else {
      this.dialog.open(VideoModalComponent, {
        data: {
          ...data,
          dialogRef: this.dialogRef
        },
        width: '100%',
        height: '100%',
        panelClass: 'video-dialog'
      });
    }

  }
  ngOnDestroy(): void {
    this.linkImageSubscription.unsubscribe();
    this.modelSubscription.unsubscribe();
    this.labelSubscription.unsubscribe();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    //  this.tilesRuntime = null
    this.cleanModel();
    this.baseFile = false;
    this.isLoad = false;
    this.isLabels = false;
    window.removeEventListener('resize', this.handleResize, false)
  }

  remove(image, node) {
    image.loading = true;
    const index = this.annotations.findIndex(o => o.id === node.id);
    this.backendService.removedLinkedImages(this.assetId, node.modelId, this.annotations[index].id, this.linkImages[node.id].filter(o => o.type === image.type && o.id !== image.id).map(o => o.id), image.type, this.projectId).subscribe(() => {
      image.loading = false;
      const iIndex = this.linkImages[node.id].findIndex(o => o.id == image.id);
      this.linkImages[node.id].splice(iIndex, 1);
      this.updateModelColors(node.id);
    }, error => {
      image.loading = false;
    })
  }

  removeLabelPrompt(label) {
    this.deleteLabelDialogRef = this.dialog.open(this.deleteLabelDialog, {
      data: { label: label }
    });
  }

  deleteLabel(label) {
    this.backendService.remove3DModelLabels(label.modelId, label).subscribe((result) => {
      this.backendService.removeLabelLinkImages(this.assetId, label.modelId, label.id).subscribe((result) => { })
      this.deleteLabelDialogRef.close();
    })
  }

  preview(image) {
    if (this.type === 'timeline' || this.type === 'project') {
      this.dialogRef.close();
    }
    this.router.navigateByUrl(`/dashboard/projects/${image.projectId}/images/${image.id}`);
  }

  handleAdd() {
    document.getElementById('model_image').click();
  }

  import() {
    document.getElementById('import_file').click();

  }
  importRCFile() {
    document.getElementById('import_rc_file').click();

  }

  async fileChoose(event) {
    if (!event.target.files.length) { return };
    if (!this.backendService.DOMAIN_CONTROL?.features?.threeDModel) {
      this.toaster.warning("Your domain does not have permission to use 3D access.")
      return;
    };
    const file = event.target.files[0];
    this.isImageUploading$.next(true);
    let isModel, isObjFile, isMtl;
    try {
      const zipFiles = await jsZip.loadAsync(file);
      Object.keys(zipFiles.files).forEach((file) => {
        if (file.toLocaleLowerCase().indexOf(".obj") != -1 ||
          // file.toLocaleLowerCase().indexOf(".3ds") != -1 ||
          //  file.toLocaleLowerCase().indexOf(".ply") != -1 ||
          file.toLocaleLowerCase().indexOf(".fbx") != -1 ||
          file.toLocaleLowerCase().indexOf(".dae") != -1 ||
          //  file.toLocaleLowerCase().indexOf(".x3d") != -1 ||
          file.toLocaleLowerCase().indexOf(".gltf") != -1 ||
          file.toLocaleLowerCase().indexOf(".glb") != -1
          // ||  file.toLocaleLowerCase().indexOf(".stl") != -1
        ) {
          if (file.toLocaleLowerCase().indexOf(".obj") != -1) {
            isObjFile = true;
          }
          isModel = true;
        }
        if (file.toLocaleLowerCase().indexOf(".mtl") != -1) {
          isMtl = true;
        }
      })
    } catch (ex) {
      this.toaster.error(ex.toString());

    }

    if (isObjFile && !isMtl) {
      this.isImageUploading$.next(false);
      this.toaster.warning("OBJ model required the material file.")
      return;
    }

    if (!isModel) {
      this.isImageUploading$.next(false);
      this.toaster.warning("The Process required  valid supported file formats.")
      return;
    }

    this.isImageUploading$.next(true);
    const ref = this.storage.ref('baseFiles').child(uuidv4());
    this.uiService.uploadModelPercentage = 1; // initially to load loader
    if (file) {
      this.uiService.modelUploadTaks = ref.put(file);
      this.uiService.modelUploadTaks.task.on('state_changed', async (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        this.uiService.uploadModelPercentage = progress;
      },
        (error) => {
          this.uiService.uploadModelPercentage = 0;
          this.isImageUploading$.next(false);
        },
        () => {
          const subs = ref
            .getDownloadURL()
            .subscribe((url) => {
              subs.unsubscribe();
              this.uiService.uploadModelPercentage = 0;
              this.isImageUploading$.next(false);
              if (!this.model) {
                this.backendService.add3DModels$(this.assetId, url,this.sourceType).subscribe(response => {
                  this.backendService.generate3DTiling(response.id).pipe(take(1), share()).subscribe();
                });
              } else {
                this.backendService.update3DModels$(this.model.id, url,this.sourceType).subscribe(response => {
                  this.backendService.generate3DTiling(this.model.id).pipe(take(1), share()).subscribe();
                });
              }

            })
        }
      );
    }

  }

  cancelUpload() {
    this.uiService.modelUploadTaks.cancel();
    this.uiService.uploadModelPercentage = 0;
    this.isImageUploading$.next(false);
  }

  chooseCSVFormat(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];

    this.isImageUploading$.next(true);

    if (file) {
      const ref = this.storage.ref('baseFiles').child
        ('rc_files').child(uuidv4() + "_" + file.name);

      const tasks = ref.put(file);
      tasks.task.on('state_changed', async (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
        (error) => {
          this.isImageUploading$.next(false);
        },
        () => {
          const subs = ref
            .getDownloadURL()
            .subscribe((url) => {
              subs.unsubscribe();
              if (this.type == 'project' && this.selectedProjectId) {
                this.backendService.projectModelRCFile$(this.projectId,
                  url).subscribe()
              } else {
                this.backendService.baseModelRCFile$(this.model.id,
                  url).subscribe()
              }
              this.csvFileToJSON(url);
              this.toaster.success("File uploaded successfully.");

            })
        }
      );
    }

  }

  chooseJsonFormat(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    const _this = this;
    var reader = new FileReader();
    reader.onload = function (e: any) {
      var labels = JSON.parse(e.target.result); // parse json
      let checkLabel = labels.map(o => o.title).filter(element => _this.annotations.map(o => o.title).includes(element));
      if (checkLabel.length) {
        _this.toaster.warning("Label name should be unique. You can not create the duplicate label");
        return;
      }

      let existingLabels = [];
      labels.forEach(lbl => {
        const label = {
          "title": lbl.title || "",
          "id": uuidv4(),
          "camPos": {
            "x": lbl.camPos.x,
            "y": lbl.camPos.y,
            "z": lbl.camPos.z
          },
          "lookAt": {
            "x": lbl.lookAt.x,
            "y": lbl.lookAt.y,
            "z": lbl.lookAt.z
          }
        }
        existingLabels.push(label)
      });
      _this.backendService.create3DModelLabels(_this.model.id, existingLabels).subscribe((result) => { })
    };
    reader.readAsText(file);
  }

  exportQR() {
    this.model.status = 'processing';
    this.backendService.exportQR(this.assetId, '3D').pipe(take(1)).subscribe(response => { }, error => {
      this.model.status = 'failed';
      throw (error);
    })
  }

  toggleMenu() {
    this.isLeftMenuCollpase = !this.isLeftMenuCollpase
  }

  captureNew() {
    const renderer = this.model.tilesUrl ? this.viewer.viewer.render() : this.viewer.renderer;
    this.isImageUploading$.next(true);
    const imgData = this.model.tilesUrl ? this.viewer.viewer.canvas.toDataURL() : renderer.domElement.toDataURL('image/jpeg');
    this.backendService.uploadBase3DScreenshots(imgData).pipe().subscribe((response: any) => {
      this.isImageUploading$.next(false);
      this.backendService.add3DScreenshots$(this.model.id, response.link).subscribe()
    }, error => {
      this.isImageUploading$.next(false);
      throw (error);
    })
  }

  public isChecked() {
    return this.model?.screens?.filter(o => o.checked).length ? false : true;
  }

  addToReport() {
    this.dialogRef.close({
      images: this.model?.screens?.filter(o => o.checked),
      modelId: this.model.id,
      annotations: this.annotations
    })
  }

  edit(screen) {
    screen.editing = true;
  }

  update() {
    const screens = this.model.screens.filter(function (obj) {
      delete obj.editing;
      return delete obj.checked;

    });
    this.backendService.update3DScreenshots$(this.model.id, screens).subscribe()
  }

  deleteScreen(index) {
    this.model.screens.splice(index, 1);
    this.update();
  }

  toggle3DPanelView() {
    this.is3DPanelExpand = !this.is3DPanelExpand;
    if (this.baseFile && this.viewer) {
      if (!this.isCesiumViewer && this.viewer.scene?.children?.length) {
        setTimeout(() => {
          this.handleResize();
        }, 200);
      } else {
        this.handleTilesResize();
      }
    }
  }

  saveOrientation() {
    if (!this.viewer) { return }
    if (this.type == 'project' && this.selectedProjectId) {
      this.backendService.projectModelOrientation$(this.projectId,
        this.viewer.orientation).subscribe()
    } else {
      this.backendService.baseModelOrientation$(this.model.id,
        this.viewer.orientation).subscribe()
    }

  }

  getTransformArray() {
    var transformArray = new Array(16);
    var transformMatrix = this.viewer.viewer.camera.transform;
    // Convert the transformation matrix to an array
    return Cesium.Matrix4.toArray(transformMatrix, transformArray);
  }

  saveSettings(type) {
    if (!this.viewer) { return }

    let params =
      !this.isCesiumViewer ?
        {
          cameraPosition:
          {
            x: this.viewer.camera.position.x,
            y: this.viewer.camera.position.y,
            z: this.viewer.camera.position.z
          },
          up:
          {
            x: this.viewer.camera.up.x,
            y: this.viewer.camera.up.y,
            z: this.viewer.camera.up.z
          },
          rotateAngle: {
            x: this.viewer.camera.rotation.x,
            y: this.viewer.camera.rotation.y,
            z: this.viewer.camera.rotation.z
          },
          controlTarget: {
            x: this.viewer.controls.target.x,
            y: this.viewer.controls.target.y,
            z: this.viewer.controls.target.z
          },
          texture: this.viewer.textureOptions,
        } :
        {

          cameraPosition:
          {
            x: this.viewer.viewer.camera.position.x,
            y: this.viewer.viewer.camera.position.y,
            z: this.viewer.viewer.camera.position.z
          },
          up:
          {
            x: this.viewer.viewer.camera.up.x,
            y: this.viewer.viewer.camera.up.y,
            z: this.viewer.viewer.camera.up.z
          },
          direction:
          {
            x: this.viewer.viewer.camera.direction.x,
            y: this.viewer.viewer.camera.direction.y,
            z: this.viewer.viewer.camera.direction.z
          },
          pitch: this.viewer.viewer.camera.pitch,
          roll: this.viewer.viewer.camera.roll,
          heading: this.viewer.viewer.camera.heading,
          // frustum:
          // {
          //   x: this.viewer.viewer.camera.frustum.x,
          //   y: this.viewer.viewer.camera.frustum.y,
          //   z: this.viewer.viewer.camera.frustum.z
          // }
        }
    if (type == 'reset') {
      delete this.viewer.state.cameraPosition;
      delete this.viewer.state.up;
      delete this.viewer.state.rotateAngle;
      delete this.viewer.state.controlTarget;
      delete this.viewer.state.controlTarget;
      delete this.viewer.state.pitch;
      delete this.viewer.state.roll;
      delete this.viewer.state.heading;
      delete this.viewer.state.direction;

    }
    if (this.type == 'project' && this.selectedProjectId) {
      this.backendService.defaultProjectCameraPosition$(this.projectId,
        Object.assign({}, this.viewer.state, type != 'reset' ? params : {})
      ).subscribe()
    } else {
      this.backendService.defaultCameraPosition$(this.model.id,
        Object.assign({}, this.viewer.state, type != 'reset' ? params : {})
      ).subscribe()
    }
    this.toaster.success("viewer settings updated.");
  }

  handleCancel(data): void {
    this.confirmDialog
      .open(
        `Are you sure you want to cancel the process for "${this.asset?.assetName}"?`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          data.isCancelling = true;
          const subs = this.backendService.cancel3DModel(this.model.id, 'models').pipe(take(1)).subscribe(() => {
            subs.unsubscribe();
            data.isCancelling = false;
          });
        }
      });
  }

  changeLabelView(event) {
    if (!this.isCesiumViewer) {
      this.viewer.scene?.children.filter(o => o.name == "annotations")
        .map((item) => {
          return item.visible = event;
        })
    } else {
      this.viewer.viewer.entities.values.map((item) => {
        return item.show = event;
      })

    }

  }

  onCropValChange(event) {
    if (event == 'crop') {
      if (event === this.cropControl) {
        this.cropControl = "";
        this.viewer.removeCube();
        this.toolBox = "";
      } else {
        this.cropControl = event;
        this.viewer.initCube();
      }
    }
  }


  selectedToolbox: string = "";
  onValChange(event) {
    this.viewer.removeAll();
    if (event === this.selectedToolbox) {
      //deselect
      this.toolBox = "";
      this.selectedToolbox = "";
    } else {
      this.selectedToolbox = event;
      if (this.toolBox === 'crop') {
        // this.viewer.initCube();
      } else if (this.toolBox === 'rcTool') {

      } else {
        this.backendService.getProjectMeasurements$(this.uiService.selectedProjectId).subscribe((result) => {
          if (result.docs.length) {
            this.measurements = result.docs[0];
            if (this.toolBox === 'distance') {
              this.viewer.loadDistances(result.docs[0].data()?.distances || [])
            }
            if (this.toolBox === 'angle') {
              this.viewer.loadAngles(result.docs[0].data()?.angles || [])
            }
          }
        });
      }


    }
  }

  onControlsValChange(event) {
    this.viewer?.changeControl(event);
  }

  deleteMarker(index) {
    this.viewer?.deleteByIndex(index);
  }

  getDocKey() {
    if (this.toolBox == 'distance') {
      return `distances`
    }
    if (this.toolBox == 'angle') {
      return `angles`
    }
  }
  savePoints() {
    this.createBtnOptions.active = true;
    if (!this.measurements) {
      this.backendService.addProjectMeasurements$(this.uiService.selectedProjectId, this.getDocKey(), this.viewer.markers).then((result) => {
        this.measurements = {};
        this.measurements['id'] = result.id;
        this.createBtnOptions.active = false;
      });
    } else {
      this.backendService.updateProjectMeasurements$(this.uiService.selectedProjectId, this.measurements.id, this.getDocKey(), this.viewer.markers).then((result) => {
        this.createBtnOptions.active = false;
      })
    }
  }

  createShape() {
    this.viewer.createGeometry();
  }

  async updateModelColors(id) {
    if (!this.isCesiumViewer) {
      const sprite = this.viewer.scene.children.find(o => o.name == 'annotations' && o.userData.id == id)
      if (sprite && this.linkImages[id]) {
        const images = this.linkImages[id]?.filter(o => o.type != 'videos');
        const maxArr = images.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
        const max = Math.max(...[].concat(...maxArr))
        const tags = images.map(x => x.tags?.find(e => e.sensitive == max)).filter(x => x !== undefined)
        if (tags.length) {
          const findTag = this.tags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
          if (findTag) {
            const color = findTag.levels?.find(o => o.level == max)?.color;
            this.updateSprite(sprite, color)
          }
        } else {
          this.updateSprite(sprite, '#22222')
        }
      }
    } else {

      const entitiesArray = this.viewer.viewer.entities.values;

      const sprite = entitiesArray.find(o => o.customData.id == id)
      if (sprite && this.linkImages[id]) {
        const images = this.linkImages[id]?.filter(o => o.type != 'videos');
        const maxArr = images.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
        const max = Math.max(...[].concat(...maxArr))
        const tags = images.map(x => x.tags?.find(e => e.sensitive == max)).filter(x => x !== undefined)
        if (tags.length) {
          const findTag = this.tags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
          if (findTag) {
            const color = findTag.levels?.find(o => o.level == max)?.color;
            this.updateTiles(sprite, color)
          }
        } else {
          this.updateTiles(sprite, '#22222')
        }
      }
    }
  }

  async updateTiles(sprite, color) {
    if (sprite.customData['color'] && sprite.customData['color'] === color) {
      return;
    }
    sprite.customData['color'] = color;
    const canvas = await this.generalService.getCanvasLabel(sprite.customData.number, color)
    sprite.billboard.image = canvas;
  }

  async updateSprite(sprite, color) {
    if (sprite.userData['color'] && sprite.userData['color'] === color) {
      return;
    }
    sprite.userData['color'] = color;
    const canvas = await this.generalService.getCanvasLabel(sprite.userData.number, color)
    var texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    sprite.material = new THREE.SpriteMaterial({
      map: texture,
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false
    })
  }

  checkTopSeverity(id) {
    const labelImages = this.linkImages[id]?.filter(o => o.type != 'videos');
    if (labelImages) {
      const maxArr = labelImages.map(x => x.tags?.filter(x => x.sensitive)?.map(x => x.sensitive))
      const max = Math.max(...[].concat(...maxArr))
      const tags = labelImages.map(x => x.tags?.find(e => e.sensitive == max)).filter(x => x !== undefined)
      if (tags.length) {
        const findTag = this.tags.find(x => x.tag == tags[0]?.tag && x.status == 'active')
        if (findTag) {
          return findTag.levels?.find(o => o.level == max)?.color;
        }
      }
    }
    return "";
  }

  imageSeverity(image) {
    const levels = image.tags?.filter(o => o.sensitive).map(o => o.sensitive);
    if (levels && levels.length) {
      const maxLevel = Math.max(...levels);
      const tag = image.tags?.find(element => element.sensitive === maxLevel);
      const findTag = this.tags.find(x => x.tag == tag.tag && x.status == 'active')
      if (findTag)
        return findTag.levels?.find(o => o.level == maxLevel)?.color;
    }
    return "";
  }

  getSeverityDetail(image) {
   return this.generalService.getSeverityDetail(image,image.tags);
  }

  markAsBaseline() {
    this.isProcessing = true;
    this.projectId = this.uiService.project.id;
    this.backendService.markAsBaseline$(this.assetId,
      this.uiService.project.size,
      this.uiService.project.gltfUrl,
      this.uiService.project.modelOptions,
      this.uiService.project.ownModel,
      this.uiService.project.tilesUrl
    ).subscribe((res) => {
      this.getLabels(res.id);
    });

  }
  closeHints() {
    this.isHint = false;
  }

  logInfo(image) {
    this.dialog.open(LogFileComponent, {
      width: '30vw',
      data: {
        logFrame: image.logFrame,
        fileName: image.fileName
      },
    });

  }

  async loadCablePoints(points, name) {
    const canvas = await this.generalService.getText(name);
    var texture = new THREE.Texture(canvas);
    texture.needsUpdate = true;
    const annotationSpriteMaterial = new THREE.SpriteMaterial({
      map: texture,
      // useScreenCoordinates: false,
      // transparent: true,
      depthTest: false,
      depthWrite: false,
      sizeAttenuation: false,
    })
    const annotationSprite = new THREE.Sprite(annotationSpriteMaterial)
    annotationSprite.scale.set(this.viewer.state.label, this.viewer.state.label, this.viewer.state.label)
    annotationSprite.position.copy(points)
    //  annotationSprite.userData = { number: index, ...el };
    // annotationSprite.name = "annotations";
    annotationSprite.visible = true;
    this.viewer.scene.add(annotationSprite)
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  linkMedias(label) {
    this.gotoAnnotation(label)
    this.addImages(label, this.model.id);
  }


  isModelAlignBlocks() {
    if (this.getReadOnlyForCurrentUser()) {
      return GENERAL.access;
    }

    if (!this.project?.tileStatus) {
      return TilesMessage.GENERATE_MODEL_SYNC;
    }
    if (this.project?.tileStatus === this.tilesProcess || this.project?.tileStatus === this.tilesQueue) {
      return TilesMessage.PROCESS_SYNC;
    }
    if (this.project?.tileStatus === this.tilesFailed) {
      return TilesMessage.FAILED;
    }
    if (!this.uiService.model || !this.uiService.model?.tileStatus) {
      return TilesMessage.GENERATE_BASE_MODEL;
    }
    if (this.uiService.model?.tileStatus === this.tilesProcess || this.uiService.model?.tileStatus === this.tilesQueue) {
      return TilesMessage.BASE_MODEL_PROCESS;
    }
    if (this.uiService.model?.tileStatus === this.tilesFailed) {
      return TilesMessage.BASELINE_FAILED;
    }
    if (this.project?.alignStatus === this.tilesProcess ||
      this.project?.alignStatus === this.tilesQueue) {
      return TilesMessage.ALIGNMENT_PROCESS;
    }
    return ``
  }


  alignModel() {
    if (this.isModelAlignBlocks()) {
      return;
    }
    if (this.project?.tilesUrl) {
      this.confirmDialog
        .open(
          `Are you sure your model is aligned with base model?
          `,
          {
            trueButtonTitle: 'Yes',
            falseButtonTitle: 'No'
          }
        )
        .afterClosed()
        .subscribe((result: boolean) => {
          if (result) {
            this.backendService.updateProjectAlignment(this.uiService.selectedProjectId,
              Object.assign({}, this.uiService.model.modelOptions)
            ).then(() => {
              this.toaster.success("Model alignment successfully");
              this.getLabels(this.model.id);
            })
          }
        });
      return;
    }
    this.dialog.open(AlignModelsComponent, {
      width: '100%',
      height: '100%',
      data: { project: this.uiService.project, assetId: this.uiService.asset.id }
    });
  }
}
