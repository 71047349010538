import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { TagAddDialogComponent } from '../tag-add-dialog/tag-add-dialog.component';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';
import { filter, map, tap } from 'rxjs/operators';
import { ProjectsAddDialogComponent } from '../projects/add-dialog/add-dialog.component';
import { UiService } from '../services/ui.service';
import { ToastrService } from 'ngx-toastr';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {
  public projects$: Observable<Project[]>;
  public tags;
  public filterTags;
  public aiTags;
  public projects;
  public summary;
  // public selected: string;
  public canEdit = false;
  public tagSubscription: Subscription;
  public selectedSubscription: Subscription = new Subscription();
  public statuses = [
    'active',
    'de-active'
  ];
  public canEditRoles = [
    'owner',
    'editor'
  ];

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  get selected(): string {
    return this.uiService.selectedProjectId;
  }

  set selected(value: string) {
    this.uiService.selectedProjectId = value;
  }
  constructor(private confirmDialog: WaMatConfirmDialog, public backendService: BackendService, public dialog: MatDialog,
    public uiService: UiService, private toast: ToastrService) {
    if (!this.selected) {
      this.loading$.next(false);
    }
  }

  getTags(): any {
    if (!this.selected) { return }
    if (this.tagSubscription) {
      this.tagSubscription.unsubscribe();
    }
    this.backendService.getSummary(this.selected).subscribe((result: any) => {
      this.summary = result;
    })
    this.tagSubscription = this.backendService.getTags(this.selected).subscribe(({ tags }) => {
      this.loading$.next(false);
      this.filterTags = this.tags = tags.sort((tag1, tag2) => tag1.tag.localeCompare(tag2.tag))
        .map(tag => {
          return {
            ...tag
          };
        });
      const currentRole = this.projects.find(project => project.id === this.selected).people[this.backendService.getCurrentUser().uid].role;
      this.canEdit = this.canEditRoles.indexOf(currentRole) !== -1;
    }, error => {
      this.loading$.next(false);
    });
  }

  getSearch(event) {
    const search = event.target.value;
    if (search) {
      this.filterTags = this.tags.filter(tag => {
        return tag.tag.toLocaleLowerCase()?.includes(search.toLocaleLowerCase());
      });
    } else {
      this.filterTags = this.tags;
    }
  }

  ngOnInit(): void {
    this.selectedSubscription = this.uiService.getProjectsEvent$.pipe(
      tap((projects: []) => {
        this.projects = projects;
        this.projects$ = of(projects);
        if (projects && projects.length === 0) {
          this.loading$.next(false);
        }
      }),
    ).subscribe(() => {
      if (this.selected) {
        this.getTags();
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedSubscription.unsubscribe();
    if (this.tagSubscription) {
      this.tagSubscription.unsubscribe();
    }
  }

  openDialog(): void {
    this.dialog.open(TagAddDialogComponent, {
      width: '40vw',
      height: '100vh',
      panelClass: 'top-mr-dialog',
      data: {
        tags: this.tags ? this.tags : [],
        projectId: this.selected
      }
    });
  }
  removeTag(tag): void {
    const newTag = {
      tag: tag.tag,
      created_at: tag.created_at,
      uuid: tag.uuid
    };
    if (this.summary && this.summary.features[tag.tag]) {
      this.confirmDialog
        .open(
          `Are you sure you want to delete the feature? you will loose all annotations data against that feature.`,
          {
            trueButtonTitle: 'Yes',
            falseButtonTitle: 'No'
          }
        )
        .afterClosed()
        .subscribe((modelResult: boolean) => {
          if (modelResult) {
            this.backendService.removeTag(tag.id, this.selected, newTag).subscribe(result => {
              this.backendService.cleanupAnnotations(this.selected,tag.tag).subscribe();
            });
          }
        });
    } else {
      this.backendService.removeTag(tag.id, this.selected, newTag).subscribe(result => {
      });
    }
  }

  editTag(tag): void {
    this.dialog.open(TagAddDialogComponent, {
      width: '40vw',
      height: '100vh',
      panelClass: 'top-mr-dialog',
      data: {
        tags: this.tags ? this.tags : [],
        tag: tag,
        edit: true,
        projectId: this.selected
      }
    });
  }

  getFindingCount(tag) {
    if (this.summary && this.summary.features[tag.tag]) {
      return this.summary.features[tag.tag];
    }
  }

  updateTagStatus(tag, { value }): void {
    const newTag = {
      tag: tag.tag,
      created_at: tag.created_at,
      uuid: tag.uuid,
      status: value,
      levels: tag.levels || [],
      sensitivity: !!tag.sensitivity
    };

    this.backendService.updateTagStatus(tag.id, this.selected, newTag).subscribe(result => {
      console.log(result);
    });
  }

  changeProject(id): void {
    this.selected = id;
    this.tags = [];
    this.uiService.changeProjectEvent$.next();
    if (this.selected) {
      this.getTags();
    }
  }

  isSearch: boolean = false;
  searchToggle() {
    this.isSearch = !this.isSearch;
  }
  getToolTip(level) {
    return `${level.title} Action: ${level.action || '-'} `
  }
}
