<div class="popup-header w-100 d-flex">

    <p class="w-100">
        Narration for image {{data?.imageName}}
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>


<div mat-dialog-content style="height: calc(95vh - 150px);overflow-y:scroll">
    <mat-checkbox *ngIf="imageGroups.length" [(ngModel)]="isChecked" (change)="isChecked = $event.checked" class="mr-2">
        Narration for each group
    </mat-checkbox>
    <form *ngIf="imageGroups.length == 0 || !isChecked" class="d-flex justify-content-between">
        <div class="form-field w-100">
            <textarea id="narration" type="text" class="form-control input-field" placeholder="Add narration here..."
                 name="narration" [(ngModel)]="narration" style="min-height: 280px; width: 100%;">
            </textarea>
        </div>
    </form>

    <div *ngIf="isChecked">
        <div *ngFor="let imageGroup of imageGroups">
            <h3 class="mb-1 mt-1" >Group: {{imageGroup.groupName}} </h3>
            <form  class="d-flex justify-content-between">
                <div class="form-field w-100">
                    <textarea  type="text" class="form-control input-field"
                        placeholder="Add narration here..."  name="narration" [(ngModel)]="imageGroup.narration"
                        style="min-height: 200px; width: 100%;">
                    </textarea>
                </div>
            </form>
        </div>
        
    </div>

</div>
<div mat-dialog-actions>
    <br><br>
    <mat-spinner-button [options]="createBtnOptions" (btnClick)="saveNarration()"></mat-spinner-button>
    <button mat-button (click)="dialogRef.close()">Cancel</button>

</div>
