<div class="popup-header d-flex">
  <p class="w-100">
    Assign Project to asset
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<div>
  <div mat-dialog-content>
    <mat-form-field class="w-100 mb-2" appearance="fill">
      <mat-label>Choose Asset</mat-label>
      <!-- <mat-select [(ngModel)]="assetId">
            <mat-option *ngFor="let asset of assets" [value]="asset.id">
              {{asset.assetName}}
            </mat-option>
          </mat-select> -->
      <mat-select [(ngModel)]="assetId">
        <input class="search-input" placeholder="search" (keyup)="search($event.target.value)">
        <mat-option *ngFor="let asset of filterAssets" [value]="asset.id">
          {{asset.assetName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="link-button">
      <!-- <button mat-button (click)="close()">Cancel</button> -->
      <mat-spinner-button [options]="createBtnOptions" (btnClick)="handleCreate()" [disabled]="!assetId">
      </mat-spinner-button>
    </div>
  </div>
  <div class="strike">
    <span>OR</span>
  </div>
  <p (click)="handleAddAsset()" class="create-asset">Create new asset</p>

</div>