<div class="popup-header w-100 d-flex">
    <p class="w-100">
        Filters
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<div class="cell">
    <mat-tab-group class="mat-two-tabs"  (selectedTabChange)="tabsChange($event)" [selectedIndex]="tabIndex">
        <mat-tab label="Features">
            <ng-container [ngTemplateOutlet]="featuresOuter"></ng-container>
        </mat-tab>
        <mat-tab label="Report Groups">
            <ng-container [ngTemplateOutlet]="groupsOuter"></ng-container>
        </mat-tab>
    </mat-tab-group>

    <ng-template #featuresOuter>
        <mat-card style="height:calc(100vh - 300px)" class="auto scroller p-0 border-0 shadow-none">

            <div *ngFor="let tag of tags" (click)="tagClick(tag,$event)">
                <p class="tag-option py-2 px-3 m-0 border-bottom" matTooltip="{{tag.tag}}"
                    [class.active]="tag.tag === selectedTag">
                    <mat-icon class="sensitivity-icon" *ngIf="tag.sensitivity">{{!tag.isOpen ? 'expand_more' :
                        'expand_less'}}
                    </mat-icon>
                    {{ tag.tag }} {{getFilterTagCount(tag.tag)}}
                </p>
                <div class="text-center" *ngIf="tag.isOpen && tag.tag === selectedTag">
                    <span  *ngFor="let level of tag.levels" 
                    [style.background]="level.color" [id]="level.level"
                    [style.opacity]="sensitiveTag === level.level ? 1 : 0.5"
                        [style.border]="sensitiveTag === level.level ? '1px solid #007bff' : 'none'" class="circle levels"
                        [matTooltip]="level.title"></span>

                    <!-- <span [style.opacity]="sensitiveTag === '1' ? 1 : 0.2"
                        [style.border]="sensitiveTag === '1' ? '1px solid #007bff' : 'none'" class="circle medium"
                        matTooltip="medium"></span>
                    <span [style.opacity]="sensitiveTag === '2' ? 1 : 0.2"
                        [style.border]="sensitiveTag === '2' ? '1px solid #007bff' : 'none'" class="circle high"
                        matTooltip="high"></span> -->
                </div>
            </div>


        </mat-card>
    </ng-template>

    <ng-template #groupsOuter>
        <mat-card style="height:calc(100vh - 300px)" class="auto scroller p-0 border-0 shadow-none">
            <div *ngFor="let group of groups" (click)="groupClick(group)">
                <p class="tag-option py-2 px-3 m-0 border-bottom" matTooltip="{{group.groupName}}"
                    [class.active]="group.id === selectedGroup?.id">
                    {{ group.groupName }}
                </p>
            </div>
        </mat-card>

    </ng-template>

    <button class="bottom-button mb-4" mat-raised-button [disabled]="(tabIndex == 0 && !selectedTag) || (tabIndex == 1 && !selectedGroup) " color="primary"
        (click)="filterImages()">
        <span class="text-uppercase">Apply</span>
    </button>
</div>