<mat-card class="project-card">
  <div class="pr-10">
    <div class="w-100 d-flex align-items-center">
      <mat-card-title class="w-100 wrap-line">
        <a [routerLink]="['./', project.id]">
          {{ project.name }}
        </a>
      </mat-card-title>
    </div>
    <div class="w-100">
      <mat-card-subtitle>
        <ng-container [ngSwitch]="getRoleOfProject()">
          <ng-container *ngSwitchCase="'owner'">Owner</ng-container>
          <ng-container *ngSwitchCase="'editor'">Editor</ng-container>
          <ng-container *ngSwitchCase="'reviewer'">Reviewer</ng-container>
          <ng-container *ngSwitchCase="'approver'">Approver</ng-container>
          <ng-container *ngSwitchCase="'viewer'">Viewer</ng-container>
          <span class="ml-2" *ngIf="readonly"><b>Read Only</b></span>
        </ng-container>

        <span *ngIf="project.projectType ==='thermal'" style="float: right;"class="material-icons">device_thermostat</span>

      </mat-card-subtitle>

      <mat-card-subtitle class="asset" *ngIf="selectedTab=='projects' && project.asset">
        Asset : <b>
          {{project.asset?.assetName}}
        </b>
      </mat-card-subtitle>
      <mat-card-subtitle >
        <span *ngIf="project.inspectionDate">
          Inspection Date: <b>{{project.inspectionDate | date: 'dd/MM/yyyy'}}</b>
        </span>
        <span  [style.background]="checkTopSeverity(project.projectSummary?.severityFeatures)"  class="circle severity"></span>
      </mat-card-subtitle>
      
      <!-- <mat-card-subtitle >
        <div [innerHTML]="getFeatures(project.projectSummary?.features)">
       </div>

      </mat-card-subtitle> -->


    </div>
  </div>
  <div class="w-100  d-flex bottom align-items-center">
    <div>
      <mat-icon matTooltip="project members" svgIcon="members"></mat-icon>
      <span>
        {{ (project.people | keyvalue).length }}
      </span>
    </div>

    <mat-icon *ngIf="project.projectSummary?.features" [matTooltip]="getFeatures(project.projectSummary?.features)">
      read_more</mat-icon>

    <mat-icon matTooltip="edit" (click)="sendRenameEvent(project)" [class.disabled]="readonly">edit</mat-icon>

    <mat-spinner-button class="delete-icon" matTooltip="delete" [options]="deleteBtnOptions"
      (btnClick)="openDeleteDialog(project)" [disabled]="!isOwnerOfProject()">
    </mat-spinner-button>
    <mat-icon matTooltip="asset info" class="asset-icon" *ngIf="selectedTab=='projects'" (click)="assetView(project)">
      <img *ngIf="project.assetId" src="assets/assets-info.svg">
      <img *ngIf="!project.assetId" src="assets/assets-info2.svg">
    </mat-icon>

  </div>

</mat-card>

<ng-template #deleteDialog let-data>
  <div class="popup-header w-100 d-flex">
    <p class="w-100 d-flex">
      <mat-icon style="color: #F2C94C;" class="mr-2">warning</mat-icon>
      <span>Are you absolutely sure?</span>
    </p>
    <mat-icon class="close-btn" (click)="closeDeleteDialog()">close</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="deleteDialog-body">
      <p>This action <b>cannot</b> be undone. This will permanently delete the <b>{{data.project.name}}</b> project,
        images, tags, and remove all collaborator associations.</p>
      <p>This will not change your billing plan. If you want to downgrade, you can do so in your Billing Settings.</p>
      <p>Please type <b>{{data.project.name}}</b> to confirm:</p>
    </div>

    <div class="deleteDialog-footer">
      <mat-form-field class="w-100 mb-2 deleteDialog-input" appearance="fill">
        <input matInput cdkFocusInitial [(ngModel)]="projectNameInput" placeholder="Project Name"
          autocomplete="false" />
      </mat-form-field>

    </div>
  </mat-dialog-content>
  <div mat-dialog-actions>


    <div class="w-100  align-items-center justify-content-end mt-3 mr-2">
      <button [disabled]="data.project.name !== projectNameInput" color="warn" mat-button mat-stroked-button
        class="deleteDialog-button" (click)="deleteProject(data.project)">
        I understand the consequences, delete this repository
      </button>
    </div>
  </div>
</ng-template>