<div (click)="toggleMenu()" [style.left]="!isMenu ? '250px' : '0px'" class="collapse-menu"
  *ngIf="collapseMenu != 'hide'">
  <mat-icon>{{!isMenu ? 'arrow_left' : 'arrow_right'}}</mat-icon>
</div>

<div matTooltip="Close asset panel" (click)="close()" *ngIf="collapseMenu == 'hide'" class="asset-close">
  <mat-icon>close</mat-icon>
</div>

<div [hidden]="isMenu" class="grid-contaner h-100  w-250">
  <div style="position:fixed" class="grid-x h-100 w-250">
    <div style="height: calc(100vh - 60px);" class="cell scroller">
      <div [ngStyle]="{'background-color': bgColor }" class="h-100 project-sidebar scroller">
        <mat-tab-group class="mat-two-tabs" [selectedIndex]="uiService.assetSelectedTabIndex"
          (selectedTabChange)="selectTab($event)" (click)="cancelClickEvent($event);">

          <mat-tab label="CAMPAIGNS" *ngIf="uiService.defaultView =='campaigns'">
            <ng-template mat-tab-label>
              <span>CAMPAIGNS <span class="number">({{uiService.campaigns.length}})</span></span> <mat-icon
                style="font-size: 14px;" (click)="toggleCampaignView()" [matTooltip]="!uiService.campaignAssetView ? 'toggle client-asset view' : 'toggle client-company-asset view'">{{
                uiService.campaignAssetView ? 'arrow_upward': 'arrow_downward'}}</mat-icon>
            </ng-template>
            <ng-container [ngTemplateOutlet]="campaignOuter"></ng-container>
          </mat-tab>
          <mat-tab label="ASSETS" *ngIf="uiService.defaultView =='assets'">
            <ng-template mat-tab-label>
              <span>ASSETS <span class="number">({{assets.length}})</span></span>
            </ng-template>
            <ng-container [ngTemplateOutlet]="assetsOuter"></ng-container>
          </mat-tab>
          <mat-tab label="PROJECTS">
            <ng-template mat-tab-label>
              <span>PROJECTS <span class="number">({{projects.length}})</span></span>
            </ng-template>
            <ng-container [ngTemplateOutlet]="projectOuter"></ng-container>
          </mat-tab>

        </mat-tab-group>
        <!-- Template for Assets in left menu -->
        <ng-template #assetsOuter>
          <ng-container *ngFor="let node of uiService.assetsNodes">
            <p *ngIf="node.children.length" [class.node-active]="node.id== uiService.selectedAssetNode.parent?.id"
              class="company-title" (click)="toggleAssetNode(node)">
              <mat-icon> {{node.expand ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>
              <span [style.color]="node.id== uiService.selectedAssetNode.parent?.id ? '#4456F9' : '#000000'">
                {{node.name}}
              </span>
            </p>
            <div [class.node-active]="node.id == uiService.selectedAssetNode.parent?.id" class="asset-title"
              *ngIf="node.expand">
              <p class="word-wrap" matTooltip="{{child.name}}"
                [class.child-active]="child.id== uiService.selectedAssetNode.child?.id"
                (click)="handleAssetsClick(node,child);" *ngFor="let child of node.children">
                {{child.name}}
              </p>
            </div>
          </ng-container>
        </ng-template>

        <!-- Template for Projects in left menu -->
        <ng-template #projectOuter>
          <ng-container *ngFor="let node of uiService.projectNodes">
            <p [class.node-active]="node.id== uiService.selectedProjectNode.parent?.id" class="company-title"
              (click)="toggleNode(node)">
              <mat-icon *ngIf="node.id !='All'"> {{node.expand ? 'arrow_drop_down' : 'arrow_right'}}</mat-icon>
              <span [style.color]="node.id== uiService.selectedProjectNode.parent?.id ? '#4456F9' : '#000000'">
                {{node.name}}
              </span>
            </p>
            <div [class.node-active]="node.id== uiService.selectedProjectNode.parent?.id" class="asset-title"
              *ngIf="node.expand">
              <p [class.child-active]="node.id== uiService.selectedProjectNode.parent?.id && child.id== uiService.selectedProjectNode.child?.id"
                (click)="handleProjectClick(node,child);" *ngFor="let child of node.children">
                {{child.name}}
              </p>
            </div>
          </ng-container>

        </ng-template>

        <ng-template #campaignOuter>
          <div *ngIf="uiService.campaignAssetView">
            <ng-container *ngFor="let campaign of uiService.campaigns">
              <p (click)="toggleCampaignNode(campaign)" *ngIf="campaign.assets.length" class="company-title d-flex">
                <mat-icon> {{campaign.expand ? 'arrow_drop_down' :
                  'arrow_right'}}</mat-icon>
                <span style="flex-direction: column;" class="d-flex" matTooltip="{{getCompanyName(campaign) + ' ['+campaign.campaignName+'
                ]'}}">
                  <span> {{getCompanyName(campaign)}}
                  </span>
                  <span class="campaign-name"> ({{campaign.campaignName}})</span>
                </span>
              </p>
              <div class="asset-title" *ngIf="campaign.expand"
                [class.node-active]="campaign.id == uiService.selectedCampaignNode.company?.id">
                <p class="word-wrap" (click)="handleCampaignClick(campaign,assetId);"
                  *ngFor="let assetId of campaign.assets"
                  [class.child-active]="campaign.id == uiService.selectedCampaignNode.company?.id && assetId == uiService.selectedCampaignNode.asset">
                  {{getAssetName(assetId)}}
                </p>
              </div>
            </ng-container>
          </div>
          <div *ngIf="!uiService.campaignAssetView">
            <ng-container *ngFor="let company of uiService.groupByCompanyView | keyvalue">
              <div class="company-title campaigns">
                <p (click)="toggleCampaignNode(company.value)">
                  <mat-icon>{{company.value.expand ? 'arrow_drop_down' :
                    'arrow_right' }}</mat-icon>
                  <span>
                    {{getCompanyName(company.value.companyId)}}
                  </span>   
                </p>
               
                <div class="campaign-node-title" *ngFor="let campaign of company.value.campaigns"
                  [class.node-active]="campaign.campaignName == uiService.selectedCampaignCompany.campaign?.campaignName">
                  <p (click)="eventTarget($event,company,campaign,null)" class="word-wrap" *ngIf="company.value.expand">
                    <mat-icon id="icon"> {{campaign.expand ? 'arrow_drop_down' :
                      'arrow_right'}}</mat-icon>
                    <span class="campaign-name"> 
                      {{campaign.campaignName}}
                    </span>
                  </p>
                  <div *ngIf="campaign.expand && company.value.expand">
                    <p  (click)="handleCompanyAssetClick(company.value,campaign,assetId)" class="word-wrap" *ngFor="let assetId of campaign.assets"
                      [class.child-active]="campaign.campaignName == uiService.selectedCampaignCompany.campaign?.campaignName  && assetId == uiService.selectedCampaignCompany.asset">
                      <span>
                        {{getAssetName(assetId)}}</span>
                    </p>
                  </div>

                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>

      </div>
    </div>
  </div>
</div>