<ng-content></ng-content>
<div class="DashboardContainer1"></div>
<div *ngIf="loading" class="h-100 w-100 flex align-center align-middle">
    <mat-spinner style="right: 380px;
    z-index: 99999;" [diameter]="50"></mat-spinner>
  </div>

<ng-template #fileVerifyDialog let-data>
    <div class="warningDialog">
        <div class="warningDialog-body">
            <p>
               File Verification failed.
            </p>
            <span>
              {{message}}
            </span>
        
        </div>
    </div>
    <div class="warningDialog-footer">
        <div class="d-flex align-items-center justify-content-end mt-3">
          <button 
          mat-button
          mat-stroked-button
          class="warningDialog-button"
          (click)="close()"
        >
         OK
        </button>
        </div>
      </div>
</ng-template>
