import { BackendService } from '../../services/backend.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UiService } from '../../services/ui.service';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Project } from '../../projects/item/project.type';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { IImage } from 'src/app/models/image.interface';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-image-titles',
  templateUrl: './image-titles.component.html',
  styleUrls: ['./image-titles.component.scss'],
})
export class ImageTitlesComponent implements OnInit, OnDestroy {
  
  public projects$: Observable<Project[]>;
  public folders$: Observable<any[]>;
  public folderImages$: Observable<any[]>;
  public relations$ = new BehaviorSubject<any>([]);
  activeFolder: string;
  folderImages: IImage[];

  private imageIds: string[] = [];
  private imagesThumbs = {};

  @Input() projectId: string;

  private onDestroy$ = new Subject();
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  constructor(
    public backendService: BackendService,
    public uiService: UiService,
    private generalService: GeneralService
  ) {
    this.projects$ = this.uiService.projects$;
    this.folders$ = this.uiService.folders$;
    this.activeFolder = this.uiService.activeFolder;
    this.folderImages = this.uiService.images;
  }

  ngOnInit(): void {
    this.getRelations();
  }

  getRelations(): void {
    this.backendService.getProjectRelationsAll$(this.projectId).pipe(
      take(1),
      map(relations => relations.filter(relation => this.folderImages.find(image => image.id === relation.imageId))),
      tap((relations: any[]) => {
        this.relations$.next(relations);
        if (this.imageIds.length === 0) {
          relations.forEach(rel => {
            const parentId = rel.imageId;
            if (!this.imageIds.includes(parentId)) {
              this.imageIds.push(parentId)
            }

            const childId = rel.childImagePath.replace('images/', '');
            if (!this.imageIds.includes(childId)) {
              this.imageIds.push(childId)
            }
          });
          this.loadImages();
        }
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  loadImages(): void {
    this.imagesThumbs = {};
    this.imageIds.forEach(id => {
      this.backendService.getImage$<any>(id).pipe(take(1)).subscribe((image) => {
        this.imagesThumbs[`images/${id}`] = image.thumbFileUrl;
      });
    });
  }

  deleteRelation(relation: any, update: boolean = true): void {
    this.backendService.removeImageRelation(this.projectId, relation.parentImagePath, relation.childImagePath).pipe(
      switchMap(() => {
        this.backendService.addHistory([relation.imageId], this.projectId, "context removed", "context",relation.annotationId)
        return this.backendService.removeAnnotation(relation.annotationId, relation.imageId);
      }),
      take(1)
    ).subscribe(() => {
      if (update) {
        this.getRelations();
      }
    });
  }

  deleteAllRelations(): void {
    this.relations$.value.forEach((relation, idx) => {
      this.deleteRelation(relation, false);
    });
    this.relations$.next([]);
  }

  handleProjectChange(): void {}

  filterCheckedArray(array): any[] {
    return array.filter(item => item.isChecked);
  }

  // addRelationsToReport(): void {
  //   this.dialogRef.close({
  //     relations: this.filterCheckedArray(this.relations$.value),
  //     imagesThumbs: this.imagesThumbs
  //   });
  // }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  onScroll() {
    if (this.backendService.latestRelationEntry) {
      this.backendService.getProjectRelationsNext$(this.projectId).pipe(
        map(relations => relations.filter(relation => this.folderImages.find(image => image.id === relation.imageId))),
        tap((relations: any[]) => {
          this.relations$.next(
            this.generalService.makeImageArrayUnique(this.relations$.value.concat(relations))
          );
          relations.forEach(rel => {
            const parentId = rel.imageId;
            if (!this.imageIds.includes(parentId)) {
              this.imageIds.push(parentId)
            }
  
            const childId = rel.childImagePath.replace('images/', '');
            if (!this.imageIds.includes(childId)) {
              this.imageIds.push(childId)
            }
          });
          this.loadImages();
        }),
        takeUntil(this.onDestroy$)
      ).subscribe();
    }
  }
}
