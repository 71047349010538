import Konva from 'konva';
import { Vector2d } from 'konva/types/types';
import { KonvaComponent } from '../konva/konva.module';
import { KonvaConfig } from '../models/tagging.interface';

export type DrawingMode = 'polygon' | 'rectangle' | 'line' | 'point'
const BOX_SIZE = 15;
export type MouseEventType =
  | 'mouseUp'
  | 'mouseDown'
  | 'mouseMove'
  | 'click'
  | 'doubleClick';

type EventCallback = (
  currentSelectionConfig: KonvaConfig,
  cursorPos?: Konva.Vector2d,
  isDrawing?: boolean,
  stage?: KonvaComponent
) => {
  isDrawing: boolean;
  selectionConfig?: KonvaConfig;
  selectionLineConfig?: KonvaConfig;
  selectionShadowConfig?: KonvaConfig;
  tagArea?: { points: number[] };
  errorShow?: boolean;
} | null
type DrawingStrategy = Partial<Record<MouseEventType, EventCallback>>;

const SELECTION_STROKE_SIZE: number = 3;
const LABEL_POS_OFFSET_Y: number = 25;

const KONVA_SELECTION_CONFIG: Partial<KonvaConfig> = {
  strokeWidth: SELECTION_STROKE_SIZE,
  stroke: '#EB5757',
  strokeScaleEnabled: false,
  fill: 'rgba(255, 255, 255, 0.3)',
};

const KONVA_SELECTION_LINE_CONFIG: Partial<KonvaConfig> = {
  dash: [2, 2],
  lineJoin: 'round',
  listening: false
};

const RECT_MIN_SIZE = 25;

export const EMPTY_SELECTION: Partial<KonvaConfig> = { points: [] };

export const getPolygonRect = (points: number[]) => {
  const xValues = points.filter((_, i) => i % 2 === 0);
  const yValues = points.filter((_, i) => i % 2 === 1);

  const left = Math.min(...xValues);
  const right = Math.max(...xValues);
  const top = Math.min(...yValues);
  const bottom = Math.max(...yValues);

  const originPoint = isRect(points)
    ? { originX: right, originY: top }
    : { originX: points[0], originY: points[1] };
  return {
    ...originPoint,
    x: left,
    y: top,
    width: right === left ? right - bottom : right - left,
    height: bottom === top ? bottom - top : bottom - top,
  };
};



export const createBoxOnXY = (points: number[], scale: { x: number; y: number }) => {
  const x = points[0];
  const y = points[1];
  const scaleBoxSize = BOX_SIZE * (scale.x >= scale.y ? scale.x : scale.y);
  return [
    x - scaleBoxSize, y - scaleBoxSize,
    x + scaleBoxSize, y - scaleBoxSize,
    x + scaleBoxSize, y + scaleBoxSize,
    x - scaleBoxSize, y + scaleBoxSize,
  ]
};

export const createRectBox = (points: number[]) => {
  const x = points[0]
  const y = points[1]
  return [
    x - BOX_SIZE, y - BOX_SIZE,
    x + BOX_SIZE, y - BOX_SIZE,
    x + BOX_SIZE, y + BOX_SIZE,
    x - BOX_SIZE, y + BOX_SIZE
  ]
};

export const getScaledPolygon = (
  points: number[],
  scale: { x: number; y: number }
) => {
  return points.map((point, i) => point * (i % 2 ? scale.x : scale.y)
  );
};


const isRect = (points: number[]) => {
  const [ax, ay, bx, by, cx, cy, dx, dy] = points;

  return ax === dx && ay === by && cx === bx && cy === dy;
};

export const inverseScaleXY = (scale: number) => {
  return {
    scale: {
      x: 1 / scale,
      y: 1 / scale,
    }
  };
};

export const scaledLabelOffset = (scale: number) => {
  return LABEL_POS_OFFSET_Y / scale;
}

const createTag: EventCallback = (selectionConfig, _, isDrawing, stage) => {
  if (!isDrawing) {
    return null;
  }

  const scale = stage.getScale();

  const { width, height } = getPolygonRect(selectionConfig.points);

  const allowed_min = RECT_MIN_SIZE / scale;
  const isValidTag = width > allowed_min && height > allowed_min;
  return {
    isDrawing: false,
    selectionShadowConfig: EMPTY_SELECTION,
    selectionLineConfig: EMPTY_SELECTION,
    selectionConfig: EMPTY_SELECTION,
    tagArea: isValidTag ? { ...selectionConfig, points: selectionConfig.points } : null,
    errorShow: height && height > 2 && !isValidTag ? true : false
  };
};


const getLinePoints = (points: number[], cursorPos: Vector2d): number[] => {
  const firstPoint = points.slice(0, 2);
  const lastPoint = points.length >= 4 ? points.slice(-2) : [];

  return [...firstPoint, ...lastPoint, cursorPos.x, cursorPos.y];
};
const getLastLinePoints = (points: number[], cursorPos: Vector2d): number[] => {

  const firstPoint = points.slice(0, 2);
  const lastPoint = points.length >= 4 ? points.slice(-2) : [];
  return points.length == 2 ? [...firstPoint, ...lastPoint, cursorPos.x, cursorPos.y] : [...lastPoint, cursorPos.x, cursorPos.y];
};
export const DRAWING_STRATEGIES: Record<DrawingMode, DrawingStrategy> = {
  rectangle: {
    mouseDown: (selectionConfig, cursorPos) => {
      const points = [cursorPos.x, cursorPos.y];

      return {
        isDrawing: true,
        selectionConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: true,
        },
      };
    },
    mouseMove: (selectionConfig, cursorPos, isDrawing) => {
      if (!isDrawing) {
        return null;
      }

      const [originX, originY] = selectionConfig.points;
      const points = [
        originX,
        originY,
        cursorPos.x,
        originY,
        cursorPos.x,
        cursorPos.y,
        originX,
        cursorPos.y,
      ];

      return {
        isDrawing: true,
        selectionConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: true,
        },
      };
    },
    mouseUp: createTag,
  },
  polygon: {
    mouseMove: (selectionConfig, cursorPos, isDrawing, stage) => {
      if (!isDrawing) {
        return null;
      }

      const immediateIntersection = stage.getStage().getIntersection(cursorPos);
      if (immediateIntersection.getClassName() !== 'Image') return;

      let points = selectionConfig.points;

      if (getPolyLinesIntersection(points.concat(cursorPos.x, cursorPos.y)) ||
        //checkSelfOverlap(selectionConfig.points.concat(cursorPos.x, cursorPos.y)) ||
        checkInvalidAngle(points.concat(cursorPos.x, cursorPos.y))) {
        points = points.slice(-2).concat([points[0], points[1]]);
      } else {
        points = getLinePoints(points, cursorPos);
      }



      return {
        isDrawing,
        selectionLineConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...KONVA_SELECTION_LINE_CONFIG,
          ...selectionConfig,
          points,
        },
      };
    },
    click: (selectionConfig, cursorPos, _, stage) => {
      const points = selectionConfig.points.concat(cursorPos.x, cursorPos.y);

      const immediateIntersection = stage.getStage().getIntersection(cursorPos);
      if (immediateIntersection.getClassName() !== 'Image') return;

      if (getPolyLinesIntersection(points) /*|| checkSelfOverlap(points)*/ || checkInvalidAngle(points)) { return; }

      return {
        isDrawing: true,
        selectionConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: true,
        },
        selectionLineConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...KONVA_SELECTION_LINE_CONFIG,
          ...selectionConfig,
          points: getLinePoints(points, cursorPos),
        },
        selectionShadowConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: false,
        },
      };
    },
    doubleClick: (selectionConfig, _, isDrawing, stage) => {
      const points = selectionConfig.points;

      if (points.length < 4 || getPolyLinesIntersection(points) ||
          /*checkSelfOverlap(points) ||*/ checkInvalidAngle(points)) { return null; }

      return createTag(selectionConfig, _, isDrawing, stage);
    },
  },
  line: {
    mouseMove: (selectionConfig, cursorPos, isDrawing, stage) => {
      if (!isDrawing) {
        return null;
      }
      let points = selectionConfig.points;
      points = getLastLinePoints(points, cursorPos);

      return {
        isDrawing,
        selectionLineConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...KONVA_SELECTION_LINE_CONFIG,
          ...selectionConfig,
          closed: false,
          points,
        },
      };
    },
    click: (selectionConfig, cursorPos, _, stage) => {
      const points = selectionConfig.points.concat(cursorPos.x, cursorPos.y);
      return {
        isDrawing: true,
        selectionConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: false,
        },
        selectionLineConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...KONVA_SELECTION_LINE_CONFIG,
          ...selectionConfig,
          points: getLastLinePoints(points, cursorPos),
          closed: false,
        },
        selectionShadowConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: false,
        },
      };
    },
    doubleClick: (selectionConfig, _, isDrawing, stage) => {

      if (!isDrawing) {
        return null;
      }

      const scale = stage.getScale();

      const { width, height } = getPolygonRect(selectionConfig.points);
      const allowed_min = RECT_MIN_SIZE / scale;
      const isValidTag = width > allowed_min && height > allowed_min;
      return {
        isDrawing: false,
        selectionShadowConfig: { closed: false, points: [] },
        selectionLineConfig: { closed: false, points: [] },
        selectionConfig: { closed: false, points: [] },
        tagArea: isValidTag ? { ...selectionConfig, points: selectionConfig.points } : null,
        errorShow: height && height > 2 && !isValidTag ? true : false
      };
    },
  },
  point: {
    mouseMove: (selectionConfig, cursorPos, isDrawing, stage) => {
      if (!isDrawing) {
        return null;
      }
      /* let points = selectionConfig.points;
       points = getLastLinePoints(points, cursorPos);
 
       return {
         isDrawing,
         selectionLineConfig: {
           ...KONVA_SELECTION_CONFIG,
           ...KONVA_SELECTION_LINE_CONFIG,
           ...selectionConfig,
           closed: false,
           points,
         },
       };*/
    },
    click: (selectionConfig, cursorPos, _, stage) => {
      const points = [cursorPos.x, cursorPos.y];
      return {
        isDrawing: true,
        selectionConfig: {
          ...KONVA_SELECTION_CONFIG,
          ...selectionConfig,
          points,
          closed: true,
          dash: [2, 2]
        },
        tagArea: { ...selectionConfig, dash: [1, 1], points: points, closedType: 'point' }
      };


    }
  },
};

const getPolyLinesIntersection = (points: number[]) => {
  const checkCollision = (lineA, lineB) => {
    const s1x = lineA.x2 - lineA.x1;
    const s1y = lineA.y2 - lineA.y1;
    const s2x = lineB.x2 - lineB.x1;
    const s2y = lineB.y2 - lineB.y1;

    const s = (-s1y * (lineA.x1 - lineB.x1) + s1x * (lineA.y1 - lineB.y1)) / (-s2x * s1y + s1x * s2y);
    const t = (s2x * (lineA.y1 - lineB.y1) - s2y * (lineA.x1 - lineB.x1)) / (-s2x * s1y + s1x * s2y);

    if (s >= 0 && s <= 1 && t >= 0 && t <= 1) {
      const ix = lineA.x1 + (t * s1x);
      const iy = lineA.y1 + (t * s1y);

      if ((ix === lineA.x1 && iy === lineA.y1) || (ix === lineA.x2 && iy === lineA.y2) ||
        (ix === lineB.x1 && iy === lineB.y1) || (ix === lineB.x2 && iy === lineB.y2)) {
        return false;
      }

      return true;
    }

    return false; // No collision
  }

  const createLine = (x1: number, y1: number, x2: number, y2: number) => {
    return { x1, y1, x2, y2 };
  }

  const n = points.length;
  let i;
  for (i = 0; i < n - 2; i += 2) {
    const A = createLine(points[i + 0], points[i + 1], points[i + 2], points[i + 3]);

    const B = createLine(points[n - 4], points[n - 3], points[n - 2], points[n - 1]);
    const C = createLine(points[n - 2], points[n - 1], points[0], points[1]);
    const D = createLine(points[n - 4], points[n - 3], points[0], points[1]);

    if (checkCollision(A, B) || checkCollision(A, C) || checkCollision(A, D)) {
      console.warn("polylines intersecting");
      return true;
    }
  }
};

const checkSelfOverlap = (points: number[]) => {
  const n = points.length;
  const rect1 = getPolygonRect(points);
  const rect2 = getPolygonRect([...points.slice(-4), points[0], points[1]]);

  if (points.length < 8 || rect1.width === 0 || rect2.width === 0) { return false; }

  if (rect1.x >= rect2.x && rect1.x <= rect2.x + rect2.width &&
    rect1.x + rect1.width >= rect2.x && rect1.x + rect1.width <= rect2.x + rect2.width &&
    rect1.y >= rect2.y && rect1.y <= rect2.y + rect2.height &&
    rect1.y + rect1.height >= rect2.y && rect1.y + rect1.height <= rect2.y + rect2.height) {
    console.warn("polygon overlapping", rect1, rect2);
    return true;
  }
  return false;
};

const checkInvalidAngle = (points: number[]) => {
  if (points.length < 8) return false;

  const getAngle = (lineA, lineB) => {
    const s1x = lineA.x2 - lineA.x1;
    const s1y = lineA.y2 - lineA.y1;
    const s2x = lineB.x2 - lineB.x1;
    const s2y = lineB.y2 - lineB.y1;

    let angle = (Math.atan2(s2y, s2x) - Math.atan2(s1y, s1x));
    if (angle > Math.PI) { angle -= 2 * Math.PI; }
    else if (angle <= -Math.PI) { angle += 2 * Math.PI; }

    return Math.abs(angle * 180 / Math.PI);
  }

  const createLine = (x1: number, y1: number, x2: number, y2: number) => {
    return { x1, y1, x2, y2 };
  }

  const n = points.length;

  const A = createLine(points[n - 4], points[n - 3], points[n - 2], points[n - 1]);
  const B = createLine(points[n - 4], points[n - 3], points[n - 6], points[n - 5]);

  const C = createLine(points[0], points[1], points[n - 2], points[n - 1]);
  const D = createLine(points[0], points[1], points[2], points[3]);

  const E = createLine(points[0], points[1], points[n - 4], points[n - 3]);
  const F = createLine(points[n - 4], points[n - 3], points[0], points[1]);

  const angleAB = getAngle(A, B);
  const angleCD = getAngle(C, D);

  const angleCE = getAngle(C, E);
  const angleAF = getAngle(A, F);


  if (/*angleAB >= 170 ||*/ angleAB <= 2 || /*angleCD >= 170 ||*/ angleCD <= 2 ||
      /*angleCE >= 170 ||*/ angleCE <= 2 || /*angleAF >= 170 ||*/ angleAF <= 2) {
    console.warn("steep angle");
    return true;
  }

  return false;
};
