import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../services/backend.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';
import { IReportEditorData, ReportEditorDialogComponent } from '../report-editor-dialog/report-editor-dialog.component';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ITemplate } from '../models/template.interface';
import { IInitialsData, InitialsDataModel } from '../models/initials-data.interface';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { UiService } from '../services/ui.service';
import * as moment from 'moment';
import { GeneralService } from '../services/general.service';
@Component({
  selector: 'app-project-initials',
  templateUrl: './project-initials.component.html',
  styleUrls: ['./project-initials.component.scss']
})
export class ProjectInitialsComponent implements OnInit {
  @Input() projectId: string;
  @Input() projects;
  @Input() projectUsers;
  @Output() removeInitials = new EventEmitter();
  projectInitialSubscribe;
  projectInitials: any[];
  asset;
  isInitialsListView = true;
  public isUpdateInitialsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public initialsForm = new FormGroup({
    projectName: new FormControl(''),
    contractNumber: new FormControl(''),
    projectNumber: new FormControl(''),
    documentNumber: new FormControl(''),
    author: new FormControl(''),
    date: new FormControl(''),
    revision: new FormControl(''),
    approver: new FormControl(''),
    checker: new FormControl(''),
    clientLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    yourLogo: new FormGroup({
      name: new FormControl(''),
      file: new FormControl(null),
      link: new FormControl(''),
    }),
    id: new FormControl(''),
    isAssetInfo: new FormControl(false),
    isContactInfo: new FormControl(false),
    isCompanyInfo: new FormControl(false),

  });
  public assetForm = new FormGroup({
    assetName: new FormControl(''),
    assetId: new FormControl(''),
    assetNumber: new FormControl(''),
    industry: new FormControl(''),
    eqClass: new FormControl(''),
    eqSubClass: new FormControl(''),
    location: new FormControl(''),
    country: new FormControl(''),
    dateOfComm: new FormControl(''),
    latitude: new FormControl(''),
    longitude: new FormControl('')
  });
  public companyForm= new FormGroup({
    companyName: new FormControl(''),
    address: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    country: new FormControl(''),
    zip: new FormControl(''),
    phone: new FormControl(''),
    fax: new FormControl('')
  });

  public contactForm = new FormGroup({
    contactName: new FormControl(''),
    contactPersonLocation: new FormControl(''),
    department: new FormControl(''),
    email: new FormControl(''),
    workContact: new FormControl(''),
    mobileContact: new FormControl('')
  });
  get clientLogo(): AbstractControl { return this.initialsForm.controls.clientLogo; }
  get yourLogo(): AbstractControl { return this.initialsForm.controls.yourLogo; }
  get projectName(): AbstractControl { return this.initialsForm.controls.projectName; }
  get contractNumber(): AbstractControl { return this.initialsForm.controls.contractNumber; }
  get projectNumber(): AbstractControl { return this.initialsForm.controls.projectNumber; }
  get documentNumber(): AbstractControl { return this.initialsForm.controls.documentNumber; }
  get author(): AbstractControl { return this.initialsForm.controls.author; }
  get date(): AbstractControl { return this.initialsForm.controls.date; }
  get revision(): AbstractControl { return this.initialsForm.controls.revision; }
  get approver(): AbstractControl { return this.initialsForm.controls.approver; }
  get checker(): AbstractControl { return this.initialsForm.controls.checker; }
  get isAssetInfo(): AbstractControl { return this.initialsForm.controls.isAssetInfo; }
  get isCompanyInfo(): AbstractControl { return this.initialsForm.controls.isCompanyInfo }
  get isContactInfo(): AbstractControl { return this.initialsForm.controls.isContactInfo }

  get assetId(): AbstractControl { return this.assetForm.controls.assetId }
  get assetName(): AbstractControl { return this.assetForm.controls.assetName }
  get assetNumber(): AbstractControl { return this.assetForm.controls.assetNumber }
  get industry(): AbstractControl { return this.assetForm.controls.industry }
  get eqClass(): AbstractControl { return this.assetForm.controls.eqClass }
  get eqSubClass(): AbstractControl { return this.assetForm.controls.eqSubClass }
  get location(): AbstractControl { return this.assetForm.controls.location }
  get country(): AbstractControl { return this.assetForm.controls.country }
  get dateOfComm(): AbstractControl { return this.assetForm.controls.dateOfComm }
  get latitude(): AbstractControl { return this.assetForm.controls.latitude }
  get longitude(): AbstractControl { return this.assetForm.controls.longitude }


  get companyName(): AbstractControl { return this.companyForm.controls.companyName }
  get address(): AbstractControl { return this.companyForm.controls.address }
  get city(): AbstractControl { return this.companyForm.controls.city }
  get state(): AbstractControl { return this.companyForm.controls.state }
  get companyCountry(): AbstractControl { return this.companyForm.controls.country }
  get zip(): AbstractControl { return this.companyForm.controls.zip }
  get phone(): AbstractControl { return this.companyForm.controls.phone }
  get fax(): AbstractControl { return this.companyForm.controls.fax }


  get contactName(): AbstractControl { return this.contactForm.controls.contactName }
  get contactPersonLocation(): AbstractControl { return this.contactForm.controls.contactPersonLocation }
  get department(): AbstractControl { return this.contactForm.controls.department }
  get email(): AbstractControl { return this.contactForm.controls.email }
  get workContact(): AbstractControl { return this.contactForm.controls.workContact }
  get mobileContact(): AbstractControl { return this.contactForm.controls.mobileContact }

  constructor(
    public backendService: BackendService,
    private uiService: UiService,
    private generalService: GeneralService
  ) {

  }

  ngOnInit(): void {
    this.getProjectInitials(this.projectId);
    const project = this.projects.find(o => o.id == this.projectId)
    if (project.assetId) {
      this.getAssetById(project.assetId)
    }
  }


  imagesPreview(event, form: AbstractControl): void {
    if (event.target.files && event.target.files[0]) {
      let imageFile;
      const reader = new FileReader();
      reader.onload = (_event: any) => {
        imageFile = {
          link: _event.target.result,
          file: event.srcElement.files[0],
          name: event.srcElement.files[0].name
        };
        form.setValue(imageFile);

      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  revisedProjectInitials(data) {
    return this.backendService.revisedProjectInitials$(data, this.projectId)
  }

  public addProjectInitials(): void {
    const initialsData = new InitialsDataModel();
    const project = this.projects.find(o => o.id == this.uiService.selectedProjectId);
    const projectPeoples = project.people;
    const keys = Object.keys(projectPeoples);

    /* Default add reviewer,approver to initials window */
    let reviewer = [];
    let approver = [];
    keys.forEach(key => {
      if (projectPeoples[key].role == 'owner') {
        initialsData.author = this.getRoleName(key);
      }
      if (projectPeoples[key].role == 'reviewer') {
        reviewer.push(this.getRoleName(key));
      }
      if (projectPeoples[key].role == 'approver') {
        approver.push(this.getRoleName(key));
      }
    });
    initialsData.approver = approver.join(",");
    initialsData.checker = reviewer.join(",");
    initialsData.projectName = project.name;
    this.backendService.addProjectInitials$(initialsData, this.projectId)

  }

  public updateProjectInitials(): void {
    let initialsData = this.initialsForm.value;
    this.isUpdateInitialsLoading$.next(true)
    combineLatest([
      this.backendService.saveLogos(initialsData.clientLogo),
      this.backendService.saveLogos(initialsData.yourLogo)
    ]).pipe(
      switchMap(([clientLogo, yourLogo]) => {
        if (clientLogo) {
          initialsData.clientLogo = clientLogo;
        }
        if (yourLogo) {
          initialsData.yourLogo = yourLogo;
        }
        initialsData.isUpdate=true;
        if(initialsData.date){
          initialsData.date= moment(initialsData.date).format('DD-MMM-YYYY')
        }
        initialsData["asset"]= this.assetForm.value;
        initialsData["company"]= this.companyForm.value;
        initialsData["contact"]= this.contactForm.value;
        
        return this.backendService.updateProjectInitials$(this.initialsForm.value, this.projectId)
      })
    ).subscribe(response => {
      this.isUpdateInitialsLoading$.next(false)
      this.isInitialsListView = true;
      this.initialsForm.reset();
      this.companyForm.reset();
      this.contactForm.reset();
      this.assetForm.reset();
    }, error => {
      this.isUpdateInitialsLoading$.next(false)
    });
  }

  getAssetById(assetId) {
    this.backendService.getAssetById(assetId).subscribe((asset) => {
      this.asset = asset.data();
    });
  }

  public getProjectInitials(projectId: string): void {
    this.projectInitialSubscribe = this.backendService.valueChangesProjectInitials(projectId).subscribe(response => {
      this.projectInitials = response;
      
    });
  }

  getRoleName(id) {
    const user = this.projectUsers.find(o => o &&  o.uid == id)
    return user ? (user.fistName && user.lastName) ? user.fistName + ' ' + user.lastName : user.displayName : " ";
  }

  openForm(idx): void {
    this.isInitialsListView = false;
    this.initialsForm.patchValue(this.projectInitials[idx]);
  if(this.projectInitials[idx].date){
    this.initialsForm.controls['date'].setValue(moment(this.projectInitials[idx].date).format("YYYYY-MM-DD"))
  }
    if(this.asset){
      if(!this.projectInitials[idx].isUpdate){
        this.assetForm.patchValue(this.asset);
        this.contactForm.patchValue(this.asset);
        this.companyForm.patchValue(this.asset);
      }else{
        if(this.projectInitials[idx].asset)
        this.assetForm.patchValue(this.projectInitials[idx].asset);
        if(this.projectInitials[idx].contact)    
        this.contactForm.patchValue(this.projectInitials[idx].contact);
        if(this.projectInitials[idx].company)  
        this.companyForm.patchValue(this.projectInitials[idx].company);
      }
   
    }
  }

  getReadOnlyForCurrentUser(project): boolean {
    const projectId = this.projectId;
    return this.generalService.getReadOnlyForCurrentUser(this.projects.find(o => o.id == projectId));
  }

  ngOnDestroy(): void {
    this.projectInitialSubscribe.unsubscribe();
  }

  getUserName(initials) {
    if (initials.createdBy != this.backendService.currentUser.uid) {
      let find = this.projectUsers.filter(o => o).find(o => o.uid == initials.createdBy)
      if (find) {
        return `(${(find.displayName || find.email)})`
      }
    }
  }

  removeProjectInitials(id): void {
    const projectId = this.projectId;
    this.backendService.removeProjectInitials$(projectId, id);
    this.removeInitials.emit(id);
  }

}
