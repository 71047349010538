import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BackendService } from 'src/app/services/backend.service';
import { UiService } from 'src/app/services/ui.service';
import { GeneralService } from 'src/app/services/general.service';
import { IGroup } from '../../models/group.interface';
import { takeUntil } from 'rxjs/operators';
import { ImageTitleImageDialogComponent } from 'src/app/image-title-image-dialog/image-title-image-dialog.component';
@Component({
  selector: 'app-report-group',
  templateUrl: './report-group.component.html',
  styleUrls: ['./report-group.component.scss']
})
export class ReportGroupComponent implements OnInit {
  groupImages = [];
  selectedGroupImages = [];
  private onDestroy$ = new Subject();
  constructor(
    public dialogRef: MatDialogRef<ReportGroupComponent>,
    public dialog: MatDialog,
    public uiService: UiService,
    private generalService: GeneralService,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  groupImagesChanged(images: any[]): void {
    this.selectedGroupImages = images;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  deleteImagesFromGroup(): void {
    this.backendService.removeImagesGroup$(this.selectedGroup , this.selectedGroupImages, this.data.projectId).subscribe(() => {
      this.groupImages = this.groupImages.filter(x => !this.selectedGroupImages.includes(x.id));
      this.selectedGroupImages = [];
      this.selectGroup();
    });
  }

  showImageDialog(): void {
    const data = {
      projectId: this.data.projectId,
      groupId: this.data.group.id
    };
    const dialogRef = this.dialog.open(ImageTitleImageDialogComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });

    const subscr = dialogRef.afterClosed().subscribe(() => {
      subscr.unsubscribe();
      this.selectGroup();
    });
  }

  selectedGroup;
  selectGroup(): void {
    this.uiService.groups$.pipe(takeUntil(this.onDestroy$)).subscribe(groups => {
      this.selectedGroup =groups.find(group => group.id === this.data.group.id);
      (this.selectedGroup  && this.selectedGroup .images ? this.selectedGroup .images : []).map(imageId => {
        if (!this.groupImages.find(o => o.id == imageId)) {
          this.backendService.getImage$(imageId).subscribe((image: any) => {
            if (image && !image.deleted) {
              this.groupImages.push(image);
              this.groupImages = this.generalService.makeImageArrayUnique(this.groupImages)
            }
          })
        }

      });
      this.uiService.selectedImagesIds$.next([]);
    });
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ right: '0' });
    this.selectGroup();
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }
}



