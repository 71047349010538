import {
  AmbientLight,
  AnimationMixer,
  AxesHelper,
  Box3,
  Cache,
  DirectionalLight,
  GridHelper,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  REVISION,
  Scene,
  SkeletonHelper,
  Vector3,
  WebGLRenderer,
  sRGBEncoding,
  LinearToneMapping,
  ACESFilmicToneMapping,
  MathUtils,
  Raycaster,
  Clock
} from 'three';

// import {
//   CONTAINED,
//   INTERSECTED,
//   NOT_INTERSECTED,
//   MeshBVH, computeBoundsTree, acceleratedRaycast,
// } from 'three-mesh-bvh';
import {
  CSS2DRenderer,
  CSS2DObject,
} from 'three/examples/jsm/renderers/CSS2DRenderer'
import * as THREE from 'three'
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
//import Stats from 'three/examples/jsm/libs/stats.module.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS3DRenderer } from 'three/examples/jsm/renderers/CSS3DRenderer.js';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';
import { GUI } from 'dat.gui';
import * as TWEEN from '@tweenjs/tween.js';
//import { CSG } from 'three-csg-ts';
//import * as earcut from 'earcut';
//import * as createBackground  from './../Three/lib/three-vignette.js'
const DEFAULT_CAMERA = '[default]';
const environments = [
  {
    id: '',
    name: 'None',
    path: null,
  },
  {
    id: 'neutral', // THREE.RoomEnvironment
    name: 'Neutral',
    path: null,
  },
  {
    id: 'venice-sunset',
    name: 'Venice Sunset',
    path: 'https://storage.googleapis.com/donmccurdy-static/venice_sunset_1k.exr',
    format: '.exr'
  },
  {
    id: 'footprint-court',
    name: 'Footprint Court (HDR Labs)',
    path: 'https://storage.googleapis.com/donmccurdy-static/footprint_court_2k.exr',
    format: '.exr'
  }
];
const MANAGER = new LoadingManager();
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`
const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(`${THREE_PATH}/examples/js/libs/draco/gltf/`);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(`${THREE_PATH}/examples/js/libs/basis/`);
const Preset = { ASSET_GENERATOR: 'assetgenerator' };
import { Subject } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
Cache.enabled = true;

export class Viewer {
  settingsChanges = new Subject();
  el;
  options;
  lights = [];
  mesh;
  gui;
  state;
  dimensions: any;
  scene;
  camera;
  renderer;
  lblRenderer;
  pmremGenerator;
  controls;
  cameraCtrl;
  cameraFolder = null;
  animFolder = null;
  animCtrls = [];
  morphFolder = null;
  morphCtrls = [];
  skeletonHelpers = [];
  gridHelper = null;
  axesHelper = null;
  vignette;
  neutralEnvironment;
  raycaster = new Raycaster()
  progress = 0;
  clock = new Clock();
  object;
  pivot = new THREE.Group();
  orientation = {
    X: 0,
    Y: 0,
    Z: 0,
  }
  toolBox = '';
  requestId;
  textureOptions = {
    minFilter: 'LinearMipmapLinearFilter',
    magFilter: 'LinearFilter',
    anisotropy: 1
  };
  params = {
    toolMode: 'box',
    selectionMode: 'intersection',
    liveUpdate: false,
    selectModel: false,
    useBoundsTree: true
  };
  // handle building lasso shape
  startX = - Infinity;
  startY = - Infinity;
  prevX = - Infinity;
  prevY = - Infinity;
  tempVec0 = new THREE.Vector2();
  tempVec1 = new THREE.Vector2();
  tempVec2 = new THREE.Vector2();
  selectionPoints = [];
  dragging = false;
  selectionNeedsUpdate = false;
  selectionShape = new THREE.Line();
  selectionShapeNeedsUpdate = false;
  highlightMesh;
  highlightWireframeMesh;
  constructor(el, options) {
    this.el = el;
    this.options = options;

    this.lights = [];
    this.mesh = null;
    this.gui = null;

    const lighting = {
      punctualLights: true,
      exposure: 0.0,
      toneMapping: LinearToneMapping,
      ambientIntensity: 0.3,
      ambientColor: 0xFFFFFF,
      directIntensity: 0.8 * Math.PI, // TODO(#116)
      directColor: 0xFFFFFF,
      environment: options.preset === Preset.ASSET_GENERATOR
        ? environments.find((e) => e.id === 'footprint-court').name
        : environments[1].name,

    }
    this.state = {
      ...{
        camera: DEFAULT_CAMERA,
        grid: this.options.grid || false,
        label: 0.05,
        bgColor: '#e1e1e1',
      }, ...lighting, ...{
        ...this.options.modelOptions
      },
      coordinate: ''
    };

    this.scene = new Scene();

    // Create a CSS3DRenderer to overlay Cesium on top of Three.js




    this.renderer = new WebGLRenderer({ logarithmicDepthBuffer: true, preserveDrawingBuffer: true, antialias: true });
    //  this.renderer.useLegacyLights = false;
    //this.renderer.physicallyCorrectLights = true;
    this.renderer.domElement.style.position = 'absolute';
    this.renderer.domElement.style.top = 0;
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    this.renderer.setClearColor(`${this.state.bgColor}`);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(el.width, el.height);

    this.lblRenderer = new CSS2DRenderer();
    this.lblRenderer.setSize(el.width, el.height)
    this.lblRenderer.domElement.style.position = 'absolute'
    this.lblRenderer.domElement.style.top = '0px'
    this.lblRenderer.domElement.style.pointerEvents = 'none'
    this.el.appendChild(this.lblRenderer.domElement);
    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.neutralEnvironment = this.pmremGenerator.fromScene(new RoomEnvironment()).texture;
    // document.body.appendChild(this.renderer.domElement);
    /*this.controls = new TrackballControls(this.camera, this.renderer.domElement);
    this.controls.rotateSpeed = 2.0;
    this.controls.autoRotate = false;
    */
    this.el.append(this.renderer.domElement);
    const fov = 60;
    this.camera = new PerspectiveCamera(fov, this.renderer.domElement.clientWidth / this.renderer.domElement.clientHeight, 0.01, 1000);
    this.camera.aspect = el.width / el.height;
    this.camera.updateProjectionMatrix();
    this.scene.add(this.camera);

    this.cameraCtrl = null;
    this.cameraFolder = null;
    this.animFolder = null;
    this.animCtrls = [];
    this.morphFolder = null;
    this.morphCtrls = [];
    this.skeletonHelpers = [];
    this.gridHelper = null;
    this.axesHelper = null;
    this.animate = this.animate.bind(this);
    this.requestId = requestAnimationFrame(this.animate);
  }

  animate(time) {
    this.requestId = requestAnimationFrame(this.animate);
    this.render();

  }

  render() {

    // Update the selection lasso lines
   /* if (this.selectionShapeNeedsUpdate && this.toolBox === 'crop') {
      if (this.params.toolMode === 'lasso') {
        const ogLength = this.selectionPoints.length;
        this.selectionPoints.push(
          this.selectionPoints[0],
          this.selectionPoints[1],
          this.selectionPoints[2]
        );

        this.selectionShape.geometry.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(this.selectionPoints, 3, false)
        );

        this.selectionPoints.length = ogLength;

      } else {
        this.selectionShape.geometry.setAttribute(
          'position',
          new THREE.Float32BufferAttribute(this.selectionPoints, 3, false)
        );

      }

      this.selectionShape.frustumCulled = false;
      this.selectionShapeNeedsUpdate = false;

    }
*/
   /* if (this.selectionNeedsUpdate) {
      this.selectionNeedsUpdate = false;
      if (this.selectionPoints.length > 0) {
        this.updateSelection();
      }
      const yScale = Math.tan(THREE.MathUtils.DEG2RAD * this.camera.fov / 2) * this.selectionShape.position.z;
      this.selectionShape.scale.set(- yScale * this.camera.aspect, - yScale, 1);

    }
    */



    this.renderer.render(this.scene, this.camera);
    this.lblRenderer.render(this.scene, this.camera)
    if (this.controls) {
      this.controls.update();
    }
    TWEEN.update();
    //this.updateAnnotationOpacity();
    // this.updateScreenPosition();
  }

  updateScreenPosition() {
    const vector = new THREE.Vector3(250, 250, 250);
    const canvas = this.renderer.domElement;
    vector.project(this.camera);
    vector.x = Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio));
    vector.y = Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio));
  }

  updateAnnotationOpacity() {
    const annotations = this.scene.children.filter(o => o.name == 'annotations');
    // const mesh = this.scene.children.filter(o => o.type == 'Group' && o.children.length)[0];
    // annotations.forEach(label => {
    //   const meshDistance = this.camera.position.distanceTo(mesh?.position);
    //   const spriteDistance = this.camera.position.distanceTo(label.position);
    //   let spriteBehindObject = spriteDistance > meshDistance;
    //   label.material.opacity = spriteBehindObject ? 0.25 : 1;
    // });
    this.updateAllOpacitiesBasedOnRotation(this.camera, annotations)

  }


  updateAllOpacitiesBasedOnRotation(camera, annotationSprites) {
    for (var i = 0; i < annotationSprites.length; i++) {
      var annotationSprite = annotationSprites[i];
      this.updateOpacityBasedOnRotation(camera, annotationSprite);
    }
  }

  updateOpacityBasedOnRotation(camera, annotationSprite) {
    var cameraPosition = new THREE.Vector3();
    camera.getWorldPosition(cameraPosition);

    var vectorToAnnotation = new THREE.Vector3();
    annotationSprite.getWorldPosition(vectorToAnnotation);
    vectorToAnnotation.sub(cameraPosition);

    // Calculate the angle between the vectors
    var angle = camera.getWorldDirection(new THREE.Vector3()).angleTo(vectorToAnnotation);

    // Set a threshold angle for visibility
    var visibilityThreshold = Math.PI / 4; // Adjust as needed

    // Adjust visibility based on the angle
    annotationSprite.material.visible = angle < visibilityThreshold;

    // Adjust opacity based on the angle for the back side
    if (!annotationSprite.material.visible) {
      annotationSprite.material.opacity = 1 - Math.abs(Math.cos(angle));
    } else {
      // Set full opacity for the front side
      annotationSprite.material.opacity = 1;
    }
  }

  resize(height, width) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
    this.lblRenderer.setSize(width, height);
  }


  load(url, rootPath, assetMap) {
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader(MANAGER)
        .setCrossOrigin('anonymous')
        .setDRACOLoader(DRACO_LOADER)
        .setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer))
        .setMeshoptDecoder(MeshoptDecoder);
      loader.load(url, (gltf) => {
        const scene = gltf.scene || gltf.scenes[0];

        if (!scene) {
          // Valid, but not supported by this viewer.
          throw new Error(
            'This model contains no scene, and cannot be viewed here. However,'
            + ' it may contain individual 3D resources.'
          );
        }

        this.setContent(scene, true);


        // See: https://github.com/google/draco/issues/349
        // DRACOLoader.releaseDecoderModule();

        resolve(gltf);

      }, undefined, reject);

    });

  }

  /**
   * @param {THREE.Object3D} object
   * @param {Array<THREE.AnimationClip} clips
   */
  setContent(object, isTiles = false) {
    this.clear();

    const latitude = 47.201907;
    const longitude = -2.690503;
    const seaFloorLevel = -15.59;
    const position = this.latLongToVector3(latitude, longitude, seaFloorLevel)
  //  object.position.copy(position);
    const box = new Box3().setFromObject(object);
    this.dimensions = box.getSize(new Vector3());
    const size = box.getSize(new Vector3()).length();
    if (isTiles) {
      this.camera.position.z = -5; // default load tiles position
    }
    else {
      const center = box.getCenter(new Vector3());
      /*object.position.x = (object.position.x - center.x);
       object.position.y = (object.position.y - center.y);
       object.position.z = (object.position.z - center.z);
*/
      this.camera.near = size / 100;
      this.camera.far = size * 100;
      this.camera.updateProjectionMatrix();
    //  this.camera.position.copy(center);
       this.camera.position.x = size / 2.0;
       this.camera.position.y = size / 2.0;
       this.camera.position.z = size / 2.0;
      this.camera.lookAt(center);
    

    }

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.setCamera(DEFAULT_CAMERA);
    // this.scene.add(object);
    this.mesh = object;

    this.mesh.traverse((node) => {
      if (node.isLight) {
        this.state.punctualLights = false;
      }
      if (node instanceof THREE.Mesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
        node.material.depthWrite = !node.material.transparent;
        const material = node.material;
        if (material.map) {
          if (this.options?.modelOptions?.texture) {
            this.textureOptions = this.options?.modelOptions?.texture;
          }
          material.map.minFilter = THREE[this.textureOptions.minFilter];
          material.map.magFilter = THREE[this.textureOptions.magFilter];
          material.map.anisotropy = Number(this.textureOptions.anisotropy);
          material.map.needsUpdate = true;
        }
      }


    })
    // this.mesh.children[0].geometry.boundsTree = new MeshBVH(this.mesh.children[0].geometry);
    this.updateLights();
    this.updateEnvironment();

     box.getCenter(this.mesh.position); // this re-sets the mesh position
  this.mesh.position.multiplyScalar(- 1);
    this.scene.add(this.pivot);
    this.pivot.add(this.mesh);
    this.alignment(this.options.modelOptions);
    // selection shape

    if (!isTiles) {
      this.selectionShape = new THREE.Line();
      this.selectionShape.material.color.set(0xff9800).convertSRGBToLinear();
      this.selectionShape.renderOrder = 1;
      this.selectionShape.name = 'selectionShape';
      this.selectionShape.position.z = -.2;
      this.selectionShape.depthTest = false;
      this.selectionShape.scale.setScalar(1);
      this.camera.add(this.selectionShape);

      // meshes for selection highlights
      /*  this.highlightMesh = new THREE.Mesh();
        this.highlightMesh.position.copy(this.mesh.position)
        this.highlightMesh.geometry = this.mesh.children[0].geometry.clone();
        this.highlightMesh.geometry.drawRange.count = 0;
        this.highlightMesh.material = new THREE.MeshBasicMaterial({
          opacity: 0.05,
          transparent: true,
          depthWrite: false,
        });
        this.highlightMesh.material.color.set(0xff9800).convertSRGBToLinear();
        this.highlightMesh.renderOrder = 1;
        this.pivot.add(this.highlightMesh);
  
  
        this.highlightWireframeMesh = new THREE.Mesh();
        this.highlightWireframeMesh.position.copy(this.mesh.position)
        this.highlightWireframeMesh.geometry = this.highlightMesh.geometry;
        this.highlightWireframeMesh.material = new THREE.MeshBasicMaterial({
          opacity: 0.25,
          transparent: true,
          wireframe: true,
          depthWrite: false,
        });
        this.highlightWireframeMesh.material.color.copy(this.highlightMesh.material.color);
        this.highlightWireframeMesh.renderOrder = 2;
        this.pivot.add(this.highlightWireframeMesh);
  */

    }
    //Orientation of  models
    if (this.options.modelOptions?.orientation) {
      this.orientation = this.options.modelOptions.orientation;
      if (this.orientation.X) {
        const rad = MathUtils.degToRad(this.orientation.X);
        this.pivot.rotation.x = rad;
      }
      if (this.orientation.Y) {
        const rad = MathUtils.degToRad(this.orientation.Y);
        this.pivot.rotation.y = rad;
      }
      if (this.orientation.Z) {
        const rad = MathUtils.degToRad(this.orientation.Z);
        this.pivot.rotation.z = rad;
      }
    } else {
      //  rotate 180 degree for up/down issue
      if (!this.model.ownModel && !this.model.isAligned) {
        const rad = MathUtils.degToRad(180);
        this.pivot.rotation.x = rad;
        this.pivot.rotation.y = rad;
      }

    }
    this.setCameraPositions(this.options.modelOptions);
    this.changeControl('orbit');
  }

  croppintTool(event, type) {
    console.log(event.which)
    if (event.which != 1 && type === 'pointerdown' && this.toolBox === 'crop') {
      this.prevX = event.offsetX;
      this.prevY = event.offsetY;
      this.startX = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
      this.startY = - ((event.offsetY / (this.renderer.domElement.clientHeight)) * 2 - 1);
      this.selectionPoints.length = 0;
      this.dragging = true;
      this.controls.enabled = false;
    }

    if (event.which != 1 && type === 'pointerup' && this.toolBox === 'crop') {
      this.selectionShape.visible = false;
      this.dragging = false;
      this.controls.enabled = true;
      if (this.selectionPoints.length) {
        this.selectionNeedsUpdate = true;
      }
    }

    if (type === 'pointermove' && this.toolBox === 'crop') {
      // If the right mouse button is not pressed
      if ((2 & event.buttons) === 0) {
        return;
      }
      const ex = event.offsetX;
      const ey = event.offsetY;

      const nx = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
      const ny = - ((event.offsetY / (this.renderer.domElement.clientHeight)) * 2 - 1);


      if (this.params.toolMode === 'box') {

        // set points for the corner of the box
        this.selectionPoints.length = 3 * 5;

        this.selectionPoints[0] = this.startX;
        this.selectionPoints[1] = this.startY;
        this.selectionPoints[2] = 0;

        this.selectionPoints[3] = nx;
        this.selectionPoints[4] = this.startY;
        this.selectionPoints[5] = 0;

        this.selectionPoints[6] = nx;
        this.selectionPoints[7] = ny;
        this.selectionPoints[8] = 0;

        this.selectionPoints[9] = this.startX;
        this.selectionPoints[10] = ny;
        this.selectionPoints[11] = 0;

        this.selectionPoints[12] = this.startX;
        this.selectionPoints[13] = this.startY;
        this.selectionPoints[14] = 0;

        if (ex !== this.prevX || ey !== this.prevY) {
          this.selectionShapeNeedsUpdate = true;

        }

        this.prevX = ex;
        this.prevY = ey;
        this.selectionShape.visible = true;
        if (this.params.liveUpdate) {

          this.selectionNeedsUpdate = true;

        }

      } else {

        // If the mouse hasn't moved a lot since the last point
        if (
          Math.abs(ex - this.prevX) >= 3 ||
          Math.abs(ey - this.prevY) >= 3
        ) {

          // Check if the mouse moved in roughly the same direction as the previous point
          // and replace it if so.
          const i = (this.selectionPoints.length / 3) - 1;
          const i3 = i * 3;
          let doReplace = false;
          if (this.selectionPoints.length > 3) {

            // prev segment direction
            this.tempVec0.set(this.selectionPoints[i3 - 3], this.selectionPoints[i3 - 3 + 1]);
            this.tempVec1.set(this.selectionPoints[i3], this.selectionPoints[i3 + 1]);
            this.tempVec1.sub(this.tempVec0).normalize();

            // this segment direction
            this.tempVec0.set(this.selectionPoints[i3], this.selectionPoints[i3 + 1]);
            this.tempVec2.set(nx, ny);
            this.tempVec2.sub(this.tempVec0).normalize();

            const dot = this.tempVec1.dot(this.tempVec2);
            doReplace = dot > 0.99;

          }

          if (doReplace) {

            this.selectionPoints[i3] = nx;
            this.selectionPoints[i3 + 1] = ny;

          } else {

            this.selectionPoints.push(nx, ny, 0);

          }

          this.selectionShapeNeedsUpdate = true;
          this.selectionShape.visible = true;

          this.prevX = ex;
          this.prevY = ey;

          if (this.params.liveUpdate) {

            this.selectionNeedsUpdate = true;

          }

        }

      }


    }


  }




  updateLights() {
    const state = this.state;
    const lights = this.lights;

    if (state.punctualLights && !lights.length) {
      this.addLights();
    } else if (!state.punctualLights && lights.length) {
      this.removeLights();
    }

    this.renderer.toneMapping = Number(state.toneMapping);
    this.renderer.toneMappingExposure = Math.pow(2, state.exposure);

    if (lights.length === 2) {
      lights[0].intensity = state.ambientIntensity;
      lights[0].color.setHex(state.ambientColor);
      lights[1].intensity = state.directIntensity;
      lights[1].color.setHex(state.directColor);
    }
  }

  addLights() {
    const state = this.state;
    if (this.options.preset === Preset.ASSET_GENERATOR) {
      const hemiLight = new HemisphereLight();
      hemiLight.name = 'hemi_light';
      this.scene.add(hemiLight);
      this.lights.push(hemiLight);
      return;
    }
    const light1 = new AmbientLight(state.ambientColor, state.ambientIntensity);
    light1.name = 'ambient_light';
    this.scene.add(light1);

    const light2 = new DirectionalLight(state.directColor, state.directIntensity);
    light2.position.set(0.5, 0, 0.866); // ~60º
    light2.name = 'main_light';
    this.scene.add(light2);
    this.lights.push(light1, light2);
  }

  removeLights() {
    this.lights.forEach((light) => light.parent.remove(light));
    this.lights.length = 0;
  }

  updateEnvironment() {
    const environment = environments.filter((entry) => entry.name === this.state.environment)[0];
    this.getCubeMapTexture(environment).then(({ envMap }) => {
      this.scene.environment = envMap;
    });
  }

  setCameraPositions(option) {
    if (option?.cameraPosition) {
      this.camera.position.set(
        option.cameraPosition.x,
        option.cameraPosition.y,
        option.cameraPosition.z
      )
    }
    if (option?.rotateAngle) {
      this.camera.rotation.set(
        option.rotateAngle.x,
        option.rotateAngle.y,
        option.rotateAngle.z
      )
    }
    if (option?.controlTarget) {
      this.controls.target.set(
        option.controlTarget.x,
        option.controlTarget.y,
        option.controlTarget.z)
    }
    if (option?.up) {
      this.camera.up.set(
        option.up.x,
        option.up.y,
        option.up.z
      )
    }
  }

  alignment(option) {
    if (option?.rotation) {
      this.mesh.rotation.x = THREE.MathUtils.degToRad(option.rotation.x);
      this.mesh.rotation.y = THREE.MathUtils.degToRad(option.rotation.y);
      this.mesh.rotation.z = THREE.MathUtils.degToRad(option.rotation.z);
    }
    if (option?.scale) {
      this.mesh.scale.x = option.scale.x;
      this.mesh.scale.y = option.scale.y;
      this.mesh.scale.z = option.scale.z;
    }
    if (option?.translate) {
      if ((option.translate.x || option.translate.y || option.translate.z)) {
        this.mesh.position.x = option.translate.x;
        this.mesh.position.y = option.translate.y;
        this.mesh.position.z = option.translate.z;
      }
      if (option?.orientation) {
        this.pivot.rotation.x += THREE.MathUtils.degToRad(option.orientation.X);
        this.pivot.rotation.y += THREE.MathUtils.degToRad(option.orientation.Y);
        this.pivot.rotation.z += THREE.MathUtils.degToRad(option.orientation.Z);
      }
    }

  }


  /**
   * @param {string} name
   */
  setCamera(name) {
    this.controls.enabled = true;
  }


  getCubeMapTexture(environment) {
    const { id, path } = environment;

    // neutral (THREE.RoomEnvironment)
    if (id === 'neutral') {

      return Promise.resolve({ envMap: this.neutralEnvironment });

    }

    // none
    if (id === '') {
      return Promise.resolve({ envMap: null });
    }

    return new Promise((resolve, reject) => {
      new EXRLoader()
        .load(path, (texture) => {
          const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
          this.pmremGenerator.dispose();

          resolve({ envMap });

        }, undefined, reject);

    });

  }

  updateDisplay() {
    if (this.state.grid !== Boolean(this.gridHelper)) {
      if (this.state.grid) {
        this.gridHelper = new GridHelper();
        this.axesHelper = new AxesHelper();
        this.axesHelper.renderOrder = 999;
        this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
        this.scene.add(this.axesHelper);

        this.scene.add(this.gridHelper);
      } else {
        this.scene.remove(this.gridHelper);
        this.scene.remove(this.axesHelper);
        this.gridHelper = null;
      }
    }
  }

  updateBackground() {
    this.renderer.setClearColor(`${this.state.bgColor}`);
  }

  labelCtrlChange() {
    const labels = this.scene.children.filter(o => o.name == 'annotations');
    labels.forEach(label => {
      label.scale.set(this.state.label, this.state.label, this.state.label)
    });
  }
  addGUI(meunOptions) {
    if (!meunOptions) {
      return;
    }
    const _this = this;
    const guiWrap = document.createElement('div');
    const gui = this.gui = new GUI({ autoPlace: false, width: 260, hideable: true });
    // Display controls.
    const bgColor1Ctrl = gui.addColor(this.state, 'bgColor').name('Background');
    bgColor1Ctrl.onChange(() => this.updateBackground());
    const labelsCtrl = gui.add(this.state, 'label', 0.01, 0.1).name('Label width');
    labelsCtrl.onChange(() => this.labelCtrlChange());


    const coordinateFolder = gui.addFolder('Coordinate System')

    coordinateFolder.add(this.state, 'coordinate', [
      "EGM84 30'",
      "EGM96 15'",
      "EGM2008 2.5'",
      "EGM2008 1' ",
    ]).name('Global Coordinate').onChange((value) => {
      let rad = 0;
      if (value === "EGM84 30'") {
        rad = MathUtils.degToRad(90);// adjust accordig to value
      }
      if (value === "EGM96 15'") {
        rad = MathUtils.degToRad(180);// adjust accordig to value
      }
      if (value === "EGM2008 2.5'") {
        rad = MathUtils.degToRad(270);// adjust accordig to value
      }
      if (value === "EGM2008 1'") {
        rad = MathUtils.degToRad(360);// adjust accordig to value
      }
      _this.pivot.rotation.x = rad;
    });

    const textureFolder = gui.addFolder('Texture')

    textureFolder.add(this.textureOptions, 'minFilter', [
      'NearestFilter',
      'LinearFilter',
      'NearestMipmapNearestFilter',
      'LinearMipmapNearestFilter',
      'NearestMipmapLinearFilter',
      'LinearMipmapLinearFilter',
    ]).onChange((value) => {
      const minFilter = THREE[value];
      this.mesh.traverse((node) => {
        if (node instanceof THREE.Mesh) {
          const material = node.material;
          if (material.map) {
            material.map.minFilter = minFilter;
            material.map.needsUpdate = true;
          }
        }
      });
    });

    textureFolder.add(this.textureOptions, 'magFilter', ['NearestFilter', 'LinearFilter']).onChange((value) => {
      const magFilter = THREE[value];
      this.mesh.traverse((node) => {
        if (node instanceof THREE.Mesh) {
          const material = node.material;
          if (material.map) {
            material.map.magFilter = magFilter;
            material.map.needsUpdate = true;
          }
        }
      });
    });

    textureFolder.add(this.textureOptions, 'anisotropy', 1, this.renderer.capabilities.getMaxAnisotropy()).onChange((value) => {
      this.mesh.traverse((node) => {
        if (node instanceof THREE.Mesh) {
          const material = node.material;
          if (material.map) {
            material.map.anisotropy = Number(value);
            material.map.needsUpdate = true;
          }
        }
      });
    });
    /*  const selectionFolder = gui.addFolder('selection');
      selectionFolder.add(this.params, 'toolMode', ['lasso', 'box']);
      selectionFolder.add(this.params, 'selectionMode', ['centroid', 'centroid-visible', 'intersection']);
      selectionFolder.add(this.params, 'selectModel');
      selectionFolder.add(this.params, 'liveUpdate');
      selectionFolder.add(this.params, 'useBoundsTree');
      */
    // Lighting controls.
    const light = gui.add(this.state, 'exposure', -10, 10, 0.01).name('lighting')
    light.onChange(() => this.updateLights());


    const lightFolder = gui.addFolder('Advanced Lighting');
    const envMapCtrl = lightFolder.add(this.state, 'environment', environments.map((env) => env.name));
    envMapCtrl.onChange(() => this.updateEnvironment());
    [
      lightFolder.add(this.state, 'toneMapping', { Linear: LinearToneMapping, 'ACES Filmic': ACESFilmicToneMapping }),
      //  lightFolder.add(this.state, 'exposure', -10, 10, 0.01),
      lightFolder.add(this.state, 'punctualLights').name('punctual lights').listen(),
      lightFolder.add(this.state, 'ambientIntensity', 0, 2).name('ambient intensity'),
      lightFolder.addColor(this.state, 'ambientColor').name('ambient color'),
      lightFolder.add(this.state, 'directIntensity', 0, 4).name('direct intensity'), // TODO(#116)
      lightFolder.addColor(this.state, 'directColor').name('direct color')
    ].forEach((ctrl) => ctrl.onChange(() => this.updateLights()));

    var container = document.createElement('div');
    container.style.display = 'flex';
    container.id = "orientation";
    var span = document.createElement('span');
    span.classList.add('property-name');
    span.textContent = "Orientation"
    // (90°)
    container.append(span);
    // Create the buttons
    var xButton = document.createElement('button');
    xButton.textContent = 'X';
    xButton.title = "90° X axis rotation"
    xButton.classList.add('gui-cust-btn');
    xButton.addEventListener('click', function () {
      _this.orientation.X = _this.orientation.X + 90;
      if (_this.orientation.X > 360) {
        _this.orientation.X = 90;
      }
      const rad = MathUtils.degToRad(_this.orientation.X);
      _this.pivot.rotation.x = rad;
      _this.settingsChanges.next('orientation');
    });

    var yButton = document.createElement('button');
    yButton.textContent = 'Y';
    yButton.title = "90° Y axis rotation"
    yButton.classList.add('gui-cust-btn');
    yButton.addEventListener('click', function () {
      _this.orientation.Y = _this.orientation.Y + 90;
      if (_this.orientation.Y > 360) {
        _this.orientation.Y = 90;
      }
      const rad = MathUtils.degToRad(_this.orientation.Y);
      _this.pivot.rotation.y = rad;
      _this.settingsChanges.next('orientation');
    });
    var zButton = document.createElement('button');
    zButton.textContent = 'Z';
    zButton.title = "90° Z axis rotation"
    zButton.classList.add('gui-cust-btn');
    zButton.addEventListener('click', function () {
      _this.orientation.Z = _this.orientation.Z + 90;
      if (_this.orientation.Z > 360) {
        _this.orientation.Z = 90;
      }
      const rad = MathUtils.degToRad(_this.orientation.Z);
      _this.pivot.rotation.z = rad;
      _this.settingsChanges.next('orientation');
    });

    // Append buttons to the container
    container.appendChild(xButton);
    container.appendChild(yButton);
    container.appendChild(zButton);
    var li = document.createElement('li');
    li.append(container);
    var saveBtn = {
      add: function () {
        _this.settingsChanges.next('update');
      }
    };
    gui.add(saveBtn, 'add').name('Save settings');
    var reset = {
      add: function () {
        _this.controls.reset();
        _this.settingsChanges.next('reset');
      }
    };
    gui.add(reset, 'add').name('Reset view');

    this.el.parentElement.appendChild(guiWrap);
    guiWrap.classList.add(this.options.class ? this.options.class : 'gui-wrap');
    guiWrap.appendChild(gui.domElement);
    if (!this.model?.orientationLocked && !this.model?.isAligned) {
      guiWrap.firstChild.firstChild.insertBefore(li, guiWrap.firstChild.firstChild.firstChild)

    }
    gui.close();

  }

  // updateAnistropy() {
  //   this.mesh.traverse( ( object ) => {
  //     if ( object.isMesh === true ) {

  //        object.material.map.anisotropy = maxAnisotropy;

  //     }

  //  } );
  //   material2.map = texture2.clone()
  // }



  updateGUI() {
    const cameraNames = [];
    const morphMeshes = [];
    this.mesh.traverse((node) => {
      if (node.isMesh && node.morphTargetInfluences) {
        morphMeshes.push(node);
      }
      if (node.isCamera) {
        node.name = node.name || `VIEWER__camera_${cameraNames.length + 1}`;
        cameraNames.push(node.name);
      }
    });

    if (cameraNames.length) {
      this.cameraFolder.domElement.style.display = '';
      if (this.cameraCtrl) this.cameraCtrl.remove();
      const cameraOptions = [DEFAULT_CAMERA].concat(cameraNames);
      this.cameraCtrl = this.cameraFolder.add(this.state, 'camera', cameraOptions);
      this.cameraCtrl.onChange((name) => this.setCamera(name));
    }

    if (morphMeshes.length) {
      this.morphFolder.domElement.style.display = '';
      morphMeshes.forEach((mesh) => {
        if (mesh.morphTargetInfluences.length) {
          const nameCtrl = this.morphFolder.add({ name: mesh.name || 'Untitled' }, 'name');
          this.morphCtrls.push(nameCtrl);
        }
        for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
          const ctrl = this.morphFolder.add(mesh.morphTargetInfluences, i, 0, 1, 0.01).listen();
          Object.keys(mesh.morphTargetDictionary).forEach((key) => {
            if (key && mesh.morphTargetDictionary[key] === i) ctrl.name(key);
          });
          this.morphCtrls.push(ctrl);
        }
      });
    }
  }

  clear() {
    if (!this.mesh) return;
    this.scene.remove(this.mesh);
    // dispose geometry
    this.mesh.traverse((node) => {
      if (!node.isMesh) return;
      node.geometry.dispose();
    });
    // dispose textures
    traverseMaterials(this.mesh, (material) => {
      for (const key in material) {

        if (key !== 'envMap' && material[key] && material[key].isTexture) {
          material[key].dispose();
        }
      }

    });

  }

  model: any;
  loadGLTF(model, meunOptions = true) {
    const _this = this;
    this.model = model;
    return new Promise((resolve, reject) => {
      for (const path in model.gltfUrl) {
        const file = model.gltfUrl[path];
        if (model.gltfUrl.length > 1) {
          // load bin folder
          MANAGER.setURLModifier(function (url) {
            return url;
          });
        }
        MANAGER.onProgress = function (url, itemsLoaded, itemsTotal) {
          if (model.gltfUrl.length > 1) {
            _this.progress = (itemsLoaded / itemsTotal * 100)
          }
        };
        const extension = file.split('.').pop().toLowerCase();
        if (extension == 'gltf' || extension == 'glb') {
          const loader = new GLTFLoader(MANAGER)
            .setDRACOLoader(DRACO_LOADER)
            .setMeshoptDecoder(MeshoptDecoder)
            .setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer));

          loader.load(file, (gltf) => {
            const scene = this.object = gltf.scene || gltf.scenes[0];
            if (!scene) {
              // Valid, but not supported by this viewer.
              throw new Error(
                'This model contains no scene, and cannot be viewed here. However,'
                + ' it may contain individual 3D resources.'
              );
            }
            this.setContent(scene, false);
            this.addGUI(meunOptions);
            resolve(gltf);
          },
            (xhr) => {
              if (xhr.lengthComputable) {
                if (model.gltfUrl.length === 1) {
                  this.progress = (xhr.loaded / xhr.total) * 100;
                }
              }
            },
            (error) => {
              reject(error)
            }
          )
        }
      }
    });
  }

  markers = [];
  markerId: string = "";
  distanceClick = 0;
  distanceShapes = [];
  distancePoints = [];
  onMeasurementDistance(event) {
    const _this = this;
    if (_this.distanceClick == 0) {
      this.markerId = uuidv4()
    }
    let intersects = _this.getIntersections(event);
    if (intersects.length > 0) {

      _this.distancePoints.push(new THREE.Vector3());
      const radius = _this.dimensions.length() / 500;
      const shape = createSphereGeometry(radius, _this.markerId);
      _this.scene.add(shape);
      _this.distanceShapes.push(shape)
      _this.distancePoints[_this.distanceClick].copy(intersects[0].point);
      _this.distanceShapes[_this.distanceClick].position.copy(intersects[0].point);
      _this.distanceClick++;
      if (_this.distanceClick > 1) {
        var distance = _this.distancePoints[0].distanceTo(
          _this.distancePoints[1]);
        //   const volume = _this.dimensions.x * _this.dimensions.y * distance;
        // console.log(volume)
        const measurementLabel = createLabel(_this.markerId);
        this.scene.add(measurementLabel)
        measurementLabel.element.innerText =
          distance.toFixed(3) + 'm';
        measurementLabel.position.copy(intersects[0].point)
        measurementLabel.position.lerpVectors(_this.distancePoints[0], _this.distancePoints[1], 0.5)
        const line = createLine(_this.distancePoints[0], _this.distancePoints[1], _this.markerId);
        _this.scene.add(line);
        _this.markers.push({
          title: distance.toFixed(3) + 'm',
          points: _this.distancePoints.map(element => {
            return { x: element.x, y: element.y, z: element.z }
          }),
          id: _this.markerId
        })
        _this.distanceClick = 0;
        _this.distanceShapes = [];
        _this.distancePoints = [];
      }
    }
  }

  loadDistances(multiPoints) {
    this.markers = multiPoints;
    multiPoints.forEach(data => {
      let distancePoints = [];
      data.points.forEach((point, i) => {
        const radius = this.dimensions.length() / 500;
        const shapes = createSphereGeometry(radius, data.id);
        distancePoints.push(new THREE.Vector3());
        distancePoints[i].copy(point);
        shapes.position.copy(point);
        this.scene.add(shapes);
        if (i == 1) {
          const measurementLabel = createLabel(data.id);
          this.scene.add(measurementLabel)
          measurementLabel.element.innerText =
            data.title;
          measurementLabel.position.copy(distancePoints[0])
          measurementLabel.position.lerpVectors(distancePoints[0], distancePoints[1], 0.5)
          const line = createLine(distancePoints[0], distancePoints[1], data.id);
          this.scene.add(line);
        }
      });
    })
  }

  loadAngles(multiPoints) {
    this.markers = multiPoints;
    multiPoints.forEach(data => {
      let anglePoints = [];
      data.points.forEach((point, i) => {
        const radius = this.dimensions.length() / 500;
        const shapes = createSphereGeometry(radius, data.id);
        anglePoints.push(new THREE.Vector3());
        anglePoints[i].copy(point);
        shapes.position.copy(point);
        if (i != 1) {
          this.scene.add(shapes);
        }
        if (i >= 1) {
          const line = createLine(anglePoints[anglePoints.length - 2], anglePoints[anglePoints.length - 1], data.id);
          this.scene.add(line);
          if (i == 2) {
            const measurementLabel = createLabel(data.id);
            this.scene.add(measurementLabel)
            measurementLabel.element.innerText =
              data.title;
            measurementLabel.position.copy(anglePoints[0])
            measurementLabel.position.lerpVectors(anglePoints[0], anglePoints[2], 0.5);

          }
        }
      });
    })
  }

  updateRectanglePoints(point1, point2) {
    // Get the attribute of the geometry
    const positionAttribute = this.geometry.getAttribute('position');
    // Update the vertices of the lines
    positionAttribute.setXYZ(0, point1.x, point1.y, point1.z);
    positionAttribute.setXYZ(1, point2.x, point1.y, point1.z);
    positionAttribute.setXYZ(2, point2.x, point1.y, point1.z);
    positionAttribute.setXYZ(3, point2.x, point2.y, point2.z);
    positionAttribute.setXYZ(4, point2.x, point2.y, point2.z);
    positionAttribute.setXYZ(5, point1.x, point2.y, point2.z);
    positionAttribute.setXYZ(6, point1.x, point2.y, point2.z);
    positionAttribute.setXYZ(7, point1.x, point1.y, point1.z);

    this.points = [
      new THREE.Vector3(point1.x, point1.y, point1.z),
      new THREE.Vector3(point2.x, point1.y, point1.z),
      new THREE.Vector3(point2.x, point2.y, point2.z),
      new THREE.Vector3(point1.x, point2.y, point2.z)
    ]

    // Mark the attribute as needing an update
    positionAttribute.needsUpdate = true;

  }

  createRectangleGeometry(point1, point2) {
    // Calculate the width and height of the rectangle based on the distance between the points
    const width = point1.distanceTo(point2);
    if (!width) {
      return;
    }
    // Create a new geometry for the rectangle
    this.geometry = new THREE.BufferGeometry();


    // Define the vertices of the rectangle
    this.vertices =
      new Float32Array([
        point1.x, point1.y, point1.z,
        point2.x, point1.y, point1.z,
        point2.x, point1.y, point1.z,
        point2.x, point2.y, point2.z,
        point2.x, point2.y, point2.z,
        point1.x, point2.y, point2.z,
        point1.x, point2.y, point2.z,
        point1.x, point1.y, point1.z,
      ]);


    // Set the vertices as an attribute of the geometry
    // Create a new mesh using the geometry and material for the lines
    this.geometry.setAttribute('position', new THREE.BufferAttribute(this.vertices, 3));

    const material = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide });

    const lines = new THREE.Line(this.geometry, material);

    lines.material.color.set(0xff9800).convertSRGBToLinear();
    lines.renderOrder = 1;
    lines.depthTest = false;
    //lines.frustumCulled = false;
    //  lines.selectionShapeNeedsUpdate = false;
    lines.name = "linesGeo";
    // Add the lines to the scene
    this.scene.add(lines);
  }

  createRectangleBox() {
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, transparent: true, opacity: 0.5 });

    const width = this.points[0].distanceTo(this.points[1]);
    const height = this.points[1].distanceTo(this.points[2]);
    const depth = this.points[0].distanceTo(this.points[3]);

    const geometry = new THREE.BoxGeometry(width, height, depth);
    const rectangleBox = new THREE.Mesh(geometry, material);
    rectangleBox.name = 'boxShape';
    const center = new THREE.Vector3().addVectors(this.points[0], this.points[2]).multiplyScalar(0.5);
    rectangleBox.position.copy(center);

    this.scene.add(rectangleBox);

  }

  isPointInsideGeometry(ray, boundingBox) {
    const intersection = new THREE.Vector3();
    return ray.intersectBox(boundingBox, intersection);
  }

  createVirtualCamera(data) {
    const virtualCamera = new THREE.PerspectiveCamera(60, this.el.width / this.el.height, 0.01, 1000);
    virtualCamera.position.set(data.x, data.y, data.altitude);
    //  virtualCamera.rotation.set(MathUtils.degToRad(data.pitch), MathUtils.degToRad(data.heading), MathUtils.degToRad(data.roll));
    virtualCamera.updateMatrixWorld();
    return virtualCamera;
  }

  isPointInGeometry(x, y, alt, heading, pitch, roll) {
    const virtualCamera = this.createVirtualCamera({
      x: x,
      y: y,
      altitude: alt,
      pitch: pitch, heading: heading, roll: roll
    });
    /*this.raycaster.set(virtualCamera.position, virtualCamera.getWorldDirection(new THREE.Vector3()));

    const intersects = this.raycaster.intersectObject(this.highlightMesh)
    return intersects;
    */

    const geometry = this.scene.children.find(o => o.name === 'boxShape');
    this.raycaster.set(virtualCamera.position, virtualCamera.getWorldDirection(new THREE.Vector3()));
    // Check for intersections with the mesh
    const intersects = this.raycaster.intersectObject(geometry, true);
    return intersects;

  }

  highlightModelArea() {
    // Calculate the bounding box of the model
    const boundingBox = new THREE.Box3().setFromObject(this.mesh);

    // Create the wireframe box that fits within the bounding box
    const geometry = new THREE.BoxGeometry(boundingBox.max.x - boundingBox.min.x, boundingBox.max.y - boundingBox.min.y, boundingBox.max.z - boundingBox.min.z);
    const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });

    const highlightBox = new THREE.Mesh(geometry, material);

    // Calculate the center point of the model's bounding box
    const center = new THREE.Vector3();
    boundingBox.getCenter(center);

    // Calculate the center point of the highlighted area
    const highlightCenter = new THREE.Vector3();
    // Calculate the offset to position the highlight box correctly
    const offset = highlightCenter.clone().sub(center);

    highlightBox.position.copy(center).add(offset);
    this.scene.add(highlightBox);
  }


  removeHighlight() {
    const geometries = this.scene.children.filter(o => o.name === 'boxShape')
    geometries.forEach(geo => {
      this.scene.remove(geo)
    });
  }

  removeLines() {
    const geometries = this.scene.children.filter(o => o.name === 'linesGeo')
    geometries.forEach(geo => {
      this.scene.remove(geo)
    });
    this.geometry = null;
  }

  rectanglePoints = [];
  geometry;
  vertices;
  isDrawing: boolean;
  onMeasurementRectangle(event, type) {
    const _this = this;
    if (type === 'click') {
      if (this.geometry) {
        this.scene.remove(this.geometry);
        this.rectanglePoints = [];
      }
      this.controls.enabled = false;
      this.isDrawing = true;
      let intersects = _this.getIntersections(event);
      if (intersects.length > 0) {
        _this.rectanglePoints.push(intersects[0].point, intersects[0].point)

      }
    }

    if (type === 'release') {
      this.controls.enabled = true;
      this.isDrawing = false;
      if (this.points.length) {
        this.createRectangleBox();
        _this.settingsChanges.next('rc_points');
      }
      this.points = [];
    }
    if (type === 'move' && this.isDrawing && _this.rectanglePoints.length) {
      let intersects = _this.getIntersections(event);
      if (intersects.length > 0) {
        const point = intersects[0].point;
        // Define the two points between which you want to draw the rectangle
        const point1 = new THREE.Vector3(_this.rectanglePoints[0].x, _this.rectanglePoints[0].y,
          _this.rectanglePoints[0].z);
        const point2 = new THREE.Vector3(point.x, point.y, point.z);
        this.removeHighlight();
        if (this.geometry) {
          this.updateRectanglePoints(
            point1, point2)
        } else {
          this.createRectangleGeometry(point1, point2)

        }
      }
    }


  }



  rectangle;
  // Create an array to hold selectable objects
  selectableObjects = [];
  startPoint = new THREE.Vector2();
  endPoint = new THREE.Vector2();
  startDrawPoint = new THREE.Vector3();
  endDrawPoint = new THREE.Vector3();
  drawing;
  intersectionPoint = new THREE.Vector3();
  lineMaterial = new THREE.LineBasicMaterial({
    color: 0xff5555,
    linewidth: 2,
    transparent: true,
    opacity: 0.75,
    side: THREE.DoubleSide,
    depthTest: false
  });
  onMeasurementRectangle1(event, type) {
    const _this = this;
    if (event.which === 1) {
      return;
    }
    if (type === 'click') {
      this.removeHighlight();
      this.controls.enabled = false;
      this.controls.enablePan = false;
      this.drawing = true;
      const mouse = new THREE.Vector2((event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1,
        -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1);

      const intersection = this.getIntersections(event);
      if (intersection.length) {
        this.startDrawPoint.copy(intersection[0].point);
        this.endDrawPoint.copy(intersection[0].point);
      }
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, this.camera);

      const intersectionPoint = new THREE.Vector3();
      raycaster.ray.intersectPlane(new THREE.Plane(new THREE.Vector3(0, 0, 1), 0), intersectionPoint);

      this.startPoint.copy(intersectionPoint); // Set the starting point based on the cursor position
    }
    if (type === 'move') {
      if (this.drawing) {

        this.endPoint.x = (event.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        this.endPoint.y = - (event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;

        const intersection = this.getIntersections(event);
        if (intersection.length) {
          this.endDrawPoint.copy(intersection[0].point);
        }
        // Update the raycaster's origin and direction based on the camera and mouse position
        this.raycaster.setFromCamera(this.endPoint, this.camera);

        // Calculate the intersection point with a plane at z = 0
        this.raycaster.ray.intersectPlane(new THREE.Plane(new THREE.Vector3(0, 0, 1), 0), this.intersectionPoint);
        // Update the selection line's vertices based on intersection point
        this.endPoint.copy(this.intersectionPoint);

        // Remove previous rectangle if exists
        if (this.rectangle) {
          this.scene.remove(this.rectangle);
        }


        // Create a rectangle using Line segments
        const geometry = new THREE.BufferGeometry();
        const vertices = new Float32Array([
          this.startPoint.x, this.startPoint.y, 0,
          this.startPoint.x, this.endPoint.y, 0,
          this.endPoint.x, this.endPoint.y, 0,
          this.endPoint.x, this.startPoint.y, 0,
          this.startPoint.x, this.startPoint.y, 0
        ]);
        this.lineMaterial.renderOrder = 1;
        geometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));

        this.rectangle = new THREE.Line(geometry, this.lineMaterial);


        this.scene.add(this.rectangle);

      }
    }

    if (type === 'release') {
      this.controls.enabled = true;
      this.drawing = false;
      this.controls.enablePan = true;

      this.points = [
        new THREE.Vector3(this.startDrawPoint.x, this.startDrawPoint.y, this.startDrawPoint.z),
        new THREE.Vector3(this.endDrawPoint.x, this.startDrawPoint.y, this.startDrawPoint.z),
        new THREE.Vector3(this.endDrawPoint.x, this.endDrawPoint.y, this.endDrawPoint.z),
        new THREE.Vector3(this.startDrawPoint.x, this.endDrawPoint.y, this.endDrawPoint.z)
      ]

      this.createRectangleBox();
      // Remove the rectangle from the scene
      if (this.rectangle) {
        this.scene.remove(this.rectangle);
        this.rectangle = null;
      }
      _this.settingsChanges.next('rc_points');
    }

  }

  performObjectSelection(minX, minY, maxX, maxY) {
    const normalizedMouse = new THREE.Vector2(
      (minX + maxX) / 2,
      (minY + maxY) / 2
    );

    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(normalizedMouse, this.camera);

    const intersects = raycaster.intersectObjects(this.selectableObjects);

    if (intersects.length > 0) {
      // Perform selection logic for the intersected objects
      const selectedObject = intersects[0].object;
    }
  }



  calculateBoundingBox(points) {
    const min = new THREE.Vector3(Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY);
    const max = new THREE.Vector3(Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY, Number.NEGATIVE_INFINITY);

    for (const point of points) {
      min.min(point);
      max.max(point);
    }

    return new THREE.Box3(min, max);
  }

  deleteByIndex(index) {
    const id = this.markers[index].id;
    this.scene.children.filter(o => o.name == id).map((item) => {
      this.scene.remove(item)
    })
    this.markers.splice(index, 1);
  }

  removeAll() {
    this.markers.forEach((marker, i) => {
      const id = this.markers[i].id;
      this.scene.children.filter(o => o.name == id).map((item) => {
        this.scene.remove(item)
      })
    });
    this.markers = [];
    this.distanceClick = 0;
    this.distanceShapes = [];
    this.distancePoints = [];
    this.angleClick = 0;
    this.angleShapes = [];
    this.anglePoints = [];
    this.isDrawing = false;
    this.removeHighlight();
    this.removeLines();
    this.removeCube();
  }


  angleClick = 0;
  angleShapes = [];
  anglePoints = [];
  onMeasurementAngle(event) {
    const _this = this;

    if (_this.angleClick == 0) {
      this.markerId = uuidv4()
    }
    let intersects = _this.getIntersections(event);
    if (intersects.length > 0) {
      _this.anglePoints.push(new THREE.Vector3());
      const radius = _this.dimensions.length() / 500;
      const shape = createSphereGeometry(radius, _this.markerId);
      if (_this.angleClick != 1) {
        _this.scene.add(shape);
      }
      _this.angleShapes.push(shape)
      _this.anglePoints[_this.angleClick].copy(intersects[0].point);
      _this.angleShapes[_this.angleClick].position.copy(intersects[0].point);
      _this.angleClick++;
      if (_this.angleClick > 1) {
        const line = createLine(_this.anglePoints[_this.anglePoints.length - 2], _this.anglePoints[_this.anglePoints.length - 1], _this.markerId);
        _this.scene.add(line);
        if (_this.angleClick == 3) {
          var a = new THREE.Vector3().copy(_this.anglePoints[1]).sub(_this.anglePoints[0]);
          var b = new THREE.Vector3().copy(_this.anglePoints[1]).sub(_this.anglePoints[2]);
          var angle = a.angleTo(b);
          const measurementLabel = createLabel(_this.markerId);
          this.scene.add(measurementLabel)
          const angleVal = THREE.MathUtils.radToDeg(angle).toFixed(2);
          measurementLabel.element.innerText =
            `${angleVal}°`
          measurementLabel.position.lerpVectors(_this.anglePoints[0], _this.anglePoints[2], 0.5);
          _this.markers.push({
            title: `${angleVal}°`,
            points: _this.anglePoints.map(element => {
              return { x: element.x, y: element.y, z: element.z }
            }),
            id: _this.markerId
          })
          _this.angleClick = 0;
          _this.angleShapes = [];
          _this.anglePoints = [];
        }
      }
    }
  }


  /* areaClick = 0;
   areaShapes = [];
   areaPoints = [];
   onMeasurementArea(event) {
     const _this = this;
     if (_this.areaClick == 0) {
       this.markerId = uuidv4()
     }
     let intersects = _this.getIntersections(event);
     if (intersects.length > 0) {
       const colorAttribute = intersects[0]
         .object.geometry.getAttribute('color');
       const face = intersects[0].face;
 
       const color = new THREE.Color(Math.random() * 0xff0000);
 
       intersects[0]
         .object.material.color.set(color)
       // colorAttribute.setXYZ(face.a, color.r, color.g, color.b);
       // colorAttribute.setXYZ(face.b, color.r, color.g, color.b);
       //  colorAttribute.setXYZ(face.c, color.r, color.g, color.b);
 
       colorAttribute.needsUpdate = true;
 
 
     }
 
   }
 */
  /*onMeasurementAreaComplete() {
    const _this = this;
    if (_this.areaClick > 1) {
      const line = createLine(_this.areaPoints[_this.areaPoints.length - 1], _this.areaPoints[0], _this.markerId);
      _this.scene.add(line);
      let points = _this.areaPoints;
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      _this.getVolume(geometry);
 
      //geometry.vertices = points;
      // Create the faces of the polygon
      // for (let i = 0; i < points.length - 2; i++) {
      //   const face = new THREE.Face3(0, i + 1, i + 2);
      //   geometry.faces.push(face);
      // }
 
      // Create the mesh
      const mesh = new THREE.Mesh(geometry);
      // Calculate the surface areaW
      const faceCount = mesh.geometry.faces.length;
      let surfaceArea = 0;
      for (let i = 0; i < faceCount; i++) {
        const face = mesh.geometry.faces[i];
        const a = mesh.geometry.vertices[face.a];
        const b = mesh.geometry.vertices[face.b];
        const c = mesh.geometry.vertices[face.c];
        surfaceArea += new THREE.Triangle(a, b, c);
      }
 
      console.log(surfaceArea);
    }
  }
*/

  createGeometry() {
    const geometry = new THREE.BoxGeometry()
    const material = new THREE.MeshNormalMaterial()
    const cube = new THREE.Mesh(geometry, material)
    cube.position.set(this.mesh.position.x, this.mesh.position.y, this.mesh.position.z);
    this.scene.add(cube)
    const controls = new TransformControls(this.camera, this.renderer.domElement)
    controls.attach(cube)
    this.scene.add(controls);
    window.addEventListener('keydown', function (event) {
      switch (event.code) {
        case 'KeyG':
          controls.setMode('translate')
          break
        case 'KeyR':
          controls.setMode('rotate')
          break
        case 'KeyS':
          controls.setMode('scale')
          break
      }
    })

  }

  /*  calculateVolume() {
      const _this = this;
      if (_this.volumeClick > 1) {
        const line = createLine(_this.volumePoints[_this.volumePoints.length - 1], _this.volumePoints[0], _this.markerId);
        _this.scene.add(line);
        let points = _this.volumePoints;
 
        const triangles = earcut.flatten(points.map(p => [p.x, p.y, p.z]));
        function tetrahedronVolume(p1, p2, p3) {
          const v1 = new THREE.Vector3().subVectors(p1, p3);
          const v2 = new THREE.Vector3().subVectors(p2, p3);
          const v3 = new THREE.Vector3().crossVectors(v1, v2);
          return v3.dot(p3) / 6;
        }
 
        let volume = 0;
        for (let i = 0; i < triangles.length; i += 3) {
          const p1 = points[triangles[i]];
          const p2 = points[triangles[i + 1]];
          const p3 = points[triangles[i + 2]];
          volume += tetrahedronVolume(p1, p2, p3);
        }
        console.log(volume)
 
 
      }
 
    }*/

  loadObj(model, alignMenuOptions = false, meunOptions = true) {
    return new Promise((resolve, reject) => {
      const mtlLoader = new MTLLoader();
      const loader = new OBJLoader(MANAGER)
      const textureLoader = new THREE.TextureLoader();
      const texture = textureLoader.load(model.texturedUrl);
      const material = new THREE.MeshBasicMaterial({ map: texture });
      mtlLoader.load(model.mtlUrl, (mtl) => {
        mtl.preload();
        loader.setMaterials(mtl);
        loader.load(model.objUrl, (obj) => {
          obj.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              child.material = material;
            }
          });
          this.addGUI(meunOptions);

          this.setContent(obj, false);

          resolve(obj);
        },
          (xhr) => {
            if (xhr.lengthComputable) {
              this.progress = (xhr.loaded / xhr.total) * 100;
            }
          },
          (error) => {
            reject(error)
          }
        )
      });
    });
  }



  remove() {
    this.clear();
    cancelAnimationFrame(this.requestId);
    this.renderer.dispose();
    this.progress = 0;
  }

  getVolume(geometry) {
    if (!geometry.isBufferGeometry) {
      console.log("'geometry' must be an indexed or non-indexed buffer geometry");
      return 0;
    }
    var isIndexed = geometry.index !== null;
    let position = geometry.attributes.position;
    let sum = 0;
    let p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();
    if (!isIndexed) {
      let faces = position.count / 3;
      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, i * 3 + 0);
        p2.fromBufferAttribute(position, i * 3 + 1);
        p3.fromBufferAttribute(position, i * 3 + 2);
        sum += signedVolumeOfTriangle(p1, p2, p3);
      }
    }
    else {
      let index = geometry.index;
      let faces = index.count / 3;
      for (let i = 0; i < faces; i++) {
        p1.fromBufferAttribute(position, index.array[i * 3 + 0]);
        p2.fromBufferAttribute(position, index.array[i * 3 + 1]);
        p3.fromBufferAttribute(position, index.array[i * 3 + 2]);
        sum += signedVolumeOfTriangle(p1, p2, p3);
      }
    }
    return sum;
  }

  getIntersections(event) {
    var vector = new THREE.Vector2();
    vector.set(
      event.offsetX / this.renderer.domElement.clientWidth * 2 - 1,
      -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1
    );
    this.raycaster.setFromCamera(vector, this.camera);
    let intersects = this.raycaster.intersectObjects(this.scene.children);
    return intersects.filter(o => o.faceIndex).sort(function (a, b) {
      return a.distance - b.distance;
    });

  }

  addSelectionToCamera(camera) {
    // selection shape
    this.selectionShape = new THREE.Line();
    this.selectionShape.material.color.set(0xff9800).convertSRGBToLinear();
    this.selectionShape.renderOrder = 1;
    this.selectionShape.position.z = -.3;
    this.selectionShape.depthTest = false;
    this.selectionShape.scale.setScalar(1);
    camera.add(this.selectionShape);
  }

  changeControl(event) {
    this.controls.dispose();
    const camera = new PerspectiveCamera(this.camera.fov, this.camera.aspect, this.camera.near, this.camera.far);
    camera.position.copy(this.camera.position);
    camera.rotation.copy(this.camera.rotation);
    camera.up.copy(this.camera.up);
    this.camera = camera;
    this.camera.updateProjectionMatrix();
    if (event === 'orbit') {
      const orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
      orbitControl.dampingFactor = 0.2;
      orbitControl.enableDamping = true;
      orbitControl.enabled = true;
      orbitControl.target.copy(this.controls.target)
      this.controls = orbitControl;

    } else {
      // this.camera.up.set(0, 0, 1);
      const trackballControls = new TrackballControls(this.camera, this.renderer.domElement);
      trackballControls.rotateSpeed = 3.0;
      trackballControls.zoomSpeed = 1.2;
      trackballControls.panSpeed = 0.8;
      trackballControls.staticMoving = true;
      trackballControls.dynamicDampingFactor = 0.3;
      this.controls = trackballControls;
      //  this.camera.lookAt(this.controls.target);
    }

  }

  //Cropping
  createCubicShapeMesh() {
    const geometry = new THREE.BufferGeometry();
    const material = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      transparent: true,
      opacity: 0.5,
      depthWrite: false,
    });

    return new THREE.Mesh(geometry, material);
  }

  cubeShapeMesh;
  points = [];
  onCropMouseDown(event) {
    var vector = new THREE.Vector2();
    vector.set(
      event.offsetX / this.renderer.domElement.clientWidth * 2 - 1,
      -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1
    );
    this.raycaster.setFromCamera(vector, this.camera);
    const intersects = this.raycaster.intersectObject(this.cube);
    // Set dragging to true if the cube is clicked
    if (intersects.length > 0) {
      this.controls.enabled = false;
      this.dragging = true;
    }
    const intersectsModel = this.getIntersections(event);
    if (intersectsModel.length > 0) {
      this.cube.position.copy(intersectsModel[0].point);

    }
  }

  onCropMouseUp(event) {
    this.dragging = false;
    this.controls.enabled = true;
  }

  onCropMouseMove(event) {
    if (!this.dragging) return;

    var vector = new THREE.Vector2();
    vector.set(
      event.offsetX / this.renderer.domElement.clientWidth * 2 - 1,
      -(event.offsetY / this.renderer.domElement.clientHeight) * 2 + 1
    );
    this.raycaster.setFromCamera(vector, this.camera);
    let intersects = this.raycaster.intersectObjects(this.scene.children, true);

    // Move the cubic box to the intersection point on the model's surface
    if (intersects.length > 0) {
      const intersectionPoint = intersects[0].point;
      this.cube.position.copy(intersectionPoint);
    }
  }

  cube;
  cubePoints: any = [];
  initCube() {
    // Place the cubic box at the center of the model
    const boxSize = 1; // Size of the cubic box
    const color = Math.random() * 0xffffff;
    this.cube = new THREE.Mesh(new THREE.BoxGeometry(boxSize, boxSize, boxSize), new THREE.MeshBasicMaterial({ color: color, transparent: true, opacity: 0.5 }));
    this.cube.position.set(0, 0, 0); // Place the box at the center of the model
    this.cube.name = uuidv4();
    this.scene.add(this.cube);

    const r = this.cube.material.color.r * 255;
    const g = this.cube.material.color.g * 255;
    const b = this.cube.material.color.b * 255;
    this.cubePoints.push({
      id: this.cube.name,
      color: `rgb(${r.toFixed(0)},${g.toFixed(0)},${b.toFixed(0)},0.5)`,
      size: boxSize
    })
  }

  changeCubeSize(size, cube) {
    const select = this.cubePoints.find(o => o.id === cube.id);
    cube.size = size;
    this.scene.children.filter(o => o.name == select.id).map((item) => {
      item.scale.set(size, size, size)
    })
  }

  deleteCube(cube) {
    const select = this.cubePoints.find(o => o.id === cube.id);
    this.scene.children.filter(o => o.name == select.id).map((item) => {
      this.scene.remove(item)
    })
    this.cubePoints = this.cubePoints.filter(item => item.id != select.id)
  }

  removeCubeShapes() {
    this.cubePoints.forEach(point => {
      this.scene.children.filter(o => o.name == point.id).map((cube) => {

        // Extract the positions of the left bottom and top right corners
        /* const leftBottomCorner = boundingBox.min.clone();
             const topRightCorner = boundingBox.max.clone();
             const minLocal = this.mesh.worldToLocal(leftBottomCorner)
 
             const maxLocal = this.mesh.worldToLocal(topRightCorner)
 
 
             const blenderMinX = minLocal.x;
             const blenderMinY = minLocal.z; // Swap Y and Z
             const blenderMinZ = -minLocal.y; // Negate Z
 
             const blenderMaxX = maxLocal.x;
             const blenderMaxY = maxLocal.z; // Swap Y and Z
             const blenderMaxZ = -maxLocal.y; // Negate Z
     */

        // Write a custom shader that discards fragments outside the cropping cube
        /*  const clippingShader = `
  varying vec3 vWorldPosition;
  void main() {
      vWorldPosition = (modelMatrix * vec4(position, 1.0)).xyz;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
  `;
 
          // Set up the custom material with the clipping shader
          const customMaterial = new THREE.ShaderMaterial({
            vertexShader: clippingShader,
            fragmentShader: `
      varying vec3 vWorldPosition;
      void main() {
          if (vWorldPosition.x < cropCubeMinX || vWorldPosition.x > cropCubeMaxX ||
              vWorldPosition.y < cropCubeMinY || vWorldPosition.y > cropCubeMaxY ||
              vWorldPosition.z < cropCubeMinZ || vWorldPosition.z > cropCubeMaxZ) {
              discard;
          }
          gl_FragColor = vec4(1.0, 1.0, 1.0, 1.0);
      }
  `,
            uniforms: {
              cropCubeMinX: { value: cropCube.position.x - cropCube.scale.x / 2 },
              cropCubeMaxX: { value: cropCube.position.x + cropCube.scale.x / 2 },
              cropCubeMinY: { value: cropCube.position.y - cropCube.scale.y / 2 },
              cropCubeMaxY: { value: cropCube.position.y + cropCube.scale.y / 2 },
              cropCubeMinZ: { value: cropCube.position.z - cropCube.scale.z / 2 },
              cropCubeMaxZ: { value: cropCube.position.z + cropCube.scale.z / 2 },
            }
          });
 
          // Apply the custom material to the model
 
  this.mesh.children[0].material = customMaterial;
        */

      })
    });

  }

  removeCube() {

    this.cubePoints.forEach(point => {
      this.scene.children.filter(o => o.name == point.id).map((cube) => {
        this.scene.remove(cube);
      })
    })
    this.cubePoints = [];
  }

  cropCubeArea() {
    this.mesh.traverse((node) => {
      if (node.isMesh) {
        node.material = this.createCustomMaterial(this.cube);
      }
    });
    this.removeCube();
  }

  createCustomMaterial(cube) {
    const vertexShader = `
      varying vec3 vWorldPosition;
      void main() {
          vec4 worldPosition = modelMatrix * vec4( position, 1.0 );
          vWorldPosition = worldPosition.xyz;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
      }
  `;

    const fragmentShader = `
      varying vec3 vWorldPosition;
      uniform vec3 cubePosition;
      uniform vec3 cubeSize;
      void main() {
          vec3 cubeMin = cubePosition - cubeSize * 0.5;
          vec3 cubeMax = cubePosition + cubeSize * 0.5;
          if (all(greaterThanEqual(vWorldPosition, cubeMin)) && all(lessThanEqual(vWorldPosition, cubeMax))) {
              gl_FragColor = vec4(1.0);
          } else {
              discard;
          }
      }
  `;

    const material = new THREE.ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      uniforms: {
        cubePosition: { value: cube.position },
        cubeSize: { value: cube.scale }
      }
    });

    return material;
  }

/*
  invWorldMatrix = new THREE.Matrix4();
  camLocalPosition = new THREE.Vector3();
  tempRay = new THREE.Ray();
  centroid = new THREE.Vector3();
  screenCentroid = new THREE.Vector3();
  faceNormal = new THREE.Vector3();
  toScreenSpaceMatrix = new THREE.Matrix4();
  boxPoints = new Array(8).fill(7).map(() => new THREE.Vector3());
  boxLines = new Array(12).fill(11).map(() => new THREE.Line3());
  lassoSegments = [];
  perBoundsSegments = [];

  updateSelection() {
    // TODO: Possible improvements
    // - Correctly handle the camera near clip
    // - Improve line line intersect performance?

    this.toScreenSpaceMatrix
      .copy(this.mesh.matrixWorld)
      .premultiply(this.camera.matrixWorldInverse)
      .premultiply(this.camera.projectionMatrix);

    // create scratch points and lines to use for selection
    while (this.lassoSegments.length < this.selectionPoints.length) {
      this.lassoSegments.push(new THREE.Line3());
    }

    this.lassoSegments.length = this.selectionPoints.length;

    for (let s = 0, l = this.selectionPoints.length; s < l; s += 3) {

      const line = this.lassoSegments[s];
      const sNext = (s + 3) % l;
      line.start.x = this.selectionPoints[s];
      line.start.y = this.selectionPoints[s + 1];

      line.end.x = this.selectionPoints[sNext];
      line.end.y = this.selectionPoints[sNext + 1];

    }

    this.invWorldMatrix.copy(this.mesh.matrixWorld).invert();
    this.camLocalPosition.set(0, 0, 0).applyMatrix4(this.camera.matrixWorld).applyMatrix4(this.invWorldMatrix);

    const indices = [];
    this.mesh.children[0].geometry.boundsTree.shapecast({
      intersectsBounds: (box, isLeaf, score, depth) => {

        // check if bounds intersect or contain the lasso region
        if (!this.params.useBoundsTree) {

          return INTERSECTED;

        }

        // Get the bounding box points
        const { min, max } = box;
        let index = 0;

        let minY = Infinity;
        let maxY = - Infinity;
        let minX = Infinity;
        for (let x = 0; x <= 1; x++) {

          for (let y = 0; y <= 1; y++) {

            for (let z = 0; z <= 1; z++) {

              const v = this.boxPoints[index];
              v.x = x === 0 ? min.x : max.x;
              v.y = y === 0 ? min.y : max.y;
              v.z = z === 0 ? min.z : max.z;
              v.w = 1;
              v.applyMatrix4(this.toScreenSpaceMatrix);
              index++;

              if (v.y < minY) minY = v.y;
              if (v.y > maxY) maxY = v.y;
              if (v.x < minX) minX = v.x;

            }

          }

        }

        // Find all the relevant segments here and cache them in the above array for
        // subsequent child checks to use.
        const parentSegments = this.perBoundsSegments[depth - 1] || this.lassoSegments;
        const segmentsToCheck = this.perBoundsSegments[depth] || [];
        segmentsToCheck.length = 0;
        this.perBoundsSegments[depth] = segmentsToCheck;
        for (let i = 0, l = parentSegments.length; i < l; i++) {

          const line = parentSegments[i];
          const sx = line.start.x;
          const sy = line.start.y;
          const ex = line.end.x;
          const ey = line.end.y;
          if (sx < minX && ex < minX) continue;

          const startAbove = sy > maxY;
          const endAbove = ey > maxY;
          if (startAbove && endAbove) continue;

          const startBelow = sy < minY;
          const endBelow = ey < minY;
          if (startBelow && endBelow) continue;

          segmentsToCheck.push(line);

        }

        if (segmentsToCheck.length === 0) {

          return NOT_INTERSECTED;

        }

        // Get the screen space hull lines
        const hull = this.getConvexHull(this.boxPoints);
        const lines = hull.map((p, i) => {

          const nextP = hull[(i + 1) % hull.length];
          const line = this.boxLines[i];
          line.start.copy(p);
          line.end.copy(nextP);
          return line;

        });

        // If a lasso point is inside the hull then it's intersected and cannot be contained
        if (this.pointRayCrossesSegments(segmentsToCheck[0].start, lines) % 2 === 1) {

          return INTERSECTED;

        }

        // check if the screen space hull is in the lasso
        let crossings = 0;
        for (let i = 0, l = hull.length; i < l; i++) {

          const v = hull[i];
          const pCrossings = this.pointRayCrossesSegments(v, segmentsToCheck);

          if (i === 0) {

            crossings = pCrossings;

          }

          // if two points on the hull have different amounts of crossings then
          // it can only be intersected
          if (crossings !== pCrossings) {

            return INTERSECTED;

          }

        }

        // check if there are any intersections
        for (let i = 0, l = lines.length; i < l; i++) {

          const boxLine = lines[i];
          for (let s = 0, ls = segmentsToCheck.length; s < ls; s++) {

            if (this.lineCrossesLine(boxLine, segmentsToCheck[s])) {

              return INTERSECTED;

            }

          }

        }

        return crossings % 2 === 0 ? NOT_INTERSECTED : CONTAINED;

      },

      intersectsTriangle: (tri, index, contained, depth) => {

        const i3 = index * 3;
        const a = i3 + 0;
        const b = i3 + 1;
        const c = i3 + 2;

        // check all the segments if using no bounds tree
        const segmentsToCheck = this.params.useBoundsTree ? this.perBoundsSegments[depth] : this.lassoSegments;
        if (this.params.selectionMode === 'centroid' || this.params.selectionMode === 'centroid-visible') {

          // get the center of the triangle
          this.centroid.copy(tri.a).add(tri.b).add(tri.c).multiplyScalar(1 / 3);
          this.screenCentroid.copy(this.centroid).applyMatrix4(this.toScreenSpaceMatrix);

          // counting the crossings
          if (
            contained ||
            this.pointRayCrossesSegments(this.screenCentroid, segmentsToCheck) % 2 === 1
          ) {

            // if we're only selecting visible faces then perform a ray check to ensure the centroid
            // is visible.
            if (this.params.selectionMode === 'centroid-visible') {

              tri.getNormal(this.faceNormal);
              this.tempRay.origin.copy(this.centroid).addScaledVector(this.faceNormal, 1e-6);
              this.tempRay.direction.subVectors(this.camLocalPosition, this.centroid);

              const res = this.mesh.children[0].geometry.boundsTree.raycastFirst(this.tempRay, THREE.DoubleSide);
              if (res) {

                return false;

              }

            }

            indices.push(a, b, c);
            return this.params.selectModel;

          }

        } else if (this.params.selectionMode === 'intersection') {

          // if the parent bounds were marked as contained then we contain all the triangles within
          if (contained) {

            indices.push(a, b, c);
            return this.params.selectModel;

          }

          // get the projected vertices
          const vertices = [
            tri.a,
            tri.b,
            tri.c,
          ];

          // check if any of the vertices are inside the selection and if so then the triangle is selected
          for (let j = 0; j < 3; j++) {

            const v = vertices[j];
            v.applyMatrix4(this.toScreenSpaceMatrix);

            const crossings = this.pointRayCrossesSegments(v, segmentsToCheck);
            if (crossings % 2 === 1) {

              indices.push(a, b, c);
              return this.params.selectModel;

            }

          }

          // get the lines for the triangle
          const lines = [
            this.boxLines[0],
            this.boxLines[1],
            this.boxLines[2],
          ];

          lines[0].start.copy(tri.a);
          lines[0].end.copy(tri.b);

          lines[1].start.copy(tri.b);
          lines[1].end.copy(tri.c);

          lines[2].start.copy(tri.c);
          lines[2].end.copy(tri.a);

          // check for the case where a selection intersects a triangle but does not contain any
          // of the vertices
          for (let i = 0; i < 3; i++) {

            const l = lines[i];
            for (let s = 0, sl = segmentsToCheck.length; s < sl; s++) {

              if (this.lineCrossesLine(l, segmentsToCheck[s])) {

                indices.push(a, b, c);
                return this.params.selectModel;

              }

            }

          }

        }

        return false;

      }

    });

    const indexAttr = this.mesh.children[0].geometry.index;
    const newIndexAttr = this.highlightMesh.geometry.index;
    if (indices.length && this.params.selectModel) {

      // if we found indices and we want to select the whole model
      for (let i = 0, l = indexAttr.count; i < l; i++) {

        const i2 = indexAttr.getX(i);
        newIndexAttr.setX(i, i2);

      }

      this.highlightMesh.geometry.drawRange.count = Infinity;
      newIndexAttr.needsUpdate = true;

    } else {

      // update the highlight mesh
      for (let i = 0, l = indices.length; i < l; i++) {

        const i2 = indexAttr.getX(indices[i]);
        newIndexAttr.setX(i, i2);

      }

      this.highlightMesh.geometry.drawRange.count = indices.length;
      newIndexAttr.needsUpdate = true;
    }

  }
*/
  // Math Functions
  // https://www.geeksforgeeks.org/convex-hull-set-2-graham-scan/
  getConvexHull(points) {

    function orientation(p, q, r) {
      const val =
        (q.y - p.y) * (r.x - q.x) -
        (q.x - p.x) * (r.y - q.y);

      if (val == 0) {

        return 0; // colinear

      }

      // clockwise or counterclockwise
      return (val > 0) ? 1 : 2;

    }

    function distSq(p1, p2) {

      return (p1.x - p2.x) * (p1.x - p2.x) +
        (p1.y - p2.y) * (p1.y - p2.y);

    }

    function compare(p1, p2) {

      // Find orientation
      const o = orientation(p0, p1, p2);
      if (o == 0)
        return (distSq(p0, p2) >= distSq(p0, p1)) ? - 1 : 1;

      return (o == 2) ? - 1 : 1;

    }

    // find the lowest point in 2d
    let lowestY = Infinity;
    let lowestIndex = - 1;
    for (let i = 0, l = points.length; i < l; i++) {

      const p = points[i];
      if (p.y < lowestY) {

        lowestIndex = i;
        lowestY = p.y;

      }

    }

    // sort the points
    const p0 = points[lowestIndex];
    points[lowestIndex] = points[0];
    points[0] = p0;

    points = points.sort(compare);

    // filter the points
    let m = 1;
    const n = points.length;
    for (let i = 1; i < n; i++) {

      while (i < n - 1 && orientation(p0, points[i], points[i + 1]) == 0) {

        i++;

      }

      points[m] = points[i];
      m++;

    }

    // early out if we don't have enough points for a hull
    if (m < 3) return null;

    // generate the hull
    const hull = [points[0], points[1], points[2]];
    for (let i = 3; i < m; i++) {

      while (orientation(hull[hull.length - 2], hull[hull.length - 1], points[i]) !== 2) {

        hull.pop();

      }

      hull.push(points[i]);

    }

    return hull;

  }

  pointRayCrossesLine(point, line, prevDirection, thisDirection) {
    const { start, end } = line;
    const px = point.x;
    const py = point.y;

    const sy = start.y;
    const ey = end.y;

    if (sy === ey) return false;

    if (py > sy && py > ey) return false; // above
    if (py < sy && py < ey) return false; // below

    const sx = start.x;
    const ex = end.x;
    if (px > sx && px > ex) return false; // right
    if (px < sx && px < ex) { // left

      if (py === sy && prevDirection !== thisDirection) {

        return false;

      }

      return true;

    }

    // check the side
    const dx = ex - sx;
    const dy = ey - sy;
    const perpx = dy;
    const perpy = - dx;

    const pdx = px - sx;
    const pdy = py - sy;

    const dot = perpx * pdx + perpy * pdy;

    if (Math.sign(dot) !== Math.sign(perpx)) {

      return true;

    }

    return false;

  }

  pointRayCrossesSegments(point, segments) {
    let crossings = 0;
    const firstSeg = segments[segments.length - 1];
    let prevDirection = firstSeg.start.y > firstSeg.end.y;
    for (let s = 0, l = segments.length; s < l; s++) {

      const line = segments[s];
      const thisDirection = line.start.y > line.end.y;
      if (this.pointRayCrossesLine(point, line, prevDirection, thisDirection)) {

        crossings++;

      }

      prevDirection = thisDirection;

    }

    return crossings;

  }

  // https://stackoverflow.com/questions/3838329/how-can-i-check-if-two-segments-intersect
  lineCrossesLine(l1, l2) {

    function ccw(A, B, C) {

      return (C.y - A.y) * (B.x - A.x) > (B.y - A.y) * (C.x - A.x);

    }

    const A = l1.start;
    const B = l1.end;

    const C = l2.start;
    const D = l2.end;

    return ccw(A, C, D) !== ccw(B, C, D) && ccw(A, B, C) !== ccw(A, B, D);

  }


  latLongToVector3(latitude, longitude, altitude) {
    const latRad = latitude * Math.PI / 180;
    const lonRad = longitude * Math.PI / 180;
    // Set up earth radius (adjust for your needs)
    const earthRadius = 6371e3; // Earth radius in meters

    // Calculate x, y, z coordinates using spherical coordinates
    const x = earthRadius * Math.cos(latRad) * Math.cos(lonRad);
    const y = earthRadius * Math.sin(latRad);
    const z = earthRadius * Math.cos(latRad) * Math.sin(lonRad);

    // Return the Vector3 position
    return new THREE.Vector3(x, y + altitude, z);


  }

  createLinePoints(images, vectorA, vectorB) {

    const path = new THREE.LineCurve3(vectorA, vectorB);

    // Create a tube geometry to represent the thick line
    const geometry = new THREE.TubeGeometry(path, 1, 8, 8, false);

    // Create a material for the thick line
    const material = new THREE.MeshBasicMaterial({ color: 0xff5555 });

    // Create a mesh from the geometry and material
    const line = new THREE.Mesh(geometry, material);

    // Optionally, give the line a name
    
    //const line = createLine(point1, point2, uuidv4(),10);
    line.name="connected_line"
    this.scene.add(line);

    // Calculate the midpoint between the two points
  //  const midpoint = new THREE.Vector3().copy(point1).lerp(point2, 0.5);
    // Calculate the direction vector from point1 to point2
 //   const direction = new THREE.Vector3().copy(point2).sub(point1).normalize();

    // Offset the camera position along the direction vector
 //   const distance = point1.distanceTo(point2);
    // Calculate the camera position
//    const offsetFactor = 1.5; // Adjust this factor to control how much extra space around the points you want in the camera's view
//    const offset = direction.multiplyScalar(distance * offsetFactor);
 //   const cameraPosition = midpoint.clone().add(offset);

    // Set the camera's position and lookAt
    //this.camera.position.copy(cameraPosition);
    //this.camera.lookAt(midpoint);

  }

}

function signedVolumeOfTriangle(p3, p2, p1) {
  return p1.dot(p2.cross(p3)) / 6.0;
}

function traverseMaterials(object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}

function createLine(vectorA, vectorB, boxId,linewidth=2) {
  var lineGeometry = new THREE.BufferGeometry().setFromPoints([new THREE.Vector3(), new THREE.Vector3()]);
  var lineMaterial = new THREE.LineBasicMaterial({
    color: 0xff5555,
    linewidth: linewidth,
    transparent: true,
    // opacity: 0.75
  });
  const line = new THREE.Line(lineGeometry, lineMaterial);
  line.name = boxId;
  line.geometry.attributes.position.setXYZ(0, vectorA.x, vectorA.y, vectorA.z);
  line.geometry.attributes.position.setXYZ(1, vectorB.x, vectorB.y, vectorB.z);
  line.geometry.attributes.position.needsUpdate = true;
  return line;
}

function createSphereGeometry(radius, boxId) {
  var marker = new THREE.Mesh(
    new THREE.SphereGeometry(radius, 12, 12),
    new THREE.MeshBasicMaterial({
      color: 0xff5555,
    }
    )
  );
  marker.name = boxId;
  return marker;
}

function createLabel(boxId) {
  const measurementDiv = document.createElement(
    'div'
  ) as HTMLDivElement;
  measurementDiv.className = 'measurementLabel'
  const measurementLabel = new CSS2DObject(measurementDiv)
  measurementLabel.name = boxId;
  return measurementLabel;
}


function getTriangleArea(p1, p2, p3) {
  var tr = new THREE.Triangle(p1, p2, p3);
  return tr.getArea();
}

function getTriangleVolume(p1, p2, p3) {
  return p1.dot(p2.cross(p3)) / 6.0;
}


