import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IImage } from 'src/app/models/image.interface';
import { GeneralService } from 'src/app/services/general.service';
import { UiService } from 'src/app/services/ui.service';
import { SelectContainerComponent } from 'ngx-drag-to-select';

@Component({
  selector: 'app-image-checkbox-grid',
  templateUrl: './image-checkbox-grid.component.html',
  styleUrls: ['./image-checkbox-grid.component.scss']
})
export class ImageCheckboxGridComponent implements OnInit {
  @ViewChild('containerGrid') selectContainer: SelectContainerComponent;
  public set selectedImagesIds$(images) {
    this.uiService.selectedImagesIds$.next(images);
  }

  public get selectedImagesIds$(): any {
    return this.uiService.selectedImagesIds$;
  }

  public set movedImages(images) {
    this.uiService.movedImages = images;
  }

  public get movedImages(): IImage[] {
    return this.uiService.movedImages;
  }

  @Input() images: any[] = [];
  @Input() selected: string[] = [];
  selectedImages: IImage[];
  public contextMenuPosition = { x: '0px', y: '0px' };
  @Output() selectedChange: EventEmitter<any> = new EventEmitter();
  @Output() switchTab: EventEmitter<boolean> = new EventEmitter();
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;
  constructor(private uiService: UiService,
    private snackBar: MatSnackBar,
    private generalService: GeneralService) { }

  ngOnInit(): void {
    this.selectedImagesIds$.next(this.selected);
  }

  onClickImageCheckbox(event: MouseEvent, id: string): void {
    const currentVal = this.selectedImagesIds$.value;
    let newVal;
    if (currentVal.includes(id)) {
      newVal = currentVal.filter(val => val !== id);
    } else {
      newVal = [...currentVal, id];
    }
    this.selectedImagesIds$.next(newVal);
    this.selectedChange.emit(newVal);
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }
  
  someMethod(event: MouseEvent) {
    this.selectedImagesIds$.next(this.selectedImages.map(o => o.id));
    this.selectedChange.emit(this.selectedImages.map(o => o.id));
  }

  isChecked(imageId: string): boolean {
    return this.selectedImages && this.selectedImages.map(selectedImage => selectedImage.id).includes(imageId)
  }

  isMovedImage(image: IImage): boolean {
    return this.movedImages.length && this.movedImages.map(image => image.id).includes(image.id);
  }
  itemDeselected() {
    if (!this.movedImages.length) {
      this.cancelMoving();
    }
  }
  cancelMoving() {
    this.movedImages = this.selectedImages = [];
    this.snackBar.dismiss();
    this.selectedImagesIds$.next([]);
    this.selectedChange.emit([]);
  }
  onScroll() {
    console.log('scroll!!!');
  }

}