import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { BackendService } from '../../services/backend.service';
import { UiService } from '../../services/ui.service';
@Component({
    selector: 'projects-add-dialog',
    templateUrl: './add-dialog.component.html',
    styleUrls: ['./add-dialog.component.scss']
})
export class ProjectsAddDialogComponent implements OnInit, OnDestroy {
    public name: string;
    public industry: string;
    public inspectionDate = this.formatDate();
    public assetProjects = [];
    public projectType: string = 'visual';
    public inspectionType: string = '';
    public isMembers: boolean = false;
    public isFeatures: boolean = false;
    public isFolders: boolean = false;
    public cameraType: string;
    public cameraModel: any;
    public modelsList;
    public members = [];
    public classDefinition: string;
    public standardClasses = [];
    public createBtnOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Create',
        raised: true,
        spinnerSize: 24,
        buttonColor: 'primary',
        spinnerColor: 'primary',
        mode: 'indeterminate',
        customClass: 'text-uppercase'
    };
    assetId: any;
    public get projects() {
        return this.uiService.allProjects;
    }

    constructor(
        public backend: BackendService,
        public uiService: UiService,
        public dialogRef: MatDialogRef<ProjectsAddDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.assetProjects = this.projects.filter(o => o.assetId == this.data.assetId).sort((a, b) => new Date(a?.createdDate.toDate()).getTime() - new Date(b?.createdDate.toDate()).getTime());
        this.assetId = this.data.asset.id;
        if (this.uiService.selectedSideTab === 'campaigns') {
            this.inspectionDate = this.uiService.selectedCampaignNode.company?.startDate;
        }

    }

    formatDate(){
        return moment().format('YYYY-MM-DD')
    }

    ngOnInit(): void {
        this.listenProjects();
        this.backend.getStandardClasses().subscribe((result) => {
            this.standardClasses = result;
        });
    }

    ngOnDestroy() { }

    close(): void {
        this.name = '';
        this.dialogRef.close();
    }

    isNameValid(name): boolean {
        return this.data.projects.indexOf(name) === -1;
    }

    handleCreate(name, inspectionDate, projectType, cameraType): void {
        if (name && inspectionDate && this.isNameValid(name)) {
            if (!this.isMembers) {
                this.members = [];
            }
            this.createBtnOptions.active = true;
            this.backend.createProject(name, inspectionDate, this.assetId, this.members, projectType, cameraType, this.cameraModel, this.inspectionType, this.classDefinition).then((result) => {
                this.createBtnOptions.active = false;
                if (this.classDefinition) {
                    this.isFeatures = false;
                }
                if (this.isFolders || this.isFeatures) {
                    this.backend.createFeaturesandFolders(this.assetProjects[this.assetProjects.length - 1].id, result.id, this.isFolders, this.isFeatures).subscribe(() => { });
                }
                this.close();
            });
        }

    }

    membersChoosen(event) {
        this.isMembers = event.checked;
    }

    listenProjects(): any {
        if (this.projects.length > 0) {
            let projects = this.projects.filter(o => o.assetId == this.data.assetId).sort((a, b) => new Date(a?.createdDate.toDate()).getTime() - new Date(b?.createdDate.toDate()).getTime());
            const project = projects[projects.length - 1];
            this.members = [];
            if (project) {
                Object.keys(project.people).forEach(userId => {
                    if (userId != this.backend.getCurrentUser().uid) {
                        this.backend.getUser(userId).pipe(take(1)).subscribe(IUser => {
                            const user = IUser.data();
                            const role = project.people[user.uid].role != 'owner' ? project.people[user.uid].role : 'editor';
                            this.members.push({
                                name: user.displayName || `${user.firstName} ${user.lastName}`,
                                id: user.uid,
                                role: role,
                                readonly: role == 'editor' ? false : project.people[user.uid].readonly
                            })
                        })
                    }
                });

            }

        }
    }

    remove(index) {
        this.members.splice(index, 1)
    }

    onCameraTypeChange() {
        this.cameraModel = ''
        if (this.cameraType == 'FLIR') {
            this.modelsList = [{
                text: 'ThermaCAM P660 West',
                value: 'FLIR ThermaCAM P660 West'
            }, { text: 'T640', value: 'FLIR T640' }, { text: 'E8 Wifi', value: 'FLIR E8 Wifi' }, { text: 'Vue Pro', value: 'FLIR Vue Pro' }]
        } else if (this.cameraType == 'DJI') {
            this.modelsList = [{ text: 'Zenmuse H20T', value: 'DJI Zenmuse H20T' }, { text: ' Zenmuse XT S', value: 'DJI Zenmuse XT S' },
            { text: 'DJI MAVIC2-ENTERPRISE-ADVANCED', value: 'DJI MAVIC2-ENTERPRISE-ADVANCED' }
            ]
        } else {
            this.modelsList = [{ value: 'SensflY' }]
        }
    }
}
