<div class="popup-header w-100 d-flex">
  <p class="w-100">
   Link Media
   <span>
     {{label}}
   </span>
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="d-flex  p-10 align-items-center justify-content-between border-bottom">
  <div>
    <ng-container *ngIf="folders?.length > 0; else loading">
      <span (click)="goToRoot()" class="project-name link-folder" >{{selectedProjectName$ | async}}</span>
      <ng-container *ngFor="let folder of foldersBreadcrumb">
        <span class="divider mx-1">&gt;</span>
        <span (click)="toggleFolder(folder)" class="subtitle link-folder" >{{
          folder.name
          }}</span>
      </ng-container>

    </ng-container>
    <ng-template #loading>
      <div class="h-100 w-100 flex align-center align-middle">
        <mat-spinner [diameter]="24"></mat-spinner>
      </div>
    </ng-template>
  </div>

  <div>
    <button  mat-raised-button color="primary" type="button" (click)="addImagesToTitle()">
      <span>Link Media</span>
    </button>
  </div>
</div>

<mat-dialog-content class="p-0 m-0 bg">
  <div class="d-flex">
    <mat-card class="two-coloumn-card">
      <div class="d-flex">
        <button (click)="activeTab(1)" [class.tab_active]="tab === 1" class="tab">
          Sub Folders
        </button>
        <button (click)="activeTab(2)" [class.tab_active]="tab === 2" class="tab">
          Features
        </button>
      </div>

      <div *ngIf="tab ==2" class="h-100">
        <div class="flex flex-column" style="overflow-y: auto;
        height: calc(100vh - 165px);">
          <div class="auto scroller">
            <div matTooltip="{{tag.tag}}" *ngFor="let tag of tags" class="tag-option"
              [class.active]="tag.tag === selectedTag" (click)="selectTagEl(tag.tag)">
              {{ tag.tag }}
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="tab ==1" class="h-100">
        <div class="flex flex-column"  style="overflow-y: auto;
        height: calc(100vh  - 165px);">
          <div class="auto scroller">
            <button *ngFor="let folder of showedFolders$ | async; let i = index" class="btn folder-item d-flex m-0 border-bottom"
            [class.active]="folder.path === (selectedFolder$ | async)?.path" (click)="toggleFolder(folder)">
            {{ folder.name }}
          </button>
          </div>  
        </div>

      </div>
    </mat-card>
    <dts-select-container style="padding: 10px !important; width:100%" #selectContainerTitle="dts-select-container"
    (select)="someMethod($event)" [(selectedItems)]="selectedImages" [disabled]="getReadOnlyForCurrentUser()"
    [style.minHeight]="'300px'">
    <p class="help-title">Use Ctrl/Shift + Click to select multiple images</p>
    <div class="modal-two-side-layout-images w-100" (scroll)="selectContainerTitle.update()" infiniteScroll
      [infiniteScrollDistance]="4" [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="onScroll()"
      style="height: calc(100vh - 194px); overflow-x:hidden;overflow-y: auto;width:100% !important">
      <!-- <div class="modal-two-side-layout-images" style="overflow-y: auto;"> -->
      <ng-container *ngIf="loading$ | async">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>
      <span *ngIf="
            !loading$.value &&
            ((folderImages$ | async)?.length === 0 && videos.length == 0) &&
            selectedFolder$.value !== null
          ">
        Folder is empty.
      </span>

      <div class="row position-relative m-0" *ngIf="
        (folderImages$ | async)?.length != 0 ||  videos.length != 0" [class.hidden]="loading$ | async">
        <ng-container *ngFor="let image of images">
          <div class="col-sm-4" style="padding: 5px" >
            <div [dtsSelectItem]="image"  [style.border]="isChecked(image.id) ? '2px solid #2196f3' : 'none'" [style.border]="isChecked(image.id) ? '2px solid #2196f3' : 'none'" class="image-wrapper project embed-responsive embed-responsive-16by9 mb-3"
              [style.pointerEvents]="blockedImagesIds.includes(image.id) ? 'none' : 'auto'">
              <div class="img-placeholder embed-responsive-item">
                <img [style.opacity]="isMovedImage(image) ? '.5' : '1'"
                  [src]="image.type=='videos'? image.posterImage : image.thumbFileUrl"
                  style="min-width: 100%; min-height: 100%; object-fit: cover" />
              </div>
              <mat-icon *ngIf="image.type=='videos'" class="vid-icon">video_library</mat-icon>

              <span *ngIf="blockedImagesIds.includes(image.id)" class="text-added">Added</span>
              <mat-checkbox [disabled]="true" [color]="'primary'" class="img-checkbox"
                [checked]="isChecked(image.id)">
              </mat-checkbox>
            </div>
            <div class="project-item__name">{{ image.fileName }}</div>
          </div>
        </ng-container>
      </div>

      <div class="w-100 d-flex justify-content-center" *ngIf="isFolderContentLoading$ | async">
        <!-- Loading... -->
        <span>
          <mat-spinner [diameter]="20"></mat-spinner>
        </span>
      </div>
    </div>
  </dts-select-container>
  </div>
</mat-dialog-content>