<div class="popup-header d-flex">
    <p class="w-100">
        {{data.fileName}}
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div>
    <div mat-dialog-content>
        <div *ngIf="data.logFrame" class="cell auto-scroller">
            <table class="table table-curved shadow-none">
                <tbody>
                    <tr>
                        <td>HEHDT</td>
                        <td>{{data.logFrame.HEHDT}}</td>
                    </tr>
                    <tr>
                        <td>HETHS</td>
                        <td>{{data.logFrame.HETHS}}</td>
                    </tr>
                    <tr>
                        <td>ATITUD</td>
                        <td>{{data.logFrame.ATITUD}}</td>
                    </tr>
                    <tr>
                        <td>SPEED</td>
                        <td>{{data.logFrame.SPEED}}</td>
                    </tr>
                    <tr>
                        <td>POSITI</td>
                        <td>{{data.logFrame.POSITI}}</td>
                    </tr>
                    <tr>
                        <td>TIME</td>
                        <td>
                            <mat-icon matTooltip="2 hr UTC time difference between frame and data log" style="position: relative;
                            top: 7px;cursor:pointer;
                            color: red">info</mat-icon>
                            {{data.logFrame.TIME}}
                        </td>
                    </tr>
                    <tr>
                        <td>HEAVE</td>
                        <td>{{data.logFrame.HEAVE}}</td>
                    </tr>
                    <tr>
                        <td>STDHRP</td>
                        <td>{{data.logFrame.STDHRP}}</td>
                    </tr>
                    <tr>
                        <td>STDPOS</td>
                        <td>{{data.logFrame.STDPOS}}</td>
                    </tr>
                    <tr>
                        <td>STDSPD</td>
                        <td>{{data.logFrame.STDSPD}}</td>
                    </tr>
                    <tr>
                        <td>UTCIN</td>
                        <td>{{data.logFrame.UTCIN}}</td>
                    </tr>

                    <tr>
                        <td>DEPIN</td>
                        <td>{{data.logFrame.DEPIN}}</td>
                    </tr>
                    <tr>
                        <td>ALGSTS</td>
                        <td>{{data.logFrame.ALGSTS}}</td>
                    </tr>
                    <tr>
                        <td>UTCIN</td>
                        <td>{{data.logFrame.UTCIN}}</td>
                    </tr>
                    <tr>
                        <td>STATUS</td>
                        <td>{{data.logFrame.STATUS}}</td>
                    </tr>
                    <tr>
                        <td>HT_STS</td>
                        <td>{{data.logFrame.HT_STS}}</td>
                    </tr>
                    <tr>
                        <td>USRSTS</td>
                        <td>{{data.logFrame.USRSTS}}</td>
                    </tr>
                    <tr>
                        <td>ALGSTX</td>
                        <td>{{data.logFrame.ALGSTX}}</td>
                    </tr>
                    <tr>
                        <td>SYSSTX</td>
                        <td>{{data.logFrame.SYSSTX}}</td>
                    </tr>
                    <tr>
                        <td>FileName</td>
                        <td>{{data.logFrame.FileName}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="!data.logFrame" class="text-center w-100 m-10 p-10 cell auto-scroller">
       No Log Found
        </div>
    </div>