<div (keyup.enter)="handleCreate(name)">
  <h1 mat-dialog-title>
    <mat-icon
      fontSet="material-icons-outlined"
      color="primary"
      class="mr-2 text-middle"
    >
      create_new_folder
    </mat-icon>
    <span>New Group</span>
  </h1>

  <div mat-dialog-content class="pb-5">
    <mat-form-field class="w-100 mb-2" appearance="fill">
      <mat-label>Group name</mat-label>
      <input matInput cdkFocusInitial [(ngModel)]="name" placeholder="Group name"/>
    </mat-form-field>
    <div class="input-error" *ngIf="!isNameValid(name)">Group already exists</div>
  </div>

  <div mat-dialog-actions>
    <button mat-button (click)="close()">Cancel</button>
    <mat-spinner-button
      [options]="createBtnOptions"
      (btnClick)="handleCreate(name)"
      [disabled]="!name || !isNameValid(name)"
    ></mat-spinner-button>
  </div>

</div>
