<div class="popup-header w-100 d-flex">
    <p class="w-100">
    Labels
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>
<div class="cell">
    <div  matTooltip="Choose Technical Drawing" (click)="toggleRightMenu()" [style.right]="!isRightMenu ? '0px' : '20vw'" *ngIf="technicalDrawings.length > 0"
        class="toggle-menu">
        <mat-icon>{{!isRightMenu ? 'arrow_left' : 'arrow_right'}}</mat-icon>
    </div>
    <mat-card  class="auto scroller p-0 border-0 shadow-none" style="height:calc(100vh - 230px)" >
        <!-- <mat-card-title> Labels </mat-card-title> -->
        <div *ngIf="data.model === '2d' && isRightMenu && technicalDrawings.length > 0" class="mb-2 carusal-inner">
            <div *ngFor="let image of technicalDrawings; let i = index" (click)="activateImage(image)">
                <img class="carusal-image" [src]="image.fileUrl" [class.active]="image.id == model?.id" />
                <div class="filename">{{ image.fileName }}</div>
            </div>
        </div>
            <div *ngFor="let label of labels" (click)="labelClick(label)">
                <p class="tag-option py-2 px-3 m-0 border-bottom" matTooltip="{{label.title}}" [class.active]="label.id === selectedLabel">
                    {{ label.title}}
                </p>
            </div>

    </mat-card>
    <button  class="bottom-button" mat-raised-button [disabled]="!selectedLabel"
        color="primary" (click)="linkImageToLabel()">
        <span class="text-uppercase">Link</span>
    </button>
</div>