import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  private params: any;
  constructor(private snackBar : MatSnackBar, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
    });
    if(sessionStorage.getItem("inactiveUser")){
      this.snackBar.open(`Your session is expired. please re-login again`, 'Ok', {
        panelClass: 'snack-bar'
      });
      sessionStorage.removeItem("inactiveUser");
    }
  }

  public onResetPasswordRequested(): void {
    this.router.navigate(['forgot-password']);
  }

  public handleSuccess(): void {
    sessionStorage.setItem('sessionLogout', new Date(moment().endOf('week').toDate()).getTime().toString())
   if (this.params.redirectUrl) {
      this.router.navigate([this.params.redirectUrl]);
    } else if (this.params.action === 'addToProject') {
      this.router.navigate(['/dashboard/projects'],
        {
          queryParams: {
            addToProject: this.params.project,
            role: this.params.role,
            readonly: this.params.readonly,
            id: this.params.id
          }
        });
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  public navigateToSignUp(): void {
    if (this.params) {
      this.router.navigate(['signup'], {
        queryParams: {
          project: this.params.project,
          role: this.params.role,
          action: this.params.action,
          readonly: this.params.readonly,
          id: this.params.id
        }
      });
    } else {
      this.router.navigate(['signup']);
    }

  }

  openWebSite() {
    window.open(`https://oceansai.tech/products/deepdive?requestUrl=${window.location.origin}`)
  }

}
