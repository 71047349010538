export enum inferenceEngineMode {
  Show = 1,
  Hide = 2,
  Beta = 3
}
export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyAf762DNnsTk705V2jS2hQhVucSts5i4YU",
    authDomain: "dive-oim.firebaseapp.com",
    projectId: "dive-oim",
    storageBucket: "dive-oim.appspot.com",
    messagingSenderId: "1058868376380",
    appId: "1:1058868376380:web:3996a0ad7586ea7b67e439",
    measurementId: "G-43769P8QC0",
  },
  appName: 'Demo',
  baseAPIUrl: 'https://report-gen-1058868376380.asia-southeast1.run.app',
  baseURL:'https://diveintegration-api-hu2gcr7mfa-as.a.run.app',
  engineURL: ' https://diveintegration-api-hu2gcr7mfa-as.a.run.app/ai_trigger_process/',
  imageSimilarity:'https://diveside-by-side-hu2gcr7mfa-as.a.run.app/dive-image-similarity/',
  engineMode:inferenceEngineMode.Show,
  timeline:true,
  QRexport:'https://qr-automation-hu2gcr7mfa-as.a.run.app/generate-qrcode',
  objToTiling: 'https://generate-3d-hu2gcr7mfa-as.a.run.app/tiling_engine/',
  threeDModel: 'https://generate-3d-hu2gcr7mfa-as.a.run.app/3d_model_engine/',
  cancelThreeDModel: 'https://generate-3d-hu2gcr7mfa-as.a.run.app/cancel_process/',
  alignment:`https://model-alignment-hu2gcr7mfa-as.a.run.app//model_alignment_task/`,
  thermal:`https://thermal-process-hu2gcr7mfa-as.a.run.app/`,
  dxfUrl:'https://dxf-drawing-hu2gcr7mfa-as.a.run.app/',
  logProcessUrl: 'https://process-logfile-hu2gcr7mfa-as.a.run.app//process_logfiles/',
  imagePolygons:' https://locateimagesinpolygon-hu2gcr7mfa-as.a.run.app//get_images_in_polygon/',
 fetchAnnotations:  'https://dive-python-general-hu2gcr7mfa-as.a.run.app/',
  defaultTemplateLocation: `templates/default/Default-template.docx`,
  modelSize: 600,
  severityLevels: [{
    level: 1,
    color: '#b4e6fd'
  }, {
    level: 2,
    color: '#5cbfed'
  }, {
    level: 3,
    color: '#007eb9'
  }, {
    level: 4,
    color: '#fdeb69'
  }, {
    level: 5,
    color: '#f7dd25'
  }, {
    level: 6,
    color: '#e39a16'
  }, {
    level: 7,
    color: '#c7953a'
  }, {
    level: 8,
    color: '#f17c7c'
  }, {
    level: 9,
    color: '#ff4f4f'
  }, {
    level: 10,
    color: '#d30808'
  }]
};

