<div class="popup-header w-100 d-flex">
    <p class="w-100 ">
      <span>Select your csv columns</span>
    </p>
    <mat-icon (click)="allSelected()" matTooltip="Select all Columns">select_all</mat-icon>
  </div>
  <mat-dialog-content>
    <div class="d-flex">
      <div class="col-6">
        <h3> <b>Column Name</b></h3>
      </div>
      <div class="col-6">
        <h3><b>Selected csv columns</b> </h3>
      </div>
    </div>
   
    <div class="d-flex">
     <!-- <ul id="list1"  class="container-list" [dragula]='"groups"' [dragulaModel]='list1'>
        <li  *ngFor="let list of list1;let index = index"  >
          <div class="list-item wrap-text"   matTooltip="{{list.value}}" >{{ list.value }}</div>
          <ul [dragula]='"groups"'  [dragulaModel]='list1[index].children'>
            <li  *ngFor="let item of list.children" >
              <div class="list-item wrap-text"   matTooltip="{{item.value}}" >{{ item.value }}</div>
            </li>
          </ul>
        </li>
      </ul>  -->


      <!-- <ul id="list2"  class="container-list" dragula="groups" [dragulaModel]='list2'>
        <li *ngFor="let list of list2;let index = index ">
          <div class="list-item wrap-text"   matTooltip="{{list.value}}" >{{ list.value }}</div>
          <ul [dragula]='"groups"'  [dragulaModel]='list2[index].children'>
            <li  *ngFor="let item of list.children">
              <div class="list-item wrap-text"   matTooltip="{{item.value}}" >{{ item.value }}</div>
            </li>
          </ul>
        </li>
      </ul>  -->


       <!-- List 1 -->
        <!-- List 1 -->
      <!-- <ul class='container-list'  [dragula]='"ul-group"' [dragulaModel]='list1'>
        <li class="list-item wrap-text" *ngFor="let item of list1" [dragula]='"li-group"' [dragulaModel]='[item]'>
          {{ item.value }}
          <ul [dragula]='"li-group"' [dragulaModel]='item.children'>
            <li  [dragula]='"li-group"' [dragula]='"child-group"'    [dragulaModel]='[child]' *ngFor="let child of item.children">
              {{ child.value }}
            </li>
          </ul>
        </li>
      </ul> -->

       <!-- List 1 -->
           <ul  class='container-list' [dragula]='"ul-group"' [dragulaModel]='list1'>
            <li *ngFor="let item of list1" [dragula]='"ul-group"' [dragulaModel]='[item]'>
              {{ item.value }}
              <ul [dragula]='"child-group"' [dragulaModel]='item.children'>
                <li *ngFor="let child of item.children" [dragula]='"child-group"' [dragulaModel]='[child]'>
                  {{ child.value }}
                </li>
              </ul>
            </li>
          </ul>
    
          <!-- List 2 -->
          <ul  class='container-list' [dragula]='"ul-group"' [dragulaModel]='list2'>
            <li *ngFor="let item of list2" [dragula]='"ul-group"' [dragulaModel]='[item]'>
              {{ item.value }}
              <ul [dragula]='"child-group-2"' [dragulaModel]='item.children'>
                <li *ngFor="let child of item.children" [dragula]='"child-group-2"' [dragulaModel]='[child]'>
                  {{ child.value }}
                </li>
              </ul>
            </li>
          </ul>


      <!-- <ul class='container-list' [dragula]='"ul-group"' [dragulaModel]='list2'>
        <li class="list-item wrap-text" *ngFor="let item of list2" [dragula]='"li-group"' [dragulaModel]='item.children'>
          {{ item.value }}
          <ul [dragula]='"li-group"' [dragulaModel]='item.children'>
            <li  [dragula]='"li-group"' [dragulaModel]='child' *ngFor="let child of item.children">
              {{ child.value }}
            </li>
          </ul>
        </li>
      </ul> -->

  
  
      <!-- <div class="container-list" dragula="groups" [(dragulaModel)]="dropItems">
        <div class="list-item wrap-text" *ngFor="let item of dropItems">
          {{ item.value }}
        </div>
      </div> -->
    </div>
  </mat-dialog-content>
  <div mat-dialog-actions class="m-1">
    <button color="primary" (click)="saveColumns();" [disabled]="!list2.length" class="btn w-100" mat-raised-button>
      Select
    </button>
  
  </div>