import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { BackendService } from '../services/backend.service';
import { Observable, Subscription } from 'rxjs';
import { Project } from '../projects/item/project.type';

@Component({
  selector: 'app-report-add-image-dialog',
  templateUrl: './report-add-image-dialog.component.html',
  styleUrls: ['./report-add-image-dialog.component.scss'],
})
export class ReportAddImageDialogComponent implements OnInit, OnDestroy {
  public projects$: Observable<Project[]>;
  public projects: Project[];
  public nodes: any[] = [];
  public folders: any[] = [];
  public selectedFolderPaths: string[] = [];
  public timerId: any;
  public selectedProjectId: string;
  public includeGroupedImages: boolean;
  public selectedSubscription = new Subscription();
  public folderImages$ : Observable<any>;
  public selectedImages: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backendService: BackendService,
    public dialogRef: MatDialogRef<ReportAddImageDialogComponent>,
    public dialog: MatDialog
  ) {
    // this.projects$ = this.backendService.getProjects();
    // this.selectedSubscription = this.projects$.subscribe((projects) => {
    //   this.projects = projects;
    //   if (this.projects && this.projects.length) {
    //     if (!this.selectedProjectId) {
    //       this.selectedProjectId = this.data.projectId;
    //     }
    //     console.log(this.projects);

    //   }
    // });
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
    this.selectedProjectId = this.data.projectId;
    this.getFolders();
  }

  getFolders(): void {
    this.backendService
      .getFolders$(this.selectedProjectId)
      .subscribe((folders) => {
        const result = [];
        folders.docs.forEach((doc, i) => {
          const node = {
            name: doc.data().name,
            doc: doc.data(),
            path: doc.ref.path,
            breadcrumb: {
              name: doc.data().name,
            },
            children: [],
          };
          result.push(node);
          this.backendService.getFoldersOf(doc.ref, doc.ref.path, result[i]);
        });
        this.nodes = result.sort((a, b) => a.name.localeCompare(b.name));

        if (this.nodes.length) {
          this.toggleFolder(this.nodes[0].path);
        }
        this.timerId = setInterval(() => {
          this.prepareFolders();
        }, 2000);
      });
  }

  changeProject(): void {
    this.getFolders();
  }

  prepareFolders(): void {
    // console.log(this.folders);
    this.folders = [];
    this.nodes.forEach((node) => {
      this.folders.push({
        doc: node.doc,
        name: node.name,
        path: node.path,
        level: 0,
      });
      this.expandFolder(node, 1);
    });
  }

  expandFolder(folder, level): void {
    if (folder.children.length) {
      folder.children.forEach((child) => {
        this.folders.push({
          doc: child.doc,
          name: child.name,
          path: child.path,
          level,
        });
        this.expandFolder(child, level + 1);
      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.timerId);
  }

  toggleSelectedImage(id: string) {
    if (this.selectedImages.includes(id)) {
      this.selectedImages = this.selectedImages.filter(x => x != id);
    } else {
      this.selectedImages.push(id);
    }
  }

  addImages() {
    this.dialogRef.close({
      selectedImages: this.selectedImages
    });
  }

  toggleFolder(path): void {
    if (this.isFolderSelected(path)) {
      this.selectedFolderPaths = this.selectedFolderPaths.filter(
        (tempPath) => tempPath !== path
      );
    } else {
      this.selectedFolderPaths = [path];
      this.folderImages$ = this.backendService.getFolderContents$(
        path,
        this.selectedProjectId
      );
    }

    console.log(this.selectedFolderPaths);
  }

  isFolderSelected(path): boolean {
    return !!this.selectedFolderPaths.find((tempPath) => tempPath === path);
  }
}
