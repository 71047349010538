import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { validateBasis } from '@angular/flex-layout';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IActionHandlerParams } from '../action-handler/action-handler.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  public form = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    confirm: new FormControl('', Validators.required),
  },
  this.mustMatch('password', 'confirm')
  );


   mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }
  public isCorrectLink$ = new BehaviorSubject<boolean>(null);
  public isSuccess$ = new BehaviorSubject<boolean>(null);

  private params: IActionHandlerParams;

  constructor(
    private auth: AngularFireAuth,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.params = this.route.snapshot.queryParams as IActionHandlerParams;

    this.auth.verifyPasswordResetCode(this.params.oobCode).then((email) => {
      this.isCorrectLink$.next(true);
    }).catch(() => this.isCorrectLink$.next(false));
  }
  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  submit(): void {
    const { password, confirm } = this.form.value;
    if (password !== confirm) {
      this.form.get('confirm').setErrors({ confrim: true });
      return;
    }

    this.auth.confirmPasswordReset(this.params.oobCode, password).then((res) => {
      this.isSuccess$.next(true);
      setTimeout(() => this.router.navigateByUrl('/signin'), 2000);
    }).catch(() => this.isSuccess$.next(false));
  }

}
