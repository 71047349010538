import { Component, Input, ViewChild, Inject, OnDestroy, OnInit, Output, EventEmitter, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { LinkImagesComponent } from '../link-images/link-images.component';
import { UiService } from '../services/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Subject, BehaviorSubject, interval } from 'rxjs';
import { ImageModalComponent } from '../image-modal/image-modal.component';
import { v4 as uuidv4 } from 'uuid';
import { VideoModalComponent } from '../video-modal/video-modal.component';
import { ToastrService } from 'ngx-toastr';
import { filter, map, retry, takeUntil, takeWhile } from 'rxjs/operators';
import { KonvaComponent } from '../konva/konva.module';
import { Images, KonvaConfig, TaggedArea } from '../models/tagging.interface';
import Konva from 'konva';
import { DXFViewer } from '../Three/dxf-viewer';
import { DXF, Status, AssetType, InspectionType } from './../models/app.enum';
import { ITreeOptions, ITreeState, TreeComponent, TreeModel, TREE_ACTIONS } from '@circlon/angular-tree-component';
const props = "children";
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { DxfLayersComponent } from '../dxf-layers/dxf-layers.component';
import { WaMatConfirmDialog } from '@webacad/material-confirm-dialog';
import { GeneralService } from '../services/general.service';
import * as THREE from 'three';
import * as jsZip from 'jsZip';
import { LogFileComponent } from '../log-file/log-file.component';
import * as geolib from 'geolib';
function getRelativePointerPosition(node: Konva.Node): Konva.Vector2d {
  const transform = node.getAbsoluteTransform().copy();
  transform.invert();
  const pos = node.getStage().getPointerPosition();
  return transform.point(pos);
}
declare var google: any;
//import * as L from 'leaflet';
declare var L: any;
//import 'leaflet-kml';
@Component({
  selector: 'app-base-image-model',
  templateUrl: './base-image-model.component.html',
  styleUrls: ['./base-image-model.component.scss']
})
export class BaseImageModelComponent implements OnInit, OnDestroy {
  @Input() public assetId;
  @Input() public asset;
  @Input() public dialogRef;
  @Input() public isAssetOwner;
  @Input() public projects;
  @Input() public selectedProjectId;
  @Input() public leftProjectId;
  @Input() public rightProjectId;
  @Input() public leftImageId;
  @Input() public rightImageId;
  @Input() public panelsVisible;
  @Input() public type = 'asset';
  is2DPanelExpand: boolean = true;
  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() cancelSelection: EventEmitter<any> = new EventEmitter();

  @ViewChild('dxfTreeComponent') private treeComponent: TreeComponent;
  @ViewChild('deleteLabelDialog') deleteLabelDialog: any;
  deleteLabelDialogRef;
  @ViewChild('renameDialog') renameDialog: any;
  renameDialogRef;
  @ViewChild('georeference') georeferenceDialog: any;
  georeferenceDialogRef: any;
  @ViewChild('dxfDialog') dxfDialog: any;
  dxfDialogRef: any;
  @ViewChild('geoPointsDialog') geoPointsDialog: any;
  geoPointsDialogRef: any;
  @ViewChild('panelLayerDailog') panelLayerDailog: any;
  panelLayerDailogRef: any;
  @ViewChild('progressBar') progressBar: any;
  @ViewChild('levelDialog') levelDialog: any;
  levelDialogRef;
  public isImageUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isShapeFileProcessing$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  baseFile: boolean = false;
  projectId: string;
  annotations = [];
  linkImages;
  technicalDrawings = [];
  drawing;
  private onDestroy$ = new Subject();
  linkImageSubscription: any = new Subject();
  public isLeftMenu: boolean = false;
  public isRightMenu: boolean = false;
  @ViewChild('containerImage') container: ElementRef;
  @ViewChild('stage') stage: KonvaComponent;
  leftImageSubscription: Subscription;
  rightImageSubscription: Subscription;
  panelSubscription: Subscription;

  public configStage$: BehaviorSubject<any> = new BehaviorSubject({
    width: 0,
    height: 0,
  });
  public containerSize: any;
  public configImg$: BehaviorSubject<KonvaConfig> = new BehaviorSubject({
    width: 0,
    height: 0,
    image: null,
  });
  public taggedAreas: Map<string, TaggedArea[]> = new Map();

  public get activeImgAreas(): TaggedArea[] {
    return this.taggedAreas.get(this.drawing?.id) || [];
  }
  public get queued() {
    return Status.QUEUED;
  }
  public get processing() {
    return Status.PROCESSING;
  }
  public get failed() {
    return Status.FAILED;
  }
  public get success() {
    return Status.SUCCESS;
  }
  public get solar() {
    return AssetType.SOLAR;
  }
  public get FORSSEA() {
    return InspectionType.FORSSEA;
  }
  isPanelDXF: boolean = false;
  labelsChanged: boolean = false;
  treeOptions: ITreeOptions = {
    allowDrag: false,
    allowDrop: false
  };
  nodes: any = [];
  state: ITreeState = {
    expandedNodeIds: {
      1: true,
      2: true
    },
    hiddenNodeIds: {},
    activeNodeIds: {}
  };

  // For KMZ Viewer
  toolBox: string = "";
  lineColors = {
    L1: '#800080',
    L2: '#013220',
    L3: '#440000',
    L4: '#0000FF',
    L5: '#FF0000'
  }

  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    raised: true,
    spinnerSize: 24,
    spinnerColor: 'primary',
    buttonIcon: {
      color: 'primary',
      fontIcon: 'save',
      inline: false
    },
    customClass: 'text-uppercase'
  };
  levels = {
    'L1': 'L1',
    'L2': 'L2',
    'L3': 'L3',
    'L4': 'L4',
    'L5': 'L5',
  }
  constructor(
    private dialog: MatDialog,
    public uiService: UiService,
    public backendService: BackendService,
    private toaster: ToastrService,
    private renderer: Renderer2,
    private generalService: GeneralService,
    private confirmDialog: WaMatConfirmDialog,
    private router: Router) {

  }

  setContainerSize(): void {
    if (this.container) {
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };
      this.configStage$.next(this.containerSize);
      this.configImg$.next(this.containerSize);
    }
  }

  handleZoom(event: WheelEvent): void {
    if (!this.configImg$.getValue().image) {
      return;
    }

    //this.taggedAreas.set(this.drawing.id, this.imageAnnotations);
  }

  getImageScale(drawing: any): { widthScale: number; heightScale: number } {
    if (!drawing || !drawing.image) {
      return {
        widthScale: 1,
        heightScale: 1
      };
    };
    const image = drawing.image;
    const { width, height } = image;
    const { width: stageWidth, height: stageHeight } = this.configStage$.getValue();
    const widthScale = stageWidth / width;
    const heightScale = stageHeight / height;
    return {
      widthScale: Math.min(widthScale, heightScale),
      heightScale: Math.min(widthScale, heightScale),
    };
  }

  activate(drawing): void {
    const { image } = this.drawing;
    if (image) {
      this.setContainerSize();
      const { height, width } = image;
      const { widthScale, heightScale } = this.getImageScale(drawing);
      const newWidth = width * widthScale;
      const newHeight = height * heightScale;
      this.getLabels(drawing.id);
      this.stage.reset();
      const newSize = {
        width: newWidth,
        height: newHeight,
      };
      this.configStage$.next(newSize);
      this.configImg$.next({
        ...newSize,
        image: image,
      });

    }
    else {
      const image = new Image();
      image.onload = () => {
        this.drawing['image'] = image;
        const { height, width } = image;
        this.setContainerSize();
        const { widthScale, heightScale } = this.getImageScale(drawing);
        const newWidth = width * widthScale;
        const newHeight = height * heightScale;
        this.getLabels(drawing.id);
        this.stage.reset();
        const newSize = {
          width: newWidth,
          height: newHeight,
        };
        this.configStage$.next(newSize);
        this.configImg$.next({
          ...newSize,
          image: image
        });
      };
      image.src = drawing.fileUrl;
    }
  }

  handleClick(event): void {
    if (this.isAssetOwner && this.type != 'report') {
      if (event.target.attrs.name) {
        const label = this.annotations.find(o => o.id == event.target.attrs.name)
        this.gotoAnnotation(label)
      } else {
        this.handleMouseEvent(event);
      }
    }
  }

  handleMouseEvent(event: MouseEvent): void {
    try {
      // avoid breaking when event is a WheelEvent
      if (event['evt'].shiftKey) return;
    } catch (error) {
      // do nothing
    }
    const point = getRelativePointerPosition(this.stage.getStage());
    const { widthScale, heightScale } = this.getImageScale(this.drawing);
    const pixels = {
      x: point.x * (1 / widthScale),
      y: point.y * (1 / heightScale),
    }

    let labelTitle = prompt('Please enter label number');

    if (!labelTitle) {
      return;
    }
    if ([].concat(...this.technicalDrawings.map(o => o.labels)).find(o => o?.title === labelTitle)) {
      this.toaster.warning("Label name should be unique. You can not create the duplicate label");
      return;
    }
    const label = [{
      "title": labelTitle || "",
      "id": uuidv4(),
      "point": {
        "x": pixels.x,
        "y": pixels.y
      }
    }]
    this.backendService.createTechnicalDrawingLabels(this.drawing, label).subscribe((result) => { })
  }

  ngOnInit(): void {
    window.addEventListener('contextmenu', function (e) {
      e.preventDefault(); // Prevent the default context menu
    });
    if (this.type === "project" && this.selectedProjectId) {
      this.projectId = this.selectedProjectId;
    }
    this.getModels();
  }

  isSetConfigStage = true;
  public isSetContainerSize: boolean = true;
  ngAfterViewInit() {
    if (window) {
      this.renderer.listen(window, 'resize', () => {
        this.isSetConfigStage = true;
      });
    }
  }

  ngAfterViewChecked(): void {
    if (this.isSetConfigStage && this.container) {
      this.isSetConfigStage = false;
      this.setContainerSize();
      if (this.drawing) {
        this.activate(this.drawing);
      }
    }

    if (this.container && this.isSetContainerSize) {
      this.isSetContainerSize = false;
      const { offsetWidth, offsetHeight } = this.container.nativeElement;
      this.containerSize = {
        width: offsetWidth,
        height: offsetHeight,
      };
    }
  }
  modelSubscription: Subscription;
  dxfFile: any;
  kmlFile: any;
  isProcessing: boolean = false;
  isKMZLoad: boolean = false;
  getModels() {
    this.isProcessing = true;
    if (this.asset.assetType !== AssetType.SOLAR) {
      this.modelSubscription = this.backendService.get2DModels$(this.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
        this.isProcessing = false;
        result = result.filter(o => !o.isDXFFile);
        if (result.length) {
          this.technicalDrawings = result;
          if (result) {
            this.baseFile = true;
            if (!result[0].isKMLFile) {
              if (!this.drawing) {
                this.drawing = result[0];
                this.activate(this.drawing);
              } else {
                this.drawing = { ...this.drawing, ...this.technicalDrawings.find(o => o.id == this.drawing.id) }
              }
            } else {
              this.loadKML(result[0]);
            }

          }
        }
        else {
          this.baseFile = false;
        }
      });

    } else {
      this.modelSubscription = this.backendService.get2DDXFModels$(this.assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
        this.isProcessing = false;
        if (result) {
          if (result.isShapeFile) {
            this.kmlFile = result;
            if (result.shpStatus === this.success) {
              this.baseFile = true;
              if (this.kmlFile.levels) {
                this.levels = this.kmlFile.levels;
              }
              if (!this.isKMZLoad) {
                this.isKMZLoad = true;
                if (this.kmz) {
                  this.kmz.remove();
                }
                this.loadShapeViewer(result);
                if (this.projectId) {
                  this.getDXFLinkedImages(this.kmlFile.id);
                }
                if (this.leftProjectId) {
                  this.getDXFLeftLinkedImages(this.kmlFile.id);
                }
                if (this.rightProjectId) {
                  this.getDXFRightLinkedImages(this.kmlFile.id);
                }

                this.getSolarLabels(this.kmlFile);
              }
            }
            if (result.shpStatus === this.processing || result.shpStatus === this.queued) {
              this.baseFile = true;
              if (this.is2DPanelExpand) {
                this.toggleKmlPanelView();
              }
              if (!this.map) {
                this.loadShapeViewer(result);
              }
            }
            else if (result.shpfile_conversion_status === this.processing || result.shpfile_conversion_status === this.queued) {
              this.isShapeFileProcessing$.next(true);
            } else if (result.shpfile_conversion_status === this.success) {
              this.baseFile = true;
              this.isShapeFileProcessing$.next(false);
              if (this.is2DPanelExpand) {
                this.toggleKmlPanelView();
              }
              if (!this.map) {
                this.loadShapeViewer(result);
              }
            }
            else if (result.shpfile_conversion_status === this.failed) {
              this.isShapeFileProcessing$.next(false);
            }

          } else {
            this.baseFile = true;
            this.dxfFile = result;
            if (this.dxfFile.levels) {
              this.levels = this.dxfFile.levels;
            }
            if (!this.viewer) {
              this.initDXF(this.dxfFile);
            }
            if (this.dxfFile.dxfStatus === Status.SUCCESS && this.dxfFile.dxfUrl) {
              /* if (!this.dxfFile.isGeoreferenced && !this.dxfFile.manualLinking) {
                 this.geoPointsDialogRef = this.dialog.open(this.geoPointsDialog, {
                   disableClose: true,
                 });
               }*/

              if (!this.isPanelDXF) {
                if (this.uiService.dxfId === this.dxfFile.id && this.uiService.panelDXF) {
                  this.isPanelDXF = true;
                  this.viewer.addPanelsToScene(this.uiService.panelDXF)
                  if (this.projectId) {
                    this.getDXFLinkedImages(this.dxfFile.id);
                  }
                  if (this.leftProjectId) {
                    this.getDXFLeftLinkedImages(this.dxfFile.id);
                  }
                  if (this.rightProjectId) {
                    this.getDXFRightLinkedImages(this.dxfFile.id);
                  }
                } else {
                  this.backendService.getFile(this.dxfFile.dxfUrl).subscribe((panels: any) => {
                    this.isPanelDXF = true;
                    this.viewer.renderDxf(panels);
                    if (this.projectId) {
                      this.getDXFLinkedImages(this.dxfFile.id);
                    }
                    if (this.leftProjectId) {
                      this.getDXFLeftLinkedImages(this.dxfFile.id);
                    }
                    if (this.rightProjectId) {
                      this.getDXFRightLinkedImages(this.dxfFile.id);
                    }
                  })
                }
                this.getSolarLabels(this.dxfFile);

              }
            }
          }

        }
      })
    }
  }

  carausalActivateImage(drawing) {
    this.drawing = drawing;
    this.selectedLabel = null;
    this.linkImages = null;
    this.activate(drawing);
  }

  selectedLabel;
  gotoAnnotation(node) {
    if (this.type === 'asset') {
      return;
    }
    this.is2DPanelExpand = false;
    this.selectedLabel = node;
    this.rcImages.images = [];

    if (this.circles.length) {
      const circle = this.circles.filter(o => o.options.customData.id === node.id);
      if (circle.length) {
        var circleLatLng = circle[0].getLatLng();
        var popupContent = circle[0].options.customData.title;
        L.popup().setContent(popupContent)
          .setLatLng(circleLatLng)
          .openOn(this.map);
        // Set the map view to the circle's coordinates with an appropriate zoom level
        this.map.setView(circleLatLng, 18); // Adjust the zoom level as needed
      }
      setTimeout(() => {
        this.map.invalidateSize();
      }, 50);
    }
    var testimonial = document.getElementById('capture');
    if (testimonial) {
      testimonial.innerHTML = "";
    }
  }


  addImages(el, modelId): void {
    if (this.type != 'project') {
      return;
    }
    if (!this.isAssetOwner) {
      this.toaster.warning("Only Owners can links the medias")
      return;
    }
    const index = this.annotations.findIndex(o => o.id == el.id);
    if (!this.linkImages[el.id]) {
      this.linkImages[el.id] = [];
    }
    const nodeImagesIds = this.linkImages[el.id].map(item => item.id) || [];
    const data: any = {
      project: this.uiService?.project,
      nodeImagesIds,
      assetId: this.assetId,
      label: this.annotations[index].title
    };
    const dialogRef = this.dialog.open(LinkImagesComponent, {
      width: '70vw',
      height: '100vh',
      panelClass: 'no-border-radius-dialog',
      data,
    });
    dialogRef.afterClosed().subscribe(r => {
      if (!r) {
        return;
      }
      if (r.images.length) {
        this.backendService.linkImages(this.assetId, modelId, this.annotations[index].id, r.images, "images", this.projectId, "2d").subscribe((result) => {
          r.images.forEach(imageId => {
            if (imageId && !this.linkImages[el.id].find(o => o.id == imageId)) {
              this.backendService.getImage$<any>(imageId).subscribe((image: any) => {
                if (image.deleted != true) {
                  this.linkImages[el.id].push(image)
                }
              });
            }
          });
        });
      }
      if (r.videos.length) {
        this.backendService.linkImages(this.assetId, modelId, this.annotations[index].id, r.videos, "videos", this.projectId, "2d").subscribe((result) => {
          r.videos.forEach(videoId => {
            if (videoId && !this.linkImages[el.id].find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  this.linkImages[el.id].push(video)
                }
              });
            }
          });
        });
      }

    });
  }

  labelSubscription: Subscription;
  getLabels(id) {
    //const circleTexture = new THREE.TextureLoader().load('assets/model/circle.png')
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.labelSubscription = this.backendService.getTechnicalDrawinglLabels$(id).subscribe((result: any) => {
      this.annotations = result.label || [];
      const annotations = [];
      const index = this.technicalDrawings.findIndex(o => o.id == id)
      this.technicalDrawings[index].labels = this.annotations;
      const { widthScale, heightScale } = this.getImageScale(this.drawing);

      this.annotations.forEach((label, i) => {
        this.annotations[i].color = label.color || this.generalService.generateRandomColor();
        const pixel = {
          x: label.point.x / (1 / widthScale),
          y: label.point.y / (1 / heightScale),
        }
        annotations.push(
          {
            config$: new BehaviorSubject({
              x: pixel.x,
              name: label.id,
              y: pixel.y,
              width: 8,
              height: 8,
              stroke: '#FFF',
              fill: label.color,
              strokeWidth: 1
            }),
            configText$: new BehaviorSubject({
              name: label.id,
              x: pixel.x,
              y: pixel.y,
              text: label.title,
              // fill: this.generateRandomColor(),
              fontSize: 8,
              lineHeight: 1,
              align: 'center'
            })
          })
      })
      this.taggedAreas.set(this.drawing.id, annotations);
      if (!this.linkImages) {
        this.getLinkedImages(this.drawing.id);
      }
    });
  }

  getLinkedImages(id) {
    if (this.linkImageSubscription) {
      this.linkImageSubscription.unsubscribe();
    }
    this.linkImages = [];

    this.linkImageSubscription = this.backendService.getLinkedImages(this.assetId, id, this.projectId).subscribe((result) => {
      if (!result) { return }
      let keys = Object.keys(result);
      const _this = this;
      keys.forEach(element => {
        const index = this.annotations.findIndex(o => o.id == element);
        if (index != -1) {
          if (!_this.linkImages[element]) {
            _this.linkImages[element] = [];
          }
          result[element]['images']?.forEach(image => {
            if (image && !this.linkImages[element].find(o => o.id == image)) {
              this.backendService.getImage$<any>(image).subscribe((image: any) => {
                if (image.deleted != true) {
                  _this.linkImages[element].push(image)
                }
              });
            }
          })
          result[element]['videos']?.forEach(videoId => {
            if (videoId && !this.linkImages[element].find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  _this.linkImages[element].push(video)
                }
              });
            }
          })
        }
      });
    });
  }


  linkedMedia = [];
  getDXFLinkedImages(modelId: string) {
    this.linkedMedia = null;
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(this.assetId, modelId, this.projectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            this.linkedMedia = JSON.parse(medias) || [];
          })
        }
      }, (error) => {
        throw error;
      });
  }

  leftLinkedMedia = null;
  leftImage;
  getDXFLeftLinkedImages(modelId: string) {
    this.leftLinkedMedia = null;
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(this.assetId, modelId, this.leftProjectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            this.leftLinkedMedia = JSON.parse(medias) || [];
            if (this.panelsVisible && this.leftImageId) {
              const observable = interval(500);
              this.leftImageSubscription = observable.pipe(
                filter(() => {
                  return this.dxfFile ? this.nodes.length && (this.viewer && this.viewer.scene.children.filter(o => o.name === 'PanelViewer' || o.name === 'DXFViewer').length == 2) :
                    this.nodes.length && (this.kmz && !this.kmzProcessing)
                })
              ).subscribe(() => {
                this.leftImageSubscription.unsubscribe();
                this.backendService.getImage$<any>(this.leftImageId).subscribe((image: any) => {
                  if (image.deleted != true) {
                    this.leftImage = image;
                  }
                });
                this.markedLabels(this.leftImageId, this.leftLinkedMedia, this.dxfFile ? 0XFFA500 : 'FFA500', 'left')
              });

            }
          })
        }
      }, (error) => {
        throw error;
      });
  }

  markingPanels = [];
  highlightedImageId;
  markedLabels(imageId, mediaFile, color, type) {
    if (this.is2DPanelExpand) {
      this.dxfFile ? this.toggleDXFPanelView() : this.toggleKmlPanelView();
    }
    function findPanelsByImage(imageId) {
      const panels = [];
      for (const panelId in mediaFile) {
        if (mediaFile[panelId] && mediaFile[panelId].images && mediaFile[panelId].images.includes(imageId)) {
          panels.push(panelId);
        }
      }
      return panels;
    }
    const resultPanels = findPanelsByImage(imageId);

    function getHandlesByLayer(data, labels, layer = 'L5') {
      const panels = [];

      function search(tree) {
        if (tree.layer === layer && labels.find(id => id === tree.id) && tree.panels) {
          panels.push(...tree.panels);
        }

        if (tree.children) {
          tree.children.forEach(child => search(child));
        }
      }

      data.forEach(item => search(item));

      return panels;
    }
    const handles = getHandlesByLayer(this.nodes, resultPanels, 'L5').filter(this.generalService.onlyUnique);
    if (!handles.length) {
      this.toaster.warning(!this.rightImageId ? "Image not linked with any panels" :
        type == 'right' ? "Right Image not linked with any panels" :
          "Left Image not linked with any panels"
      );
      return;
    }
    if (this.dxfFile) {
      const panelLayer = this.viewer.scene.children.filter(o => o.name === 'PanelViewer');
      if (panelLayer.length) {
        const markingPanels = {
          imageId: imageId,
          visible: this.markingPanels.length == 0 ? true : false
        }
        if (markingPanels.visible) {
          this.highlightedImageId = imageId;
        }
        const layers = panelLayer[0].children.filter(layer =>
          handles.includes(layer.userData.entity.handle));
        if (markingPanels.visible) {
          this.handleResize();
        }
        this.viewer.createGroup(imageId, layers.map(o => o.userData.entity), color, true, markingPanels.visible)
        this.markingPanels.push(markingPanels)
      }
    } else {
      // KMZ panel click
      const layer = this.markedLayers.find(o => o.folderName === `${this.kmlFile.id}_panels`).layers;
      const markingPanels = {
        imageId: imageId,
        visible: this.markingPanels.length == 0 ? true : false,
        polygons: [],
      }
      let keys = Object.keys(layer._layers);
      keys.forEach(key => {
        const entity = layer._layers[key].feature?.properties?.Unique_Id;
        if (handles.includes(entity)) {
          var polygon = L.polygon(layer._layers[key].getLatLngs(), {
            fillColor: color,
            fillOpacity: 0.5
          })
          if (markingPanels.visible) {
            polygon.addTo(this.map)
          }
          markingPanels.polygons.push(polygon);
        }
      });
      if (markingPanels.visible) {
        this.highlightedImageId = imageId;
        // Get the bounds of all polygons
        var bounds = L.featureGroup(markingPanels.polygons).getBounds();
        // Fit the map bounds to the polygons with padding
        this.map.fitBounds(bounds, { padding: [50, 50] }); // Adjust padding as needed
      }
      this.markingPanels.push(markingPanels)

    }


  }

  markedImage(imageId) {
    if (this.highlightedImageId === imageId) {
      // inverse highlight
      if (this.dxfFile) {
        const highlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id != imageId);
        const unHighlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id === imageId);
        unHighlight.visible = false;
        highlight.visible = true;
        this.highlightedImageId = highlight.userData.id;
        this.viewer.setCameraToPanel(highlight.children);
      } else {
        const highlight = this.markingPanels.find(o => o.imageId != imageId);
        const unHighlight = this.markingPanels.find(o => o.imageId == imageId);
        unHighlight.visible = false;
        highlight.visible = true;
        this.highlightedImageId = highlight.imageId;
        if (highlight) {
          highlight.polygons.forEach(polygon => {
            if (!this.map.hasLayer(polygon)) {
              polygon.addTo(this.map)
            }
          });
          // Get the bounds of all polygons
          var bounds = L.featureGroup(highlight.polygons).getBounds();
          // Fit the map bounds to the polygons with padding
          this.map.fitBounds(bounds, { padding: [50, 50] }); // Adjust padding as needed
        }
        if (unHighlight) {
          unHighlight.polygons.forEach(polygon => {
            if (this.map.hasLayer(polygon)) {
              this.map.removeLayer(polygon)
            }
          });
        }
      }


    } else {
      if (this.dxfFile) {
        const unHighlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id != imageId);
        const highlight = this.viewer.scene.children.find(o => o.name === 'shapeLayers' && o.userData.id === imageId);
        unHighlight.visible = false;
        highlight.visible = true;
        this.highlightedImageId = highlight.userData.id;
        this.viewer.setCameraToPanel(highlight.children);
      } else {
        const highlight = this.markingPanels.find(o => o.imageId === imageId);
        const unHighlight = this.markingPanels.find(o => o.imageId != imageId);
        unHighlight.visible = false;
        highlight.visible = true;
        this.highlightedImageId = highlight.imageId;
        if (highlight) {
          highlight.polygons.forEach(polygon => {
            if (!this.map.hasLayer(polygon)) {
              polygon.addTo(this.map)
            }
          });
          // Get the bounds of all polygons
          var bounds = L.featureGroup(highlight.polygons).getBounds();
          // Fit the map bounds to the polygons with padding
          this.map.fitBounds(bounds, { padding: [50, 50] }); // Adjust padding as needed
        }
        if (unHighlight) {
          unHighlight.polygons.forEach(polygon => {
            if (this.map.hasLayer(polygon)) {
              this.map.removeLayer(polygon)
            }
          });
        }
      }
    }
  }


  rightLinkedMedia = null;
  rightImage;
  getDXFRightLinkedImages(modelId: string) {
    this.rightLinkedMedia = null;
    this.labelSubscription = this.backendService
      .getDXFLinkedImages(this.assetId, modelId, this.rightProjectId).subscribe((linkImages: any) => {
        if (linkImages && linkImages.linkFile) {
          this.backendService.getFile(linkImages.linkFile).subscribe((medias: any) => {
            this.rightLinkedMedia = JSON.parse(medias) || [];
            if (this.panelsVisible && this.rightImageId) {
              const observable = interval(500);
              this.rightImageSubscription = observable.pipe(
                filter(() => {
                  return this.dxfFile ? this.nodes.length && (this.viewer && this.viewer.scene.children.filter(o => o.name === 'PanelViewer' || o.name === 'DXFViewer').length == 2) :
                    this.nodes.length && (this.kmz && !this.kmzProcessing);
                })
              ).subscribe(() => {
                this.rightImageSubscription.unsubscribe();
                this.backendService.getImage$<any>(this.rightImageId).subscribe((image: any) => {
                  if (image.deleted != true) {
                    this.rightImage = image;
                  }
                });
                this.markedLabels(this.rightImageId, this.rightLinkedMedia, this.dxfFile ? 0X0000FF : '0000FF', 'right')

              });
            }
          })
        }
      }, (error) => {
        throw error;
      });
  }

  openModal(data, node) {
    const project = this.projects.find(o => o.id == this.projectId);
    if (!project) {
      this.toaster.warning('You are not able to access the project.')
      return;
    }
    if (data.type != "videos") {
      this.dialog.open(ImageModalComponent, {
        //  panelClass: 'no-border-radius-dialog',
        height: '97%',
        width: '97%',
        data: {
          ...data,
          dialogRef: this.dialogRef
        },
      });

    } else {
      this.dialog.open(VideoModalComponent, {
        data: {
          ...data,
          dialogRef: this.dialogRef
        },
        width: '100%',
        height: '100%',
        panelClass: 'video-dialog'
      });
    }

  }

  ngOnDestroy(): void {
    this.linkImageSubscription.unsubscribe();
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.modelSubscription.unsubscribe();
    if (this.viewer && this.isPanelDXF) {
      this.uiService.panelDXF = this.viewer.scene.children.find(o => o.name === 'PanelViewer');
      this.uiService.rawDXF = this.viewer.scene.children.find(o => o.name === 'DXFViewer');
      this.uiService.dxfId = this.dxfFile.id;
    }
    if (document.getElementById('dxfPanel')) {
      document.getElementById('dxfPanel').innerHTML = "";
    }
    this.viewer?.remove();
    if (this.leftImageSubscription) { this.leftImageSubscription.unsubscribe(); }
    if (this.rightImageSubscription) { this.rightImageSubscription.unsubscribe(); }
    if (this.panelSubscription) { this.panelSubscription.unsubscribe(); }
    this.cancelSelection.emit()

    if (this.kmz) {
      this.kmz.clearLayers();
      this.kmz.remove()
      this.kmz.clearAllEventListeners()
    }
    if (this.map) {
      this.map.remove();
    }
  }

  remove(image, node) {
    image.loading = true;
    const index = this.annotations.findIndex(o => o.id === node.id);
    this.backendService.removedLinkedImages(this.assetId, this.drawing.id, this.annotations[index].id, this.linkImages.filter(o => o.type === image.type && o.id !== image.id).map(o => o.id), image.type, this.projectId).subscribe(() => {
      image.loading = false;
      const iIndex = this.linkImages[node.id].findIndex(o => o.id == image.id);
      this.linkImages[node.id].splice(iIndex, 1);
    }, error => {
      image.loading = false;
    })
  }

  removeDXFLinkImages(image, label) {
    // Change to file
    image.loading = true;
    this.backendService.removedLinkedImages(this.assetId, this.dxfFile.id, label.id, this.linkImages.filter(o => o.type === image.type && o.id !== image.id).map(o => o.id), image.type, this.projectId).subscribe(() => {
      image.loading = false;
      const iIndex = this.linkImages.findIndex(o => o.id == image.id);
      this.linkImages[label.id].splice(iIndex, 1);
    }, error => {
      image.loading = false;
    })
  }

  removeLabelPrompt(label) {
    this.deleteLabelDialogRef = this.dialog.open(this.deleteLabelDialog, {
      data: { label: label }
    });
  }

  deleteLabel(label) {
    this.backendService.removeTechnicalDrawingLabels(this.drawing.id, label).subscribe((result) => {
      this.backendService.removeLabelLinkImages(this.assetId, this.drawing.id, label.id).subscribe((result) => { })
      this.deleteLabelDialogRef.close();
    })
  }

  preview(image) {
    this.router.navigateByUrl(`/dashboard/projects/${image.projectId}/images/${image.id}`);
  }

  handleAdd() {
    if (this.technicalDrawings.length >= 20) {
      this.toaster.warning("You can max 20 technical drawings uploads");
      return;
    }
    if (this.asset.assetType === AssetType.SOLAR) {
      document.getElementById('tech_dxf_image').click();
    } else {
      document.getElementById('tech_image').click();

    }
  }

  async fileChoose(event) {
    if (!event.target.files.length) { return };
    const file = event.target.files[0];
    const isDXFFile = (file.name.toLowerCase().indexOf("dxf") != -1) ? true : false;
    const isKMLFile = (file.name.toLowerCase().indexOf(".kml") != -1 ||
      file.name.toLowerCase().indexOf(".kmz") != -1) ? true : false;
    const isZipFile = (file.name.toLowerCase().indexOf(".zip") != -1) ? true : false;

    if (isZipFile) {
      const zipFiles = await jsZip.loadAsync(file);
      const checkShapeFiles = Object.keys(zipFiles.files).filter((element, index) => {
        return element.indexOf('.shp') != -1;
      });
      if (!checkShapeFiles.length) {
        this.toaster.warning("zip does not contains the shape file data");
        return;
      }
    }
    this.isImageUploading$.next(true);
    this.backendService.uploadTechnicalDrawing(file).pipe().subscribe(response => {
      this.isImageUploading$.next(false);
      this.backendService.add2DModels$(this.assetId, response.link, file.name, isDXFFile, isKMLFile, isZipFile ? true : false).subscribe((result: any) => {
        this.modelSubscription.unsubscribe();
        if (isZipFile) {
          this.isShapeFileProcessing$.next(true);
          this.backendService.kmlGenerator(result.id).pipe(takeUntil(this.onDestroy$)).subscribe(response => { }, error => {
            this.isShapeFileProcessing$.next(false);
          })
        }
        this.getModels();

      })
    }, error => {
      this.isImageUploading$.next(false);
      throw (error);
    })
  }

  toggleLeftMenu() {
    this.isLeftMenu = !this.isLeftMenu;
  }
  toggleRightMenu() {
    this.isRightMenu = !this.isRightMenu;
  }

  exportQR() {
    this.drawing.status = 'processing';
    this.backendService.exportQR(this.assetId, '2D').pipe(takeUntil(this.onDestroy$)).subscribe(response => { }, error => {
      this.drawing.status = 'failed';
      throw (error);
    })
  }

  removeDrawing(drawing) {
    if (this.technicalDrawings.find(o => o.id == drawing.id)?.labels && this.technicalDrawings.find(o => o.id == drawing.id)?.labels.length != 0) {
      this.toaster.warning("Image can not be deleted,It linked with the labels")
      return;
    }

    if (this.drawing.id == drawing.id) {
      this.drawing = null;
      this.labelSubscription.unsubscribe();
    }
    this.backendService.removeTechnicalDrawing(drawing.id)

  }

  public isChecked() {
    return this.technicalDrawings?.filter(o => o.checked).length ? false : true;
  }

  addToReport() {
    this.dialogRef.close({
      images: this.technicalDrawings?.filter(o => o.checked)
    })
  }

  toggle2DPanelView() {
    this.is2DPanelExpand = !this.is2DPanelExpand;
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 100);
    }
    if (this.baseFile && this.drawing) {
      setTimeout(() => {
        this.activate(this.drawing);
      }, 200);
    }
  }

  toggleDXFPanelView() {
    this.is2DPanelExpand = !this.is2DPanelExpand;
    this.handleResize();
  }


  toggleKmlPanelView() {
    this.is2DPanelExpand = !this.is2DPanelExpand;
    if (this.map) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, 50);
    }
  }

  filterImages() {
    let selectedLeftProjectImages = [];
    let selectedRightProjectImages = [];
    const _this = this;
    if (this.dxfFile || this.kmlFile) {
      isChecked(this.nodes);
      console.log(selectedLeftProjectImages);
      console.log(selectedRightProjectImages);
      const labels = this.nodes.filter(o => o.checked);
      this.dialogRef.close({
        modelId: this.dxfFile ? this.dxfFile.id : this.kmlFile.id,
        labels: labels,
        type: 'DXF',
        leftProjectImages: selectedLeftProjectImages.filter(this.generalService.onlyUnique),
        rightProjectImages: selectedRightProjectImages.filter(this.generalService.onlyUnique)
      })

    }
    else {
      const labels = this.annotations.filter(o => o.checked);
      this.dialogRef.close({
        modelId: this.drawing.id,
        labels: labels
      })
    }

    function isChecked(nodes) {
      nodes.forEach(node => {
        if (node.layer === 'L5' && node.checked) {
          if (_this.leftLinkedMedia && _this.leftLinkedMedia[node.id] && _this.leftLinkedMedia[node.id]['images'])
            selectedLeftProjectImages = selectedLeftProjectImages.concat(_this.leftLinkedMedia[node.id]['images'])

          if (_this.rightLinkedMedia && _this.rightLinkedMedia[node.id] && _this.rightLinkedMedia[node.id]['images'])
            selectedRightProjectImages = selectedRightProjectImages.concat(_this.rightLinkedMedia[node.id]['images'])
        }
        if (node.children && node.children.length) {
          isChecked(node.children)
        }
      });

    }
  }

  getSelectedLabels() {
    let checkboxChecked = false;
    if (this.dxfFile || this.kmlFile) {
      if (this.nodes) {
        isChecked(this.nodes)
        if (checkboxChecked) {
          return true;
        }
      }
    } else {
      if (this.annotations && this.annotations.filter(o => o.checked).length > 0) {
        return true;
      }
      return false;
    }
    return false;
    function isChecked(nodes) {
      nodes?.forEach(node => {
        if (node.checked) {
          checkboxChecked = true;
        }
        if (node.children) {
          isChecked(node.children)
        }
      });
    }

  }

  /**
  * Sets up the view manager.
  * @return {Viewer}
  */
  viewer;
  createViewer(model) {
    const viewerEl: any = document.getElementById('dxfPanel');
    let modelWidth = (window.innerWidth / 2);
    let modelHeight = window.innerHeight - (this.type === 'asset' ? 190 : 50);
    viewerEl.width = modelWidth;
    viewerEl.height = modelHeight;
    this.viewer = new DXFViewer(viewerEl, { modelOptions: model.modelOptions || null });
    return this.viewer;

  }


  geoReference = []
  savePoints(data) {
    this.geoReference.push({
      panel: data.panel,
      latitude: data.latitude,
      longitude: data.longitude
    })
    this.georeferenceDialogRef.close();
  }

  dxfLoading: boolean = false;
  isLoging: boolean = false;
  initDXF(model) {
    this.viewer = this.viewer || this.createViewer(model);
    this.dxfLoading = true;
    if (this.uiService.dxfId === this.dxfFile.id && this.uiService.rawDXF) {
      this.viewer.scene.add(this.uiService.rawDXF);
      this.viewer.centerCamera();
      this.dxfLoading = false;
    } else {
      this.backendService.getFile(model.fileUrl).subscribe((result: any) => {
        this.viewer.loadDXF(result);
        this.dxfLoading = false;
        if (!this.dxfFile.panelLayers) {
          const observable = interval(1000);
          this.panelSubscription = observable.pipe(
            filter(() => {
              return this.viewer.layers && this.dxfFile
            })
          ).subscribe(() => {
            this.panelSubscription.unsubscribe();
            if (this.viewer.layers) {
              const dialogRef = this.dialog.open(DxfLayersComponent, {
                disableClose: true,
                data: { lists: Object.keys(this.viewer?.layers) },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.backendService.update2DModels$(this.dxfFile.id, { panelLayers: result }).subscribe(() => {
                    this.backendService.dxfExtract(this.dxfFile.id).subscribe(() => { });
                  });
                }
              });
            }
          });
        }
      })
    }

    /**Click event */
    const _this = this;
    let click: any;
    this.viewer.renderer.domElement.addEventListener('pointerdown', onDownClick, true)
    function onDownClick(event) {
      if (_this.viewer.toolBox) {
        return;
      }
      const time = new Date().getTime();
      const currentTime = click;
      click = time;
      if (time - currentTime < 300 && event.which === 1) {
        if (_this.type != 'asset' && _this.type != 'project') {
          return;
        }

        //double click event

        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(
          {
            x: ((event.offsetX) / _this.viewer.renderer.domElement.clientWidth) * 2 - 1,
            y: -((event.offsetY) / _this.viewer.renderer.domElement.clientHeight) * 2 + 1,

          },
          _this.viewer.camera
        )
        const intersects = raycaster.intersectObjects(_this.viewer.mesh.children, false)
        if (intersects.length && intersects[0].object.userData.entity) {
          _this.handleSelect(intersects[0].object.userData.entity);

        }
        /*  if (intersects.length && this.enableGepPoints) {
  
            intersects.forEach(intersect => {
              intersect.object.material.color.set(0xff9800);
            });
          }*/

      }

    };

    /**Selecton event */
    this.viewer.selectionChanges.subscribe((data) => {
      this.selectionOnDXFandKML(data);
    });


  }

  toolBoxValue: string;
  onValChange(event) {
    if (this.viewer && this.toolBoxValue === this.viewer.toolBox) {
      this.viewer.toolBox = "";
      this.toolBoxValue = "";
    }
    else {
      this.toolBoxValue = event;
    }
  }

  onKMZValChange(event) {
    if (this.toolBoxValue === this.toolBox) {
      this.toolBox = "";
      this.toolBoxValue = "";
    }
    else {
      this.toolBoxValue = event;
    }
  }


  // Store references to rectangles by their IDs
  rectangles = {};
  createShape(data) {
    let parent: any;
    if (this.dxfFile ? this.viewer.toolBox === 'L1'
      : this.toolBox === 'L1') {
      if (!this.nodes) {
        this.nodes = [];
      }
      parent = this.nodes;
    }
    if (this.dxfFile ? this.viewer.toolBox === 'L2'
      : this.toolBox === 'L2') {
      if (!this.nodes[data.indexes[0]][`${props}`]) {
        this.nodes[data.indexes[0]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`];
    }

    if (this.dxfFile ? this.viewer.toolBox === 'L3'
      : this.toolBox === 'L3') {
      if (!this.nodes[data.indexes[0]][`${props}`]
      [data.indexes[1]][`${props}`]
      ) {
        this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`]
    }

    if (this.dxfFile ? this.viewer.toolBox === 'L4'
      : this.toolBox === 'L4') {
      if (!this.nodes[data.indexes[0]][`${props}`]
      [data.indexes[1]][`${props}`][data.indexes[2]][`${props}`]
      ) {
        this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`]

    }

    if (this.dxfFile ? this.viewer.toolBox === 'L5'
      : this.toolBox === 'L5') {
      if (!this.nodes[data.indexes[0]][`${props}`]
      [data.indexes[1]][`${props}`][data.indexes[2]][`${props}`][data.indexes[3]][`${props}`]
      ) {
        this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`][data.indexes[3]][`${props}`] = [];
      }
      parent = this.nodes[data.indexes[0]][`${props}`][data.indexes[1]][`${props}`][data.indexes[2]][`${props}`][data.indexes[3]][`${props}`]
    }
    const children = [];
    if (this.dxfFile ? this.viewer.toolBox === 'L4'
      : this.toolBox === 'L4') {
      const panels = this.dxfFile ?
        data.panels.map(o => o.entity.handle) :
        data.panels;
      panels.forEach((handle, i) => {
        children.push({
          name: `Pnl ${i + 1}`,
          panels: [handle],
          //  startPoint: data.startPoint,
          //  endPoint: data.endPoint,
          id: uuidv4(),
          layer: `L5`
        })
      });
    }

    parent.push({
      name: data.name,
      children: children,
      panels: this.dxfFile ? data.panels.map(o => o.entity.handle) : data.panels,
      startPoint: data.startPoint,
      endPoint: data.endPoint,
      id: data.id,
      layer: data.layer
    })
    const nodes = [];
    this.nodes.forEach((e1, p) => {
      const node = {
        id: e1.id,
        name: e1.name,
        ...e1,
        children: [],
      };
      nodes.push(node)
      e1[`${props}`]?.forEach((e2, i) => {
        const node = {
          id: e2.id,
          name: e2.name,
          ...e2,
          children: []
        }
        nodes[p].children.push(node);
        e2[`${props}`]?.forEach((e3, j) => {
          const node = {
            id: e3.id,
            name: e3.name,
            ...e3,
            children: [],
          };
          nodes[p].children[i].children.push(node);
          e3[`${props}`]?.forEach((e4, k) => {
            const node = {
              id: e4.id,
              name: e4.name,
              ...e4,
              children: [],
            };
            nodes[p].children[i].children[j].children.push(node);
            e4[`${props}`]?.forEach((e5, l) => {
              const node = {
                id: e5.id,
                name: e5.name,
                ...e5,
                children: [],
              };
              nodes[p].children[i].children[j].children[k].children.push(node);
            });
          });
        });
      });
    });

    this.nodes = nodes;
    if (this.dxfDialogRef) {
      this.dxfDialogRef.close();
    }
    this.isLoging = false;
    if (this.dxfFile) {
      this.viewer.createBox(data.id, data.startPoint, data.endPoint, data.layer);
    } else {
      this.addRectangle(data.id, data.startPoint, data.endPoint, data.layer, false);

    }

    this.triggerUpdate();

  }

  maxMatchedRecords(array = [], panels) {

    const item = array.reduce((maxMatchRecord, item) => {
      const matchCount = panels.reduce((count, filterItem) => {
        return count + (item.panels.includes(this.dxfFile ? filterItem.entity.handle : filterItem) ? 1 : 0);
      }, 0);

      if (matchCount > (maxMatchRecord ? maxMatchRecord.maxMatchCount : 0)) {
        return { maxMatchCount: matchCount, record: item };
      } else {
        return maxMatchRecord;
      }
    }, null);
    if (item && item.maxMatchCount) {
      return [item.record];
    } else {
      return [];
    }
    /* 
    const filteredArray = array.filter(item => panels.some((filterItem) => {
        return item.panels.includes(filterItem.entity.handle);
      }))
    
    let maxMatchedArray = [];
      let maxMatchedCount = 0;
      filteredArray.forEach(item => {
        const matchedCount = item.panels.filter(panel => panels.some(filterItem => filterItem.entity.handle === panel)).length;
        if (matchedCount > maxMatchedCount) {
          maxMatchedArray = [item];
          maxMatchedCount = matchedCount;
        } else if (matchedCount === maxMatchedCount) {
          maxMatchedArray.push(item);
        }
      });
      return maxMatchedArray;
      */
  }

  selectedPanelId: string;
  selectedLayer;
  isLayerShow: boolean = false;
  handleNodeClick(node, cameraFitToObject: boolean = false) {
    let panels = node.data.panels || [];
    this.selectedPanelId = node.data.id;
    this.rcImages.images = [];
    if (this.selectedLayer) {
      this.map.removeLayer(this.selectedLayer)
    }
    if (this.kmlFile) {
      this.showMarkedEntities(false);
      this.isLayerShow = false;
    }

    // Right side images for panel entity
    if (this.projectId && node.data.layer === 'L5' && this.linkedMedia && (this.type == 'asset' || this.type == 'project')) {
      if (this.is2DPanelExpand) {
        this.dxfFile ? this.toggleDXFPanelView() : this.toggleKmlPanelView();
      }
      this.selectedLabel = node.data;
      let keys = Object.keys(this.linkedMedia);
      this.linkImages = [];
      this.backendService.cancelFetchImage$.next();
      this.backendService.getPanels(this.projectId, panels[0]).subscribe((panel: any) => {
        const element = keys.find(o => o === this.selectedLabel.id);
        if (element) {
          this.linkedMedia[element]['images']?.forEach(imageId => {
            if (imageId && !this.linkImages.find(o => o.id == imageId)) {
              this.backendService.getImage$<any>(imageId).subscribe((image: any) => {
                if (image.deleted != true) {
                  this.linkImages.push({ ...image, uuid: uuidv4(), type: 'images', panel })
                }
              });
            }
          })
          this.linkedMedia[element]['videos']?.forEach(videoId => {
            if (videoId && !this.linkImages.find(o => o.id == videoId)) {
              this.backendService.getVideo$<any>(videoId).subscribe((video: any) => {
                if (video.deleted != true) {
                  this.linkImages.push({ ...video, type: 'videos' })
                }
              });
            }
          })
        }
      })

    }

    // Other Entity selected
    if (node.data.layer != `L5`) {
      // hide all marked boundaries and marked selected one
      if (this.dxfFile) {
        const groups = this.viewer.scene.children.filter(o => o.name != 'PanelViewer' && o.name != 'DXFViewer' &&
          o.name != node.data.id);
        groups.forEach(group => {
          group.visible = false;
        });
        const shape = this.viewer.scene.children.find(o => o.name === node.data.id);
        if (!shape) {
          this.viewer.createBox(node.data.id, node.data.startPoint, node.data.endPoint, node.data.layer)
        } else {
          shape.visible = true;
        }
      } else {
        let keys = Object.keys(this.rectangles);
        keys.forEach(key => {
          this.map.removeLayer(this.rectangles[key]);
          delete this.rectangles[key];
        });
        this.addRectangle(node.data.id, node.data.startPoint, node.data.endPoint, node.data.layer, true)
      }
    }

    // Panel Entity Selected
    if (node.data.layer === `L5`) {
      if (this.dxfFile) {
        const groups = this.viewer.scene.children.filter(o => o.name === 'shapeLayers' && o.userData.id != node.data.id);
        groups.forEach(group => {
          group.visible = false
        });
        const scene = this.viewer.scene.children.filter(o => o.userData?.id === node.data.id);
        if (scene.length === 0) {
          const panelLayer = this.viewer.scene.children.filter(o => o.name === 'PanelViewer');
          if (panelLayer.length) {
            const layers = panelLayer[0].children.filter(layer => panels.includes(layer.userData.entity.handle));
            this.viewer.createGroup(node.data.id, layers.map(o => o.userData.entity), 0xff0000, cameraFitToObject)
          }
        } else {
          scene[0].visible = true;
        }
      }
      else {
        // KMZ panel click
        const layers = this.kmz.getLayers();
        layers.forEach(el => {
          let keys = Object.keys(el._layers);
          keys.forEach(key => {
            const entity = el._layers[key].feature?.properties?.Unique_Id;
            if (entity === panels[0]) {

              let keys = Object.keys(this.rectangles);
              keys.forEach(key => {
                this.map.removeLayer(this.rectangles[key]);
                delete this.rectangles[key];
              });

              var polygon = L.polygon(el._layers[key].getLatLngs(), {
                fillColor: 'red', // Fill color (green in this example)
                fillOpacity: 0.5     // 
              }).addTo(this.map);
              this.selectedLayer = polygon;
              if (cameraFitToObject) {
                var bounds = L.latLngBounds(node.parent.data.startPoint, node.parent.data.endPoint);
                this.map.fitBounds(bounds);
              }
              //this.addRectangle(node.parent.data.id, node.parent.data.startPoint, node.parent.data.endPoint, node.parent.data.layer, true)
              return;
            }
          });
        });
      }
    }

    if (this.dxfFile) {
      this.handleResize();
      const selectedShape = this.viewer.scene.children.filter(o => o.name === 'PanelViewer')[0].children.filter(o => node.data.panels.includes(o.userData.entity.handle))
      this.viewer?.setCameraToPanel(selectedShape);
    }
  }


  handleEditNodeClick(node) {
    const name = node.data.name;
    this.renameDialogRef = this.dialog.open(this.renameDialog, {
      data: { node: node }
    })
    this.renameDialogRef.afterClosed().subscribe(() => {
      if (name != node.data.name && node.data.name) {
        this.nodes = this.replaceName(this.nodes, node.data.layer, node.data.name.replace(/\d/g, ''))
        this.triggerUpdate();
      }
    })
  }

  replaceName(nodes, layer, value) {
    nodes.forEach((node, i) => {
      if (node.layer === layer) {
        node.name = value + " " + (i + 1);
      }
      if (node.children.length) {
        this.replaceName(node.children, layer, value)
      }
    });
    return nodes;
  }

  handleDeleteNodeClick(node) {
    this.deleteLabelDialogRef = this.dialog.open(this.deleteLabelDialog, {
      data: { node: node, isDXF: true }
    });
  }

  deletedNodes = []
  deleteNodeLabel(node) {
    function removeElementById(data, targetId) {
      let deletedIds = [];
      function removeRecursive(children) {
        const filteredEntity = children.reduce((result, item) => {
          if (item.id === targetId) {
            // Add the IDs of the deleted children to the array
            deletedIds = deletedIds.concat(getChildIds(item));
            return result; // Skip this item and its children
          }

          const newItem = { ...item }; // Create a shallow copy to avoid modifying the original data

          if (item.children && item.children.length > 0) {
            // Recursively remove elements from the 'children' array
            newItem.children = removeRecursive(item.children);
          }

          result.push(newItem);
          return result;
        }, []);

        return filteredEntity;
      }

      const filteredData = data.reduce((result, item) => {
        if (item.id === targetId) {
          // Add the IDs of the deleted children to the array
          deletedIds = deletedIds.concat(getChildIds(item));
          return result; // Skip this item and its children
        }

        const newItem = { ...item }; // Create a shallow copy to avoid modifying the original data

        if (item.children && item.children.length > 0) {
          // Recursively remove elements from the 'children' array
          newItem.children = removeRecursive(item.children);
        }

        result.push(newItem);
        return result;
      }, []);

      return { filteredData, deletedIds };
    }

    // Helper function to get all child IDs
    function getChildIds(item) {
      let ids = [item.id];

      if (item.children && item.children.length > 0) {
        for (const childItem of item.children) {
          const childIds = getChildIds(childItem);
          ids = ids.concat(childIds);
        }
      }
      return ids;
    }

    const targetId = node.data.id; // The ID you want to remove
    const { deletedIds, filteredData } = removeElementById(this.nodes, targetId);
    this.nodes = filteredData;
    this.triggerUpdate();

    this.deletedNodes = this.deletedNodes.concat(deletedIds);
    deletedIds.forEach(id => {
      if (this.dxfFile) {
        const panel = this.viewer.scene.children.find(o => o.name === id);
        if (panel) {
          this.viewer.scene.remove(panel)
        }
      } else {
        if (this.rectangles[id]) {
          this.map.removeLayer(this.rectangles[id])
          delete this.rectangles[id];
        }

      }

    });
    this.deleteLabelDialogRef.close();
  }

  handleResize = () => {
    if (this.viewer.scene.children) {
      let modelWidth = (window.innerWidth / (!this.is2DPanelExpand ? 2 : 1));
      let modelHeight = window.innerHeight - (this.type === 'asset' ? 190 : 50);
      this.viewer.resize(modelHeight, modelWidth)
    }
  }

  closePanel() {
    this.backendService.closeManualGeoPosition(this.dxfFile.id);
    this.geoPointsDialogRef.close();
  }

  enableGepPoints: boolean = false;
  openGeoPanelHint() {
    this.enableGepPoints = true;
    this.viewer.toolBox = "georeference";
    this.geoPointsDialogRef.close();

  }

  cancelLabels() {
    this.labelsChanged = false;
    this.nodes = JSON.parse(this.previousNode);
  }

  saveLabels() {
    const data = this.nodes;
    this.createBtnOptions.active = true;
    this.backendService.uploadLabelsJson(this.dxfFile.id, data).pipe().subscribe((response: any) => {
      this.backendService.update2Dlabels$(this.dxfFile.id, response.link, this.levels).subscribe()
      this.labelsChanged = false;
      this.createBtnOptions.active = false;
      this.toaster.success("DXF drawing and labels saved successfully.");
    }, error => {
      this.toaster.error("Something issue to store your labels");
      this.createBtnOptions.active = false;
      throw (error);

    })

    /*  this.backendService.createTechnicalDrawingLabels(this.dxfFile, data, true).subscribe((result) => {
     if (this.deletedNodes.length) {
       this.backendService.removedDXFLabelLinkImages(this.assetId, this.dxfFile.id, this.deletedNodes).subscribe((result) => { })
       this.deletedNodes = [];
     }
      })
      */
  }

  previousNode: any;
  getSolarLabels(dxf) {
    if (dxf.labelsFile) {
      this.backendService.getFile(dxf.labelsFile).subscribe((labels: any) => {
        this.previousNode = labels;
        this.nodes = JSON.parse(labels) || [];
      })
    }
  }

  captureNew() {
    const renderer = this.viewer.renderer;
    this.isImageUploading$.next(true);
    const imgData = renderer.domElement.toDataURL('image/jpeg');
    this.backendService.uploadBase3DScreenshots(imgData).pipe().subscribe((response: any) => {
      this.isImageUploading$.next(false);
      this.backendService.add2DScreenshots$(this.dxfFile.id, response.link).subscribe()
    }, error => {
      this.isImageUploading$.next(false);
      throw (error);
    })
  }

  addDXFToReport() {
    this.dialogRef.close({
      images: this.dxfFile?.screens?.filter(o => o.checked),
      modelId: this.dxfFile.id,
      annotations: this.annotations
    })
  }

  edit(screen) {
    screen.editing = true;
  }

  update() {
    const screens = this.dxfFile.screens.filter(function (obj) {
      delete obj.editing;
      return delete obj.checked;
    });
    this.backendService.update2DScreenshots$(this.dxfFile.id, screens).subscribe()
  }

  public isDXFFileChecked() {
    return this.dxfFile?.screens?.filter(o => o.checked).length ? false : true;
  }

  deleteScreen(index) {
    this.dxfFile.screens.splice(index, 1);
    this.update();
  }

  deletePrompt() {
    if (this.nodes.length) {
      this.toaster.warning('Delete all labels before deleting the model')
      return;
    }
    this.confirmDialog
      .open(
        `Are you sure you want to delete the model?`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.ngOnDestroy();
          this.uiService.panelDXF = null;
          this.uiService.rawDXF = null;
          this.uiService.dxfId = null;
          this.backendService.deleteDXFModel(this.dxfFile ? this.dxfFile.id
            : this.kmlFile.id).then((result: any) => {
              this.baseFile = false;
              if (this.dxfFile) {
                this.isPanelDXF = false;
                this.dxfFile = null;
                this.viewer = null;
              } else {
                this.kmlFile = null;
                this.map = null;
                this.isKMZLoad = false;
              }
              this.selectedLabel = null
              this.getModels();
            });
        }
      });
  }

  nodeSelect(node, event) {
    if (event) {
      isChecked(node.data);
    }
    function isChecked(node) {
      node.children.forEach(child => {
        child.checked = true;
        if (child.children) {
          isChecked(child)
        }
      });
    }
  }

  calculateRotation(image): number {
    if (image.metaData && !image.isRotate) {
      // You may need to adjust this logic based on your specific requirements
      return -parseFloat(image.metaData.GimbalYawDegree);//
    }
    return 0;
  }

  calculateImageRotation(image): number {
    if (image.isRotate) {
      return parseFloat(image.metaData.GimbalYawDegree);
    }
    return 0;
  }

  checkYaw(image) {
    return image.metaData?.GimbalYawDegree && parseFloat(image.metaData.GimbalYawDegree) ? true : false
  }

  rotateImage(image) {
    if (!image.isRotate) {
      image.isRotate = false
    }
    image.isRotate = !image.isRotate;
  }

  onDoubleTap(level) {
    this.levelDialogRef = this.dialog.open(this.levelDialog, {
      data: {
        name: this.levels[level],
        level: level,
      },
    });
  }

  saveLevel(data) {
    if (data.name != this.levels[data.level]) {
      this.nodes = this.replaceName(this.nodes, this.levels[data.level], data.name.replace(/\d/g, ''))
      this.triggerUpdate();
    }
    this.levels[data.level] = data.name;
    this.levelDialogRef.close();
  }

  triggerUpdate() {
    this.selectionChange.emit({
      nodes: this.nodes,
      levels: this.levels,
      id: this.dxfFile ? this.dxfFile.id : this.kmlFile.id
    })
  }

  logInfo(image) {
    this.dialog.open(LogFileComponent, {
      width: '30vw',
      data: {
        logFrame: image.logFrame,
        fileName: image.fileName
      },
    });

  }


  hoverChildContextImages(area: TaggedArea, enabled: boolean) {
    if (enabled) {
      area.isShow = true;
    } else {
      area.isShow = false;
    }
  }

  map;
  /*loadKMLOLD(data) {
    this.drawing = data;
    if (this.map) {
      return;
    }
    this.map = new google.maps.Map('map', {
      //  center: new google.maps.LatLng(47.19241240198025, -2.6876490053146833),
      zoom: 2,
      minZoom: 10,
      mapTypeId: 'terrain'
    });
    var kmlLayer = new google.maps.KmlLayer(data.fileUrl, {
      suppressInfoWindows: true,
      preserveViewport: false,
      map: this.map
    });
    this.getMapLabels(data.id);
    var testimonial = document.getElementById('capture');
    kmlLayer.addListener('click', (event) => {
      this.is3dMode = false;
      var content = event.featureData.infoWindowHtml;
      if (testimonial) {
        testimonial.innerHTML = content;
      }
      this.selectedLabel = null;
  
  
    });
  
    // Add double-click event listener to the map
    google.maps.event.addListener(this.map, 'dblclick', (event) => {
  
      if (this.type === 'report' || this.type === 'timeline') {
        return;
      }
      if (!this.isAssetOwner) {
        this.toaster.warning("Only Owners can create the labels")
        return;
      }
  
      // Create a circle at the double-clicked location
      let labelTitle = prompt('Please enter label number');
      if (!labelTitle) {
        return;
      }
      if ([].concat(...this.technicalDrawings.map(o => o.labels)).find(o => o?.title === labelTitle)) {
        this.toaster.warning("Label name should be unique. You can not create the duplicate label");
        return;
      }
      const label = [{
        "title": labelTitle || "",
        "id": uuidv4(),
        latitude: event.latLng.lat(),
        longitude: event.latLng.lng(),
        color: this.generalService.generateRandomColor()
  
      }]
      this.backendService.createTechnicalDrawingLabels(this.drawing, label).subscribe((result) => { })
    });
  }
  */
  kmz: any;
  loadKML(data) {
    this.drawing = data;
    if (this.map) {
      return;
    }
    this.setContainerSize();
    this.map = L.map('map', {
      contextmenu: false,  // Disable Leaflet's context menu
      preferCanvas: true, // recommended when loading large layers.
    });
    // this.map.setView(new L.LatLng(24.5854, 73.7125), 12);
    // Add Leaflet Geoman Free controls if needed
    const tileLayer = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 30,
      //  attribution: '&copy; OpenStreetMap contributors'
    }).addTo(this.map);

    tileLayer.on('load', function () {
      console.log('All tiles loaded successfully');
    });
    this.kmz = L.kmzLayer().addTo(this.map);
    // Load KML file
    this.kmz.load(data.fileUrl);
    //  var control = L.control.layers(null, null, { collapsed:false }).addTo(map);
    this.kmz.on('load', (e) => {
      //  control.addOverlay(e.layer, e.name);
      // Fit the map to the bounds of the loaded KMZ layer
      e.layer.addTo(this.map)
      this.map.fitBounds(e.target.getBounds());
    });
    // Add double-click event listener to the map
    this.map.on('dblclick', (event) => {
      if (this.asset.assetType === AssetType.SOLAR) {
        return; // prevent new label creation for solar assets.
      }
      if (this.type === 'report' || this.type === 'timeline') {
        return;
      }
      if (!this.isAssetOwner) {
        this.toaster.warning("Only Owners can create the labels")
        return;
      }

      // Create a circle at the double-clicked location
      let labelTitle = prompt('Please enter label number');
      if (!labelTitle) {
        return;
      }
      if ([].concat(...this.technicalDrawings.map(o => o.labels)).find(o => o?.title === labelTitle)) {
        this.toaster.warning("Label name should be unique. You can not create the duplicate label");
        return;
      }
      const label = [{
        "title": labelTitle || "",
        "id": uuidv4(),
        latitude: event.latlng.lat,
        longitude: event.latlng.lng,
        color: this.generalService.generateRandomColor()

      }]
      this.backendService.createTechnicalDrawingLabels(this.drawing, label).subscribe((result) => { })
    });

    this.map.on('zoom', () => {
      this.handleMapZoom();
    })

    this.map.off('mousemove', this.onMouseMove);
    this.map.off('mouseup', this.onMouseUp);
    this.map.off('mousedown', this.onMouseUp);
    this.map.on('mousedown', (e) => {
      this.onMouseDown(e);
    })
    this.map.on('mousemove', (e) => {
      this.onMouseMove(e);
    })
    this.map.on('mouseup', (e) => {
      this.onMouseUp(e);
    })
    if (this.asset.assetType != AssetType.SOLAR) {
      this.getMapLabels(data.id)
    }
  }

  kmzProcessing = false;
  markedLayers = [];
  selectionBox;
  intersectingProperties = []; // Array to store 
  loadShapeViewer(data) {
    if (!this.map) {
      this.map = L.map('kmlMap', {
        contextmenu: false,
        doubleClickZoom: false,
        preferCanvas: true, // recommended when loading large layers.
      });
      L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
        maxZoom: 30,
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(this.map);

    }
    this.setContainerSize();
    this.kmzProcessing = true;
    this.kmz = L.kmzLayer();
    let layerCount = 0;
    // Load KML file
    if (data.shpStatus === this.success) {
      layerCount = data.kmzUrl.length;
      data.kmzUrl.forEach(el => {
        this.kmz.load(el.url);
      });
    } else {
      data.merged_kmzUrl = data.merged_kmzUrl?.map(o => ({ ...o, checked: true }));
      layerCount = data.merged_kmzUrl.length;
      data.merged_kmzUrl?.forEach(el => {
        this.kmz.load(el.url);
      });
    }

    //const control = L.control.layers(null, null, { collapsed: false }).addTo(this.map);
    let loadedLayerCount = 0;
    this.kmz.on('load', (e) => {
      //   control.addOverlay(e.layer, e.name);
      loadedLayerCount++;
      if (loadedLayerCount === layerCount) {
        this.kmzProcessing = false;
      }
      this.markedLayers.push({
        folderName: e.name.replace(".kmz", ""),
        layers: e.layer,
        checked: true
      })

      if (e.name.replace(".kmz", "") != `${this.kmlFile.id}_component`) {
        e.layer.addTo(this.map)
        // Fit the map to the bounds of the loaded KMZ layer
        this.map.fitBounds(data.shpStatus === this.success ? e.layer.getBounds() : e.target.getBounds());
      }

      if (e.name.indexOf(`${this.kmlFile.id}_panels`) != -1) {
        // Add double-click event listener to the kmz panels
        e.layer.on('dblclick', (e) => {
          if (this.type === 'report' || this.type === 'timeline') {
            return;
          }
          const handle = e.layer.feature?.properties?.Unique_Id;
          if (handle) {
            this.handleSelect(handle)
          }
        })
      }

    });

    this.map.off('mousemove', this.onMouseMove);
    this.map.off('mouseup', this.onMouseUp);
    this.map.off('mousedown', this.onMouseUp);
    this.map.on('mousedown', (e) => {
      this.onMouseDown(e);
    })
    this.map.on('mousemove', (e) => {
      this.onMouseMove(e);
    })
    this.map.on('mouseup', (e) => {
      this.onMouseUp(e);
    })
    this.map.on('zoom', () => {
      //   this.handleMapZoom();
    })



    // Add double-click event listener to the map
    /* this.map.on('dblclick', (e) => {
     if (this.type === 'report' || this.type === 'timeline') {
       return;
     }

    const panel = this.markedLayers.find(o => o.folderName === `${this.kmlFile.id}_panels`);
     const positions = [];
     panel.layers.eachLayer((layer) => {
       layer.getLatLngs().forEach((position) => {
         positions.push({ latitude: position.lat, longitude: position.lng })
       });
       const insidePoint = geolib.isPointInPolygon({ latitude: e.latlng.lat, longitude: e.latlng.lng },positions);

       if(insidePoint){
         const handle = layer.feature?.properties?.Unique_Id;
         if (handle) {
           this.handleSelect(handle)
         }
       }
      
     })
   
   })*/
  }

  choosePanelLayers(layer) {
    const layers = this.markedLayers.filter(o => o.folderName === layer.folderName);
    layers.forEach(panel => {
      layer.checked ? this.map.addLayer(panel.layers) : this.map.removeLayer(panel.layers)
    });
  }


  // Event handler for mouse down

  startPoint = null;
  endPoint = null;
  onMouseDown(e) {
    // If the left mouse button is not pressed
    if (e.originalEvent.button !== 2 || !this.toolBox) {
      return;
    }
    if (this.selectionBox) {
      //clear last marked drawing
      this.map.removeLayer(this.selectionBox);
      this.selectionBox = null;
      this.intersectingProperties = [];
    }
    this.startPoint = e.latlng;
    this.selectionBox = L.rectangle([this.startPoint, this.startPoint], { color: "#ff7800", weight: 1, fillOpacity: 0.1 }).addTo(this.map);
    // Disable dragging and zooming interactions on the map
    this.map.dragging.disable();
    this.map.touchZoom.disable();

  }

  // Event handler for mouse move
  onMouseMove(e) {
    // If the right mouse button is not pressed
    if (!this.startPoint || (2 & e.originalEvent.buttons) === 0 || !this.selectionBox || !this.toolBox) {
      return;
    }
    // let startPoint = this.selectionBox.getBounds().getNorthWest();
    this.endPoint = e.latlng;
    let bounds = L.latLngBounds(this.startPoint, this.endPoint);
    this.selectionBox.setBounds(bounds);
    this.selectionBox.setStyle({ opacity: 0.5 }); // Adjust opacity for visibility

  }

  rcImages: any = {
    loading: false,
    images: [],
  };
  // Event handler for mouse up
  onMouseUp(e) {
    this.map.dragging.enable();
    this.map.touchZoom.enable();
    if (e.originalEvent.button == 2 && this.toolBox && this.selectionBox) {
      // Use the selection box bounds for whatever you need
      let selectionBounds = this.selectionBox.getBounds();
      console.log("Selection Box Bounds:", selectionBounds.toBBoxString());
      // Iterate through all layers on the map
      this.map.eachLayer((layer) => {
        // Check if layer is a feature layer (e.g., GeoJSON, marker, etc.)
        if (layer instanceof L.Path) {
          // Check if layer's bounds intersect with the selection box bounds
          if (selectionBounds.intersects(layer.getBounds())) {
            // Retrieve properties of the intersecting layer
            var properties = null;
            if (layer.feature && layer.feature.properties) {
              properties = layer.feature.properties;
            }
            if (properties) {
              // layer.setStyle({ color: 'blue' }); // Change color to red
              this.intersectingProperties.push(properties);
            }
          }
        }
      });

      if (this.toolBox === 'rectangle') {
        var x1 = selectionBounds.getNorthEast().lat; // Latitude of Northeast corner
        var y1 = selectionBounds.getNorthEast().lng; // Longitude of Northeast corner
        var x3 = selectionBounds.getSouthEast().lat// Latitude of Southeast corner
        var y3 = selectionBounds.getSouthWest().lng; // Longitude of Southeast corner
        // Calculate other six corners
        var x2 = x1; // Latitude of Northwest corner
        var y2 = y3; // Longitude of Northwest corner
        var x4 = x3; // Latitude of Southwest corner
        var y4 = y1; // Longitude of Southwest corner
        // Output the coordinates of all eight points
        console.log("Northeast corner: (" + x1 + ", " + y1 + ")");
        console.log("Northwest corner: (" + x2 + ", " + y2 + ")");
        console.log("Southwest corner: (" + x3 + ", " + y3 + ")");
        console.log("Southeast corner: (" + x4 + ", " + y4 + ")");

        this.rcImages.loading = true;
        this.rcImages.images = [];
        if (this.is2DPanelExpand) {
          this.toggleKmlPanelView();
        }
        this.selectedLabel = null;

        const rectangle = [x1, y1, x2, y2, x3, y3, x4, y4];
        let images = this.uiService.images.filter(o => o.imageCornerGps);
        if (!images.length) {
          this.toaster.warning("No geo reference found from image data");
          this.rcImages.loading = false;
          return;
        }
        for (let i = 0; i < images.length; i++) {
          const imageCorner = images[i].imageCornerGps;

          const imageInsidePolygon = this.isPointInPolygon(imageCorner, rectangle)
          //     const polygonInsideImage= this.isPointInPolygon(rectangle, imageCorner)

          if (imageInsidePolygon) {
            this.rcImages.images.push(images[i]);
          }
        }
        this.rcImages.loading = false;

      } else {
        const panels = this.intersectingProperties.filter(o => o.Unique_Id).map(o => o.Unique_Id);
        if (panels.length) {
          this.selectionOnDXFandKML(
            {
              id: uuidv4(),
              layer: this.toolBox,
              panels: panels.filter(this.generalService.onlyUnique),
              startPoint: this.startPoint,
              endPoint: this.endPoint,
            }
          );
        }
        this.map.removeLayer(this.selectionBox);
        this.selectionBox = null;
        this.intersectingProperties = [];
      }
    }

  }

  handleMapZoom() {
    const zoom = this.map.getZoom();
    let maxRadius = 20;
    let radius = this.map.getMaxZoom() - zoom;
    // Set the new radius for the circle
    radius = radius > maxRadius ? maxRadius : radius;
    this.circles.forEach(circle => {
      circle.setRadius(radius);
    });

  };

  getMapLabels(id) {
    if (this.labelSubscription) {
      this.labelSubscription.unsubscribe();
    }
    this.labelSubscription = this.backendService.getTechnicalDrawinglLabels$(id).subscribe((result: any) => {
      this.removeAllCircles();
      this.annotations = result.label || [];
      const index = this.technicalDrawings.findIndex(o => o.id == id)
      this.technicalDrawings[index].labels = this.annotations;
      // this.attachMapPoints(this.annotations);
      this.annotations.forEach(el => {
        if (el.latitude && el.longitude) {
          this.createCircle({ lat: el.latitude, lng: el.longitude }, 8, el)
        }
      });
      if (!this.linkImages) {
        this.getLinkedImages(id);
      }
      this.handleMapZoom();
    });
  }

  circles = [];
  // Function to create a circle
  createCircle(center, radius, el) {
    const circle = L.circle([center.lat, center.lng], {
      color: '#e1e1e1', // Color of the circle border
      fillColor: el.color || "#FF0000", // Color of the circle fill
      fillOpacity: 0.5, // Opacity of the circle fill
      radius: radius, // Radius of the circle in meters,
      customData: el,
    }).addTo(this.map);
    circle.on('click', (e) => {
      if (e.target.options.customData) {
        this.gotoAnnotation(e.target.options.customData)
      }
    });

    this.circles.push(circle);
    // Define popup content
    var popupContent = el.title;

    // Create a popup and bind it to the circle
    var popup = L.popup()
      .setContent(popupContent)
      .setLatLng(circle.getLatLng())
      .openOn(this.map);

    popup.remove();
    // Show popup on mouseover
    circle.on('mouseover', (e) => {
      popup.addTo(this.map).openPopup();
    });

    // Hide popup on mouseout
    circle.on('mouseout', (e) => {
      popup.remove();
    });
  }

  // Function to remove all circles
  removeAllCircles() {
    // Loop through the circles array and remove each circle from the map
    this.circles.forEach(circle => {
      circle.remove(); // Remove the circle from the map
    });
    // Clear the circles array
    this.circles = [];
  }

  open3D() {
    this.dialogRef.close();
    const projectId = "vc2gf3TX9efXhboqZAKL";
    const filteredProjects = this.projects.filter(o => o.assetId == "smNwFrAf5tqfWKo8UQyV")
    this.uiService.updateAssetNodeEvent$.next(filteredProjects);
    this.router.navigateByUrl(`/dashboard/projects/${projectId}`)
  }

  selectionOnDXFandKML(data) {
    const toolBox = this.dxfFile ? this.viewer?.toolBox : this.toolBox;
    let panelData: any = {};
    if (this.isLoging || !data || !data.panels.length || toolBox === 'L5') {
      return;
    }
    this.isLoging = true;

    if (toolBox === 'georeference') {
      this.georeferenceDialogRef = this.dialog.open(this.georeferenceDialog, {
        data: panelData
      });
      return;
    }
    if (toolBox === 'L1') {
      panelData = {
        ...data,
        structure: [],
        header: this.levels['L1'],
        name: this.nodes.length ?
          this.nodes[0]?.name.replace(/\d/g, '') + `${this.nodes.length + 1}` : `${this.levels['L1']} 1`
      }
    }

    if (toolBox === 'L2') {
      if (!this.nodes || !this.nodes.length) {
        this.toaster.warning(DXF.No_PDT);
        this.isLoging = false;
        return;
      }
      const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
      if (maxMatchedArray.length == 0) {
        this.toaster.warning(DXF.PDT_WRONG_MARKED);
        this.isLoging = false;
        return;
      }

      const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id)
      panelData = {
        ...data,
        indexes: [index],
        header: this.levels['L2'],
        structure: [maxMatchedArray[0].name],
        name: this.nodes[index][`${props}`].length ?
          this.nodes[index][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`].length + 1}` : `${this.levels['L2']} 1`
      }

    }

    if (toolBox === 'L3') {
      if (!this.nodes || !this.nodes.length) {
        this.toaster.warning(DXF.No_PDT);
        this.isLoging = false;
        return;
      }
      const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
      if (maxMatchedArray.length == 0) {
        this.toaster.warning(DXF.PDT_WRONG_MARKED);
        this.isLoging = false;
        return;
      }
      const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id);
      const maxMatchedArrayJB = this.maxMatchedRecords(this.nodes[index][`${props}`], data.panels);
      if (maxMatchedArrayJB.length == 0) {
        this.toaster.warning(DXF.JB_WRONG_MARKED);
        this.isLoging = false;
        return;
      }
      const JBIndex = this.nodes[index][`${props}`].findIndex(o => o.id === maxMatchedArrayJB[0].id);
      panelData = {
        ...data,
        indexes: [index, JBIndex],
        header: this.levels['L3'],
        structure: [maxMatchedArray[0].name, maxMatchedArrayJB[0].name],
        name: this.nodes[index][`${props}`][JBIndex][`${props}`].length ?
          this.nodes[index][`${props}`][JBIndex][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`][JBIndex][`${props}`].length + 1}` : `${this.levels['L3']} 1`
      }
    }

    if (toolBox === 'L4') {
      if (!this.nodes || !this.nodes.length) {
        this.toaster.warning(DXF.No_PDT);
        this.isLoging = false;
        return;
      }
      const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
      if (maxMatchedArray.length == 0) {
        this.toaster.warning(DXF.PDT_WRONG_MARKED);
        this.isLoging = false;
        return;
      }
      const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id);

      const maxMatchedArrayJB = this.maxMatchedRecords(this.nodes[index][`${props}`], data.panels);
      if (maxMatchedArrayJB.length == 0) {
        this.toaster.warning(DXF.JB_WRONG_MARKED);
        this.isLoging = false;
        return;
      }
      const JBIndex = this.nodes[index][`${props}`].findIndex(o => o.id === maxMatchedArrayJB[0].id);

      const maxMatchedArrayTR = this.maxMatchedRecords(this.nodes[index][`${props}`][JBIndex][`${props}`], data.panels);
      if (maxMatchedArrayTR.length == 0) {
        this.toaster.warning(DXF.TR_WRONG_MARKED);
        this.isLoging = false;
        return;
      }

      const TRIndex = this.nodes[index][`${props}`][JBIndex][`${props}`].findIndex(o => o.id === maxMatchedArrayTR[0].id);
      panelData = {
        ...data,
        indexes: [index, JBIndex, TRIndex],
        header: this.levels['L4'],
        structure: [maxMatchedArray[0].name, maxMatchedArrayJB[0].name, maxMatchedArrayTR[0].name],
        name: this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`].length ?
          this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`].length + 1}` : `${this.levels['L4']} 1`
      }
    }

    if (toolBox === 'L5') {
      if (!this.nodes || !this.nodes.length) {
        this.toaster.warning(DXF.No_PDT);
        this.isLoging = false;
        return;
      }
      const maxMatchedArray = this.maxMatchedRecords(this.nodes, data.panels);
      if (maxMatchedArray.length == 0) {
        this.toaster.warning(DXF.PDT_WRONG_MARKED);
        this.isLoging = false;
        return;
      }
      const index = this.nodes.findIndex(o => o.id === maxMatchedArray[0].id);

      const maxMatchedArrayJB = this.maxMatchedRecords(this.nodes[index][`${props}`], data.panels);
      if (maxMatchedArrayJB.length == 0) {
        this.toaster.warning(DXF.JB_WRONG_MARKED);
        this.isLoging = false;
        return;
      }
      const JBIndex = this.nodes[index][`${props}`].findIndex(o => o.id === maxMatchedArrayJB[0].id);

      const maxMatchedArrayTR = this.maxMatchedRecords(this.nodes[index][`${props}`][JBIndex][`${props}`], data.panels);
      if (maxMatchedArrayTR.length == 0) {
        this.toaster.warning(DXF.TR_WRONG_MARKED);
        this.isLoging = false;
        return;
      }

      const TRIndex = this.nodes[index][`${props}`][JBIndex][`${props}`].findIndex(o => o.id === maxMatchedArrayTR[0].id);
      const maxMatchedArraySTR = this.maxMatchedRecords(this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`], data.panels);
      if (maxMatchedArraySTR.length == 0) {
        this.toaster.warning(DXF.STR_WRONG_MARKED);
        this.isLoging = false;
        return;
      }

      const STRIndex = this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`].findIndex(o => o.id === maxMatchedArraySTR[0].id);
      panelData = {
        ...data,
        indexes: [index, JBIndex, TRIndex, STRIndex],
        header: this.levels['L5'],
        structure: [maxMatchedArray[0].name, maxMatchedArrayJB[0].name, maxMatchedArrayTR[0].name, maxMatchedArraySTR[0].name],
        name: this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][STRIndex][`${props}`].length ?
          this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][STRIndex][`${props}`][0]?.name.replace(/\d/g, '') + `${this.nodes[index][`${props}`][JBIndex][`${props}`][TRIndex][`${props}`][STRIndex][`${props}`].length + 1}` : `${this.levels['L5']} 1`
      }
    }

    if (toolBox === 'L1') {
      this.createShape(panelData);
    } else {
      if (this.dxfDialogRef) {
        this.dxfDialogRef.close();
      }
      panelData.structure.push(panelData.name)
      this.dxfDialogRef = this.dialog.open(this.dxfDialog, {
        data: panelData
      });
      this.dxfDialogRef.afterClosed().subscribe(() => {
        this.isLoging = false;
      })
    }
  }

  generatePanelKmz() {
    const selectedLayers = this.markedLayers.filter(o => o.checked).map(o => o.folderName)
    if (!selectedLayers.length) {
      this.toaster.warning("Please select atleat one panel layers");
      return;
    }

    this.confirmDialog
      .open(
        `Please make sure you select the correct panels?`,
        {
          trueButtonTitle: 'Yes',
          falseButtonTitle: 'No'
        }
      )
      .afterClosed()
      .subscribe((result: boolean) => {
        if (result) {
          this.backendService.update2DModels$(this.kmlFile.id, { panelLayers: selectedLayers }).subscribe(() => {
            this.backendService.kmzExtract(this.kmlFile.id).subscribe(() => { });
          });
        }
      });
  }

  addRectangle(id, startPoint, endPoint, layer, fitBound = false) {
    var bounds = L.latLngBounds(startPoint, endPoint);
    const rectangle = L.rectangle(bounds, { color: this.lineColors[layer || this.toolBox], weight: 1 }).addTo(this.map);
    this.rectangles[id] = rectangle;
    if (fitBound) {
      this.map.fitBounds(bounds);
    }
    return rectangle;
  }

  otherLayers(event) {
    const layer = this.markedLayers.find(o => o.folderName === `${this.kmlFile.id}_component`);
    if (layer) {
      if (event.checked) {
        this.map.addLayer(layer.layers);
        this.kmz.addLayer(layer.layers)

      } else {
        this.map.removeLayer(layer.layers);
        this.kmz.removeLayer(layer.layers);

      }
      this.map.fitBounds(this.kmz.getBounds());
    }
  }

  handleSelect(handle) {
    function getNodeByLayer(data, handle) {
      const panels = [];
      function search(tree) {
        if (tree.panels.includes(handle)) {
          panels.push(tree);
        }

        if (tree.children) {
          tree.children.forEach(child => search(child));
        }
      }

      data.forEach(item => search(item));
      return panels;
    }
    const nodes = getNodeByLayer(this.nodes, handle);
    if (nodes.length) {
      this.treeComponent.treeModel.collapseAll();
    }
    nodes.forEach(node => {
      const panel = this.treeComponent.treeModel.getNodeById(node.id);
      if (panel) {
        panel.expand(true);
        if (node.layer === 'L5') {
          this.handleNodeClick(this.treeComponent.treeModel.getNodeById(node.id), false)
          return;
        }
      }
    });
  }

  isPointInPolygon(corner, points) {
    const checkPoints = [
      points.slice(0, 2), // Northeast
      points.slice(2, 4), // Northwest
      points.slice(4, 6), // Southwest
      points.slice(6, 8)  // Southeast
    ];
    let isInside = false; // Flag variable to track if any point is inside the polygon
    checkPoints.forEach(el => {
      const insidePoint = geolib.isPointInPolygon({ latitude: el[0], longitude: el[1] }, [
        { latitude: corner[0], longitude: corner[1] },
        { latitude: corner[2], longitude: corner[3] },
        { latitude: corner[4], longitude: corner[5] },
        { latitude: corner[6], longitude: corner[7] },
      ]);
      if (insidePoint) {
        isInside = true; // Set the flag to true if any point is inside the polygon
      }
    });

    return isInside; // Return the flag variable after the loop is finished
  }

  showMarkedEntities(event) {
    this.getLayers(this.nodes, event);
  }

  getLayers(nodes, value) {
    nodes.forEach(node => {
      if (node.startPoint && node.endPoint) {
        if (value) {
          const rectangle = this.addRectangle(node.id, node.startPoint, node.endPoint, node.layer, false);
          this.rectangles[node.id] = rectangle;
        } else {
          let keys = Object.keys(this.rectangles);
          keys.forEach(key => {
            this.map.removeLayer(this.rectangles[key]);
            delete this.rectangles[key];
          });
        }
      }

      if (node.children && node.children.length) {
        this.getLayers(node.children, value)
      }
    });

  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }

  linkMedias(label) {
    this.gotoAnnotation(label)
    this.addImages(label, this.drawing.id);
  }

imageSeverity(image) {
  const levels = image.tags?.filter(o => o.sensitive).map(o => o.sensitive);
  if (levels && levels.length) {
    const maxLevel = Math.max(...levels);
    const tag = image.tags?.find(element => element.sensitive === maxLevel);
    const findTag = image.tags.find(x => x.tag == tag.tag && x.status == 'active')
    if (findTag)
      return findTag.levels?.find(o => o.level == maxLevel)?.color;
  }
  return "";
}
getSeverityDetail(image) {
 return this.generalService.getSeverityDetail(image,image.tags);
}
}

