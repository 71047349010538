import { Component, OnInit, ViewChild, Input, OnChanges, SimpleChanges, AfterViewInit, AfterViewChecked, Output, EventEmitter } from '@angular/core';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import { AngularFireStorage } from '@angular/fire/storage';
import FirebaseCloudStorage from '../upload/firestorage.plugin';
import { BackendService } from '../services/backend.service';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../services/ui.service';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-template.component.html',
  styleUrls: ['./upload-template.component.scss']
})
export class UploadTemplateComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
  @Input() templates;
  private uppy;
  private title = '';
  private message = '';
  dialModalRef: any;
  loading: boolean = false;
  @ViewChild('fileVerifyDialog') fileVerifyDialog: any;
  @Output() successLoad: EventEmitter<void> = new EventEmitter();

  private isInit = true;

  constructor(private dialog: MatDialog,
    public toastr:ToastrService,
    private storage: AngularFireStorage,
    private uiService: UiService,
    private backendService: BackendService) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void { }

  ngAfterViewInit(): void { }

  ngAfterViewChecked(): void {
    if (this.isInit && document.getElementsByClassName('DashboardContainer1').length > 0) {
      this.isInit = false;
      this.init();
    }
  }

  init(): void {
    if (this.uppy) {
      this.uppy.close();
    }

    this.uppy = Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        allowedFileTypes: ['.docx'],
        maxNumberOfFiles: 1
      }
    })
      .use(Dashboard, {
        inline: false,
        trigger: '.UppyTemplateModalOpenerBtn',
        target: '.DashboardContainer1',
        replaceTargetContent: true,
        note: 'Docx only',
        maxHeight: 450,
        proudlyDisplayPoweredByUppy: false,
        closeModalOnClickOutside: true,
        fileManagerSelectionType: 'both',
      })


    this.uppy.on('file-added', (file) => {
      let templateName = prompt('Please enter template title');
      if (!templateName) {
        templateName = prompt('Please enter a valid template title');
      }
      if (!templateName) {
        this.uppy.reset();
        return;
      }

      this.title = templateName;
      this.loading = true;
      this.backendService.verifyDoc(file.data).pipe(take(1)).subscribe((result) => {
        this.loading = false;
        if (result.status === 'failed') {
          this.uppy.reset();
          this.toastr.warning( `Some mandatory tags are missing from the template: ${result.tags.join()}`)
        } else {
          this.uppy.use(FirebaseCloudStorage, {
            storageRef: this.storage.ref('templates'),
            storageCustomMetaData: {}
          });
        }

      }, error => {
        this.loading = false;
        alert('Something went wrong.');
      });

    });
    this.uppy.on('upload-success', (file, response) => {
      this.backendService.addTemplate(this.title, response.refId, this.uiService.selectedProjectId).subscribe(() => { });
    });
  }

  close() {
    this.dialModalRef.close();
  }
}
