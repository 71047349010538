<div style="position: relative; min-width: 600px">
  <div>
    <h2 mat-dialog-title>Add images</h2>

    <div class="bc">bread > crumb</div>

    <div class="folders">
      <div class="row">
        <div
          class="col-3 folders-item"
          *ngFor="let folder of folders; let i = index"
        >
          <button
            class="btn folder-button"
            (click)="toggleFolder(folder.path)"
            [ngClass]="isFolderSelected(folder.path) ? 'active' : ''"
          >
            {{ folder.name }}
          </button>
        </div>
      </div>
    </div>

    <div class="folders">
      <ng-container *ngIf="folders.length">
        <div class="d-flex">
          <ul class="mdc-image-list">
            <li class="mdc-image-list__item" *ngFor="let image of (folderImages$ | async)" (click)="toggleSelectedImage(image.id)">
              <img class="mdc-image-list__image" src="{{image.mediumFileUrl || image.thumbFileUrl}}">
              <mat-checkbox [checked]="selectedImages.includes(image.id)" [color]="'primary'" style="position: absolute; right: 5px; top: 5px"
                (click)="$event.stopImmediatePropagation()"
                (change)="toggleSelectedImage(image.id)">
              </mat-checkbox>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="folders">
      <button mat-raised-button color="primary" (click)="addImages()" [disabled]="!selectedImages.length">
        Add Images
      </button>
    </div>
  </div>
</div>
