import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {MatProgressButtonOptions} from 'mat-progress-buttons';
import { ToastrService } from 'ngx-toastr';
import { BackendService } from '../services/backend.service';
@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  authDialogRef;
  public password: string;
  public currentPassword: string;

  public createBtnOptions: MatProgressButtonOptions = {
      active: false,
      text: 'Update',
      raised: true,
      spinnerSize: 24,
      buttonColor: 'primary',
      spinnerColor: 'primary',
      mode: 'indeterminate',
      customClass: 'text-uppercase'
  };

  constructor(
      public backend: BackendService,private toastr:ToastrService,
      public dialogRef: MatDialogRef<UpdatePasswordComponent>,
      private dialog:MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  close(): void {
      this.password = '';
      this.dialogRef.close();
  }

  handleUpdate(currentPassword,password): void {
      if (currentPassword && password) {
          this.createBtnOptions.active = true;
          this.backend.getReauthenticateUser(currentPassword).then(() => {
            this.backend.updateCurrentUserPassword(password)
            .then(() => {
              this.toastr.success('', 'Password changed successfully!');
              this.createBtnOptions.active = false;
              this.close();
            }).catch(err => {
              this.createBtnOptions.active = false;         
              this.toastr.error('', err.message);
            });
          }).catch(err => {
            this.createBtnOptions.active = false;
            this.toastr.error('', err.message);
          });
      }
  }
}
