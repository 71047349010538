<div class="pl-4 pr-4">
  <div mat-dialog-title class="d-flex justify-content-center align-middle mb-5">
    <img class="header__logo-img" src="../../assets/logo.png" alt="Dive logo" />
    <span class="header__logo-text">Dive</span>
  </div>
  <h1>Create your company account</h1>
  <p class="description">
    You'll be the account admin since you're creating the account. you could give admin access to other users as well.
  </p>
  <div mat-dialog-content>
    <form class="d-flex flex-column justify-content-between" [formGroup]="form" (submit)="submit()">
      <div class="form-field mb-4">
        <label for="input-email">Company Name</label>
        <input id="input-email" type="text" maxLength="64" required cdkFocusInitial formControlName="companyName"
          [class.is-invalid]="form.get('companyName').invalid && form.get('companyName').touched" style="width: 384px"
          class="form-control input-field" placeholder="Company Name" name="companyName" [(ngModel)]="companyName" />
      </div>
      <div class="form-field">
        <label for="input-role">Industry</label>
        <br>
        <mat-form-field appearance="fill" id="input-role" style="padding: 0; width: 384px;">
          <mat-select [(value)]="industry" [placeholder]="'Select'" formControlName="industry">
            <mat-option *ngFor="let industry of backendService.industries" [value]="industry">{{industry}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button mat-raised-button type="submit" color="primary" class="submit-button"
        [disabled]="form.get('companyName').invalid || form.get('industry').invalid">
        <span class="text-uppercase">Continue</span>
      </button>
    </form>
  </div>

</div>