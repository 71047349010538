import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { UiService } from './ui.service';
import { IProject } from '../models/project.interface';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(
    private backendService: BackendService,
    private uiService: UiService
  ) { }

  getReadOnlyForCurrentUser(project?: IProject): boolean {
    if ((this.uiService.project || project) && (project || this.uiService.project).people) {
      return (project || this.uiService.project)?.people[this.backendService.getCurrentUser()?.uid].readonly
    }
    else {
      return false;
    }
  }

  getReadOnlyIfAiModalProcess(project?: IProject): boolean {
    if ((this.uiService.engine.filter(o => o.status === 'queued' || o.status === 'processing').length) > 0) {
      return true;
    }
    return false;
  }

  AIOverrideProcess(): boolean {
    const process = this.uiService.engine.filter(o => o.status === 'queued' || o.status === 'processing');
    if ((process.length) > 0) {
      if (process[0].mode === 'replace')
        return true;
    }
    return false;
  }
  compareImagesNames(a, b) {
    if (a.fileName < b.fileName) {
      return -1;
    }
    if (a.fileName > b.fileName) {
      return 1;
    }
    return 0;
  }

  // for join image arrays during scrolling
  makeImageArrayUnique(array) {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
      for (let j = i + 1; j < a.length; ++j) {
        if (a[i].id === a[j].id)
          a.splice(j--, 1);
      }
    }
    return a;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  public async getBackground(color: string = '#22222') {
    var canvas = document.createElement("canvas");
    canvas.width = 40;
    canvas.height = 40;
    var context = canvas.getContext("2d");
    context.beginPath();
    context.fillStyle = color;
    context.strokeStyle = "black";
    context.font = "15px Georgia";
    context.lineWidth = 1;
    context.arc(20, 20, 20, 0, 2 * Math.PI, false);
    context.fill();
    return canvas;
  }


  public async getText(text, color: string = '#22222') {
    var canvas = document.createElement("canvas");
    canvas.width = 300;
    canvas.height = 300;
    var ctx = canvas.getContext("2d");
    ctx.font = "100px Comic Sans MS";
    ctx.fillStyle = "red";
    ctx.textAlign = "center";
    ctx.fillText(text, canvas.width / 2, canvas.height / 2);
    return canvas;
  }
  public async getCanvasLabel(index, color: string = '#22222') {
    var canvas = document.createElement("canvas");
    canvas.width = 80;
    canvas.height = 80;
    var ctx = canvas.getContext("2d");
    const tags = color === 'transparent' ? {
      fontColor: "black",
      font: "20px arial",
      color: 'transparent',
      fit: true,
      decimals: 0,
      lineWidth: 0.5
    } :
      {
        fontColor: "white",
        font: "12px arial",
        color: color,
        strokeStyle: "#FFF",
        lineWidth: 3,
        fit: true,
        decimals: 0,
      }
    // document.body.appendChild(canvas);
    // set comments above the function declaration for help
    var sun = createCircleNumberImage(
      30, Number.isInteger(index) ? index + 1 : index, tags
    )
    ctx.drawImage(sun.draw(), ctx.canvas.width / 2 - sun.radius, ctx.canvas.height / 2 - sun.radius);
    return canvas;

    function createCircleNumberImage(radius, number, style) {
      // create HTML 5 image element
      var img: any = document.createElement("canvas");
      // size it
      img.width = Math.ceil(radius * 2);
      img.height = Math.ceil(radius * 2);
      // get a drawing context
      img.ctx = img.getContext("2d");
      // set custom attributes
      img.radius = radius;
      img.number = number;
      img.displayStyle = style;
      style.decimals = style.decimals === undefined || isNaN(style.decimals) ? 0 : style.decimals;

      // add draw function
      img.draw = function () {
        var fontScale, fontWidth, fontSize, number;
        // resize
        this.width = Math.ceil(this.radius * 2);
        this.height = Math.ceil(this.radius * 2);
        // clear (incase resize did not do it) 
        this.ctx.clearRect(0, 0, this.width, this.height);

        // draw the circle
        this.ctx.fillStyle = this.displayStyle.color;
        this.ctx.beginPath();
        this.ctx.arc(radius, radius, (this.radius - this.displayStyle.lineWidth), 0, Math.PI * 2);
        this.ctx.fill();
        // draw the circle
        this.ctx.lineWidth = this.displayStyle.lineWidth;
        this.ctx.strokeStyle = this.displayStyle.strokeStyle;
        this.ctx.stroke()

        // setup the font styles
        this.ctx.font = this.displayStyle.font;
        this.ctx.textAlign = "center";
        this.ctx.textBaseline = "middle";
        this.ctx.fillStyle = this.displayStyle.fontColor;

        // get the value to display
        number = Number.isInteger(index) ?
          this.number.toFixed(this.displayStyle.decimals) : number;

        // get the font size
        fontSize = Number(/[0-9\.]+/.exec(this.ctx.font)[0]);
        if (!this.displayStyle.fit || isNaN(fontSize)) { // Dont fit text or font height unknown
          this.ctx.fillText(number, radius, radius);
        } else {
          // fit font as based on the angle from text center to bottom right
          fontWidth = this.ctx.measureText(number).width;
          fontScale = Math.cos(Math.atan(fontSize / fontWidth)) * this.radius * 2 / fontWidth;
          this.ctx.setTransform(fontScale, 0, 0, fontScale, this.radius, this.radius);
          this.ctx.fillText(number, 0, 0);
          this.ctx.setTransform(1, 0, 0, 1, 0, 0); // restor the transform
        }

        if (!this.displayStyle.fit || isNaN(fontSize)) { // Dont fit text or font height unknown
          this.ctx.fillText(number, radius, radius);
        } else {
          fontScale = Math.cos(Math.atan(fontSize / fontWidth)) * (this.radius - this.displayStyle.lineWidth) * 2 / fontWidth;
          this.ctx.setTransform(fontScale, 0, 0, fontScale, this.radius, this.radius);
          this.ctx.fillText(number, 0, 0);
          this.ctx.setTransform(1, 0, 0, 1, 0, 0); // restor the transform
        }
        // return this so you can call the draw function from within a canvas drawImage function
        return this;
      }
      // draw first time
      img.draw()
      // return new image
      return img;
    }

  }

  getSeverityDetail(image,tags) {
    const levels = image.tags?.filter(o => o.sensitive).map(o => o.sensitive);
    if (levels && levels.length) {
      const maxLevel = Math.max(...levels);
      const tag = image.tags.find(element => element.sensitive === maxLevel);
      const findTag = tags.find(x => x.tag == tag.tag && x.status == 'active')
      if (findTag) {
        return `${findTag.tag} : ${findTag.levels?.find(o => o.level == maxLevel)?.title} - level: ${findTag.levels?.find(o => o.level == maxLevel)?.level}  `
      }
    }
    return "";
  }

  isHotspots(annotation) {
    const minTemp = annotation.minTemperature;
    const maxTemp = annotation.maxTemperature;
    const projectMinTemp = this.uiService.project.minTemperature;
    const projectMaxTemp = this.uiService.project.maxTemperature;
    const stdDev = this.uiService.project.std_dev;

    if (projectMinTemp && minTemp && maxTemp &&
      projectMaxTemp && stdDev) {
      if (maxTemp >= (projectMaxTemp + stdDev)) {
        return true; //hotspots
      }
      if (minTemp <= (projectMinTemp - stdDev)) {
        return false; //coldspots
      }
    } else {
      if (annotation.temperature && projectMinTemp &&
        projectMaxTemp && stdDev) {
        if (annotation.temperature >= (projectMaxTemp + stdDev)) {
          return true; //hotspots
        }
        if (annotation.temperature <= (projectMinTemp - stdDev)) {
          return false;   //coldspots
        }
      }
    }
    return null;
  }

  generateRandomColor() {
    var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;
  }
}
