import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})

export class LandingComponent implements OnInit {
  name='Request Demo Account';
  appName=environment.appName;
  constructor(private route:ActivatedRoute) {
    this.route.queryParams.subscribe((params) => {
      if(params && params.project){
        this.name='Get Started';

      }
    });
  }

  ngOnInit(): void {}
}
