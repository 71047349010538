import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../services/backend.service';
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationOptions } from 'intl-input-phone';
@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  public params: any;
  form: FormGroup;
  submitted = false;
  loading;
  invitedData;
  configOption: ConfigurationOptions;
  constructor(
    private readonly router: Router,
    private route: ActivatedRoute,
    private backend: BackendService,
    private formBuilder: FormBuilder,
    private toast: ToastrService
  ) {
    this.configOption = new ConfigurationOptions();
    this.configOption.SelectorClass = "WithBasic";
    this.configOption.IsShowToolTip = false;
    //this.configOption.OptionTextTypes =[ContentOptionsEnum.CountryPhoneCode]
    //this.configOption.ToolTipText=[ContentOptionsEnum.CountryPhoneCode]
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (this.params.action === 'addToProject') {
        this.backend.getProspectiveById(this.params.id).subscribe((res) => {
          if (!res.exists) {
            this.toast.warning("Link was expired");
            this.router.navigate(['/signin']);
          } else {
            this.invitedData = res.data();
            console.log(this.invitedData)
            /*const emailElement: any = document.querySelector("input[formControlName='email']")
             emailElement.value = this.invitedData.email;
            emailElement.disabled = true;
            */
          }
        });
      }
    })

  }

  isRegisterButtonDisabled(isDisable) {
    const regButton: any = document.getElementById('createAccountButton');
    isDisable ? regButton.classList.add('mat-button-disabled') :
      regButton.classList.remove('mat-button-disabled');
    regButton.disabled = isDisable;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, this.validateEmail]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      companyName: ['', Validators.required],
      companyAddress: ['', Validators.required],
      contactNumber: ['', Validators.required],
      companyWebsite: ['']
    });
  }


  validateEmail(c: AbstractControl): { [key: string]: any } {
    const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!c.value) {
      return null;
    }
    return EMAIL_REGEXP.test(c.value)
      ? null
      : {
        validEmail: true
      };
  }


  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  handleSuccess(): void {
    sessionStorage.setItem('sessionLogout', new Date(moment().endOf('week').toDate()).getTime().toString())
    const readonly = (this.invitedData?.readonly || this.params.readonly).toString() == 'true' ? true : false;
    if (this.params.action === 'addToProject') {
      this.isRegisterButtonDisabled(true);
      this.backend.addProjectPeople(this.invitedData?.projectId || this.params.project, this.invitedData?.role || this.params.role, readonly, this.backend.getCurrentUser().uid, this.backend.getCurrentUser().email).subscribe((result) => {
        this.isRegisterButtonDisabled(false);
        this.router.navigate(['/dashboard']);
      }, error => {
        this.isRegisterButtonDisabled(false);
        this.toast.error('Something went wrong for your link');
      });
    }
    else if (this.params.redirectUrl) {
      this.router.navigate([this.params.redirectUrl]);
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

  public navigateToSignIn(): void {
    if (this.params) {
      this.router.navigate(['signin'], {
        queryParams: {
          project: this.params.project,
          role: this.params.role,
          action: this.params.action,
          id: this.params.id,
          readonly: this.params.readonly
        }
      });
    } else {
      this.router.navigate(['signin']);
    }
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    const formValue = this.form.value;
    this.loading = true;
    const html = `<p>User Name : ${formValue.name}</p>
    <p>Email : ${formValue.email}</p>
    <p>Company : ${formValue.companyName}</p>
    <p>Address : ${formValue.companyAddress}</p>
    <p>Contact : ${formValue.contactNumber.Number}</p>
    <p>Website : ${formValue.companyWebsite}</p>`
    const params = {
      name: formValue.name,
      email: formValue.email,
      password: formValue.password,
      address: formValue.companyAddress,
      contact: formValue.contactNumber.Number,
      website: formValue.companyWebsite,
      requested: true,
      created: false
    }

    this.backend.accountRequest(html, params).subscribe((result) => {
      this.loading = false;
      this.form.reset();
      this.toast.success('Your request submitted successfully.When your account will be ready, we will inform you')
      this.router.navigate(['/']);
    })

  }

  openWebSite() {
    window.open(`https://oceansai.tech/products/deepdive?requestUrl=${window.location.origin}`)
  }
}
