<div class="popup-header w-100 d-flex">
  <p class="w-100">
    {{ name }}
  </p>



  <span  class="d-flex w-50" *ngIf="isLoading">
    <mat-spinner [diameter]="20" class="spinner"></mat-spinner>
    fetching data
  </span>


  <button *ngIf="data.selectedTabIdx == 0" mat-raised-button style="border: 1px solid #fff" color="primary"
    [disabled]="spinner || isLoading" (click)="makeReport()" class="dark">
    Make report
  </button>
  <mat-spinner matToolTip="editMode ? 'Loading data' : 'Processing'" [diameter]="20" class="spinner" *ngIf="spinner">
  </mat-spinner>
  <button [disabled]="spinner || isLoading" class="ml-2 mr-4 dark" style="border: 1px solid #fff" *ngIf="data.selectedTabIdx == 0"
    mat-raised-button color="primary" (click)="saveReport()">
    Save
  </button>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="grid-container bg full h-100 position-relative px-3 pt-0">
  <div class="grid-x h-100 position-relative">

    <mat-tab-group class="report-editor mat-small-tabs" [(selectedIndex)]="data.selectedTabIdx">
      <mat-tab label="Reports">
        <ng-container [ngTemplateOutlet]="reportsContent"></ng-container>
      </mat-tab>
      <mat-tab label="Initials">
        <ng-container [ngTemplateOutlet]="initialsContent"></ng-container>
      </mat-tab>
    </mat-tab-group>
    <ng-template #reportsContent>
      <div class="cell h-100 auto position-relative">

        <!-- MAIN TOOLBAR ICONS-->
        <div class="report-heading border-bottom pt-1 pb-2 mb-3 d-flex mt-0">
          <div class="d-flex text-right report-toolbar ml-auto align-items-center">
            <div style="cursor: pointer;" (click)="toggleBorder()">
              <mat-icon matTooltip="image border OFF" *ngIf="!imageBorder">
                image
              </mat-icon>
              <mat-icon style="color:#4456F9" matTooltip="image border ON" *ngIf="imageBorder"> image</mat-icon>

            </div>


            <div style="cursor: pointer;" (click)="gridView()">
              <mat-icon matTooltip="Single column reporting" *ngIf='gridColumn == "one"'><span class="material-icons">
                  view_sidebar
                </span></mat-icon>
              <mat-icon matTooltip="Dual column reporting" *ngIf='gridColumn == "two"'><span class="material-icons">
                  view_array
                </span></mat-icon>

            </div>
            <div style="cursor: pointer;" (click)="manageGroupReport()">+ Report Groups</div>

            <div style="cursor: pointer;margin-left:10px;" *ngIf="asset?.baseModel === '3d'
            && asset.assetType != 'solar'" (click)="capture3DScreens()" matTooltip="Add screenshots for report">3D
            </div>
            <div style="cursor: pointer;margin-left:10px;"
              *ngIf="asset?.baseModel === '2d'   && asset.assetType != 'solar'" (click)="getTechnicalDrawings()"
              matTooltip="Add 2D plane for report">2D</div>

            <div (click)="collapseAllNodes()" style="cursor: pointer;" class="ml-2">
              <ng-container *ngIf="!isCollapse">Collapse</ng-container>
              <ng-container *ngIf="isCollapse">Expand</ng-container>
              All
            </div>
          </div>
        </div>


        <div class="d-flex">
          <div cdkScrollable class="editor-container editor-styles">


            <!-- HEADING TEMPLATE-->
            <div class="d-flex justify-content-between" style="padding: 7px 0; margin: 0;min-height: 60px;">
              <div style="border:1px solid #46B3E6" class="node-item node-item_main m-0 w-100">
                <div class="node-title">
                  <input type="text" class="btn-outline-none" [(ngModel)]="currentHeading" matInput
                    (focus)="clearHeading()" (focusout)="addDefaultHeading()"
                    style="outline: none; display: block; width: 100%" maxlength="100"
                    (keydown.enter)="addHeading();$event.target.value= ''; " />

                </div>
              </div>
            </div>

            <!-- NODE TEMPLATE -->
            <ng-template #tmplNode let-node="node" let-idx="idx">
              <div class="node-item node-item_main mr-0 mt-3" [attr.data-id]="node.id" [attr.id]="'node-' + node.id"
                [style.marginLeft]="
                node.type == 'heading' ? 40 * (node.level - 1) + 'px' : 0
              " [style.width]="node.type == 'relation' ? '420px' : 'auto'"
                [class.node-border]="node.type == 'heading'">

                <div class="node-title position-relative" *ngIf="node.type == 'heading' || node.type =='features'">
                  <!-- HEADING TYPE-->
                  <div *ngIf="node.type == 'heading'" class="d-flex justify-content-between border-bottom pb-2">
                    <p class="m-0 w-75">
                      <input type="text" class="w-100" maxlength="100" matInput [(ngModel)]="node.data"
                        (click)="$event.stopImmediatePropagation()" />
                    </p>
                    <p class="m-0 d-flex align-items-center report-icons" style="cursor: pointer">
                      <span *ngIf="!node.isModelType" class="d-flex" (click)="addImages(node.id)">
                        <mat-icon matTooltip="folder images">image</mat-icon>
                      </span>
                      <span *ngIf="node.isMap || !node.isModelType" class="btn-text" matTooltip="text" (click)="
                        addText(node.id); $event.stopImmediatePropagation()
                      ">+&nbsp;Text</span>
                      <span *ngIf="!node.isModelType" class="d-flex ml-2 icon-context" matTooltip="context"
                        (click)="this.manageTitles(node.id); $event.stopImmediatePropagation()">
                        <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="30" height="30" rx="3" fill="#c2c2c2" />
                          <rect x="16.3638" y="16.3636" width="8.18182" height="8.18182" rx="1" fill="#7e7e7e" />
                        </svg>
                      </span>
                      <span matTooltip="timeline"
                        *ngIf="backendService.DOMAIN_CONTROL?.features?.sideBySide  && !node.isModelType"
                        class="d-flex ml-2" (click)="this.manageSideBySide(node.id); $event.stopImmediatePropagation()">
                        <mat-icon>
                          <img src="assets/timeline.png" />
                        </mat-icon>
                      </span>
                      <span class="d-flex ml-2">
                        <mat-icon *ngIf="!node.isModelType"
                          [class.disabled]="idx === 0 || nodeLookup[node.id].level < 2" (click)="
                          decreaseLevel(node.id, nodes[idx-1]?.id);
                          $event.stopImmediatePropagation()
                        ">arrow_back</mat-icon>
                        <mat-icon *ngIf="!node.isModelType"
                          [class.disabled]="idx === 0 || !checkIncreaseLevelArrow(node.id, nodes[idx-1].id)" (click)="
                          increaseLevel(node.id, nodes[idx-1]?.id);
                          $event.stopImmediatePropagation()
                        ">arrow_forward</mat-icon>
                        <mat-icon (click)="toggleNode(node)"> {{node.isExpanded ? 'arrow_drop_down' : 'arrow_drop_up'}}
                        </mat-icon>
                        <mat-icon [ngStyle]="{ color: '#EB5757' }" (click)="removeNode(node.id)">close
                        </mat-icon>
                      </span>

                    </p>
                  </div>

                  <!-- FEATURES-->
                  <div *ngIf="node.type == 'features'" class="d-flex justify-content-between border-bottom pb-2">
                    <p class="m-0 w-75">
                      <input type="text" class="w-100" maxlength="100" matInput [(ngModel)]="node.data"
                        (click)="$event.stopImmediatePropagation()" />
                    </p>
                    <p class="m-0 d-flex align-items-center report-icons" style="cursor: pointer">
                      <span class="d-flex ml-2">
                        <mat-icon (click)="toggleNode(node)"> {{node.isExpanded ? 'arrow_drop_down' : 'arrow_drop_up'}}
                        </mat-icon>
                        <!-- <mat-icon [ngStyle]="{ color: '#EB5757' }" (click)="removeNode(node.id)">close
                        </mat-icon> -->
                      </span>

                    </p>
                  </div>
                </div>

                <div *ngIf="node.type == 'image'" class="d-flex ">
                  <ul class="mdc-image-list">
                    <li class="mdc-image-list__item flex-column" [ngStyle]="getGridLi()">
                      <img style="object-fit: contain;" class="mdc-image-list__image w-100" [src]="node.data.fileUrl"
                        [style.border]="imageBorder ? '2px solid rgb(227, 227, 227)': 'none'" />

                      <div *ngIf="!node.isModelImages">{{node.data?.fileName}} </div>
                      <div *ngIf="node.data?.narration" matTooltip="{{node.data?.narration}}" class="narration">
                        {{node.data?.narration}} </div>
                      <div *ngIf="node.data?.labels" matTooltip="labels">
                        Locations: {{node.data?.labels}} </div>
                      <div class="text-center w-100" *ngIf="node.isModelImages">
                        {{node.data.title}}
                      </div>
                      <div *ngIf="!node.isModelImages" class="mdc-image-list__supporting" style="flex-wrap: wrap">
                        <div class="w-100 image-info">
                          <p>

                            <span>
                              <button class="details" type="button" matTooltip="Feature count"><img
                                  src="assets/icons/annotations.svg" class="mr-1"> <b>{{node.polygons?.length ||
                                  0}}</b></button>

                              <button class="details" type="button" matTooltip="Contexts"><img
                                  src="assets/icons/context.svg" class="mr-1">
                                <b>{{getContext(node.polygons)}}</b></button>
                              <button class="details" type="button" matTooltip="Findings"><img
                                  src="assets/icons/annotated-images.svg" class="mr-1">
                                <b>{{getNotes(node.polygons)}}</b></button>

                              <button class="details" type="button" matTooltip="Groups"><img
                                  src="assets/icons/group.svg" class="mr-1"><b>{{getImageGroup(node)}}</b></button>

                            </span>
                            <!-- Annotations :<b>{{node.polygons?.length || 0}}</b>&nbsp;
                            Notes :<b>{{getNotes(node.polygons)}}</b>
                            Contexts :<b>{{getContext(node.polygons)}}</b>&nbsp;
                            Groups : <b>{{getImageGroup(node)}}</b> -->
                          </p>

                        </div>
                        <!-- <mat-checkbox [(ngModel)]="node.notes">Notes</mat-checkbox> -->

                        <!-- showing image annotations information -->
                        <!-- <div *ngIf="node.polygons && node.polygons.length" class="w-100">
                        <div *ngFor="let polygon of node.polygons">
                          <div class="d-flex" *ngIf="polygon.sensitive?.toString() || polygon.note">
                            <span class=" text-nowrap" *ngIf="polygon.tag">
                              <b>Tag:</b> {{ polygon.tag }}
                            </span>
                            <span class="ml-2 text-nowrap" *ngIf="polygon.sensitive?.toString()">
                              <b>Sensitive:</b> {{formatLabel(+polygon.sensitive)}}
                            </span>
                            <span class="ml-2" *ngIf="polygon.note"><b>Note:</b> {{polygon.note}}</span>
                          </div>
                        </div>
                      </div> -->
                        <!--   ====    ================    ====     -->
                      </div>
                    </li>
                  </ul>
                </div>

                <div *ngIf="node.type == 'relation'" class="d-flex image-relation" style="max-width: 420px;">
                  <div class="image-wrapper">
                    <div class="img-placeholder" style="width: 192px; height: 115px; object-fit: cover">
                      <img *ngIf="node.data.imagesThumbs[node.data.parentImagePath]"
                        [src]="node.data.imagesThumbs[node.data.parentImagePath]" alt="Image" class="img-placeholder"
                        style="width: 100%; height: 100%; object-fit: contain" />
                    </div>
                  </div>

                  <div class="image-wrapper align-self-end ml-2">
                    <div class="img-placeholder" style="width: 192px; height: 115px; object-fit: cover">
                      <img *ngIf="node.data.imagesThumbs[node.data.childImagePath]"
                        [src]="node.data.imagesThumbs[node.data.childImagePath]" alt="Image" class="img-placeholder"
                        style="width: 100%; height: 100%; object-fit: contain" />
                    </div>
                    <!-- <button mat-icon-button class="edit-button">
                    <mat-icon svgIcon="pencil_outline"></mat-icon>
                  </button> -->
                  </div>


                </div>

                <div *ngIf="node.type == 'timeline'" class="d-flex image-relation"
                  style="max-width: 420px;padding-top:30px;">
                  <div class="image-wrapper">
                    <div class="img-placeholder" style="width: 192px; height: 115px; object-fit: cover">
                      <img *ngIf="node.imagesThumbs[node.keyImageId]" [src]="node.imagesThumbs[node.keyImageId]"
                        alt="Image" class="img-placeholder" style="width: 100%; height: 100%; object-fit: contain" />
                    </div>
                  </div>
                  <div class="image-wrapper align-self-end ml-2">
                    <div class="img-placeholder" style="width: 192px; height: 115px">
                      <img *ngIf="node.imagesThumbs[node.valueImageId]" [src]="node.imagesThumbs[node.valueImageId]"
                        alt="Image" class="img-placeholder" style="width: 100%; height: 100%; object-fit: contain" />
                    </div>
                  </div>
                  <p class="image-node-title">{{node.title}}</p>
                </div>



              
                  <app-ckeditor [style.border]="!node.textBorder ? 'none' : ' 1px solid #CACACA'"
                  [config]="{ toolbar: ['bold', 'italic','NumberedList','BulletedList', 'BlockQuote','Undo','Redo'] }"
                     *ngIf="node.type == 'text'" [(ngModel)]="node.data"></app-ckeditor>

                <mat-icon (click)="textBorder(true,node.id)" class="text-grid-node" matTooltip="text border OFF"
                  *ngIf=" node.type === 'text' && !node?.textBorder"> <span class="material-icons">
                    border_all
                  </span></mat-icon>
                <mat-icon (click)="textBorder(false,node.id)" class="text-grid-node" matTooltip="text border ON"
                  *ngIf=" node.type === 'text' && node?.textBorder"> <span class="material-icons">
                    border_clear
                  </span></mat-icon>
                <mat-icon *ngIf="node.type != 'heading' && node.type != 'features'" class="remove-node"
                  [ngStyle]="{ color: '#EB5757' }" (click)="removeNode(node.id)">close
                </mat-icon>

                <div *ngIf="
                  node.isExpanded &&
                  node.children.length &&
                  node.type == 'heading'
                " class="node-child row" cdkDropListGroup cdkDropList [cdkDropListData]="node.children"
                  (cdkDropListDropped)="dropChildren($event,node)">
                  <div *ngFor="let child of node.children; let idx = index;" cdkDrag class="text-center"
                    [class]="child.type=='image' && !node.modelType && gridColumn =='two'?  'col-6' : 'col-12'">
                    <ng-container *ngTemplateOutlet="tmplNode; context: { node: child, idx: idx }"></ng-container>
                    <div *ngIf="node.type == 'heading' && node.modelType === '3d' && node.children.length === idx+1"
                      class="d-flex justify-content-between">
                      <div style="text-align: center;width: 100%;padding: 10px;" *ngIf="node.annotations">
                        {{getLabels(node.annotations)}}
                      </div>
                    </div>


                  </div>


                </div>


                <!-- PROJECT SCOPE TEMPLATE-->
                <div [innerHTML]="projectScope" *ngIf="node.isExpanded && node.type == 'heading' && node.isProjectScope"
                  class="m-2 justify-content-between"></div>

                <!-- MAP TEMPLATE-->
                <div *ngIf="node.isExpanded && node.type == 'heading' && node.isMap"
                  class="d-flex justify-content-between">
                  <img [src]="node.fileUrl" />
                </div>


                <!-- CERTIFICATES TEMPLATE-->
                <div *ngIf="node.isExpanded && node.type == 'heading' && (node.isCertificates|| node.isDocuments)"
                  class="m-2 justify-content-between">
                  <div *ngFor="let child of node.urls; let idx = index;">
                    {{idx + 1}}. <a [href]="child.url" target="_blank">{{child.documentName}}
                    </a>
                  </div>
                </div>
                <div *ngIf="
                node.isExpanded &&
                node.children.length &&
                node.type == 'features'
              " class="node-child row" cdkDropListGroup cdkDropList [cdkDropListData]="node.children"
                  (cdkDropListDropped)="dropChildren($event,node)">
                  <div *ngFor="let child of node.children; let idx = index;" cdkDrag class="w-100 text-center">

                    <!-- FEATURE TABLE TEMPLATE -->
                    <div *ngIf="child.type === 'findings_table'" class="w-90 text-left">
                      Findings
                      <mat-icon [ngStyle]="{ color: '#EB5757',float:'right' }" (click)="removeNode(child.id)">close
                      </mat-icon>
                      <table class="table w-100">
                        <thead>
                          <tr>
                            <th scope="col" class="text-uppercase">Feature Name</th>
                            <th scope="col" class="text-uppercase">Annotations Count</th>
                            <th scope="col" class="text-center">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let tag of child.data.tags">
                            <td>{{ tag.tag }}
                            </td>
                            <td class="text-capitalize">
                              {{getFindingCount(tag)}}
                            </td>
                            <td class="text-center">
                              {{tag.description}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <!-- SEVERITY TABLE TEMPLATE -->
                    <div *ngIf="child.type === 'severity_table'" class="w-90 text-left">
                      Severity [{{child.data.tag}}]
                      <mat-icon [ngStyle]="{ color: '#EB5757',float:'right' }" (click)="removeNode(child.id)">close
                      </mat-icon>
                      <table class="table w-100">
                        <thead>
                          <tr>
                            <th scope="col" class="text-uppercase">Level</th>
                            <th scope="col" class="text-uppercase">Title</th>
                            <th scope="col" class="text-center">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let level of child.data.levels">
                            <td>
                              <span [style.background]="level.color" class="circle severity"></span>

                              {{ level.level }}
                            </td>
                            <td class="text-capitalize">
                              {{level.title}}
                            </td>
                            <td class="text-center">
                              {{level.action}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>


            </ng-template>

            <!-- MAIN CONTAINER -->
            <div cdkDropList [cdkDropListData]="nodes" [id]="'main'" (cdkDropListDropped)="drop1($event)">
              <div *ngFor="let node of nodes, let idx = index;" cdkDrag >
                <ng-container *ngTemplateOutlet="tmplNode; context: { node: node, idx: idx }"></ng-container>
              </div>
            </div>
          </div>

          <div class="cell contents content-styles">
            <div [hidden]="isLoading" class="sidebar-content">
              <div class="title text-uppercase">Contents</div>
              <div class="node-title scope">
                <mat-checkbox (change)="includeProjectScope($event.checked)" [(ngModel)]="isProjectScope"
                  [color]="primary">
                  <mat-label>Project scope </mat-label>
                </mat-checkbox>
                <mat-checkbox (change)="featureChange($event.checked)" [(ngModel)]="isFeatures" [color]="primary">
                  <mat-label>Features </mat-label>
                </mat-checkbox>
                <mat-checkbox *ngIf="asset?.mapImage" (change)="includeMapScreenShots($event.checked)"
                  [(ngModel)]="data.isMapScreen" [color]="primary">
                  <mat-label>Maps </mat-label>
                </mat-checkbox>
                <mat-checkbox *ngIf="project?.certificates" (change)="includeCertificate($event.checked)"
                  [(ngModel)]="data.isCertificate" [color]="primary">
                  <mat-label>Certificates </mat-label>
                </mat-checkbox>
                <mat-checkbox *ngIf="project?.documents" (change)="includeDocuments($event.checked)"
                  [(ngModel)]="data.isDocuments" [color]="primary">
                  <mat-label>Documents </mat-label>
                </mat-checkbox>

              </div>
              <!-- CONTENTS CONTAINER -->
              <div cdkDropList [cdkDropListData]="nodes" [id]="'main-contents'"
                (cdkDropListDropped)="drop1($event, true)">
                <div *ngFor="let node of nodes" cdkDrag>
                  <ng-container *ngTemplateOutlet="tmplNodeContents; context: { node: node }"></ng-container>
                </div>
              </div>

              <!-- CONTENTS TEMPLATE -->
              <ng-template #tmplNodeContents let-node="node" let-idx="idx">
                <div
                  *ngIf="node.type !== 'text' && node.type !== 'relation' && node.type !== 'image' && node.type !== 'timeline'"
                  class="node-item focusable mr-0 p-1 mt-3" [attr.data-id]="node.id"
                  [attr.id]="'node-contents-' + node.id" [style.marginLeft]="
                  node.type == 'heading' ? 20 * (node.level - 1) + 'px' : 0
                ">
                  <div matTooltip="{{node.data}}" class="node-item_contents">
                    {{ (node.type === "image") ? "image" : node.data }}
                  </div>
                  <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                  <!-- <div *ngIf="node.children.length && node.type == 'heading'" class="pl-3" 
                   >
                    <div *ngFor="let child of node.children;  let idx = index;" 
                    >
                      <div class="node-item_placeholder"></div>
                      <ng-container *ngTemplateOutlet="tmplNodeContents; context: { node: child, idx: idx  }">
                      </ng-container>
                    </div>
                  </div> -->
                </div>
              </ng-template>



            </div>
          </div>
        </div>

      </div>

    </ng-template>
  </div>
</div>

<!-- ** initial template for tabs ** -->
<ng-template #initialsContent>

  <app-project-initials class="w-100" *ngIf="data.projectId" [projects]="data.projects"
    [projectUsers]="data.projectUsers" [projectId]="data.projectId"></app-project-initials>
</ng-template>
<!-- ************* -->


<ng-template let-data #baseModel>
  <app-base-model *ngIf="asset?.baseModel === '3d'" [projects]="data.projects" [isAssetOwner]="data.isAssetOwner"
    [assetId]="data.assetId" [selectedProjectId]="data.selectedProjectId" [dialogRef]="modelDialogRef" [type]="'report'"
    [asset]="data.asset">
  </app-base-model>

  <app-base-image-model *ngIf="asset?.baseModel === '2d'" [projects]="data.projects" [isAssetOwner]="data.isAssetOwner"
    [assetId]="data.assetId" [dialogRef]="modelDialogRef" [type]="'report'" [asset]="data.asset">
  </app-base-image-model>
</ng-template>