import { Injectable } from '@angular/core';
import { combineLatest, from, iif, Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { TaggedArea } from '../models/tagging.interface';
import { IFolder } from '../models/folder.interface';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class CombineQueriesService {

  constructor(
    private backendService: BackendService
  ) { }

  getFolders$(projectId: string): Observable<IFolder[]> {
    return this.backendService.getFolders$(projectId).pipe(
      map((res: any) => this.parseFolderResponse(res)),
      switchMap(({ folders, docRefs }) => {
        return combineLatest([
          ...docRefs.map((ref, i) => {
            return this.getFoldersOf$(ref, ref.path, folders[i]);
          }),
          of([])
        ]).pipe(
          map((children: IFolder[][]) => {
            folders.forEach((fold, i) => fold.children = children[i]);

            return folders.sort((a, b) => a.name.localeCompare(b.name));
          })
        );
      }),
    );
  }

  private parseFolderResponse(response: any, parent?: IFolder): { folders: IFolder[], docRefs: any } {
    const docRefs = [];
    let folders: IFolder[] = [];
    response.docs.forEach((doc: any) => {
      const folder: IFolder = {
        name: doc.data().name,
        doc: doc.data(),
        docId: doc.id,
        path: parent ? `${parent.path}/folders/${doc.id}` : doc.ref.path,
        breadcrumb: {
          name: parent ? `${parent.breadcrumb.name}/${doc.data().name}` : doc.data().name,
        },
        children: [],
      };
      docRefs.push(doc.ref);
      folders.push(folder);
    });
    // folders = [...folders.sort((a, b) => a.name.localeCompare(b.name))];
    return ({ folders, docRefs });
  }

  getFoldersOf$(docRef: any, path: string, parent: IFolder): Observable<IFolder[]> {
    const getFoldersChild$ = from(docRef.collection('folders').get());
    return getFoldersChild$.pipe(
      map((res: any) => this.parseFolderResponse(res, parent)),
      switchMap(({ folders, docRefs }) => {
        return combineLatest([
          ...docRefs.map((ref, i) => {
            return this.getFoldersOf$(ref, `${parent.path}/folders/${folders[i].docId}`, folders[i]);
          }),
          of([])
        ]).pipe(
          map((children: IFolder[][]) => {
            folders.forEach((fold, i) => fold.children = children[i]);
            return folders;
          })
        );
      }),
    );
  }

  getFoldersImages$(folderPath: string, projectId: string): Observable<any> {
    return this.backendService.getFolderContents$(folderPath, projectId).pipe(
      catchError(() => of([])),
      switchMap((images: any[]) =>
        iif(() => images.length > 0,
          combineLatest(images.map(image =>
            this.backendService.getImageAnnotations$<{ polygons: Record<string, TaggedArea> }>(image.id)
          )).pipe(
            map((annotations) => {
              annotations.forEach((annotation, i) => {
                images[i].tags = annotation ? annotation.polygons : [];
              });
              return images;
            })
          ),
          of([])
        ),
      ));
  }

  getFolderAndSubfolderImages$(folder: IFolder, projectId: string): Observable<any> {
    if (folder === null) {
      return timer(50).pipe(switchMap(() => of([])));
    }

    return combineLatest([
      this.getFoldersImages$(folder.path, projectId),
      ...folder.children.map(childFolder => {
        return this.getFolderAndSubfolderImages$(childFolder, projectId);
      }),
      of([]),
    ]).pipe(
      map((result: any[]) => {
        const images = [];
        result.forEach(imgArr => images.push(...imgArr));
        return images;
      }),
    );
  }

  // getFolderAndSubfolderImages$ with scrolling
  getFolderAndSubfolderImagesFirst$(folder: IFolder, projectId: string): Observable<any> {
    if (folder === null) {
      return timer(50).pipe(switchMap(() => of([])));
    }

    return combineLatest([
      this.getFoldersImagesFirst$(folder.path, projectId),
      ...folder.children.map(childFolder => {
        return this.getFolderAndSubfolderImages$(childFolder, projectId);
      }),
      of([]),
    ]).pipe(
      map((result: any[]) => {
        const images = [];
        result.forEach(imgArr => images.push(...imgArr));
        return images;
      }),
    );
  }

  getFolderAndSubfolderImagesNext$(folder: IFolder, projectId: string): Observable<any> {
    if (folder === null) {
      return timer(50).pipe(switchMap(() => of([])));
    }

    return combineLatest([
      this.getFoldersImagesNext$(folder.path, projectId),
      ...folder.children.map(childFolder => {
        return this.getFolderAndSubfolderImages$(childFolder, projectId);
      }),
      of([]),
    ]).pipe(
      map((result: any[]) => {
        const images = [];
        result.forEach(imgArr => images.push(...imgArr));
        return images;
      }),
    );
  }

  getFoldersImagesFirst$(folderPath: string, projectId: string): Observable<any> {
    return this.backendService.getFolderContentsFirst$(folderPath, projectId)
      .pipe(
        catchError(() => of([])),
        switchMap((images: any[]) =>
          iif(() => images.length > 0,
            combineLatest(images.map(image =>
              this.backendService.getImageAnnotations$<{ polygons: Record<string, TaggedArea> }>(image.id)
            )).pipe(
              map((annotations) => {
                annotations.forEach((annotation, i) => {
                  images[i].tags = annotation ? annotation.polygons : [];
                });
                return images;
              })
            ),
            of([])
          ),
        ));
  }

  getFoldersImagesNext$(folderPath: string, projectId: string): Observable<any> {
    return this.backendService.getFolderContentsNext$(folderPath, projectId).pipe(
      catchError(() => of([])),
      switchMap((images: any[]) =>
        iif(() => images.length > 0,
          combineLatest(images.map(image =>
            this.backendService.getImageAnnotations$<{ polygons: Record<string, TaggedArea> }>(image.id)
          )).pipe(
            map((annotations) => {
              annotations.forEach((annotation, i) => {
                images[i].tags = annotation ? annotation.polygons : [];
              });
              return images;
            })
          ),
          of([])
        ),
      ));
  }


  getVideoImages(
    projectId: string,
    videoId: string
  ): Observable<any> {
    return this.backendService.getVideoImagesContent(projectId, videoId).valueChanges({ idField: 'id' }).pipe(
      catchError(() => of([])),
      switchMap((images: any[]) =>
        iif(() => images.length > 0,
          combineLatest(images.map(image =>
            this.backendService.getImageAnnotations$<{ polygons: Record<string, TaggedArea> }>(image.id)
          )).pipe(
            map((annotations) => {
              annotations.forEach((annotation, i) => {
                images[i].tags = annotation ? annotation.polygons : [];
                images[i].type = 'images'

              });
              return images
            })
          ),
          of([])
        ),
      ));

  }

  getFirstFolderImages$(folder: IFolder, projectId: string): Observable<any> {
    if (folder === null) {
      return timer(50).pipe(switchMap(() => of([])));
    }
    return this.backendService.getFolderContentsFirst$(folder.path, projectId)
      .pipe(
        map((images: any[]) => {
          return images;
        }),
      );
  }

  getNextFolderImages$(folder: IFolder, projectId: string): Observable<any> {
    if (folder === null) {
      return timer(50).pipe(switchMap(() => of([])));
    }
    return this.backendService.getFolderContentsNext$(folder.path, projectId)
    .pipe(
      map((images: any[]) => {
        return images;
      }),
    );
  }
  // ==============================================
}
