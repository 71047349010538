import { Component, Inject, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { take } from 'rxjs/operators';
import { UiService } from 'src/app/services/ui.service';
@Component({
  selector: 'app-report-html-viewer',
  templateUrl: './report-html-viewer.component.html',
  styleUrls: ['./report-html-viewer.component.scss']
})
export class ReportHtmlViewerComponent implements OnInit {
htmlContent;

  constructor(public backendService: BackendService,
    private sanitizer:DomSanitizer,
    private uiService:UiService,
    private toastr : ToastrService,
    public dialogRef: MatDialogRef<ReportHtmlViewerComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    html;
  ngOnInit(): void {
    let htmlTemplate = this.data.html;
    const reportData = this.data.reportData;
    htmlTemplate = htmlTemplate.replace("{{ logo_url }}", this.getLogoUrl(reportData))
    .replace("{{ report_number }}", reportData["reportNumber"])
    .replace("{{ project_name }}", reportData["projectName"])
    .replace("{{ project_location }}", reportData["projectLocation"])
    .replace("{{ report_date }}", reportData["reportDate"])
    .replace("{{ client_name }}", reportData["clientName"])
    .replace("{{ project_description }}", reportData["projectDescription"])
    .replace("{{ work_permit_no }}", reportData["workPermitNo"])
    .replace("{{ work_site }}", reportData["workSite"])
    .replace("{{ location }}", reportData["location"])
    .replace("{{ personnel_name }}", reportData["personnelName"])
    .replace("{{ personnel_company }}", reportData["personnelCompany"])
    .replace("{{ personnel_position }}", reportData["position"])
    .replace("{{ concerns_comments }}", reportData["comments"])
    .replace("{{ weather_conditions }}", reportData["weatherConditions"])
    .replace("{{ daily_progress }}", reportData["dailyProgressAchieved"])
    .replace("{{ next_24h_planning }}", reportData["next24HourPlanning"]);


    const ignoredKeys = [
      "logo_url", "report_number", "project_name", "project_location", 
      "report_date", "client_name", "project_description", "work_permit_no", 
      "work_site", "location", "personnel_name", "personnel_company", 
      "personnel_position", "concerns_comments", "weather_conditions", 
      "daily_progress", "next_24h_planning","row.time","row.description",
      "row.comments"
  ];
  
  const matches = htmlTemplate.match(/{{\s*([\w\d_]+)\s*}}/g) || [];
  
  const extractedKeys = matches
      .map(match => match.replace(/{{\s*|\s*}}/g, "")) // Remove {{ and }}
      .filter(key => !ignoredKeys.includes(key)); // Exclude ignored keys
  
      extractedKeys.forEach(key => {
        const regex = new RegExp(`{{\\s*${key}\\s*}}`, 'g'); // Match with or without spaces
        htmlTemplate = htmlTemplate.replace(regex, reportData[`dynamic_${key}`] || '');
      });
     
   /* const loopContent = htmlTemplate.match(/{% for row in activity_rows %}([\s\S]*?){% endfor %}/);
    // If the loop content is found, replace it with dynamically generated rows
    if (loopContent) {
      const loopBody = loopContent[1];  // Content between the for loop tags
      let generatedRows = '';

      // Loop through the activity rows and generate table rows
      reportData.timeLogs.forEach((row) => {
        let table = loopBody;
        table = table.replace("{{ row.time }}", row.time)
        .replace("{{ row.description }}",  row.description)
        .replace("{{ row.comments }}", row.comment);
        generatedRows += table;
      });

       // Replace the placeholder with the generated rows
  this.htmlContent = htmlTemplate = htmlTemplate.replace(loopContent[0], generatedRows);
    }
    */
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlTemplate, "text/html");
    const element = doc.getElementById("timeline");
    
    if (element && element.tagName === "TR") { // Ensure it's a table row
        let generatedRows = '';
        const loopBody = element.outerHTML; // Get the existing row template
    
        reportData.timeLogs.forEach((row) => {
            let tableRow = loopBody.replace(/\n/g, '');
            tableRow = tableRow.replace("{{ row.time }}", row.time || '')
                               .replace("{{ row.description }}", row.description || '')
                               .replace("{{ row.comments }}", row.comment || '');
            generatedRows += tableRow;
        });
    
        // Convert the generatedRows string into actual DOM elements
        const tempWrapper:any = document.createElement("tbody"); // Temporary wrapper
        tempWrapper.innerHTML = generatedRows;
    
        // Insert new rows before removing the old row
        const parent = element.parentNode;
        // Remove the original template row
        parent.removeChild(element);

        for (let tr of tempWrapper.children) {
          parent.appendChild(tr.cloneNode(true)); // Clone and move each <tr> into the <table> parent
      }
    
        // Update htmlTemplate with modified content
        this.htmlContent = htmlTemplate =doc.documentElement.outerHTML;
    }
    

         
// Replace the placeholder with the actual rows
    this.html=  this.sanitizer.bypassSecurityTrustHtml(htmlTemplate)
  }
  
   getLogoUrl(reportData)
    {
        var companyLogo = reportData["companyLogo"];
        return companyLogo?.link != null ? companyLogo.link : "";
    }

    markAsReview(){
       this.backendService.updateProjectDailyReports$({...this.data.reportData,isReviewed:true,reviewDate : new Date(),
        reportLocation: {
          status: 'Reviewed' // This updates or adds the 'status' field
    }
      }, this.data.projectId).then(() => {
       this.toastr.success('Reviewed successfully');
       this.backendService.getUser(this.data.reportData.approver).pipe(take(1)).subscribe(user => {
         if(user){
          this.backendService.dailyReportSendForApproveEmail(user.data().email,this.data.reportData,this.uiService.project,this.uiService.asset).subscribe();
         }
      })
       this.dialogRef.close();
      })
    } 

    markAsApproved(){
      let data =  {...this.data.reportData,isReviewed:true,isApproved:true,approveDate : new Date(), reportHTML: this.htmlContent,
        reportLocation: {
              status: 'Approved' // This updates or adds the 'status' field
        }
      }
       this.backendService.updateProjectDailyReports$(data, this.data.projectId).then(() => {
        this.toastr.success('Approved successfully');
        this.backendService.createDailyReport(this.data.reportData.id,this.data.projectId).subscribe();
        this.dialogRef.close();
          })
    } 
  }
