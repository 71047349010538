import { Subject } from 'rxjs';
import * as THREE from 'three'
import { v4 as uuidv4 } from 'uuid';
import {
  AmbientLight,
  AnimationMixer,
  AxesHelper,
  Box3,
  Cache,
  DirectionalLight,
  GridHelper,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  OrthographicCamera,
  REVISION,
  Scene,
  SkeletonHelper,
  Vector3,
  WebGLRenderer,
  sRGBEncoding,
  LinearToneMapping,
  ACESFilmicToneMapping,
  MathUtils,
  Raycaster,
  Clock,
} from 'three';
Cache.enabled = true;
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { SelectionBox } from 'three/examples/jsm/interactive/SelectionBox';
import { SelectionHelper } from 'three/examples/jsm/interactive/SelectionHelper';
import { TextEntity } from '../DXF/src/entities/textEntity';
import { DimensionEntity } from '../DXF/src/entities/dimensionEntity';
import { LineEntity } from '../DXF/src/entities/lineEntity';
import { InsertEntity } from '../DXF/src/entities/insertEntity';
import { CircleEntity } from '../DXF/src/entities/circleEntity';
import { SplineEntity } from '../DXF/src/entities/splineEntity';
import { SolidEntity } from '../DXF/src/entities/solidEntity';
import { HatchEntity } from '../DXF/src/entities/hatchEntity';
import { Helper, parseString } from 'dxf';
import { Properties } from '../DXF/src/entities/baseEntity/properties';
import { LayerHelper } from '../DXF/src/entities/baseEntity/layerHelper';
import { ColorHelper } from '../DXF/src/entities/baseEntity/colorHelper';
const size = 10000;
export class DXFViewer {
  _font;
  selectionChanges = new Subject();
  options;
  scene;
  camera;
  renderer;
  object;
  requestId;
  controls;
  mesh;
  raycaster = new Raycaster();
  toolBox: string = "";
  lineColors = {
    L1: '#800080',
    L2: '#013220',
    L3: '#440000',
    L4: '#0000FF',
    L5: '#FF0000'
  }
  colorHelper;
  LayerHelper;
  onBeforeTextDraw;

  constructor(el, options) {
    this.colorHelper = new ColorHelper();
    this.LayerHelper = new LayerHelper();

    this.options = options;

    this.scene = new Scene();
    this.renderer = new WebGLRenderer({ preserveDrawingBuffer: true, antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setClearColor(`#e1e1e1`);
    this.renderer.setSize(el.width, el.height);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFShadowMap;
    el.append(this.renderer.domElement);
    let aspect = el.width / el.height;
    this.camera = new OrthographicCamera(-size * aspect, size * aspect, size, -size, -size / 2, size);
    this.camera.updateProjectionMatrix();
    this.scene.add(this.camera);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement)
    this.controls.reset();
    this.controls.dampingFactor = .2;
    this.controls.enableDamping = true;
    this.controls.enabled = true;
    this.controls.enableRotate = false;
    // this.animate = this.animate.bind(this);
    this.renderer.domElement.addEventListener('wheel', (e) => {
      //  this.calculateThreshold();

    });
    this.animate();
  }

  animate() {
    this.requestId = requestAnimationFrame(() => { this.animate(); });
    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }



  remove() {
    this.clear();
    cancelAnimationFrame(this.requestId);
    this.renderer.dispose();
    this.camera.remove();
    if (this.selectionChanges) {
      this.selectionChanges.unsubscribe();
    }
  }

  clear() {
    while (this.scene.children.length > 0) { this.scene.remove(this.scene.children[0]); }
  }

  resize(height, width) {
    this.camera.aspect = width / height;
    this.camera.left = size * this.camera.aspect / - 2;
    this.camera.right = size * this.camera.aspect / 2;
    this.camera.top = size / 2;
    this.camera.bottom = - size / 2;
    if (this.lastZoom) {
      this.camera.zoom = this.lastZoom;
    }
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }

  centerPosition;
  centerCamera() {
    const dxf = this.scene.children.find(o => o.name == "DXFViewer");
    let box = new Box3().setFromObject(dxf);
    let bigAxis = box.max.x - box.min.x > box.max.y - box.min.y ? 'x' : 'y';
    let size = bigAxis === 'x' ? box.max.x - box.min.x : box.max.y - box.min.y;
    let sizeFrustum = bigAxis === 'x' ? this.camera.right - this.camera.left : this.camera.top - this.camera.bottom;

    let lateralMargin = 1; //percentage of screento leave on the sides. 1 means no margin
    if (size < sizeFrustum) { this.camera.zoom = lateralMargin * (sizeFrustum / size); this.camera.updateProjectionMatrix(); }
    else this.camera.zoom = 1;

    let center = box.min.add(box.max.sub(box.min).divideScalar(2));

    this.camera.position.set(center.x, center.y, 700);
    this.controls.target.set(this.camera.position.x, this.camera.position.y, 0);
    this.lastZoom = this.camera.zoom;
    this.camera.updateProjectionMatrix();
  }

  /**
   * @param {THREE.Object3D} object
   * @param {Array<THREE.AnimationClip} clips
   */

  layers = [];
  polylineMaterial = new THREE.LineBasicMaterial({
    color: 0xff9800
  });
  createShapesFromDxfData(data: any) {
    const dxfObjects = new THREE.Group(); // Create a Three.js group to hold the objects.
    dxfObjects.name = 'PanelViewer';


    data.entities.forEach((entity: any, index) => {
      if (entity.type === 'LWPOLYLINE') {
        // Create a polygon or polyline
        const shape = new THREE.Shape();

        const points = entity.vertices.map((vertex: any) => new THREE.Vector2(vertex.x, vertex.y));
        shape.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          shape.lineTo(points[i].x, points[i].y);
        }
        shape.closePath();
        const shapeGeometry = new THREE.ShapeGeometry(shape);
        // Create a MeshBasicMaterial with a specified color
        const shapeMaterial = new THREE.MeshBasicMaterial({ color: 0xff9800 }); // Replace 0x00ff00 with your desired color

        // Create a Mesh by combining the geometry and material
        const filledShape = new THREE.Mesh(shapeGeometry, shapeMaterial);

        // Set the position of the filled shape
        filledShape.position.set(0, 0, 0);
        filledShape.name = `P_${index + 1}`;
        filledShape.visible = false;
        filledShape.userData = {
          entity: entity,
          id: `P_${index + 1}`
        }
        dxfObjects.add(filledShape);
      }
      if (entity.type === 'HATCH') {
        // Create a polygon or polyline
        const entityShape = entity.boundary?.loops[0]?.entities[0];
        if (entityShape && entityShape.type === "POLYLINE") {
          const shape = new THREE.Shape();
          const points = entityShape.points.map((vertex: any) => new THREE.Vector2(vertex.x, vertex.y));
          shape.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            shape.lineTo(points[i].x, points[i].y);
          }
          shape.closePath();
          const shapeGeometry = new THREE.ShapeGeometry(shape);
          // Create a MeshBasicMaterial with a specified color
          const shapeMaterial = new THREE.MeshBasicMaterial({ color: 0xff9800 }); // Replace 0x00ff00 with your desired color

          // Create a Mesh by combining the geometry and material
          const filledShape = new THREE.Mesh(shapeGeometry, shapeMaterial);

          // Set the position of the filled shape
          filledShape.position.set(0, 0, 0);
          filledShape.name = `P_${index + 1}`;
          filledShape.visible = false;
          filledShape.userData = {
            entity: entity,
            id: `P_${index + 1}`
          }
          dxfObjects.add(filledShape);
        }


      }
      /* if (entity.type === 'LWPOLYLINE') {
          // Create a polygon or polyline
          const points = entity.vertices.map((vertex: any) => new THREE.Vector2(vertex.x, vertex.y));
          const polylineGeometry = new THREE.BufferGeometry().setFromPoints(points);
          const polylineMaterial = this.polylineMaterial;
          const polyline = new THREE.Line(polylineGeometry, polylineMaterial);
          polyline.name = `P_${index + 1}`;
          polyline.visible = false;
          polyline.userData = {
            entity: entity,
            id: `P_${index + 1}`
          }
          dxfObjects.add(polyline);
        }*/

    });
    return dxfObjects;
  }

  async _loadFont(fontPath) {
    if (this._font) return;
    try {
      this._font = await new Promise((resolve, reject) => {
        const loader = new FontLoader();
        loader.load(fontPath, resolve, null, reject);
      });
    }
    catch (e) {
      console.log(e);
      this._font = null;
    }
  }

  async renderDxf(panelFile: any) {
    const helper = new Helper(panelFile);
    let data: any = helper.parse();
    const dxfObjects = this.createShapesFromDxfData(data);
    this.addPanelsToScene(dxfObjects);
  }

  addPanelsToScene(dxfObjects) {
    this.mesh = dxfObjects;
    const baseScene = this.scene.children.filter(o => o.name === 'DXFViewer')
    if (baseScene.length) {
      this.scene.add(dxfObjects)
    }
    this.selectionHandler(dxfObjects);
  }

  async loadDXF(file: any) {
    await this._loadFont("assets/fonts/helvetiker_regular.typeface.json");
    const helper = new Helper(file);
    let data: any = helper.parse();
    data.entities = data.entities.filter(o => o.type != 'ATTRIB');
    data.tables.layers = this.LayerHelper.parse(data.tables.layers);
    //export layers
    this.layers = data.tables.layers;
    //return draw
    this._drawDXF(data)

  }

  async _drawDXF(data) {
    let group = new THREE.Group();
    group.name = 'DXFViewer';
    group.userData = {
      name: 'DXFViewer'
    }
    //initialize
    let lines = new LineEntity(data);
    let circles = new CircleEntity(data);
    let splines = new SplineEntity(data);
    let solids = new SolidEntity(data);
    let dimensions = new DimensionEntity(data, this._font);
    let texts = new TextEntity(data, this._font);
    let inserts = new InsertEntity(data, this._font);
    let hatchs = new HatchEntity(data, this._font);

    //add callbacks
    Properties.onBeforeTextDraw = this.onBeforeTextDraw;

    //draw
    lines = lines.draw(data);
    circles = circles.draw(data);
    splines = splines.draw(data);
    solids = solids.draw(data);
    dimensions = dimensions.draw(data);
    texts = texts.draw(data);
    inserts = inserts.draw(data);
    hatchs = hatchs.draw(data);
    //add to group
    if (lines) group.add(lines);
    if (circles) group.add(circles);
    if (splines) group.add(splines);
    if (solids) group.add(solids);
    if (dimensions) group.add(dimensions);
    if (texts) group.add(texts);
    if (inserts) group.add(inserts);
    if (hatchs) group.add(hatchs);

    //this._writeCount( lines, circles, splines, solids, dimensions, texts, inserts );
    // this.rotateByView(group, data);

    //get layer names
    //		const layer_names = Object.keys(viewer.layers );

    //add entity array property to layers
    //	layer_names.forEach( name => viewer.layers[name].entities = [] );
    this.scene.add(group);
    this.centerCamera();

    const panelScene = this.scene.children.filter(o => o.name === 'PanelViewer')

    if (!panelScene.length && this.mesh) {
      this.scene.add(this.mesh)
    }

  }

  rotateByView(group, data) {
    let model = data.objects && data.objects.layouts ? data.objects.layouts.find(o => o.name.toLowerCase() === 'model') : null;
    if (!model) return;
    let keys = Object.keys(data.tables.vports);
    for (let i = 0; i < keys.length; i++) {
      let vport = data.tables.vports[keys[i]];
      if (vport.handle === model.lastActiveViewport && vport.angle !== 0) {
        group.rotateOnAxis(new Vector3(0, 0, 1), vport.angle * Math.PI / 180);
        return;
      }
    }
  }

  intersection = new THREE.Vector3();
  plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
  handleMouseWheel(event) {
    event.preventDefault();
    const camera = this.camera; const renderer = this.renderer; const scene = this.scene;
    // Calculate mouse position in normalized device coordinates (NDC)
    const mouseNDC = new THREE.Vector2();
    mouseNDC.x = (event.offsetX / renderer.domElement.clientWidth) * 2 - 1;
    mouseNDC.y = -(event.offsetY / renderer.domElement.clientHeight) * 2 + 1;

    // Calculate ray from camera to mouse position
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(mouseNDC, camera);

    raycaster.ray.intersectPlane(this.plane, this.intersection);

    // Calculate the distance from the camera to the intersection point
    const distance = camera.position.distanceTo(this.intersection);

    // Adjust the zoom level based on the wheel direction
    const zoomSpeed = 0.1; // Adjust the speed as needed
    camera.zoom -= event.deltaY * zoomSpeed;

    // Move the camera along its view direction to maintain the intersection point
    camera.position.add(raycaster.ray.direction.clone().multiplyScalar(distance - camera.position.distanceTo(this.intersection)));

  }

  calculateThreshold() {

    const size = {
      width: this.camera.right / this.camera.zoom - this.camera.left / this.camera.zoom,
      height: this.camera.top / this.camera.zoom - this.camera.bottom / this.camera.zoom
    };
    const threshold = Math.min(size.width, size.height);
    this.raycaster.params.Line.threshold = threshold / 500;
  }

  startPoint = new THREE.Vector2();
  endPoint = new THREE.Vector2();
  mouse = new THREE.Vector2();
  points = [];
  helper;
  selectionHandler(group) {
    const selectionBox = new SelectionBox(this.camera, group);
    this.helper = new SelectionHelper(this.renderer, 'selectBox');

    this.renderer.domElement.addEventListener('pointerdown', e => {
      if (e.which == 1 && this.toolBox) {
        this.helper.startPoint.set(e.clientX, e.clientY);
        this.helper.enabled = true;
        const x = (e.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        const y = -(e.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        selectionBox.startPoint.set(x, y, 0);
        this.mouse.set(x, y);
        // Convert the 2D mouse position to a 3D point on the plane
        const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(this.mouse, this.camera);
        const intersectPoint = new THREE.Vector2();
        raycaster.ray.intersectPlane(plane, intersectPoint);
        this.startPoint = { x:  raycaster.ray.origin.x, y: raycaster.ray.origin.y };


      } else {
        this.helper.enabled = false;
      }
    });

    this.renderer.domElement.addEventListener('pointerup', (e) => {
      if (e.which == 1 && this.toolBox) {
        const id = uuidv4()
        const x = (e.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        const y = -(e.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        selectionBox.endPoint.set(x, y, 0);
        // Convert the 2D mouse position to a 3D point on the plane
        const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        const raycaster = new THREE.Raycaster();
        raycaster.setFromCamera(this.mouse, this.camera);
        const intersectPoint = new THREE.Vector2();
        raycaster.ray.intersectPlane(plane, intersectPoint);
        this.endPoint = { x: raycaster.ray.origin.x, y: raycaster.ray.origin.y };;
        const allSelected = selectionBox.select();
        if (allSelected && allSelected.length) {
          this.selectionChanges.next({
            id: id,
            startPoint: this.startPoint,
            endPoint: this.endPoint,
            layer: this.toolBox,
            panels: allSelected.map(o => o.userData)
          })
        }
      }
    })

    this.renderer.domElement.addEventListener('pointermove', e => {

      // If the left mouse button is not pressed
      if ((1 & e.buttons) === 0 || !this.toolBox) {
        return;
      }
      if (this.helper.isDown) {
        const x = (e.offsetX / this.renderer.domElement.clientWidth) * 2 - 1;
        const y = -(e.offsetY / this.renderer.domElement.clientHeight) * 2 + 1;
        selectionBox.endPoint.set(
          (e.offsetX / this.renderer.domElement.clientWidth) * 2 - 1,
          - (e.offsetY / this.renderer.domElement.clientHeight) * 2 + 1,
          0);
        this.mouse.set(x, y);

      }

    });
  }


  createGroup(id, panels, color = 0xff0000, cameraFitToObject,visible=true) {
    const dxfObjects = this.createShapesFromData(panels, id, color);
    dxfObjects.visible=visible;
    this.scene.add(dxfObjects);
    if (cameraFitToObject && visible) {
      this.setCameraToPanel(dxfObjects.children)
    }
  }

  lastZoom;
  setCameraToPanel(shape) {
    if(!shape.length){
      return;
    }
    const boundingBox = new THREE.Box3();
    // Calculate the bounding box that contains all shapes
    shape.forEach(shape => {
      boundingBox.expandByObject(shape);
    });
    // Calculate the center of the bounding box
    const center = boundingBox.getCenter(new THREE.Vector3());
    // Calculate the size of the bounding box
    const bboxSize = boundingBox.getSize(new THREE.Vector3());
    // Set the camera position, lookAt, and update controls
    this.camera.position.copy(center);
    this.camera.lookAt(center);
    this.camera.aspect =this.camera.aspect=this.renderer.domElement.clientWidth/this.renderer.domElement.clientHeight;
    const size = Math.min(bboxSize.x,bboxSize.y)
    // Set orthographic camera parameters to fit all shapes


    const maxDimension = Math.max(bboxSize.x, bboxSize.y);
    const zoom = maxDimension / Math.min(this.renderer.domElement.clientWidth, this.renderer.domElement.clientHeight);

    // Adjust zoom based on the zoomFactor (e.g., zoom out by 10%)
    const zoomFactor=    zoom/0.8;
    this.camera.left = maxDimension * this.camera.aspect / -2 * zoomFactor;
    this.camera.right = maxDimension * this.camera.aspect / 2 * zoomFactor;
    this.camera.top = maxDimension / 2 * zoomFactor;
    this.camera.bottom = -maxDimension / 2 * zoomFactor;
    // Set the zoom factor dynamically
    this.camera.zoom = shape.length === 1 ? zoom/4 :zoom;
    this.camera.updateProjectionMatrix();
    this.controls.target.copy(center);
    this.controls.update();
   }

  createShapesFromData(data: any, id, color) {
    const dxfObjects = new THREE.Group(); // Create a Three.js group to hold the objects.
    dxfObjects.name = 'shapeLayers';
    dxfObjects.userData = {
      id: id
    }
    data.forEach((entity: any) => {
      if (entity.type === 'LWPOLYLINE') {
        // Create a polygon or polyline
        const shape = new THREE.Shape();

        const points = entity.vertices.map((vertex: any) => new THREE.Vector2(vertex.x, vertex.y));
        shape.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          shape.lineTo(points[i].x, points[i].y);
        }
        shape.closePath();
        const shapeGeometry = new THREE.ShapeGeometry(shape);
        // Create a MeshBasicMaterial with a specified color
        const shapeMaterial = new THREE.MeshBasicMaterial({ color: color }); // Replace 0x00ff00 with your desired color

        // Create a Mesh by combining the geometry and material
        const filledShape = new THREE.Mesh(shapeGeometry, shapeMaterial);

        // Set the position of the filled shape
        filledShape.position.set(0, 0, 0);

        dxfObjects.add(filledShape);
      }
      if (entity.type === 'HATCH') {
        // Create a polygon or polyline
        const entityShape = entity.boundary?.loops[0]?.entities[0];
        if (entityShape && entityShape.type === "POLYLINE") {
          const shape = new THREE.Shape();
          const points = entityShape.points.map((vertex: any) => new THREE.Vector2(vertex.x, vertex.y));
          shape.moveTo(points[0].x, points[0].y);
          for (let i = 1; i < points.length; i++) {
            shape.lineTo(points[i].x, points[i].y);
          }
          shape.closePath();
          const shapeGeometry = new THREE.ShapeGeometry(shape);
          // Create a MeshBasicMaterial with a specified color
          const shapeMaterial = new THREE.MeshBasicMaterial({ color: color }); // Replace 0x00ff00 with your desired color

          // Create a Mesh by combining the geometry and material
          const filledShape = new THREE.Mesh(shapeGeometry, shapeMaterial);

          // Set the position of the filled shape
          filledShape.position.set(0, 0, 0);

          dxfObjects.add(filledShape);
        }


      }

    });
    return dxfObjects;
  }

  createBox(id, startPoint, endPoint, layer) {
    
    const bottomRight = { x: endPoint.x, y: startPoint.y };
    const topRight = { x: endPoint.x, y: endPoint.y };
    const bottomLeft = { x: startPoint.x, y: endPoint.y };
    const shape = {
      closed: false,
      layer: this.toolBox,
      type: "LWPOLYLINE",
      vertices: [startPoint, bottomRight, endPoint, topRight, bottomLeft, startPoint]
    }
    const points = shape.vertices.map((vertex: any) => new THREE.Vector2(vertex.x, vertex.y));
    const polylineGeometry = new THREE.BufferGeometry().setFromPoints(points);
    const polylineMaterial = new THREE.LineBasicMaterial({
      color: this.hexStringToThreeColor(this.lineColors[layer || this.toolBox]),
      depthTest: false,
      depthWrite: false,
    });
    const polyline = new THREE.Line(polylineGeometry, polylineMaterial);
    polyline.name = id;
    this.scene.add(polyline);

  }

  hexStringToThreeColor(hexString) {
    // Remove the leading '#' if present
    hexString = hexString.replace('#', '');

    // Parse the hexadecimal value as an integer
    const hexValue = parseInt(hexString, 16);

    // Create a THREE.Color object from the parsed value
    const color = new THREE.Color(hexValue);

    return color;
  }




}