<div class="popup-header w-100 d-flex">
  <p class="w-100">
    IMAGE CONTEXT
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<mat-dialog-content class="mat-typography">
  <div class="row">
    <div class="col-sm">
      <div class="d-flex">
        <ng-container *ngFor="let image of images">
          <div class="image-wrapper" *ngIf="image.id == data.imageId">
            <img [src]="image.thumbFileUrl" alt="Image" class="img-placeholder" style="object-fit: contain" />
          </div>
        </ng-container>

        <div class="image-wrapper align-self-end ml-2">
          <div class="img-placeholder">
            <img *ngIf="currentRelation" [src]="currentRelation.thumbFileUrl" alt="Image" class="img-placeholder"
              style=" object-fit: contain; width: 100%; height: 100%;;" />
          </div>
          <button *ngIf="currentRelation" mat-icon-button class="edit-button" (click)="currentRelation = null">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="isCropImage" class="d-flex">
      <mat-spinner [diameter]="20" class="ml-1 loading"></mat-spinner>
      We are cropping the image, will take few seconds.
    </div>
    <div class="col-4 d-flex justify-content-end align-items-end">
      <button mat-button [disabled]="!currentRelation" class="btn folder-button" (click)="onClickOk()"
        style="width: 180px; padding: 7px;">
        Ok
      </button>

      <!-- <mat-form-field
        *ngIf="projects$ | async as projects"
        appearance="outline"
        class="pure"
      >
        <mat-select
          [(value)]="selectedProject"
          (click)="$event.stopImmediatePropagation()"
          placeholder="Select project"
        >
          <mat-option
            *ngFor="let project of projects"
            [value]="project.id"
            (click)="$event.stopImmediatePropagation()"
          >
            {{ project.name }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
  </div>

  <div class="modal-two-side-layout mt-3">
    <div class="modal-two-side-layout-folders">
      <!-- <div class="folders">
        <button
          *ngFor="let folder of folders$ | async"
          class="btn folder-item border-bottom"
          [class.active]="folder.path === (selectedFolder$ | async)?.path"
          (click)="toggleFolder(folder)"
        >
          {{ folder.name }}
        </button>
      </div> -->

      <div class="scroller zoom-item folders" style="height: calc(100vh - 175px);">
        <tree-root #treeComponent [treeAllowDrop]="true" [focused]="true" [nodes]="uiService.nodes"
          [options]="treeOptions">
          <ng-template #treeNodeWrapperTemplate let-node let-index="index">
            <div class="node-wrapper" [class.active]="selectedFolder == node.data?.id"
              (click)="handleClick(node.data); $event.stopImmediatePropagation();">
              <tree-node-expander [node]="node"></tree-node-expander>

              <div matTooltip="{{node.data.name}}" class="node-content-wrapper w-100 wrap-text">
                <tree-node-content [node]="node" [index]="index">
                </tree-node-content>
              </div>
            </div>
          </ng-template>
        </tree-root>
      </div>

    </div>
    <div class="modal-two-side-layout-images">
      <div class="d-flex flex-wrap">
        <ng-container *ngIf="loading$ | async">
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </ng-container>

        <span *ngIf="
            !loading$.value &&
            (folderImages$ | async)?.length === 0 &&
            selectedFolder$.value !== null
          ">
          Folder is empty.
        </span>

        <mat-radio-group style="display: flex; flex-direction: row; flex-wrap: wrap; width: 100%"
          [(ngModel)]="currentRelation" [class.hidden]="loading$ | async">
          <div class="infinite-scroll-container w-100" infiniteScroll [infiniteScrollDistance]="4"
            [infiniteScrollThrottle]="10" [scrollWindow]="false" (scrolled)="onScroll()"
            style="height: calc(100vh - 264px); overflow-y: auto;">
            <ng-container *ngFor="let image of folderImages">
              <div class="m-2" style="position: relative; display: inline-block;" (click)="onSelect(image)">
                <img [src]="image.thumbFileUrl" alt="Image" class="img-placeholder"
                  style="width: 192px; height: 115px; object-fit: cover" />
                <mat-radio-button [value]="image" style="position: absolute; right: 5px; top: 5px"></mat-radio-button>
              </div>
            </ng-container>
            <div class="w-100 d-flex justify-content-center" *ngIf="isFolderContentLoading$ | async">
              <!-- Loading... -->
              <span>
                <mat-spinner [diameter]="20"></mat-spinner>
              </span>
            </div>
          </div>
        </mat-radio-group>
      </div>
    </div>
  </div>
</mat-dialog-content>