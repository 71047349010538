<div class="grid-container h-100">
  <div class="grid-x h-100">
    <div class="cell auto">
      <div class="grid-container full h-100">
        <div class="grid-y h-100">
          <div class="cell shrink">
            <div class="py-35">
              <div class="grid-container full">
                <div class="grid-x align-middle align-justify">
                  <div class="cell shrink">
                    <h2 class="page__title mb-0">
                      <ng-content select="page-title"></ng-content>
                    </h2>
                  </div>
                  <div class="cell shrink">
                    <ng-content select="page-actions"></ng-content>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cell auto scroller p-3">
            <ng-content select="page-body"></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
