<div class="popup-header w-100 d-flex">
    <p class="w-100">
        Severity Levels
    </p>
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>

<div class="levels">
    <div mat-dialog-content>
        <ul class="p-level">
            <li  class="d-flex" [matTooltip]="level.action"
                *ngFor="let level of data.levels;let i = index;">
                <p class="w-100" (click)="addLevel(level.level)">
                    <span class="bubbles" [style.background]="level.color"></span>
                    {{level.title}}
                </p>
                <img  matTooltip="Info" (click)="openDetails(level)" class="std_image" *ngIf="level.imageUrl" [src]="level.imageUrl" />
            </li>
        </ul>
        <span *ngIf="data.chart" (click)="additionalInfo()" class="icon-info" matTooltip="Info">
            <mat-icon >info</mat-icon>
          </span>

    </div>
</div>


<ng-template let-data #imageModel>
    <div class="popup-header w-100 d-flex">
        <p class="w-100">
            {{data.title}}
        </p>
        <mat-icon class="close-btn" (click)="imageModelDialogRef.close()">close</mat-icon>
    </div>
    <div class="p-1 m-1">
        <p class="desc">
            {{data.description}}
        </p>
        <img [src]="data.imageUrl" />
    </div>

</ng-template>