<div class="popup-header w-100 d-flex">
    <p class="w-100">
      Report
    </p>

    <button *ngIf ="!data.isPreview && this.backendService.currentUserData?.uid ===  this.data.reportData?.reviewer && !this.data.reportData?.isReviewed" mat-button mat-raised-button color="primary" (click)="markAsReview()">
        Reviewed
      </button>
      <button *ngIf ="!data.isPreview && this.backendService.currentUserData?.uid ===  this.data.reportData?.approver && this.data.reportData?.isReviewed &&
      !this.data.reportData?.isApproved
      " mat-button mat-raised-button color="primary" (click)="markAsApproved()">
        Approved
      </button>
      
    <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
  </div>

<div class="m-2 p-2" [innerHTML] = "html">
</div>


