import Konva from 'konva';

const MIN_ZOOM = 1.;
const MAX_ZOOM = 5.0;
const RATIO = 0.07;

/**
 * 
 * @param evt 
 * @param stage 
 */
export function zoomHandler (evt: WheelEvent, stage: Konva.Stage): void {
    if (!stage) { return; }

    evt.preventDefault();
    const oldScale = stage.scaleX();

    const pointer = stage.getPointerPosition();
    let allowZoom = true;

    if (pointer.x < stage.x()) pointer.x = stage.x();
    if (pointer.y < stage.y()) pointer.y = stage.y();

    if (pointer.x < stage.x() || pointer.y < stage.y()) { allowZoom = false; }

    stage.find('Image').each(img => {
        const imgRect = img.getClientRect();
        if (pointer.x > imgRect.width + imgRect.x) pointer.x = imgRect.width + imgRect.x;
        if (pointer.y > imgRect.height + imgRect.y) pointer.y = imgRect.height + imgRect.y;

        if (pointer.x > imgRect.width + imgRect.x || pointer.y > imgRect.height + imgRect.y) { allowZoom = false; }
    });

    //if (!allowZoom) return;

    const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
    };

    var delta = 0;
    if(evt.deltaY) {
        delta = evt.deltaY > 0 ? -1 : 1;
    }
    
    var newScale = _clamp(oldScale + (delta * RATIO));

    stage.scale({ x: newScale, y: newScale });
    stage.position({
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
    });
    stage.batchDraw();
}

export function registerZoomOnWheel(stage: Konva.Stage) {
    stage.on('wheel', (e) => {
        zoomHandler(e.evt, stage);
        stage.fire('dragmove', e); // dragmove event on zoom forces image to position correctly on zoom as well
    });
}

/**
 * 
 * @param num 
 * @param min 
 * @param max 
 */
function _clamp(num: number, min?: number, max?: number): number {
    min = min || MIN_ZOOM;
    max = max || MAX_ZOOM;

    return num <= min ? min : num >= max ? max : num;
}