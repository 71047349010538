<div style="height: 100vh" class="grid-x align-center align-middle">
  <div class="cell shrink">
    <mat-card class="mat-card-outer">
      <div class="card-wrapper">

        <div class="card-logo">
          <img alt="logo" src="../../assets/appLogo.jpg">
        </div>

        <h2 class="card-title">
          Set new password
        </h2>

        <form [formGroup]="form" *ngIf="isCorrectLink$ | async; else linkMessage" (submit)="submit()">
         
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" required formControlName="password" class="form-control"
              [ngClass]="{ 'is-invalid':  f.password.errors }" />
            <div *ngIf=" f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
              <div *ngIf="f.password.errors.minlength">Minimum length is 6 digit.</div>
            </div>
          </div>

          <div class="form-group">
            <label for="password">Password confirmation</label>
            <input type="text" required formControlName="confirm" class="form-control"
              [ngClass]="{ 'is-invalid':  f.confirm.errors }" />
              <div *ngIf=" f.confirm.errors" class="invalid-feedback">
                <div *ngIf="f.confirm.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirm.errors.mustMatch">Password and confirm not match</div>
              </div>
          </div>
          <div class="d-flex">
            <button mat-raised-button [disabled]="form.invalid"
              class="submit-button" type="submit">Set password</button>
          </div>

          <!-- success -->

          <div *ngIf="isSuccess$ | async; else errorMessage" style="color: rgb(0, 109, 0); margin-top: 20px;">
            Password changed
          </div>
        </form>

      </div>
    </mat-card>
  </div>
</div>

<ng-template #linkMessage>
  <div>
    {{ isCorrectLink$.value === null ? 'Loading...' : 'Incorrect link'}}
  </div>
</ng-template>

<ng-template #errorMessage>
  <div style="color: rgb(142, 0, 0); margin-top: 20px;">
    {{ isSuccess$.value === null ? '' : 'Something went wrong'}}
  </div>
</ng-template>