import { BackendService } from '../services/backend.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { UiService } from '../services/ui.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../projects/item/project.type';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GeneralService } from '../services/general.service';

@Component({
  selector: 'app-image-timeline-dialog',
  templateUrl: './image-timeline-dialog.component.html',
  styleUrls: ['./image-timeline-dialog.component.scss']
})
export class ImageTimelineDialogComponent implements OnInit {

  public projects$: Observable<Project[]>;
  public folders$: Observable<any[]>;
  public folderImages$: Observable<any[]>;

  public timelines$ = new BehaviorSubject<any>([]);

  private imageIds: string[] = [];
  private imagesThumbs = {};
  private imageId: string;
  private projectId: string;

  private onDestroy$ = new Subject();

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  constructor(
    public dialogRef: MatDialogRef<ImageTimelineDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backendService: BackendService,
    public uiService: UiService,
    public router: Router,
    private generalService: GeneralService
  ) {
    this.projects$ = this.uiService.projects$;
    this.folders$ = this.uiService.folders$;
    this.imageId = data.imageId;
    this.projectId = data.projectId;
    this.getTimelines();
  }

  getTimelines() {
      this.getAllTimelines();

  }

  onScroll() {
  //  this.onScrollForAllTimelines();
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
  }


  getAllTimelines(): void {
    this.backendService.getAllImagesTimelines$(this.projectId).pipe(
      tap((timelines: any[]) => {
        this.tapForAllTimelines(timelines);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();
  }

  tapForAllTimelines(timelines: any[]) {
    this.timelines$.next(
      this.generalService.makeImageArrayUnique(this.timelines$.value.concat(timelines))
    );
    timelines.forEach(rel => {
      rel.isChecked = false;
      this.imageIds.push(rel.keyImageId);
      this.imageIds.push(rel.valueImageId);
    });
    this.loadImages();
  }
  // ================

  loadImages(): void {
    this.imagesThumbs = {};
    this.imageIds.forEach(id => {
      this.backendService.getImage$<any>(id).pipe(take(1)).subscribe((image) => {
        if (!image) {
          console.log('[image is absent] =>', id)
        }
        this.imagesThumbs[id] = image.thumbFileUrl;
      });
    });
  }

  /*deleteRelation(relation: any, update: boolean = true): void {
    this.backendService.removeImageRelation(this.projectId, relation.parentImagePath, relation.childImagePath).pipe(
      switchMap(() => {
        this.backendService.addHistory([this.data.imageId],this.data.projectId, "context removed", "context",relation.imageId)
        return this.backendService.removeAnnotation(relation.annotationId, relation.imageId);
   
      }),
      take(1)
    ).subscribe(() => {
      if (update) {
        this.router.navigate['../' + this.imageId];
        this.getTimelines();
      }
    });
  }

  deleteAllRelations(): void {
    this.timelines$.value.forEach((relation, idx) => {
      this.deleteRelation(relation, false);
    });
    this.timelines$.next([]);
  }
*/
  handleProjectChange(): void {}

  filterCheckedArray(array): any[] {
    return array.filter(item => item.isChecked);
  }

  addTimelinesToReport(): void {
    this.dialogRef.close({
      timelines: this.filterCheckedArray(this.timelines$.value),
      imagesThumbs: this.imagesThumbs
    });
  }

  getReadOnlyForCurrentUser(): boolean {
    return this.generalService.getReadOnlyForCurrentUser();
  }
}

