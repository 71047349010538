import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-link-labels',
  templateUrl: './link-labels.component.html',
  styleUrls: ['./link-labels.component.scss']
})
export class LinkLabelsComponent implements OnInit {
  onDestroy$ = new Subject();
  labels: any = [];
  model;
  selectedLabel: string = "";
  isLoading: boolean = false;
  technicalDrawings = [];
  constructor(
    public dialogRef: MatDialogRef<LinkLabelsComponent>,
    public dialog: MatDialog,
    private toast: ToastrService,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.getModels(data.assetId)
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
  }

  getModels(assetId) {
    if (this.data.model === '3d') {
      this.backendService.get3DModels$(assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
        if (result) {
          this.model = result;
          this.backendService.get3DModelLabels$(result.id).pipe(takeUntil(this.onDestroy$)).subscribe((labels: any) => {
            this.labels = labels.label || [];
          })
        }
      });
    }
    else {
      this.backendService.get2DModels$(assetId).pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
        result = result?.filter(o => !o.isDXFFile);
        
        if (result.length) {
          
          this.model = result[0];
          this.technicalDrawings = result;
          this.labels = result[0].labels || [];
        }

      });
    }

  }

  activateImage(drawing) {
    this.model = drawing;
    this.labels = drawing.labels;
    this.selectedLabel = null;
    this.isRightMenu = false;
  }

  isRightMenu: boolean = false;
  toggleRightMenu() {
    this.isRightMenu = !this.isRightMenu;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  labelClick(label) {
    this.selectedLabel = label.id;
  }

  linkImageToLabel() {
    this.backendService.linkImages(this.data.assetId, this.model.id, this.selectedLabel, [this.data.imageId], 'images',this.data.projectId,this.data.model).subscribe((result) => {
      this.toast.success('Image Linked successfully');
      this.dialogRef.close();
    })
  }

  selectLabel() {
    this.backendService.getLinkedImages(this.data.assetId, this.model.id,this.data.projectId).pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      const label = Object.keys(result).find(key => key === this.selectedLabel)
     let images =[];
      if (label) {
        images= result[label]['images'] || []
      }
      this.dialogRef.close({images});
    })
  }
}

