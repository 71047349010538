import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from '@angular/material/dialog';
import { BackendService } from '../services/backend.service';
import { filter, switchMap } from 'rxjs/operators';
import { combineLatest, Subject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IGroup } from '../models/group.interface';

export interface IImageGroupDialogData {
  groups$: Observable<IGroup>;
  projectId: string;
  showAddBtn?: boolean;
}

@Component({
  selector: 'app-image-group-dialog',
  templateUrl: './image-group-dialog.component.html',
  styleUrls: ['./image-group-dialog.component.scss'],
})
export class ImageGroupDialogComponent implements OnInit, OnDestroy {
  get selectedGroup(): any[] {
    return this.backendService.selectedGroups;
  }
  set selectedGroup(val: any[]) {
    this.backendService.selectedGroups = val;
  }

  public filterValue$ = new BehaviorSubject<string>('');
  public group$: Observable<any>;
  private onDestroy$ = new Subject();

  constructor(
    public dialogRef: MatDialogRef<ImageGroupDialogComponent>,
    public dialog: MatDialog,
    private backendService: BackendService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.group$ = combineLatest([
      this.data.groups$,
      this.filterValue$
    ]).pipe(
      map(([groups, filterVal]: [any[], string]) => groups.filter(group => {
        return group.groupName.toLowerCase().includes(filterVal.toLowerCase());
      }))
    );
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  toggleGroup(event: MouseEvent, group: any): void {
    event.stopPropagation();
    event.preventDefault();
    const currentVal = this.selectedGroup;
    let newVal;
    if (this.isGroupSelected(group)) {
      newVal = currentVal.filter(item => item.id !== group.id);
    } else {
      newVal = [...currentVal, group];
    }
    this.selectedGroup = newVal;
  }

  isGroupSelected(group: any): boolean {
    return this.selectedGroup.some(item => item.id === group.id);
  }

  deleteGroup(id: string): void {
    this.backendService.removeImageGroup(id, this.data.projectId).subscribe();
  }

  filterTitles($event: Event): void {
    const searchValue = ($event.target as HTMLInputElement).value;
    this.filterValue$.next(searchValue);
  }

  addGroups(): void {
    const selected = this.selectedGroup;
    this.selectedGroup = [];
    this.dialogRef.close(selected);
  }

  deleteCheckedGroups(): void {
    this.selectedGroup.forEach(group => {
      this.deleteGroup(group.id);
    });
  }
}
