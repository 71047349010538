import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {BackendService} from '../services/backend.service';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  public params;
  public text = 'Proceeding...';

  constructor(private firestore: AngularFirestore,
              private readonly router: Router,
              private route: ActivatedRoute,
              private auth: AngularFireAuth,
              private backendService: BackendService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (params.mode === 'verifyEmail') {
        const actionCode = params.oobCode;
        const lang = params.lang || 'en';

        const config = {
          apiKey: params.apiKey
        };

        this.auth.user.subscribe(user => {
          this.firestore.collection('prospective_members', ref =>
            ref.where(`email`, '==', user.email)
          ).valueChanges({idField: 'id'}).subscribe((invites: any) => {
            if (invites.length) {
              combineLatest(invites.map(invite => {
                return this.backendService.addUserToProject(invite.projectId, invite.role, false);
              })).subscribe(result => {
                console.log('addition result', result);
              });
            }
          });
        });

        this.auth.applyActionCode(actionCode).then((result) => {
          this.text = 'Email verified';
        }).catch(er => {
          this.text = 'Something went wrong';
        });
      }
    });
  }

}
