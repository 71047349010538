import {
  AmbientLight,
  AnimationMixer,
  AxesHelper,
  Box3,
  Cache,
  DirectionalLight,
  GridHelper,
  HemisphereLight,
  LinearEncoding,
  LoaderUtils,
  LoadingManager,
  PMREMGenerator,
  PerspectiveCamera,
  REVISION,
  Scene,
  SkeletonHelper,
  Vector3,
  WebGLRenderer,
  sRGBEncoding,
  LinearToneMapping,
  ACESFilmicToneMapping,
  MathUtils,
  Raycaster,
  Clock
} from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls'
import * as THREE from 'three'
//import Stats from 'three/examples/jsm/libs/stats.module.js';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as _ from 'lodash';
import { EXRLoader } from 'three/examples/jsm/loaders/EXRLoader';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment';

//import * as createBackground  from './../Three/lib/three-vignette.js'

const DEFAULT_CAMERA = '[default]';

const MANAGER = new LoadingManager();
const THREE_PATH = `https://unpkg.com/three@0.${REVISION}.x`
const DRACO_LOADER = new DRACOLoader(MANAGER).setDecoderPath(`${THREE_PATH}/examples/js/libs/draco/gltf/`);
const KTX2_LOADER = new KTX2Loader(MANAGER).setTranscoderPath(`${THREE_PATH}/examples/js/libs/basis/`);
const Preset = { ASSET_GENERATOR: 'assetgenerator' };
import { interval, Subject, Subscription } from 'rxjs';
Cache.enabled = true;
export class Alignment {
  settingsChanges = new Subject();
  el;
  options;
  lights = [];
  content;
  gui;
  state;

  scene;
  camera;

  renderer;
  pmremGenerator;
  controls;
  cameraCtrl;
  cameraFolder = null;
  animFolder = null;
  animCtrls = [];
  morphFolder = null;
  morphCtrls = [];
  skeletonHelpers = [];
  gridHelper = null;
  axesHelper = null;
  vignette;
  neutralEnvironment;
  raycaster = new Raycaster()
  projectProgress = 0;
  baselineProgress = 0;
  clock = new Clock();
  object;
  transformControls;
  pivot = new THREE.Group();

  orientation = {
    X: 0,
    Y: 0,
    Z: 0,
  }
  baselineMesh;
  projectMesh;
  constructor(el, options) {
    this.el = el;
    this.options = options;

    this.lights = [];
    this.content = null;
    this.gui = null;
    const lighting = {
      punctualLights: true,
      exposure: 0.0,
      toneMapping: LinearToneMapping,
      ambientIntensity: 0.3,
      ambientColor: 0xFFFFFF,
      directIntensity: 0.8 * Math.PI, // TODO(#116)
      directColor: 0xFFFFFF
    }

    if (options.modelOptions) {
      this.state = { ...this.options.modelOptions, ...lighting, ...{ grid: true } };
    } else {
      this.state = {
        ...{
          camera: DEFAULT_CAMERA,
          grid: true,
          label: 0.05,
          bgColor: '#e1e1e1'
        },
        ...lighting
      };
    }
    this.scene = new Scene();
    const fov = 60;
    this.camera = new PerspectiveCamera(fov, el.width / el.height, 0.01, 1000);
    this.scene.add(this.camera);

    this.renderer = new WebGLRenderer({ preserveDrawingBuffer: true, antialias: true });
    //this.renderer.useLegacyLights  = true;
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    this.renderer.setClearColor(`${this.state.bgColor}`);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(el.width, el.height);

    this.pmremGenerator = new PMREMGenerator(this.renderer);
    this.pmremGenerator.compileEquirectangularShader();

    this.neutralEnvironment = this.pmremGenerator.fromScene(new RoomEnvironment()).texture;

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.rotateSpeed = 2.0;
    // this.controls.minDistance = 1;
    //this.controls.target.set(0, 0, 0)
    //  this.controls.autoRotate = false;
    this.el.appendChild(this.renderer.domElement);
    this.cameraCtrl = null;
    this.cameraFolder = null;
    this.animFolder = null;
    this.animCtrls = [];
    this.morphFolder = null;
    this.morphCtrls = [];
    this.skeletonHelpers = [];
    this.gridHelper = null;
    this.axesHelper = null;

    // this.addAxesHelper();
    this.animate = this.animate.bind(this);
    this.requestId = requestAnimationFrame(this.animate);
  }

  requestId;
  animate(time) {
    this.requestId = requestAnimationFrame(this.animate);
    this.controls.update();
    this.render();

  }

  render() {
    this.renderer.render(this.scene, this.camera);
    if (this.controls)
      this.controls.update()

    //  this.updateAnnotationOpacity();
    //this.updateScreenPosition();
  }

  updateScreenPosition() {
    const vector = new THREE.Vector3(250, 250, 250);
    const canvas = this.renderer.domElement;
    vector.project(this.camera);
    vector.x = Math.round((0.5 + vector.x / 2) * (canvas.width / window.devicePixelRatio));
    vector.y = Math.round((0.5 - vector.y / 2) * (canvas.height / window.devicePixelRatio));
  }

  updateLights() {
    const state = this.state;
    const lights = this.lights;

    if (state.punctualLights && !lights.length) {
      this.addLights();
    } else if (!state.punctualLights && lights.length) {
      this.removeLights();
    }

    this.renderer.toneMapping = Number(state.toneMapping);
    this.renderer.toneMappingExposure = Math.pow(2, state.exposure);

    if (lights.length === 2) {
      lights[0].intensity = state.ambientIntensity;
      lights[0].color.setHex(state.ambientColor);
      lights[1].intensity = state.directIntensity;
      lights[1].color.setHex(state.directColor);
    }
  }

  addLights() {
    const state = this.state;
    if (this.options.preset === Preset.ASSET_GENERATOR) {
      const hemiLight = new HemisphereLight();
      hemiLight.name = 'hemi_light';
      this.scene.add(hemiLight);
      this.lights.push(hemiLight);
      return;
    }
    const light1 = new AmbientLight(state.ambientColor, state.ambientIntensity);
    light1.name = 'ambient_light';
    this.camera.add(light1);

    const light2 = new DirectionalLight(state.directColor, state.directIntensity);
    light2.position.set(0.5, 0, 0.866); // ~60º
    light2.name = 'main_light';
    this.camera.add(light2);
    this.lights.push(light1, light2);
  }

  removeLights() {
    this.lights.forEach((light) => light.parent.remove(light));
    this.lights.length = 0;
  }

  updateAnnotationOpacity() {
    const annotations = this.scene.children.filter(o => o.name == 'annotations');
    const mesh = this.scene.children.filter(o => o.type == 'Group' && o.children.length)[0];
    annotations.forEach(label => {
      const meshDistance = this.camera.position.distanceTo(mesh?.position);
      const spriteDistance = this.camera.position.distanceTo(label.position);
      let spriteBehindObject = spriteDistance > meshDistance;
      label.material.opacity = spriteBehindObject ? 0.25 : 1;
    });


    // Do you want a number that changes size according to its position?
    // Comment out the following line and the `::before` pseudo-element.
  }

  resize(height, width) {
    this.camera.aspect = width / height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(width, height);
  }


  /**
   * @param {THREE.Object3D} object
   * @param {Array<THREE.AnimationClip} clips
   */

  baseModelCheckSubscription: Subscription;
  setContent(object, isTiles = false, projectModel, baseModel) {
    const box = new Box3().setFromObject(object);
    const size = box.getSize(new Vector3()).length();
    const center = box.getCenter(new Vector3());
    if (isTiles) {
      this.camera.position.z = -5; // default load tiles position
    }
    else {

      this.controls.reset();
       object.position.x = (object.position.x - center.x);
       object.position.y = (object.position.y - center.y);
       object.position.z = (object.position.z - center.z);
      this.controls.maxDistance = size * 100;
      this.camera.near = size / 100;
      this.camera.far = size * 100;
      this.camera.updateProjectionMatrix();
      this.camera.position.copy(center);
      this.camera.position.x = size / 2.0;
      this.camera.position.y = size / 5.0;
      this.camera.position.z = size / 2.0;
      this.camera.lookAt(center);
    }

    this.setCamera(DEFAULT_CAMERA);
    this.content = object;
    this.state.punctualLights = true;
    this.content.traverse((node) => {
      if (node.isLight) {
        this.state.punctualLights = false;
      } else if (node.isMesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
        node.material.depthWrite = !node.material.transparent;
      }
    })


    box.getCenter(object.position); // this re-sets the mesh position
    object.position.multiplyScalar(- 1);
    this.scene.add(this.pivot);

    const observable = interval(500);
    this.baseModelCheckSubscription = observable.subscribe(() => {
      if (this.scene.children.filter(o => o.name === 'baseline').length) {
        this.baseModelCheckSubscription.unsubscribe();

        if(!projectModel.isAligned){
          const baseObject = this.scene.children.filter(o => o.name === 'baseline')[0];

          var bbox1 = new Box3().setFromObject(baseObject);
          var bbox2 = new Box3().setFromObject(object);
          const bbox1Size = bbox1.getSize(new Vector3())
          const bbox2Size = bbox2.getSize(new Vector3())
          // Calculate the scaling factors needed to make the models the same size
          var scaleFactor = bbox1Size.length() / bbox2Size.length();;
        //   Apply the scaling factors to the second model
          object.scale.set(scaleFactor, scaleFactor, scaleFactor);
      
          // Calculate the distance to move the second model to the right of the first model
          var distanceToMove = bbox1.max.x - bbox2.min.x;

          // Move the second model to the right of the first model
          object.position.x += bbox1.max.x - bbox2.min.x;
          object.position.y += bbox1.max.y - bbox2.min.y;
          object.position.z += bbox1.max.z - bbox2.min.z;
  
        }
        

      }
    });
    this.pivot.add(object);
    this.updateLights();
    this.updateEnvironment();
    if (baseModel?.modelOptions?.orientation) {
      this.pivot.rotation.x = THREE.MathUtils.degToRad(baseModel.modelOptions.orientation.X);
      this.pivot.rotation.y = THREE.MathUtils.degToRad(baseModel.modelOptions.orientation.Y);
      this.pivot.rotation.z = THREE.MathUtils.degToRad(baseModel.modelOptions.orientation.Z);
    } else {
      //  rotate 180 degree for up/down issue
      if (!baseModel.ownModel) {
        const rad = MathUtils.degToRad(180);
        this.pivot.rotation.x = rad;
        this.pivot.rotation.y = rad;
      }

    }
    this.alignment(this.options.modelOptions);
    this.setCameraPositions(baseModel?.modelOptions)

    this.transformControls = new TransformControls(this.camera, this.renderer.domElement)
    this.transformControls.setMode('scale');
    this.transformControls.position.set(-20, 0, -5);
    // Set the renderOrder of the TransformControls to render on top
    this.scene.add(this.transformControls);

    const _this = this;
    function onChange() {
      if (_this.transformControls.getMode() == "rotate") {
        const currentRotation = _this.transformControls.object.rotation;
        _this.options.modelOptions.rotation.x = THREE.MathUtils.radToDeg(currentRotation.x);
        _this.options.modelOptions.rotation.y = THREE.MathUtils.radToDeg(currentRotation.y);
        _this.options.modelOptions.rotation.z = THREE.MathUtils.radToDeg(currentRotation.z);
      }

      if (_this.transformControls.getMode() == "translate") {
        const currentPosition = _this.transformControls.object.position;
        _this.options.modelOptions.translate.x = currentPosition.x;
        _this.options.modelOptions.translate.y = currentPosition.y;
        _this.options.modelOptions.translate.z = currentPosition.z;
      }
    }
    // Event listener for the mouseUp event
    this.transformControls.addEventListener('mouseUp', onChange);

    // Event listener for the touchEnd event
    this.transformControls.addEventListener('touchEnd', onChange);

  }

  setBaselineContent(object, isTiles = false) {
    const box = new Box3().setFromObject(object);
    if (isTiles) {
      this.camera.position.z = -5; // default load tiles position
    }
    else {
      const center = box.getCenter(new Vector3());
       object.position.x = (object.position.x - center.x);
       object.position.y = (object.position.y - center.y);
       object.position.z = (object.position.z - center.z);
      
    }

    this.setCamera(DEFAULT_CAMERA);
    object.name = "baseline";
    this.scene.add(object);
    this.state.punctualLights = true;
    object.traverse((node) => {
      if (node.isLight) {
        this.state.punctualLights = false;
      } else if (node.isMesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
        node.material.depthWrite = !node.material.transparent;
      }
    })
    this.updateLights();
    this.updateEnvironment();
    this.updateDisplay();


  }

  addProjectScene(object) {
    this.content = object;
    const pivot = _.cloneDeep(this.pivot);
    this.pivot = new THREE.Group();
    this.pivot.rotation.copy(pivot.rotation);
    const meshes = this.scene.children.filter(o => o.type === 'Group');
    meshes.forEach(mesh => {
      this.scene.remove(mesh)
    });
    this.scene.add(this.pivot);
    this.pivot.add(object);

  }

  addScene(object) {
    this.content = object;
    const pivot = _.cloneDeep(this.pivot);
    this.pivot = new THREE.Group();
    this.pivot.rotation.copy(pivot.rotation);
    this.scene.add(this.pivot);
    this.pivot.add(object);
  }

  combineScene(baseModelObject, projectModelObject) {
    this.content = projectModelObject;
    const pivot = _.cloneDeep(this.pivot);
    this.pivot = new THREE.Group();
    this.pivot.rotation.copy(pivot.rotation);
    this.scene.add(this.pivot);
    this.pivot.add(projectModelObject);
    this.scene.add(baseModelObject)
  }


  alignedModel(object, isTiles = false, modelOptions = {}, isBaseLine?: boolean) {
    const box = new Box3().setFromObject(object);
    if (isTiles) {
      this.camera.position.z = -5; // default load tiles position
    }
    else {

      const size = box.getSize(new Vector3()).length();
      const center = box.getCenter(new Vector3());
      this.controls.reset();
      /*  object.position.x = (object.position.x - center.x);
        object.position.y = (object.position.y - center.y);
        object.position.z = (object.position.z - center.z);
        */
      this.controls.maxDistance = size * 100;
      this.camera.near = size / 100;
      this.camera.far = size * 100;
      this.camera.updateProjectionMatrix();
      this.camera.position.copy(center);
      this.camera.position.x = size / 2.0;
      this.camera.position.y = size / 5.0;
      this.camera.position.z = size / 2.0;
      this.camera.lookAt(center);
    }
    this.content = object;
    this.setCamera(DEFAULT_CAMERA);
    this.pivot = new THREE.Group();
    this.scene.add(this.pivot);
    this.pivot.add(object);
    if (isBaseLine) {
      this.alignment(modelOptions);
    }
    //
    this.state.punctualLights = true;
    this.content.traverse((node) => {
      if (node.isLight) {
        this.state.punctualLights = false;
      } else if (node.isMesh) {
        // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
        node.material.depthWrite = !node.material.transparent;
      }
    })

    this.updateLights();
    this.updateEnvironment();

  }

  setCameraPositions(option) {
    if (option?.cameraPosition) {
      this.camera.position.set(
        option.cameraPosition.x,
        option.cameraPosition.y,
        option.cameraPosition.z
      )
    }
    if (option?.rotateAngle) {
      this.camera.rotation.set(
        option.rotateAngle.x,
        option.rotateAngle.y,
        option.rotateAngle.z
      )
    }
    if (option?.controlTarget) {
      this.controls.target.set(
        option.controlTarget.x,
        option.controlTarget.y,
        option.controlTarget.z)
    }
    if (option?.up) {
      this.camera.up.set(
        option.up.x,
        option.up.y,
        option.up.z
      )
    }
  }

  updateEnvironment() {
    const environment = {
      id: "neutral",
      name: "Neutral",
      path: null
    };
    this.getCubeMapTexture(environment).then(({ envMap }: any) => {
      this.scene.environment = envMap;
    });

  }


  alignment(option) {
    if (option?.rotation) {
      this.content.rotation.x = THREE.MathUtils.degToRad(option.rotation.x);
      this.content.rotation.y = THREE.MathUtils.degToRad(option.rotation.y);
      this.content.rotation.z = THREE.MathUtils.degToRad(option.rotation.z);
    }
    if (option?.scale) {
      this.content.scale.x = option.scale.x;
      this.content.scale.y = option.scale.y;
      this.content.scale.z = option.scale.z;

    }
    if (option?.translate) {
      if ((option.translate.x || option.translate.y || option.translate.z)) {
        this.content.position.x = option.translate.x;
        this.content.position.y = option.translate.y;
        this.content.position.z = option.translate.z;

      } else {
        this.state.translate = this.content.position;
      }

    }

    if (option?.orientation && (option?.orientation.X != 0
      || option?.orientation.Y != 0 || option?.orientation.Z != 0)) {
      this.orientation.X = option?.orientation.X;
      this.orientation.Y = option?.orientation.Y;
      this.orientation.Z = option?.orientation.Z;
      this.pivot.rotation.x += THREE.MathUtils.degToRad(option.orientation.X);
      this.pivot.rotation.y += THREE.MathUtils.degToRad(option.orientation.Y);
      this.pivot.rotation.z += THREE.MathUtils.degToRad(option.orientation.Z);
    } else {
      this.orientation.X = 0;
      this.orientation.Y = 0;
      this.orientation.Z = 0;
    }
    console.log(this.orientation)
  }


  /**
   * @param {string} name
   */
  setCamera(name) {
    this.controls.enabled = true;
  }


  getCubeMapTexture(environment) {
    const { id, path } = environment;

    // neutral (THREE.RoomEnvironment)
    if (id === 'neutral') {

      return Promise.resolve({ envMap: this.neutralEnvironment });

    }

    // none
    if (id === '') {
      return Promise.resolve({ envMap: null });
    }

    return new Promise((resolve, reject) => {
      new EXRLoader()
        .load(path, (texture) => {
          const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
          this.pmremGenerator.dispose();

          resolve({ envMap });

        }, undefined, reject);

    });

  }

  updateDisplay() {
    if (this.state.grid !== Boolean(this.gridHelper)) {
      if (this.state.grid) {
        this.gridHelper = new GridHelper();
        this.axesHelper = new AxesHelper();
        this.axesHelper.renderOrder = 999;
        this.axesHelper.onBeforeRender = (renderer) => renderer.clearDepth();
        this.scene.add(this.axesHelper);

        this.scene.add(this.gridHelper);
      } else {
        this.scene.remove(this.gridHelper);
        this.scene.remove(this.axesHelper);
        this.gridHelper = null;
      }
    }
  }

  updateBackground() {
    this.renderer.setClearColor(`${this.state.bgColor}`);
  }

  labelCtrlChange() {
    const labels = this.scene.children.filter(o => o.name == 'annotations');
    labels.forEach(label => {
      label.scale.set(this.state.label, this.state.label, this.state.label)
    });
  }
  rotationUpdateX() {
    const rad = MathUtils.degToRad(parseFloat(this.state.rotation.x));
    this.content.rotation.x = rad;
  }

  rotationUpdateY() {
    const rad = MathUtils.degToRad(parseFloat(this.state.rotation.y));
    this.content.rotation.y = rad;
  }

  rotationUpdateZ() {
    const rad = MathUtils.degToRad(parseFloat(this.state.rotation.z));
    this.content.rotation.z = rad;
  }

  translateUpdateX() {
    this.content.position.x = this.state.translate.x;
  }

  translateUpdateY() {
    this.content.position.y = this.state.translate.y;
  }

  translatUpdateZ() {
    this.content.position.z = this.state.translate.z;

  }

  scaleUpdate() {
    this.content.scale.x = this.state.scale.x;
    this.content.scale.y = this.state.scale.x;
    this.content.scale.z = this.state.scale.x;
  }

  updateGUI() {
    const cameraNames = [];
    const morphMeshes = [];
    this.content.traverse((node) => {
      if (node.isMesh && node.morphTargetInfluences) {
        morphMeshes.push(node);
      }
      if (node.isCamera) {
        node.name = node.name || `VIEWER__camera_${cameraNames.length + 1}`;
        cameraNames.push(node.name);
      }
    });

    if (cameraNames.length) {
      this.cameraFolder.domElement.style.display = '';
      if (this.cameraCtrl) this.cameraCtrl.remove();
      const cameraOptions = [DEFAULT_CAMERA].concat(cameraNames);
      this.cameraCtrl = this.cameraFolder.add(this.state, 'camera', cameraOptions);
      this.cameraCtrl.onChange((name) => this.setCamera(name));
    }

    if (morphMeshes.length) {
      this.morphFolder.domElement.style.display = '';
      morphMeshes.forEach((mesh) => {
        if (mesh.morphTargetInfluences.length) {
          const nameCtrl = this.morphFolder.add({ name: mesh.name || 'Untitled' }, 'name');
          this.morphCtrls.push(nameCtrl);
        }
        for (let i = 0; i < mesh.morphTargetInfluences.length; i++) {
          const ctrl = this.morphFolder.add(mesh.morphTargetInfluences, i, 0, 1, 0.01).listen();
          Object.keys(mesh.morphTargetDictionary).forEach((key) => {
            if (key && mesh.morphTargetDictionary[key] === i) ctrl.name(key);
          });
          this.morphCtrls.push(ctrl);
        }
      });
    }


  }

  clear() {
    if (!this.content) return;
    this.scene.remove(this.content);
    // dispose geometry
    this.content.traverse((node) => {
      if (!node.isMesh) return;
      node.geometry.dispose();
    });
    if (this.baseModelCheckSubscription) {
      this.baseModelCheckSubscription.unsubscribe();
    }
    // dispose textures
    traverseMaterials(this.content, (material) => {
      for (const key in material) {

        if (key !== 'envMap' && material[key] && material[key].isTexture) {

          material[key].dispose();

        }

      }

    });

  }





  loadGLTF(model, isbaselineModel = false) {
    const _this = this;
    const urls = model.gltfUrl;
    return new Promise((resolve, reject) => {
      for (const path in urls) {
        const file = urls[path];
        if (urls.length > 1) {
          MANAGER.setURLModifier(function (url) {
            return url;
          });
        }
        MANAGER.onProgress = function (url, itemsLoaded, itemsTotal) {
          if (urls.length > 1) {
            !isbaselineModel ? _this.projectProgress = (itemsLoaded / itemsTotal * 100)
              : _this.baselineProgress = (itemsLoaded / itemsTotal * 100)

          }
        };
        const extension = file.split('.').pop().toLowerCase();
        if (extension == 'gltf' || extension == 'glb') {
          const loader = new GLTFLoader(MANAGER)
            .setDRACOLoader(DRACO_LOADER)
            .setMeshoptDecoder(MeshoptDecoder)
            .setKTX2Loader(KTX2_LOADER.detectSupport(this.renderer));

          loader.load(file, (gltf) => {
            const scene = gltf.scene || gltf.scenes[0];
            if (!scene) {
              // Valid, but not supported by this viewer.
              throw new Error(
                'This model contains no scene, and cannot be viewed here. However,'
                + ' it may contain individual 3D resources.'
              );
            }
            resolve(scene);
          },
            (xhr) => {
              if (xhr.lengthComputable) {
                if (urls.length === 1) {
                  !isbaselineModel ?
                    _this.projectProgress = (xhr.loaded / xhr.total) * 100
                    : _this.baselineProgress = (xhr.loaded / xhr.total) * 100

                }
              }
            },
            (error) => {
              reject(error)
            }
          )
        }
      }
    });
  }


  remove() {
    this.clear();
    cancelAnimationFrame(this.requestId);
    this.renderer.dispose();
    this.projectProgress = 0;
    this.baselineProgress = 0;
    if (this.transformControls) {
      this.transformControls.dispose();
      this.scene.remove(this.transformControls)
    }

  }

  changeControl(event) {
    this.controls.dispose();
    const camera = new PerspectiveCamera(this.camera.fov, this.camera.aspect, this.camera.near, this.camera.far);
    camera.position.copy(this.camera.position);
    camera.rotation.copy(this.camera.rotation);
    this.camera = camera;
    // this.camera.updateProjectionMatrix();
    if (event === 'orbit') {
      const orbitControl = new OrbitControls(this.camera, this.renderer.domElement);
      orbitControl.dampingFactor = 0.2;
      orbitControl.enableDamping = true;
      orbitControl.enabled = true;
      orbitControl.target.copy(this.controls.target)
      this.controls = orbitControl;

    } else {
      const trackballControls = new TrackballControls(this.camera, this.renderer.domElement);
      trackballControls.rotateSpeed = 3.0;
      trackballControls.zoomSpeed = 1.2;
      trackballControls.panSpeed = 0.8;
      trackballControls.staticMoving = true;
      trackballControls.dynamicDampingFactor = 0.3;
      this.controls = trackballControls;
    }

  }

};

function traverseMaterials(object, callback) {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
}



