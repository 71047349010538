import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import { BackendService } from '../../services/backend.service';

@Component({
  selector: 'project-rename-folder',
  templateUrl: './rename-folder.component.html',
  styleUrls: ['./rename-folder.component.scss']
})
export class ProjectRenameFolderComponent implements OnInit {
  public name: string;
  public createBtnOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Rename',
    raised: true,
    spinnerSize: 24,
    buttonColor: 'primary',
    spinnerColor: 'primary',
    mode: 'indeterminate',
    customClass: 'text-uppercase'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backend: BackendService,
    public dialogRef: MatDialogRef<ProjectRenameFolderComponent>
  ) {
    this.name = this.data.folder.data.name
  }

  ngOnInit(): void {
  }

  isNameValid(name): boolean {
    return this.data.folders.indexOf(name) === -1 || name === this.data.folder.data.name;
  }

  close(): void {
    this.dialogRef.close(false);
  }

  handleRename(name): void {
    if (name && this.isNameValid(name)) {
      this.createBtnOptions.active = true;
      this.backend.renameFolder(this.data.folder.data.path, this.name).then(() => {
        this.createBtnOptions.active = false;
        this.dialogRef.close({success: true, name});
      });
    }
  }
}
