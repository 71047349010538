import { Component, ViewChild, Inject, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { ITreeNode, TreeNode, TreeNodeType } from '../../models/tree-node.interface';
import { BackendService } from 'src/app/services/backend.service';
import { ToastrService } from 'ngx-toastr';
declare var Jodit:any;
@Component({
  selector: 'app-template-adjustment',
  templateUrl: './template-adjustment.component.html',
  styleUrls: ['./template-adjustment.component.scss']
})
export class TemplateAdjustmentComponent implements OnInit {
  content:any;
  @ViewChild('dailyReportEditor') editorDialog: ElementRef;
  constructor(private backendService:BackendService,
    private toastr:ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TemplateAdjustmentComponent>,
    @Inject(DOCUMENT) private document: Document,
    ) { }

  ngOnInit(): void {
    setTimeout(() => 
   this.initializeJodit(this.data.htmlContent), 20)
  }
  
editor;
initializeJodit(content) {
  const editorElement = this.editorDialog.nativeElement;
  this.editor = Jodit.make(editorElement,{
    removeButtons: [
      'zoom', 'fullsize'   // Removed Zoom and Fullscreen
  ]
  });
  this.editor.value=content;
}


saveTemplate(){
  this.backendService.updateDailyReportTemplate(this.data.projectId,this.data.templateId, this.editor.value).subscribe(() => {
    this.dialogRef.close();
    this.toastr.success("template updated")
  })
}
}
