import { BackendService } from './../services/backend.service';
import { BehaviorSubject, Subject, Observable, of } from 'rxjs';
import { UiService } from './../services/ui.service';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Project } from '../projects/item/project.type';
import { map, switchMap, takeUntil, share, take, tap } from 'rxjs/operators';
import { IFolder } from '../models/folder.interface';
import { CombineQueriesService } from '../services/combine-queries.service';
import { GeneralService } from '../services/general.service';
import { ITreeOptions } from '@circlon/angular-tree-component';
@Component({
  selector: 'app-image-detail-dialog',
  templateUrl: './image-detail-dialog.component.html',
  styleUrls: ['./image-detail-dialog.component.scss'],
})
export class ImageDetailDialogComponent implements OnInit, OnDestroy {
  public projects$: Observable<Project[]>;
  public folders$: Observable<any[]>;
  public folderImages$: Observable<any[]>;
  public folderImages: any[];
  onDestroy$ = new Subject();
  public selectedProject$: BehaviorSubject<string>;
  get selectedProject(): string {
    return this.selectedProject$.value;
  }
  set selectedProject(val: string) {
    this.selectedProject$.next(val);
  }

  public selectedFolder$ = new BehaviorSubject<IFolder>(null);
  public loading$ = new BehaviorSubject<boolean>(false);
  public isFolderContentLoading$ = new BehaviorSubject<boolean>(false);
  private _currentRelation: any;
  public isCropImage: any;
  public get currentRelation() {
    return this._currentRelation;
  }

  public set currentRelation(value: any) {
    this.deleteRelation();
    this._currentRelation = value;
  }

  public get images(): any[] {
    return this.uiService.images;
  }

  treeOptions: ITreeOptions = {
    allowDrag: false,
    allowDrop: false,
  };
  public selectedFolder: string;

  constructor(
    public dialogRef: MatDialogRef<ImageDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public backendService: BackendService,
    public uiService: UiService,
    private combineQueriesService: CombineQueriesService,
    private generalService: GeneralService
  ) {
    //  this.projects$ = this.uiService.projects$.pipe(map((projects: any[]) => projects.filter(project => !project.deletedDate)))
    this.selectedProject$ = new BehaviorSubject(this.data.projectId);
    if (this.uiService.nodes.length) {
      this.handleClick(this.uiService.nodes[0]);
    }
  }

  ngOnInit(): void {
    this.dialogRef.updatePosition({ top: '0', right: '0' });

    this.dialogRef.beforeClosed().pipe(take(1)).subscribe(() => this.handleRelationChange());
  }

  handleRelationChange(): void {
    if (this.currentRelation) {
      this.backendService.addImageRelation(
        this.data.projectId,
        `images/${this.data.imageId}`,
        `images/${this._currentRelation.id}`,
        this.data.area,
        this.data.imageId
      );
      this.backendService.addHistory([this.data.imageId], this.data.projectId, `context added linking to ${this._currentRelation.fileName}`, "context", this._currentRelation.id)

    } else {
      this.backendService
        .removeAnnotation(
          this.uiService.addContextArea.annotation.id,
          this.data.imageId
        )
        .subscribe(() => {
          this.uiService.addContextArea = null;
        });
    }
  }

  onScroll() {
    this.isFolderContentLoading$.next(true)
    this.combineQueriesService.getNextFolderImages$(this.selectedFolder$.value, this.selectedProject).pipe(
      tap(images => {
        this.folderImages = this.generalService.makeImageArrayUnique(this.folderImages.concat(images));
        this.isFolderContentLoading$.next(false)
      }),
    ).subscribe();
  }

  onSelect(image): void {
    this.currentRelation = image;
    /* if (this.isCropImage) {
      return
    }
   if (image.id === this.data.imageId) {
      this.currentRelation = null;
      this.isCropImage = true;
      this.backendService.croppedLinkedImage(this.data.projectId, this.data.annotationId, image.id, this.uiService.addContextArea.annotation.id
        , this.selectedFolder$.value.path).pipe(take(1), share()).subscribe((response: any) => {
          this.backendService.getCropedImage$(this.data.projectId, response.fileUrl)
            .pipe(takeUntil(this.onDestroy$)).subscribe((result: any) => {
              this.currentRelation = result;
              this.isCropImage = false;
            })

        }, error => {
          this.isCropImage=false;
        })
    } else {
      this.currentRelation = image;

    }
    */
  }

  deleteRelation(): void {
    if (!this.currentRelation) {
      return;
    }
    this.backendService.removeImageRelation(this.data.projectId, `images/${this.data.imageId}`, `images/${this._currentRelation.id}`);

  }

  toggleFolder(folder: IFolder): void {
    this.selectedFolder$.next(folder);
  }

  onClickOk(): void {
    this.dialogRef.close();
  }

  handleClick(node) {
    this.isFolderContentLoading$.next(true)
    this.selectedFolder = node.id;
    this.combineQueriesService.getFirstFolderImages$(node, this.selectedProject).pipe(take(1)
    ).subscribe(images => {
      this.isFolderContentLoading$.next(false)
      this.folderImages = images
      this.selectedFolder$.next(node);
    }
    )
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
