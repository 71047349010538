<div class="popup-header w-100 d-flex">
  <p class="w-100">
    {{data.group.groupName}}
  </p>
  <mat-icon class="close-btn" (click)="dialogRef.close()">close</mat-icon>
</div>
<div>
  <div class="reportTemplate">
 
    <div class="reportTemplate-header">
      <!-- <span>
       
    
        </span> -->
      <div class="d-flex">
        <button [disabled]="getReadOnlyForCurrentUser()" mat-raised-button color ="primary" class="mr-2"
          (click)="showImageDialog()">
          <mat-icon class="mr-1">add_circle_outline</mat-icon>
          <span>Add images</span>
        </button>
        <button mat-button color="basic" type="button" (click)="deleteImagesFromGroup()"
          [disabled]="getReadOnlyForCurrentUser()">
          <mat-icon class="mr-2" svgIcon="trash"></mat-icon>
          <span>Delete</span>
        </button>
      </div>
    </div>

    <div class="bg reportTemplate-body">
      <app-image-checkbox-grid [images]="groupImages" (selectedChange)="groupImagesChanged($event)"></app-image-checkbox-grid>
    </div>
  </div>
</div>